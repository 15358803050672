import React, { useEffect, useState, useMemo, useRef } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Grid, Typography } from "@mui/material/";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory, useParams, useLocation } from "react-router-dom";
import updateStarredItemsCache from "_utils/updateStarredItemsCache";
import DangerButton from "_components/DangerButton";
import CustomModal from "_components/Modal/CustomModal";
import isElectronHook from "_utils/isElectron";
import { useUser } from "_utils/UserContext";
import { FILES_FOLDER_PAGINATION_LIMIT } from "_constants/GlobalVariables";
import {
   TRASH_FILES_FOLDERS,
   COPY_FILES_FOLDERS,
   DELETE_FILES_FOLDERS,
   GET_PROJECTS,
   MOVE_FILES_FOLDERS,
   STARRED_FILES_FOLDERS,
   GET_FOLDERS,
   GET_FILES,
   GET_CURRENT_USER,
   CREATE_NESTED_FOLDER,
   GET_FOLDER,
} from "_apollo/queries";
import MoveModal from "_components/Modal/MoveModal";
import CopyModal from "_components/Modal/CopyModal";
import FilesTable from "_components/FilesTable/FilesTable";
import ModalFilesListings from "_components/ModalFilesListings/ModalFilesListings";
import useCheckStorageLimit from "_utils/useCheckStorageLimit";
import { filterHiddenFiles } from "_utils/fileTypeUtil/fileTypeUtil";
import useGetFiles from "_utils/useGetFiles";
import { useQueryParam, BooleanParam, StringParam } from "use-query-params";
import useFileUploader from "_components/FileUploader/useFileUploader";
import FileUploaderDropHandler from "_components/FileUploaderDropHandler";
import FoldersPath from "./FoldersPath";
import HeaderActions from "./HeaderActions";
import HeaderActionsTrash from "./HeaderActionsTrash";

const useStyles = makeStyles((theme) => ({
   header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.coreApp.borders}`,
   },
   foldersPath: {
      marginTop: "4px",
      marginLeft: "4px",
      flexGrow: 1,
   },
}));

/**
 * Files Listings
 *
 * Take a folder object (and a project object in some cases) and returns the paginated folder listings for that component.
 *
 * TODO: this is a very large component, it should be broken down into smaller components.
 * @returns {JSX.Element} The files listings component for the passed folder.
 */
const FilesListings = () => {
   const isElectron = isElectronHook();
   const history = useHistory();
   const { uri } = useParams();
   const classes = useStyles();

   // const { totalSyncFiles, numberOfSyncedFiles } = useSyn
   const checkStorageLimit = useCheckStorageLimit();
   const [trashed] = useQueryParam("trashed", BooleanParam);

   // File uri, and folderUri from the router params.
   const { folderUri } = useParams();

   const [user] = useUser();

   const [selectedFiles, setSelectedFiles] = useState([]);
   const [selectedFolders, setSelectedFolders] = useState([]);
   const [openPopover, setOpenPopover] = useState(false);
   const [showMoveModal, setShowMoveModal] = useState(false);
   const [showCopyModal, setShowCopyModal] = useState(false);
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [showFileProcessingModal, setShowFileProcessingModal] =
      useState(false);
   const [currentModalFolder, setCurrentModalFolder] = useState(null);
   const [disableModalBtn, setDisableModalBtn] = useState(false);
   const [search] = useState("");
   const [selectedDropFolderId, setSelectedDropFolderId] = useState(null);
   const [openPath] = useQueryParam("openPath", StringParam);
   const location = useLocation();

   // Check for variable on the url to open deep links and trigger effects.
   useEffect(() => {
      if (isElectron && user?.profile && openPath) {
         window.electronAPI.showFolderInFinderOrExplorer(
            openPath !== "root" // 'root' is used as default due an empty string resolves to null/undefined.
               ? `${window.electronAPI.getMyAuxFilesFolder(
                    user?.profile?.id
                 )}/${openPath}`
               : window.electronAPI.getMyAuxFilesFolder(user?.profile?.id)
         );

         // Remove from the url the query param that opens the file location.
         const queryParams = new URLSearchParams(location.search);
         if (queryParams.has("openPath")) {
            queryParams.delete("openPath");
            history.replace({
               search: queryParams.toString(),
            });
         }
      }
   }, [user?.profile, openPath]);

   const {
      data: dataFolders,
      fetchMore: fetchMoreFolders,
      refetch: refetchFolders,
      loading: foldersLoading,
   } = useQuery(GET_FOLDERS, {
      variables: {
         uri: folderUri,
         offset: 0,
         trashed: Boolean(trashed),
      },
   });

   const folders = dataFolders?.getFolders?.folders;
   const foldersPage = dataFolders?.getFolders?.pageInfo?.page;
   const foldersTotalPages = dataFolders?.getFolders?.pageInfo.totalPages;

   const {
      data: dataFiles,
      fetchMore: fetchMoreFiles,
      refetch: refetchFiles,
      loading: filesLoading,
   } = useGetFiles(
      { uri: folderUri, offset: 0, trashed: Boolean(trashed) },
      (foldersPage === undefined && foldersTotalPages > 0) || // Only start loading files via lazyload once folders are all loaded otherwise only files with lazyload.
         (foldersPage < foldersTotalPages && foldersTotalPages > 0) // The foldersTotalPages > 0 condition makes sure that files load in a folder with no sub-folders.
   );

   const files = filterHiddenFiles(dataFiles?.getFiles?.files);
   const filesPage = dataFiles?.getFiles?.pageInfo?.page;
   const filesTotalPages = dataFiles?.getFiles?.pageInfo.totalPages;

   // Allows files or folders to be checked or unchecked.
   useEffect(() => {
      let checkFiles;
      let checkFolders;
      if (currentModalFolder?.id) {
         checkFiles = selectedFiles.some(
            (el) => el?.folderId === currentModalFolder?.id
         );
         checkFolders = selectedFolders.some(
            (el) => el?.parentFolderId === currentModalFolder?.id
         );
      } else {
         checkFiles = selectedFiles.some((el) => !el?.folderId);
         checkFolders = selectedFolders.some((el) => !el?.parentFolderId);
      }
      if (checkFiles || checkFolders) {
         setDisableModalBtn(true);
      } else {
         setDisableModalBtn(false);
      }
   }, [selectedFiles, selectedFolders, currentModalFolder?.id]);

   const [moveFilesFolders, { loading: movingFiles }] = useMutation(
      MOVE_FILES_FOLDERS,
      {
         // onCompleted({ moveFilesFolder: moveWatchFiles }) {
         onCompleted() {
            setShowMoveModal(false);
            setSelectedFiles([]);
            setSelectedFolders([]);
         },
         refetchQueries: [
            {
               query: GET_PROJECTS,
            },
            {
               query: GET_FOLDERS,
               variables: { uri: folderUri, trashed: Boolean(trashed) },
            },
            {
               query: GET_FILES,
               variables: { uri: folderUri, trashed: Boolean(trashed) },
            },
         ],
         awaitRefetchQueries: true,
      }
   );

   const [deleteFilesFolders] = useMutation(DELETE_FILES_FOLDERS, {
      refetchQueries: [
         {
            query: GET_FOLDERS,
            variables: { uri: folderUri, trashed: true },
         },
         {
            query: GET_FILES,
            variables: { uri: folderUri, trashed: true },
         },
         {
            query: GET_CURRENT_USER,
         },
      ],
      onCompleted() {
         setSelectedFiles([]);
         setSelectedFolders([]);
      },
   });

   const [copyFilesFolders, { loading: copyingFiles }] = useMutation(
      COPY_FILES_FOLDERS,
      {
         onCompleted() {
            setShowCopyModal(false);
            setSelectedFiles([]);
            setSelectedFolders([]);
            setCurrentModalFolder(null);
         },
         refetchQueries: [
            {
               query: GET_PROJECTS,
            },
            {
               query: GET_CURRENT_USER,
            },
            {
               query: GET_FOLDERS,
               variables: { uri: folderUri, trashed: Boolean(trashed) },
            },
            {
               query: GET_FILES,
               variables: { uri: folderUri, trashed: Boolean(trashed) },
            },
         ],
         awaitRefetchQueries: true,
      }
   );

   const [starFilesFolders] = useMutation(STARRED_FILES_FOLDERS, {
      onCompleted() {
         setSelectedFiles([]);
         setSelectedFolders([]);
      },
      update: updateStarredItemsCache,
   });

   const [trashFilesFolders] = useMutation(TRASH_FILES_FOLDERS, {
      /**
       * trashFilesFolders onCompleted
       * Triggers sync in electron after restoring/trashing files/folders.
       * Reset state of the selected checkboxes.
       */
      onCompleted() {
         setSelectedFiles([]);
         setSelectedFolders([]);
      },
      refetchQueries: [
         { query: GET_PROJECTS },
         {
            query: GET_FOLDERS,
            variables: { uri: folderUri, trashed: false },
         },
         {
            query: GET_FILES,
            variables: { uri: folderUri, trashed: false },
         },
         {
            query: GET_FOLDERS,
            variables: { uri: folderUri, trashed: true },
         },
         {
            query: GET_FILES,
            variables: { uri: folderUri, trashed: true },
         },
      ],
      awaitRefetchQueries: true,
   });

   /**
    * Has More Files Folders
    * @returns {boolean} If more files/folders should be fetched
    */
   const hasMoreFilesFolders = useMemo(() => {
      if (filesPage === undefined || foldersPage === undefined) {
         return true;
      }
      return foldersPage < foldersTotalPages || filesPage < filesTotalPages;
   }, [filesPage, foldersPage, filesLoading, foldersLoading]);

   /**
    * Fetch More Files Folders
    *
    * Fetches the next page of files or folders in the Files
    */
   const fetchMoreFilesFolders = () => {
      if (filesLoading || foldersLoading) {
         return;
      }
      if (foldersPage < foldersTotalPages && foldersTotalPages > 0) {
         fetchMoreFolders({
            variables: {
               uri: folderUri,
               offset: FILES_FOLDER_PAGINATION_LIMIT * foldersPage,
               trashed: Boolean(trashed),
            },
         });
      } else if (filesPage < filesTotalPages) {
         fetchMoreFiles({
            variables: {
               uri: folderUri,
               offset: FILES_FOLDER_PAGINATION_LIMIT * filesPage,
               trashed: Boolean(trashed),
            },
         });
      }
   };

   /**
    * Handle Refetch
    *
    * Force a refetch of the current files and folders.
    */
   const handleRefetch = () => {
      const variablesRefetch = {
         uri: folderUri,
         offset: 0,
         trashed: Boolean(trashed),
      };
      refetchFolders(variablesRefetch);
      refetchFiles(variablesRefetch);
   };

   // Reload file and folder data when the user changes folder route.
   useEffect(() => {
      handleRefetch();
   }, [folderUri, !!uri, trashed]);

   // From an array of files/folders, return an array of ids.
   // Used for queries/mutations.
   const getIds = (fList) => fList.map((f) => f.id);

   const totalSelectedFiles = useMemo(() => {
      return (selectedFiles.length || 0) + (selectedFolders.length || 0);
   }, [selectedFiles, selectedFolders]);

   const [filter, setFilter] = useState("");

   const { data: folderData } = useQuery(GET_FOLDER, {
      variables: { folderUri },
   });
   const currentFolder = folderData?.getFolder;

   const uploadFiles = useFileUploader();
   const folderUploadButton = useRef(null);
   const dropFilesRef = useRef([]);

   const [createNestedFolder] = useMutation(CREATE_NESTED_FOLDER, {
      onCompleted(data) {
         const { createNestedFolder: fileData } = data;
         if (fileData) {
            uploadFiles(
               dropFilesRef.current.map((f) => {
                  const mutatedFile = f;
                  mutatedFile.overwrite = true;
                  return mutatedFile;
               })
            );
         }
      },
      refetchQueries: [
         {
            query: GET_FOLDERS,
            variables: {
               uri: folderUri,
               trashed: false,
            },
         },
         {
            query: GET_FILES,
            variables: {
               uri: folderUri,
               trashed: false,
            },
         },
      ],
      awaitRefetchQueries: true,
   });

   /**
    * Handle Drop Event
    *
    * This function will be called when a file is dropped on the dropzone.
    * @param {object} event event from input.
    * @param {object} data normalized event from dnd.
    */
   const handleDropEvent = async (event, data) => {
      const eventResetValue = event;
      const filesInput = Array.from(data?.files || []);

      const normalFiles = filesInput;
      // Combine files data and filter out .DS_Store files from macs.
      const filesUpload = filterHiddenFiles(normalFiles || data?.files);
      const foldersUpload = data?.folders;

      if (foldersUpload.length === 0 && filesUpload.length === 0) return;

      // Check user limits
      const enoughStorage = checkStorageLimit(filesUpload, user);
      if (!enoughStorage) {
         return;
      }

      if (foldersUpload?.length > 0 && !(foldersUpload[0] instanceof File)) {
         dropFilesRef.current = filesUpload;
         createNestedFolder({
            variables: {
               nestedFolders: foldersUpload,
               currentFolderId: selectedDropFolderId || currentFolder?.id,
               currentProjectId: currentFolder?.projectId,
            },
         });
      } else {
         uploadFiles(
            filesUpload.map((f) => {
               const mutableFile = f;
               mutableFile.folderId = selectedDropFolderId || currentFolder?.id;
               return mutableFile;
            })
         );

         if (eventResetValue?.target?.value) eventResetValue.target.value = "";
      }
      setOpenPopover(false);
   };

   useEffect(() => {
      const inputEl = folderUploadButton.current;
      if (inputEl && openPopover) {
         inputEl.setAttribute("webkitdirectory", true);
         inputEl.setAttribute("directory", true);
         inputEl.setAttribute("mozdirectory", true);
      }
   }, [openPopover]);

   // Used when the user clicks on "Create Folder" button in FilesTable.
   const [createFolderData, setCreateFolderData] = useState(null);

   return (
      <Grid width="100%">
         {/* @region Modals - could refactor to FilesModal components */}
         {/* <CustomModal
            header={
               <Typography
                  component="h3"
                  color="danger.main"
                  variant="modalHeader"
               >
                  Removing Files from Sync Folder
               </Typography>
            }
            footer={
               <Grid container justifyContent="center">
                  <DangerButton
                     onClick={() =>
                        moveFilesFolders({
                           variables: {
                              fileIds: getIds(selectedFiles),
                              folderIds: getIds(
                                 selectedFolders.filter(
                                    (f) => f.id !== currentModalFolder?.id
                                 )
                              ),
                              targetFolderId: currentModalFolder?.id,
                              projectId: currentModalFolder?.projectId,
                           },
                        })
                     }
                  >
                     Confirm
                  </DangerButton>
                  <Button onClick={() => setShowLocalChangesModal(false)}>
                     Cancel
                  </Button>
               </Grid>
            }
            open={showLocalChangesModal}
            handleClose={() => setShowLocalChangesModal(false)}
         >
            <Typography marginBottom={3} variant="h5" textAlign="center">
               Some of the selected files are currently in a synced folder. If
               you move these files they will be removed from local file system.
            </Typography>
         </CustomModal> */}
         <MoveModal
            title={`Move ${totalSelectedFiles} Files`}
            show={showMoveModal}
            disableBtn={disableModalBtn}
            handleClose={() => {
               setShowMoveModal(false);
               setCurrentModalFolder(null);
               setSelectedFiles([]);
               setSelectedFolders([]);
            }}
            handleMove={() => {
               moveFilesFolders({
                  variables: {
                     fileIds: getIds(selectedFiles),
                     folderIds: getIds(
                        selectedFolders.filter(
                           (f) => f.id !== currentModalFolder?.id
                        )
                     ),
                     targetFolderId: currentModalFolder?.id,
                     projectId: currentModalFolder?.projectId,
                  },
               });
            }}
            body={
               <div style={{ padding: "12px 20px" }}>
                  <ModalFilesListings
                     currentModalFolder={currentModalFolder}
                     setCurrentModalFolder={setCurrentModalFolder}
                     defaultSelectedFolder={selectedFolders}
                     defaultSelectedFiles={selectedFiles}
                     filter={filter}
                     setFilter={setFilter}
                  />
               </div>
            }
            loading={movingFiles}
            fileAmount={totalSelectedFiles}
         />
         <CopyModal
            title={`Copy ${totalSelectedFiles} Files`}
            show={showCopyModal}
            disableBtn={disableModalBtn}
            handleClose={() => {
               setShowCopyModal(false);
               setSelectedFiles([]);
               setSelectedFolders([]);
               setCurrentModalFolder(null);
            }}
            handleMove={() => {
               // Check user limits
               const canCopyFiles = checkStorageLimit(
                  [...selectedFiles, ...selectedFolders],
                  user
               );
               if (canCopyFiles) {
                  copyFilesFolders({
                     variables: {
                        fileIds: getIds(selectedFiles),
                        folderIds: getIds(
                           selectedFolders.filter(
                              (f) => f.id !== currentModalFolder?.id
                           )
                        ),
                        targetFolderId: currentModalFolder?.id,
                        projectId: currentModalFolder?.projectId,
                     },
                  });
               }
            }}
            body={
               <div style={{ padding: "12px 20px" }}>
                  <ModalFilesListings
                     currentModalFolder={currentModalFolder}
                     setCurrentModalFolder={setCurrentModalFolder}
                     defaultSelectedFolder={selectedFolders}
                     defaultSelectedFiles={selectedFiles}
                     filter={filter}
                     setFilter={setFilter}
                  />
               </div>
            }
            loading={copyingFiles}
            setFilter={setFilter}
            fileAmount={totalSelectedFiles}
         />
         <CustomModal
            fullWidth="xs"
            header={
               <Typography
                  component="h3"
                  color="danger.main"
                  variant="modalHeader"
                  marginTop="20px"
               >
                  {selectedFiles.length === 0 && selectedFolders.length === 0
                     ? "Empty trash"
                     : "Delete files"}
               </Typography>
            }
            footer={
               <Grid
                  container
                  justifyContent="center"
                  gap="16px"
                  marginBottom="20px"
               >
                  <Button
                     variant="outlined"
                     sx={{ borderColor: "secondary.shade30" }}
                     onClick={() => {
                        setShowDeleteModal(false);
                        setSelectedFiles([]);
                        setSelectedFolders([]);
                     }}
                  >
                     Cancel
                  </Button>
                  <DangerButton
                     onClick={() => {
                        if (
                           selectedFiles.length === 0 &&
                           selectedFolders.length === 0
                        ) {
                           deleteFilesFolders({
                              variables: {
                                 fileIds: getIds(files),
                                 folderIds: getIds(folders),
                              },
                           });
                        } else {
                           deleteFilesFolders({
                              variables: {
                                 fileIds: getIds(selectedFiles),
                                 folderIds: getIds(selectedFolders),
                              },
                           });
                        }
                        setShowDeleteModal(false);
                     }}
                  >
                     Delete
                  </DangerButton>
               </Grid>
            }
            handleClose={() => {
               setShowDeleteModal(false);
               setSelectedFiles([]);
               setSelectedFolders([]);
            }}
            open={showDeleteModal}
         >
            <Grid direction="column" container>
               <Typography
                  marginBottom={1}
                  variant="modalMessage"
                  component="h5"
                  textAlign="center"
                  maxWidth="100%"
               >
                  Are you sure you want to permanently delete this?
                  <br />
                  This cannot be undone
               </Typography>
            </Grid>
         </CustomModal>
         <CustomModal
            open={showFileProcessingModal}
            handleClose={() => setShowFileProcessingModal(false)}
            header={
               <Typography
                  component="h3"
                  color="danger.main"
                  variant="modalHeader"
               >
                  Files Still Processing
               </Typography>
            }
            footer={
               <Grid container justifyContent="center">
                  <Button
                     color="primary"
                     variant="outlined"
                     onClick={() => setShowFileProcessingModal(false)}
                  >
                     Go Back
                  </Button>
               </Grid>
            }
         >
            <Typography sx={{ margin: "0 1em" }}>
               You cannot move or copy files that are being processed.
               Processing should complete in a few minutes.
            </Typography>
         </CustomModal>
         {/* @endregion */}

         <div className={classes.header}>
            <div className={classes.foldersPath}>
               <FoldersPath folderUri={folderUri} />
            </div>

            {/* TODO: Split the trash in to a separate route to simplify this and use shared components for the table element. */}
            {trashed ? (
               <HeaderActionsTrash
                  files={files}
                  folders={folders}
                  selectedFiles={selectedFiles}
                  selectedFolders={selectedFolders}
                  totalSelectedFiles={totalSelectedFiles}
                  setShowDeleteModal={setShowDeleteModal}
                  trashFilesFolders={trashFilesFolders}
               />
            ) : (
               <HeaderActions
                  files={files}
                  folderUri={folderUri}
                  selectedDropFolderId={selectedDropFolderId}
                  selectedFolders={selectedFolders}
                  selectedFiles={selectedFiles}
                  totalSelectedFiles={totalSelectedFiles}
                  starFilesFolders={starFilesFolders}
                  trashFilesFolders={trashFilesFolders}
                  setShowCopyModal={setShowCopyModal}
                  setShowMoveModal={setShowMoveModal}
                  openPopover={openPopover}
                  setOpenPopover={setOpenPopover}
                  setCreateFolderData={setCreateFolderData}
               />
            )}

            {/* TODO: refactor this to app layout so that files can be dropped on the app at any time. */}
            {!trashed && (
               <FileUploaderDropHandler
                  dropId={currentFolder?.id}
                  handleDropEvent={handleDropEvent}
               />
            )}
         </div>
         {/* 🤮🤮🤮🤮 */}
         <FilesTable
            folders={folders}
            files={files}
            hasMoreFilesFolders={hasMoreFilesFolders}
            fetchMoreFilesFolders={fetchMoreFilesFolders}
            foldersLoading={foldersLoading}
            filesLoading={filesLoading}
            setSelectedFolders={setSelectedFolders}
            setSelectedFiles={setSelectedFiles}
            selectedFolders={selectedFolders}
            selectedFiles={selectedFiles}
            createFolderData={createFolderData}
            setCreateFolderData={setCreateFolderData}
            setShowCopyModal={setShowCopyModal}
            setShowMoveModal={setShowMoveModal}
            starFilesFolders={(params) => {
               starFilesFolders(params);
            }}
            trashFilesFolders={trashFilesFolders}
            moveFilesFolders={(...params) => {
               moveFilesFolders(...params);
            }}
            isInTrash={trashed}
            setShowDeleteModal={setShowDeleteModal}
            selectedDropFolderId={selectedDropFolderId}
            setSelectedDropFolderId={setSelectedDropFolderId}
            search={search}
            folderUri={folderUri}
         />
      </Grid>
   );
};

FilesListings.defaultProps = {};
FilesListings.propTypes = {};

export default FilesListings;
