import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import { useUser } from "_utils/UserContext";
import { Grid } from "@mui/material";
import ConnectionStateButton from "./ConnectionStateButton";
import Tag from "./Tag";

const useStyles = makeStyles((theme) => ({
   roundedImage: {
      height: 57,
      width: 57,
   },
   roundedImageContainer: {
      display: "flex",
      marginRight: "8px",
   },
   containerCards: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
      gridGap: "1rem",
      margin: "24px 0",
   },
   card: {
      maxWidth: 400,
   },
   flex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
   },
   maxWidth: {
      flexGrow: 100,
   },
   userName: {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: "24px",
      color: "black",
      textDecoration: "none",
   },
   link: {
      textDecoration: "none",
   },
   chipContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "6px",
      marginTop: "12px",
   },
   cardContainer: {
      display: "flex",
   },
   description: {
      marginTop: "8px",
      color: theme.palette.secondary.shade60,
      fontSize: theme.typography.small2.fontSize,
      lineHeight: "16px",
   },
}));

const DiscoverProfilesList = ({ profiles }) => {
   const classes = useStyles();
   const [user] = useUser();
   const currentProfile = user?.profile;

   return (
      <div className={classes.containerCards}>
         {profiles.map((profile) => {
            return (
               <Card
                  variant="outlined"
                  // Featured projects extra spacing
                  className={classes.card}
                  key={`${profile.uri}-discover-feed`}
               >
                  <div className={classes.cardContainer}>
                     <div className={classes.roundedImageContainer}>
                        <Link to={`/${profile?.uri}`}>
                           <Avatar
                              className={classes.roundedImage}
                              src={profile?.photoUrlOrGravatar}
                              alt=""
                           />
                        </Link>
                     </div>
                     <div className={classes.maxWidth}>
                        <div className={classes.flex}>
                           <Link
                              to={`/${profile?.uri}`}
                              className={classes.link}
                           >
                              <div className={classes.userName}>
                                 {profile?.name}
                              </div>
                           </Link>
                           <ConnectionStateButton
                              connection={
                                 currentProfile?.connections?.sent.find(
                                    (c) => c.receiverProfile?.id === profile.id
                                 ) ||
                                 currentProfile?.connections?.received.find(
                                    (c) => c.senderProfile?.id === profile.id
                                 )
                              }
                              publicProfile={profile}
                              isSender={
                                 !!currentProfile?.connections?.sent.find(
                                    (c) => c.receiverProfile?.id === profile.id
                                 )
                              }
                              isReceiver={
                                 !!currentProfile?.connections?.received.find(
                                    (c) => c.senderProfile?.id === profile.id
                                 )
                              }
                              buttonStyles={{
                                 size: "small",
                                 variant: "contained",
                              }}
                           />
                        </div>
                        <div className={classes.chipContainer}>
                           {profile?.tags.slice(0, 4).map((el) => (
                              <Grid
                                 key={`${profile?.uri}-${el?.uri}-tag-profile-discover`}
                                 component={Link}
                                 to={`/discover/${el.uri}`}
                                 style={{
                                    marginBottom: 5,
                                    marginRight: 5,
                                    cursor: "pointer",
                                    textDecoration: "inherit",
                                    color: "inherit",
                                 }}
                              >
                                 <Tag color="default" tag={el} id={el.id} />
                              </Grid>
                           ))}
                        </div>
                     </div>
                  </div>
                  <div className={classes.description}>
                     {profile?.description}
                  </div>
               </Card>
            );
         })}
      </div>
   );
};
DiscoverProfilesList.propTypes = {
   profiles: PropTypes.instanceOf(Array).isRequired,
};
export default DiscoverProfilesList;
