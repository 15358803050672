import PropTypes from "prop-types";
import React from "react";
import { Modal, Button, Box } from "@mui/material";
import DangerButton from "./DangerButton";

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 400,
   bgcolor: "background.paper",
   boxShadow: 24,
   borderRadius: 5,
   p: 4,
};

const ConfirmCancelModal = ({
   show,
   handleClose,
   handleConfirm,
   title,
   body,
   textButton,
   textCancelButton = "No, Go Back",
   className = undefined,
   loading = false,
}) => {
   return (
      <Modal open={show} onClose={handleClose}>
         <Box sx={style}>
            <h3>{title}</h3>
            {body}
            <Box mt={3} display="flex" gap={1}>
               <Button
                  className={className}
                  variant="contained"
                  color="primary"
                  onClick={() => handleClose()}
                  disabled={loading}
               >
                  {textCancelButton}
               </Button>
               <DangerButton
                  variant="contained"
                  data-testid="cancel-button"
                  onClick={() => handleConfirm()}
                  disabled={loading}
               >
                  {textButton}
               </DangerButton>
            </Box>
         </Box>
      </Modal>
   );
};

ConfirmCancelModal.propTypes = {
   body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
   handleClose: PropTypes.func.isRequired,
   handleConfirm: PropTypes.func.isRequired,
   show: PropTypes.bool.isRequired,
   title: PropTypes.string.isRequired,
   textButton: PropTypes.string.isRequired,
   textCancelButton: PropTypes.string.isRequired,
   className: PropTypes.shape({}),
   loading: PropTypes.bool,
};

ConfirmCancelModal.defaultProps = {
   className: undefined,
   loading: false,
};

export default ConfirmCancelModal;
