/**
 * Is Electron Hook
 * @returns {boolean} is the app in Electron
 */
const isElectronHook = () => {
   const userAgent = navigator.userAgent.toLowerCase();
   if (userAgent.indexOf(" electron/") > -1) {
      // Electron-specific code
      return true;
   }
   return false;
};

export default isElectronHook;
