import React, {
   useEffect,
   useState,
   useRef,
   useLayoutEffect,
   useMemo,
} from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useParams, useHistory } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import FilePreview from "_components/FilePreview";
import isFileReady from "_utils/isFileReady";
import useGetDAWFileFolder from "_utils/useGetDAWFileFolder";
import FileTags from "_components/FileInfo/FileTags";
import GeneralInfo from "_components/FileInfo/GeneralInfo";
import AppLayout from "../../_layouts/AppLayout";

const useStyles = makeStyles(() => ({
   root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      position: "relative",
      height: "100%",
   },
   rootInner: {
      boxSizing: "border-box",
      paddingTop: "12px",
      height: "100%",
      display: "flex",
      flexDirection: "column",
   },
   // tabContainer: {
   //    borderBottom: "1px solid #D9D9D9",
   //    height: 35,
   //    minHeight: 35,
   //    "& .MuiTabs-flexContainer": {
   //       alignItems: "center",
   //       height: "100%",
   //       "& button": {
   //          textTransform: "capitalize",
   //       },
   //    },
   //    "& .MuiTabs-indicator": {
   //       height: 4,
   //    },
   //    "& .MuiTab-root": {
   //       padding: "0 15px",
   //       fontSize: 12,
   //       minWidth: 0,
   //    },
   // },
   // tab: {
   //    overflowY: "auto",
   // },
}));

/**
 * Single File Page.
 *
 * Full screen view of a file such as an audio file or DAW file showing only the bouncedown.
 * @returns {JSX.Element} Project File Simple View.
 */
const FilePage = () => {
   const classes = useStyles();

   const isPollingRef = useRef(false);

   const { fileFolderURI } = useParams();

   const history = useHistory();

   const {
      data: fileData,
      // loading: loadingFile,
      // refetch,
      startPolling,
      stopPolling,
   } = useGetDAWFileFolder(() => {
      // if (!data?.getFileFolder?.file && !data?.getFileFolder?.folder) {
      //    history.push(`/files`);
      // }
      // TODO: TODO: fix these queries to get the file correctly using simple file query.
   });

   useEffect(() => {
      if (
         fileData?.getFileFolder?.file?.trashed ||
         fileData?.getFileFolder?.folder?.trashed
      ) {
         history.push(`/files`);
      }
      if (
         fileData?.getFileFolder?.file &&
         !isFileReady(fileData?.getFileFolder?.file)
      ) {
         startPolling(10000);
         isPollingRef.current = true;
      } else if (isPollingRef.current) {
         stopPolling();
      }
   }, [fileData?.getFileFolder]);

   const { fileFolder } = useMemo(() => {
      const currentFileFolder = fileData?.getFileFolder.file
         ? fileData?.getFileFolder.file
         : fileData?.getFileFolder.folder;
      return {
         fileFolder: currentFileFolder,
      };
   }, [fileData?.getFileFolder]);

   // const [tabIndex, setTabIndex] = useState(0);

   const [height, setHeight] = useState(0);
   const previewRef = useRef(null);

   useLayoutEffect(() => {
      setHeight(previewRef.current?.clientHeight);
   }, [previewRef.current, previewRef.current?.clientHeight]);

   return (
      <AppLayout>
         <div className={classes.root} key={`${fileFolderURI}-file-page`}>
            <div className={classes.rootInner}>
               <Box ref={previewRef}>
                  <FilePreview fileFolder={fileFolder} fullView />
               </Box>
               <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  position="relative"
                  height={`calc(100% - ${height}px)`}
               >
                  {/* <Tabs
                     value={tabIndex}
                     onChange={(e, newValue) => {
                        setTabIndex(newValue);
                        setSearchValue("");
                        setSearch(false);
                        setFilteredMessages(allMessages);
                     }}
                     aria-label="project tab"
                     className={classes.tabContainer}
                  >
                     <Tab
                        label="COMMENTS"
                        id={`simple-tab-${0}`}
                        aria-controls={`project-tabpanel-${0}`}
                     />
                     <Tab
                        label="FILE INFO"
                        id={`simple-tab-${1}`}
                        aria-controls={`project-tabpanel-${1}`}
                     />
                  </Tabs> */}
                  {/* <TabPanel value={tabIndex} index={0} className={classes.tab}> */}
                  <FileTags fileFolder={fileData?.getFileFolder} />
                  <Divider sx={{ margin: "0 20px" }} />
                  <GeneralInfo fileFolder={fileData?.getFileFolder} />
                  {/* </TabPanel> */}
                  {/* <TabPanel value={tabIndex} index={0} className={classes.tab}>
                      TODO: comments 
                  </TabPanel> */}
               </Box>
            </div>
         </div>
      </AppLayout>
   );
};

export default FilePage;
