import Button from "@mui/material/Button";
import Card from "@mui/material/Card";

import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik, Form } from "formik";
import { GET_CATEGORIES, UPDATE_PROFILE_CATEGORIES } from "_apollo/queries";
import Category from "_components/Category";
import SuggestionsCategories from "_components/SuggestionsCategories/SuggestionsCategories";

/**
 * Category Form
 * @param {object} props props
 * @param {object} props.user User object
 * @param {boolean} props.showSubtitle Show subtitle.
 * @param {number} props.teamId teamId
 * @param {number} props.id id
 * @returns {JSX.Element} Category form component.
 */
const CategoryForm = ({ user, showSubtitle = false, teamId, id }) => {
   const categoryFrom = useRef();

   const [categories, setCategories] = useState([]);
   const [deletedCategories, setDeletedCategories] = useState([]);
   const [categoriesSearch, setCategoriesSearch] = useState([]);
   const [categoriesDefault, setCategoriesDefault] = useState([]);

   useQuery(GET_CATEGORIES, {
      /**
       * getCategories onCompleted
       * @param {object} data data
       * @param {object} data.getCategories Categories object.
       */
      onCompleted({ getCategories }) {
         setCategoriesSearch(getCategories);
         setCategoriesDefault(getCategories);
      },
   });

   const [
      updateProfileCategories,
      { loading: loadingUpdateProfileCategories },
   ] = useMutation(UPDATE_PROFILE_CATEGORIES);

   useEffect(() => {
      if (user?.profile.categories) {
         setCategories(user?.profile.categories?.map((el) => el.name));
      }
   }, [user?.profile.categories]);

   /**
    * removeCategories
    * @param {string} name Category name.
    */
   const removeCategories = (name) => {
      setDeletedCategories((prevArr) => [...prevArr, name]);
      setCategories((prevCategories) =>
         prevCategories.filter((category) => category !== name)
      );
   };

   /**
    * addCategories
    * @param {object} props props
    * @param {string} props.name Category name.
    */
   const addCategories = ({ name }) => {
      if (categories.length < 5) {
         const categoriesName = name.trim().toLowerCase();
         if (
            categories.findIndex(
               (category) => category.name === categoriesName
            ) === -1
         ) {
            setCategories((prevCategories) => [
               ...prevCategories,
               categoriesName,
            ]);
         }
      } else {
         categoryFrom?.current?.setFieldError(
            "categories",
            "You may only add 5 categories."
         );
      }
   };

   return (
      <Card className="text-left mb-3" id="settings-card">
         <Card.Header as="span" className="border-bottom-0">
            Guide Settings
         </Card.Header>

         <Card.Body>
            <Formik
               innerRef={categoryFrom}
               enableReinitialize
               initialValues={{
                  category: "",
               }}
               onSubmit={async () => {
                  await updateProfileCategories({
                     variables: {
                        categories,
                        deletedCategories,
                        teamId,
                        id,
                     },
                  });
               }}
               initialTouched={{
                  category: false,
               }}
            >
               {({
                  handleSubmit,
                  handleChange,
                  errors,
                  values,
                  setFieldValue,
                  setFieldTouched,
                  touched,
               }) => (
                  <Form.Group className="position-relative">
                     <Form.Label className="text-md-right">
                        Categories &nbsp;
                     </Form.Label>

                     {categories.length > 0 &&
                        categories?.map((category, index) => {
                           const key = `${category}-${index}`;
                           return (
                              <Category
                                 id={key}
                                 key={key}
                                 name={category}
                                 removeCategories={removeCategories}
                              />
                           );
                        })}

                     <SuggestionsCategories
                        // data={dataContacts?.userContacts}
                        categoriesSearch={categoriesSearch}
                        setCategoriesSearch={setCategoriesSearch}
                        categoriesDefault={categoriesDefault}
                        values={values}
                        categories={categories}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        addCategories={addCategories}
                        errors={errors}
                        touched={touched.category}
                        setFieldTouched={setFieldTouched}
                     />
                     {showSubtitle && (
                        <p className="text-muted text-small">
                           <small>
                              Category your profile with the music you like (Hip
                              Hop, House etc) and what you do (Producer,
                              Songwriter, A&R etc) to help us customise your Aux
                              feed.
                           </small>
                        </p>
                     )}
                     <div className="d-flex align-items-center">
                        <Button
                           variant="primary"
                           className="mx-3"
                           onClick={() => {
                              handleSubmit();
                           }}
                           disabled={loadingUpdateProfileCategories}
                        >
                           {loadingUpdateProfileCategories
                              ? "Loading..."
                              : "Update"}
                        </Button>
                     </div>
                  </Form.Group>
               )}
            </Formik>
         </Card.Body>
      </Card>
   );
};

CategoryForm.propTypes = {
   user: PropTypes.instanceOf(Object).isRequired,
   showSubtitle: PropTypes.bool.isRequired,
   teamId: PropTypes.number.isRequired,
   id: PropTypes.number.isRequired,
};

export default CategoryForm;
