import { Box, Button, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import appStorage from "_utils/appStorage";

const OutlinedButton = withStyles({
   root: {
      border: "1px solid white",
      color: "white",

      fontSize: "12px",
      "&:hover": {
         border: "1px solid white",
         color: "white",
      },
   },
})(Button);

/**
 * ErrorBanner
 * ErrorBanner is a component that displays an error banner at the top of the screen.
 * @returns {JSX.Element} The Error Banner Component.}
 */
const ErrorBanner = () => {
   const { networkError } = useSelector((state) => state.AppReducer);

   const history = useHistory();
   const [intervalSeconds, setIntervalSeconds] = useState(() => {
      const offline = appStorage.getValue({ key: "offline" }) || 1;
      return 30 * offline;
   });
   const intervalRef = useRef();

   useEffect(() => {
      const offline = appStorage.getValue({ key: "offline" }) || 0;
      appStorage.setValue({ key: "offline", value: Number(offline) + 1 });
      intervalRef.current = setInterval(() => {
         setIntervalSeconds((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(intervalRef.current);
   }, []);

   useEffect(() => {
      if (intervalSeconds === 0) {
         clearInterval(intervalRef.current);
         history.go(0);
      }
   }, [intervalSeconds]);

   const retryNetwork = () => {
      clearInterval(intervalRef.current);
      appStorage.removeValue({ key: "offline" });
   };

   useEffect(() => {
      if (!networkError) {
         retryNetwork();
      }
   }, [networkError]);

   if (!networkError) {
      return null;
   }
   return (
      <Grid container backgroundColor="strong.orange" py="8px">
         <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            padding="0px 24px"
            gap="30px"
            color="white"
         >
            <Typography variant="small">
               <Typography variant="small" component="span" fontWeight="bold">
                  App Offline
               </Typography>
               : Make sure your computer has an active and stable connection.
            </Typography>
            <span />
            <Box display="flex" gap="20px" alignItems="center">
               <Typography variant="small">
                  Retrying in {intervalSeconds} seconds.
               </Typography>
               <OutlinedButton
                  variant="outlined"
                  onClick={() => {
                     setIntervalSeconds(0);
                     retryNetwork();
                  }}
               >
                  Retry Now
               </OutlinedButton>
            </Box>
         </Grid>
      </Grid>
   );
};

export default ErrorBanner;
