/**
 * Format Date.
 * @param {Date} date timestamp.
 * @returns {Date} date on the right format
 */
const formatDate = (date) => {
   const dateData = new Date(Number(date));
   return dateData.toLocaleDateString("en-GB", {
      weekday: undefined,
      year: "2-digit",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
   });
};

export default formatDate;
