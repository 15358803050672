import React from "react";
import PropTypes from "prop-types";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import { GET_CURRENT_USER, UPDATE_CONTACT } from "_apollo/queries";
import { useMutation } from "@apollo/react-hooks";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
   labelInput: {
      marginTop: "10px",
      lineHeight: "16px",
      fontSize: theme.typography.small2.fontSize,
   },
   input: {
      marginBottom: "10px",
      width: "100%",
      [`& fieldset`]: {
         borderRadius: 0,
      },
   },
}));

const AddContactForm = ({ callback, initialValues }) => {
   const classes = useStyles();
   const [addUpdateContact] = useMutation(UPDATE_CONTACT, {
      refetchQueries: [{ query: GET_CURRENT_USER }],
      onCompleted() {
         callback();
      },
   });
   const contactValidationSchema = Yup.object().shape({
      name: Yup.string().max(255).label("Name"),
      email: Yup.string().email().trim().max(255).label("Email"),
      phone: Yup.string()
         .max(255)
         .label("Phone Number")
         .max(25)
         .test("phone-number", "Numbers only.", (value) => {
            if (!value) return true;
            const regex = new RegExp(/\d+/);
            return regex.test(value);
         }),
   });

   return (
      <Formik
         initialValues={{
            name: initialValues?.name,
            email: initialValues?.email,
            phone: initialValues?.phone,
         }}
         onSubmit={({ name, email, phone }) => {
            addUpdateContact({
               variables: {
                  id: initialValues?.id,
                  name,
                  email,
                  phone,
               },
            });
         }}
         validationSchema={contactValidationSchema}
      >
         {({ values, errors, touched, handleChange, handleSubmit }) => {
            return (
               <form onSubmit={handleSubmit}>
                  <Typography className={classes.labelInput}>Name</Typography>
                  <TextField
                     size="small"
                     className={classes.input}
                     variant="outlined"
                     fullWidth
                     id="name"
                     name="name"
                     placeholder="Name"
                     value={values.name}
                     onChange={handleChange}
                     error={touched.name && Boolean(errors.name)}
                     helperText={touched.name && errors.name}
                  />
                  <Typography className={classes.labelInput}>Email</Typography>
                  <TextField
                     size="small"
                     className={classes.input}
                     variant="outlined"
                     fullWidth
                     id="email"
                     name="email"
                     placeholder="Email"
                     value={values.email}
                     onChange={handleChange}
                     error={touched.email && Boolean(errors.email)}
                     helperText={touched.email && errors.email}
                  />
                  <Typography className={classes.labelInput}>
                     Phone Number
                  </Typography>
                  <TextField
                     size="small"
                     className={classes.input}
                     variant="outlined"
                     fullWidth
                     id="phone"
                     name="phone"
                     placeholder="Phone Number"
                     value={values.phone}
                     onChange={handleChange}
                     error={touched.phone && Boolean(errors.phone)}
                     helperText={touched.phone && errors.phone}
                  />
                  <Box justifyContent="center" display="flex">
                     <Button
                        disabled={
                           !values.email && !values.name && !values.phone
                        }
                        type="submit"
                        variant="contained"
                     >
                        {initialValues?.id ? "Update" : "Add"}
                     </Button>
                  </Box>
               </form>
            );
         }}
      </Formik>
   );
};

export default AddContactForm;
AddContactForm.propTypes = {
   callback: PropTypes.func,
   initialValues: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
   }),
};

AddContactForm.defaultProps = {
   callback: () => null,
   initialValues: null,
};
