import { Grid, Typography, Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import PastelGrad from "_assets/PastelGrad";
import DarkButton from "_components/Buttons/DarkButton";
import PropTypes from "prop-types";
import isElectronHook from "_utils/isElectron";

const useStyles = makeStyles((theme) => ({
   root: {
      background: `linear-gradient(249.61deg, #6147FF 0%, rgba(97, 71, 255, 0) 50%),
         linear-gradient(-43.46deg, #78FCE4 15.62%, rgba(12, 236, 196, 0) 52.75%),
         linear-gradient(90deg, #DCB0FF 1.46%, rgba(220, 176, 255, 0) 100%)`, // errorBackground,
      height: "100vh",
      position: "relative",
      "& h2": {
         fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
         fontSize: 76,
         fontWeight: "bold",
      },
      /* Allow the whole window to be dragged around when full screen error is showing */
      "-webkit-app-region": "drag",
   },
   br: {
      marginTop: "30px",
      content: `""`,
      display: "block",
   },
   darkButton: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "8px 12px",
      gap: "10px",
      position: "absolute",
      width: "70px",
      height: "36px",
   },
   // Click events are eaten by the -webkit-app-region: drag; so we need to disable it for anything that is interactive.
   noDrag: {
      ...theme.noDrag,
   },
}));

/**
 * ErrorPage
 * Error page for when something goes wrong.
 * @param {object} root0 props.
 * @param {string} root0.error error.
 * @returns {JSX.Element} ErrorPage component.
 */
const ErrorPage = ({ error }) => {
   const classes = useStyles();
   const isElectron = isElectronHook();

   return (
      <Grid
         container
         className={classes.root}
         justifyContent="center"
         alignItems="center"
      >
         <Box position="absolute" display="flex" gap="34px" top={150} left={96}>
            <PastelGrad />
            <PastelGrad />
            <PastelGrad />
         </Box>
         <Box width="36%" zIndex={2} className={classes.noDrag}>
            <Typography
               component="h2"
               sx={{ lineHeight: "1em", marginBottom: "30px" }}
            >
               Oops something went wrong!
            </Typography>
            <Typography variant="large" component="p" mt="15px" mb="20px">
               The error has been sent to the tech team to fix.
               <br className={classes.br} />
               {error?.message?.slice().split("\n").length > 0 && (
                  <span style={{ userSelect: "auto" }}>
                     Error message: {error?.message?.slice().split("\n")[0]}
                  </span>
               )}
               <br className={classes.br} />
               To get help give feedback on your experience with the app{" "}
               <Button
                  component="a"
                  href={`mailto:hello@aux.app?subject=Aux Error&body=${error?.message}`}
                  style={{ marginLeft: "-10px" }}
                  variant="text"
                  color="primary"
                  target="_blank"
               >
                  <Typography variant="large">email the Aux team.</Typography>
               </Button>
            </Typography>
            <DarkButton
               className={`${classes.darkButton} ${classes.noDrag}`}
               variant="contained"
               size="large"
               onClick={() => {
                  if (isElectron) {
                     window.electronAPI.reload();
                  } else {
                     window.history.go(0);
                  }
               }}
            >
               Reload
            </DarkButton>
         </Box>
         <Box
            position="absolute"
            display="flex"
            gap="34px"
            bottom={150}
            right={96}
         >
            <PastelGrad />
            <PastelGrad />
            <PastelGrad />
         </Box>
      </Grid>
   );
};

ErrorPage.propTypes = {
   error: PropTypes.string.isRequired,
};

export default ErrorPage;
