import React from "react";
import AppLayout from "_layouts/AppLayout";
import FilesListings from "./FilesListings";

/**
 * Files Listing Page
 * @returns {JSX.Element} File listing page JSX.
 */
function FilesListingsPage() {
   return (
      <AppLayout>
         <FilesListings />
      </AppLayout>
   );
}

export default FilesListingsPage;
