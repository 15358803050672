import { Button, Chip, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { isLightMode } from "themeHelpers";
import { useTheme } from "@mui/styles";
import SoundsSearchForm from "./SoundsSearchForm";
import SamplePlayer from "./SamplePlayer";
import SoundsRecordUploadForm from "./SoundsRecordUploadForm";

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: "100%",
    width: "100%",
    background:
      isLightMode &&
      "radial-gradient(63.18% 63.49% at 50.05% -18.92%, #C8BFFF 0%, rgba(255, 255, 255, 0.00) 100%)",
  },
  contentsParent: {},
  contentsWrapper: {
    textAlign: "center",
    maxWidth: "500px",
    margin: "0 auto",
    padding: "40px 24px",
  },
  searchDescription: {
    fontSize: "14px",
    lineHeight: "20px",
    margin: "19px auto 27px auto",
  },
  promptSuggestions: {
    marginTop: 24,
  },
  // Two column grid
  suggestedSounds: {
    display: "block",
    textAlign: "center",
    marginTop: "40px",
    padding: "0 30px 30px 30px",
  },
  suggestedSoundsDescription: {
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "24px",
  },
  suggestedSoundsListings: {
    display: "grid",
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    },
    gap: "16px 24px",
    justifyContent: "center",
    "& > div": {
      height: "92px",
    },
  },
}));

const allSuggestedPrompts = [
  "jungle breakbeat 120bpm",
  "upbeat dance pop rnb",
  "synth chords in b major",
  "slow downtempo melodic techno with breaks mixing various genres in each track",
  "single shot snare drum",
  "soft piano chords",
  "energetic hip hop loop with drums keys and horns",
  "jazz hip hop loop that sounds dusty 90bpm",
  "funky thick synth bass lines for hip hop and funk major key 90bpm no drums",
  "dubstep snare",
  "dubstep bass and stabs no drums",
  "distorted 808 bassline",
  "classic deep house drums 125 bpm with congas",
  "clap",
  "cinematic chord progression intense 4 bars in evolving pads",
  "chill lofi drums that sounds like the future",
  "boom bap drum loop with rhodes for hip hop track 90bpm",
  "bongo beat calypso",
  "an alien forest soundscape bird song water leaves wind waves",
  "alternative guitar",
  "acoustic guitar smooth jazz",
  "acid techo bass line with drums 130bpm",
  "1960s classic drum breakbeat 80 bpm",
  "808 drum beats",
  "60s classic slow funky drum breakbeat",
];

const allSuggestedSounds = [
  {
    prompt: "upbeat dance pop rnb",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/upbeat-dance-pop-r-b.wav",
  },
  {
    prompt: "synth chords in b major",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/synth-chords-in-b-major.wav",
  },
  {
    prompt:
      "slow downtempo melodic techno with breaks mixing various genres in each track",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/slow-downtempo-melodic-techno-with-breaks-mixing-various-genres-in-each-track.wav",
  },
  {
    prompt: "single shot snare drum",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/single-shot-snare-drum.wav",
  },
  {
    prompt: "soft piano chords",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/sample_soft-piano-chords.wav",
  },
  {
    prompt: "energetic hip hop loop with drums keys and horns",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/sample_energetic-hip-hop-loop-with-drums-keys-and-horns.wav",
  },
  {
    prompt: "jazz hip hop loop that sounds dusty 90bpm",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/jazz-hip-hop-loop-that-sounds-dusty-90bpm.wav",
  },
  {
    prompt:
      "funky thick synth bass lines for hip hop and funk major key 90bpm no drums",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/funky-thick-synth-bass-lines-for-hip-hop-and-funk-major-key-90bpm-no-drums.wav",
  },
  {
    prompt: "dubstep snare",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/dubstep-snare.wav",
  },
  {
    prompt: "dubstep bass and stabs no drums",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/dubstep-bass-and-stabs-no-drums.wav",
  },
  {
    prompt: "distorted 808 bassline",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/distorted-808-bassline.wav",
  },
  {
    prompt: "classic deep house drums 125 bpm with congas",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/classic-deep-house-drums-125-bpm-with-congas.wav",
  },
  {
    prompt: "clap",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/clap.wav",
  },
  {
    prompt: "cinematic chord progression intense 4 bars in evolving pads",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/cinematic-chord-progression-intense-4-bars-in-evolving-pads.wav",
  },
  {
    prompt: "chill lofi drums that sounds like the future",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/chill-lofi-drums-that-sounds-like-the-future.wav",
  },
  {
    prompt: "boom bap drum loop with rhodes for hip hop track 90bpm",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/boom-bap-drum-loop-with-rhodes-for-hip-hop-track-90bpm.wav",
  },
  {
    prompt: "bongo beat calypso",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/bongo-beat-calypso.wav",
  },
  {
    prompt: "an alien forest soundscape bird song water leaves wind waves",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/an-alien-forest-soundscape-bird-song-water-leaves-wind-waves.wav",
  },
  {
    prompt: "alternative guitar",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/alternative-guitar.wav",
  },
  {
    prompt: "acoustic guitar smooth jazz",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/acoustic-guitar-smooth-jazz.wav",
  },
  {
    prompt: "acid techo bass line with drums 130bpm",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/acid-techo-bass-line-with-drums-130bpm.wav",
  },
  {
    prompt: "1960s classic drum breakbeat 80 bpm",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/1960s-classic-drum-breakbeat-80-bpm.wav",
  },
  {
    prompt: "808 drum beats",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/808-drum-beats.wav",
  },
  {
    prompt: "60s classic slow funky drum breakbeat",
    url: "https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/sounds-suggested-samples/1.0/60s-classic-slow-funky-drum-breakbeat.wav",
  },
];

/**
 * SoundsSearch
 *
 * Screen that shows search bar and suggested prompts for generating sounds.
 *
 * @param {object} props props
 * @param {boolean} props.loading Overall loading state.
 * @param {Array<string>} props.samples List of generated samples.
 * @param {string} props.prompt Current prompt value.
 * @param {Function} props.setPrompt Set prompt value.
 * @param {number} props.samplesGenerating Number of samples still being generated.
 * @param {Function} props.generateSounds Generate sounds function.
 * @param {Function} props.abortAllRequestsAndReset Abort all requests function.
 * @returns {JSX.Element} Sounds Search screen
 */
const SoundsSearch = ({
  loading,
  samples,
  prompt,
  setPrompt,
  samplesGenerating,
  generateSounds,
  abortAllRequestsAndReset,
}) => {
  const classes = useStyles();
  const theme: any = useTheme();

  // Simulate progress bar taking about 40 seconds to reach 100 using setInterval.
  const [generationProgress, setGenerationProgress] = useState(0);
  useEffect(() => {
    let interval;
    if (samplesGenerating > 0) {
      interval = setInterval(() => {
        setGenerationProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(interval);
            return 100;
          }
          return oldProgress + 1;
        });
      }, 500);
    }
    if (samplesGenerating === 0) {
      setGenerationProgress(0);
    }
    return () => clearInterval(interval);
  }, [samplesGenerating]);

  // Choose three random suggestions each page load.
  const [suggestedPrompts, setSuggestedPrompts] = useState([]);
  useEffect(() => {
    const randomPrompts = [];
    while (randomPrompts.length < 4) {
      const randomPrompt =
        allSuggestedPrompts[
          Math.floor(Math.random() * allSuggestedPrompts.length)
        ];
      if (!randomPrompts.includes(randomPrompt)) {
        randomPrompts.push(randomPrompt);
      }
    }
    setSuggestedPrompts(randomPrompts);
  }, []);

  // Choose four random suggested sounds each page load.
  const [suggestedSounds, setSuggestedSounds] = useState([]);
  useEffect(() => {
    const randomSounds = [];
    while (randomSounds.length < 4) {
      const randomSound =
        allSuggestedSounds[
          Math.floor(Math.random() * allSuggestedSounds.length)
        ];
      if (!randomSounds.includes(randomSound)) {
        randomSounds.push(randomSound);
      }
    }
    setSuggestedSounds(randomSounds);
  }, []);

  const [currentlyPlayingID, setCurrentlyPlayingID] = useState(null);

  return (
    <div className={classes.root}>
      <div className={classes.contentsParent}>
        <div className={classes.contentsWrapper}>
          {samplesGenerating > 0 ? (
            <>
              <div className={classes.searchDescription}>
                Hold tight! Generating never before heard sounds...
                <br />
                This usually takes 30 seconds
              </div>

              <LinearProgress
                variant="determinate"
                value={generationProgress}
                sx={{
                  height: "4px",
                  borderRadius: "4px",
                  width: "240px",
                  margin: "0 auto 44px auto",
                }}
              />

              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setPrompt("");
                  abortAllRequestsAndReset();
                }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <div className={classes.searchDescription}>
                Create royalty free sounds to use in your own tracks, record an
                idea and enter a prompt to generate new sounds:
              </div>

              <SoundsRecordUploadForm />

              <SoundsSearchForm
                loading={loading}
                samples={samples}
                generateSounds={generateSounds}
                prompt={prompt}
                setPrompt={setPrompt}
                abortAllRequestsAndReset={abortAllRequestsAndReset}
              />

              <div className={classes.promptSuggestions}>
                {suggestedPrompts.map((suggestedPrompt) => {
                  const chipColor = prompt === suggestedPrompt ? "#5740E6" : "";
                  const chipTextColor =
                    prompt === suggestedPrompt
                      ? "#fff"
                      : theme.palette.coreApp.text;
                  const onDeleteAction =
                    prompt === suggestedPrompt
                      ? () => setPrompt("")
                      : undefined;

                  return (
                    <Chip
                      key={suggestedPrompt}
                      onClick={() => setPrompt(suggestedPrompt)}
                      label={suggestedPrompt}
                      size="small"
                      sx={{
                        margin: "0 6px 7px 0",
                        backgroundColor: chipColor,
                        color: chipTextColor,
                        fontSize: "12px",
                        "&:hover": {
                          color: theme.palette.coreApp.text,
                        },
                        "& svg": {
                          fill: "#fff",
                        },
                      }}
                      onDelete={onDeleteAction}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>

        {samplesGenerating === 0 && (
          <div className={classes.suggestedSounds}>
            <div className={classes.suggestedSoundsDescription}>
              Or generate new samples based on one of these sounds curated by
              the Aux team:
            </div>

            <div className={classes.suggestedSoundsListings}>
              {suggestedSounds.map((suggestedSound, index) => {
                return (
                  <SamplePlayer
                    id={index}
                    key={suggestedSound.url}
                    title={suggestedSound.prompt}
                    sample={{ signed_url: suggestedSound.url }}
                    currentlyPlayingID={currentlyPlayingID}
                    setCurrentlyPlayingID={setCurrentlyPlayingID}
                    generateSounds={generateSounds}
                    disableDownloads
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SoundsSearch;
