import { useMutation } from "@apollo/react-hooks";
import { STATS_MUTATION } from "../_apollo/queries";

/**
 * Stats Mutation
 *
 * Hook which will send the utm tags of the session with the params pass to the function.
 *
 * This hook should be called on: every stats request.
 * @param {object} params the stats mutation params.
 * @returns {Function} sendStatsMutation Send stats mutation with utm tags.
 */
function useStatsMutation(params) {
   const [statsMutation] = useMutation(STATS_MUTATION, params);

   /**
    * Send Stats Mutation
    * @param {object} rest the stats mutation params to pass through.
    * @param {object} options the options to pass through.
    */
   const sendStatsMutation = async (rest, options) => {
      statsMutation({
         variables: {
            stats: {
               utmMedium: sessionStorage.getItem("utmMedium"),
               utmSource: sessionStorage.getItem("utmSource"),
               utmCampaign: sessionStorage.getItem("utmCampaign"),
               utmContent: sessionStorage.getItem("utmContent"),
               ...rest,
            },
         },
         ...options,
      });
   };
   return sendStatsMutation;
}

export default useStatsMutation;
