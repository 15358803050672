import React from "react";
import PropTypes from "prop-types";

/**
 * PastelGrad
 * SVG X for the pastel gradient background.
 * @param {object} props Component props.
 * @param {object} props.style style object to be applied to the svg element.
 * @returns {JSX.Element} PastelGrad component.}
 */
const PastelGrad = ({ style }) => {
   return (
      <svg
         style={style}
         width="74"
         height="74"
         viewBox="0 0 74 74"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M28.6983 37L0.491239 65.2071C-0.163746 65.8621 -0.163746 66.924 0.491238 67.579L6.42101 73.5088C7.07599 74.1637 8.13793 74.1637 8.79292 73.5088L37 45.3017L65.2071 73.5088C65.8621 74.1637 66.924 74.1637 67.579 73.5088L73.5088 67.579C74.1637 66.924 74.1637 65.8621 73.5088 65.2071L45.3017 37L73.5087 8.79292C74.1637 8.13794 74.1637 7.076 73.5087 6.42101L67.579 0.491238C66.924 -0.163747 65.862 -0.163745 65.2071 0.49124L37 28.6983L8.79294 0.491239C8.13795 -0.163746 7.07601 -0.163746 6.42103 0.491239L0.491252 6.42101C-0.163733 7.076 -0.163732 8.13794 0.491253 8.79293L28.6983 37Z"
            fill="white"
         />
      </svg>
   );
};

PastelGrad.propTypes = {
   style: PropTypes.shape(),
};

PastelGrad.defaultProps = {
   style: null,
};

export default PastelGrad;
