import React, { useRef, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  GET_TAGS_FILE_FOLDER,
  GET_TAGS,
  ADD_TAG_FILE_FOLDER,
  DELETE_TAG_FILE_FOLDER,
} from "_apollo/queries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Skeleton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  noTags: {
    fontSize: "0.8rem",
    lineHeight: "1rem",
  },
  container: {
    padding: "20px 24px",
  },
  titleButton: {
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    border: "none",
    cursor: "pointer",
    overflow: "hidden",
    outline: "none",
    color: theme.palette.coreApp.text,
    lineHeight: "20px",
    fontSize: "14px",
    fontWeight: "700",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
  },
  iconButton: {
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    border: "none",
    cursor: "pointer",
    overflow: "hidden",
    outline: "none",
    padding: "0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  wrapperTags: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "12px 0px",
  },
  chip: {
    backgroundColor: "#f2f2f2",
    borderRadius: "16px",
    padding: "5px 8px",
    color: "#666",
    lineHeight: "16px",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#5740e6",
      color: "white",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  input: {
    backgroundColor: "#f2f2f2",
    border: "none",
    cursor: "pointer",
    overflow: "hidden",
    outline: "none",
    padding: "2px 8px",
    fontSize: "12px",
    lineHeight: "16px",
    height: "20px",
    minWidth: "166px",
  },
  suggestions: {
    position: "absolute",
    top: "21px",
    left: "0px",
    right: "0px",
    borderRadius: "4px",
    background: "#f6f6f6",
    boxShadow:
      "3px 5px 5px 0px rgba(102, 102, 102, 0.15), -1px -1px 3px 0px rgba(102, 102, 102, 0.1)",
    overflow: "hidden",
  },
  suggestion: {
    display: "flex",
    padding: "2px 35px 2px 20px",
    alignItems: "center",
    alignSelf: "stretch",
    fontSize: "12px",
    fontStyle: "normal",
    lineHeight: "16px",
    "&:hover": {
      background: "#5740e6",
      color: "white",
    },
  },
}));

/**
 * File Tags.
 *
 * Component used of the file info tabs.
 * @param {object} root0 props.
 * @param {object} root0.fileFolder object with a file or a folder.
 * @param {boolean} root0.hideArrow hide arrow on collapse tags box.
 * @param {boolean} root0.removeSpacing hide remove horizontal padding of container.
 * @returns {JSX.Element} FileTags component.
 */
function FileTags({ fileFolder, hideArrow, removeSpacing }) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const { data, loading } = useQuery(GET_TAGS_FILE_FOLDER, {
    variables: {
      id: fileFolder?.file?.id || fileFolder?.folder?.id,
      isFile: !!fileFolder?.file,
    },
    skip: !fileFolder?.file && !fileFolder?.folder,
  });

  const [addTagFileFolder] = useMutation(ADD_TAG_FILE_FOLDER, {
    /**
     * update cache after add tag.
     * @param {object} cache cache.
     * @param {object} param1 data response with errors.
     * @param {object} param1.data data response of addTagFileFolder.
     */
    update(cache, { data: res }) {
      if (res?.addTagFileFolder) {
        const cacheDeleteTagFileFolder = cache.readQuery<any>({
          query: GET_TAGS_FILE_FOLDER,
          variables: {
            id: fileFolder?.file?.id || fileFolder?.folder?.id,
            isFile: !!fileFolder?.file,
          },
        });

        cache.writeQuery({
          query: GET_TAGS_FILE_FOLDER,
          variables: {
            id: fileFolder?.file?.id || fileFolder?.folder?.id,
            isFile: !!fileFolder?.file,
          },
          data: {
            getTagsFileFolder: [
              ...cacheDeleteTagFileFolder?.getTagsFileFolder,
              res?.addTagFileFolder,
            ],
          },
        });
      }
    },
  });
  const [deleteTagFileFolder] = useMutation(DELETE_TAG_FILE_FOLDER, {
    /**
     * update cache after delete a tag.
     * @param {object} cache cache.
     * @param {object} param1 data response with errors.
     * @param {object} param1.data data response of deleteTagFileFolder.
     */
    update(cache, { data: res }) {
      if (res?.deleteTagFileFolder) {
        const cacheDeleteTagFileFolder = cache.readQuery<any>({
          query: GET_TAGS_FILE_FOLDER,
          variables: {
            id: fileFolder?.file?.id || fileFolder?.folder?.id,
            isFile: !!fileFolder?.file,
          },
        });

        cache.writeQuery({
          query: GET_TAGS_FILE_FOLDER,
          variables: {
            id: fileFolder?.file?.id || fileFolder?.folder?.id,
            isFile: !!fileFolder?.file,
          },
          data: {
            getTagsFileFolder:
              cacheDeleteTagFileFolder?.getTagsFileFolder.filter(
                (n) => n.id !== res?.deleteTagFileFolder?.id
              ),
          },
        });
      }
    },
  });

  const [suggestions, setSuggestions] = useState([]);
  const [valueInput, setValueInput] = useState("");
  const [getTags] = useLazyQuery(GET_TAGS, {
    /**
     * getTags onCompleted
     * @param {object} response Apollo response object.
     */
    onCompleted(response) {
      setSuggestions(response.getTags);
    },
  });
  const timer = useRef(null);

  /**
   * Handle Tag Change
   * @param {object} e Event object.
   */
  const handleTagChange = (e) => {
    setValueInput(e.target.value);
    clearTimeout(timer.current);
    // Search for tags
    if (e.target.value !== "") {
      timer.current = setTimeout(() => {
        getTags({
          variables: {
            name: e.target.value,
          },
        });
      }, 1000);
    } else {
      setSuggestions([]);
    }
  };

  /**
   * Chip Tag
   *
   * @param {object} param0 props
   * @param {object} param0.tag a tag.
   * @returns {JSX.Element} chimp component with handlers.
   */
  const ChipTag = ({ tag }) => {
    const [hover, setHover] = useState(false);
    return (
      <div
        className={classes.chip}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {tag.name}
        {hover ? (
          <button
            className={classes.iconButton}
            type="button"
            onClick={() => {
              setSuggestions([]);
              deleteTagFileFolder({
                variables: {
                  idFileFolder: fileFolder?.file?.id || fileFolder?.folder?.id,
                  isFile: !!fileFolder?.file,
                  tagId: tag.id,
                },
              });
            }}
            aria-label="Delete Tag"
          >
            &nbsp;
            <CancelIcon sx={{ height: "16px", fill: "#c8bfff" }} />
          </button>
        ) : null}
      </div>
    );
  };

  return (
    <div
      style={removeSpacing ? { padding: "20px 0px" } : {}}
      className={classes.container}
    >
      <div className={classes.header}>
        <button
          className={classes.titleButton}
          type="button"
          onClick={() => setOpen((a) => !a)}
        >
          Tags
          {!hideArrow && (
            <>
              &nbsp;&nbsp; {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </>
          )}
        </button>
        {data?.getTagsFileFolder?.length < 5 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <input
              className={classes.input}
              placeholder="add a tag"
              onChange={handleTagChange}
              value={valueInput}
              onKeyDown={(e) => {
                // Can not add more than 5 tags.
                if (e.key === "Enter" && data?.getTagsFileFolder.length < 5) {
                  addTagFileFolder({
                    variables: {
                      id: fileFolder?.file?.id || fileFolder?.folder?.id,
                      isFile: !!fileFolder?.file,
                      name: (e.target as HTMLInputElement).value,
                    },
                  });
                  setSuggestions([]);
                  setValueInput("");
                  (e.target as HTMLInputElement).value = "";
                }
              }}
            />
            <div className={classes.suggestions}>
              {suggestions.map((tag) => {
                return (
                  <div
                    key={tag.id}
                    className={classes.suggestion}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        addTagFileFolder({
                          variables: {
                            id: fileFolder?.file?.id || fileFolder?.folder?.id,
                            isFile: !!fileFolder?.file,
                            name: tag.name,
                          },
                        });
                        handleTagChange({ target: { value: tag.name } });
                      }
                    }}
                    onClick={() => {
                      addTagFileFolder({
                        variables: {
                          id: fileFolder?.file?.id || fileFolder?.folder?.id,
                          isFile: !!fileFolder?.file,
                          name: tag.name,
                        },
                      });
                      handleTagChange({ target: { value: tag.name } });
                    }}
                  >
                    {tag.name}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {open && (
        <div className={classes.wrapperTags}>
          {loading && (
            <>
              <div className={classes.chip}>
                <Skeleton width={40} />
              </div>
              <div className={classes.chip}>
                <Skeleton width={40} />
              </div>
            </>
          )}
          {!loading &&
            data?.getTagsFileFolder?.length > 0 &&
            data?.getTagsFileFolder?.map((tag) => {
              return <ChipTag key={tag.id} tag={tag} />;
            })}
          {!loading && data?.getTagsFileFolder?.length === 0 && (
            <div className={classes.noTags}>
              Add tags to{" "}
              {fileFolder?.file?.originalName || fileFolder?.folder?.name}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default FileTags;
