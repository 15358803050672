import {
   Box,
   Button,
   Checkbox,
   CircularProgress,
   Divider,
   FormControlLabel,
   FormHelperText,
   Grid,
   List,
   ListItem,
   ListItemText,
   TextField,
   Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { CheckCircle, ChevronLeft } from "@mui/icons-material";
import { SubscriptionPlans } from "_constants/GlobalVariables";
import DangerButton from "_components/DangerButton";
import { makeStyles, useTheme } from "@mui/styles";
import {
   UPDATE_SUBSCRIPTION,
   REGISTER_STRIPE,
   UPDATE_CARD,
   SETUP_INTENT,
   CONFIRM_PAYMENT,
} from "_apollo/queries";
import { useMutation } from "@apollo/react-hooks";
import { useUser } from "_utils/UserContext";
import getUsersPlan from "_utils/getUsersPlan";
import { Formik } from "formik";
import * as Yup from "yup";
import checkUsersSubscriptionPlan from "_utils/checkUsersSubscriptionPlan";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import * as Sentry from "@sentry/react";
import gtag from "_utils/gtag";
import ReactPixel from "react-facebook-pixel";
import CustomModal from "./CustomModal";

const useStyles = makeStyles((theme) => ({
   featureList: {
      padding: 0,
      "& li": {
         padding: 0,
         marginBottom: "14px",
         "& div": {
            margin: 0,
         },
      },
   },
   title: {
      marginBottom: "8px",
      fontWeight: "700",
      lineHeight: "1.5rem",
      fontSize: "1.125rem",
      color: theme.palette.primary.main,
   },
   description: {
      marginTop: "0px",
      marginBottom: "16px",
      lineHeight: "1.25rem",
      fontSize: "0.875rem",
      color: theme.palette.secondary.shade60,
   },
   trialQuote: {
      lineHeight: "1rem",
      fontSize: "0.75rem",
      marginTop: "8px",
      marginBottom: "16px",
      color: theme.palette.secondary.shade60,
   },
   label: {
      lineHeight: "1.125rem",
      fontSize: "0.875rem",
      marginBottom: "2px",
      fontWeight: 700,
   },
   inputForm: {
      minWidth: "420px",
      "& .MuiInputBase-root": {
         backgroundColor: "white",
         "& fieldset": {
            border: "solid 1px #D9D9D9",
            borderRadius: 4,
         },
      },
   },
   price: {
      lineHeight: "1.5rem",
      fontSize: "1.125rem",
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
      gap: "4px",
   },
   recurrence: {
      lineHeight: "1rem",
      fontSize: "0.75rem",
      fontWeight: 400,
   },
   cardInputContainer: {
      marginLeft: "26px",
   },
   cardFormLabel: {
      padding: "15px 0 8px",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 700,
   },
   termsLabel: {
      fontSize: "14px",
      lineHeight: "20px",
   },
   termsLabelLink: {
      textDecoration: "none",
      color: theme.palette.primary.main,
   },
   stripeElement: {
      padding: "12px 9px",
      border: "solid 1px #D9D9D9",
      borderRadius: 4,
      backgroundColor: "white",
      "&:hover": {
         border: "solid 1px black",
      },
      "&.StripeElement--focus": {
         outline: `solid 2px ${theme.palette.primary.main}`,
         "&:hover": {
            border: "solid 1px #D9D9D9",
         },
      },
   },
}));

const UpgradeSubscriptionModal = ({
   open,
   handleClose,
   title,
   description,
}) => {
   const [user] = useUser();
   const { ArrayPlans, Features } = SubscriptionPlans;
   const theme = useTheme();
   const [showCardInput, setShowCardInput] = useState(false);
   const classes = useStyles();
   const [selectedPlan, setSelectedPlan] = useState(null);
   const [openFailedPlanUpdate, setOpenFailedPlanUpdate] = useState(false);
   const validationSchema = Yup.object().shape({
      name: Yup.string()
         .min(3)
         .max(128)
         .trim()
         .required()
         .matches(
            /^((?:[A-Za-z]+ ?){1,4})$/,
            "Your name should not include any special characters."
         )
         .label("Cardholder's Name"),
      quantity: Yup.number().min(
         checkUsersSubscriptionPlan(
            [SubscriptionPlans.PlanModalEnum.TEAM],
            selectedPlan?.id
         )
            ? 5
            : 1,
         "You must have at least 5 team members."
      ),
      zip: Yup.string().required().max(50).label("Zip"),
      coupon: Yup.string().max(50).label("Coupon"),
      terms: Yup.bool().oneOf(
         [true],
         "You must accept the subscription terms."
      ),
   });
   const formRef = useRef();
   const [loadingSubscribeRequest, setLoadingSubscribeRequest] =
      useState(false);
   const [openSuccessPlanUpdateModal, setOpenSuccessPlanUpdateModal] =
      useState(false);
   const [recurrence, setRecurrence] = useState("Monthly");

   // Fetch the users country by IP on first load.
   const [userIPCurrency, setUserIPCurrency] = useState("USD");
   useEffect(() => {
      try {
         fetch("https://geoip-internal.aux.app/")
            .then((response) => response.json())
            .then((data) => {
               if (data?.country && data?.eu) {
                  if (data.eu === "1") {
                     setUserIPCurrency("EUR");
                  }
                  if (data.country === "GB") {
                     setUserIPCurrency("GBP");
                  }
               }
            });
      } catch {
         // fail silently falling back to USD
      }
   }, []);

   const filteredArrayPlans = useMemo(() => {
      let currentSubscription = getUsersPlan(user?.currentBillingPlan);
      if (currentSubscription && currentSubscription.label === "Free") {
         currentSubscription = null;
      }
      if (currentSubscription) {
         const filteredPlans = ArrayPlans.filter(
            (ap) =>
               ap.type === recurrence &&
               ap.label !== "Free" &&
               currentSubscription.currency === ap.currency
         );
         if (selectedPlan?.type !== recurrence) {
            setSelectedPlan(
               filteredPlans.find(
                  (plan) =>
                     plan.type === recurrence &&
                     plan.label === selectedPlan?.label
               )
            );
         }
         // Set the next plan up if the user is upgrading.
         const nextPlan = filteredPlans.find(
            (plan) => plan.id >= currentSubscription.id
         );
         setSelectedPlan(nextPlan);
         // Return the filtered plans.
         return filteredPlans;
      }
      const filteredPlans = ArrayPlans.filter(
         (ap) =>
            ap.type === recurrence &&
            ap.label !== "Free" &&
            userIPCurrency === ap.currency
      );
      // Check if selected plan has different currency than user's IP
      if (
         (selectedPlan?.currency !== userIPCurrency || !selectedPlan) &&
         filteredPlans.length > 0 &&
         !user?.currentBillingPlan
      ) {
         setSelectedPlan(filteredPlans[2]);
         // Check if the recurrence is different than the current plan and if it is select it
      } else if (selectedPlan?.type !== recurrence) {
         setSelectedPlan(
            filteredPlans.find(
               (plan) =>
                  plan.type === recurrence && plan.label === selectedPlan.label
            )
         );
      }
      return filteredPlans;
   }, [user?.currentBillingPlan, ArrayPlans, recurrence, userIPCurrency]);

   const [updateSubscriptionStripe] = useMutation(UPDATE_SUBSCRIPTION);
   const stripe = useStripe();
   const elements = useElements();
   const [setupIntent] = useMutation(SETUP_INTENT);
   const [updateCard] = useMutation(UPDATE_CARD);

   const [registerStripe] = useMutation(REGISTER_STRIPE, {
      /**
       * registerStripe onError
       */
      onError() {
         setLoadingSubscribeRequest(false);
         setOpenFailedPlanUpdate(true);
      },
   });

   const [confirmPayment] = useMutation(CONFIRM_PAYMENT, {
      /**
       * confirmPayment onCompleted
       *
       * After a confirmed payment, trigger the following events:
       * - Facebook Pixel
       * - Google Analytics
       * - Google Adwords - (via Google Tag Manager)
       */
      onCompleted() {
         setOpenSuccessPlanUpdateModal(true);
         setLoadingSubscribeRequest(false);

         // Track the Subscription conversion event
         const priceOfPlan = getUsersPlan(selectedPlan.plan)?.price;
         // Facebook
         ReactPixel.track("Subscribe", {
            value: `${priceOfPlan.toFixed(2)}`,
            currency: selectedPlan?.currency,
            predicted_ltv: `${(
               priceOfPlan * (recurrence === "Monthly" ? 12 : 2)
            ).toFixed(2)}`,
         });
         // Google Analytics
         gtag("event", "purchase", {
            event_label: "Purchase",
            event_category: "ecommerce",
            value: (priceOfPlan * (recurrence === "Monthly" ? 12 : 2)).toFixed(
               2
            ), // NOTE: Google doesn't handle currencies itself. In future we may want to adjust the value ourselves to a single currency to make this more accurate.
         });
         // Google Adwords
         gtag("event", "conversion", {
            send_to: "AW-10804925588/KB-uCInU2oYDEJSpmKAo",
            value: (priceOfPlan * (recurrence === "Monthly" ? 12 : 2)).toFixed(
               2
            ),
            currency: selectedPlan?.currency,
         });
         // Twitter
         window.twq("track", "Purchase", {
            value: (priceOfPlan * (recurrence === "Monthly" ? 12 : 2)).toFixed(
               2
            ),
            currency: selectedPlan?.currency,
         });
      },
      /**
       * registerStripe onError
       */
      onError() {
         setOpenFailedPlanUpdate(true);
         setLoadingSubscribeRequest(false);
      },
   });

   /**
    * Handle Submit Form
    * @param {object} values Form values.
    * @returns {void}
    */
   const handleSubmitForm = async (values) => {
      if (!stripe || !elements) {
         return;
      }
      setLoadingSubscribeRequest(true);
      const cardElement = elements.getElement(CardElement);
      // if the user already has a plan, we update it.
      if (user?.currentBillingPlan) {
         // Check if the user is using the same card or a different one.
         // If card element doesn't exist, it means the user is using the same card.
         if (!cardElement) {
            // Update the subscription with the same card.
            await updateSubscriptionStripe({
               variables: {
                  priceId: selectedPlan.plan,
                  newPlan: selectedPlan.label,
                  quantity: values.quantity,
                  coupon: null,
               },
            });
         }
         // TODO: Verify in another request that the card if valid before updating the subscription.
         const { data } = await setupIntent();
         const setupIntentSecret = data.setupIntent;
         const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
         });
         // If this is successful, Stripe will attach for us the payment method to the costumer object.
         const { setupIntent: setupIntentData, error: errorSetupIntent } =
            await stripe.confirmCardSetup(setupIntentSecret, {
               payment_method: paymentMethod.id,
            });
         if (errorSetupIntent || error) {
            // Show message to customer that the subscription could not be updated.
            Sentry.captureException(errorSetupIntent || error);
            setLoadingSubscribeRequest(false);
            setOpenFailedPlanUpdate(true);
         }
         if (setupIntentData.status === "succeeded") {
            // If we can use recurring charges, we can update the card.
            await updateCard({
               variables: {
                  paymentMethod: paymentMethod.id,
                  postalCode: values.zip,
               },
               // Once the card is updated we proceed to update the subscription.
               onCompleted: async () => {
                  await updateSubscriptionStripe({
                     variables: {
                        priceId: selectedPlan.plan,
                        newPlan: selectedPlan.label,
                        quantity: values.quantity,
                        coupon: null,
                     },
                  });
               },
               onError: () => {
                  setLoadingSubscribeRequest(false);
                  setOpenFailedPlanUpdate(true);
               },
            });
         }
      } else {
         // If the user is not registered create it and return a setupIntent clientSecret.
         const { data } = await registerStripe({
            variables: {
               terms: values.terms,
            },
         });
         const clientSecret = data?.registerStripe?.setupIntentSecret;

         // Once the users is registered and we asked for a secret to create a mandate, we proceed to verify the card.
         const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
         });

         if (error) {
            Sentry.captureException(error);
            setLoadingSubscribeRequest(false);
            setOpenFailedPlanUpdate(true);
            return;
         }
         // If this is successful, Stripe will attach for us the payment method to the costumer object.
         const { setupIntent: setupIntentData, error: errorSetupIntent } =
            await stripe.confirmCardSetup(clientSecret, {
               payment_method: paymentMethod.id,
            });
         if (errorSetupIntent) {
            Sentry.captureException(error);
            setLoadingSubscribeRequest(false);
            setOpenFailedPlanUpdate(true);
            return;
         }

         // If the card is valid and we can get payments from it, we proceed to create the subscription.
         if (!errorSetupIntent && setupIntentData.status === "succeeded") {
            // Let's subscribe the customer.
            await confirmPayment({
               variables: {
                  paymentMethod: paymentMethod.id,
                  postalCode: values.zip,
                  priceId: selectedPlan.plan,
                  coupon: null,
                  currentPlan: selectedPlan.label,
                  quantity: values.quantity,
               },
            });
         }
      }
      setSelectedPlan(null);
      handleClose();
   };

   // TODO: split out into separate modal subcomponents rendered conditionally to reduce complexity here.
   return (
      <CustomModal
         open={open}
         handleClose={handleClose}
         fullWidth="lg"
         header={
            <Grid
               container
               flexDirection="column"
               alignItems="center"
               justifyContent="center"
               marginBottom="30px"
            >
               <Typography
                  className={classes.title}
                  variant="header"
                  component="h3"
               >
                  {title}
               </Typography>
               <p className={classes.description}>{description}</p>
               <div
                  style={{
                     borderRadius: "24px",
                     color: "white",
                     backgroundColor: theme.palette.secondary.shade5,
                     display: "flex",
                     alignItems: "center",
                     width: "400px",
                     maxWidth: "88vw",
                     height: "30px",
                     cursor: "pointer",
                  }}
               >
                  <div
                     style={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        padding: "7px 29px",
                        borderRadius: "24px",
                        width: "50%",
                        color: recurrence !== "Monthly" ? "black" : "white",
                        textAlign: "center",
                        backgroundColor:
                           recurrence === "Monthly"
                              ? theme.palette.primary.main
                              : theme.palette.secondary.shade5,
                     }}
                     role="button"
                     tabIndex={0}
                     onClick={() => {
                        setRecurrence("Monthly");
                     }}
                     onKeyDown={(e) => {
                        if (e.key === "Enter") {
                           setRecurrence("Monthly");
                        }
                     }}
                  >
                     Monthly
                  </div>
                  <div
                     style={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        padding: "7px 29px",
                        color: recurrence === "Monthly" ? "black" : "white",
                        width: "50%",
                        textAlign: "center",
                        borderRadius: "24px",
                        backgroundColor:
                           recurrence !== "Monthly"
                              ? theme.palette.primary.main
                              : theme.palette.secondary.shade5,
                     }}
                     role="button"
                     tabIndex={0}
                     onClick={() => {
                        setRecurrence("Yearly");
                     }}
                     onKeyDown={(e) => {
                        if (e.key === "Enter") {
                           setRecurrence("Yearly");
                        }
                     }}
                  >
                     Yearly{" "}
                     <span
                        style={{
                           color:
                              recurrence === "Monthly"
                                 ? theme.palette.primary.main
                                 : theme.palette.secondary.shade5,
                        }}
                     >
                        -17% off
                     </span>
                  </div>
               </div>
            </Grid>
         }
         footer={
            <Grid
               container
               justifyContent="center"
               direction="column"
               alignItems="center"
            >
               <Box>
                  {!user?.currentBillingPlan ? (
                     <DangerButton
                        sx={{ width: "242px" }}
                        disabled={loadingSubscribeRequest}
                        onClick={async () => {
                           if (showCardInput) {
                              const errors =
                                 await formRef.current.validateForm();
                              if (Object.keys(errors).length === 0) {
                                 await handleSubmitForm(formRef.current.values);
                              }
                           } else if (user?.currentBillingPlan) {
                              await updateSubscriptionStripe({
                                 variables: {
                                    priceId: selectedPlan.plan,
                                    newPlan: selectedPlan.label,
                                    quantity: 1,
                                 },
                              });
                              setSelectedPlan(null);
                              handleClose();
                           } else {
                              setShowCardInput(true);
                           }
                        }}
                     >
                        {loadingSubscribeRequest ? (
                           <CircularProgress
                              style={{ height: "16px", width: "16px" }}
                           />
                        ) : (
                           "Start 14-day free trial"
                        )}
                     </DangerButton>
                  ) : (
                     <Button
                        variant="contained"
                        color="primary"
                        sx={{ width: "242px", marginBottom: "16px" }}
                        disabled={loadingSubscribeRequest}
                        onClick={async () => {
                           if (showCardInput) {
                              const errors =
                                 await formRef.current.validateForm();
                              if (Object.keys(errors).length === 0) {
                                 await handleSubmitForm(formRef.current.values);
                              }
                           } else if (user?.currentBillingPlan) {
                              await updateSubscriptionStripe({
                                 variables: {
                                    priceId: selectedPlan.plan,
                                    newPlan: selectedPlan.label,
                                    quantity: 1,
                                 },
                              });
                              setSelectedPlan(null);
                              handleClose();
                           } else {
                              setShowCardInput(true);
                           }
                        }}
                     >
                        {loadingSubscribeRequest ? (
                           <CircularProgress
                              style={{ height: "16px", width: "16px" }}
                           />
                        ) : (
                           "Change plan"
                        )}
                     </Button>
                  )}
               </Box>
               {!user?.currentBillingPlan && (
                  <div className={classes.trialQuote}>
                     You&apos;ll be charged after the trial, cancel anytime
                  </div>
               )}
            </Grid>
         }
      >
         <>
            {openSuccessPlanUpdateModal && (
               <CustomModal
                  fullWidth="xs"
                  open={openSuccessPlanUpdateModal}
                  handleClose={() => setOpenSuccessPlanUpdateModal(false)}
                  header={
                     <Typography color="primary" variant="modalHeader">
                        Plan Updated
                     </Typography>
                  }
                  footer={
                     <div style={{ display: "flex" }}>
                        <Button
                           data-testid="subscription-success-button"
                           style={{ margin: "auto" }}
                           variant="contained"
                           onClick={() => {
                              setOpenSuccessPlanUpdateModal(false);
                           }}
                        >
                           Let&apos;s Go!
                        </Button>
                     </div>
                  }
               >
                  <Typography
                     style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        padding: "12px 20px",
                        textAlign: "center",
                     }}
                  >
                     Your plan was successfully updated, enjoy!
                  </Typography>
               </CustomModal>
            )}
            {openFailedPlanUpdate && (
               <CustomModal
                  fullWidth="xs"
                  open={openFailedPlanUpdate}
                  handleClose={() => setOpenFailedPlanUpdate(false)}
                  header={
                     <Typography
                        style={{
                           color: theme.palette.danger.main,
                           padding: "12px 20px",
                        }}
                        variant="modalHeader"
                     >
                        Plan update failed
                     </Typography>
                  }
                  footer={
                     <div style={{ display: "flex" }}>
                        <Button
                           style={{
                              margin: "auto",
                              color: "white",
                              backgroundColor: theme.palette.danger.main,
                           }}
                           variant="contained"
                           onClick={() => {
                              setOpenFailedPlanUpdate(false);
                           }}
                        >
                           Try again
                        </Button>
                     </div>
                  }
               >
                  <Typography
                     style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        margin: "0 1em",
                     }}
                  >
                     Sorry there was a problem with your payment method, no
                     charges were made, please try again.
                  </Typography>
               </CustomModal>
            )}
            <Grid container gap="10px" justifyContent="center">
               {/* if showCardInput is true filter by selectedPlan */}
               {filteredArrayPlans
                  .filter((a) => {
                     if (showCardInput) {
                        return a.label === selectedPlan?.label;
                     }
                     return true;
                  })
                  .map((element) => {
                     const currentSubscription = ArrayPlans.find(
                        (a) => a?.plan === user?.currentBillingPlan
                     );

                     // Find all the plan before the current subscription by index on the array.
                     const previousPlans = filteredArrayPlans.filter(
                        (plan, i) =>
                           i <
                           filteredArrayPlans
                              .map((a) => a.label)
                              .indexOf(currentSubscription?.label)
                     );
                     let backgroundColor = "";
                     if (
                        previousPlans.find((plan) => plan.plan === element.plan)
                     ) {
                        backgroundColor = theme.palette.secondary.shade5;
                     } else if (selectedPlan?.plan === element.plan) {
                        backgroundColor = "rgba(87, 64, 230, 0.08)";
                     }

                     return (
                        <Box
                           key={element.plan}
                           minHeight={300}
                           width={236}
                           border={`1px solid ${
                              selectedPlan?.plan === element.plan
                                 ? theme.palette.primary.main
                                 : theme.palette.secondary.shade15
                           }`}
                           backgroundColor={backgroundColor}
                           padding="24px 18px"
                           borderRadius="6px"
                           boxSizing="border-box"
                           display="flex"
                           flexDirection="column"
                           alignItems="flex-start"
                           onClick={() => {
                              // if it is a previous plan do nothing
                              if (
                                 previousPlans.find(
                                    (plan) => plan.plan === element.plan
                                 )
                              ) {
                                 return;
                              }
                              setSelectedPlan(element);
                           }}
                        >
                           <Typography
                              className={classes.label}
                              color={
                                 selectedPlan?.plan === element.plan
                                    ? theme.palette.primary.main
                                    : theme.palette.secondary.shade60
                              }
                           >
                              {element.label}
                           </Typography>
                           <Typography
                              className={classes.price}
                              color={
                                 selectedPlan?.plan === element.plan
                                    ? theme.palette.primary.main
                                    : theme.palette.secondary.shade60
                              }
                           >
                              {`${new Intl.NumberFormat("en", {
                                 style: "currency",
                                 currency: element?.currency || "EUR",
                                 maximumFractionDigits: 0,
                                 minimumFractionDigits: 0,
                              }).format(element?.price || 0)}/ `}
                              <span
                                 className={classes.recurrence}
                                 color={
                                    selectedPlan?.plan === element.plan
                                       ? theme.palette.primary.main
                                       : theme.palette.secondary.shade60
                                 }
                              >
                                 {element.type}{" "}
                                 {element.label === "Team" ? "per member" : ""}
                              </span>
                           </Typography>
                           <Box mt="18px" mb="10px" width="100%">
                              <Divider
                                 color={theme.palette.secondary.shade15}
                              />
                           </Box>
                           <List dense className={classes.featureList}>
                              {Features[`${element.label}PlanFeatures`]
                                 .slice(0, 4)
                                 .map((el) => (
                                    <ListItem disableGutters key={el}>
                                       <CheckCircle
                                          fontSize="10px"
                                          color="primary"
                                          sx={{ marginRight: "6px" }}
                                       />
                                       <ListItemText>
                                          <Typography
                                             fontSize="12px"
                                             color={
                                                selectedPlan?.plan ===
                                                element.plan
                                                   ? theme.palette.primary.main
                                                   : theme.palette.secondary
                                                        .shade60
                                             }
                                             noWrap
                                          >
                                             {el}
                                          </Typography>
                                       </ListItemText>
                                    </ListItem>
                                 ))}
                           </List>
                           {showCardInput && (
                              <Button
                                 sx={{
                                    padding: "0",
                                    color: theme.palette.primary.main,
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    marginTop: "10px",
                                 }}
                                 variant="link"
                                 onClick={() => {
                                    setShowCardInput(false);
                                 }}
                              >
                                 <ChevronLeft
                                    sx={{
                                       height: "16px",
                                       width: "16px",
                                       marginRight: "3px",
                                    }}
                                 />{" "}
                                 <div>back to all plans</div>
                              </Button>
                           )}
                        </Box>
                     );
                  })}
               {showCardInput && (
                  <div className={classes.cardInputContainer}>
                     <Formik
                        innerRef={formRef}
                        enableReinitialize
                        initialValues={{
                           name: "",
                           zip: "",
                           coupon: "",
                           terms: false,
                           quantity: 1,
                        }}
                        validationSchema={validationSchema}
                     >
                        {({ handleChange, errors, values }) => {
                           return (
                              <form>
                                 <>
                                    <Grid container direction="column">
                                       <Grid
                                          item
                                          className={classes.cardFormLabel}
                                       >
                                          Cardholder&apos;s Name
                                       </Grid>
                                       <Grid container>
                                          <TextField
                                             data-testid="card-holder-name"
                                             className={classes.inputForm}
                                             size="small"
                                             variant="outlined"
                                             name="name"
                                             value={values.name}
                                             isInvalid={!!errors.name}
                                             error={errors.name}
                                             onChange={handleChange}
                                             helperText={errors.name}
                                          />
                                       </Grid>
                                    </Grid>
                                    <Grid container direction="column">
                                       <Grid
                                          item
                                          className={classes.cardFormLabel}
                                       >
                                          Card
                                       </Grid>
                                       <Grid container direction="column">
                                          <CardElement
                                             className={classes.stripeElement}
                                             options={{
                                                hidePostalCode: true,
                                                style: {
                                                   color: "black",
                                                   base: {
                                                      fontSize:
                                                         theme.typography.h6
                                                            .fontSize,
                                                      "::placeholder": {
                                                         color: "#aab7c4",
                                                      },
                                                   },
                                                   invalid: {
                                                      color: "#9e2146",
                                                   },
                                                },
                                             }}
                                          />
                                       </Grid>
                                    </Grid>
                                    <Grid container direction="column">
                                       <Grid
                                          item
                                          className={classes.cardFormLabel}
                                       >
                                          ZIP / Postal Code
                                       </Grid>
                                       <Grid container direction="column">
                                          <TextField
                                             data-testid="postal-code"
                                             className={classes.inputForm}
                                             size="small"
                                             variant="outlined"
                                             name="zip"
                                             value={values.zip}
                                             isInvalid={!!errors.zip}
                                             error={errors.zip}
                                             onChange={handleChange}
                                             helperText={errors.zip}
                                          />
                                       </Grid>
                                    </Grid>
                                    <Grid container direction="column">
                                       <Grid
                                          item
                                          className={classes.cardFormLabel}
                                       >
                                          <FormControlLabel
                                             label={
                                                <span
                                                   className={
                                                      classes.termsLabel
                                                   }
                                                >
                                                   I accept the{" "}
                                                   <a
                                                      target="_blank"
                                                      href="/info/subscription-terms/"
                                                      className={
                                                         classes.termsLabelLink
                                                      }
                                                   >
                                                      subscription terms
                                                   </a>
                                                </span>
                                             }
                                             variant="outlined"
                                             name="terms"
                                             value={values.terms}
                                             onChange={handleChange}
                                             control={
                                                <Checkbox data-testid="terms" />
                                             }
                                          />
                                          <Box px="14px">
                                             <FormHelperText
                                                error={errors.terms}
                                             >
                                                {errors.terms}
                                             </FormHelperText>
                                          </Box>
                                       </Grid>
                                    </Grid>
                                 </>
                              </form>
                           );
                        }}
                     </Formik>
                  </div>
               )}
            </Grid>
         </>
      </CustomModal>
   );
};

export default UpgradeSubscriptionModal;

UpgradeSubscriptionModal.propTypes = {
   open: PropTypes.bool,
   handleClose: PropTypes.func.isRequired,
};
UpgradeSubscriptionModal.defaultProps = {
   open: false,
};
