import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import formatBytes from "_utils/formatBytes";
import { getFileTypeObj } from "_utils/fileTypeUtil/fileTypeUtil";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: "20px 24px",
  },
  titleButton: {
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    border: "none",
    cursor: "pointer",
    overflow: "hidden",
    outline: "none",
    color: theme.palette.coreApp.text,
    lineHeight: "20px",
    fontSize: "14px",
    fontWeight: "700",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
  },
  wrapper: {
    display: "grid",
    gridTemplateColumns: "90px 200px",
    rowGap: "8px",
    padding: "12px 0px",
  },
  subtitle: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "700",
  },
  text: {
    fontSize: "12px",
    lineHeight: "16px",
  },
}));

/**
 * General Info.
 *
 * Component used of the file info tabs.
 * @param {object} root0 props.
 * @param {object} root0.fileFolder object with a file or a folder.
 * @returns {JSX.Element} GeneralInfo component.
 */
function GeneralInfo({ fileFolder }) {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  const fileOrFolder = fileFolder?.file || fileFolder?.folder;

  /**
   * Formats a given timestamp into a human-readable date and time string.
   *
   * @param {number} timestamp - The timestamp to be formatted. (Number of milliseconds since January 1, 1970, 00:00:00 UTC)
   * @returns {string} The formatted date and time string in the format: "day month year - hours:minutes".
   *                  Example: "28 Jul 2023 - 09:30"
   */
  function formatTimestamp(timestamp) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(timestamp);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day} ${month} ${year} - ${hours}:${minutes}`;
  }

  return (
    <div className={classes.container}>
      <button
        className={classes.titleButton}
        type="button"
        onClick={() => setOpen((a) => !a)}
      >
        General &nbsp;&nbsp; {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
      </button>

      {open && (
        <div className={classes.wrapper}>
          <div className={classes.subtitle}>Kind:</div>
          <div className={classes.text}>
            {getFileTypeObj(fileOrFolder).name}
          </div>
          <div className={classes.subtitle}>Size:</div>
          <div className={classes.text}>{formatBytes(fileOrFolder?.size)}</div>
          {fileOrFolder?.project?.title && (
            <>
              <div className={classes.subtitle}>Project:</div>
              <div className={classes.text}>{fileOrFolder?.project?.title}</div>
            </>
          )}
          <div className={classes.subtitle}>Created:</div>
          <div className={classes.text}>
            {formatTimestamp(+fileOrFolder?.createdAt)}
          </div>
        </div>
      )}
    </div>
  );
}

export default GeneralInfo;
