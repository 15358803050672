import type { File as AuxFile } from "types";
import CryptoJS from "crypto-js";

/**
 * Hash File from Browser File Object
 *
 * Hashes a browser file object using MurmurHash3.
 * @param {File} file Browser file object..
 * @returns {number} hash.
 */
export const hashFileFromBrowserFileObject = (file: File): Promise<String> =>
  new Promise((resolve, reject) => {
    try {
      const sha1 = CryptoJS.algo.SHA1.create();

      let read = 0;
      const unit = 1024 * 1024;
      let blob;

      // Read file in chunks using FileReader
      const reader = new FileReader();
      reader.readAsArrayBuffer(file.slice(read, read + unit));
      reader.onload = (e) => {
        const bytes = CryptoJS.lib.WordArray.create(e.target.result);
        sha1.update(bytes);
        read += unit;
        if (read < file.size) {
          blob = file.slice(read, read + unit);
          reader.readAsArrayBuffer(blob);
        } else {
          const hashObject = sha1.finalize();
          resolve(hashObject.toString(CryptoJS.enc.Hex));
        }
      };
    } catch (error) {
      reject(error);
    }
  });

declare global {
  interface Blob {
    text(): Promise<string>;
  }
}

Blob.prototype.text = function blobToText(): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsText(this);
  });
};

/**
 * Hash File from Disk
 *
 * Hashes a file on the users local file system using MurmurHash3.
 * @param {AuxFile} file Aux file object which must include a path.
 * @param {number} profileId profile id.
 * @returns {string} hash.
 */
export const hashFileFromDisk = async (
  file: AuxFile,
  profileId: number
): Promise<String> => {
  try {
    const userMyAuxFilesPath =
      window?.electronAPI?.getMyAuxFilesFolder(profileId);

    if (!userMyAuxFilesPath) {
      throw new Error("Unable to get Aux Files path.");
    }

    return await window?.electronAPI?.sha1File(
      window?.electronAPI?.updatePathCrossPlatform(
        `${userMyAuxFilesPath}/${file.path}`
      )
    );
  } catch (error) {
    return error;
  }
};
