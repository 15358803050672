import React from "react";

/**
 * LogicIcon
 * @returns {JSX.Element} LogicIcon
 */
function LogicIcon() {
   return (
      <svg
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 1C0 0.447715 0.447715 0 1 0H1.71053H2.75H17.6667H18.2895H19C19.5523 0 20 0.447715 20 1V19C20 19.5523 19.5523 20 19 20H18.2895H17.6667H2.75H1.71053H1C0.447715 20 0 19.5523 0 19V1ZM10 2.27273C13.3066 2.27273 15.9868 4.91818 15.9868 8.18182C15.9868 11.4455 13.3066 14.0909 10 14.0909C6.69342 14.0909 4.01316 11.4455 4.01316 8.18182C4.01316 4.91818 6.69342 2.27273 10 2.27273ZM10 4.09091C7.71638 4.09091 5.85526 5.92786 5.85526 8.18182C5.85526 10.4358 7.71638 12.2727 10 12.2727C12.2836 12.2727 14.1447 10.4358 14.1447 8.18182C14.1447 5.92786 12.2836 4.09091 10 4.09091ZM10 5C11.7858 5 13.2237 6.41916 13.2237 8.18182C13.2237 9.94447 11.7858 11.3636 10 11.3636C8.21415 11.3636 6.77631 9.94447 6.77631 8.18182C6.77631 6.41916 8.21415 5 10 5ZM10 7.27273C9.75572 7.27273 9.52145 7.36851 9.34872 7.53899C9.17599 7.70948 9.07895 7.94071 9.07895 8.18182C9.07895 8.42292 9.17599 8.65415 9.34872 8.82464C9.52145 8.99513 9.75572 9.09091 10 9.09091C10.2443 9.09091 10.4785 8.99513 10.6513 8.82464C10.824 8.65415 10.9211 8.42292 10.9211 8.18182C10.9211 7.94071 10.824 7.70948 10.6513 7.53899C10.4785 7.36851 10.2443 7.27273 10 7.27273ZM13.2237 15.9091C13.477 15.9091 13.6842 16.1136 13.6842 16.3636V17.7273C13.6842 17.9773 13.477 18.1818 13.2237 18.1818H6.77631C6.52303 18.1818 6.31579 17.9773 6.31579 17.7273V16.3636C6.31579 16.1136 6.52303 15.9091 6.77631 15.9091H13.2237Z"
            fill="currentColor"
         />
      </svg>
   );
}

export default LogicIcon;
