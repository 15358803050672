import { Box, Button, Link } from "@mui/material";
import React, { useMemo, useState } from "react";
import AppLayout from "_layouts/AppLayout";
import makeStyles from "@mui/styles/makeStyles";
import { AutoFixHigh, Info } from "@mui/icons-material";
import { useSoundsData } from "_utils/SoundsDataContext";
import AllowanceComponent from "_components/AllowanceComponent";
import { SubscriptionPlans } from "_constants/GlobalVariables";
import { useUser } from "_utils/UserContext";
import getUsersPlan from "_utils/getUsersPlan";
import UpgradeSubscriptionModal from "_components/Modal/UpgradeSubscriptionModal";
import SoundsListings from "./SoundsListings";
import SoundsSearch from "./SoundsSearch";

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    position: "relative",
  },
  // TODO: we could extract the page header to a reusable component as all app pages want a header that matches the height of the user in the menu and these styles are already shared between three pages, sounds, master and settings.
  pageHeaderBar: {
    padding: "23px",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.coreApp.borders}`,
  },
  title: {
    margin: 0,
  },
  containerLimits: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    gap: "46px",
  },
  scrollableBody: {
    flexGrow: 1,
    overflow: "auto",
  },
  footerLinks: {
    display: "flex",
    fontSize: "12px",
    height: "26px",
    lineHeight: "16px",
    padding: "4px 24px",
    background: theme.palette.hover.darkBlue,
    color: theme.palette.light.main,
    "& a": {
      color: theme.palette.light.main,
    },
  },
  footerLink: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    textAlign: "left",
    verticalAlign: "middle",
  },
  greyBorder: {
    border: "1px solid #B3B3B3",
  },
}));

const { Limits } = SubscriptionPlans;

/**
 * Sounds Page
 *
 * Page to show generative samples to users.
 *
 * @returns {JSX.Element} Sounds Page
 */
const SoundsPage = () => {
  const classes = useStyles();
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);

  const {
    loading,
    samples,
    prompt,
    setPrompt,
    samplesGenerating,
    generateSounds,
    abortAllRequestsAndReset,
  } = useSoundsData();

  const [user] = useUser();

  const userSubscriptionObject = useMemo(
    () => getUsersPlan(user?.currentBillingPlan),
    [user]
  );

  const userLimitObj = useMemo(
    () =>
      Limits.find(
        (l) => l.planEnum === (userSubscriptionObject?.modalEnum || 0)
      ),
    [userSubscriptionObject]
  );

  return (
    <AppLayout>
      <>
        {openSubscriptionModal && (
          <UpgradeSubscriptionModal
            title={
              user?.currentBillingPlan
                ? "To download more sounds upgrade your plan"
                : "To download sounds start your free trial"
            }
            description="and get access all app features, including project storage and mastering"
            open={openSubscriptionModal}
            handleClose={() => setOpenSubscriptionModal(false)}
          />
        )}
        <div className={classes.root}>
          <div className={classes.pageHeaderBar}>
            <h3 className={classes.title}>Sounds</h3>
            <div className={classes.containerLimits}>
              {(userLimitObj.soundsAllowance === 0 ||
                0.75 * userLimitObj.soundsAllowance <
                  user?.limits?.soundsDownloaded) && (
                <Button
                  className={classes.greyBorder}
                  onClick={() => {
                    setOpenSubscriptionModal(true);
                  }}
                >
                  Upgrade to Download
                </Button>
              )}
              {userLimitObj.soundsAllowance !== 9999 &&
                userLimitObj.soundsAllowance !== 0 && (
                  <AllowanceComponent
                    currentValue={user?.limits?.soundsDownloaded}
                    limit={userLimitObj.soundsAllowance}
                    title="sounds downloaded"
                  />
                )}
            </div>
          </div>

          <div className={classes.scrollableBody}>
            {samples && samples.length === 0 ? (
              <SoundsSearch
                loading={loading}
                prompt={prompt}
                setPrompt={setPrompt}
                samples={samples}
                samplesGenerating={samplesGenerating}
                abortAllRequestsAndReset={abortAllRequestsAndReset}
                generateSounds={generateSounds}
              />
            ) : (
              <SoundsListings
                loading={loading}
                prompt={prompt}
                setPrompt={setPrompt}
                samples={samples}
                samplesGenerating={samplesGenerating}
                generateSounds={generateSounds}
                abortAllRequestsAndReset={abortAllRequestsAndReset}
              />
            )}
          </div>

          <div className={classes.footerLinks}>
            <Box style={{ textAlign: "left" }} className={classes.footerLink}>
              <AutoFixHigh
                sx={{ fontSize: "18px", mr: "5px", color: "#fff" }}
              />
              <span>LoopLM v2.0</span>
            </Box>
            <Box ml="auto" style={{ textAlign: "right" }}>
              <Link
                href="https://aux.app/info/looplm"
                target="_blank"
                className={classes.footerLink}
              >
                <span>Learn more about LoopLM</span>
                <Info sx={{ fontSize: "18px", ml: "5px", color: "#fff" }} />
              </Link>
            </Box>
          </div>
        </div>
      </>
    </AppLayout>
  );
};

export default SoundsPage;
