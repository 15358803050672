import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import PlanPricingRow from "_components/PlanPricingRow";
import MetaTags from "_components/MetaTags";
import { Box } from "@mui/material";
import { useUser } from "_utils/UserContext";
import Footer from "_components/Footer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SubscriptionPlans } from "../../_constants/GlobalVariables";
import CurrencySelector from "../../_components/CurrencySelector";
import Header from "../../_components/Header/Header";

const useStyles = makeStyles(() => ({
   title: {
      fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
      lineHeight: "95%",
      marginBottom: "30px",
   },
   container: {
      paddingBottom: 50,
      maxWidth: 1200,
      padding: "0px 20px",
      overflowX: "auto",
      height: "100%",
      margin: "0 auto",
   },
   backgroundMain: {
      padding: "0px",
   },
   main: {
      color: "white",
      maxWidth: 1200,
      padding: "0px 20px",
      margin: "0 auto",
      "& p": {
         maxWidth: 700,
         color: "#B3B3B3",
         fontSize: "19px",
         lineHeight: "145%",
         marginTop: "0px",
         marginBottom: "0px",
      },
   },
}));

const { ArrayPlans } = SubscriptionPlans;

/**
 * Pricing Page
 * @returns {JSX.Elements} The Pricing Page
 */
const PricingPage = () => {
   const [user] = useUser();
   const classes = useStyles();
   const mobileView = useMediaQuery("(max-width:900px)");

   const [selectedButton, setSelectedButton] = useState("Monthly");

   // Fetch the users country by IP on first load.
   const [currency, setCurrency] = useState("USD");
   useEffect(() => {
      try {
         fetch("https://geoip-internal.aux.app/")
            .then((response) => response.json())
            .then((data) => {
               if (data?.country && data?.eu) {
                  if (data.eu === "1") {
                     setCurrency("EUR");
                  }
                  if (data.country === "GB") {
                     setCurrency("GBP");
                  }
               }
            });
      } catch {
         // fail silently falling back to USD
      }
   }, []);

   const history = useHistory();

   useEffect(() => {
      if (user) {
         history.push("/settings#subscription");
      }
   }, [user]);

   return (
      <div style={{ backgroundColor: "#111111" }}>
         <MetaTags
            title="Aux Pricing"
            description="Take your music workflow to the next level with one of our Aux subscription plans."
         />
         <Box>
            <div className={classes.backgroundMain}>
               <Header whiteText />
               <Box className={classes.main}>
                  <h1
                     className={classes.title}
                     style={{
                        fontSize: mobileView ? "3.6rem" : "76px",
                     }}
                  >
                     Pricing
                  </h1>
                  <p>
                     Backup 2GB of music files and collaborate on unlimited
                     music projects, <strong>free forever</strong>.
                  </p>
                  <br />
                  <p>
                     Upgrade to download samples and loops, increase your
                     project storage, master your tracks and unlock industry
                     connections.
                  </p>
                  <br />
                  <p>
                     Cancel all of your other music tool subscriptions, Aux is
                     the only app you need.
                  </p>
               </Box>
            </div>
         </Box>
         <div className={classes.container}>
            <Box marginTop="58px" marginBottom={mobileView ? "24px" : "78px"}>
               <CurrencySelector
                  currency={currency}
                  setCurrency={setCurrency}
                  recurrence={selectedButton}
                  setRecurrence={setSelectedButton}
                  loggedOut
               />
            </Box>
            <div
               style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                  gridGap: "30px",
                  flexWrap: "wrap",
               }}
            >
               {ArrayPlans.map((element) => {
                  if (
                     element.type === selectedButton &&
                     (!element?.currency || currency === element.currency)
                  ) {
                     return (
                        <PlanPricingRow
                           key={element.id}
                           plan={user?.currentBillingPlan}
                           clickHandler={() => {
                              if (element.label === "Team") {
                                 window.open(
                                    "mailto:hello@aux.app?subject=Teams Plan Pricing Request"
                                 );
                              }
                              if (user) {
                                 if (element.label === "Free") {
                                    history.push("/feed");
                                 } else {
                                    history.push("/settings#subscription");
                                 }
                              } else {
                                 history.push("/signup");
                              }
                           }}
                           selectedButton={selectedButton}
                           label={element.label}
                           hide={element.hide}
                           price={element?.price}
                           currency={currency}
                        />
                     );
                  }
                  return null;
               })}
            </div>
         </div>
         <Footer />
      </div>
   );
};

export default PricingPage;
