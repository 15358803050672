/**
 * File Is Ready Predicate
 * @param {object} file Apollo file instance
 * @returns {boolean} If the file is ready
 */
const isFileReady = (file) => {
   if (!file?.mimeType?.includes("audio")) {
      return true;
   }
   if (
      file?.processingPreview === 0 &&
      (file?.processingWaveform === 0 || file?.isStem || file?.isBouncedown)
   ) {
      return true;
   }
   return false;
};

export default isFileReady;
