import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { useUser } from "_utils/UserContext";
import { filterHiddenFiles } from "_utils/fileTypeUtil/fileTypeUtil";
import useCheckStorageLimit from "_utils/useCheckStorageLimit";
import useFileUploader from "_components/FileUploader/useFileUploader";
import { GET_FOLDER } from "../../_apollo/queries";

/**
 * File Uploader Button
 *
 * A complex component that has both the file and folder upload buttons as
 * well as their upload logic and the file dropzone logic.
 *
 * TODO: The components should be split from the uploader logic to make this simpler.
 * @param {props} props props
 * @param {string} props.folderUri Folder Uri to upload into.
 * @param {number} props.selectedDropFolderId Selected folder id.
 * @param {Function} props.callback callback triggered after handleDrop is finnish.
 * @returns {JSX.Element} Returns both the Upload Files and Upload Folders.
 */
const FileUploadInput = ({ folderUri, selectedDropFolderId, callback }) => {
  const uploadFiles = useFileUploader();
  const [user] = useUser();
  const checkStorageLimit = useCheckStorageLimit();

  // Get folder and it's parent folders.
  // folderUri
  const { data: folderData } = useQuery(GET_FOLDER, {
    variables: { folderUri },
  });
  const currentFolder = folderData?.getFolder;

  // Will retrieve the files from the DnD or from the hidden input
  /**
   * Handle Drop Event
   * @param {object} event event from input.
   */
  const handleDropEvent = async (event) => {
    const filesInput = Array.from(event?.target?.files || []);
    // Combine files data and filter out .DS_Store files from macs.
    const files = filterHiddenFiles(filesInput);

    if (files.length === 0) return;

    // Check user limits
    const enoughStorage = checkStorageLimit(files, user);
    if (!enoughStorage) {
      return;
    }

    uploadFiles(
      files.map((f) => {
        const mutableFile = f;
        mutableFile.folderId = selectedDropFolderId || currentFolder?.id;
        return mutableFile;
      })
    );
    callback();
  };

  return (
    <input
      id="file-upload-input"
      hidden
      type="file"
      multiple
      onChange={(e) => {
        handleDropEvent(e);
        e.target.value = null;
      }}
    />
  );
};

FileUploadInput.defaultProps = {
  selectedDropFolderId: null,
  folderUri: null,
  callback: () => null,
};

FileUploadInput.propTypes = {
  folderUri: PropTypes.string,
  selectedDropFolderId: PropTypes.string,
  callback: PropTypes.func,
};

export default FileUploadInput;
