import { makeStyles } from "@mui/styles";
import React from "react";
import { Link } from "react-router-dom";
import getConfig from "_utils/getConfig";
import isElectronHook from "_utils/isElectron";

const useStyles = makeStyles((theme) => ({
   linkInfo: {
      color: theme.palette.primary.main,
      lineHeight: "20px",
      fontSize: theme.typography.small1.fontSize,
   },
}));

const TermsAndConditionsLabel = ({ textColorOverride }) => {
   const classes = useStyles();
   const { domain } = getConfig();
   const isElectron = isElectronHook();

   return (
      <span
         style={{
            fontSize: "14px",
            lineHeight: "20px",
            color: textColorOverride || "inherit",
         }}
      >
         I accept the{" "}
         <Link
            target="_blank"
            to="/info/terms-of-service/"
            className={classes.linkInfo}
            onClick={(e) => {
               // On a build of electron the link does not work due to MUI. This breaks the code guidelines, but in this case we make an exception until we recreate these components in IndUI
               if (isElectron) {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open(`${domain}/info/terms-of-service/`);
               }
            }}
         >
            terms of service
         </Link>{" "}
         and{" "}
         <Link
            target="_blank"
            to="/info/privacy-policy/"
            className={classes.linkInfo}
            onClick={(e) => {
               // On a build of electron the link does not work
               if (isElectron) {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open(`${domain}/info/privacy-policy/`);
               }
            }}
         >
            privacy policy
         </Link>
      </span>
   );
};

export default TermsAndConditionsLabel;
