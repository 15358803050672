const electronAPI = window?.electronAPI;

export const {
  mkDirSync,
  trashFileOrDirSync,
  moveFileOrDirSync,
  clearStorageData,
  updateSyncStatus,
  showFolderInFinderOrExplorer,
  machineIdSync,
  updatePathCrossPlatform,
  isMac,
  isWindows,
  createMyAuxFilesFolder,
  getMyAuxFilesFolder,
  fs,
  beforeQuit,
  suspend,
  resume,
  storeData,
  retrieveData,
  getWatcher,
  isFile,
  sha1File,
  isFileEditLocked,
  editLockedPaths,
  readChunkSync,
  getDirName,
  startDrag,
  getBaseName,
} = electronAPI || {};
