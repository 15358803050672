import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import * as Sentry from "@sentry/react";
import AuxConnectLogo from "../_assets/AuxConnectLogo";
import ghostApi from "../_utils/ghostContentApi";
import BlogPostCard from "./BlogPostCard";
import DarkButton from "./Buttons/DarkButton";

const useStyles = makeStyles((theme) => ({
   centerButton: {
      display: "flex",
      justifyContent: "center",
      margin: "10px 0 0px",
   },
   blogNews: {
      margin: "18px 0px 34px",
      fontSize: theme.typography.h6.fontSize,
      lineHeight: "24px",
   },
   paddingContainer: {
      paddingTop: "40px",
      paddingBottom: "40px",
      "@media (max-width: 768px)": {
         paddingTop: "50px",
         paddingBottom: "70px",
      },
   },
   flexWrap: {
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
      gap: "36px",
      height: "380px",
      "@media (max-width: 682px)": {
         height: "auto",
      },
   },
}));

const MiniBlog = () => {
   const mobileSize = useMediaQuery("(max-width:1020px)");
   const classes = useStyles();
   const [ghostPosts, setGhostPosts] = useState([]);

   useEffect(() => {
      ghostApi.posts
         .browse({ include: "tags,authors" })
         .then((posts) => setGhostPosts(posts))
         .catch((error) => {
            Sentry.captureException(error);
         });
   }, []);

   return (
      <Container className={classes.paddingContainer}>
         <AuxConnectLogo />
         <p className={classes.blogNews}>
            The latest news and updates for the new music industry. <br /> New
            posts weekly.
         </p>
         <div className={classes.flexWrap}>
            {ghostPosts.slice(0, mobileSize ? 2 : 3).map((post) => (
               <BlogPostCard key={`${post?.slug}-mini-post`} post={post} />
            ))}
         </div>
         <div className={classes.centerButton}>
            <DarkButton
               variant="contained"
               size="large"
               component={Link}
               to="/connect"
               sx={{ padding: "12px 16px", fontSize: "16px" }}
            >
               View all blog posts
            </DarkButton>
         </div>
      </Container>
   );
};

export default MiniBlog;
