import PropTypes from "prop-types";
import React from "react";
import * as Sentry from "@sentry/react";
import { CONFIG_ENV } from "_utils/getConfig";

/**
 * throwError
 * Logs error to Sentry and redirects to error page. Do not use this function directly, throw a normal error(throw new Error("message")) and it will be caught by ErrorBoundary.
 * @param {Error} error Error object to be thrown and logged to Sentry.
 * @param {string | object} extra Extra information to be logged to Sentry, most of the cases is the stack trace.
 */
const throwError = (error, extra = null) => {
   if (CONFIG_ENV !== "expo") {
      // On green or production log the error to sentry.
      Sentry.captureException(error, { extra });
   }
};

class ErrorBoundary extends React.Component {
   constructor(props) {
      super(props);
      this.state = { hasError: false };
   }

   static getDerivedStateFromError() {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
   }

   componentDidMount() {
      const { onError, userId } = this.props;

      this.handleError = (event) => {
         throwError(
            event.error,
            `UserId: ${userId}. Error:${event.error.stack}`
         );
         // Update the state to indicate an error occurred
         this.setState({ hasError: true });
         onError(event.error);
      };

      // Add a global error event listener
      window.addEventListener("error", this.handleError);
   }

   componentDidUpdate(prevProps) {
      const { userId } = this.props;

      // Check if the userId prop has changed from null to a valid value
      if (!prevProps.userId && userId) {
         this.addErrorListener();
      }
      // Check if the userId prop has changed from a valid value to null
      else if (prevProps.userId && !userId) {
         this.removeErrorListener();
      }
   }

   componentWillUnmount() {
      // Remove the global error event listener to prevent memory leaks
      window.removeEventListener("error", this.handleError);
   }

   addErrorListener() {
      const { onError, userId } = this.props;

      this.handleError = (event) => {
         throwError(event.error, userId, event.error.stack);
         // Update the state to indicate an error occurred
         this.setState({ hasError: true });
         onError(event.error);
      };

      // Add a global error event listener
      window.addEventListener("error", this.handleError);
   }

   removeErrorListener() {
      // Remove the global error event listener to prevent memory leaks
      window.removeEventListener("error", this.handleError);
   }

   render() {
      const { hasError } = this.state;
      const { fallback, children } = this.props;

      return hasError ? fallback : children;
   }
}

ErrorBoundary.propTypes = {
   children: PropTypes.node,
   fallback: PropTypes.node.isRequired,
   onError: PropTypes.func.isRequired,
   userId: PropTypes.string,
};

export default ErrorBoundary;
