import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { CircularProgress, Paper, Grid } from "@mui/material/";
import makeStyles from "@mui/styles/makeStyles";
import { connect } from "react-redux";
import { FILES_FOLDER_PAGINATION_LIMIT } from "_constants/GlobalVariables";
import { GET_MODAL_FOLDERS } from "../../_apollo/queries";
import FilesTable from "../FilesTable/FilesTable";
import FoldersPath from "../../_pages/FilesPage/FoldersPath";

const useStyles = makeStyles(() => ({
   filterInput: {
      backgroundColor: "#F2F2F2",
      border: "none",
      marginLeft: 10,
      marginBottom: 8,
      paddingLeft: 10,
      outline: "none",
      borderRadius: 8,
      width: 246,
      height: 37,
   },
   tableContainer: {
      height: 300,
      "& > div": {
         maxHeight: "100%",
      },
   },
}));

/**
 * Modal Files Listings
 *
 * A stripped down wrapper to the Files Table used on modals
 * such as the move and copy modal.
 *
 * Currently this modal only shows folders.
 * @param {object} props props
 * @param {object|null} props.currentModalFolder The current folder to open the modal pointing to, can be null for root folder.
 * @param {Function} props.setCurrentModalFolder Sets the current folder in the upper component scope.
 * @param {Array} props.defaultSelectedFolder Array of selected file or folder ids. TODO: update if this is incorrect use @lluisemper
 * @param {Array} props.defaultSelectedFiles Array of selected file or folder ids. TODO: update if this is incorrect use @lluisemper
 * @param {string} props.filter Text filter string to filter results, to be passed to the child row.
 * @param {Function} props.setFilter Sets the filter in the upper component state.
 * @returns {JSX.Element} Modal File Listings wrapper with Files Listings.
 */
const ModalFilesListings = ({
   currentModalFolder,
   setCurrentModalFolder,
   defaultSelectedFolder,
   defaultSelectedFiles,
   filter,
   setFilter,
}) => {
   const classes = useStyles();
   const [selectedFiles, setSelectedFiles] = useState(
      defaultSelectedFiles || []
   );
   const [selectedFolders, setSelectedFolders] = useState(
      defaultSelectedFolder || []
   );
   const [renamingFolderValue, setRenamingFolderValue] = useState(null);
   const [, setShowMoveModal] = useState(false);
   const [, setShowCopyModal] = useState(false);
   const [, setCurrentFolderId] = useState(null);

   const {
      data: dataFolders,
      fetchMore: fetchMoreFolders,
      refetch: refetchFolders,
      loading,
   } = useQuery(GET_MODAL_FOLDERS, {
      variables: {
         uri: currentModalFolder?.uri,
         offset: 0,
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
   });
   const folders = dataFolders?.getModalFolders?.folders;
   const foldersPage = dataFolders?.getModalFolders?.pageInfo?.page;
   const foldersTotalPages = dataFolders?.getModalFolders?.pageInfo.totalPages;

   const handleChangeDirectory = (targetFolder) => {
      // Set the current folder in the parent to allow the modal to enable the confirmation button.
      setCurrentModalFolder(targetFolder);
   };

   /**
    * Fetch More Files Folders
    *
    * Fetches the next page of files or folders in the Files
    */
   const fetchMoreFilesFolders = () => {
      if (foldersPage < foldersTotalPages) {
         fetchMoreFolders({
            variables: {
               uri: currentModalFolder?.uri,
               offset: FILES_FOLDER_PAGINATION_LIMIT * foldersPage,
            },
         });
      }
      // Note, this modal currently only shows folder listings.
   };

   // Reload folder data when the user navigates within the modal.
   useEffect(() => {
      const variablesRefetch = {
         uri: currentModalFolder?.uri,
         offset: 0,
      };
      refetchFolders(variablesRefetch);
   }, [currentModalFolder?.uri]);

   return (
      <>
         <Grid container alignItems="baseline">
            <input
               className={classes.filterInput}
               onChange={(e) => {
                  setFilter(e?.target?.value);
               }}
               type="text"
               placeholder="Filter"
            />
            <FoldersPath
               folderUri={currentModalFolder?.uri}
               isModal
               handleChangeDirectory={handleChangeDirectory}
            />
         </Grid>
         <Grid
            className={classes.tableContainer}
            elevation={0}
            component={Paper}
            xs={12}
         >
            {loading ? (
               <Grid xs={12} justifyContent="center" container>
                  <CircularProgress />
               </Grid>
            ) : (
               <FilesTable
                  folders={folders}
                  hasMoreFilesFolders={
                     foldersPage !== undefined &&
                     foldersPage < foldersTotalPages
                  }
                  fetchMoreFilesFolders={fetchMoreFilesFolders}
                  setCurrentFolderId={setCurrentFolderId}
                  setSelectedFolders={setSelectedFolders}
                  setSelectedFiles={setSelectedFiles}
                  selectedFolders={selectedFolders}
                  selectedFiles={selectedFiles}
                  setRenamingFolderValue={setRenamingFolderValue}
                  renamingFolderValue={renamingFolderValue}
                  setShowCopyModal={setShowCopyModal}
                  setShowMoveModal={setShowMoveModal}
                  isModal
                  handleChangeDirectory={handleChangeDirectory}
                  search={filter}
                  foldersLoading={loading}
                  filesLoading={false}
               />
            )}
         </Grid>
      </>
   );
};

ModalFilesListings.propTypes = {
   currentModalFolder: PropTypes.shape({
      id: PropTypes.string,
      uri: PropTypes.string,
   }),
   setCurrentModalFolder: PropTypes.func.isRequired,
   defaultSelectedFolder: PropTypes.instanceOf(Array),
   defaultSelectedFiles: PropTypes.instanceOf(Array),
   setFilter: PropTypes.func.isRequired,
   filter: PropTypes.string.isRequired,
};

ModalFilesListings.defaultProps = {
   currentModalFolder: null,
   defaultSelectedFolder: [],
   defaultSelectedFiles: [],
};

const mapStateToProps = (state) => {
   const { isPaused, currentFile } = state.AppReducer;
   return { isPaused, currentFile };
};

export default connect(mapStateToProps)(ModalFilesListings);
