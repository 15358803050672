import React from "react";
import FolderEmptyIcon from "_assets/FolderEmptyIcon";
import { Button, useTheme } from "@mui/material";
import isElectronHook from "_utils/isElectron";
import {
  writeFileToFilesystem,
  writeFolderToFilesystem,
} from "_utils/fileSyncHelpers";
import FolderUploadInput from "_components/FileUploader/FolderUploadInput";
import FileUploadInput from "_components/FileUploader/FileUploadInput";
import { useUser } from "_utils/UserContext";

/**
 * Empty Files View
 * @returns {JSX.Element} Empty Files View
 */
function EmptyFilesView() {
  const theme = useTheme<any>();
  const isElectron = isElectronHook();
  const [user] = useUser();

  /**
   * Click handler
   * Click handler for upload buttons
   * @param {object} e event
   * @param {boolean | null} isFile if it is file or folder upload
   */
  const clickHandler = (e, isFile?: boolean) => {
    // If you are trying to upload to an existing sync folder, simply write it into that sync folder.
    // Without this, the app would upload the file first then the sync code would have to download it again.
    if (isElectron) {
      if (isFile) {
        writeFileToFilesystem(
          window.electronAPI.getMyAuxFilesFolder(user?.profile?.id)
        );
      } else {
        writeFolderToFilesystem(
          window.electronAPI.getMyAuxFilesFolder(user?.profile?.id)
        );
      }
    } else {
      e?.target?.querySelector("input")?.click();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <FolderEmptyIcon />
      <div
        style={{
          marginTop: "5px",
          marginBottom: "18px",
          fontSize: "18px",
          lineHeight: "24px",
        }}
      >
        You have no files yet
      </div>
      <div
        style={{
          textAlign: "center",
          fontSize: "12px",
          lineHeight: "16px",
          color: theme.palette.secondary.shade60,
        }}
      >
        Upload new files, save sounds
        <br />
        or master tracks to see them here.
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginTop: "36px",
        }}
      >
        <Button
          sx={{ borderColor: "secondary.shade30" }}
          variant="outlined"
          onClick={clickHandler}
        >
          Upload Folder
          <FolderUploadInput />
        </Button>
        <Button
          sx={{ borderColor: "secondary.shade30" }}
          variant="outlined"
          onClick={(e) => clickHandler(e, true)}
        >
          Upload File
          <FileUploadInput />
        </Button>
      </div>
    </div>
  );
}

export default EmptyFilesView;
