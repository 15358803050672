import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "@mui/material/Container";

const useStyles = makeStyles((theme) => ({
   gridSwitcher: {
      marginTop: "78px",
      marginBottom: "95px",
      height: "240vh",
   },
   switchImage: {
      flex: 2,
      height: "auto",
      maxWidth: "66%",
      maxHeight: "100%",
      width: "100%",
      "& video": {
         height: "auto",
         maxHeight: "100%",
         width: "100%",
         objectFit: "contain",
      },
      "@media (max-width: 900px)": {
         maxWidth: "100%",
      },
   },
   stickyContainer: {
      position: "sticky",
      top: "10vh",
      height: "80vh",
      display: "flex",
      alignItems: "flex-start",
      gap: "50px",
   },
   options: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      maxHeight: "100%",
   },
   childOption: {
      flex: 1,
      paddingLeft: "40px",
      paddingTop: "12px",
      borderLeft: `6px solid ${theme.palette.primary.main}`,
   },
   childOptionDisabled: {
      flex: 1,
      paddingLeft: "40px",
      paddingTop: "12px",
      borderLeft: "1px solid #9390A7",
      color: "#B6B4C7",
      marginLeft: "5px",
   },
   title: {
      fontSize: "32px",
      lineHeight: "32px",
      marginBottom: "1.4vw",
      fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
      "@media (max-width: 1200px)": {
         marginBottom: "1vw",
         fontSize: "36px",
         lineHeight: "36px",
      },
      "@media (max-width: 900px)": {
         width: "100%",
         marginTop: "28px",
         marginBottom: "26px",
      },
   },
   subtitle: {
      fontSize: "16px",
      lineHeight: "150%",
      "@media (max-width: 900px)": {
         width: "100%",
         marginBottom: "100px",
      },
   },
   mobileRoot: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "78px",
   },
}));

/**
 * Switch Features
 * @returns {JSX.Element} Switch Features Component
 */
const SwitchFeatures = () => {
   const classes = useStyles();
   const containerRef = useRef();
   const imageRef = useRef();
   const [section, setSection] = useState(1);
   const [heightImage, setHeightImage] = useState(0);

   const mobileView = useMediaQuery("(max-width:900px)");

   useEffect(() => {
      /**
       * Callback resize
       */
      const callbackResize = () => {
         if (imageRef.current?.clientHeight) {
            setHeightImage(imageRef.current.clientHeight);
         }
      };
      window.addEventListener("resize", callbackResize, true);
      return () => {
         window.removeEventListener("resize", callbackResize);
      };
   }, []);

   useLayoutEffect(() => {
      /**
       * Handle scroll
       */
      const handleScroll = () => {
         if (containerRef.current) {
            const screenPosition = containerRef.current.getBoundingClientRect();
            const offset = window.innerHeight / 10;
            const elementVisibleHeight =
               (containerRef.current.clientHeight / 3) * 2;

            const elementPosition = screenPosition.top - offset;

            const heightSection = elementVisibleHeight / 3;
            // Scrolling top to bottom
            if (elementPosition > 0) {
               setSection(1);
            } else {
               const positivePosition = Math.abs(elementPosition);
               if (heightSection > positivePosition) {
                  setSection(1);
               } else if (heightSection * 2 > positivePosition) {
                  setSection(2);
               }
            }
         }
      };

      window.addEventListener("scroll", handleScroll);
      handleScroll();

      return () => window.removeEventListener("scroll", handleScroll);
   }, []);

   if (mobileView) {
      return (
         <Container className={classes.mobileRoot}>
            <video
               src="https://aux-marketing-assets.s3.eu-west-1.amazonaws.com/marketing-pages/sync.mp4"
               muted
               autoPlay
               ref={imageRef}
               playsInline
               className={classes.switchImage}
               onLoadedMetadata={(e) => setHeightImage(e.target.offsetHeight)}
            />
            <div className={classes.title}>Desktop Sync</div>
            <div className={classes.subtitle}>
               Your project is automatically updated within Aux as you work on
               it in your DAW.
            </div>
            <video
               src="https://aux-marketing-assets.s3.eu-west-1.amazonaws.com/marketing-pages/audio-player.mp4"
               muted
               autoPlay
               ref={imageRef}
               playsInline
               className={classes.switchImage}
               onLoadedMetadata={(e) => setHeightImage(e.target.offsetHeight)}
            />
            <div className={classes.title}>DAW Audio Player</div>
            <div className={classes.subtitle}>
               Share, play back and comment on your files without having to wait
               for your DAW to launch.
            </div>
         </Container>
      );
   }

   return (
      <div className={classes.gridSwitcher} ref={containerRef}>
         <div className={classes.stickyContainer}>
            <div ref={imageRef} className={classes.switchImage}>
               {section === 1 && (
                  <video
                     src="https://aux-marketing-assets.s3.eu-west-1.amazonaws.com/marketing-pages/sync.mp4"
                     muted
                     autoPlay
                     playsInline
                     onLoadedMetadata={(e) =>
                        setHeightImage(e.target.offsetHeight)
                     }
                  />
               )}
               {section === 2 && (
                  <video
                     src="https://aux-marketing-assets.s3.eu-west-1.amazonaws.com/marketing-pages/audio-player.mp4"
                     muted
                     autoPlay
                     playsInline
                     onLoadedMetadata={(e) =>
                        setHeightImage(e.target.offsetHeight)
                     }
                  />
               )}
            </div>

            <div
               className={classes.options}
               style={{ height: `${heightImage}px` }}
            >
               <div
                  className={
                     section === 1
                        ? classes.childOption
                        : classes.childOptionDisabled
                  }
               >
                  <div className={classes.title}>Desktop Sync</div>
                  <div className={classes.subtitle}>
                     Your project is automatically updated within Aux as you
                     work on it in your DAW.
                  </div>
               </div>
               <div
                  className={
                     section === 2
                        ? classes.childOption
                        : classes.childOptionDisabled
                  }
               >
                  <div className={classes.title}>DAW Audio Player</div>
                  <div className={classes.subtitle}>
                     Share, play back and comment on your files without having
                     to wait for your DAW to launch.
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default SwitchFeatures;
