import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import { useMutation } from "@apollo/react-hooks";
import useStatsMutation from "_utils/useStatsMutation";
import { useUser } from "_utils/UserContext";
import CustomModal from "_components/Modal/CustomModal";
import { UPDATE_PROFILE_IMG, REMOVE_PROFILE_IMG } from "../_apollo/queries";
import ImageUploadInput from "./ImageUploadInput";
import DangerButton from "./DangerButton";

// Increase pixel density for crop preview quality on retina screens.

/**
 * UploadProfileImage
 * @param {object} param0 props.
 * @param {object} param0.profile current profile.
 * @param {object} param0.managingProfile managing profile.
 * @param {string} param0.selectedTeam team id.
 * @returns {JSX.Element} Upload Profile Image.
 */
const UploadProfileImage = ({ profile, managingProfile, selectedTeam }) => {
   const [user, , fetchUserCache] = useUser();
   // Click the plus button to open the file picker
   const refInvisibleInput = useRef(null);

   const [base64Image, setBase64Image] = useState(undefined);
   const [show, setShow] = useState(false);
   const [upImg, setUpImg] = useState();

   const sendStatsMutation = useStatsMutation();

   // user id and base64string
   const [updateProfileImg, { loading: loadingProfilePicture }] = useMutation(
      UPDATE_PROFILE_IMG,
      {
         /**
          * updateProfileImg onCompleted
          */
         onCompleted() {
            fetchUserCache();
            sendStatsMutation({
               statsId: "UserProfileImageUpdated",
               userId: user.id,
            });
            setShow(false);
         },
      }
   );
   const [removeProfileImg, { loading: loadingRemoveImg }] = useMutation(
      REMOVE_PROFILE_IMG,
      {
         /**
          * removeProfileImg onCompleted
          */
         onCompleted() {
            fetchUserCache();
            sendStatsMutation({
               statsId: "UserProfileImageUpdated",
               userId: user.id,
            });
         },
      }
   );

   /**
    * getCroppedImg
    */
   const getCroppedImg = async () => {
      await updateProfileImg({
         variables: {
            image: base64Image,
            teamId: selectedTeam,
         },
      });
   };

   /**
    * openSelectFile
    */
   const openSelectFile = () => {
      refInvisibleInput.current.click();
   };

   /**
    * onSelectFile
    * @param {object} e event.
    */
   const onSelectFile = (e) => {
      if (e.target.files && e.target.files.length > 0) {
         setShow(true);
         const reader = new FileReader();
         reader.addEventListener("load", () => setUpImg(reader.result));
         reader.readAsDataURL(e.target.files[0]);
      } else {
         setShow(false);
      }
   };

   return (
      <Box>
         <input
            type="file"
            className="d-none"
            accept="image/*"
            onChange={(e) => {
               onSelectFile(e);
               e.target.value = null;
            }}
            hidden
            ref={refInvisibleInput}
         />

         <Box>
            <Box my="16px">
               <Avatar
                  src={
                     profile?.photoUrlOrGravatar.indexOf("?default") !== -1
                        ? `${
                             profile?.photoUrlOrGravatar
                          }?time=${new Date().getTime()}`
                        : profile?.photoUrlOrGravatar
                  }
                  style={{ width: 84, height: 84, textAlign: "center" }}
               />
            </Box>
            <Grid container>
               <Box mr="10px">
                  <Button
                     disabled={show}
                     className="mr-2"
                     variant="outlined"
                     color="primary"
                     onClick={() => openSelectFile()}
                  >
                     Upload Profile Photo
                  </Button>
               </Box>
               {!profile?.photoUrlOrGravatar.includes("/avatar/") && (
                  <DangerButton
                     disabled={show || loadingRemoveImg}
                     onClick={async () => {
                        await removeProfileImg({
                           variables: {
                              isBackground: false,
                              id: managingProfile?.id || null,
                              teamId: selectedTeam,
                           },
                        });
                     }}
                  >
                     {loadingRemoveImg ? "Loading..." : "Delete"}
                  </DangerButton>
               )}
            </Grid>
         </Box>
         <CustomModal open={show} fullWidth="sm">
            <Grid mb={1}>
               <Typography variant="large">Crop your image</Typography>
            </Grid>
            <Grid>
               <ImageUploadInput
                  setBase64Image={setBase64Image}
                  upImg={upImg}
                  circularCrop
               />
            </Grid>
            <Grid container mt={1}>
               <Box mr={1}>
                  <Button
                     variant="contained"
                     color="primary"
                     onClick={async () => {
                        await getCroppedImg();
                     }}
                     disabled={loadingProfilePicture}
                  >
                     {loadingProfilePicture ? "Loading..." : "Save"}
                  </Button>
               </Box>
               <DangerButton onClick={async () => setShow(false)}>
                  Cancel
               </DangerButton>
            </Grid>
         </CustomModal>
      </Box>
   );
};

UploadProfileImage.propTypes = {
   profile: PropTypes.instanceOf(Object).isRequired,
   managingProfile: PropTypes.instanceOf(Object).isRequired,
   selectedTeam: PropTypes.number.isRequired,
};

export default UploadProfileImage;
