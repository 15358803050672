import React from "react";
import PropTypes from "prop-types";
import {
   Box,
   Card,
   CardActionArea,
   CardActions,
   Grid,
   Button,
   List,
   ListItem,
   Divider,
   Chip,
   ListItemText,
   Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useUser } from "_utils/UserContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CheckCircle } from "@mui/icons-material";
import { SubscriptionPlans } from "../_constants/GlobalVariables";

const { Features } = SubscriptionPlans;

const useStyles = makeStyles((theme) => ({
   upgradeButton: {
      display: "block",
      margin: "0 auto",
      width: "100%",
      height: "48px",
      fontSize: "16px",
   },
   featureList: {
      padding: 0,
      "& li": {
         padding: 0,
         marginBottom: "14px",
         "& div": {
            margin: 0,
         },
      },
   },
   launchingSoon: {
      lineHeight: "1rem",
      fontSize: "0.75rem",
      color: "white",
      marginBottom: "10px",
      marginTop: "6px",
      fontWeight: 700,
   },
   card: {
      padding: "35px 40px",
      borderRadius: "8px",
      overflow: "visible",
      position: "relative",
      boxSizing: "border-box",
      flex: "1 1 0",
      fontSize: theme.typography.h3.fontSize,
      display: "flex",
      flexDirection: "column",
      "&:hover": {
         borderColor: theme.palette.primary.main,
      },
   },
}));

/**
 * Subscription Row Plans
 *
 * A single pricing plan card.
 *
 * NOTE: This component is heavily coupled with the parent and is a good candidate for refactoring/simplification.
 * @param {object} props props
 * @param {object} props.plan This cards plan object.
 * @param {Function} props.clickHandler Function to call when card is clicked.
 * @param {string} props.selectedButton ?? Unsure @lluis / @lukmaan-b can you add details here.
 * @param {string} props.label Label of the price.
 * @param {number} props.price Price of the plan.
 * @param {string} props.currency Currency of the plan.
 * @param {number} props.planId The Stripe plan id.
 * @returns {JSX.Element} Pricing plan card component.
 */
const PlanPricingRow = ({
   plan,
   clickHandler,
   selectedButton,
   label,
   price,
   currency,
   planId,
}) => {
   const [user] = useUser();
   const classes = useStyles();
   const mobileView = useMediaQuery("(max-width:900px)");

   /**
    * Render Action Button
    *
    * Renders either the current plan, request demo or start trial button.
    * @returns {JSX.Element} Pricing action button.
    */
   const renderActionButton = () => {
      if ((label === "Free" && !user?.subscriptions) || label === plan?.label) {
         return (
            <Button
               variant="contained"
               sx={{
                  color: "black",
                  backgroundColor: "#FFD645",
                  height: "48px",
               }}
               color="secondary"
               className={classes.upgradeButton}
               id={`plan-${planId}`}
            >
               {!user ? "Get started free" : "Current Plan"}
            </Button>
         );
      }
      if (!plan) {
         if (label === "Basic") {
            return (
               <Button
                  variant="outlined"
                  color="secondary"
                  sx={{
                     color: "white",
                     backgroundColor: "black",
                     border: "1px solid black",
                  }}
                  className={classes.upgradeButton}
               >
                  Start 14-day free trial
               </Button>
            );
         }
         return (
            <Button
               variant="outlined"
               color="secondary"
               sx={{ color: "#F2F2F2", border: "1px solid #F2F2F2" }}
               className={classes.upgradeButton}
            >
               Start 14-day free trial
            </Button>
         );
      }

      let textContent = "Upgrade";

      if (plan?.id > planId) {
         textContent = "Downgrade";
      }

      return (
         <Button
            variant="contained"
            color="primary"
            id={`plan-${planId}`}
            className={classes.upgradeButton}
         >
            {textContent}
         </Button>
      );
   };

   return (
      <Card
         className={classes.card}
         style={{
            backgroundColor: label === "Basic" ? "#BA9FFE" : "#2D2D32",
            border: "0.8px solid #4C4C50",
            color: label === "Basic" ? "black" : "white",
            height: mobileView ? "auto" : "600px",
         }}
         variant="outlined"
      >
         {label === "Basic" && (
            <div
               style={{
                  position: "absolute",
                  top: "-16px",
                  right: "15px",
                  backgroundColor: "#FFD645",
                  borderRadius: "20px",
                  fontSize: "16px",
                  lineHeight: "24px",
                  padding: "4px 20px",
                  height: "24px",
               }}
            >
               Popular
            </div>
         )}
         <CardActionArea onClick={clickHandler} component={Box}>
            <Grid container alignItems="flex-start">
               <span
                  style={{
                     margin: 0,
                     fontSize: "2rem",
                     lineHeight: "120%",
                     fontFamily: "dr-extrabold, san-serif",
                  }}
               >
                  {label}
                  {price === 0.99 && (
                     <Chip
                        sx={{ marginLeft: 1, marginTop: "-5px" }}
                        label="New"
                     />
                  )}
               </span>
            </Grid>
            <Box marginTop="0.8rem" display="flex" alignItems="center">
               <span
                  style={{
                     fontSize: mobileView ? "48px" : "56px",
                     lineHeight: mobileView ? "95%" : "60px",
                     fontFamily: "dr-extrabold, san-serif",
                  }}
               >
                  {new Intl.NumberFormat("en", {
                     style: "currency",
                     currency,
                  }).format(price)}
               </span>
               &nbsp; &nbsp;
               <span style={{ fontSize: "1.375rem" }}>
                  {label === "Team" && "Member/"}/{selectedButton}
               </span>
            </Box>
         </CardActionArea>
         <Divider style={{ marginTop: "2rem", backgroundColor: "#4C4C50" }} />
         <CardActions
            style={{
               margin: mobileView ? "1.5rem 0 44px" : "1.5rem 0 auto",
               padding: 0,
            }}
         >
            <Box display="flex" flexDirection="column">
               <List dense className={classes.featureList}>
                  {Features[`${label}PlanFeatures`].slice(0, 4).map((el) => (
                     <ListItem disableGutters key={el}>
                        <CheckCircle
                           fontSize="10px"
                           color="primary"
                           sx={{ marginRight: "6px", fill: "#FFD645" }}
                        />
                        <ListItemText>
                           <Typography fontSize="12px" noWrap>
                              {el}
                           </Typography>
                        </ListItemText>
                     </ListItem>
                  ))}
               </List>
               {Features[`${label}PlanFeatures`].length > 4 && (
                  <>
                     <div className={classes.launchingSoon}>Launching soon</div>
                     <List dense className={classes.featureList}>
                        {Features[`${label}PlanFeatures`]
                           .slice(4, 6)
                           .map((el) => (
                              <ListItem disableGutters key={el}>
                                 <CheckCircle
                                    fontSize="10px"
                                    sx={{
                                       marginRight: "6px",
                                       fill: "#AE47FF",
                                    }}
                                 />
                                 <ListItemText>
                                    <Typography fontSize="12px">
                                       {el}
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                           ))}
                     </List>
                  </>
               )}
            </Box>
         </CardActions>
         <CardActionArea onClick={clickHandler} component={Box}>
            {renderActionButton()}
         </CardActionArea>
      </Card>
   );
};

PlanPricingRow.propTypes = {
   plan: PropTypes.shape({ id: PropTypes.number }),
   clickHandler: PropTypes.func.isRequired,
   selectedButton: PropTypes.string.isRequired,
   label: PropTypes.string.isRequired,
   price: PropTypes.number,
   currency: PropTypes.string.isRequired,
   planId: PropTypes.number.isRequired,
};

PlanPricingRow.defaultProps = {
   plan: null,
   price: null,
};

export default PlanPricingRow;
