import * as React from "react";
import { Box, Divider, Grid, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import AuxLogo from "_assets/AuxLogo";
import isElectronHook from "_utils/isElectron";
import appStorage from "_utils/appStorage";
import apiVersion from "apiVersion.json";
import CustomModal from "./CustomModal";

const buildAPIVersion = apiVersion.apiVersion;

const useStyles = makeStyles((theme) => ({
   header: {
      fontSize: theme.typography.small1.fontSize,
      textAlign: "center",
      marginTop: "1em",
      marginBottom: "2em",
   },
   description: {
      fontSize: theme.typography.small1.fontSize,
      width: 260,
      textAlign: "center",
   },
   button: {
      padding: "8px 12px",
      color: "white !important",
   },
   divider: { margin: "0 auto" },
}));

const FeedbackModal = ({ open, handleClose }) => {
   const classes = useStyles();
   return (
      <CustomModal open={open} handleClose={handleClose}>
         <Box className={classes.header}>
            <AuxLogo width={130} height={28} style={{ marginBottom: "1em" }} />
            <Box>
               {buildAPIVersion && <span>Version: {buildAPIVersion}</span>}
               {isElectronHook() &&
                  appStorage.getValue({ key: "auxDeviceID" }) && (
                     <span>
                        {" "}
                        - Device ID:{" "}
                        {appStorage.getValue({ key: "auxDeviceID" }).slice(-6)}
                     </span>
                  )}
            </Box>
         </Box>
         <Grid
            container
            wrap="nowrap"
            mb={3}
            style={{ maxWidth: "560px", margin: "0 auto 20px auto" }}
         >
            <Box>
               <Typography color="primary" variant="modalHeader">
                  Contact Aux Team
               </Typography>
               <Typography
                  my={2}
                  variant="modalMessage"
                  className={classes.description}
               >
                  Get in touch with the team behind the Aux app. We’re open to
                  suggestions, ideas, bug reports and partnering up.
               </Typography>
               <Box mt="16px" display="flex" justifyContent="center">
                  <Button
                     component="a"
                     href={`mailto:hello@aux.app?subject=Aux Beta Feedback&body=Debug Information: ${
                        buildAPIVersion &&
                        `App Build Version ${buildAPIVersion}`
                     }${
                        isElectronHook() && window?.electronAPI?.isMac
                           ? " Mac "
                           : " Windows "
                     }${
                        isElectronHook() &&
                        appStorage.getValue({ key: "auxDeviceID" })
                           ? ` - Device ID: 
                              ${appStorage
                                 .getValue({ key: "auxDeviceID" })
                                 .slice(-6)}`
                           : ""
                     }`}
                     className={classes.button}
                     variant="contained"
                     color="primary"
                     target="_blank"
                  >
                     Email Aux Team
                  </Button>
               </Box>
            </Box>
            <Divider
               className={classes.divider}
               orientation="vertical"
               flexItem
            />
            <Box>
               <Typography color="primary" variant="modalHeader">
                  Feature Idea
               </Typography>
               <Typography
                  my={2}
                  variant="modalMessage"
                  className={classes.description}
               >
                  Do you have an amazing feature idea? Our roadmap is public and
                  you can contribute. Add your best ideas here.
               </Typography>
               <Box mt="16px" display="flex" justifyContent="center">
                  <Button
                     component="a"
                     href="https://roadmap.aux.app/aux-public-roadmap"
                     className={classes.button}
                     variant="contained"
                     color="primary"
                     target="_blank"
                  >
                     Send Feature Idea
                  </Button>
               </Box>
            </Box>
         </Grid>
      </CustomModal>
   );
};

FeedbackModal.propTypes = {
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
};

export default FeedbackModal;
