import ToggleButton from "@mui/material/ToggleButton";
import React from "react";
import PropTypes from "prop-types";
import { Box, Switch } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
   switch: {
      width: 34,
      height: 30,
      padding: "7px 0px",
      "& .MuiSwitch-switchBase": {
         margin: 1,
         padding: 0,
         "&.Mui-checked": {
            transform: "translateX(12px)",
            "& + .MuiSwitch-track": {
               opacity: 0.38,
               backgroundColor: theme.palette.primary.main,
            },
         },
      },
      "& .MuiSwitch-thumb": {
         backgroundColor: theme.palette.primary.main,
         width: 20,
         height: 20,
         marginTop: "4px",
         "&:before": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            top: 40,
         },
      },
      "& .MuiSwitch-track": {
         backgroundColor: theme.palette.primary.main,
         opacity: 0.38,
         borderRadius: 20 / 2,
      },
   },
   activeRecurrence: {
      fontSize: "14px",
      lineHeight: "20px",
      color: theme.palette.primary.main,
   },
   inactiveRecurrence: {
      fontSize: "14px",
      lineHeight: "20px",
      color: theme.palette.secondary.shade30,
   },
}));

const CurrencySelector = ({
   currency,
   setCurrency,
   recurrence,
   setRecurrence,
   loggedOut,
}) => {
   const classes = useStyles();
   const mobileView = useMediaQuery("(max-width:900px)");

   return (
      <Box
         ml="auto"
         display="flex"
         flexDirection={mobileView ? "column" : "row"}
         justifyContent="space-between"
         gap={mobileView ? "16px" : "0px"}
      >
         {/* There is different styles for logged in and logged out users */}
         {loggedOut ? (
            <div
               style={{
                  borderRadius: "24px",
                  color: "white",
                  backgroundColor: "#232328",
                  display: "flex",
                  alignItems: "center",
                  width: "400px",
                  maxWidth: "88vw",
                  height: "44px",
                  cursor: "pointer",
               }}
            >
               <div
                  style={{
                     fontSize: mobileView ? "1rem" : "19px",
                     lineHeight: "145%",
                     padding: mobileView ? "10px 7px" : "7px 29px",
                     borderRadius: "24px",
                     width: "50%",
                     textAlign: "center",
                     backgroundColor:
                        recurrence === "Monthly" ? "#4C4C50" : "#232328",
                  }}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                     setRecurrence("Monthly");
                  }}
                  onKeyDown={(e) => {
                     if (e.key === "Enter") {
                        setRecurrence("Monthly");
                     }
                  }}
               >
                  Monthly
               </div>
               <div
                  style={{
                     fontSize: mobileView ? "1rem" : "19px",
                     lineHeight: "145%",
                     padding: mobileView ? "10px 7px" : "7px 29px",
                     color: loggedOut ? "white" : "black",
                     width: "50%",
                     textAlign: "center",
                     borderRadius: "24px",
                     backgroundColor:
                        recurrence !== "Monthly" ? "#4C4C50" : "#232328",
                  }}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                     setRecurrence("Yearly");
                  }}
                  onKeyDown={(e) => {
                     if (e.key === "Enter") {
                        setRecurrence("Yearly");
                     }
                  }}
               >
                  Yearly <span style={{ color: "#FFD645" }}>-17% off</span>
               </div>
            </div>
         ) : (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
               <div
                  className={
                     recurrence === "Monthly"
                        ? classes.activeRecurrence
                        : classes.inactiveRecurrence
                  }
               >
                  Monthly
               </div>
               <div>
                  <Switch
                     className={classes.switch}
                     checked={recurrence !== "Monthly"}
                     onChange={() => {
                        setRecurrence(
                           recurrence === "Monthly" ? "Yearly" : "Monthly"
                        );
                     }}
                  />
               </div>
               <div
                  className={
                     recurrence !== "Monthly"
                        ? classes.activeRecurrence
                        : classes.inactiveRecurrence
                  }
               >
                  Yearly
               </div>
            </div>
         )}
         <div style={{ display: "flex" }}>
            <ToggleButton
               data-testid="button-usd"
               sx={{
                  width: mobileView ? "33%" : "42px",
                  height: "42px",
                  backgroundColor: loggedOut ? "#232328" : "white",
                  color: loggedOut ? "white" : "black",
                  "&.Mui-selected, &.Mui-selected:hover": {
                     color: loggedOut ? "black" : "white",
                     backgroundColor: loggedOut ? "#BA9FFE" : "#6147FF",
                  },
               }}
               selected={currency === "USD"}
               value="USD"
               color={loggedOut ? "secondary" : "primary"}
               onChange={() => {
                  setCurrency("USD");
               }}
            >
               $
            </ToggleButton>
            <ToggleButton
               sx={{
                  margin: "0 8px",
                  width: mobileView ? "33%" : "42px",
                  height: "42px",
                  backgroundColor: loggedOut ? "#232328" : "white",
                  color: loggedOut ? "white" : "black",
                  "&.Mui-selected, &.Mui-selected:hover": {
                     color: loggedOut ? "black" : "white",
                     backgroundColor: loggedOut ? "#BA9FFE" : "#6147FF",
                  },
               }}
               data-testid="button-gbp"
               color={loggedOut ? "secondary" : "primary"}
               selected={currency === "GBP"}
               value="GBP"
               onChange={() => {
                  setCurrency("GBP");
               }}
            >
               £
            </ToggleButton>
            <ToggleButton
               sx={{
                  width: mobileView ? "33%" : "42px",
                  height: "42px",
                  backgroundColor: loggedOut ? "#232328" : "white",
                  color: loggedOut ? "white" : "black",
                  "&.Mui-selected, &.Mui-selected:hover": {
                     color: loggedOut ? "black" : "white",
                     backgroundColor: loggedOut ? "#BA9FFE" : "#6147FF",
                  },
               }}
               value="EUR"
               color={loggedOut ? "secondary" : "primary"}
               selected={currency === "EUR"}
               onChange={() => {
                  setCurrency("EUR");
               }}
            >
               €
            </ToggleButton>
         </div>
      </Box>
   );
};

export default CurrencySelector;

CurrencySelector.propTypes = {
   currency: PropTypes.string.isRequired,
   setCurrency: PropTypes.func.isRequired,
   recurrence: PropTypes.string.isRequired,
   setRecurrence: PropTypes.func.isRequired,
   loggedOut: PropTypes.bool,
};

CurrencySelector.defaultProps = {
   loggedOut: false,
};
