import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import Routes from "_routes/Routes";
import { GET_CURRENT_USER } from "_apollo/queries";
import getConfig, { CONFIG_ENV } from "_utils/getConfig";
import appStorage from "_utils/appStorage";
import isElectronHook from "_utils/isElectron";
import { UserProvider } from "_utils/UserContext";
import { MasteringDataContext } from "_utils/MasteringDataContext";
import { SoundsDataContext } from "_utils/SoundsDataContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { toggleAppMenu, setError, openLoadingAppModal } from "_redux/actions";
import CustomModal from "_components/Modal/CustomModal";
import { Box, CssBaseline, Typography } from "@mui/material";
import ErrorBoundary from "_components/ErrorBoundary";
import { UploadDataProvider } from "_utils/UploadDataContext";
import apiVersion from "apiVersion.json";
import ErrorPage from "_pages/ErrorPage";
import AppLoadingModal from "_components/AppLoadingModal";
import {
   ThemeProvider,
   StyledEngineProvider,
   createTheme,
} from "@mui/material/styles";
import getDesignTokens, { isDarkMode } from "themeHelpers";

const buildAPIVersion = apiVersion.apiVersion;

const { twitterConversationKey, restUrl } = getConfig();

/**
 * Aux App!
 * @returns {JSX.Element} The Aux App!
 */
const App = () => {
   const [apiVersionDisplay, setAPIVersionDisplay] = useState();
   const isElectron = isElectronHook();
   const dispatch = useDispatch();
   const { showLoadingAppModal } = useSelector((state) => state.AppReducer);
   const [showVersionOutdated, setShowVersionOutdated] = useState(false);

   // Auto switch theme when color mode change is detected.
   const [colorMode, setColorMode] = useState(isDarkMode ? "dark" : "light");
   window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
         const newColorScheme = event.matches ? "dark" : "light";
         setColorMode(newColorScheme);
      });

   // Update the theme only if the mode changes
   const theme = React.useMemo(
      () => createTheme(getDesignTokens(colorMode)),
      [colorMode]
   );

   const { data: currentUser } = useQuery(GET_CURRENT_USER, {
      fetchPolicy: "network-only",
      /**
       * currentUser onCompleted.
       * @param {object} data Apollo get current user data object.
       * @returns {void} void
       */
      onCompleted(data) {
         if (!data || !data?.currentUser?.profile?.uri) {
            appStorage.removeValue({
               key: "auxSelectedProfileURI",
            });
            appStorage.removeValue({
               key: "profileId",
            });
            return;
         }
         appStorage.setValue({
            key: "auxSelectedProfileURI",
            value: data?.currentUser?.profile?.uri,
         });
         appStorage.setValue({
            key: "profileId",
            value: data?.currentUser?.profile?.id,
         });
      },
   });
   // If the electron app is loading and there is a jwt show loading modal until the current user is loaded.
   useEffect(() => {
      if (isElectron && appStorage.getValue({ key: "auxJWT" })) {
         dispatch(openLoadingAppModal(true));
      }
   }, []);

   const uncollapseMenu = useMediaQuery("(min-width:1100px)");

   useEffect(() => {
      if (uncollapseMenu) {
         dispatch(toggleAppMenu("260"));
      } else {
         dispatch(toggleAppMenu("76"));
      }
   }, [uncollapseMenu]);

   // Append twitter conversation script.
   useEffect(() => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerHTML = `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
      },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
      a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
      // Insert Twitter Pixel ID and Standard Event data below
      twq('init','${twitterConversationKey}');`;
      document.body.appendChild(script);
      return () => {
         document.body.removeChild(script);
      };
   }, []);

   const url = new URL(window.location.href);
   const utmMedium = url.searchParams.get("utm_medium");
   const utmSource = url.searchParams.get("utm_source");
   const utmCampaign = url.searchParams.get("utm_campaign");
   const utmContent = url.searchParams.get("utm_content");
   // Get utm values on load
   useEffect(() => {
      if (utmMedium) {
         sessionStorage.setItem("utmMedium", utmMedium);
      }
      if (utmSource) {
         sessionStorage.setItem("utmSource", utmSource);
      }
      if (utmCampaign) {
         sessionStorage.setItem("utmCampaign", utmCampaign);
      }
      if (utmContent) {
         sessionStorage.setItem("utmContent", utmContent);
      }
   }, []);

   useEffect(() => {
      if (currentUser) {
         dispatch(openLoadingAppModal(false));
      }
   }, [currentUser]);

   useEffect(async () => {
      // Check app build is compatible with the API version.
      if (isElectron) {
         const response = await fetch(`${restUrl}/version`);

         // Catch error loading the api and show an error rather than the version out of date message.
         if (!response.ok) {
            // This error should will be caught by the error boundary and show the full page error screen.
            throw new Error("The API failed to load, the app may be updating.");
         } else {
            const remoteAPIVersion = await response.text();

            if (CONFIG_ENV !== "standalone") {
               setAPIVersionDisplay(buildAPIVersion);
            }

            setShowVersionOutdated(
               remoteAPIVersion.trim() !== buildAPIVersion.trim()
            );
         }
      }
   }, []);

   // useEffect to add online & offline events.
   useEffect(() => {
      window.addEventListener("online", () => {
         dispatch(setError(false));
      });
      window.addEventListener("offline", () => {
         dispatch(setError(true));
      });
   }, []);
   const [errorText, setErrorText] = useState("Unknown Error");
   return (
      <StyledEngineProvider injectFirst>
         <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className={`App ${colorMode}`}>
               {showLoadingAppModal && <AppLoadingModal />}
               <ErrorBoundary
                  userId={currentUser?.currentUser?.id}
                  onError={(err) => {
                     setErrorText(err);
                  }}
                  fallback={
                     <>
                        <ErrorPage error={errorText} />
                     </>
                  }
               >
                  <UploadDataProvider>
                     <MasteringDataContext>
                        <SoundsDataContext>
                           <UserProvider>
                              <>
                                 {/* <CustomModal open={showVersionOutdated}>
                                    <>
                                       {/* TODO: fix auto update and update this message to simply show ... updating with a progress bar. 
                                       <Typography variant="modalHeader">
                                          Time to Update
                                       </Typography>
                                       <Box px={2}>
                                          <p>
                                             Your version of the Aux app is out
                                             of date. Download the latest
                                             version to keep working on your
                                             projects.
                                          </p>
                                          <p>
                                             <em>
                                                Visit aux.app to download the
                                                new version.
                                             </em>
                                          </p>
                                       </Box>
                                    </>
                                 </CustomModal> */}
                                 <Routes key={colorMode} user={currentUser} />
                              </>
                           </UserProvider>
                        </SoundsDataContext>
                     </MasteringDataContext>
                  </UploadDataProvider>
               </ErrorBoundary>
               {CONFIG_ENV !== "standalone" &&
                  // If you want to hide the dev label (for video work for example) set auxHideDevLabel to anything in localStorage.
                  !localStorage.getItem("auxHideDevLabel") && (
                     <div
                        style={{
                           position: "fixed",
                           left: "95px",
                           top: "7px",
                           padding: "6px 12px",
                           backgroundColor:
                              CONFIG_ENV === "expo" ? "#ff0000" : "#00FF00",
                        }}
                     >
                        🚀
                        {" : "}
                        {CONFIG_ENV}
                        {window?.electronAPI?.isMac && " : "}
                        {window?.electronAPI?.isWindows && " : 🪟"}
                        {apiVersionDisplay && (
                           <>
                              {" : "}
                              {apiVersionDisplay}
                           </>
                        )}
                        {isElectronHook() &&
                           appStorage.getValue({ key: "auxDeviceID" }) && (
                              <>
                                 {" : "}
                                 <span>
                                    {appStorage
                                       .getValue({ key: "auxDeviceID" })
                                       .slice(-4)}
                                 </span>
                              </>
                           )}
                     </div>
                  )}
            </div>
         </ThemeProvider>
      </StyledEngineProvider>
   );
};

export default App;
