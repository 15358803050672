import React from "react";
import PropTypes from "prop-types";

const Category = ({ id, name, removeCategories }) => {
   return (
      <div
         style={{ backgroundColor: "#ff9f69" }}
         className="ml-1 p-1 text-capitalize text-white"
         key={id}
      >
         {name}
         {removeCategories && (
            <span
               role="button"
               tabIndex={0}
               className="tag-close-icon"
               onClick={() => removeCategories(name)}
               onKeyDown={(event) => {
                  if (event.key === "Backspace") {
                     removeCategories(name);
                  }
               }} //
            >
               &nbsp; x
            </span>
         )}
      </div>
   );
};

Category.propTypes = {
   id: PropTypes.number.isRequired,
   name: PropTypes.string.isRequired,
   removeCategories: PropTypes.func.isRequired,
};

export default Category;
