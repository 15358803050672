import { PaletteMode } from "@mui/material";

export const isDarkMode =
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;
export const isLightMode = !isDarkMode;

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    //  mode,
    ...(mode === "dark"
      ? {
          // Dark mode
          primary: {
            main: "#6147FF",
            500: "#6202EE",
          },
          secondary: {
            dark: "#666666",
            main: "#C4C4C4",
            shade5: "#F2F2F2",
            shade15: "#D9D9D9",
            shade30: "#B3B3B3",
            shade45: "#8C8C8C",
            shade60: "#666666",
          },
          danger: {
            main: "#E63E33",
            dark: "#bf332a",
          },
          muted: {
            main: "#8C8C8C",
          },
          gray: {
            main: "#D9D9D9",
          },
          strong: {
            yellow: "#FFD645",
            orange: "#FF8845",
            green: "#0CECC4",
            red: "#FF4539",
            darkBlue: "#6147FF",
          },
          light: {
            main: "#fff",
          },
          dark: {
            main: "#232328",
          },
          coreApp: {
            text: "#e2e2e3",
            background: "#232328",
            // borders: "#4C4C50",
            borders: "#77777A",
          },
          purple: {
            main: "#9D75FF",
          },
          hover: {
            red: "#E63E33",
            darkBlue: "#5740E6",
          },
          pastel: {
            darkBlue: "#C8BFFF",
            purple: "#F1EFFE",
          },
        }
      : {
          // Light mode.
          primary: {
            main: "#6147FF",
            500: "#6202EE",
          },
          secondary: {
            dark: "#666666",
            main: "#C4C4C4",
            shade5: "#F2F2F2",
            shade15: "#D9D9D9",
            shade30: "#B3B3B3",
            shade45: "#8C8C8C",
            shade60: "#666666",
          },
          danger: {
            main: "#E63E33",
            dark: "#bf332a",
          },
          muted: {
            main: "#8C8C8C",
          },
          gray: {
            main: "#D9D9D9",
          },
          strong: {
            yellow: "#FFD645",
            orange: "#FF8845",
            green: "#0CECC4",
            red: "#FF4539",
            darkBlue: "#6147FF",
          },
          light: {
            main: "#fff",
          },
          dark: {
            main: "#232328",
          },
          coreApp: {
            text: "#000",
            background: "#fff",
            borders: "#D9D9D9",
          },
          purple: {
            main: "#9D75FF",
          },
          hover: {
            red: "#E63E33",
            darkBlue: "#5740E6",
          },
          pastel: {
            darkBlue: "#C8BFFF",
            purple: "#F1EFFE",
          },
        }),
  },
  typography: {
    fontFamily: ["Inter", "Arial"].join(", "),
    h1: { fontSize: "2.5rem", fontWeight: "normal" },
    h2: { fontSize: "2rem", fontWeight: "normal" },
    h3: { fontSize: "1.5rem", fontWeight: "normal" },
    h4: { fontSize: "1.25rem", fontWeight: "normal" },
    h5: { fontSize: "1.125rem", fontWeight: "normal" },
    h6: { fontSize: "1rem", fontWeight: "normal" },
    big1: { fontSize: "3rem", fontWeight: "normal" },
    big2: { fontSize: "6rem", fontWeight: "normal" },
    small1: { fontSize: "0.875rem", fontWeight: "normal" },
    small2: { fontSize: "0.75rem", fontWeight: "normal" },
    button: {
      textTransform: "none",
      fontSize: "0.875rem",
      lineHeight: "20px",
      fontWeight: "normal",
    },
    modalHeader: {
      fontSize: "1.13rem",
      fontWeight: 700,
      textAlign: "center",
      display: "block",
    },
    modalMessage: {
      fontSize: "0.875rem",
      fontWeight: 400,
      textAlign: "center",
      display: "block",
      maxWidth: "85%",
      margin: "auto",
    },
    large: {
      fontSize: "1.125rem",
    },
    medium: {
      fontSize: "0.875rem",
    },
    small: {
      fontSize: "0.75rem",
      lineHeight: "0.875rem",
    },
    largeBold: {
      fontSize: "1.125rem",
      fontWeight: "bold",
    },
    mediumBold: {
      fontSize: "0.875rem",
      fontWeight: "bold",
    },
    smallBold: {
      fontSize: "0.75rem",
      fontWeight: "bold",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: mode === "dark" ? "#e2e2e3" : "#000",
          backgroundColor: mode === "dark" ? "#232328" : "#fff",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: mode === "dark" ? "#e2e2e3" : "#000",
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        container: {
          color: mode === "dark" ? "#e2e2e3" : "#000",
          backgroundColor: mode === "dark" ? "#232328" : "#fff",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          border: `solid 1px #D9D9D9`,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "0 16px",
          // color: "coreApp.text",
          backgroundColor: "transparent",
          borderBottomColor: mode === "dark" ? "#77777A" : "#D9D9D9",
        },
        head: {
          backgroundColor: mode === "dark" ? "#232328" : "#fff",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#F2F2F2",
          borderRadius: 4,
          "& fieldset": {
            border: "none",
            borderRadius: 4,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: 16,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: mode === "dark" ? "#e2e2e3" : "#000",
          marginBottom: 10,
          fontSize: "0.75rem",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: mode === "dark" ? "#77777A" : "#D9D9D9",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          padding: "6px 12px",
          borderRadius: "4px",
          variants: [
            {
              props: { variant: "outlined" },
              style: {
                color: mode === "dark" ? "#e2e2e3" : "",
                "&:hover": {
                  color: "#6147FF",
                },
              },
            },
          ],
        },
      },
    },
  },
  ghostHtml: {
    "& figure": {
      margin: 0,
      "& iframe": {
        width: "100%",
        minHeight: "33.76vw",
      },
      "& img": {
        maxWidth: "100%",
        height: "auto",
      },
    },
    "& p": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      lineHeight: "1.5em",
      "& a": {
        color: "#6147FF",
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
      },
      "& img": {
        maxWidth: "100%",
      },
    },
    "& hr": {
      width: "100vw",
      position: "relative",
      left: "50%",
      right: "50%",
      marginLeft: "-50vw",
      marginRight: "-50vw",
      backgroundColor: "#F2F2F2",
      border: "none",
      height: 2,
    },
    "& .kg-image-card": {
      width: "80%",
      minWidth: "200px",
      margin: "auto",
      "& > figcaption": {
        fontSize: 19,
      },
      "@media (max-width: 575px)": {
        width: "100%",
      },
    },
    "& .kg-width-wide": {
      width: "100%",
    },
    "& .kg-width-full": {
      width: "100vw !important",
      position: "relative",
      margin: "0 calc(-50vw + 50%)",
      "& > figcaption": {
        textAlign: "center",
      },
    },
    "& blockquote": {
      position: "relative",
      paddingLeft: 25,
      margin: "40px 0px",
      lineHeight: "24px",
      "&:after": {
        content: `""`,
        display: "block",
        position: "absolute",
        height: "calc(100% - 50px)",
        left: 0,
        top: 0,
        border: "1px solid #810000",
      },
      "& strong": {
        color: "#707070",
        fontWeight: "400",
      },
      "& .kg-gallery-container": {
        display: "flex",
        flexDirection: "column",
        gap: 16,
        "& .kg-gallery-row": {
          display: "flex",
          gap: 16,
          "& .kg-gallery-image > img": {
            display: "block",
          },
        },
      },
    },
    "& a.kg-bookmark-container": {
      maxWidth: "720px",
      wordBreak: "break-word",
      textDecoration: "none",
      display: "flex",
      borderRadius: "5px",
      margin: "50px auto",
      background: "hsla(0,0%,100%,.6)",
      boxShadow: "0 2px 6px -2px rgb(0 0 0 / 10%), 0 0 1px rgb(0 0 0 / 40%)",
      overflow: "hidden",
      "& div.kg-bookmark-thumbnail": {
        position: "relative",
        flexGrow: 1,
        minWidth: "33%",
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          borderRadius: "0 4px 4px 0",
        },
      },
      "& div.kg-bookmark-content": {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        flexBasis: "100%",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: "20px",
        "& .kg-bookmark-title": {
          fontSize: "16px",
          lineHeight: "14px",
          fontWeight: 600,
          color: "black",
        },
        "& .kg-bookmark-description": {
          display: "-webkit-box",
          fontSize: "14px",
          lineHeight: "16px",
          fontWeight: 400,
          color: "#626d79",
          marginTop: "8px",
          maxHeight: "44px",
          overflowY: "hidden",
          "-webkit-line-clamp": 2,
          "-webkit-box-orient": "vertical",
        },
        "& .kg-bookmark-metadata": {
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
          fontWeight: 500,
          color: "#394047",
          marginTop: "32px",
          "& img": {
            width: "20px",
            height: "20px",
            marginRight: "6px",
            verticalAlign: "middle",
            border: 0,
            display: "block",
            maxWidth: "100%",
          },
        },
      },
    },
  },
  // Click events are eaten by the -webkit-app-region: drag; so we need to disable it for anything that is interactive.
  noDrag: {
    "-webkit-user-select": "none",
    "-webkit-app-region": "no-drag",
  },
});

export default getDesignTokens;
