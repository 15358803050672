import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";

// Redux

import { useMutation } from "@apollo/react-hooks";

// Apollo
import { Box, InputLabel } from "@mui/material";
import useStatsMutation from "_utils/useStatsMutation";
import { useUser } from "_utils/UserContext";
import appStorage from "_utils/appStorage";
import { UPDATE_PROFILE_INFO } from "../../../../_apollo/queries";

// DND

import ReservedUsernames from "../../../../ReservedUsernames";
import TagForm from "../../../../_components/TagForm";

const useStyles = makeStyles(() => ({
   inputLinksContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 8,
   },
   inputLinks: {
      [`& fieldset`]: {
         borderRadius: 0,
      },
   },
}));
const ProfileInformationForm = ({
   handleNextSlide,
   isManagingProfile,
   managingProfile,
   selectedTeam,
}) => {
   const classes = useStyles();
   const [user] = useUser();
   const sendStatsMutation = useStatsMutation();

   const profileForm = useRef();

   const [tags, setTags] = useState([]);
   const [deletedTags, setDeletedTags] = useState([]);
   const [isUpdated, setIsUpdated] = useState(false);

   const validationSchemaProfile = Yup.object().shape({
      tag: Yup.string().label("Tag").nullable(),
      bio: Yup.string().max(1000).nullable().label("Bio"),
      profileLink: Yup.string()
         .required()
         .min(3)
         .matches(
            /^[0-9a-z\-_]+$/g,
            "Only lower-case letters, numbers and dashes allowed"
         )
         .max(40)
         .notOneOf(ReservedUsernames, "Profile Link is reserved")
         .label("Profile Link"),
   });

   const [
      updateProfileInfo,
      { loading: loadingProfileInfo, error: errorUpdateProfileInfo },
   ] = useMutation(UPDATE_PROFILE_INFO, {
      onCompleted({ updateProfileInfo: data }) {
         setIsUpdated(true);
         setTimeout(() => {
            setIsUpdated(false);
         }, 5000);
         sendStatsMutation({
            statsId: "UserProfileUpdated",
            userId: user.id,
         });
         appStorage.setValue({
            key: "auxSelectedProfileURI",
            value: data?.profile?.uri,
         });
         handleNextSlide();
      },
   });

   const textButton = useMemo(() => {
      if (loadingProfileInfo) {
         return "Loading...";
      }
      if (isUpdated) {
         return "Updated";
      }
      return "Update";
   }, [isUpdated, loadingProfileInfo]);

   useEffect(() => {
      const profileTags = isManagingProfile
         ? managingProfile?.tags
         : user?.profile?.tags;
      if (profileTags) {
         setTags(profileTags);
      }
   }, [user?.profile?.tags, managingProfile?.tags, isManagingProfile]);

   return (
      <Box>
         <Formik
            innerRef={profileForm}
            enableReinitialize
            initialValues={{
               tag: "",
               bio:
                  (isManagingProfile
                     ? managingProfile?.description
                     : user?.profile?.description) || "",
               location:
                  (isManagingProfile
                     ? managingProfile?.location
                     : user?.profile?.location) || "",
               profileLink:
                  (isManagingProfile
                     ? managingProfile?.uri
                     : user?.profile?.uri) || "",
            }}
            validateOnChange
            validationSchema={validationSchemaProfile}
            onSubmit={async (values) => {
               const defaultProfileLink = isManagingProfile
                  ? managingProfile?.uri
                  : user?.profile?.uri;
               const isProfileLinkDiff = !(
                  defaultProfileLink === values.profileLink
               );
               await updateProfileInfo({
                  variables: {
                     tags: tags?.map((t) => t.name),
                     deletedTags,
                     bio: values.bio,
                     location: values.location,
                     profileLink: isProfileLinkDiff ? values.profileLink : null,
                     id: managingProfile?.id || null,
                     teamId: selectedTeam,
                  },
               });
            }}
            initialTouched={{
               tag: false,
            }}
         >
            {({
               handleSubmit,
               handleChange,
               errors,
               values,
               setFieldError,
               setFieldValue,
               setFieldTouched,
               touched,
            }) => {
               if (
                  errorUpdateProfileInfo &&
                  errorUpdateProfileInfo.graphQLErrors
               ) {
                  errorUpdateProfileInfo?.graphQLErrors?.forEach((event) => {
                     setFieldError("profileLink", event?.message);

                     // Delete the errors to avoid re-render loop
                     delete errorUpdateProfileInfo.graphQLErrors;
                  });
               }
               return (
                  <form onSubmit={handleSubmit} id="submit-profile-info-form">
                     <div className={classes.inputLinksContainer}>
                        <InputLabel>Tags</InputLabel>
                        <TagForm
                           setDeletedTags={setDeletedTags}
                           setTags={setTags}
                           profileForm={profileForm}
                           tags={tags}
                           values={values}
                           setFieldValue={setFieldValue}
                           handleChange={handleChange}
                           errors={errors}
                           touched={touched}
                           setFieldTouched={setFieldTouched}
                           profile={user?.profile}
                           showSubtitle
                        />
                     </div>
                     <div className={classes.inputLinksContainer}>
                        <InputLabel>Bio</InputLabel>
                        <TextField
                           size="small"
                           className={classes.inputLinks}
                           variant="outlined"
                           id="bio"
                           name="bio"
                           multiline
                           rows={3}
                           placeholder="Bio"
                           value={values.bio}
                           onChange={handleChange}
                           error={touched.bio && Boolean(errors.bio)}
                           helperText={touched.bio && errors.bio}
                        />
                     </div>
                     <div className={classes.inputLinksContainer}>
                        <InputLabel>Profile Link</InputLabel>
                        <TextField
                           size="small"
                           className={classes.inputLinks}
                           variant="outlined"
                           id="profileLink"
                           name="profileLink"
                           placeholder="profileLink"
                           value={values.profileLink}
                           onChange={handleChange}
                           error={
                              touched.profileLink && Boolean(errors.profileLink)
                           }
                           helperText={
                              touched.profileLink && errors.profileLink
                           }
                           InputProps={{
                              startAdornment: (
                                 <InputAdornment position="start">
                                    aux.app/
                                 </InputAdornment>
                              ),
                           }}
                        />
                     </div>
                     <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        disabled={loadingProfileInfo}
                        id="submit-profile-info"
                        sx={
                           isUpdated
                              ? {
                                   backgroundColor: "#AE47FF",
                                   "&:hover": {
                                      backgroundColor: "#AE47FF",
                                      boxShadow: "none",
                                   },
                                }
                              : {}
                        }
                     >
                        {textButton}
                     </Button>
                  </form>
               );
            }}
         </Formik>
      </Box>
   );
};

ProfileInformationForm.propTypes = {
   handleNextSlide: PropTypes.func.isRequired,
   isManagingProfile: PropTypes.bool.isRequired,
   managingProfile: PropTypes.instanceOf(Object),
   selectedTeam: PropTypes.number,
};

ProfileInformationForm.defaultProps = {
   managingProfile: null,
   selectedTeam: null,
};

export default ProfileInformationForm;
