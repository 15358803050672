import { Grid, IconButton, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Delete from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";

/**
 * File Archive Action Buttons.
 * @param {object} param0 props.
 * @param {Function} param0.setShowDeleteModal show delete modal.
 * @param {Function} param0.trashFilesFolders cb after confirm modal.
 * @returns {JSX.Element} Restore and delete buttons.
 */
const FileArchiveActionButtons = ({
  setShowDeleteModal,
  trashFilesFolders,
}) => {
  const theme = useTheme<any>();
  return (
    <Grid container spacing={1} style={{ width: "auto", marginLeft: 5 }}>
      <Grid item>
        <IconButton onClick={() => trashFilesFolders()}>
          <RestoreIcon style={{ fill: theme.palette.coreApp.text }} />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={() => setShowDeleteModal(true)}>
          <Delete style={{ fill: theme.palette.danger.main }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};
FileArchiveActionButtons.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired,
  trashFilesFolders: PropTypes.func.isRequired,
};

export default FileArchiveActionButtons;
