import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import downloadApp from "_utils/downloadApp";
import { Button } from "@mui/material";
import AuxOpenMusicLogo from "../_assets/AuxOpenMusicLogo";

const useStyles = makeStyles((theme) => ({
   logoFooter: {
      marginBottom: "auto",
      marginRight: "auto",
   },
   wrapperLogo: {
      marginBottom: "50px",
      marginRight: "auto",
      "@media (max-width: 768px)": {
         width: "100%",
      },
   },
   footerContainer: {
      height: "490px",
      zIndex: 2,
      backgroundColor: "white",
      position: "relative",
   },
   footerItemContainer2: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      marginLeft: "3vw",
      flex: 2,
      "@media (max-width: 768px)": {
         marginLeft: "0",
         marginRight: "3vw",
      },
   },
   footerWrapper: {
      height: "100%",
   },
   footerTitles: {
      fontFamily: "Inter",
      fontSize: theme.typography.h6.fontSize,
      lineHeight: "24px",
      fontWeight: "bold",
      marginTop: "14px",
   },
   footerItems: {
      fontSize: theme.typography.h6.fontSize,
      lineHeight: "24px",
      "& a, & button": {
         fontSize: "inherit",
         padding: 0,
         textDecoration: "none",
         color: "black",
         "&:hover": {
            color: theme.palette.primary.main,
         },
      },
   },
   gridTemplate: {
      display: "grid",
      // gridTemplateColumns: "1fr 1fr 1fr 1fr",
      gridTemplateColumns: "1fr 1fr 1fr", // three column for the beta
      gridTemplateRows: "1fr",
      "@media (max-width: 520px)": {
         gridTemplateRows: "1fr 1fr ",
         gridTemplateColumns: "1fr 1fr",
      },
   },
   flexWrapper: {
      display: "flex",
      padding: "40px 0px",
      flexWrap: "wrap",
      alignItems: "start",
   },
   copyright: {
      padding: "32px 0",
      marginTop: 0,
   },
}));

const Footer = () => {
   const classes = useStyles();

   return (
      <div className={classes.footerContainer}>
         <Container className={classes.footerWrapper}>
            <div className={classes.flexWrapper}>
               <div className={classes.wrapperLogo}>
                  <AuxOpenMusicLogo className={classes.logoFooter} />
               </div>
               <div className={classes.gridTemplate}>
                  <div className={classes.footerItemContainer2}>
                     <h4 className={classes.footerTitles}>App</h4>
                     <p className={classes.footerItems}>
                        <Button variant="text" onClick={downloadApp}>
                           Download
                        </Button>
                     </p>
                     <p className={classes.footerItems}>
                        <Link to="/signup">Sign Up</Link>
                     </p>
                     <p className={classes.footerItems}>
                        <Link to="/signin">Sign In</Link>
                     </p>
                     <p className={classes.footerItems}>
                        <Link to="/pricing">Pricing</Link>
                     </p>
                     <p className={classes.footerItems}>
                        <Link to="/connect">Connect Magazine</Link>
                     </p>
                     <p className={classes.footerItems}>
                        <Link to="/discover">Discover</Link>
                     </p>
                     <p className={classes.footerItems}>
                        <Link to="/info/students-offer-code-coupon-code-deals">
                           Students
                        </Link>
                     </p>
                     <p className={classes.footerItems}>
                        <Link to="/info/students-offer-code-coupon-code-deals">
                           Coupon Codes
                        </Link>
                     </p>
                     {/* }
                     <p className={classes.footerItems}>
                        <Link to="/">Awards</Link>
                     </p>
                     <p className={classes.footerItems}>
                        <Link to="/">Education</Link>
                     </p> */}
                  </div>
                  {/* <div className={classes.footerItemContainer2}>
                     <h4 className={classes.footerTitles}>Downloads</h4>
                     <p className={classes.footerItems}>
                        <Link to="/">IOS</Link>
                     </p>
                     <p className={classes.footerItems}>
                        <Link to="/">Android</Link>
                     </p>
                     <p className={classes.footerItems}>
                        <Link to="/">Windows</Link>
                     </p>
                     <p className={classes.footerItems}>
                        <Link to="/">Mac</Link>
                     </p>
                  </div> */}
                  <div className={classes.footerItemContainer2}>
                     <h4 className={classes.footerTitles}>Social</h4>
                     <p className={classes.footerItems}>
                        <a href="https://www.youtube.com/channel/UCF9nOM0XwBisaNUMaK-XBcw?sub_confirmation=1">
                           YouTube
                        </a>
                     </p>
                     <p className={classes.footerItems}>
                        <a href="https://www.instagram.com/auxdotapp/">
                           Instagram
                        </a>
                     </p>
                     <p className={classes.footerItems}>
                        <a href="https://twitter.com/auxdotapp">Twitter</a>
                     </p>
                     <p className={classes.footerItems}>
                        <a href="https://www.facebook.com/auxdotapp">
                           Facebook
                        </a>
                     </p>
                     <p className={classes.footerItems}>
                        <a href="https://www.linkedin.com/company/74905786/">
                           LinkedIn
                        </a>
                     </p>
                  </div>
                  <div className={classes.footerItemContainer2}>
                     <h4 className={classes.footerTitles}>Company</h4>
                     <p className={classes.footerItems}>
                        <Link to="/connect/welcome">About Aux</Link>
                     </p>
                     <p className={classes.footerItems}>
                        <a href="https://indie.tech/what-is-indie/">
                           About Indie
                        </a>
                     </p>
                     <p className={classes.footerItems}>
                        <Link to="/info/careers">Work at Aux</Link>
                     </p>
                     <p className={classes.footerItems}>
                        <Link to="/info/terms-of-service">
                           Terms of Service
                        </Link>
                     </p>
                     <p className={classes.footerItems}>
                        <Link to="/info/privacy-policy">Privacy Policy</Link>
                     </p>
                     <p className={classes.footerItems}>
                        <Link to="/info/subscription-terms">
                           Subscription Terms
                        </Link>
                     </p>
                  </div>
               </div>
            </div>

            <p className={classes.copyright}>© Aux Music Limited</p>
         </Container>
      </div>
   );
};

export default Footer;
