import { Search } from "@mui/icons-material";
import { Box, InputBase } from "@mui/material";
import React from "react";

/**
 *FilterInput
 * @param {object} param0 props.
 * @param {string} param0.filter filter string.
 * @param {Function} param0.setFilter set filter.
 * @returns {JSX.Element} component.
 */
const FilterInput = ({ filter, setFilter }) => {
   return (
      <Box
         display="flex"
         alignItems="center"
         backgroundColor="secondary.shade5"
         borderRadius="2px"
         padding="7px 8px"
         width="300px"
      >
         <Box mr="9px" display="flex">
            <Search />
         </Box>
         <InputBase
            size="small"
            variant="outlined"
            placeholder="Filter"
            value={filter}
            sx={{
               color: "secondary.shade60",
               padding: "0px",
               "& input": { padding: "0px" },
            }}
            onChange={(e) => setFilter(e.target.value)}
         />
      </Box>
   );
};

export default FilterInput;
