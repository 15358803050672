import { SubscriptionPlans } from "../_constants/GlobalVariables";
import getUsersPlan from "./getUsersPlan";

const { Limits } = SubscriptionPlans;

/**
 * Get Subscription limit.
 * @param {object} user current user.
 * @returns {object} Limit object.
 */
const getSubscriptionLimit = (user) => {
   const usersSubscriptionEnum = getUsersPlan(
      user?.subscriptions?.stripePlan
   )?.modalEnum;
   // Or operator to default to the basic free subscription.
   return Limits.find((l) => l.planEnum === usersSubscriptionEnum) || Limits[0];
};

export default getSubscriptionLimit;
