import PropTypes from "prop-types";
import React from "react";

const ReactLabelComponent = ({ width, body }) => (
   <p style={{ maxWidth: `${width}px`, fontSize: "14px", lineHeight: "20px" }}>
      {body}
   </p>
);

ReactLabelComponent.propTypes = {
   body: PropTypes.string.isRequired,
   width: PropTypes.number.isRequired,
};

export default ReactLabelComponent;
