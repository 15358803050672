import React, { useCallback, useEffect, useRef, useState } from "react";
import {
   Box,
   Button,
   Divider,
   IconButton,
   Skeleton,
   Tooltip,
   Typography,
   useTheme,
} from "@mui/material";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Fullscreen } from "@mui/icons-material";

import downloadFile from "_utils/downloadFiles";
import {
   getExtension,
   getNameWithoutExtension,
} from "_utils/fileTypeUtil/fileTypeUtil";
import formatBytes from "_utils/formatBytes";
import { makeStyles } from "@mui/styles";
import { TRASH_FILES_FOLDERS, GET_PROJECTS } from "_apollo/queries";
import { useMutation } from "@apollo/react-hooks";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import GrayPopover from "_components/GrayPopover";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import useDownloadZip from "_utils/useDownloadZip";
import MediaFullScreen from "./MediaFullScreen";
import MediaPlayer from "./MediaPlayer";

const useStyles = makeStyles((theme) => ({
   header: {
      "& .MuiIconButton-root": {
         padding: 9,
      },
   },
   // headerDetails: {
   //    display: "flex",
   //    flex: 1,
   //    alignItems: "center",
   //    flexWrap: "wrap",
   //    minWidth: 0,
   // },
   // headerButtons: {
   //    display: "flex",
   //    justifyContent: "flex-end",
   //    flexWrap: "wrap",
   //    "& button": {
   //       padding: "5px !important",
   //    },
   //    marginBottom: "13px",
   // },
   // name: {
   //    fontWeight: "bold",
   //    marginRight: 10,
   //    width: "70%",
   // },
   type: {
      fontSize: theme.typography.small2.fontSize,
      fontWeight: "400",
      textTransform: "capitalize",
      display: "flex",
   },
   containerAudio: {
      padding: "12px 16px",
      borderBottom: `solid 1px ${theme.palette.secondary.shade15}`,
   },
   containerImageVideo: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      padding: "10px 0",
      borderBottom: `solid 1px ${theme.palette.secondary.shade15}`,
   },
   containerNoPreview: {
      fontSize: "0.8rem",
      padding: "40px 16px",
      textAlign: "center",
      borderBottom: `solid 1px ${theme.palette.secondary.shade15}`,
   },
   cornerIconBtn: {
      position: "absolute",
      right: 0,
      top: 0,
      zIndex: 4,
   },
   fileMessage: {
      maxHeight: "140px",
      height: "100%",
      width: "auto",
   },
   mutedBtn: {
      position: "absolute",
      top: "calc(50% - 20px)",
      left: "calc(50% - 16px)",
      bottom: "50%",
      right: "50%",
      width: 10,
      zIndex: 5,
      backgroundColor: "white",
      padding: "20px",
      "&:hover": {
         backgroundColor: theme.palette.primary.main,
         color: "white",
      },
   },
   // emptyBouncedown: {
   //    backgroundColor: theme.palette.secondary.shade5,
   //    color: theme.palette.secondary.shade60,
   //    padding: "24px 5px 24px 10px",
   //    height: "40px",
   //    display: "flex",
   //    alignItems: "center",
   //    gap: "11px",
   // },
   headerDetailsFile: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      flexWrap: "wrap",
      minWidth: 0,
      marginBottom: "11.5px",
      padding: "10px 16px",
      "& .MuiButtonBase-root ": {
         padding: "5px 5px 5px 0 !important",
         marginRight: "10px",
      },
   },
   popoverContainer: {
      "& > .MuiPopover-paper": {
         background: theme.palette.secondary.shade5,
         boxShadow:
            "-1px -1px 3px rgba(102, 102, 102, 0.15), 3px 5px 5px rgba(102, 102, 102, 0.15)",

         "& .MuiTypography-root": {
            padding: "3px 20px !important",
         },
      },
   },
}));

// TODO: update to download folder when use clicks download button.?????

/**
 * File Preview
 *
 * Side panel component that renders the file preview.
 * @param {object} props props.
 * @param {object} props.fileFolder file, or folder for package type DAW files, which the preview renders.
 * @returns {JSX.Element} File preview Component.
 */
const FilePreview = ({ fileFolder }) => {
   const classes = useStyles();
   const theme = useTheme();
   const smallVideoRef = useRef();
   const history = useHistory();
   const location = useLocation();
   const [canPlay, setCanPlay] = useState(false);

   const [popover, setPopover] = useState(false);

   const downloadZip = useDownloadZip();

   useEffect(() => {
      const obj = document.createElement("video");
      if (fileFolder?.mimeType?.includes("video")) {
         if (obj.canPlayType(fileFolder?.mimeType) === "") {
            setCanPlay(false);
            return;
         }
         setCanPlay(true);
      }
   }, [fileFolder?.mimeType, fileFolder?.uri]);

   const [open, setOpen] = useState(false);

   const [trashFilesFolders] = useMutation(TRASH_FILES_FOLDERS, {
      refetchQueries: [{ query: GET_PROJECTS }],
      /**
       * trashFilesFolders onCompleted
       * Uses redirects to hide the 3rd column preview of files.
       * Triggers sync in electron after archiving a fileFolder.
       */
      onCompleted() {
         const pathUrl = location.pathname;
         if (pathUrl.split("/").length === 3) {
            // Preview on root
            history.push("/files");
         } else {
            // preview on files page
            history.push(pathUrl.split("/").slice(0, -2).join("/"));
         }
      },
   });

   /**
    * Render Media
    * @returns {JSX.Element | null} Media of the fileFolder.
    */
   const renderMedia = useCallback(() => {
      if (
         fileFolder?.mimeType?.includes("audio")
         // fileFolder?.bouncedown ||
         // isSupportedDAW({ file: fileFolder })
      ) {
         // TODO: consider still allowing a bouncedown to be added to DAW project...
         // if (isSupportedDAW({ file: fileFolder }) && !fileFolder.bouncedown) {
         //    return (
         //       <div className={classes.emptyBouncedown}>
         //          <PlayCircleIcon
         //             style={{ height: "40px", width: "40px", fill: "#B3B3B3" }}
         //          />
         //          <Typography variant="medium">
         //             Upload your bouncedown to preview
         //          </Typography>
         //       </div>
         //    );
         // }
         return (
            <div className={classes.containerAudio}>
               <MediaPlayer filePreview={fileFolder.bouncedown || fileFolder} />
            </div>
         );
      }

      if (fileFolder?.mimeType?.includes("image")) {
         return (
            <div key={fileFolder?.id} className={classes.containerImageVideo}>
               <img
                  className={classes.fileMessage}
                  src={fileFolder.signedUrl}
                  alt={fileFolder?.originalName}
               />
            </div>
         );
      }

      if (fileFolder?.mimeType?.includes("video")) {
         return canPlay ? (
            <div key={fileFolder?.id} className={classes.containerImageVideo}>
               <Tooltip title="Fullscreen" placement="bottom">
                  <IconButton
                     size="small"
                     className={classes.cornerIconBtn}
                     onClick={() => {
                        smallVideoRef?.current?.pause();
                        setOpen(true);
                     }}
                  >
                     <Fullscreen />
                  </IconButton>
               </Tooltip>
               {open && (
                  <MediaFullScreen
                     open={open}
                     setOpen={(val) => {
                        smallVideoRef?.current?.play();
                        setOpen(val);
                     }}
                     file={fileFolder}
                  />
               )}
               <>
                  <IconButton
                     variant="outlined"
                     size="large"
                     className={classes.mutedBtn}
                     onClick={() => {
                        smallVideoRef?.current?.pause();
                        setOpen(true);
                     }}
                  >
                     <VolumeOffIcon />
                  </IconButton>
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video
                     className={classes.fileMessage}
                     src={fileFolder.signedUrl}
                     autoPlay
                     muted
                     ref={smallVideoRef}
                     onError={() => setCanPlay(false)}
                     onLoadedData={(e) => {
                        if (
                           e?.target?.getVideoPlaybackQuality()
                              ?.totalVideoFrames === 0
                        ) {
                           setCanPlay(false);
                        }
                     }}
                  >
                     No file preview to display for this file type.
                  </video>
               </>
            </div>
         ) : (
            <div className={classes.containerNoPreview}>
               No file preview to display for this file type.
            </div>
         );
      }
      return (
         <div className={classes.containerNoPreview}>
            No file preview to display for this file type.
         </div>
      );
   }, [fileFolder?.uri, canPlay, open]);

   return (
      <>
         <div className={classes.header}>
            <div className={classes.headerDetailsFile}>
               <Button
                  component={Link}
                  to={`/files${
                     fileFolder?.folder?.uri
                        ? `/folder/${fileFolder?.folder?.uri}`
                        : ""
                  }`}
               >
                  <ChevronLeftIcon sx={{ fill: theme.palette.coreApp.text }} />
                  <Typography
                     color={theme.palette.coreApp.text}
                     fontWeight="bold"
                     variant="h5"
                     noWrap
                  >
                     {!getNameWithoutExtension(fileFolder) && (
                        <Skeleton width={300} />
                     )}
                     {getNameWithoutExtension(fileFolder)}
                  </Typography>
               </Button>

               <Typography mr="auto" className={classes.type}>
                  &nbsp;
                  {!getExtension(fileFolder) ? (
                     <Skeleton width={30} style={{ display: "inline-block" }} />
                  ) : (
                     <>{getExtension(fileFolder).slice(1).toUpperCase()}</>
                  )}
                  {!fileFolder?.size || !formatBytes(fileFolder?.size) ? (
                     <Skeleton
                        width={20}
                        style={{ display: "inline-block", marginLeft: 6 }}
                     />
                  ) : (
                     <>&nbsp;- {formatBytes(fileFolder?.size)}</>
                  )}
               </Typography>

               <IconButton
                  className={classes.iconButton}
                  onClick={(e) => {
                     setPopover(e.currentTarget);
                  }}
               >
                  <MoreHorizIcon sx={{ fill: theme.palette.coreApp.text }} />
               </IconButton>
               <GrayPopover
                  id="popover-listings-project"
                  open={!!popover}
                  anchorEl={popover}
                  onClose={() => setPopover(false)}
                  anchorOrigin={{
                     vertical: "bottom",
                     horizontal: "right",
                  }}
                  className={classes.popoverContainer}
                  transformOrigin={{
                     vertical: "top",
                     horizontal: "right",
                  }}
               >
                  <Box
                     sx={{
                        "& p": {
                           p: "3px 0px 3px 20px !important",
                        },
                     }}
                  >
                     <Typography
                        onClick={() => {
                           // eslint-disable-next-line no-underscore-dangle
                           if (fileFolder?.__typename === "File") {
                              downloadFile(fileFolder);
                           } else {
                              downloadZip([], [fileFolder]);
                           }
                        }}
                     >
                        Download file
                     </Typography>
                     <Typography
                        onClick={() =>
                           trashFilesFolders({
                              variables: {
                                 fileIds:
                                    // eslint-disable-next-line no-underscore-dangle
                                    fileFolder?.__typename === "File"
                                       ? [fileFolder?.id]
                                       : [],
                                 folderIds:
                                    // eslint-disable-next-line no-underscore-dangle
                                    fileFolder?.__typename === "Folder"
                                       ? [fileFolder?.id]
                                       : [],
                              },
                           })
                        }
                     >
                        Trash file
                     </Typography>
                  </Box>
               </GrayPopover>
            </div>
         </div>
         <Divider />
         {renderMedia()}
      </>
   );
};

export default FilePreview;
