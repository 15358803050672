import React from "react";

/**
 * ReasonIcon
 * @returns {JSX.Element} Reason Icon
 */
function ReasonIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2H21C21.55 2 22 2.45 22 3V21C22 21.55 21.55 22 21 22H3C2.45 22 2 21.55 2 21V3C2 2.45 2.45 2 3 2ZM18.23 14.92V9.36H18.22V9.09C18.22 8.63 17.98 8.21 17.58 7.99L12.44 5.08C12.34 5.03 12.17 5 12 5C11.83 5 11.66 5.02 11.56 5.08L6.42 7.99C6.02 8.22 5.78 8.64 5.78 9.09V14.92C5.78 15.38 6.02 15.8 6.42 16.02L11.56 18.93C11.78 19.05 12.27 19.01 12.45 18.93L17.59 16.02C17.99 15.79 18.23 15.37 18.23 14.92ZM8 14.36V9.65L12 7.39L16 9.65V9.73L12 11.99V16.62L8 14.36Z"
        fill="black"
      />
    </svg>
  );
}

export default ReasonIcon;
