import React, { useState } from "react";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { useQueryParam, BooleanParam } from "use-query-params";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import isSupportedDAW from "_utils/isSupportedDAW";
import TableCellName from "./TableCells/TableCellName";
import TableCellType from "./TableCells/TableCellType";
import TableCellSize from "./TableCells/TableCellSize";
import TableCellLastModified from "./TableCells/TableCellLastModified";

const useStyles = makeStyles(() => ({
   tableRow: {
      cursor: "pointer",
      position: "relative",
      [`& .MuiTableCell-body, & button`]: {
         color: "inherit",
      },
      // TODO: tried here setting a standing border and overriding it on drag but it doesn't work because of table border collapse property.
      // border: "2px solid red",
      // margin: "2px",
   },
}));

/**
 * Table Row Folder.
 * @param {object} props props.
 * @param {object} props.f folder instance.
 * @param {object} props.foldersLoading loading state folders.
 * @param {boolean} props.isModal if it is rendered inside a modal
 * @param {Array} props.selectedFolders folders selected in checkbox.
 * @param {Function} props.setSelectedFolders set selected folders.
 * @param {Function} props.handleChangeDirectory change directory handler, used in modals.
 * @param {Function} props.renderFileFolderActionMenu render menu.
 * @param {Function} props.handleFinishRenaming rename request.
 * @param {object} props.renamingFolderValue track value of folder being renamed.
 * @param {object} props.selectedRenaming selected file to be renamed.
 * @param {Function}props.setRenamingFolderValue set rename value.
 * @param {Function} props.setSelectedRenaming set folder to rename.
 * @returns {JSX.Element} table folder row.
 */
const TableRowFolder = ({
   f,
   foldersLoading,
   isModal,
   selectedFolders,
   setSelectedFolders,
   handleChangeDirectory,
   renderFileFolderActionMenu,
   handleFinishRenaming,
   renamingFolderValue,
   selectedRenaming,
   setRenamingFolderValue,
   setSelectedRenaming,
}) => {
   const attributes = { "data-folderid": f.id };
   const classes = useStyles();
   const history = useHistory();
   const { folderURI } = useParams();
   const [hover, setHover] = useState(false);
   const [trashed] = useQueryParam("trashed", BooleanParam);

   /**
    * Click Handler.
    *
    * Handles clicking into a directory. If the file is a DAW package type folder then this click event opens the "file".
    * @param {object} e event.
    */
   const clickHandler = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isModal) {
         handleChangeDirectory(f);
      }

      // If it's a package type DAW folder, redirect to the "file" page.
      if (isSupportedDAW({ file: f })) {
         if (folderURI) {
            history.push(
               `/files/folder/${folderURI}/file/${f?.uri}${
                  trashed ? "?trashed=1" : ""
               }`
            );
         }
         history.push(`/files/file/${f?.uri}${trashed ? "?trashed=1" : ""}`);
         return;
      }

      history.push(`/files/folder/${f.uri}${trashed ? "?trashed=1" : ""}`);
   };

   return (
      <TableRow
         key={`folder-${f.uri}`}
         onMouseEnter={() => setHover(true)}
         onMouseLeave={() => setHover(false)}
         draggable={
            !foldersLoading &&
            !isModal &&
            // Do not allow root project folders from being drag
            !(f.projectId && !f.parentFolderId) &&
            !renamingFolderValue
         }
         className={classes.tableRow}
         // eslint-disable-next-line react/jsx-props-no-spreading
         {...attributes}
      >
         {!isModal && (
            <TableCell sx={{ padding: "0px !important" }}>
               {(hover || !!selectedFolders.find((_f) => _f.id === f.id)) && (
                  <Checkbox
                     disabled={foldersLoading}
                     checked={(() => {
                        return !!selectedFolders.find((_f) => _f.id === f.id);
                     })()}
                     onClick={(e) => {
                        setSelectedFolders((prevFolderArr) => {
                           if (e.target.checked) {
                              return [...prevFolderArr, f];
                           }
                           return prevFolderArr.filter(
                              (folder) => folder.id !== f.id
                           );
                        });
                     }}
                  />
               )}
            </TableCell>
         )}
         <TableCellName
            file={f}
            handleFinishRenaming={handleFinishRenaming}
            renamingFolderValue={renamingFolderValue}
            selectedRenaming={selectedRenaming}
            setSelectedRenaming={setSelectedRenaming}
            setRenamingFolderValue={setRenamingFolderValue}
            clickHandler={clickHandler}
            isModal={isModal}
            loading={foldersLoading}
         />
         <TableCellType file={f} loading={foldersLoading} />
         <TableCellSize file={f} loading={foldersLoading} />
         <TableCellLastModified file={f} loading={foldersLoading} />
         {renderFileFolderActionMenu(f)}
      </TableRow>
   );
};

export default TableRowFolder;
