import React from "react";
import makeStyles from "@mui/styles/makeStyles";

// Apollo
import MagicIcon from "_assets/icons/magic.svg";
import MagicContainer from "_assets/icons/magic-container.svg";
import SoundsMarketing from "_assets/icons/sounds-marketing.png";
import SoundsMarketingMobile from "_assets/icons/sounds-marketing-mobile.png";
import PlaySoundsMarketing from "_assets/icons/play-sounds-marketing.png";
import PlaySoundsMarketingMobile from "_assets/icons/play-sounds-marketing-mobile.png";
import LinkMarketingPages from "_components/LinkMarketingPages";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: "110px 0 86px",
    backgroundColor: "#F8F8FD",
    "@media (max-width: 900px)": {
      padding: "110px 0 40px",
    },
  },
  aiContainer: {
    margin: "0 auto",
    paddingTop: "56px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconsContainer: {
    width: "161px",
    height: "43px",
    position: "relative",
  },
  firstIconContainer: {
    position: "absolute",
    top: "10px",
    left: "12px",
  },
  secondIconContainer: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color: theme.palette.secondary.shade5,
    fontWeight: 600,
    position: "absolute",
    top: "9px",
    right: "17px",
  },
  aiTitle: {
    fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
    fontSize: "4rem",
    lineHeight: "4.75rem",
    marginTop: "18px",
    marginBottom: "8px",
    "@media (max-width: 900px)": {
      fontSize: "3rem",
      lineHeight: "3.65rem",
      textAlign: "center",
      padding: "0px 26px",
    },
  },
  aiContent: {
    fontSize: "1.625rem",
    lineHeight: "127%",
    maxWidth: "552px",
    textAlign: "center",
    marginBottom: "150px",
    padding: "0px 20px",
    color: theme.palette.secondary.shade60,
    "@media (max-width: 900px)": {
      marginBottom: "56px",
    },
  },
  sectionCreateSounds: {
    display: "flex",
    flexWrap: "nowrap",
    padding: "0px 60px",
    gap: "40px",
    marginBottom: "220px",
    // Media query mobile view to wrap
    "@media (max-width: 900px)": {
      flexDirection: "column-reverse",
      gap: "16px",
      marginBottom: "80px",
      padding: "0px 20px",
    },
  },
  sectionPlaySounds: {
    display: "flex",
    flexWrap: "nowrap",
    padding: "0px 60px",
    gap: "40px",
    "@media (max-width: 1200px)": {
      gap: "0px",
    },
    "@media (max-width: 900px)": {
      flexDirection: "column",
      gap: "20px",
      padding: "0px 20px",
    },
  },
  createSoundsTextContainer: {
    flex: 1,
    padding: "24px 60px",
    "@media (max-width: 1200px)": {
      padding: "24px 30px",
    },
    "@media (max-width: 900px)": {
      padding: "24px 0px",
    },
  },
  createSoundsTitle: {
    fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
    fontSize: "3rem",
    lineHeight: "100%",
    marginBottom: "10px",
    "@media (max-width: 900px)": {
      fontSize: "2.25rem",
      lineHeight: "2.25rem",
    },
  },
  createSoundsDescription: {
    fontSize: "1.25rem",
    lineHeight: "145%",
    marginBottom: "65px",
    color: theme.palette.secondary.shade60,
    "@media (max-width: 900px)": {
      fontSize: "1.125rem",
      lineHeight: "145%",
    },
  },
  createSoundsImageContainer: {
    flex: 2,
  },
  createSoundsImage: {
    width: "100%",
    height: "auto",
  },
}));
/**
 * AiHomepageSection
 * @returns {React.Element} AiHomepageSection
 */
function AiHomepageSection() {
  const classes = useStyles();
  const mobileView = useMediaQuery("(max-width: 900px)");
  return (
    <div className={classes.root} id="ai-creator-tools">
      <div className={classes.aiContainer}>
        <div className={classes.iconsContainer}>
          <img src={MagicContainer} alt="magic container" />
          <div className={classes.firstIconContainer}>
            <img src={MagicIcon} alt="magic icon" />
          </div>
          <div className={classes.secondIconContainer}>LoopLM 2.0</div>
        </div>
        <h5 className={classes.aiTitle}>AI Powered Music Making</h5>
        <div className={classes.aiContent}>
          Boost your productions with Aux Sounds
        </div>
        <div className={classes.sectionCreateSounds} id="aux-sounds">
          <div className={classes.createSoundsTextContainer}>
            <div className={classes.createSoundsTitle}>
              Create the sound that&apos;s in your head
            </div>
            <div className={classes.createSoundsDescription}>
              Stop searching for the perfect sample, create it instantly with a
              text prompt.
              <br />
              <br />
              Hum, tap out or beatbox your idea and transform it to professional
              sound in seconds.
              <br />
              <br />
              When you find a sound you like, drag and drop it right into your
              DAW.
            </div>
            <LinkMarketingPages to="/signup" linkText="Start creating now" />
          </div>
          <div className={classes.createSoundsImageContainer}>
            <img
              className={classes.createSoundsImage}
              src={mobileView ? SoundsMarketingMobile : SoundsMarketing}
              alt="Create the sound that's in your head"
            />
          </div>
        </div>
        <div className={classes.sectionPlaySounds} id="aux-mastering">
          <div className={classes.createSoundsImageContainer}>
            <img
              className={classes.createSoundsImage}
              src={mobileView ? PlaySoundsMarketingMobile : PlaySoundsMarketing}
              alt="Create the sound thats in your head"
            />
          </div>
          <div className={classes.createSoundsTextContainer}>
            <div className={classes.createSoundsTitle}>
              Get your tracks ready for release
            </div>
            <div className={classes.createSoundsDescription}>
              Focus on making music, let Aux handle mastering.
              <br />
              <br />
              Upload your tracks and master them with one click.
              <br />
              <br />
              Choose from a range of mastering styles to find the one that
              sounds just right.
            </div>
            <LinkMarketingPages to="/signup" linkText="Start mastering now" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AiHomepageSection;
