import PropTypes from "prop-types";
import React from "react";

import { MetaTags as ReactMetaTags } from "react-meta-tags";

const MetaTags = ({ title, description, image }) => {
   // Fallback share image
   if (image === "") {
      // eslint-disable-next-line no-param-reassign
      image = "https://aux.app/_assets/share-default.png";
   }

   return (
      <ReactMetaTags>
         <title>
            {title && `${title} - `}
            Aux
         </title>
         <meta
            name="description"
            content={description || "Welcome to the new music industry"}
         />

         <meta name="twitter:card" content="summary" />
         <meta name="twitter:site" content="@aux" />
         <meta name="twitter:title" content="aux" />
         <meta
            name="twitter:description"
            content={description || "Welcome to the new music industry"}
         />
         <meta name="twitter:image" content={image} />

         <meta property="og:title" content={title} />
         <meta property="og:type" content="website" />
         <meta property="og:image" content={image} />
         <meta
            property="og:description"
            content={
               description || "The simple file transfer service made for music."
            }
         />
         <meta name="url" content={window.location.href} />
         <meta property="og:url" content={window.location.href} />
         <meta name="locale" content="en_EN" />
         <meta property="og:locale" content="en_EN" />
         <meta property="og:site_name" content="Aux" />
      </ReactMetaTags>
   );
};

MetaTags.propTypes = {
   description: PropTypes.string.isRequired,
   image: PropTypes.string,
   title: PropTypes.string.isRequired,
};

MetaTags.defaultProps = {
   image: "",
};

export default MetaTags;
