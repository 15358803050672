import React, { useMemo, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button } from "@mui/material";
import FileUploaderDropHandler from "_components/FileUploaderDropHandler";
import { CheckCircle, Upload } from "@mui/icons-material";
import { useUser } from "_utils/UserContext";
import useCheckStorageLimit from "_utils/useCheckStorageLimit";
import useFileUploader from "_components/FileUploader/useFileUploader";
import FileFolderSelectorModal from "_components/Modal/FileFolderSelectorModal";
import { useUploadData } from "_utils/UploadDataContext";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { CustomFile, useSoundsData } from "_utils/SoundsDataContext";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";

const useStyles = makeStyles((theme: any) => ({
  buttonGrid: {
    display: "flex",
    gap: "8px",
    marginTop: "14px",
    maxWidth: "300px",
  },
  uploadButton: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    minWidth: "120px",
    whiteSpace: "nowrap",
  },
  addButton: {
    minWidth: "120px",
  },
  dropFile: {
    border: `1px dashed ${theme.palette.secondary.shade15}`,
    margin: "20px 0",
    padding: "20px",
    position: "relative",
    fontSize: "14px",
    lineHeight: "1.5rem",
    borderRadius: "8px",
    flexDirection: "column",
    background: "rgba(242, 242, 242, 0.36)",
    minHeight: "160px",
    "& > div": {
      // This counteracts the offset used for root drop handles to show inside the page. As this dropzone is already in the page.
      width: "100% !important",
      height: "100% !important",
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadingTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    lineHeight: "1.25rem",
    fontWeight: 400,
    marginTop: "42px",
    height: "33px",
  },
  titleWithIcon: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  colorPrimary: {
    color: theme.palette.primary.main,
    minWidth: "103px",
    lineHeight: "33px",
  },
  error: {
    display: "block",
    color: theme.palette.error.main,
    marginTop: "6px",
  },
}));
// const { Limits } = SubscriptionPlans;

/**
 * Sounds Record or Upload
 *
 * @returns {React.FC} JSX Element representing the Mastering Page.
 */
function SoundsRecordUploadForm() {
  const classes = useStyles();
  // const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [user] = useUser();

  // const userSubscriptionObject = useMemo(
  //   () => getUsersPlan(user?.currentBillingPlan),
  //   [user]
  // );

  // const userLimitObj = useMemo(
  //   () =>
  //     Limits.find(
  //       (l) => l.planEnum === (userSubscriptionObject?.modalEnum || 0)
  //     ),
  //   [userSubscriptionObject]
  // );

  const [openFileSelectorModal, setOpenFileSelectorModal] = useState(false);

  const [error, setError] = useState(null);

  const checkStorageLimit = useCheckStorageLimit();
  const uploadFiles = useFileUploader();
  const { filesUploading } = useUploadData();

  // File uploaded is the one that percentage is less than 100 and has a uploadCompleteCallback...
  const fileUploading = useMemo(() => {
    return filesUploading?.find((f) => f.uploadCompleteCallback);
  }, [filesUploading]);

  const { initAudio, setInitAudio } = useSoundsData();
  const audioFile: CustomFile = initAudio.file;

  const handleDropEvent = async (event, data) => {
    setError(null);

    const eventResetValue = event;
    const filesInput = Array.from<CustomFile>(
      event?.target?.files || data?.files || []
    );

    // Validation conditions for drag and drop.
    if (filesInput.length !== 1) return;
    if (
      filesInput[0]?.mimeType &&
      !filesInput[0]?.mimeType?.includes("audio")
    ) {
      setError("Only audio files are supported.");
      return;
    }

    if (filesInput[0]?.size > 2000 * 1024) {
      setError("File too large, try a smaller file.");
      return;
    }

    const enoughStorage = checkStorageLimit(filesInput, user);
    if (!enoughStorage) {
      // TODO: this should open the upgrade modal via context.
      setError("Not enough storage, upgrade your plan.");
      return;
    }

    setInitAudio((a) => ({ ...a, status: "UPLOADING" }));
    uploadFiles(
      filesInput.map((f, i) => {
        const mutableFile = f;
        mutableFile.uploadCompleteCallback = (file) => {
          setOpenFileSelectorModal(false);
          setInitAudio(() => ({ status: "UPLOADED", file }));
        };
        mutableFile.uploadIntoSoundsFolder = true;
        return mutableFile;
      })
    );

    if (eventResetValue?.target?.value) {
      eventResetValue.target.value = "";
    }
  };

  const recorderControls = useAudioRecorder({
    noiseSuppression: false,
    echoCancellation: false,
  });
  const {
    // startRecording,
    // stopRecording,
    // togglePauseResume,
    // recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    // mediaRecorder,
  } = recorderControls;

  const [isReseting, setIsReseting] = useState(false);

  const onRecordingComplete = (blob) => {
    setError(null);

    // For some reason the audio recorder component re-runs this when re-rendering. In future replace with custom recorder component.
    if (isReseting) {
      setIsReseting(false);
      return;
    }

    if (recordingTime > 20) {
      setError("Recording too long, try less than 20 seconds.");
      return;
    }

    // TODO: V4 convert to a different format like wav? As this is stored in the users files and is unplayable as a webm audio.
    const fileFromBlob: File = new File(
      [blob],
      `recording-${new Date().toISOString().replace(/\.\d+Z$/, "")}.weba`,
      { type: blob.type }
    );
    const filesInput = [fileFromBlob];

    const enoughStorage = checkStorageLimit(filesInput, user);
    if (!enoughStorage) {
      // TODO: this should open the upgrade modal via context.
      setError("Not enough storage, upgrade your plan.");
      return;
    }

    setInitAudio((a) => ({ ...a, status: "UPLOADING" }));
    uploadFiles(
      filesInput.map((f, i) => {
        const mutableFile = f as CustomFile;
        mutableFile.uploadCompleteCallback = (file) => {
          setOpenFileSelectorModal(false);
          setInitAudio(() => ({ status: "UPLOADED", file }));
        };
        mutableFile.uploadIntoSoundsFolder = true;
        return mutableFile;
      })
    );
  };

  if (initAudio.status === "UPLOADING" && fileUploading) {
    return (
      <div>
        <Box className={classes.dropFile}>
          <div>
            <div className={classes.uploadingTitle}>
              <div className={classes.titleWithIcon}>
                <PlayCircleFilledIcon
                  style={{ height: "24px", width: "24px" }}
                />
                {audioFile?.originalName || audioFile?.name || "Uploading..."}
              </div>
              <div className={classes.colorPrimary}>
                {fileUploading.percentage}
              </div>
            </div>
          </div>
          {/* <Box color="#6147FF" borderRadius="4px" overflow="hidden">
            <LinearProgress
              sx={{
                height: "7px",
              }}
              value={fileUploading.plainPercentage}
              variant={
                fileUploading?.percentage ? "determinate" : "indeterminate"
              }
              color="inherit"
            />
          </Box> */}
        </Box>
      </div>
    );
  }

  if (initAudio.status === "UPLOADED") {
    return (
      <div>
        <Box className={classes.dropFile}>
          <div>
            <div className={classes.uploadingTitle}>
              <div className={classes.titleWithIcon}>
                <CheckCircle style={{ height: "24px", width: "24px" }} />
                {audioFile.originalName}
              </div>
              <div className={classes.colorPrimary}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setIsReseting(true);
                    setInitAudio({ status: "EMPTY", file: null });
                  }}
                >
                  Remove file
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </div>
    );
  }

  return (
    <div>
      {openFileSelectorModal && (
        <FileFolderSelectorModal
          open={openFileSelectorModal}
          loading={false}
          setOpen={setOpenFileSelectorModal}
          title="Select track to seed sample generation"
          selectMode="singleFile"
          filterFiles={(file) => file.mimeType.includes("audio")}
          onConfirm={(selectedFiles: CustomFile[]) => {
            setOpenFileSelectorModal(false);
            if (selectedFiles[0].size > 2000 * 1024) {
              setError("File too large, try a smaller file.");
              return;
            }
            setInitAudio(() => ({
              status: "UPLOADED",
              file: selectedFiles[0],
            }));
          }}
        />
      )}
      <Box className={`${classes.dropFile} ${classes.flex}`}>
        <FileUploaderDropHandler handleDropEvent={handleDropEvent} />
        Record a sound, drag and drop or add from your files (optional)
        {/* <br /> */}
        {/* Try beat boxing, humming the tune you want the sample to sound like */}
        <div className={classes.buttonGrid}>
          <AudioRecorder
            recorderControls={recorderControls}
            onRecordingComplete={onRecordingComplete}
            showVisualizer
          />
          {!isRecording && !isPaused && (
            <>
              <Button
                variant="contained"
                disableElevation
                className={classes.uploadButton}
                onClick={(event) => {
                  event?.currentTarget?.querySelector("input")?.click();
                }}
              >
                <input
                  hidden
                  type="file"
                  accept="audio/*"
                  onChange={(e) => {
                    handleDropEvent(e, null);
                    e.target.value = null;
                  }}
                />
                <Upload /> Upload file
              </Button>
              <Button
                variant="outlined"
                sx={{ border: "1px solid #B3B3B3" }}
                onClick={() => setOpenFileSelectorModal(true)}
                className={classes.addButton}
              >
                Add from files
              </Button>
            </>
          )}
        </div>
        {error && <div className={classes.error}>{error}</div>}
      </Box>
    </div>
  );
}

export default SoundsRecordUploadForm;
