import React, { useEffect, useMemo, useRef, useState } from "react";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleFilled from "@mui/icons-material/PauseCircleFilled";
import { IconButton, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import WaveSurfer from "wavesurfer.js";
import { FileDownload, Refresh } from "@mui/icons-material";
import fileDownload from "js-file-download";
import useStatsMutation from "_utils/useStatsMutation";
import isElectronHook from "_utils/isElectron";
import { startDrag } from "_utils/electronAPI";
import DoneIcon from "@mui/icons-material/Done";
import { CREATE_FILE_FROM_KEY, GET_CURRENT_USER } from "_apollo/queries";
import UpgradeSubscriptionModal from "_components/Modal/UpgradeSubscriptionModal";
import { useUser } from "_utils/UserContext";
import getUsersPlan from "_utils/getUsersPlan";
import { SubscriptionPlans } from "_constants/GlobalVariables";
import { useMutation } from "@apollo/client";

const useStyles = makeStyles((theme: any) => ({
  root: {
    position: "relative",
    width: "100%",
  },
  playerWrapper: {
    width: "100%",
    height: "90px",
    background:
      "linear-gradient(92deg, rgba(97, 71, 255, 0.70) 18.75%, rgba(71, 211, 255, 0.70) 100%)",
    border: "0.5px solid #D9D9D9",
    boxShadow: "2px 2px 3px rgba(189, 189, 189, 0.2)",
    borderRadius: "4px",
    padding: "15px 20px 15px 14px",
    boxSizing: "border-box",
  },
  playerHeader: {
    width: "100%",
    height: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  playerTitle: {
    color: theme.palette.light.main,
    cursor: "pointer",
    border: "none",
    background: "none",
    padding: "0px",
    fontSize: "14px",
    // overflow ellipsis
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "77%",
    overflow: "hidden",
  },
  headerActions: {
    marginTop: "-6px",
    "& svg": {
      fill: theme.palette.light.main,
    },
  },
  playerControls: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "9px",
  },
  sampleDraggable: {
    cursor: "grab",
  },
}));
const { Limits } = SubscriptionPlans;

/**
 * SamplePlayer
 *
 * @param {object} params params.
 * @param {string} params.id Unique ID for the player.
 * @param {object} params.sample url and key of a sample.
 * @param {string} params.title Title of the audio file.
 * @param {number} params.currentlyPlayingID ID of the currently playing audio file.
 * @param {Function} params.setCurrentlyPlayingID Function to set the currently playing audio file ID.
 * @param {boolean} params.disableDownloads Disable downloads, used for the featured downloads on the main sounds screen.
 * @param {Function} params.generateSounds Generate sounds function.
 * @returns {JSX.Element} The SamplePlayer component.
 */
const SamplePlayer = ({
  id,
  title,
  sample,
  currentlyPlayingID,
  setCurrentlyPlayingID,
  disableDownloads = false,
  generateSounds,
}) => {
  const classes = useStyles();
  const [soundsDownloaded, setSoundsDownloaded] = useState(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [user] = useUser();

  const userSubscriptionObject = useMemo(
    () => getUsersPlan(user?.currentBillingPlan),
    [user]
  );

  const userLimitObj = useMemo(
    () =>
      Limits.find(
        (l) => l.planEnum === (userSubscriptionObject?.modalEnum || 0)
      ),
    [userSubscriptionObject]
  );
  const statsMutation = useStatsMutation();
  const [createFileFromKey] = useMutation(CREATE_FILE_FROM_KEY);

  const isElectron = isElectronHook();

  const waveSurferRef = useRef(null);

  const [isPaused, setIsPaused] = React.useState(true);

  // Pause if another player is playing.
  useEffect(() => {
    if (currentlyPlayingID !== id && !isPaused) {
      waveSurferRef.current.pause();
      setIsPaused(true);
    }
  }, [currentlyPlayingID]);

  useEffect(() => {
    waveSurferRef.current = WaveSurfer?.create({
      container: `#waveform-container-${id}`,
      waveColor: "#C2DDFF",
      progressColor: "white",
      height: 30,
      hideScrollbar: true,
    });

    waveSurferRef.current.on("play", () => {
      setCurrentlyPlayingID(id);
    });
    waveSurferRef.current.on("pause", () => {
      // Always restart on pause.
      waveSurferRef.current.seekTo(0);
      // setCurrentlyPlayingID(null);
    });
    waveSurferRef.current.on("ready", () => {
      waveSurferRef.current.setVolume(0.5);
    });
    // waveSurferRef.current.on("loading", () => {
    // });
    waveSurferRef.current.on("finish", () => {
      // Loop
      waveSurferRef.current.seekTo(0);
      waveSurferRef.current.play();
    });

    waveSurferRef.current.load(sample.signed_url);
  }, []);

  const triggerGenerate = () => {
    // Set timeout makes sure that there is time for the sample name to be set.
    generateSounds(title);
  };

  const checkPlanAndCredit = () => {
    if (
      userSubscriptionObject?.modalEnum === 0 ||
      user?.limits?.soundsDownloaded >= userLimitObj.soundsAllowance
    ) {
      setOpenSubscriptionModal(true);
      return false;
    }
    return true;
  };

  const addToMySamples = async () => {
    await createFileFromKey({
      variables: {
        key: sample.key,
        name: sample.key,
        size: sample.file_size,
        type: "sounds",
      },
      onCompleted: () => {
        //
      },
    });
  };

  const downloadSample = async () => {
    // Check if the user has the correct plan and credit, if not, prompt to upgrade.
    if (!checkPlanAndCredit()) {
      return;
    }

    setSoundsDownloaded(true);

    // On Electron add to my samples and sync.
    await addToMySamples();

    // Track the download in stats.
    statsMutation(
      {
        statsId: "SoundsDownload",
        metadata: JSON.stringify({ prompt: title, url: sample.signed_url }),
      },
      {
        refetchQueries: [{ query: GET_CURRENT_USER }],
      }
    );

    // On web trigger normal download too.
    fetch(sample.signed_url)
      .then((response) => response.blob())
      .then((blob) => {
        fileDownload(blob, sample.key);
      });
  };

  const dragSample = async () => {
    // Check if the user has the correct plan and credit, if not, prompt to upgrade.
    if (!checkPlanAndCredit()) {
      return;
    }

    // Start the electron drag and drop.
    startDrag(sample.signed_url);

    await addToMySamples();

    // Track the drag in stats.
    statsMutation({
      statsId: "SoundsDownload",
      metadata: JSON.stringify({
        prompt: title,
        url: sample.signed_url,
        method: "draganddrop",
      }),
    });
  };

  return (
    <div key={id} className={classes.root}>
      {openSubscriptionModal && (
        <UpgradeSubscriptionModal
          title={
            user?.currentBillingPlan
              ? "To download more sounds upgrade your plan"
              : "To download sounds start your free trial"
          }
          description="and get access all app features, including project storage and mastering"
          open={openSubscriptionModal}
          handleClose={() => setOpenSubscriptionModal(false)}
        />
      )}
      <div
        // key={`sample-${url.replaceAll(/\W/g, "")}`}
        // id={`sample-${i}`}
        draggable={isElectron && !disableDownloads}
        onDragStart={(event) => {
          event.preventDefault();

          if (isElectron) {
            dragSample();
          }
        }}
        className={isElectron ? classes.sampleDraggable : ""}
      >
        {/* The whole unit is draggable on desktop to allow the user to drag the file into the DAW */}
        <div className={classes.playerWrapper}>
          <div className={classes.playerHeader}>
            <button
              type="button"
              className={classes.playerTitle}
              onClick={() => {
                if (disableDownloads) {
                  // Trigger prompt and generate samples on this basis.
                  triggerGenerate();
                } else {
                  // Trigger download.
                  downloadSample();
                }
              }}
            >
              {title}
            </button>
            <div className={classes.headerActions}>
              <Tooltip title="Create more like this">
                <IconButton size="small" onClick={triggerGenerate}>
                  <Refresh />
                </IconButton>
              </Tooltip>
              {!disableDownloads && (
                <Tooltip title="Download">
                  <IconButton
                    size="small"
                    disabled={soundsDownloaded}
                    onClick={downloadSample}
                  >
                    {soundsDownloaded ? <DoneIcon /> : <FileDownload />}
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
          <div className={classes.playerControls}>
            <IconButton
              size="large"
              sx={{ p: "0px", height: "30px", width: "30px" }}
              onClick={() => {
                if (isPaused) {
                  // console.log(waveSurferRef.current);
                  waveSurferRef.current.play();
                  setIsPaused(false);
                } else {
                  waveSurferRef.current.pause();
                  setIsPaused(true);
                }
              }}
            >
              {!isPaused ? (
                <PauseCircleFilled
                  style={{
                    fill: "white",
                    height: "30px",
                    width: "30px",
                    opacity: 0.75,
                  }}
                />
              ) : (
                <PlayCircleFilledIcon
                  style={{
                    fill: "#C2DDFF",
                    height: "30px",
                    width: "30px",
                    opacity: 0.75,
                  }}
                />
              )}
            </IconButton>
            <div
              id={`waveform-container-${id}`}
              style={{ height: "30px", flexGrow: 1, opacity: 0.75 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SamplePlayer;
