import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { useQueryParam, StringParam } from "use-query-params";
import { GET_CURRENT_USER } from "../_apollo/queries";

/**
 * Redirect Confirm Email Hook
 *
 * Should redirect the user to confirm email page if the user has not verified their email.
 *
 * This hook should be called on: appheader.
 */
function useRedirectConfirmEmail() {
  const history = useHistory();

  const [hash] = useQueryParam("hash", StringParam);
  const [redirectTo] = useQueryParam("redirectTo", StringParam);

  useQuery(GET_CURRENT_USER, {
    fetchPolicy: "network-only", // Avoid cache results in case they were previously null
    /**
     * getCurrentUser onCompleted
     *
     * Redirect the user to the feed or a custom redirect location passed in the params.
     * @param {object} data Apollo response data for currentUser query.
     */
    onCompleted(data) {
      if (!data?.currentUser?.emailVerifiedAt) {
        if (redirectTo) {
          history.push(`/confirm-email?redirectTo=${redirectTo}`);
        } else if (hash) {
          history.push(`/confirm-email?hash=${hash}`);
        } else {
          history.push("/confirm-email");
        }
      }
    },
  });
}

export default useRedirectConfirmEmail;
