import getConfig from "../_utils/getConfig";

// ⛔️⛔️⛔️ Update in web then run yarn update-GlobalVariables.js from api

export const FILES_FOLDER_PAGINATION_LIMIT = 25;
export const MESSAGES_PAGINATION_LIMIT = 50;
export const MAX_CHUNK_SIZE = 1024 ** 2 * 10; // Remember to update this in preload.js too!
export const MAX_SIMULTANEOUS_CHUNKS = 3; // Chunk queue is shared between uploads.
export const MAX_SIMULTANEOUS_UPLOADS = 2;
export const MAX_SIMULTANEOUS_DOWNLOADS = 2;

export const POLLING_INTERVAL = 30;

const {
   plans: {
      StarterMUSD,
      StarterMGBP,
      StarterMEUR,
      StarterYUSD,
      StarterYGBP,
      StarterYEUR,
      BasicMUSD,
      BasicMGBP,
      BasicMEUR,
      BasicYUSD,
      BasicYGBP,
      BasicYEUR,
      ProMUSD,
      ProMGBP,
      ProMEUR,
      ProYUSD,
      ProYGBP,
      ProYEUR,
      VIPMUSD,
      VIPMGBP,
      VIPMEUR,
      VIPYUSD,
      VIPYGBP,
      VIPYEUR,
   },
} = getConfig();

export const SubscriptionPlans = {
   PlanModalEnum: { FREE: 0, STARTER: 5, BASIC: 1, PRO: 2, VIP: 3 },
   ArrayPlans: [
      // Free Plans
      {
         id: 1,
         label: "Free",
         modalEnum: 0,
         type: "Monthly",
         plan: undefined,
         currency: "USD",
         symbol: "$",
      },
      {
         id: 2,
         label: "Free",
         modalEnum: 0,
         type: "Yearly",
         plan: undefined,
         currency: "USD",
         symbol: "$",
      },
      {
         id: 3,
         label: "Free",
         modalEnum: 0,
         type: "Yearly",
         plan: undefined,
         currency: "GBP",
         symbol: "£",
      },
      {
         id: 4,
         label: "Free",
         modalEnum: 0,
         type: "Monthly",
         plan: undefined,
         currency: "GBP",
         symbol: "£",
      },
      {
         id: 5,
         label: "Free",
         modalEnum: 0,
         type: "Yearly",
         plan: undefined,
         currency: "EUR",
         symbol: "€",
      },
      {
         id: 6,
         label: "Free",
         modalEnum: 0,
         type: "Monthly",
         plan: undefined,
         currency: "EUR",
         symbol: "€",
      },

      // Starter Plans USD / GBP / EUR
      {
         id: 7,
         label: "Starter",
         modalEnum: 5,
         type: "Monthly",
         plan: StarterMUSD,
         legacyPlans: ["price_1MRHjWA36lU9oUD8g4iaU63J"],
         price: 4.99,
         currency: "USD",
         symbol: "$",
      },
      {
         id: 8,
         label: "Starter",
         modalEnum: 5,
         type: "Yearly",
         plan: StarterYUSD,
         price: 49.99,
         currency: "USD",
         symbol: "$",
      },
      {
         id: 9,
         label: "Starter",
         modalEnum: 5,
         type: "Monthly",
         plan: StarterMGBP,
         legacyPlans: ["price_1MRHjWA36lU9oUD8PFFkiDhk"],
         price: 3.99,
         currency: "GBP",
         symbol: "£",
      },
      {
         id: 10,
         label: "Starter",
         modalEnum: 5,
         type: "Yearly",
         plan: StarterYGBP,
         price: 39.99,
         currency: "GBP",
         symbol: "£",
      },
      {
         id: 11,
         label: "Starter",
         modalEnum: 5,
         type: "Monthly",
         plan: StarterMEUR,
         legacyPlans: ["price_1MRHjWA36lU9oUD8W9v0Xmw8"],
         price: 4.99,
         currency: "EUR",
         symbol: "€",
      },
      {
         id: 12,
         label: "Starter",
         modalEnum: 5,
         type: "Yearly",
         plan: StarterYEUR,
         price: 49.99,
         currency: "EUR",
         symbol: "€",
      },

      // Basic Plans USD / GBP / EUR
      {
         id: 13,
         label: "Basic",
         modalEnum: 1,
         type: "Monthly",
         plan: BasicMUSD,
         legacyPlans: [],
         price: 10.99,
         currency: "USD",
         symbol: "$",
      },
      {
         id: 14,
         label: "Basic",
         modalEnum: 1,
         type: "Yearly",
         plan: BasicYUSD,
         price: 109.99,
         currency: "USD",
         symbol: "$",
      },
      {
         id: 15,
         label: "Basic",
         modalEnum: 1,
         type: "Monthly",
         plan: BasicMGBP,
         legacyPlans: ["price_1JzJOVA36lU9oUD8CUdXSn5x"],
         price: 8.99,
         currency: "GBP",
         symbol: "£",
      },
      {
         id: 16,
         label: "Basic",
         modalEnum: 1,
         type: "Yearly",
         plan: BasicYGBP,
         price: 89.99,
         currency: "GBP",
         symbol: "£",
      },
      {
         id: 17,
         label: "Basic",
         modalEnum: 1,
         type: "Monthly",
         plan: BasicMEUR,
         legacyPlans: ["price_1JzJOVA36lU9oUD8wXeo7VVm"],
         price: 10.99,
         currency: "EUR",
         symbol: "€",
      },
      {
         id: 18,
         label: "Basic",
         modalEnum: 1,
         type: "Yearly",
         plan: BasicYEUR,
         price: 109.99,
         currency: "EUR",
         symbol: "€",
      },

      // Pro Plans USD / GBP / EUR
      {
         id: 19,
         label: "Pro",
         modalEnum: 2,
         type: "Monthly",
         plan: ProMUSD,
         legacyPlans: ["price_1JzJPEA36lU9oUD8PNBw4DFv"],
         price: 20.99,
         currency: "USD",
         symbol: "$",
      },
      {
         id: 20,
         label: "Pro",
         modalEnum: 2,
         type: "Yearly",
         plan: ProYUSD,
         price: 209.99,
         currency: "USD",
         symbol: "$",
      },
      {
         id: 21,
         label: "Pro",
         modalEnum: 2,
         type: "Monthly",
         plan: ProMGBP,
         legacyPlans: ["price_1JzJPEA36lU9oUD8UWo9ebKs"],
         price: 16.99,
         currency: "GBP",
         symbol: "£",
      },
      {
         id: 22,
         label: "Pro",
         modalEnum: 2,
         type: "Yearly",
         plan: ProYGBP,
         price: 169.99,
         currency: "GBP",
         symbol: "£",
      },
      {
         id: 23,
         label: "Pro",
         modalEnum: 2,
         type: "Monthly",
         plan: ProMEUR,
         price: 19.99,
         currency: "EUR",
         symbol: "€",
      },
      {
         id: 24,
         label: "Pro",
         modalEnum: 2,
         type: "Yearly",
         plan: ProYEUR,
         price: 199.99,
         currency: "EUR",
         symbol: "€",
      },
      // VIP  Plans USD / GBP / EUR
      {
         id: 25,
         label: "VIP",
         modalEnum: 3,
         type: "Monthly",
         plan: VIPMUSD,
         price: 47.99,
         currency: "USD",
         symbol: "$",
      },
      {
         id: 26,
         label: "VIP",
         modalEnum: 3,
         type: "Yearly",
         plan: VIPYUSD,
         price: 479.99,
         currency: "USD",
         symbol: "$",
      },
      {
         id: 27,
         label: "VIP",
         modalEnum: 3,
         type: "Monthly",
         plan: VIPMGBP,
         legacyPlans: ["price_1JzJQAA36lU9oUD8ZNrPpSSJ"],
         price: 39.99,
         currency: "GBP",
         symbol: "£",
      },
      {
         id: 28,
         label: "VIP",
         modalEnum: 3,
         type: "Yearly",
         plan: VIPYGBP,
         price: 399.99,
         currency: "GBP",
         symbol: "£",
      },
      {
         id: 29,
         label: "VIP",
         modalEnum: 3,
         type: "Monthly",
         plan: VIPMEUR,
         legacyPlans: ["price_1JzJQAA36lU9oUD86RhbCMHo"],
         price: 45.99,
         currency: "EUR",
         symbol: "€",
      },
      {
         id: 30,
         label: "VIP",
         modalEnum: 3,
         type: "Yearly",
         plan: VIPYEUR,
         price: 459.99,
         currency: "EUR",
         symbol: "€",
      },
   ],
   // storage values calculated in bytes.
   Limits: [
      {
         storage: 1024 * 1024 * 1024 * 2, // 2GB
         guideAccessAmount: 0,
         planEnum: 0,
         mastersAllowance: 0,
         soundsAllowance: 0,
      },
      {
         storage: 1024 * 1024 * 1024 * 10,
         guideAccessAmount: 10,
         planEnum: 5,
         mastersAllowance: 2,
         soundsAllowance: 50,
      },
      {
         storage: 1024 * 1024 * 1024 * 20,
         guideAccessAmount: 50,
         planEnum: 1,
         mastersAllowance: 5,
         soundsAllowance: 100,
      },
      {
         storage: 1024 * 1024 * 1024 * 100,
         guideAccessAmount: 100,
         planEnum: 2,
         mastersAllowance: 20,
         soundsAllowance: 200,
      },
      {
         storage: 1024 * 1024 * 1024 * 1024,
         guideAccessAmount: false,
         planEnum: 3,
         mastersAllowance: 9999,
         soundsAllowance: 9999,
      },
   ],
   Features: {
      FreePlanFeatures: [
         "Create your Aux Profile",
         "Join the Aux Community",
         "2GB of Track Storage",
      ],
      StarterPlanFeatures: [
         "50 Samples and Loops",
         "2 Mastered Tracks",
         "10GB of Track Storage",
      ],
      BasicPlanFeatures: [
         "100 Samples and Loops",
         "5 Mastered Tracks",
         "20GB of Track Storage",
      ],
      ProPlanFeatures: [
         "200 Samples and Loops",
         "20 Mastered Tracks",
         "100GB of Track Storage",
      ],
      VIPPlanFeatures: [
         "Unlimited Samples and Loops",
         "Unlimited Mastered Tracks",
         "1TB of Track Storage",
      ],
   },
};

export const months = [
   "January",
   "February",
   "March",
   "April",
   "May",
   "June",
   "July",
   "August",
   "September",
   "October",
   "November",
   "December",
];

export const masteringTypes = [
   "powerful-pop",
   "indie-diy",
   "studio-indie",
   "studio-hiphop",
   "melodic-electronic",
   "bass-heavy",
];
