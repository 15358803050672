/**
 * File Sync Helpers
 *
 * Electron only file sync library
 */
import isElectronHook from "_utils/isElectron";
import formatBytes from "_utils/formatBytes";
import { getMimeType } from "_utils/fileTypeUtil/fileTypeUtil";
// import throwError from "_utils/throwError";

const isElectron = isElectronHook();
const fs = isElectron ? window?.electronAPI?.fs : {};
const path = isElectron ? window?.electronAPI?.path : {};

// TODO: Audit and clear out these helpers and move to preload.js functions with specific abilities.

/**
 * Is File
 *
 * Checks if system path is file and folder.
 *
 * // TODO: this is duplicated here and in preload, we should move to preload as we refactor out passing the whole of fs through from electronAPI.
 * @deprecated use isFile from preload instead.
 * @param {string} fileName File name
 * @returns {boolean} If it is a file true, If it is a folder false
 */
export const isFile = (fileName) => {
   if (fileName.slice(-4) === ".zip") {
      return true;
   }
   return window?.electronAPI?.isFile(fileName);
};

/**
 * Get Full Path
 *
 * Will put together file name and path.
 * @param {string} parentPath path to the file
 * @param {string} fileName File name
 * @returns {string} full path of file
 */
export const getFullPath = (parentPath, fileName) => {
   return path.join(parentPath, fileName);
};

/**
 * Get File Stat
 *
 * Get's the file stats including mtime, size etc from file system if the file exists.
 * @param {string} filePath The file path to a watched file that you want the mapped values for
 * @returns {object} An object of file stat data.
 */
export const getFileStat = (filePath) => {
   if (!fs.existsSync(filePath)) {
      return {};
   }
   return fs.statSync(filePath);
};

/**
 * Get Name From Path
 *
 * Given a path to a dir/file it returns the name.
 * @param {string} fullPath fullPath of file/dir.
 * @returns {string} It returns just the name of the path.
 */
export const getNameFromPath = (fullPath) => {
   return path.basename(fullPath);
};

/**
 * Get File Data From Path
 *
 * This function takes a file watch path and returns a format
 *
 * @param {string} filePath The file path to a watched file that you want the mapped values for.
 * @param {boolean} isSyncFile Is the file being uploaded by the sync algo, if so don't ever create a duplicate for a file with the same path.
 * @param {number} profileId profile id.
 * @returns {object} An object containing fullData and reducedData for the passed file.
 */
export const getFileDataFromPath = (
   filePath,
   isSyncFile = false,
   profileId
) => {
   const originalName = getNameFromPath(filePath);
   const mimeType = getMimeType({
      originalName,
   })[0];

   const userMyAuxFilesPath =
      window?.electronAPI?.getMyAuxFilesFolder(profileId);

   if (!userMyAuxFilesPath) {
      throw new Error("Unable to get Aux Files path.");
   }

   const fullPathFile = getFullPath(userMyAuxFilesPath, filePath);

   const { size, mtime } = getFileStat(fullPathFile);

   // Block if file does not exist. But still pass through 0 byte files so we can use them for Ableton detection - uploads will be clocked in the uploadFiles() function.
   if (!mtime) {
      return {
         fullData: null,
         reducedData: null,
      };
   }
   const fullData = {
      fileSize: size,
      mimeType,
      formattedSize: formatBytes(size),
      name: originalName,
      isSyncFile,
      path: filePath,
      modifiedAt: mtime,
   };

   const reducedData = {
      fileSize: size,
      mimeType,
      fileName: originalName,
      path: filePath,
      modifiedAt: mtime,
   };

   return {
      // TODO: refactor this so that it returns a single simple object of data.
      fullData,
      reducedData,
   };
};

/**
 * Copy File Recursive Sync
 *
 * Copy Folder from a folder to another one.
 * @param {string} source selected folder
 * @param {string} target insert folder
 */
export const copyFolderRecursiveSync = (source, target) => {
   if (!fs.existsSync(target)) {
      fs.mkdirSync(target);
   }

   fs.copySync(source, getFullPath(target, getNameFromPath(source)));
};

/**
 * Write Folder To File System.
 * @param {string} directoryPath path a dir.
 */
export const writeFolderToFilesystem = async (directoryPath) => {
   window?.electronAPI
      ?.openDialog("showOpenDialog", {
         properties: ["openDirectory"],
      })
      .then(async (result) => {
         if (result?.filePaths[0]) {
            copyFolderRecursiveSync(result?.filePaths[0], directoryPath);
         }
      });
};

/**
 * Write File To File System.
 * @param {string} directoryPath Sync folder in which the new files will be wrote in.
 */
export const writeFileToFilesystem = async (directoryPath) => {
   window?.electronAPI
      ?.openDialog("showOpenDialog", {
         properties: ["openFile", "multiSelections"],
      })
      .then((response) => {
         if (response?.filePaths?.length > 0) {
            response?.filePaths.forEach((f) => {
               let filename = path.basename(f);
               const { name, ext } = path.parse(filename);
               let count = 1;
               /**
                * If a file exists, add a suffix copy X.
                * @param {string} filePath file path.
                * @returns {string} mutated file name with the copy suffix if it already exists.
                */
               const recursivelySuffixDuplicates = (filePath) => {
                  if (fs.existsSync(filePath)) {
                     filename = `${name} copy ${count}${ext}`;
                     count++;
                     recursivelySuffixDuplicates(
                        getFullPath(directoryPath, filename)
                     );
                  }
                  return filename;
               };
               const fileNameNoDuplicates = recursivelySuffixDuplicates(
                  getFullPath(directoryPath, filename)
               );
               if (
                  filename.slice(-5) === ".band" ||
                  filename.slice(-7) === ".logicx"
               ) {
                  copyFolderRecursiveSync(
                     f,
                     getFullPath(directoryPath, filename),
                     true
                  );
               } else {
                  fs.copyFileSync(
                     f,
                     getFullPath(directoryPath, fileNameNoDuplicates)
                  );

                  const { mtime } = getFileStat(f);
                  if (!mtime) {
                     return;
                  }

                  fs.utimesSync(
                     getFullPath(directoryPath, fileNameNoDuplicates),
                     new Date(mtime),
                     new Date(mtime)
                  );
               }
            });
         }
      });
};
