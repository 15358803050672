import GhostContentAPI from "@tryghost/content-api";
import getConfig from "./getConfig";

const { ghostAPIUrl, ghostKey } = getConfig();
const ghostApi = new GhostContentAPI({
   url: ghostAPIUrl,
   key: ghostKey,
   version: "v5.101.5",
});

export default ghostApi;
