import React, { useState } from "react";
import {
  Button,
  Typography,
  IconButton,
  InputBase,
  Box,
  useTheme,
} from "@mui/material/";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";
import FileActionButtons from "_components/FileActionButtons";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import GrayPopover from "_components/GrayPopover";
import stringIsMultiple from "_utils/stringIsMultiple";
import FileUploadInput from "_components/FileUploader/FileUploadInput";
import FolderUploadInput from "_components/FileUploader/FolderUploadInput";

const useStyles = makeStyles((theme: any) => ({
  buttonContainer: {
    paddingRight: "30px",
    flexWrap: "nowrap",
    display: "flex",
    height: "100%",
  },
  popoverContainer: {
    "& > .MuiPopover-paper": {
      background: theme.palette.secondary.shade5,
      boxShadow:
        "-1px -1px 3px rgba(102, 102, 102, 0.15), 3px 5px 5px rgba(102, 102, 102, 0.15)",
    },
  },
  rightButtonContainer: {
    display: "flex",
    marginLeft: "auto",
    alignItems: "center",
    [`& button, & label`]: {
      whiteSpace: "nowrap",
    },
    [`& .MuiIconButton-root`]: {
      padding: 8,
    },
  },
  form: {
    display: "flex",
    alignItems: "center",
    width: 400,
    height: 36,
    backgroundColor: theme.palette.secondary.shade5,
    borderRadius: 4,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    flexShrink: 1,
    minWidth: 100,
    fontSize: "14px",
  },
  trashButton: {
    borderColor: theme.palette.secondary.shade30,
    padding: "6px 12px",
    marginLeft: "20px",
  },
}));

const HeaderActions = ({
  files,
  folderUri,
  selectedDropFolderId,
  selectedFolders,
  selectedFiles,
  totalSelectedFiles,
  starFilesFolders,
  trashFilesFolders,
  setShowCopyModal,
  setShowMoveModal,
  openPopover,
  setOpenPopover,
  setCreateFolderData,
}) => {
  const theme: any = useTheme();
  const classes = useStyles();

  const history = useHistory();

  const [collapsedSearch, setCollapsedSearch] = useState(true);
  const [search, setSearch] = useState("");

  // From an array of files/folders, return an array of ids.
  // Used for queries/mutations.
  const getIds = (fList) => fList.map((f) => f.id);

  /**
   * Handle Create Folder
   *
   * When the user clicks on "Create Folder" sets the new folder object in state
   * to show the input field.
   */
  const handleCreateFolder = () => {
    setOpenPopover(false);
    // Create the folder data object in state. Submission is handled in FilesTable.
    setCreateFolderData({
      name: "New Folder",
      renaming: true,
      new: true,
      size: 0,
      updatedAt: new Date().getTime(),
    });

    // Scroll to the top of the files view to show the Create Folder input.
    const fileTableEl = document.getElementById("file-table");

    if (fileTableEl) {
      fileTableEl.scrollTop = 0;
    }
    setTimeout(() => {
      (
        document.getElementById("create-folder-input") as HTMLInputElement
      ).select();
    }, 0);
  };

  // Automatically selected the folder creation input in the listings table.
  const clickHandler = (e) => {
    (e?.target?.querySelector("input") as HTMLInputElement).click();
  };

  return (
    <div className={classes.buttonContainer}>
      <div className={classes.rightButtonContainer}>
        {(selectedFolders.length > 0 || selectedFiles.length > 0) && (
          <>
            {totalSelectedFiles > 0 && (
              <Typography
                component="span"
                style={{ minWidth: "109px", fontSize: "14px" }}
              >
                {`${totalSelectedFiles} ${stringIsMultiple(
                  totalSelectedFiles,
                  "item"
                )} selected`}
              </Typography>
            )}

            <FileActionButtons
              fileToDownload={files?.find((f) => f.id === selectedFiles[0]?.id)}
              starFilesFolders={() => {
                starFilesFolders({
                  variables: {
                    fileIds: getIds(selectedFiles),
                    folderIds: getIds(selectedFolders),
                  },
                });
              }}
              trashFilesFolders={() =>
                trashFilesFolders({
                  variables: {
                    fileIds: getIds(selectedFiles),
                    folderIds: getIds(selectedFolders),
                  },
                })
              }
              selectedFiles={selectedFiles}
              selectedFolders={selectedFolders}
              setShowCopyModal={setShowCopyModal}
              setShowMoveModal={setShowMoveModal}
              totalSelectedFiles={totalSelectedFiles}
            />
          </>
        )}
        {selectedFiles.length === 0 && selectedFolders.length === 0 && (
          <>
            {collapsedSearch ? (
              <IconButton
                type="submit"
                aria-label="search"
                onClick={() => setCollapsedSearch(!collapsedSearch)}
              >
                <SearchIcon sx={{ color: theme.palette.coreApp.text }} />
              </IconButton>
            ) : (
              <div className={classes.form}>
                <IconButton
                  type="submit"
                  style={{
                    padding: "5px 0px 5px 5px",
                    backgroundColor: "transparent",
                  }}
                  aria-label="search"
                  onClick={() => {
                    setCollapsedSearch(!collapsedSearch);
                    setSearch("");
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <InputBase
                  className={classes.input}
                  placeholder="Search"
                  autoComplete="off"
                  inputProps={{ "aria-label": "search" }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            )}
            <IconButton
              style={{
                backgroundColor: "transparent",
              }}
              id="popover-file-listings-handler"
              onClick={(e) => setOpenPopover(e.currentTarget)}
            >
              <MoreHorizIcon style={{ fill: theme.palette.coreApp.text }} />
            </IconButton>
            <GrayPopover
              id="popover-file-listings"
              open={!!openPopover}
              anchorEl={openPopover}
              onClose={() => setOpenPopover(false)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              className={classes.popoverContainer}
            >
              <Box
                sx={{
                  "& p": {
                    p: "3px 20px 3px 20px !important",
                  },
                }}
              >
                <Typography
                  id="create-folder-option"
                  onClick={handleCreateFolder}
                >
                  Create folder
                </Typography>
                <Typography onClick={clickHandler}>
                  Add folder
                  <FolderUploadInput
                    folderUri={folderUri}
                    selectedDropFolderId={selectedDropFolderId}
                    callback={() => setOpenPopover(false)}
                  />
                </Typography>
                <Typography onClick={clickHandler}>
                  Add files
                  <FileUploadInput
                    folderUri={folderUri}
                    selectedDropFolderId={selectedDropFolderId}
                    callback={() => setOpenPopover(false)}
                  />
                </Typography>
              </Box>
            </GrayPopover>
            <Button
              onClick={() => {
                history.push(`/files?trashed=1`);
              }}
              variant="outlined"
              className={classes.trashButton}
            >
              <Delete
                style={{
                  fill: theme.palette.coreApp.text,
                  height: "20px",
                  width: "20px",
                }}
              />{" "}
              &nbsp; Open trash
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderActions;
