import PropTypes from "prop-types";
import React from "react";

const AuxConnectLogo = ({ style }) => {
   return (
      <svg
         width="162"
         height="30"
         viewBox="0 0 162 30"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            style={style}
            d="M9.90004 24.3599C11.7 24.3599 13.284 23.8199 14.58 22.8479V23.9999H19.26V5.99989H14.58V7.15189C13.284 6.17989 11.7 5.63989 9.90004 5.63989C4.42804 5.63989 0.540039 9.52789 0.540039 14.9999C0.540039 20.5079 4.42804 24.3599 9.90004 24.3599ZM9.90004 20.0399C7.05604 20.0399 5.22004 18.0599 5.22004 14.9999C5.22004 11.9399 7.05604 9.95989 9.90004 9.95989C12.744 9.95989 14.58 11.9399 14.58 14.9999C14.58 18.0599 12.744 20.0399 9.90004 20.0399Z"
            fill="black"
         />
         <path
            style={style}
            d="M21.0446 16.7999C21.0446 21.2279 24.1046 24.3599 28.3166 24.3599C29.9006 24.3599 31.3046 23.8199 32.3846 22.8479V23.9999H37.0646V5.99989H32.3846V16.7999C32.3846 18.7079 31.0166 20.0399 29.0366 20.0399C27.1286 20.0399 25.7246 18.7079 25.7246 16.7999V5.99989H21.0446V16.7999Z"
            fill="black"
         />
         <path
            style={style}
            d="M49.9568 23.9999H55.4288L49.4888 14.6399L54.9968 5.99989H49.5608L46.7168 10.6799L43.8728 5.99989H38.4008L43.9088 14.6759L37.9688 23.9999H43.4408L46.7168 18.6359L49.9568 23.9999Z"
            fill="black"
         />
         <path
            style={style}
            d="M75.4343 13.9255H78.2014C77.7715 9.08982 74.0642 5.73169 69.0135 5.73169C63.6137 5.73169 59.7451 9.57339 59.7451 15.0001C59.7451 20.4537 63.6137 24.2685 69.0135 24.2685C74.0642 24.2685 77.7447 20.9641 78.2014 16.0747H75.4343C75.0044 19.5134 72.5329 21.7164 69.0135 21.7164C65.1181 21.7164 62.5391 19.0299 62.5391 15.0001C62.5391 10.9704 65.1181 8.28386 69.0135 8.28386C72.5329 8.28386 74.9776 10.4868 75.4343 13.9255Z"
            fill="black"
         />
         <path
            style={style}
            d="M86.5115 24.2685C90.595 24.2685 93.4964 21.394 93.4964 17.2836C93.4964 13.2002 90.595 10.2987 86.5115 10.2987C82.428 10.2987 79.5266 13.2002 79.5266 17.2836C79.5266 21.394 82.428 24.2685 86.5115 24.2685ZM86.5115 21.797C83.9593 21.797 82.2131 19.997 82.2131 17.2836C82.2131 14.5703 83.9593 12.7435 86.5115 12.7435C89.0905 12.7435 90.8099 14.5703 90.8099 17.2836C90.8099 19.997 89.0905 21.797 86.5115 21.797Z"
            fill="black"
         />
         <path
            style={style}
            d="M95.2254 23.9999H97.9119V15.9404C97.9119 14.0598 99.0939 12.7435 101.028 12.7435C102.882 12.7435 104.091 14.0598 104.091 15.9404V23.9999H106.777V15.9404C106.777 12.636 104.601 10.2987 101.404 10.2987C99.9805 10.2987 98.7716 10.836 97.9119 11.7763V10.5674H95.2254V23.9999Z"
            fill="black"
         />
         <path
            style={style}
            d="M108.842 23.9999H111.528V15.9404C111.528 14.0598 112.71 12.7435 114.644 12.7435C116.498 12.7435 117.707 14.0598 117.707 15.9404V23.9999H120.393V15.9404C120.393 12.636 118.217 10.2987 115.02 10.2987C113.597 10.2987 112.388 10.836 111.528 11.7763V10.5674H108.842V23.9999Z"
            fill="black"
         />
         <path
            style={style}
            d="M135.998 17.2836C135.998 13.2002 133.096 10.2987 129.013 10.2987C124.929 10.2987 122.028 13.2002 122.028 17.2836C122.028 21.394 124.929 24.2685 129.013 24.2685C131.914 24.2685 134.439 22.8447 135.272 20.4537H132.29C131.538 21.5014 130.356 21.9044 129.013 21.9044C126.783 21.9044 125.171 20.5074 124.795 18.3045H135.917C135.971 17.9821 135.998 17.6329 135.998 17.2836ZM129.013 12.6629C131.189 12.6629 132.747 13.9792 133.177 16.021H124.849C125.305 13.9792 126.864 12.6629 129.013 12.6629Z"
            fill="black"
         />
         <path
            style={style}
            d="M151.16 16.209C150.703 12.6897 147.963 10.2987 144.255 10.2987C140.172 10.2987 137.271 13.2002 137.271 17.2836C137.271 21.394 140.172 24.2685 144.255 24.2685C147.99 24.2685 150.703 21.9044 151.16 18.3582H148.446C148.043 20.4806 146.458 21.797 144.255 21.797C141.703 21.797 139.957 19.997 139.957 17.2836C139.957 14.5703 141.703 12.7435 144.255 12.7435C146.458 12.7435 148.043 14.0867 148.446 16.209H151.16Z"
            fill="black"
         />
         <path
            style={style}
            d="M160.268 10.5674H156.077V6.00034H153.391V10.5674H151.806V13.0121H153.391V19.4866C153.391 22.4686 154.895 23.9999 157.931 23.9999H160.268V21.5283H158.119C156.749 21.5283 156.077 20.8567 156.077 19.4866V13.0121H160.268V10.5674Z"
            fill="black"
         />
      </svg>
   );
};

AuxConnectLogo.propTypes = {
   style: PropTypes.shape({}),
};
AuxConnectLogo.defaultProps = {
   style: {},
};

export default AuxConnectLogo;
