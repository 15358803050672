import React from "react";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
   chip: {
      margin: "4px 4px 4px 0",
      backgroundColor: "white",
      border: `1px solid ${theme.palette.secondary.shade15}`,
      cursor: "pointer",
   },
   postImage: {
      background: `linear-gradient(90deg, #FFDCC9 0%, rgba(255, 220, 201, 0) 100%),
      linear-gradient(180deg, #3EF1D1 0%, rgba(62, 241, 209, 0) 100%), 
       #6147FF`,
      backgroundSize: "cover",
      backgroundPosition: "center center",
      height: "194px",
      width: "100%",
   },
   postTitle: {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: "28px",
      fontWeight: 700,
      margin: "24px 0 18px",
      height: "29px",
      fontFamily: "dr-bold, san-serif",
      color: "black",
   },
   postAuthor: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      marginTop: "12px",
   },
   link: { textDecoration: "none" },
   author: { color: "black" },
   card: { flex: 1, minWidth: "300px" },
}));

const BlogPostCard = ({ post }) => {
   const classes = useStyles();

   return (
      <div key={post?.id} className={classes.card}>
         <Link to={`/connect/${post?.slug}`}>
            <div
               className={classes.postImage}
               style={
                  post?.feature_image
                     ? {
                          backgroundImage: `url(${post?.feature_image})`,
                       }
                     : {}
               }
            />
         </Link>
         <Link to={`/connect/${post?.slug}`} className={classes.link}>
            <Typography className={classes.postTitle} noWrap>
               {post?.title}
            </Typography>
         </Link>
         {post?.tags?.slice(0, 3).map((tag) => (
            <Link
               key={`${post.slug}-${tag.slug}`}
               to={`/connect/tag/${tag.slug}`}
               className={classes.link}
            >
               <Chip key={tag?.id} className={classes.chip} label={tag?.name} />
            </Link>
         ))}
         <div>
            <Link
               to={`/connect/author/${post?.authors[0]?.slug}`}
               className={`${classes.link} ${classes.postAuthor}`}
            >
               <Avatar src={post?.authors[0].profile_image} />
               <div className={classes.author}>
                  {post?.authors.map((el) => el?.name).join(", ")}
               </div>
            </Link>
         </div>
      </div>
   );
};

export default BlogPostCard;
