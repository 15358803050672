import { Box, Button, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { isLightMode } from "themeHelpers";
import SamplePlayer from "./SamplePlayer";
import SoundsSearchForm from "./SoundsSearchForm";

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: "100%",
    width: "100%",
    background:
      isLightMode &&
      "radial-gradient(63.18% 63.49% at 50.05% -18.92%, #C8BFFF 0%, rgba(255, 255, 255, 0.00) 100%)",
  },
  contentsParent: {
    height: "100%",
    width: "100%",
  },
  contentsWrapper: {
    padding: "30px",
  },
  sectionHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  searchFormWrapper: {
    display: "flex",
  },
  // Two column grid
  sampleListings: {
    display: "grid",
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    },
    gap: "16px 24px",
    justifyContent: "center",
  },
  createMoreSamples: {
    marginTop: "24px",
    textAlign: "center",
  },
  sampleDraggable: {},
}));

/**
 * SoundsListings
 *
 * Screen that shows the list of generated sounds.
 *
 * @param {object} props props
 * @param {boolean} props.loading Overall loading state.
 * @param {string} props.prompt Current prompt value.
 * @param {Function} props.setPrompt Set prompt value.
 * @param {Function} props.generateSounds Generate sounds function.
 * @param {Array<string>} props.samples List of generated samples.
 * @param {number} props.samplesGenerating Number of samples still being generated.
 * @param {Function} props.abortAllRequestsAndReset Abort all requests function.
 * @returns {JSX.Element} Sounds Listings screen
 */
const SoundsListings = ({
  loading,
  prompt,
  setPrompt,
  samples,
  samplesGenerating,
  generateSounds,
  abortAllRequestsAndReset,
}) => {
  const classes = useStyles();

  // Store the prompt name so that the players don't update while the user searches with the on page search form.
  const [stickyPrompt, setStickyPrompt] = useState("");
  useEffect(() => {
    setStickyPrompt(prompt);
  }, [samples]);

  const [currentlyPlayingID, setCurrentlyPlayingID] = useState(null);

  return (
    <div className={classes.root}>
      <div className={classes.contentsParent}>
        <div className={classes.contentsWrapper}>
          <div className={classes.sectionHeader}>
            <div className={classes.searchFormWrapper}>
              <Box flexGrow={1}>
                <SoundsSearchForm
                  loading={loading}
                  samples={samples}
                  generateSounds={generateSounds}
                  prompt={prompt}
                  setPrompt={setPrompt}
                  clearExisting
                  abortAllRequestsAndReset={abortAllRequestsAndReset}
                  renderedInline
                />
              </Box>
            </div>
          </div>

          <div className={classes.sampleListings}>
            {/* Loop and show sample players for samples that are ready. */}
            {samples &&
              samples.length > 0 &&
              samples.map((sample, i) => (
                <SamplePlayer
                  id={i} // ID is used to identify the player in the ref.
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  title={stickyPrompt}
                  sample={sample}
                  currentlyPlayingID={currentlyPlayingID}
                  setCurrentlyPlayingID={setCurrentlyPlayingID}
                  generateSounds={generateSounds}
                />
              ))}
            {/* Show loading skeletons for samples that are being generated. */}
            {samplesGenerating > 0 && (
              <>
                {Array.from({ length: samplesGenerating }).map((_, i) => (
                  <div
                    id={`sample-${i}`}
                    className={classes.sampleDraggable}
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                  >
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={92}
                      style={{ borderRadius: "4px" }}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
          <div className={classes.createMoreSamples}>
            {/* "Generate more like this..." triggers more generations which loads 6 new samples on the current page. */}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                // clearExisting set to false.
                generateSounds(prompt, false);
              }}
              sx={{ margin: 1 }}
              disabled={loading || samplesGenerating > 0}
            >
              Create more like this
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoundsListings;
