import PropTypes from "prop-types";
import React from "react";

/**
 * Squiggle
 * A react component that renders the squiggle svg asset.
 * @param {object} props Component's props.
 * @param {object} props.style style object which is used to add extra styles to the svg element.
 * @returns {JSX.Element} Squiggle component.
 */
const Squiggle = ({ style }) => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="571"
         height="322"
         fill="none"
         viewBox="0 0 571 322"
         style={style}
      >
         <path
            fill="url(#paint0_linear_2076_5756)"
            fillRule="evenodd"
            d="M470.424 237.076c6.508-18.882 13.155-42.972 19.888-72.444.814-3.564 6.611-6.095 12.97-5.649l57.567 4.034c6.359.446 10.87 3.705 10.056 7.268-9.855 43.17-19.889 76.649-30.262 99.911-5.361 12.024-11.348 22.576-18.485 30.579-3.524 3.951-8.401 8.522-15.312 12.397-6.995 3.921-19.284 8.828-36.422 8.828-17.138 0-29.426-4.907-36.421-8.828-6.912-3.875-11.789-8.446-15.313-12.397-7.137-8.003-13.123-18.555-18.485-30.579-10.885-24.41-21.396-60.07-31.719-106.372-7.231-32.429-14.368-58.657-21.345-78.9-6.977 20.243-14.113 46.471-21.344 78.9-10.323 46.302-20.834 81.962-31.719 106.372-5.362 12.024-11.348 22.576-18.486 30.579-3.524 3.951-8.4 8.522-15.312 12.397-6.995 3.921-19.284 8.828-36.421 8.828-17.138 0-29.427-4.907-36.422-8.828-6.912-3.875-11.788-8.446-15.312-12.397-7.138-8.003-13.124-18.555-18.485-30.579-10.885-24.41-21.396-60.07-31.72-106.372-7.231-32.429-14.367-58.657-21.344-78.9-6.508 18.882-13.155 42.972-19.887 72.444-.815 3.564-6.612 6.095-12.97 5.649l-57.568-4.034c-6.36-.446-10.87-3.705-10.056-7.268 9.855-43.17 19.889-76.65 30.262-99.911 5.361-12.023 11.348-22.576 18.485-30.579 3.524-3.951 8.4-8.522 15.312-12.397C71.15 4.907 83.438 0 100.576 0c17.138 0 29.426 4.907 36.421 8.828 6.912 3.875 11.789 8.446 15.313 12.397 7.137 8.003 13.123 18.556 18.485 30.579 10.885 24.41 21.396 60.07 31.719 106.372 7.231 32.429 14.368 58.657 21.345 78.9 6.977-20.243 14.113-46.471 21.344-78.9 10.323-46.302 20.834-81.962 31.719-106.372 5.362-12.023 11.348-22.576 18.486-30.579 3.524-3.951 8.4-8.522 15.312-12.397C317.715 4.907 330.004 0 347.141 0c17.138 0 29.427 4.907 36.422 8.828 6.911 3.875 11.788 8.446 15.312 12.397 7.138 8.003 13.124 18.556 18.485 30.579 10.885 24.41 21.396 60.07 31.72 106.372 7.23 32.429 14.367 58.657 21.344 78.9z"
            clipRule="evenodd"
         />
         <defs>
            <linearGradient
               id="paint0_linear_2076_5756"
               x1="285.5"
               x2="285.5"
               y1="0"
               y2="322"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#B1EDFF" />
               <stop offset="1" stopColor="#B1EDFF" stopOpacity="0" />
            </linearGradient>
         </defs>
      </svg>
   );
};
Squiggle.propTypes = {
   style: PropTypes.shape({}),
};
Squiggle.defaultProps = {
   style: {},
};

export default Squiggle;
