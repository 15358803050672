import React from "react";
import { IconButton, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { setBackHistory } from "_redux/actions";

const BackAndForwardButtons = () => {
   const theme = useTheme();
   const history = useHistory();
   const backHistory = useSelector((state) => state.AppReducer.backHistory);

   const dispatch = useDispatch();
   return (
      <>
         <IconButton
            onClick={() => {
               dispatch(setBackHistory(1));
               history.goBack();
            }}
            size="large"
         >
            <ChevronLeftIcon sx={{ color: theme.palette.coreApp.text }} />
         </IconButton>
         <IconButton
            disabled={backHistory < 1}
            onClick={() => {
               dispatch(setBackHistory(-1));
               history.goForward();
            }}
            size="large"
         >
            <ChevronRightIcon sx={{ color: theme.palette.coreApp.text }} />
         </IconButton>
      </>
   );
};

export default BackAndForwardButtons;
