import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useHistory, Link } from "react-router-dom";
import MetaTags from "_components/MetaTags";
import useStatsMutation from "_utils/useStatsMutation";
import * as Sentry from "@sentry/react";
import HiringBadge from "_components/HiringBadge";
import api from "../../_utils/ghostContentApi";
import Footer from "../../_components/Footer";
import Header from "../../_components/Header/Header";
import EngageSignUpForm from "../../_components/EngageSignUpForm";
import SignUpInputFiled from "../../_components/SignUpInputFiled";
import BlogPostCard from "../../_components/BlogPostCard";

const useStyles = makeStyles((theme) => ({
   root: {
      height: "100%",
      width: "100%",
   },
   backgroundMain: {
      background: `linear-gradient(90deg, #FFDCC9 0%, rgba(255, 220, 201, 0) 100%),
      linear-gradient(180deg, #3EF1D1 0%, rgba(62, 241, 209, 0) 100%), 
       #6147FF`,
      padding: "0px 0 95px",
   },
   title: {
      textAlign: "center",
      fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
      maxWidth: "800px",
      marginLeft: "auto",
      marginRight: "auto",
      fontSize: theme.typography.big1.fontSize,
      lineHeight: "48px",
   },
   subtitle: {
      textAlign: "center",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "600px",
      fontWeight: 400,
      fontSize: theme.typography.h6.fontSize,
      lineHeight: "24px",
   },
   getStartedForm: {
      display: "flex",
      justifyContent: "center",
      padding: "45px 0 90px",
      maxWidth: "520px",
      margin: "0 auto",
   },
   containerSpace: {
      paddingTop: "80px",
      paddingBottom: "64px",
   },
   mainPost: {
      display: "grid",
      gridTemplateColumns: "1fr 2fr",
      gridGap: 24,
   },
   containerPost: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit,  minmax(280px, 1fr))",
      gridGap: 40,
      marginTop: "40px",
   },
   divider: {
      width: "100%",
      height: "1px",
      backgroundColor: theme.palette.secondary.shade15,
      marginTop: 40,
   },
   chip: {
      margin: "4px 4px 4px 0",
      backgroundColor: "white",
      border: `1px solid ${theme.palette.secondary.shade15}`,
      cursor: "pointer",
   },
   mainPostImage: {
      background: `linear-gradient(90deg, #FFDCC9 0%, rgba(255, 220, 201, 0) 100%),
      linear-gradient(180deg, #3EF1D1 0%, rgba(62, 241, 209, 0) 100%), 
       #6147FF`,
      backgroundSize: "cover",
      backgroundPosition: "center center",
      width: "100%",
      height: "100%",
   },
   mainPostTitle: {
      marginTop: "30px",
      fontSize: "34px",
      lineHeight: "41.15px",
      fontWeight: 700,
      height: "205px",
      "&:hover": {
         cursor: "pointer",
      },
   },
   mainPostAuthor: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
   },
   centerBtn: {
      display: "flex",
      justifyContent: "center",
   },
   linkNoDec: { textDecoration: "none", color: "black" },
}));

/**
 * Blog
 *
 * Blog home page
 * @returns {JSX.Element} Blog home page component
 */
const Blog = () => {
   const classes = useStyles();
   const history = useHistory();

   const [ghostPosts, setGhostPosts] = useState([]);
   const [paginationKey, setPaginationKey] = useState(1);

   const sendStatsMutation = useStatsMutation();

   useEffect(() => {
      sendStatsMutation({
         statsId: "UserPageView",
      });
   }, []);

   /**
    * Fetch posts from Ghost CMS
    * @param {object} page Ghost API post/page object.
    */
   const fetchPosts = (page) => {
      if (!page) return;
      api.posts
         .browse({
            include: "tags,authors",
            page,
            limit: ghostPosts.length === 0 ? 7 : 6,
         })
         .then((posts) => {
            setPaginationKey(posts.meta.pagination.next);
            setGhostPosts([...ghostPosts, ...posts]);
         })
         .catch((error) => {
            Sentry.captureException(error);
         });
   };

   useEffect(() => {
      fetchPosts(paginationKey);
   }, []);

   const mobileSize = useMediaQuery("(max-width:768px)");

   const mainPost = ghostPosts[0];
   const restPost = ghostPosts.slice(1);

   return (
      <div className={classes.root}>
         <HiringBadge />
         <MetaTags
            title="Aux Connect Blog"
            description="Aux's music industry blog. Look here for insight, opinions and advice on all things music."
         />
         <div className={classes.backgroundMain}>
            <Header />
            <Container>
               <h1 className={classes.title}>Welcome to Connect</h1>
               <div className={classes.subtitle}>
                  Aux&apos;s music industry blog crafted by experienced creators
                  and writers from every corner of the community. Look here for
                  insight, opinions and advice on all things music.
               </div>
               <div className={classes.getStartedForm}>
                  <SignUpInputFiled />
               </div>
            </Container>
         </div>
         <Container className={classes.containerSpace}>
            {mobileSize ? (
               <BlogPostCard post={mainPost} />
            ) : (
               <div className={classes.mainPost}>
                  <div>
                     <div>
                        {mainPost?.tags?.map((tag) => (
                           <Chip
                              component={Link}
                              to={`/connect/tag/${tag.slug}`}
                              key={tag?.id}
                              className={classes.chip}
                              label={tag?.name}
                           />
                        ))}
                     </div>
                     <div
                        className={classes.mainPostTitle}
                        aria-hidden="true"
                        onClick={() =>
                           history.push(`/connect/${mainPost?.slug}`)
                        }
                     >
                        {mainPost?.title}
                     </div>
                     <div className={classes.mainPostAuthor}>
                        <Avatar src={mainPost?.authors[0]?.profile_image} />
                        <Link
                           to={`/connect/author/${mainPost?.authors[0]?.slug}`}
                           className={classes.linkNoDec}
                        >
                           <div>{mainPost?.authors[0]?.name}</div>
                        </Link>
                     </div>
                  </div>
                  <Link to={`/connect/${mainPost?.slug}`}>
                     <div
                        className={classes.mainPostImage}
                        style={
                           mainPost?.feature_image
                              ? {
                                   backgroundImage: `url(${mainPost?.feature_image})`,
                                }
                              : {}
                        }
                     />
                  </Link>
               </div>
            )}

            <div className={classes.divider} />
            <div className={classes.containerPost}>
               {restPost.map((post) => (
                  <BlogPostCard key={post?.slug} post={post} />
               ))}
            </div>
         </Container>
         <div className={classes.centerBtn}>
            {paginationKey && (
               <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => fetchPosts(paginationKey)}
               >
                  Load More
               </Button>
            )}
         </div>
         <EngageSignUpForm />
         <Footer />
      </div>
   );
};

export default Blog;
