import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, TextField, InputLabel } from "@mui/material";
import { useMutation } from "@apollo/react-hooks";
import makeStyles from "@mui/styles/makeStyles";
import { UPDATE_PASSWORD } from "_apollo/queries";
import { useUser } from "_utils/UserContext";

const useStyles = makeStyles((theme) => ({
   label: {
      marginTop: "8px",
      marginBottom: "8px",
   },
   input: {
      marginBottom: "8px",
   },
   success: {
      marginLeft: "12px",
      fontSize: "12px",
      lineHeight: "16px",
      color: theme.palette.primary.main,
   },
}));

/**
 * Change Password Form
 * @returns {Element} jsx element.
 */
function ChangePasswordForm() {
   const [user] = useUser();
   const classes = useStyles();

   const schemaPassword = Yup.object().shape({
      currentPassword: Yup.string().min(8).required().label("Current Password"),
      password: Yup.string().min(8).required().label("Password"),
      confirmPassword: Yup.string()
         .label("Confirm password")
         .oneOf([Yup.ref("password"), null], "Passwords must match"),
   });

   const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD);
   const [passwordUpdatedSuccesfully, setPasswordUpdatedSuccesfully] =
      useState(false);

   return (
      <Formik
         enableReinitialize
         initialValues={{
            currentPassword: "",
            password: "",
            confirmPassword: "",
         }}
         validateOnChange={false}
         validationSchema={schemaPassword}
         onSubmit={async (values, { resetForm, setFieldError }) => {
            await updatePassword({
               variables: {
                  currentPassword: values.currentPassword,
                  password: values.password,
                  confirmPassword: values.confirmPassword,
                  id: user.id,
               },
               /**
                * updatePassword onCompleted
                *
                * When the password reset form request is sent, reset the form and update the state to show the user to check their email.
                */
               onCompleted() {
                  resetForm();
                  setPasswordUpdatedSuccesfully(true);
               },
               /**
                * updatePassword onError
                * @param {props} props Apollo query error data object.
                */
               onError({ graphQLErrors }) {
                  setFieldError("currentPassword", graphQLErrors[0]?.message);
               },
            });
         }}
      >
         {({ handleSubmit, handleChange, errors, values, touched }) => {
            return (
               <form onSubmit={handleSubmit}>
                  <InputLabel className={classes.label}>
                     Current Password
                  </InputLabel>
                  <TextField
                     className={classes.input}
                     type="password"
                     name="currentPassword"
                     value={values.currentPassword}
                     isInvalid={!!errors.currentPassword}
                     error={errors.currentPassword}
                     helperText={
                        touched.currentPassword && errors.currentPassword
                     }
                     onChange={handleChange}
                     size="small"
                     variant="outlined"
                     fullWidth
                  />

                  <InputLabel className={classes.label}>Password</InputLabel>
                  <TextField
                     className={classes.input}
                     type="password"
                     name="password"
                     value={values.password}
                     isInvalid={!!errors.password}
                     error={errors.password}
                     onChange={handleChange}
                     size="small"
                     variant="outlined"
                     fullWidth
                     helperText={touched.password && errors.password}
                  />

                  <InputLabel className={classes.label}>
                     Confirm Password
                  </InputLabel>
                  <TextField
                     className={classes.input}
                     type="password"
                     name="confirmPassword"
                     value={values.confirmPassword}
                     isInvalid={!!errors.confirmPassword}
                     error={errors.confirmPassword}
                     onChange={handleChange}
                     size="small"
                     variant="outlined"
                     fullWidth
                     helperText={
                        touched.confirmPassword && errors.confirmPassword
                     }
                  />

                  <Button
                     variant="outlined"
                     color="primary"
                     type="submit"
                     disabled={loading}
                  >
                     {loading ? "Loading..." : "Update"}
                  </Button>
                  {passwordUpdatedSuccesfully && (
                     <span className={classes.success}>
                        Password updated successfully
                     </span>
                  )}
               </form>
            );
         }}
      </Formik>
   );
}

export default ChangePasswordForm;
