import PropTypes from "prop-types";
import React from "react";

import { connect } from "react-redux";
import { Box, Divider } from "@mui/material";
import UploadProfileImage from "_components/UploadProfileImage";
import CategoryForm from "_pages/SettingsPage/SettingsComponents/ProfileSettings/CategoryForm";
import ProfileInformationForm from "_pages/SettingsPage/SettingsComponents/ProfileSettings/ProfileInformationForm";
import SettingsContainer from "_components/SettingsContainer";
import { useUser } from "_utils/UserContext";
import NameSettingsForm from "./ProfileSettings/NameSettingsForm";
import ChangePasswordForm from "./ProfileSettings/ChangePasswordForm";

/**
 * Profile Settings Page
 *
 * The users profile settings.
 *
 * NOTE: contains legacy code related to multiple users managing a single profile which is not used currently.
 * @param {object} prop props
 * @param {object} prop.selectedTeam Users team plan.
 * @param {object} prop.managingProfile Managing profile
 * @param {boolean} prop.isManagingProfile Is the user managing the profile on behalf of another user.
 * @returns {JSX.Element} Profile settings page component.
 */
const ProfileSettings = ({
   selectedTeam,
   managingProfile,
   isManagingProfile,
}) => {
   const [user] = useUser();

   return (
      <SettingsContainer title="Profile">
         {((isManagingProfile && managingProfile) ||
            (!isManagingProfile && user)) && (
            <>
               <UploadProfileImage
                  managingProfile={managingProfile}
                  selectedTeam={selectedTeam}
                  profile={user?.profile}
               />
               <NameSettingsForm />

               <Divider style={{ margin: "20px 0px" }} />
               {user?.profile?.guide === 1 && (
                  <CategoryForm
                     user={user}
                     showSubtitle
                     id={managingProfile?.id || null}
                     teamId={selectedTeam}
                  />
               )}
               <ProfileInformationForm
                  isManagingProfile={isManagingProfile}
                  managingProfile={managingProfile}
                  selectedTeam={selectedTeam}
               />

               <Divider style={{ margin: "20px 0px" }} />
               <ChangePasswordForm />

               <Box style={{ marginTop: "1em" }} />
            </>
         )}
      </SettingsContainer>
   );
};

ProfileSettings.propTypes = {
   selectedTeam: PropTypes.string.isRequired,
   managingProfile: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      uri: PropTypes.string,
      links: PropTypes.arrayOf({}),
   }).isRequired,
   isManagingProfile: PropTypes.bool.isRequired,
};

/**
 * mapStateToProps
 *
 * TODO: needs to be replaced with redux useDispatch/useSelector
 * @param {object} state redux state
 * @returns {object} props for component including state.
 */
const mapStateToProps = (state) => {
   const { selectedTeam, managingProfile, isManagingProfile } =
      state.AppReducer;
   return { selectedTeam, managingProfile, isManagingProfile };
};

export default connect(mapStateToProps)(ProfileSettings);
