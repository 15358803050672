import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import slugify from "slugify";
import { GET_TAGS } from "../_apollo/queries";
import Tag from "./Tag";
import SuggestionsTags from "./SuggestionsTags/SuggestionsTags";

/**
 * Tag Form
 *
 * Form to allow users to tag their own profile.
 *
 * TODO: @lluisemper refactor this as it's got mega props! Data access and storage can be pulled through into the child component.
 * @param {object} props props
 * @param {object} props.values Formik tag state.
 * @param {Function} props.setFieldValue Update the form values.
 * @param {Function} props.handleChange Update the form values change event
 * @param {object} props.errors Formik errors.
 * @param {boolean} props.touched Has the user interacted with the form.
 * @param {Function} props.setFieldTouched Set the touched state of the form.
 * @param {Function} props.setTags Set the tags state.
 * @param {Function} props.setDeletedTags Set the deleted tags state.
 * @param {object} props.profileForm Formik instance of the profile form.
 * @param {object} props.tags Tags state.
 * @param {boolean} props.showSubtitle Show the subtitle.
 * @param {object} props.profile Users profile object.
 * @returns {JSX.Element} Tag From component.
 */
const TagForm = ({
   values,
   setFieldValue,
   handleChange,
   errors,
   touched,
   setFieldTouched,
   setTags,
   setDeletedTags,
   profileForm,
   tags,
   profile,
}) => {
   const [tagSearch, setTagSearch] = useState([]);
   const [tagDefault, setTagDefault] = useState([]);

   useQuery(GET_TAGS, {
      /**
       * getTags onCompleted
       * @param {object} data Apollo data object.
       */
      onCompleted(data) {
         setTagSearch(data.getTags);
         setTagDefault(data.getTags);
      },
   });

   useEffect(() => {
      if (profile?.tags) {
         setTags(profile?.tags);
      }
   }, [profile?.tags]);

   /**
    * Remove Tag by Name
    * @param {string} name Tag name.
    */
   const removeTags = (name) => {
      setDeletedTags((prevArr) => [...prevArr, name]);
      setTags((prevTags) => prevTags.filter((tag) => tag.name !== name));
   };

   /**
    * Add Tags
    * @param {object} tagData Tag data to add.
    * @returns {void}
    */
   const addTags = (tagData) => {
      const { name } = tagData;
      if (!name || name === "") return;
      if (tags.length < 5) {
         const tagName = name.trim();
         const tagUri = slugify(tagName, { lower: true });
         if (
            tags.findIndex(
               (tag) => slugify(tag.name, { lower: true }) === tagUri
            ) === -1
         ) {
            setTags((prevTags) => [...prevTags, { name: tagName }]);
         }
      } else {
         profileForm?.current?.setFieldError("tag", "You may only add 5 tags.");
      }
   };

   return (
      <Box position="relative">
         {/* {showSubtitle && (
            <p className="text-muted text-small">
               <small>
                  Tag your profile with genres (Hip Hop, House...), what you do
                  (Producer, Songwriter, A&amp;R..) and your locations (eg.
                  London, Ibiza, Miami...) to help us customise your feed and
                  get discovered in the guide.
               </small>
            </p>
         )} */}
         {tags.length > 0 &&
            tags?.map((tag, index) => {
               const key = `${tag}-${index}`;
               return (
                  <Tag id={key} key={key} tag={tag} removeTags={removeTags} />
               );
            })}

         <SuggestionsTags
            // data={dataContacts?.userContacts}
            tagSearch={tagSearch}
            setTagSearch={setTagSearch}
            tagDefault={tagDefault}
            values={values}
            tags={tags}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            addTags={addTags}
            errors={errors}
            touched={touched.tag}
            setFieldTouched={setFieldTouched}
         />
      </Box>
   );
};

TagForm.propTypes = {
   values: PropTypes.instanceOf(Object).isRequired,
   setFieldValue: PropTypes.func.isRequired,
   handleChange: PropTypes.func.isRequired,
   errors: PropTypes.instanceOf(Object).isRequired,
   touched: PropTypes.instanceOf(Object).isRequired,
   setFieldTouched: PropTypes.func.isRequired,
   profile: PropTypes.instanceOf(Object),
   setTags: PropTypes.func.isRequired,
   setDeletedTags: PropTypes.func.isRequired,
   profileForm: PropTypes.instanceOf(Object).isRequired,
   tags: PropTypes.instanceOf(Array).isRequired,
};

TagForm.defaultProps = {
   profile: {},
};

export default TagForm;
