import { useEffect, useState } from "react";

/**
 * Get Connections Custom Hook
 * @param {boolean} showNotAccepted Include connection requests that are not accepted.
 * @param {object} user User to retrieve connections from.
 * @returns {Array} connections and getConnections.
 */
const useGetConnections = (showNotAccepted, user) => {
   const [connections, setConnections] = useState([]);

   useEffect(() => {
      const connectionsObj = user?.profile?.connections;
      let combinedArr = [];
      if (connectionsObj) {
         const sentConnection = connectionsObj?.sent?.filter((c) =>
            showNotAccepted ? true : c?.accepted === true
         );
         const receivedConnections = connectionsObj?.received?.filter((c) =>
            showNotAccepted ? true : c?.accepted === true
         );
         combinedArr = [
            ...(sentConnection?.map((sConnections) => ({
               ...sConnections?.receiverProfile,
               accepted: sConnections.accepted,
            })) || []),
            ...(receivedConnections?.map((rConnections) => ({
               ...rConnections?.senderProfile,
               accepted: rConnections.accepted,
            })) || []),
         ];
      }

      if (combinedArr.length > 0) {
         setConnections([...(combinedArr || [])]);
      }
   }, [user?.profile?.connections]);

   return [connections, setConnections];
};

export default useGetConnections;
