import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";

import { useQueryParam, StringParam } from "use-query-params";

import { Link } from "react-router-dom";
import { Box, Typography, useTheme } from "@mui/material";
import AuxAwardsLogo from "_assets/AuxAwardsLogo";
import JoinAuxConnectProfile from "_components/JoinAuxConnectProfile";
import useRedirectToFeedIfAuthorised from "_utils/useRedirectToFeedIfAuthorised";
import SignUpForm from "_components/SignUpForm";

// Redux
import AuxLogo from "../_assets/AuxLogo";
import SignInSignUpFeaturedPost from "../_components/SignInSignUpFeaturedPost";

const useStyles = makeStyles((theme) => ({
   root: {
      display: "flex",
      height: "100vh",
      "@media (max-width: 768px)": {
         flexDirection: "column-reverse",
      },
   },
   container: {
      flex: 3,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      padding: "4vw",
   },
   wrapperPost: {
      flex: 5,
   },
   noDrag: {
      ...theme.noDrag,
   },
}));

/**
 * Sign Up Page
 *
 * Sign Up page and featured post column.
 * @returns {JSX.Element} SignUpPage component.
 */
const SignUpPage = () => {
   const theme = useTheme();
   const classes = useStyles();

   const [redirectTo] = useQueryParam("redirectTo", StringParam);

   useRedirectToFeedIfAuthorised();

   return (
      <div className={classes.root}>
         <Container className={classes.container} maxWidth="md">
            <Link className={classes.noDrag} to="/">
               <AuxLogo
                  style={{
                     height: "36px",
                     width: "116px",
                     color: theme.palette.coreApp.text,
                  }}
               />
            </Link>
            {redirectTo === "awards" && (
               <Box
                  border="1px solid black"
                  padding="17px"
                  alignSelf="flex-start"
                  marginTop="10px"
               >
                  <AuxAwardsLogo />
                  <Typography fontWeight="bold" fontSize="13px">
                     You will be able to vote after you Sign Up
                  </Typography>
               </Box>
            )}
            {/* Show conversion context when user is creating and account to connect to a profile. */}
            {redirectTo && redirectTo !== "awards" && <JoinAuxConnectProfile />}

            {/* TODO: Message as above saying "Join Aux to join the project ${project.name}" */}

            {/* TODO: Re-enable and test social login.
                  <div className={classes.wrapperProviderBtn}>
                     <ProviderButton />
                  </div>
                  <div className="separator">Or</div>
                  */}
            <SignUpForm />
         </Container>
         <div className={classes.wrapperPost}>
            <SignInSignUpFeaturedPost type="signup" />
         </div>
      </div>
   );
};

SignUpPage.propTypes = {
   location: PropTypes.shape({
      state: PropTypes.shape({
         getStartedEmail: PropTypes.string,
      }),
   }),
};
SignUpPage.defaultProps = {
   location: null,
};

export default SignUpPage;
