import React from "react";

/**
 * FolderEmptyIcon
 * @returns {JSX.Element} FolderEmptyIcon
 */
function FolderEmptyIcon() {
   return (
      <svg
         width="90"
         height="90"
         viewBox="0 0 90 90"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M75 22.5H45L37.5 15H15C10.875 15 7.5 18.375 7.5 22.5V67.5C7.5 71.625 10.8375 75 14.9625 75H74.9625C79.0875 75 82.4625 71.625 82.4625 67.5V30C82.4625 25.875 79.0875 22.5 74.9625 22.5H75ZM45 70.0875V64.9125C40.875 64.9125 36.9 63.2625 34.0125 60.3375C31.0875 57.4125 29.4375 53.475 29.4375 49.35C29.4375 46.425 30.2625 43.5375 31.8375 41.0625L35.6625 44.8875C34.9875 46.275 34.65 47.8125 34.6125 49.35C34.6125 52.0875 35.7 54.75 37.65 56.7C39.6 58.65 42.225 59.7375 45 59.7375V54.5625L52.7625 62.325L45 70.0875ZM58.1625 57.6L54.3375 53.775C55.0125 52.3875 55.35 50.85 55.3875 49.3125C55.3875 46.575 54.3 43.9125 52.35 41.9625C50.4 40.0125 47.775 38.925 45 38.925V44.1L36.9 36.3375L45 28.575V33.75C49.125 33.75 53.1 35.4 55.9875 38.325C58.9125 41.25 60.5625 45.1875 60.5625 49.3125C60.5625 52.2375 59.7375 55.125 58.1625 57.6Z"
            fill="#B3B3B3"
         />
      </svg>
   );
}

export default FolderEmptyIcon;
