import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";
import CustomModal from "./Modal/CustomModal";

const useStyles = makeStyles(() => ({
   landscape: {
      maxHeight: "calc(100vh - 100px)",
      width: "auto",
      height: "calc(100vh - 100px)",
      display: "flex",
      justifyContent: "center",
      boxSizing: "border-box",
      padding: "0 12px",
   },
   portrait: {
      maxHeight: "calc(100vh - 100px)",
      width: "100%",
      height: "auto",
      display: "flex",
      justifyContent: "center",
      padding: "0 12px",
      boxSizing: "border-box",
   },
}));

/**
 * MediaFullScreen
 * @param {object} props props.
 * @param {boolean} props.open modal open/close.
 * @param {Function} props.setOpen sets open or close state.
 * @param {object} props.file file to be previewed.
 * @returns {JSX.Element} modal with the file/video preview.
 */
const MediaFullScreen = ({ open, setOpen, file }) => {
   const classes = useStyles();
   const [mediaIsPortrait, setMediaIsPortrait] = useState(false);

   /**
    * mediaOrientation
    *
    * Function used to calculate the orentation of the media, which will be used to set the right css styles.
    */
   const mediaOrientation = () => {
      const img = new Image();
      img.src = file.signedUrl;
      if (img.naturalWidth > img.naturalHeight) {
         setMediaIsPortrait(true);
      } else if (img.naturalWidth <= img.naturalHeight) {
         setMediaIsPortrait(false);
      }
   };

   useEffect(() => {
      mediaOrientation();
   }, []);

   return (
      <CustomModal
         open={open}
         handleClose={() => setOpen(null)}
         fullWidth="lg"
         header={
            <Typography variant="largeBold">{file.originalName}</Typography>
         }
      >
         <div
            className={mediaIsPortrait ? classes.portrait : classes.landscape}
         >
            {file?.mimeType?.includes("image") ? (
               <img
                  src={file.signedUrl}
                  alt={file.originalName}
                  className={
                     mediaIsPortrait ? classes.portrait : classes.landscape
                  }
               />
            ) : (
               // eslint-disable-next-line jsx-a11y/media-has-caption
               <video
                  src={file.signedUrl}
                  className={
                     mediaIsPortrait ? classes.portrait : classes.landscape
                  }
                  controls
                  autoPlay
               />
            )}
         </div>
      </CustomModal>
   );
};

MediaFullScreen.propTypes = {
   file: PropTypes.shape({
      mimeType: PropTypes.shape({
         includes: PropTypes.func,
      }),
      originalName: PropTypes.string,
      signedUrl: PropTypes.string,
   }).isRequired,
   open: PropTypes.bool.isRequired,
   setOpen: PropTypes.func.isRequired,
};

export default MediaFullScreen;
