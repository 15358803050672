import PropTypes from "prop-types";
import React from "react";

const AuxLogo = ({ width = 643, height = 126, style, className }) => {
   return (
      <svg
         className={className}
         width={width}
         height={height}
         style={style}
         viewBox="0 0 643 126"
         // fill="currentcolor"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M63 125.4C75 125.4 85.56 121.8 94.2 115.32V123H125.4V2.99998H94.2V10.68C85.56 4.19998 75 0.599976 63 0.599976C26.52 0.599976 0.600006 26.52 0.600006 63C0.600006 99.72 26.52 125.4 63 125.4ZM63 96.6C44.04 96.6 31.8 83.4 31.8 63C31.8 42.6 44.04 29.4 63 29.4C81.96 29.4 94.2 42.6 94.2 63C94.2 83.4 81.96 96.6 63 96.6Z"
            fill="currentcolor"
         />
         <path
            d="M137.297 75C137.297 104.52 157.697 125.4 185.777 125.4C196.337 125.4 205.697 121.8 212.897 115.32V123H244.097V2.99998H212.897V75C212.897 87.72 203.777 96.6 190.577 96.6C177.857 96.6 168.497 87.72 168.497 75V2.99998H137.297V75Z"
            fill="currentcolor"
         />
         <path
            d="M330.045 123H366.525L326.925 60.6L363.645 2.99998H327.405L308.445 34.2L289.485 2.99998H253.005L289.725 60.84L250.125 123H286.605L308.445 87.24L330.045 123Z"
            fill="currentcolor"
         />
         <path
            d="M420.016 57.806C436.217 57.806 447.823 46.3608 447.823 29.999C447.823 13.7178 436.217 2.19202 420.016 2.19202C403.815 2.19202 392.209 13.7178 392.209 29.999C392.209 46.3608 403.815 57.806 420.016 57.806ZM420.016 47.731C409.699 47.731 403.09 40.7994 403.09 29.999C403.09 19.1986 409.699 12.267 420.016 12.267C430.333 12.267 436.942 19.1986 436.942 29.999C436.942 40.7994 430.333 47.731 420.016 47.731Z"
            fill="currentcolor"
         />
         <path
            d="M459.085 57H469.966V39.9128H478.751C490.277 39.9128 498.579 32.4976 498.579 21.4554C498.579 10.4132 490.277 2.99802 478.751 2.99802H459.085V57ZM478.751 13.073C484.232 13.073 487.698 16.3776 487.698 21.4554C487.698 26.5332 484.313 29.8378 478.751 29.8378H469.966V13.073H478.751Z"
            fill="currentcolor"
         />
         <path
            d="M509.885 2.99802V57H545.188V46.925H520.766V34.1902H545.188V24.1152H520.766V13.073H545.188V2.99802H509.885Z"
            fill="currentcolor"
         />
         <path
            d="M594.699 57H605.58V2.99802H594.699V38.462L568.181 2.99802H557.3V57H568.181V21.536L594.699 57Z"
            fill="currentcolor"
         />
         <path
            d="M403.896 85.6822L418.726 103.817L433.637 85.6822V123H444.518V68.998H433.637L418.726 88.0196L403.896 68.998H393.015V123H403.896V85.6822Z"
            fill="currentcolor"
         />
         <path
            d="M456.645 68.998V101.56C456.645 114.618 466.639 123.806 480.825 123.806C495.011 123.806 504.924 114.618 504.924 101.56V68.998H494.043V100.754C494.043 108.653 489.207 113.731 480.825 113.731C472.443 113.731 467.526 108.653 467.526 100.754V68.998H456.645Z"
            fill="currentcolor"
         />
         <path
            d="M552.915 107.767C552.915 86.8912 527.526 94.0646 527.526 84.2314C527.526 80.3626 530.347 78.267 534.78 78.267C539.213 78.267 542.034 80.685 542.034 85.0374H552.915C552.915 74.9624 545.338 68.192 534.78 68.192C524.221 68.192 516.645 74.64 516.645 84.2314C516.645 104.865 542.034 96.0796 542.034 107.767C542.034 111.635 539.213 113.731 534.78 113.731C530.347 113.731 527.526 111.313 527.526 106.961H516.645C516.645 117.036 524.221 123.806 534.78 123.806C545.338 123.806 552.915 117.358 552.915 107.767Z"
            fill="currentcolor"
         />
         <path
            d="M575.502 123V68.998H564.621V123H575.502Z"
            fill="currentcolor"
         />
         <path
            d="M631.403 93.984H642.284C641.478 78.8312 630.194 68.192 614.558 68.192C598.357 68.192 586.751 79.7178 586.751 95.999C586.751 112.361 598.357 123.806 614.558 123.806C630.194 123.806 641.398 113.328 642.284 98.014H631.403C630.678 107.686 624.23 113.731 614.558 113.731C604.241 113.731 597.632 106.799 597.632 95.999C597.632 85.1986 604.241 78.267 614.558 78.267C624.23 78.267 630.597 84.312 631.403 93.984Z"
            fill="currentcolor"
         />
      </svg>
   );
};

AuxLogo.propTypes = {
   width: PropTypes.number,
   height: PropTypes.number,
   style: PropTypes.instanceOf(Object),
};
AuxLogo.defaultProps = {
   style: {},
   width: 65,
   height: 14,
};

export default AuxLogo;
