import React from "react";

/**
 * AdobeAuditionIcon
 * @returns {JSX.Element} Adobe Audition Icon
 */
function AdobeAuditionIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2H21C21.55 2 22 2.45 22 3V21C22 21.55 21.55 22 21 22H3C2.45 22 2 21.55 2 21V3C2 2.45 2.45 2 3 2ZM10.69 16H12.43V16.01C12.52 16.01 12.54 15.97 12.52 15.88L9.49 7.33C9.48 7.28 9.43 7.26 9.38 7.26H7.22C7.17 7.26 7.15 7.31 7.15 7.35C7.15 7.53 7.12 7.71 7.08 7.88C7.04 7.98 7.01 8.06 6.99 8.15L4.31 15.86C4.28 15.96 4.31 16.01 4.4 16.01H5.97C6.04 16.01 6.11 15.97 6.13 15.89L6.76 13.92H9.86L10.56 15.91C10.57 15.97 10.64 16 10.69 16ZM17.44 16H18.87L18.86 16.02C18.93 16.02 18.95 15.99 18.95 15.91C18.9464 15.8774 18.9441 15.8461 18.9416 15.8133C18.9374 15.7555 18.9328 15.6929 18.92 15.61C18.92 15.5494 18.9156 15.4822 18.911 15.4105C18.9057 15.3285 18.9 15.2407 18.9 15.15V14.55V9.44V9.42C18.9 9.37 18.81 9.35 18.81 9.35H17.22C17.14 9.35 17.1 9.39 17.1 9.44V14.17C16.95 14.28 16.78 14.37 16.58 14.44C16.39 14.51 16.21 14.54 16.01 14.54C15.63 14.54 15.31 14.43 15.15 14.22C15 14.01 14.91 13.72 14.91 13.35V9.41C14.9 9.34 14.8 9.32 14.8 9.32H13.19C13.12 9.33 13.1 9.43 13.1 9.43V13.56C13.09 13.97 13.14 14.37 13.26 14.75C13.36 15.05 13.51 15.33 13.73 15.56C13.93 15.77 14.17 15.93 14.44 16.02C14.72 16.12 15.03 16.16 15.34 16.16C15.65 16.16 15.97 16.13 16.27 16.04C16.62 15.94 16.94 15.77 17.21 15.53C17.21 15.58 17.21 15.65 17.23 15.7C17.24 15.735 17.2475 15.77 17.255 15.805C17.2625 15.84 17.27 15.875 17.28 15.91C17.31 15.97 17.37 16 17.44 16ZM8.77644 10.4588C8.82469 10.6169 8.87338 10.7765 8.92998 10.94H8.90998C8.93756 11.0262 8.96395 11.1112 8.98998 11.195C9.03944 11.3543 9.08756 11.5093 9.13998 11.66C9.21998 11.88 9.28998 12.09 9.33998 12.26H7.22998C7.35998 11.91 7.48998 11.52 7.58998 11.1C7.71998 10.69 7.84998 10.27 7.96998 9.87C8.08998 9.51001 8.16998 9.15002 8.24998 8.79003L8.24998 8.79H8.26998C8.31171 8.90924 8.34634 9.03558 8.38233 9.16691C8.40671 9.25587 8.43171 9.34712 8.45998 9.44L8.45999 9.44003C8.52999 9.68002 8.59999 9.92001 8.68998 10.18C8.71967 10.2728 8.74798 10.3655 8.77644 10.4588Z"
        fill="black"
      />
    </svg>
  );
}

export default AdobeAuditionIcon;
