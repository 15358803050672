import PropTypes from "prop-types";
import {
   Box,
   Button,
   Checkbox,
   Grid,
   IconButton,
   Popover,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   TableSortLabel,
   useTheme,
   Skeleton,
   Input,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Delete, MoreHoriz } from "@mui/icons-material";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import InfiniteScroll from "react-infinite-scroller";
import isElectronHook from "_utils/isElectron";
import { useUser } from "_utils/UserContext";
import CloseIcon from "@mui/icons-material/Close";
import {
   GET_PROJECTS,
   RENAME_FILE_OR_FOLDER,
   GET_PROFILE_STATS,
} from "_apollo/queries";
import {
   getGenericName,
   getNameWithoutExtension,
} from "_utils/fileTypeUtil/fileTypeUtil";

import TableRowFolder from "./TableRowFolder";
import TableRowFile from "./TableRowFile";
import { CREATE_FOLDER, GET_FOLDERS } from "../../_apollo/queries";
import TableCellLastModified from "./TableCells/TableCellLastModified";
import TableCellSize from "./TableCells/TableCellSize";
import TableCellType from "./TableCells/TableCellType";
import EmptyFilesView from "./EmptyFilesView";

const useStyles = makeStyles((theme) => ({
   menuPopover: {
      "& > .MuiPopover-paper": {
         background: theme.palette.secondary.shade5,
         boxShadow:
            "-1px -1px 3px rgba(102, 102, 102, 0.15), 3px 5px 5px rgba(102, 102, 102, 0.15)",
      },
   },
   popOver: {
      minWidth: 120,
      padding: "9px 0",
      [`& button`]: {
         color: "black !important",
         fontSize: "0.75em",
         width: "100%",
         borderRadius: 0,
         lineHeight: "12px",
         padding: "6px 16px",
         "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "white !important",
         },
         [`& span`]: {
            justifyContent: "flex-start",
         },
      },
   },
   table: {
      overflow: "auto",
      height: "calc(100vh - 128px)",
      padding: "0 16px",
      [`& table`]: {
         borderCollapse: "collapse",
      },
      "& thead tr th svg.MuiSvgIcon-root.MuiTableSortLabel-icon": {
         // Sort icon (arrow) in purple.
         color: theme.palette.primary.main,
      },
      "& thead tr th .MuiTableSortLabel-root, thead tr th .MuiTableSortLabel-root.Mui-active":
         {
            // Table header label text.
            color: theme.palette.coreApp.text,
         },
      "& tr span:not(.Mui-checked) svg.MuiSvgIcon-root": {
         // Checkbox
         color: theme.palette.coreApp.text,
      },
      "& thead tr th:first-of-type": {
         width: "18px",
         "& span": {
            padding: 0,
            width: "auto",
            marginRight: "12px",
            "&:hover": {
               backgroundColor: "transparent",
            },
         },
      },
      "& tbody tr td:first-of-type": {
         width: "18px",
         "& span": {
            padding: 0,
            width: "auto",
            "&:hover": {
               backgroundColor: "transparent",
            },
         },
      },
   },
   tableCell: {
      fontSize: theme.typography.small1.fontSize,
      padding: "6px 0px",
      width: "55%",
   },
   tableRow: {
      cursor: "pointer",
      position: "relative",
      [`& .MuiTableCell-body, & button`]: {
         color: "inherit",
      },
   },
   dragInvisible: {
      visibility: "hidden",
   },
   dragHover: {
      // TODO: still need to fix drop area shifting layout when dragging and dropping file.
      border: `2px dashed ${theme.palette.primary.main} !important`,
   },
   dropzone: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      padding: 0,
      background: "none",
      pointerEvents: "none",
   },
   multiGhostElement: {
      width: 150,
      border: `2px solid ${theme.palette.primary.main}`,
      display: "flex",
      justifyContent: "center",
      borderRadius: 5,
      padding: "15px 0",
      background: "white",
   },
   wholeRowTable: {
      textAlign: "center",
      padding: "16px",
      fontSize: theme.typography.small1.fontSize,
      lineHeight: "16px",
   },
}));

// We use __filetype throughout this file so have to disable this for the component for now
/* eslint-disable no-underscore-dangle */
/**
 * Files Table
 *
 * Takes an array of file and folder and returns a table of them.
 *
 * TODO: refactor to remove the insane number of props on this component!
 * @param {object} props props
 * @param {Array} props.folders Array of folders.
 * @param {Array} props.files Array of files.
 * @param {boolean} props.foldersLoading Folders query in upper scope is loading.
 * @param {boolean} props.filesLoading Files query in upper scope is loading.
 * @param {boolean} props.hasMoreFilesFolders Should InfiniteScroll request more pages when the user scrolls to the bottom of the page.
 * @param {Function} props.fetchMoreFilesFolders Function to fetch more files and folders pages when the user scrolls to the bottom of the page.
 * @param {Function} props.setSelectedFolders Set selectedFolders in the upper components state.
 * @param {Function} props.setSelectedFiles Set selectedFiles in the upper components state.
 * @param {Array} props.selectedFolders Array of folders selected via checkboxes.
 * @param {Array} props.selectedFiles Array of files selected via checkboxes.
 * @param {Function} props.handleChangeDirectory Function to handle the change of directory in Modal file listings.
 * @param {Function} props.setShowMoveModal Show the move modal.
 * @param {Function} props.setShowCopyModal Show the copy modal.
 * @param {boolean} props.isModal Are the listings rendered within a modal.
 * @param {Function} props.starFilesFolders Function to star files or folders
 * @param {Function} props.trashFilesFolders Function to archive files or folders
 * @param {Function} props.moveFilesFolders Function to move files or folders
 * @param {Function} props.isInTrash Hide or show archive folder.
 * @param {Function} props.setShowDeleteModal Show the delete modal.
 * @param {Function} props.setShowAddToProjectsModal Show the add to project modal.
 * @param {object} props.projectFolder Project folder object.
 * @param {string} props.search string to filter files/folders.
 * @param {string} props.folderUri folder uri.
 * @param {object} props.createFolderData Data for the new folder being created.
 * @param {Function} props.setCreateFolderData Set the new folder data.
 * @returns {JSX.Element} Files table with folders and files listings.
 */
const FilesTable = ({
   folders,
   files,
   foldersLoading,
   filesLoading,
   hasMoreFilesFolders,
   fetchMoreFilesFolders,
   setSelectedFolders,
   setSelectedFiles,
   selectedFolders,
   selectedFiles,
   handleChangeDirectory,
   setShowMoveModal,
   setShowCopyModal,
   isModal,
   trashFilesFolders,
   moveFilesFolders,
   isInTrash,
   setShowDeleteModal,
   projectFolder,
   search,
   folderUri,
   createFolderData,
   setCreateFolderData,
}) => {
   const [user] = useUser();

   const isElectron = isElectronHook();
   const theme = useTheme();
   const classes = useStyles();
   const tableRef = useRef(0);

   const [orderDirection, setOrderDirection] = useState("desc");
   const [valueToOrderBy, setValueToOrderBy] = useState("originalName");
   const [selectedPopOver, setSelectedPopOver] = useState(null);
   const [selectedRenaming, setSelectedRenaming] = useState(null);
   const [renamingFolderValue, setRenamingFolderValue] = useState(null);
   const [hover, setHover] = useState(true);

   // Check file count to avoid flashing the empty state when navigating back to the root folder from an empty sub folder.
   const { data: profileStatData, loading: userStatsLoading } =
      useQuery(GET_PROFILE_STATS);

   const showEmptyState = useMemo(() => {
      return (
         !userStatsLoading &&
         // profileStatData?.getProfileStats?.fileCount === 0 &&
         !filesLoading &&
         !foldersLoading &&
         files?.length === 0 &&
         folders?.length === 0 &&
         !folderUri &&
         !createFolderData &&
         // folderUri !== undefined &&
         !isModal
      );
   }, [
      profileStatData,
      userStatsLoading,
      folderUri,
      filesLoading,
      foldersLoading,
      files,
      folders,
      createFolderData,
   ]);

   // #region
   const handleRequestSort = (event, property) => {
      const isAscending =
         valueToOrderBy === property && orderDirection === "asc";
      setValueToOrderBy(property);
      setOrderDirection(isAscending ? "desc" : "asc");
   };

   const createSortHandler = (property) => (event) => {
      handleRequestSort(event, property);
   };

   const [renameFileOrFolder] = useMutation(RENAME_FILE_OR_FOLDER, {
      refetchQueries: [{ query: GET_PROJECTS }],
      /**
       * renameFileOrFolder onCompleted
       * Trigger sync if it is on electron app.
       */
      onCompleted: () => {
         //
      },
   });

   /**
    * Handle Finish Renaming
    *
    * Submit a rename mutation for a file or folder.
    * @param {string} name New file name
    * @param {object} renameObj File or Folder object to by renamed.
    * @returns {void}
    */
   const handleFinishRenaming = (name, renameObj) => {
      const isFile = renameObj?.__typename === "File"; // Type from Apollo cache
      setRenamingFolderValue(null);
      setSelectedRenaming(null);
      if (name === "" || !name || name === getNameWithoutExtension(renameObj)) {
         return;
      }

      renameFileOrFolder({
         variables: {
            name,
            fileId: isFile ? renameObj.id : null,
            folderId: !isFile ? renameObj.id : null,
         },
      });
   };

   const renderType = (f) => {
      const isFile = f.__typename === "File";
      if (isFile) {
         return getGenericName(f);
      }
      if (f?.project) {
         return "Project";
      }
      return "Folder";
   };

   const descendingComparator = (a, b, orderBy) => {
      // originalName prop name is different for folders, files.
      if (orderBy === "originalName") {
         const getNameValue = (obj) => {
            const isFile = obj.__typename === "File";
            if (isFile) {
               return obj?.originalName;
            }
            return obj?.name;
         };

         const aValue = getNameValue(a);
         const bValue = getNameValue(b);
         return String(aValue).localeCompare(String(bValue));
      }

      // mimeType prop name is different for folders, files & projects
      if (orderBy === "mimeType") {
         const aValue = renderType(a);
         const bValue = renderType(b);
         return String(aValue).localeCompare(String(bValue));
      }

      if (b[orderBy] < a[orderBy]) {
         return -1;
      }
      if (b[orderBy] > a[orderBy]) {
         return 1;
      }
      return 0;
   };

   const getComparator = (order, orderBy) => {
      return order === "desc"
         ? (a, b) => descendingComparator(a, b, orderBy)
         : (a, b) => -descendingComparator(a, b, orderBy);
   };

   /**
    * Sorted Row Information
    *
    * TODO: we need to move sorting to the backed in future so that if you
    * filter
    * @param {Array} filesFolders Array of files or folders objects
    * @param {Function} comparator Comparator function.
    * @param {boolean} loading Should render loading state.
    * @returns {Array} Mapped and sorted array of files or folders.
    */
   const sortedRowInformation = (filesFolders = [], comparator, loading) => {
      // Return mock data to show three skeleton items during load.
      if (loading && filesFolders.length === 0) {
         return [
            {
               __typename: "File",
               originalName: "Loading",
               name: "Loading",
               uri: "skeleton-1",
            },
            {
               __typename: "File",
               originalName: "Loading",
               name: "Loading",
               uri: "skeleton-2",
            },
            {
               __typename: "File",
               originalName: "Loading",
               name: "Loading",
               uri: "skeleton-3",
            },
         ];
      }
      return filesFolders
         .map((el, index) => [el, index])
         .sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
         })
         .map((el) => el[0]);
   };

   // #region Drag and Drop functions

   const dragGhost = useRef(null);
   const dragElement = useRef(null);
   /**
    * Drag start
    *
    * Drag items start on the files listing.
    * @param {object} e event from drag.
    */
   const dragStart = (e) => {
      dragElement.current = e.target;

      if (selectedFiles.length > 0 || selectedFolders.length > 0) {
         const ghostEl = document.createElement("div");
         ghostEl.textContent = "Moving Files/Folders";
         ghostEl.classList.add(classes.multiGhostElement);
         ghostEl.style.position = "absolute";
         ghostEl.style.top = "-1000px";
         // ghostEl.style.visibility = "hidden";
         document.querySelector(".App").appendChild(ghostEl);
         dragGhost.current = ghostEl;
         e.dataTransfer.setDragImage(ghostEl, 10, 10);
      }

      setTimeout(() => {
         // style make invisible
         const selectedElements = [];
         selectedFiles.forEach((f) => {
            const foundEl = document.querySelector(`[data-fileid="${f.id}"]`);
            selectedElements.push(foundEl);
         });
         selectedFolders.forEach((f) => {
            const foundEl = document.querySelector(`[data-folderid="${f.id}"]`);
            selectedElements.push(foundEl);
         });
         selectedElements.forEach((el) =>
            el.classList.add(classes.dragInvisible)
         );
         document.querySelectorAll(`.${classes.dropzone}`).forEach((el) => {
            const styles = el.firstChild.style;

            styles.pointerEvents = "auto";
         });
         e.target.classList.add(classes.dragInvisible);
      }, 0);

      // Needed for checkSelectedDataProcessing in FileListing.jsx.
      const fileObj = files?.filter((f) =>
         selectedFiles.some((a) => a.id === f.id)
      );
      setSelectedFiles(fileObj);
   };

   /**
    * Drag end
    *
    * Drag items end on the files listing.
    * @param {object} e event from drag.
    */
   const dragEnd = (e) => {
      dragGhost?.current?.remove();
      const selectedElements = [];

      selectedFiles.forEach((f) => {
         const foundEl = document.querySelector(`[data-fileid="${f.id}"]`);
         selectedElements.push(foundEl);
      });
      selectedFolders.forEach((f) => {
         const foundEl = document.querySelector(`[data-folderid="${f.id}"]`);
         selectedElements.push(foundEl);
      });
      selectedElements.forEach((el) =>
         el.classList.remove(classes.dragInvisible)
      );
      e.target.classList.remove(classes.dragInvisible);
      const dropzone = document.querySelector(`.${classes.dragHover}`);
      if (dropzone) {
         // Remove pointer events children of dropzone to make the tableCell clickable
         const nestedElements = dropzone.querySelectorAll("div");
         nestedElements.forEach((child) => {
            const mutatedEl = child;
            mutatedEl.style.pointerEvents = "auto";
         });
      }

      const dropFolderId = dropzone?.dataset?.folderid;

      e.target.classList.remove(classes.dragInvisible);

      const { fileid, folderid } = e.target.dataset;

      // Needed for checkSelectedDataProcessing in FileListing.jsx.
      if (fileid) {
         setSelectedFiles((prev) => prev.filter((f) => f.id !== fileid));
      }

      if (dropFolderId && dropFolderId !== folderid) {
         dropzone.classList.remove(classes.dragHover);
         const dropFolder = folders?.find((f) => f.id === dropFolderId);
         const fileIds = selectedFiles.map((f) => f.id);
         if (fileid) {
            fileIds.push(fileid);
         }
         const folderIds = selectedFolders.map((f) => f.id);
         if (folderid) {
            folderIds.push(folderid);
         }
         moveFilesFolders({
            variables: {
               fileIds: [...new Set(fileIds)],
               folderIds: [...new Set(folderIds)],
               targetFolderId: dropFolderId,
               projectId: dropFolder?.projectId,
            },
         });
      }
      setSelectedFiles([]);
      setSelectedFolders([]);
   };
   const dragOver = (e) => {
      e.preventDefault();
   };
   const dragEnter = (e) => {
      e.preventDefault();
      // event fires on children
      const tr = e.target.parentNode;
      if (!tr?.dataset?.folderid) return;

      const nestedElements = tr.querySelectorAll("div");
      nestedElements.forEach((child) => {
         const mutatedEl = child;
         mutatedEl.style.pointerEvents = "none";
      });
      tr.classList.add(classes.dragHover);
      // hover styles
   };
   const dragLeave = (e) => {
      // remove hover styles
      // event fires on children
      const tr = e.target.parentNode;
      if (!tr?.dataset?.folderid) return;
      const nestedElements = tr.querySelectorAll("div");
      nestedElements.forEach((child) => {
         const mutatedEl = child;
         mutatedEl.style.pointerEvents = "auto";
      });
      tr.classList.remove(classes.dragHover);
   };

   useEffect(() => {
      if (!isModal) {
         const draggableEls = document.querySelectorAll("tr[draggable]");

         draggableEls?.forEach((f) => {
            f.addEventListener("dragstart", dragStart);
            f.addEventListener("dragend", dragEnd);
         });

         const dropEls = document.querySelectorAll("tr[data-folderid]");
         dropEls?.forEach((dropzone) => {
            dropzone?.addEventListener("dragover", dragOver);
            dropzone?.addEventListener("dragenter", dragEnter);
            dropzone?.addEventListener("dragleave", dragLeave);
         });

         return () => {
            draggableEls?.forEach((f) => {
               f.removeEventListener("dragstart", dragStart);
               f.removeEventListener("dragend", dragEnd);
            });
            dropEls?.forEach((dropzone) => {
               dropzone?.removeEventListener("dragover", dragOver);
               dropzone?.removeEventListener("dragenter", dragEnter);
               dropzone?.removeEventListener("dragleave", dragLeave);
            });
         };
      }

      return true;
   }, [selectedFiles, selectedFolders, files, folders]);

   // #endregion

   const [createFolder] = useMutation(CREATE_FOLDER, {
      refetchQueries: [
         {
            query: GET_FOLDERS,
            variables: {
               uri: folderUri,
               trashed: false,
            },
         },
      ],
   });

   /**
    * Render File Folder Action Menu
    *
    * TODO: this should be two components.
    *
    * The menu of actions on a file or folder row such as copy/move/sync.
    * @param {object} f File or Folder.
    * @returns {JSX.Element} Popover action menu.
    */
   const renderFileFolderActionMenu = (f) => {
      if (f?.new) {
         return null;
      }

      const isFile = f?.__typename === "File";
      // let fileType = f?.__typename.toLowerCase();

      // Set up skeleton states in each row by type.
      let loading = false;
      if (isFile && filesLoading) {
         loading = true;
      }
      if (!isFile && foldersLoading) {
         loading = true;
      }

      // if (projectFolder) {
      //    fileType = fileType === "folder" ? "project" : "file";
      // }

      if (isInTrash) {
         return (
            <TableCell key={`${f?.uri}-popover`}>
               {loading && <Skeleton sx={{ width: "24px", margin: "12px" }} />}
               {!loading && !isModal && (
                  <>
                     <IconButton
                        id={`button-${f.uri}`}
                        onClick={() => setSelectedPopOver(f)}
                        size="large"
                     >
                        <MoreHoriz />
                     </IconButton>
                     <Popover
                        className={classes.menuPopover}
                        open={
                           f?.id === selectedPopOver?.id &&
                           f?.__typename === selectedPopOver?.__typename
                        }
                        onClose={() => setSelectedPopOver(null)}
                        anchorEl={document.getElementById(`button-${f.uri}`)}
                        anchorOrigin={{
                           vertical: "bottom",
                           horizontal: "right",
                        }}
                        transformOrigin={{
                           vertical: "top",
                           horizontal: "right",
                        }}
                     >
                        <Grid
                           container
                           direction="column"
                           justifyContent="flex-start"
                           className={classes.popOver}
                        >
                           <Button
                              color="primary"
                              onClick={() => {
                                 if (isFile) {
                                    trashFilesFolders({
                                       variables: {
                                          fileIds: [f.id],
                                       },
                                    });
                                 } else {
                                    trashFilesFolders({
                                       variables: {
                                          folderIds: [f.id],
                                       },
                                    });
                                 }
                                 setSelectedPopOver(null);
                              }}
                           >
                              <Grid
                                 container
                                 justifyContent="start"
                                 gap={1}
                                 flexDirection="row"
                                 alignItems="center"
                              >
                                 Restore
                              </Grid>
                           </Button>
                           <Button
                              style={{ color: theme.palette.danger.main }}
                              onClick={() => {
                                 if (isFile) {
                                    setSelectedFiles([f]);
                                 } else {
                                    setSelectedFolders([f]);
                                 }
                                 setShowDeleteModal(true);
                                 setSelectedPopOver(null);
                              }}
                           >
                              <Grid
                                 container
                                 justifyContent="start"
                                 gap={1}
                                 flexDirection="row"
                                 alignItems="center"
                              >
                                 Permanently delete
                              </Grid>
                           </Button>
                        </Grid>
                     </Popover>
                  </>
               )}
            </TableCell>
         );
      }
      return (
         <TableCell key={`${f?.uri}-popover`}>
            {loading && <Skeleton sx={{ width: "24px", margin: "12px" }} />}
            {!loading && !isModal && (
               <>
                  <IconButton
                     id={`button-${f.uri}`}
                     onClick={() => setSelectedPopOver(f)}
                     size="large"
                  >
                     <MoreHoriz />
                  </IconButton>
                  <Popover
                     className={classes.menuPopover}
                     open={
                        f?.id === selectedPopOver?.id &&
                        f?.__typename === selectedPopOver?.__typename
                     }
                     onClose={() => setSelectedPopOver(null)}
                     anchorEl={document.getElementById(`button-${f.uri}`)}
                     anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                     }}
                     transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                     }}
                  >
                     {/* TODO: refactor to the new style dropdown. */}
                     <Grid
                        container
                        direction="column"
                        className={classes.popOver}
                     >
                        <Button
                           color="primary"
                           id="move-to-folder"
                           onClick={() => {
                              if (isFile) {
                                 setSelectedPopOver(null);
                                 setSelectedFiles([f]);
                                 setShowMoveModal(true);
                              } else {
                                 setSelectedPopOver(null);
                                 setSelectedFolders([f]);
                                 setShowMoveModal(true);
                              }
                           }}
                        >
                           <Grid
                              container
                              justifyContent="start"
                              gap={1}
                              flexDirection="row"
                              alignItems="center"
                           >
                              Move to folder
                           </Grid>
                        </Button>
                        <Button
                           aria-label="Copy"
                           color="primary"
                           id="copy-to-folder"
                           onClick={() => {
                              if (isFile) {
                                 setSelectedPopOver(null);
                                 setSelectedFiles([f]);
                                 setShowCopyModal(true);
                              } else {
                                 setSelectedPopOver(null);
                                 setSelectedFolders([f]);
                                 setShowCopyModal(true);
                              }
                           }}
                        >
                           <Grid
                              container
                              justifyContent="start"
                              gap={1}
                              flexDirection="row"
                              alignItems="center"
                           >
                              Copy to folder
                           </Grid>
                        </Button>
                        <Button
                           onClick={(e) => {
                              e.preventDefault();
                              setSelectedPopOver(null);
                              setRenamingFolderValue(() =>
                                 isFile ? getNameWithoutExtension(f) : f?.name
                              );
                              setSelectedRenaming(f);
                           }}
                           color="primary"
                        >
                           <Grid
                              container
                              justifyContent="start"
                              gap={1}
                              flexDirection="row"
                              alignItems="center"
                           >
                              Rename
                           </Grid>
                        </Button>
                        <Button
                           data-testid="trash-button-popover"
                           style={{
                              color: theme.palette.danger.main,
                           }}
                           disabled={
                              f.projectId && f?.profileId !== user?.profile?.id
                           }
                           onClick={() => {
                              if (isFile) {
                                 trashFilesFolders({
                                    variables: {
                                       fileIds: [f.id],
                                    },
                                 });
                              } else {
                                 trashFilesFolders({
                                    variables: {
                                       folderIds: [f.id],
                                    },
                                 });
                              }
                              setSelectedPopOver(null);
                           }}
                        >
                           <Grid
                              container
                              justifyContent="start"
                              gap={1}
                              flexDirection="row"
                              alignItems="center"
                           >
                              Trash
                           </Grid>
                        </Button>
                     </Grid>
                  </Popover>
               </>
            )}
         </TableCell>
      );
   };

   // #endregion

   // Display this state when there are no files or folders at the root level of the files section.
   if (showEmptyState) {
      return (
         <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginTop="100px"
         >
            {isInTrash ? (
               <div
                  style={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     flexDirection: "column",
                  }}
               >
                  <Delete
                     style={{
                        width: "121px",
                        height: "121px",
                        fill: theme.palette.secondary.shade30,
                     }}
                  />
                  <div
                     style={{
                        marginTop: "5px",
                        marginBottom: "18px",
                        fontSize: "18px",
                        lineHeight: "24px",
                     }}
                  >
                     No items in the trash
                  </div>
                  <div
                     style={{
                        textAlign: "center",
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: theme.palette.secondary.shade60,
                     }}
                  >
                     Items moved to the trash will appear here <br /> and
                     permanently deleted after 30 days
                  </div>
               </div>
            ) : (
               <EmptyFilesView />
            )}
         </Box>
      );
   }

   return (
      <TableContainer
         className={classes.table}
         elevation={0}
         id="file-table"
         ref={tableRef}
      >
         <InfiniteScroll
            pageStart={0}
            loadMore={fetchMoreFilesFolders}
            initialLoad={false}
            hasMore={hasMoreFilesFolders}
            useWindow={false}
         >
            <Table stickyHeader aria-label="sticky table">
               <TableHead>
                  <TableRow>
                     {!isModal && (
                        <TableCell
                           sx={{ padding: "0px !important" }}
                           onMouseEnter={() => setHover(true)}
                           onMouseLeave={() => setHover(false)}
                        >
                           <Checkbox
                              style={
                                 selectedFiles?.length > 0 ||
                                 selectedFolders?.length > 0 ||
                                 hover
                                    ? {}
                                    : { visibility: "hidden" }
                              }
                              disabled={filesLoading || foldersLoading}
                              indeterminate={
                                 selectedFolders?.length > 0 ||
                                 selectedFiles?.length > 0
                              }
                              onClick={(e) => {
                                 if (isModal) {
                                    e.preventDefault();
                                    return;
                                 }
                                 if (e.target.checked) {
                                    if (folders) {
                                       setSelectedFolders(folders);
                                    }
                                    if (files) {
                                       setSelectedFiles(files);
                                    }
                                 } else {
                                    setSelectedFolders([]);
                                    setSelectedFiles([]);
                                 }
                              }}
                           />
                        </TableCell>
                     )}
                     <TableCell key="originalName" sx={{ padding: "10px 0px" }}>
                        <TableSortLabel
                           active={valueToOrderBy === "originalName"}
                           direction={
                              valueToOrderBy === "originalName"
                                 ? orderDirection
                                 : "asc"
                           }
                           onClick={createSortHandler("originalName")}
                        >
                           Name
                        </TableSortLabel>
                     </TableCell>
                     <TableCell key="mimeType" sx={{ paddingRight: "30px" }}>
                        <TableSortLabel
                           active={valueToOrderBy === "mimeType"}
                           direction={
                              valueToOrderBy === "mimeType"
                                 ? orderDirection
                                 : "asc"
                           }
                           onClick={createSortHandler("mimeType")}
                        >
                           Type
                        </TableSortLabel>
                     </TableCell>
                     <TableCell key="size" sx={{ p: "0 30px" }}>
                        <TableSortLabel
                           active={valueToOrderBy === "size"}
                           direction={
                              valueToOrderBy === "size" ? orderDirection : "asc"
                           }
                           onClick={createSortHandler("size")}
                        >
                           Size
                        </TableSortLabel>
                     </TableCell>
                     <TableCell key="updatedAt" sx={{ p: "0 30px" }}>
                        <TableSortLabel
                           active={valueToOrderBy === "updatedAt"}
                           direction={
                              valueToOrderBy === "updatedAt"
                                 ? orderDirection
                                 : "asc"
                           }
                           onClick={createSortHandler("updatedAt")}
                        >
                           Modified
                        </TableSortLabel>
                     </TableCell>
                     <TableCell key="status" sx={{ paddingLeft: "5px" }} />
                  </TableRow>
               </TableHead>
               {!filesLoading &&
               !foldersLoading &&
               !createFolderData &&
               files?.length === 0 &&
               folders?.length === 0 &&
               (folderUri || isModal) ? (
                  <TableBody>
                     <tr>
                        <td className={classes.wholeRowTable} colSpan="6">
                           {/* TODO: if loading don't show this first in modal */}
                           Folder Empty
                        </td>
                     </tr>
                  </TableBody>
               ) : (
                  <TableBody>
                     {createFolderData && (
                        <TableRow className={classes.tableRow}>
                           <TableCell />
                           <TableCell className={classes.tableCell}>
                              <Input
                                 id="create-folder-input"
                                 value={createFolderData?.name}
                                 sx={{ paddingRight: "24px" }}
                                 inputProps={{
                                    maxLength: "191",
                                 }}
                                 onKeyDown={(e) => {
                                    if (
                                       !e?.target?.checkValidity() ||
                                       !createFolderData?.name ||
                                       createFolderData?.name === ""
                                    ) {
                                       return;
                                    }
                                    if (e.key === "Enter") {
                                       // There is very little benefit to creating the folder on local and syncing up so for both electron and web we use create folder on remote then trigger sync in electron.
                                       createFolder({
                                          variables: {
                                             name: createFolderData?.name,
                                             parentFolderUri: folderUri,
                                          },
                                       });
                                       setCreateFolderData(null);
                                       //
                                    }
                                    if (e.key === "Escape") {
                                       setCreateFolderData(null);
                                    }
                                 }}
                                 onChange={(e) => {
                                    setCreateFolderData((a) => ({
                                       ...a,
                                       name: e?.target?.value,
                                    }));
                                 }}
                              />
                              <IconButton
                                 sx={{ p: 0, marginLeft: "-24px" }}
                                 onClick={() => setCreateFolderData(null)}
                              >
                                 <CloseIcon />
                              </IconButton>
                           </TableCell>
                           <TableCellType file={createFolderData} />
                           <TableCellSize file={createFolderData} />
                           <TableCellLastModified file={createFolderData} />
                           <TableCell />
                        </TableRow>
                     )}
                     {sortedRowInformation(
                        folders,
                        getComparator(orderDirection, valueToOrderBy),
                        foldersLoading
                     )
                        .filter(
                           (f) =>
                              f?.name?.includes(search) ||
                              f?.originalName?.includes(search) // Filter out folder based on search.
                        )
                        .map((f) => {
                           // Prevent a folder from being moved inside of itself.
                           if (
                              isModal &&
                              selectedFolders.find((sf) => sf.id === f.id)
                           ) {
                              return null;
                           }
                           return (
                              <TableRowFolder
                                 key={f.id}
                                 f={f}
                                 foldersLoading={foldersLoading}
                                 isModal={isModal}
                                 selectedFolders={selectedFolders}
                                 setSelectedFolders={setSelectedFolders}
                                 projectFolder={projectFolder}
                                 handleChangeDirectory={handleChangeDirectory}
                                 renderFileFolderActionMenu={
                                    renderFileFolderActionMenu
                                 }
                                 handleFinishRenaming={handleFinishRenaming}
                                 renamingFolderValue={renamingFolderValue}
                                 selectedRenaming={selectedRenaming}
                                 setSelectedRenaming={setSelectedRenaming}
                                 setRenamingFolderValue={setRenamingFolderValue}
                              />
                           );
                        })}
                     {/* Currently Modals only show Folders (for Move and Copy) */}
                     {sortedRowInformation(
                        files,
                        getComparator(orderDirection, valueToOrderBy),
                        filesLoading
                     )
                        .filter(
                           (f) =>
                              (f?.name?.includes(search) ||
                                 f?.originalName?.includes(search)) && // Filter for search.
                              f?.originalName.charAt(0) !== "." // Filter out 0 byte files
                        )
                        .map((f) => {
                           // Prevent a folder from being moved inside of itself.
                           if (isModal) return null;

                           return (
                              <TableRowFile
                                 key={`table-files-${f?.uri}`}
                                 f={f}
                                 filesLoading={filesLoading}
                                 isModal={isModal}
                                 selectedFiles={selectedFiles}
                                 setSelectedFiles={setSelectedFiles}
                                 projectFolder={projectFolder}
                                 handleChangeDirectory={handleChangeDirectory}
                                 renderFileFolderActionMenu={
                                    renderFileFolderActionMenu
                                 }
                                 handleFinishRenaming={handleFinishRenaming}
                                 renamingFolderValue={renamingFolderValue}
                                 selectedRenaming={selectedRenaming}
                                 setSelectedRenaming={setSelectedRenaming}
                                 setRenamingFolderValue={setRenamingFolderValue}
                              />
                           );
                        })}
                     {/* Spacer to prevent for the sync status overlay from blocking the listings on desktop. */}
                     {isElectron && (
                        <div style={{ height: "85px", width: "1px" }} />
                     )}
                  </TableBody>
               )}
            </Table>
         </InfiniteScroll>
      </TableContainer>
   );
};
/* eslint-enable no-underscore-dangle */

FilesTable.propTypes = {
   folders: PropTypes.shape({
      id: PropTypes.string,
      uri: PropTypes.string,
      find: PropTypes.func,
      filter: PropTypes.string,
      length: PropTypes.number,
   }).isRequired,
   files: PropTypes.shape({
      id: PropTypes.string,
      uri: PropTypes.string,
      filter: PropTypes.string,
      length: PropTypes.number,
   }).isRequired,
   foldersLoading: PropTypes.bool.isRequired,
   filesLoading: PropTypes.bool.isRequired,
   setSelectedFolders: PropTypes.func.isRequired,
   setSelectedFiles: PropTypes.func.isRequired,
   selectedFolders: PropTypes.instanceOf(Array).isRequired,
   selectedFiles: PropTypes.instanceOf(Array).isRequired,
   setShowMoveModal: PropTypes.func.isRequired,
   setShowCopyModal: PropTypes.func.isRequired,
   isModal: PropTypes.bool.isRequired,
   handleChangeDirectory: PropTypes.func,
   trashFilesFolders: PropTypes.func.isRequired,
   moveFilesFolders: PropTypes.func.isRequired,
   filter: PropTypes.string.isRequired,
   isInTrash: PropTypes.bool.isRequired,
   setShowDeleteModal: PropTypes.func.isRequired,
   projectFolder: PropTypes.shape({
      id: PropTypes.string,
      uri: PropTypes.string,
   }),
   hasMoreFilesFolders: PropTypes.bool,
   fetchMoreFilesFolders: PropTypes.func.isRequired,
   search: PropTypes.string,
   folderUri: PropTypes.string,
};

FilesTable.defaultProps = {
   handleChangeDirectory: null,
   projectFolder: null,
   folderUri: undefined,
   hasMoreFilesFolders: false,
   search: "",
};

export default FilesTable;
