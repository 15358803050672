import PropTypes from "prop-types";
import React, { useState, useEffect, useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { useQuery } from "@apollo/react-hooks";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import CustomModal from "_components/Modal/CustomModal";
import { Typography, useTheme } from "@mui/material";
import FilterInput from "_components/FilterInput";
import { GET_PROJECT, GET_FILES_FOLDERS } from "../../_apollo/queries";
import TableCellType from "../FilesTable/TableCells/TableCellType";
import TableCellSize from "../FilesTable/TableCells/TableCellSize";
import TableCellLastModified from "../FilesTable/TableCells/TableCellLastModified";
import TableCellName from "../FilesTable/TableCells/TableCellName";

const useStyles = makeStyles((theme: any) => ({
  innerRoot: {
    margin: "6px 24px 0 24px",
    borderBottom: `thin solid rgba(0, 0, 0, 0.12)`,
    minHeight: "calc(100vh - 395px)",
  },
  containerSpacing: {
    marginTop: "4px",
    "& table thead tr th": {
      fontWeight: "bold",
    },
    overflow: "hidden",
  },
  flexMiddle: {
    display: "flex",
    justifyContent: "center",
    margin: "32px",
  },
  posRel: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
  },
  btnLabel: {
    position: "absolute",
    top: 8,
    bottom: 0,
    right: -130,
  },
  flexRound: {
    display: "flex",
    alignItems: "center",
  },
  wholeRowTable: {
    textAlign: "center",
    padding: "16px",
    fontSize: theme.typography.small1.fontSize,
    lineHeight: "16px",
  },
  checkboxCell: {
    paddingRight: "0px",
    width: 20,
    "& span": {
      padding: "3px",
    },
  },
}));
type SelectMode =
  | "singleFile"
  | "multipleFiles"
  | "singleFolder"
  | "multipleFilesFolders";

type FileFolderSelectorModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  loading: boolean;
  onConfirm: (
    selectedFiles: any[],
    selectedFilesId: string[],
    selectedFolders: any[],
    selectedFoldersId: string[]
  ) => void;
  filterFiles?: (e: any) => boolean;
  selectMode: SelectMode;
};

/**
 * Project Add Files Modal
 *
 * @param {object} props props
 * @param {boolean} props.open Open modal.
 * @param {Function} props.setOpen Function to set open state in parent component.
 * @param {string} props.title title of the modal.
 * @param {boolean} props.loading loading state after confirm.
 * @param {Function} props.onConfirm confirm button callback.
 * @param {SelectMode} props.selectMode select mode of the modal.
 * @param {Function} props.filterFiles function to filter files.
 * @returns {JSX.Element} Modal allowing files to be added to a project.
 */
const FileFolderSelectorModal = ({
  open,
  setOpen,
  title,
  loading,
  onConfirm,
  selectMode,
  filterFiles,
}: FileFolderSelectorModalProps) => {
  const classes = useStyles();
  const { projectUri } = useParams();
  const { folderUri: folderParam } = useParams();
  const isSingleFile = selectMode === "singleFile";

  const [foldersPath, setFolderPath] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);

  const theme = useTheme<any>();

  /**
   * getIds
   *
   * Helper that flattens ids from array of file or folder objects.
   *
   * @param {Array} fList array of file or folder objects with ids.
   * @returns {Array} array of number (ids)
   */
  const getIds = (fList) => fList.map((f) => f.id);
  const selectedFilesId = useMemo(() => getIds(selectedFiles), [selectedFiles]);
  const selectedFoldersId = useMemo(
    () => getIds(selectedFolders),
    [selectedFolders]
  );
  const [filter, setFilter] = useState("");
  const [rootFolder, setRootFolder] = useState({});

  const [userFiles, setUserFiles] = useState({
    folders: [],
    files: [],
  });

  const { data: project } = useQuery(GET_PROJECT, {
    variables: {
      uri: projectUri,
    },
  });

  const totalFiles = useMemo(() => {
    // Get selected folders from Id's
    const arrayFolders = (userFiles?.folders || [])
      .filter((el) => el.projectId !== project?.getProject?.id)
      .filter((el) => selectedFoldersId.includes(el?.id));

    // Get selected files from Id's
    const arrayFiles = (userFiles?.files || [])
      .filter((el) => el.projectId !== project?.getProject?.id)
      .filter((el) => selectedFilesId.includes(el?.id));

    if (Array.isArray(arrayFolders)) {
      // Add value of all the folders
      const totalFoldersFiles = arrayFolders.reduce((a, b) => {
        return +a + (+b?.numberFiles || 0);
      }, 0);

      return arrayFiles.length + totalFoldersFiles;
    }
    return 0;
  }, [selectedFoldersId, selectedFilesId]);

  const [modalFolderUri, setModalFolderUri] = useState();
  const { data } = useQuery(GET_FILES_FOLDERS, {
    variables: {
      currentFolderUri: modalFolderUri,
      currentFolderId: undefined,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setUserFiles(data?.getFilesFolders);
  }, [data?.getFilesFolders]);

  /**
   * handleChangeDirectory
   *
   * Handles user clicking on a folder by refetching the getFilesFolders query with the new URI.
   *
   * @param {object} folder Folder object to load.
   */
  const handleChangeDirectory = (folder) => {
    setFolderPath((prevArr) => [...prevArr, folder]);
    setModalFolderUri(folder.uri);
  };

  /**
   * handleGoBackDirectory
   *
   * When the user clicks on a previous file path, load that parent folder.
   *
   * @param {object} folder Folder object to load.
   */
  const handleGoBackDirectory = (folder) => {
    const newFoldersPath = foldersPath.slice(
      0,
      foldersPath.findIndex((pathFolder) => pathFolder.uri === folder.uri) + 1
    );
    setFolderPath(newFoldersPath);
    setModalFolderUri(folder.uri);
  };

  // Set the root folder so we can get back to it in the path and update checkboxes correctly.
  useEffect(() => {
    if (data?.getFilesFolders?.uri === folderParam) {
      setRootFolder(data);
    }
  }, [folderParam, data]);

  /**
   * renderPath
   *
   * Renders the users nested path breadcrumbs.
   *
   * @returns {JSX.Element} The path component for the add modal.
   */
  const renderPath = () => {
    let buttonArr;
    if (foldersPath) {
      buttonArr = foldersPath.map((folder, i) => {
        const isLast = i === foldersPath.length - 1;
        return (
          <span key={`${folder?.uri}-path-component`}>
            /
            <Button
              sx={{
                color: isLast ? "black" : "primary.main",
              }}
              onClick={() => handleGoBackDirectory(folder)}
            >
              {folder.name}
            </Button>
          </span>
        );
      });
    }
    return (
      <Grid>
        <Button
          sx={{
            color: foldersPath.length === 0 ? "black" : "primary.main",
          }}
          onClick={() => handleGoBackDirectory(rootFolder)}
          data-testid="root-folder-button-modal"
        >
          Files
        </Button>
        {buttonArr}
      </Grid>
    );
  };

  useEffect(() => {
    if (data?.getFilesFolders) {
      setUserFiles(data.getFilesFolders);
    }
  }, [data]);

  return (
    <CustomModal
      fullWidth="800"
      open={open}
      handleClose={() => {
        setOpen(false);
      }}
      header={
        <Typography color="black" variant={"modalHeader" as any} component="h3">
          {title}
        </Typography>
      }
    >
      <div>
        <Grid>
          <div className={classes.innerRoot}>
            <div className={classes.flexRound}>
              <FilterInput filter={filter} setFilter={setFilter} />
              {renderPath()}
            </div>
            <div
              style={{
                height: "1px",
                backgroundColor: "#E0E0E0",
                width: "calc(100% - 12px)",
                margin: "12px 0px 0px",
              }}
            />
            <div className={classes.containerSpacing}>
              <TableContainer
                data-testid="table-modal-files-folders"
                style={{ maxHeight: "calc(100vh - 465px)" }}
                sx={{
                  marginTop: "-2px", // Needed to hide the table rows under the header
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {!isSingleFile && (
                        <TableCell className={classes.checkboxCell}>
                          <Checkbox
                            color="primary"
                            disabled={isSingleFile}
                            indeterminate={selectedFilesId.length > 0}
                            onClick={(e) => {
                              // @ts-ignore
                              if (e.target.checked) {
                                if (selectMode === "multipleFilesFolders") {
                                  setSelectedFolders(userFiles?.folders);
                                }

                                setSelectedFiles(
                                  userFiles?.files.filter((f) =>
                                    !f.trashed &&
                                    f?.originalName.charAt(0) !== "." &&
                                    filterFiles
                                      ? filterFiles(f)
                                      : true
                                  )
                                );
                              } else {
                                if (selectMode === "multipleFilesFolders") {
                                  setSelectedFolders([]);
                                }
                                setSelectedFiles([]);
                              }
                            }}
                          />
                        </TableCell>
                      )}
                      <TableCell style={{ padding: "8px 10px" }}>
                        Name
                      </TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Size</TableCell>
                      <TableCell>Modified</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {!userFiles?.files?.length &&
                      !userFiles?.folders?.length && (
                        <tr>
                          <td
                            className={classes.wholeRowTable}
                            colSpan={"5" as any}
                          >
                            Folder Empty
                          </td>
                        </tr>
                      )}
                    {userFiles?.folders
                      ?.filter((el) =>
                        filter.length === 0
                          ? true
                          : el.name.toLowerCase().includes(filter.toLowerCase())
                      )
                      .filter((e) => !e.trashed)
                      .map((f) => {
                        return (
                          <TableRow key={`${f?.uri}-folders-table-project`}>
                            {!isSingleFile && (
                              <TableCell className={classes.checkboxCell}>
                                <Checkbox
                                  color="primary"
                                  data-testid={`checkbox-folder-${f.id}`}
                                  checked={selectedFoldersId.includes(f.id)}
                                  disabled={
                                    selectMode !== "multipleFilesFolders"
                                  }
                                  onClick={(e) =>
                                    setSelectedFolders((prevFolderArr) => {
                                      // @ts-ignore
                                      if (e.target.checked) {
                                        return [...prevFolderArr, f];
                                      }
                                      return prevFolderArr.filter(
                                        (folder) => folder.id !== f.id
                                      );
                                    })
                                  }
                                />
                              </TableCell>
                            )}
                            <TableCellName
                              file={f}
                              isModal
                              clickHandler={() => handleChangeDirectory(f)}
                            />
                            <TableCellType file={f} />
                            <TableCellSize file={f} />
                            <TableCellLastModified file={f} />
                          </TableRow>
                        );
                      })}
                    {userFiles?.files
                      ?.filter((el) =>
                        filter.length === 0
                          ? true
                          : el.originalName
                              .toLowerCase()
                              .includes(filter.toLowerCase())
                      )
                      .filter((e) =>
                        !e.trashed &&
                        e?.originalName.charAt(0) !== "." &&
                        filterFiles
                          ? filterFiles(e)
                          : true
                      )
                      .map((f) => {
                        return (
                          <TableRow
                            key={`${f?.uri}-files-table-project`}
                            sx={
                              isSingleFile && selectedFiles[0]?.id === f.id
                                ? {
                                    ".MuiTableCell-root:first-of-type": {
                                      border: "1px solid",
                                      borderRight: "none",
                                      borderColor: "coreApp.borders",
                                    },
                                    ".MuiTableCell-root:last-of-type": {
                                      border: "1px solid",
                                      borderLeft: "none",
                                      borderColor: "coreApp.borders",
                                    },
                                    ".MuiTableCell-root": {
                                      border: "1px solid",
                                      borderLeft: "none",
                                      borderRight: "none",
                                      borderColor: "coreApp.borders",
                                    },
                                  }
                                : {}
                            }
                            onClick={() => {
                              if (isSingleFile) {
                                setSelectedFiles([f]);
                              }
                            }}
                          >
                            {!isSingleFile && (
                              <TableCell className={classes.checkboxCell}>
                                <Checkbox
                                  color="primary"
                                  checked={selectedFilesId.includes(f.id)}
                                  onClick={(e) =>
                                    setSelectedFiles((prevFileArr) => {
                                      // @ts-ignore
                                      if (e.target.checked) {
                                        return [...prevFileArr, f];
                                      }
                                      return prevFileArr.filter(
                                        (file) => file.id !== f.id
                                      );
                                    })
                                  }
                                />
                              </TableCell>
                            )}
                            <TableCellName file={f} isModal />
                            <TableCellType file={f} />
                            <TableCellSize file={f} />
                            <TableCellLastModified file={f} />
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className={classes.flexMiddle}>
            <div className={classes.posRel}>
              <Button onClick={() => setOpen(false)} variant="outlined">
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "black",
                  },
                }}
                variant="contained"
                data-testid="files-folders-confirm-button-modal"
                onClick={() => {
                  onConfirm(
                    selectedFiles,
                    selectedFilesId,
                    selectedFolders,
                    selectedFoldersId
                  );
                }}
                disabled={loading}
              >
                {loading ? "Loading..." : "Add"}
              </Button>
              {selectMode !== "singleFile" && totalFiles > 0 && (
                <div className={classes.btnLabel}>
                  {totalFiles} File{totalFiles === 1 ? "" : "s"} selected
                </div>
              )}
            </div>
          </div>
        </Grid>
      </div>
    </CustomModal>
  );
};

FileFolderSelectorModal.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default FileFolderSelectorModal;
