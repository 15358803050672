import React from "react";

/**
 * CubaseIcon
 * @returns {JSX.Element} Cubase Icon
 */
function CubaseIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 2H3C2.45 2 2 2.45 2 3V21C2 21.55 2.45 22 3 22H21C21.55 22 22 21.55 22 21V3C22 2.45 21.55 2 21 2ZM11.9 20.02L4.16 12.28L11.9 4.54L14.44 7.08C12.18 7.73 10.53 9.81 10.53 12.28C10.53 14.75 12.18 16.83 14.44 17.48L11.9 20.02ZM15.85 14.88C14.38 14.88 13.19 13.69 13.19 12.22C13.19 10.75 14.38 9.56 15.85 9.56C17.32 9.56 18.51 10.75 18.51 12.22C18.51 13.69 17.32 14.88 15.85 14.88Z"
        fill="black"
      />
    </svg>
  );
}

export default CubaseIcon;
