import PropTypes from "prop-types";
import React from "react";
import { Skeleton, TableCell, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getGenericName } from "_utils/fileTypeUtil/fileTypeUtil";
import isDawPackageType from "../../../_utils/isDawPackageType";

const useStyles = makeStyles((theme) => ({
   tableCell: {
      fontSize: theme.typography.small1.fontSize,
      // paddingRight: "30px",
   },
}));

/**
 * Table Cell Type.
 * @param {props} props props
 * @returns {JSX.Element} File Type Cell.
 */
const TableCellType = ({ file, loading }) => {
   const classes = useStyles();
   // eslint-disable-next-line no-underscore-dangle
   const isFile = file?.__typename === "File";
   const isPackageType = isDawPackageType({ fileFolder: file });

   return (
      <TableCell sx={{ paddingRight: "30px" }}>
         <Typography noWrap className={classes.tableCell}>
            {loading && <Skeleton />}
            {!loading && (isFile || isPackageType) && getGenericName(file)}
            {!loading && !isFile && !isPackageType && "Folder"}
         </Typography>
      </TableCell>
   );
};

TableCellType.propTypes = {
   file: PropTypes.shape({
      __typename: PropTypes.string,
   }).isRequired,
   loading: PropTypes.bool,
};
TableCellType.defaultProps = {
   loading: false,
};

export default TableCellType;
