const { Popover } = require("@mui/material");
const { withStyles } = require("@mui/styles");

const GrayPopover = withStyles((theme) => ({
   paper: {
      backgroundColor: theme.palette.secondary.shade5,
      padding: "12px 0",
      "& div .MuiTypography-root": {
         fontSize: theme.typography.small2.fontSize,
         color: "black",
         width: "100%",
         justifyContent: "flex-start",
         borderRadius: 0,
         padding: "4px 12px",
         cursor: "pointer",
         "&[disabled]": {
            pointerEvents: "none", // no-allow :hover styles or click events to be triggered
            cursor: "not-allowed", // and custom cursor can be defined without :hover state
            color: theme.palette.secondary.shade30,
         },
         "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
         },
      },
   },
}))(Popover);

export default GrayPopover;
