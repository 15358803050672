import AbletonIcon from "_assets/icons/AbletonIcon";
import LogicIcon from "_assets/icons/LogicIcon";
import GaragebandIcon from "_assets/icons/GaragebandIcon";
import FLStudioIcon from "_assets/icons/FLStudioIcon";
import AdobeAuditionIcon from "_assets/icons/AdobeAuditionIcon";
import ProToolsIcon from "_assets/icons/ProToolsIcon";
import CubaseIcon from "_assets/icons/CubaseIcon";
import ReasonIcon from "_assets/icons/ReasonIcon";
import AcidProIcon from "_assets/icons/AcidProIcon";
import GenericDAWIcon from "_assets/icons/GenericDAWIcon";

const fileTypeData = [
   {
      ext: [".aac"],
      name: "AAC audio",
      mimeType: ["audio/aac"],
   },
   {
      ext: [".abw"],
      name: "AbiWord document",
      mimeType: ["application/x-abiword"],
   },
   {
      ext: [".arc"],
      name: "Archive document (multiple files embedded)",
      mimeType: ["application/x-freearc"],
   },
   {
      ext: [".avi"],
      name: "AVI: Audio Video Interleave",
      mimeType: ["video/x-msvideo"],
   },
   {
      ext: [".azw"],
      name: "Amazon Kindle eBook format",
      mimeType: ["application/vnd.amazon.ebook"],
   },
   {
      ext: [".bin"],
      name: "Any kind of binary data",
      mimeType: ["application/octet-stream"],
   },
   {
      ext: [".bmp"],
      name: "Windows OS/2 Bitmap Graphics",
      mimeType: ["image/bmp"],
   },
   {
      ext: [".bz"],
      name: "BZip archive",
      mimeType: ["application/x-bzip"],
   },
   {
      ext: [".bz2"],
      name: "BZip2 archive",
      mimeType: ["application/x-bzip2"],
   },
   {
      ext: [".cda"],
      name: "CD audio",
      mimeType: ["application/x-cdf"],
   },
   {
      ext: [".csh"],
      name: "C-Shell script",
      mimeType: ["application/x-csh"],
   },
   {
      ext: [".css"],
      name: "Cascading Style Sheets (CSS)",
      mimeType: ["text/css"],
   },
   {
      ext: [".csv"],
      name: "Comma-separated values (CSV)",
      mimeType: ["text/csv"],
   },
   {
      ext: [".doc"],
      name: "Microsoft Word",
      mimeType: ["application/msword"],
   },
   {
      ext: [".docx"],
      name: "Microsoft Word (OpenXML)",
      mimeType: [
         "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ],
   },
   {
      ext: [".eot"],
      name: "MS Embedded OpenType fonts",
      mimeType: ["application/vnd.ms-fontobject"],
   },
   {
      ext: [".epub"],
      name: "Electronic publication (EPUB)",
      mimeType: ["application/epub+zip"],
   },
   {
      ext: [".gz"],
      name: "GZip Compressed Archive",
      mimeType: ["application/gzip"],
   },
   {
      ext: [".gif"],
      name: "Graphics Interchange Format (GIF)",
      mimeType: ["image/gif"],
   },
   {
      ext: [".htm", ".html"],
      name: "HyperText Markup Language (HTML)",
      mimeType: ["text/html"],
   },
   {
      ext: [".ico"],
      name: "Icon format",
      mimeType: ["image/vnd.microsoft.icon"],
   },
   {
      ext: [".ics"],
      name: "iCalendar format",
      mimeType: ["text/calendar"],
   },
   {
      ext: [".jar"],
      name: "Java Archive (JAR)",
      mimeType: ["application/java-archive"],
   },
   {
      ext: [".jpeg", ".jpg"],
      name: "JPEG",
      mimeType: ["image/jpeg"],
   },
   {
      ext: [".js"],
      name: "JavaScript",
      mimeType: ["text/javascript"],
   },
   {
      ext: [".json"],
      name: "JSON",
      mimeType: ["application/json"],
   },
   {
      ext: [".jsonld"],
      name: "JSON-LD",
      mimeType: ["application/ld+json"],
   },
   {
      ext: [".m4v"],
      name: "M4V",
      mimeType: ["audio/m4v"],
   },
   {
      ext: [".mid", ".midi"],
      name: "MIDI",
      mimeType: ["audio/midi audio/x-midi"],
   },
   {
      ext: [".mjs"],
      name: "JavaScript module",
      mimeType: ["text/javascript"],
   },
   {
      ext: [".mov"],
      name: "MOV",
      mimeType: ["video/quicktime"],
   },
   {
      ext: [".mp3"],
      name: "MP3",
      mimeType: ["audio/mpeg"],
   },
   {
      ext: [".mp4"],
      name: "MP4",
      mimeType: ["video/mp4"],
   },
   {
      ext: [".mpeg"],
      name: "MPEG",
      mimeType: ["video/mpeg"],
   },
   {
      ext: [".mpkg"],
      name: "Apple Installer Package",
      mimeType: ["application/vnd.apple.installer+xml"],
   },
   {
      ext: [".odp"],
      name: "OpenDocument presentation document",
      mimeType: ["application/vnd.oasis.opendocument.presentation"],
   },
   {
      ext: [".ods"],
      name: "OpenDocument spreadsheet document",
      mimeType: ["application/vnd.oasis.opendocument.spreadsheet"],
   },
   {
      ext: [".odt"],
      name: "OpenDocument text document",
      mimeType: ["application/vnd.oasis.opendocument.text"],
   },
   {
      ext: [".oga"],
      name: "OGG audio",
      mimeType: ["audio/ogg"],
   },
   {
      ext: [".ogv"],
      name: "OGG video",
      mimeType: ["video/ogg"],
   },
   {
      ext: [".ogx"],
      name: "OGG",
      mimeType: ["application/ogg"],
   },
   {
      ext: [".opus"],
      name: "Opus audio",
      mimeType: ["audio/opus"],
   },
   {
      ext: [".otf"],
      name: "OpenType font",
      mimeType: ["font/otf"],
   },
   {
      ext: [".png"],
      name: "PNG",
      mimeType: ["image/png"],
   },
   {
      ext: [".pdf"],
      name: "PDF",
      mimeType: ["application/pdf"],
   },
   {
      ext: [".php"],
      name: "Hypertext Preprocessor (Personal Home Page)",
      mimeType: ["application/x-httpd-php"],
   },
   {
      ext: [".ppt"],
      name: "Microsoft PowerPoint",
      mimeType: ["application/vnd.ms-powerpoint"],
   },
   {
      ext: [".pptx"],
      name: "Microsoft PowerPoint (OpenXML)",
      mimeType: [
         "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      ],
   },
   {
      ext: [".rar"],
      name: "RAR archive",
      mimeType: ["application/vnd.rar"],
   },
   {
      ext: [".rtf"],
      name: "Rich Text Format (RTF)",
      mimeType: ["application/rtf"],
   },
   {
      ext: [".sh"],
      name: "Bourne shell script",
      mimeType: ["application/x-sh"],
   },
   {
      ext: [".svg"],
      name: "Scalable Vector Graphics (SVG)",
      mimeType: ["image/svg+xml"],
   },
   {
      ext: [".swf"],
      name: "Small web format (SWF) or Adobe Flash document",
      mimeType: ["application/x-shockwave-flash"],
   },
   {
      ext: [".tar"],
      name: "Tape Archive (TAR)",
      mimeType: ["application/x-tar"],
   },
   {
      ext: [".tif", ".tiff"],
      name: "Tagged Image File Format (TIFF)",
      mimeType: ["image/tiff"],
   },
   {
      ext: [".ts"],
      name: "MPEG transport stream",
      mimeType: ["video/mp2t"],
   },
   {
      ext: [".ttf"],
      name: "TrueType Font",
      mimeType: ["font/ttf"],
   },
   {
      ext: [".txt"],
      name: "Text",
      mimeType: ["text/plain"],
   },
   {
      ext: [".vsd"],
      name: "Microsoft Visio",
      mimeType: ["application/vnd.visio"],
   },
   {
      ext: [".wav"],
      name: "WAV",
      mimeType: ["audio/wav"],
   },
   {
      ext: [".weba"],
      name: "WEBM audio",
      mimeType: ["audio/webm"],
   },
   {
      ext: [".webm"],
      name: "WEBM video",
      mimeType: ["video/webm"],
   },
   {
      ext: [".webp"],
      name: "WEBP image",
      mimeType: ["image/webp"],
   },
   {
      ext: [".woff"],
      name: "Web Open Font Format (WOFF)",
      mimeType: ["font/woff"],
   },
   {
      ext: [".woff2"],
      name: "Web Open Font Format (WOFF)",
      mimeType: ["font/woff2"],
   },
   {
      ext: [".xhtml"],
      name: "XHTML",
      mimeType: ["application/xhtml+xml"],
   },
   {
      ext: [".xls"],
      name: "Microsoft Excel",
      mimeType: ["application/vnd.ms-excel"],
   },
   {
      ext: [".xlsx"],
      name: "Microsoft Excel (OpenXML)",
      mimeType: [
         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
   },
   {
      ext: [".xml"],
      name: "XML",
      mimeType: ["application/xml", "text/xml"],
   },
   {
      ext: [".xul"],
      name: "XUL",
      mimeType: ["application/vnd.mozilla.xul+xml"],
   },
   {
      ext: [".zip"],
      name: "ZIP archive",
      mimeType: ["application/zip"],
   },
   {
      ext: [".3gp"],
      name: "3GPP audio/video container",
      mimeType: ["video/3gpp", "audio/3gpp"],
   },
   {
      ext: [".3g2"],
      name: "3GPP2 audio/video container",
      mimeType: ["video/3gpp2", "audio/3gpp2"],
   },
   {
      ext: [".7z"],
      name: "7-zip archive",
      mimeType: ["application/x-7z-compressed"],
   },
   { ext: [".m4a"], name: "M4A", mimeType: ["audio/x-m4a"] },
   { ext: [".flac"], name: "FLAC", mimeType: ["audio/flac"] },
   { ext: [".aif"], name: "AIFF", mimeType: ["audio/aiff", "audio/x-aiff"] },
   {
      ext: [".als", ".alp"],
      name: "Ableton Live",
      mimeType: ["application/other"],
      supportedDAW: true,
      icon: <AbletonIcon />,
   },
   {
      ext: [".logic", ".logicx"],
      name: "Logic Pro",
      mimeType: ["application/other"],
      supportedDAW: true,
      packageFile: true,
      icon: <LogicIcon />,
   },
   {
      ext: [".gbproj", ".band"],
      name: "GarageBand",
      mimeType: ["application/other"],
      supportedDAW: true,
      packageFile: true,
      icon: <GaragebandIcon />,
   },
   {
      ext: [".flp"],
      name: "FL Studio",
      mimeType: ["application/other"],
      supportedDAW: true,
      icon: <FLStudioIcon />,
   },
   {
      ext: [".sesx"],
      name: "Adobe Audition",
      mimeType: ["application/other"],
      supportedDAW: true,
      icon: <AdobeAuditionIcon />,
   },
   {
      ext: [".avp", ".pts"],
      name: "Pro Tools",
      mimeType: ["application/other"],
      supportedDAW: true,
      icon: <ProToolsIcon />,
   },
   {
      ext: [".cpr"],
      name: "Cubase",
      mimeType: ["application/other"],
      supportedDAW: true,
      icon: <CubaseIcon />,
   },
   {
      ext: [
         ".reason",
         ".rns",
         ".rsn",
         ".rsndemo",
         ".rps",
         ".rei",
         ".reidemo",
         ".relt",
         ".reltdemo",
         ".ree",
         ".reedemo",
         ".rltd",
         ".rsb",
      ],
      name: "Reason",
      mimeType: ["application/other"],
      supportedDAW: true,
      icon: <ReasonIcon />,
   },
   {
      ext: [".acd", ".acd-zip"],
      name: "ACID Pro",
      mimeType: ["application/other"],
      supportedDAW: true,
      icon: <AcidProIcon />,
   },
   {
      ext: [".rpp"],
      name: "Reaper",
      mimeType: ["application/other"],
      supportedDAW: true,
      icon: <GenericDAWIcon />,
   },
   {
      ext: [".mx5", ".mx6", ".mx7"],
      name: "Mixcraft",
      mimeType: ["application/other"],
      supportedDAW: true,
      icon: <GenericDAWIcon />,
   },
   {
      ext: [".song"],
      name: "Studio One",
      mimeType: ["application/other"],
      supportedDAW: true,
      icon: <GenericDAWIcon />,
   },
   {
      ext: [".pmf"],
      name: "Pyramix",
      mimeType: ["application/other"],
      supportedDAW: true,
      icon: <GenericDAWIcon />,
   },
   {
      ext: [".tracktion"],
      name: "Waveform",
      mimeType: ["application/other"],
      supportedDAW: true,
      icon: <GenericDAWIcon />,
   },
   {
      ext: [".luna"],
      name: "Luna",
      mimeType: ["application/other"],
      supportedDAW: true,
      icon: <GenericDAWIcon />,
   },
   {
      ext: [".cwb", ".cwp", ".wrk"],
      name: "Cakewalk",
      mimeType: ["application/other"],
      supportedDAW: true,
      icon: <GenericDAWIcon />,
   },
   {
      ext: [".bwproject"],
      name: "Bitwig Studio",
      mimeType: ["application/other"],
      supportedDAW: true,
      icon: <GenericDAWIcon />,
   },
];

export default fileTypeData;
