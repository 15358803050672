import * as React from "react";
import { Box, Modal, IconButton, Grid, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
   closeBtn: {
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 999,
      padding: 15,
   },
}));

/**
 * Custom Modal
 *
 * A flexible custom modal to be used across the app anywhere you want to!
 * @param {props} props props
 * @param {JSX.Element} props.children Body of modal.
 * @param {boolean} props.open Should the modal be displayed.
 * @param {Function} props.handleClose Function to call to close the modal.
 * @param {string} props.fullWidth Size of the modal xs, sm, md, lg, defaults to 655px.
 * @param {JSX.Element} props.header Child component to display in header.
 * @param {JSX.Element} props.footer Child component to display in footer.
 * @param {boolean} props.removePadding removes padding of the modal.
 * @param {string} props.colorCloseIcon color close icon.
 * @returns {JSX.Element} Custom modal component.
 */
const CustomModal = ({
   children,
   open,
   handleClose,
   fullWidth,
   header,
   footer,
   removePadding,
   colorCloseIcon = "#B3B3B3",
}) => {
   const theme = useTheme();
   const classes = useStyles();

   const getWidth = React.useMemo(() => {
      switch (fullWidth) {
         case "xs":
            return 400;
         case "sm":
            return 560;
         case "md":
            return 655;
         case "680":
            return 680;
         case "800":
            return 800;
         case "lg":
            return 1034;
         case false:
            return 655;
         default:
            return 655;
      }
   }, [fullWidth]);

   const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      backgroundColor: theme.palette.coreApp.background,
      boxShadow: 24,
      borderRadius: "8px",
      p: removePadding ? "0px" : "44px 10px 15px 10px",
      // p: removePadding ? "0px" : "22px 10px 15px 10px",
      boxSizing: "border-box",
      outline: "none",
   };

   return (
      <Modal open={open} onClose={handleClose}>
         <Box sx={style} maxWidth={getWidth}>
            {handleClose !== undefined && (
               <IconButton
                  style={{ color: colorCloseIcon }}
                  variant="outlined"
                  onClick={handleClose}
                  className={classes.closeBtn}
                  id="close-modal"
               >
                  <CloseIcon />
               </IconButton>
            )}
            <Grid flexDirection="column">
               {header && <Box mb="20px">{header}</Box>}
               {children}
               {footer && <Box mt="40px">{footer}</Box>}
            </Grid>
         </Box>
      </Modal>
   );
};

export default CustomModal;
