import React, { useRef, useState, useLayoutEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const useStyles = makeStyles(() => ({
   examplesTitle: {
      color: "#9390A7",
      paddingTop: "124px",
      paddingBottom: "8px",
   },
   examplesRow: {
      display: "flex",
      flexDirection: "column",
      "& > :nth-child(1)": {
         fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
         maxWidth: "70vw",
         marginBottom: "22px",
         color: "white",
      },
      "& > :nth-child(2)": {
         fontSize: "19px",
         lineHeight: "28px",
         color: "#9390A7",
         marginBottom: "55px",
      },
      "& > :nth-child(3)": {
         "& a": {
            fontSize: "19px",
            lineHeight: "28px",
            color: "#BA9FFE",
            maxWidth: "fit-content",
            "&:hover": {
               borderBottom: "2px solid #BA9FFE",
               marginBottom: "-2px",
            },
         },
      },
   },
   examplesCTALink: {
      textDecoration: "none",
   },
   mobileRoot: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "48px",
   },
   featureVideoMobile: {
      height: "auto",
      maxHeight: "100%",
      width: "100%",
      objectFit: "contain",
      maxWidth: "100%",
      boxSizing: "border-box",
   },
}));

/**
 * Remote Collaboration
 * @returns {JSX.Element} Remote collaboration features list component.
 */
const RemoteCollaboration = () => {
   const classes = useStyles();
   const [section, setSection] = useState(1);
   const containerRef = useRef();
   const mobileView = useMediaQuery("(max-width:900px)");

   useLayoutEffect(() => {
      /**
       * Handle Scroll linked animations.
       */
      const handleScroll = () => {
         if (containerRef.current) {
            const screenPosition = containerRef.current.getBoundingClientRect();
            const offset = window.innerHeight / 10;
            const elementPosition = screenPosition.top - offset;

            const elementVisibleHeight =
               (containerRef.current.clientHeight / 3) * 2;

            const heightSection = elementVisibleHeight / 3;

            // Scrolling top to bottom
            if (elementPosition > 0) {
               setSection(1);
            } else {
               const positivePosition = Math.abs(elementPosition);
               if (heightSection > positivePosition) {
                  setSection(1);
               } else if (heightSection * 2.3 > positivePosition) {
                  setSection(2);
               }
            }
         }
      };

      window.addEventListener("scroll", handleScroll);
      handleScroll();

      return () => window.removeEventListener("scroll", handleScroll);
   }, []);

   /**
    * Card Info
    *
    * Details of feature card.
    * @param {props} props props
    * @returns {JSX.Element} Card info sub component.
    */
   const CardInfo = ({ title, content, linkHref, linkText }) => {
      const [hover, setHover] = useState(false);
      return (
         <div style={{ height: "70vh" }} className={classes.examplesRow}>
            <div
               style={{
                  marginTop: mobileView ? "36px" : "128px",
                  fontSize: mobileView ? "36px" : "42px",
                  lineHeight: mobileView ? "100%" : "50px",
               }}
            >
               {title}
            </div>
            <div>{content}</div>
            <div
               style={{ display: "flex", alignItems: "center" }}
               onMouseEnter={() => setHover(true)}
               onMouseLeave={() => setHover(false)}
            >
               <Link to={linkHref} className={classes.examplesCTALink}>
                  {linkText}
               </Link>
               {hover && <ArrowOutwardIcon style={{ fill: "#BA9FFE" }} />}
            </div>
         </div>
      );
   };

   CardInfo.propTypes = {
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      linkHref: PropTypes.string.isRequired,
      linkText: PropTypes.string.isRequired,
   };

   return (
      <div
         style={{
            paddingBottom: mobileView ? "0px" : "240px",
            background: mobileView
               ? "radial-gradient(48% 10% at 100% 32%, rgba(174, 71, 255, 0.6) 0%, rgba(17, 17, 17, 0) 100%), radial-gradient(48% 12% at 0% 62%, rgba(97, 71, 255, 0.7) 0%, rgba(17, 17, 17, 0) 100%), rgb(0,0,0)"
               : "radial-gradient(23% 26.47% at 100% 44%, rgba(174, 71, 255, 0.6) 0%, rgba(17, 17, 17, 0) 100%), radial-gradient(30.76% 34.79% at 0% 72%, rgba(97, 71, 255, 0.7) 0%, rgba(17, 17, 17, 0) 100%), rgb(0,0,0)",
         }}
      >
         <div>
            <div style={{ paddingLeft: mobileView ? "1.2rem" : "3.87rem" }}>
               <div
                  className={classes.examplesTitle}
                  style={{
                     fontSize: mobileView ? "1.25rem" : "1.375rem",
                     lineHeight: "145%",
                  }}
               >
                  Listen and provide feedback without emails or shared documents
               </div>
               <div
                  style={{
                     fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
                     fontSize: mobileView ? "3.5rem" : "4.75rem",
                     lineHeight: "95%",
                     color: "white",
                  }}
               >
                  Remote collaboration
               </div>
               <div
                  style={{
                     fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
                     fontSize: mobileView ? "3.5rem" : "4.75rem",
                     lineHeight: "95%",
                     color: "#BA9FFE",
                     paddingBottom: mobileView ? "0px" : "92px",
                  }}
               >
                  done right
               </div>
            </div>
            {mobileView ? (
               <div className={classes.mobileRoot}>
                  <video
                     style={{ paddingRight: "20px" }}
                     src="https://aux-marketing-assets.s3.eu-west-1.amazonaws.com/marketing-pages/collaborate.mp4"
                     className={classes.featureVideoMobile}
                     autoPlay
                     muted
                     playsInline
                     loop
                  />
                  <div style={{ padding: "0px 12px" }}>
                     <CardInfo
                        title="Collaborative Projects"
                        content="Invite your bandmates, producer, label or guest
                           artist to your project to provide feedback and upload
                           files."
                        linkHref="/signup"
                        linkText="Start collaborating now"
                     />
                  </div>

                  <video
                     style={{ paddingLeft: "20px" }}
                     src="https://aux-marketing-assets.s3.eu-west-1.amazonaws.com/marketing-pages/comments-new.mp4"
                     className={classes.featureVideoMobile}
                     autoPlay
                     muted
                     playsInline
                     loop
                  />
                  <div style={{ padding: "0px 12px" }}>
                     <CardInfo
                        title="Real-time Chat"
                        content="Timestamp commenting on waveforms or just talking
                        about ideas. Edit or delete comments and resolve
                        threads."
                        linkHref="/signup"
                        linkText="Start collaborating now"
                     />
                  </div>
               </div>
            ) : (
               <div style={{ height: "165vh" }} ref={containerRef}>
                  <div
                     style={{
                        display: "grid",
                        gridTemplateColumns: "64% 36%",
                        color: "white",
                        marginBottom: "150px",
                     }}
                  >
                     <div
                        style={{
                           position: "sticky",
                           top: "4vh",
                           height: "80vh",
                        }}
                     >
                        {section === 1 && (
                           <video
                              style={{
                                 width: "90%",
                                 height: "auto",
                                 borderRadius: "0px 8px 8px 0px",
                              }}
                              src="https://aux-marketing-assets.s3.eu-west-1.amazonaws.com/marketing-pages/collaborate.mp4"
                              autoPlay
                              muted
                              playsInline
                              loop
                              data-aos="zoom-in"
                              data-aos-duration="1500"
                           />
                        )}
                        {section === 2 && (
                           <video
                              style={{
                                 width: "90%",
                                 height: "auto",
                                 borderRadius: "0px 8px 8px 0px",
                              }}
                              src="https://aux-marketing-assets.s3.eu-west-1.amazonaws.com/marketing-pages/comments-new.mp4"
                              autoPlay
                              muted
                              playsInline
                              loop
                              data-aos="zoom-in"
                              data-aos-offset="320"
                              data-aos-duration="1500"
                           />
                        )}
                     </div>
                     <div style={{ marginRight: "60px" }}>
                        <CardInfo
                           title="Collaborative Projects"
                           content="Invite your bandmates, producer, label or guest
                           artist to your project to provide feedback and upload
                           files."
                           linkHref="/signup"
                           linkText="Start collaborating now"
                        />
                        <CardInfo
                           title="Real-time Chat"
                           content="Timestamp commenting on waveforms or just talking
                  about ideas. Edit or delete comments and resolve
                  threads."
                           linkHref="/signup"
                           linkText="Start collaborating now"
                        />
                     </div>
                  </div>
               </div>
            )}
         </div>
      </div>
   );
};

export default RemoteCollaboration;
