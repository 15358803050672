import { useMutation } from "@apollo/client";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { SET_ENGAGE_STAT } from "_apollo/queries";
import gtag from "_utils/gtag";
import isElectronHook from "_utils/isElectron";
import { useUser } from "_utils/UserContext";
import useStatsMutation from "_utils/useStatsMutation";

/**
 * Google Analytics Wrapper
 *
 * We use GA 4 so we don't use react-ga. We have added the gtag directly to the public/index.html and use this only to log page views.
 * @param {object} props props
 * @param {Element} props.children Jsx element
 * @returns {Element} Jsx element
 */
const usePageView = ({ children }) => {
   const history = useHistory();
   const sendStatsMutation = useStatsMutation();
   const maintainScrollPositionPaths = ["discover"];
   const [setEngage] = useMutation(SET_ENGAGE_STAT);

   /**
    * Maintain Scroll
    *
    * Should the scroll position be maintained. If false, the component will scroll to top when the user changes route.
    * @param {string} pathname pathname
    * @returns {boolean} boolean value, true means the scroll position be maintained
    */
   const maintainScroll = (pathname) => {
      for (let i = 0; i < maintainScrollPositionPaths.length; i++) {
         const path = maintainScrollPositionPaths[i];
         if (pathname.includes(path)) {
            return true;
         }
      }
      return false;
   };

   const [user] = useUser();

   const checkEngagedStatRef = useRef(false);
   useEffect(() => {
      if (checkEngagedStatRef.current) {
         return;
      }
      if (!user) {
         return;
      }
      checkEngagedStatRef.current = true;
      setEngage().then(({ data }) => {
         const statName = data?.setEngageStat;
         // only send stats after week 1
         if (statName && statName !== "Engaged_Start") {
            gtag("event", statName, {
               event_label: statName.replace("_", " "),
               event_category: "engagement",
            });
         }
         // Send custom google tag manager events for Google Adwords
         switch (statName) {
            case "Engaged_W1":
               gtag("event", "conversion", {
                  send_to: "AW-10804925588/8f9jCPz8w5IYEJSpmKAo",
               });
               break;
            case "Engaged_W2":
               gtag("event", "conversion", {
                  send_to: "AW-10804925588/1rU7CP_8w5IYEJSpmKAo",
               });
               break;
            case "Engaged_W3":
               gtag("event", "conversion", {
                  send_to: "AW-10804925588/hUjhCIL9w5IYEJSpmKAo",
               });
               break;
            case "Engaged_W4":
               gtag("event", "conversion", {
                  send_to: "AW-10804925588/a1NRCOz-w5IYEJSpmKAo",
               });
               break;
            default:
               break;
         }
      });
   }, [user]);

   useEffect(() => {
      const unlisten = history?.listen(() => {
         // Desktop and Web valid path - because
         const desktopSafePath = isElectronHook()
            ? window.location.href.slice(window.location.href.indexOf("#/") + 1)
            : window.location.pathname;

         if (!maintainScroll(desktopSafePath.slice(1))) {
            // Reset the scroll position when switching route.
            window.scrollTo(0, 0);
         }

         // Log the PageView stat
         sendStatsMutation({
            statsId: "PageView",
            metadata: JSON.stringify({
               path: desktopSafePath,
            }),
         });
         gtag("send", "page_view", {
            page_location: window.location.href,
            page_path: desktopSafePath,
         });
         window.twq("track", "PageView", {
            page_location: window.location.href,
            page_path: desktopSafePath,
         });
      });

      return () => {
         unlisten();
      };
   }, [history]);

   return children;
};

export default usePageView;
