import PropTypes from "prop-types";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import withStyles from "@mui/styles/withStyles";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import DownloadAppButton from "./DownloadAppButton";

const DarkButton = withStyles(() => ({
   root: {
      color: "white",
      backgroundColor: "black",
      "&:hover": {
         backgroundColor: "black",
         boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      },
   },
}))(Button);

const useStyles = makeStyles(() => ({
   engageContainer: {
      boxShadow: "0px 4px 24px -1px rgba(26, 26, 26, 0.08)",
      borderRadius: "20px",
      backdropFilter: "blur(15px)",
      margin: "0 auto",
      width: "90vw",
      "@media (max-width: 900px)": {
         paddingLeft: "24px",
         paddingRight: "24px",
         width: "calc(90vw - 48px)",
      },
   },
   timeToWork: {
      fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
      fontSize: "48px",
      lineHeight: "48px",
      textAlign: "center",
      paddingTop: "88px",
      marginTop: "0px",
      "@media (max-width: 900px)": {
         fontSize: "36px",
         lineHeight: "34px",
         textAlign: "left",
         maxWidth: "520px",
      },
   },
   timeToWorkContent: {
      fontSize: "19px",
      lineHeight: "28px",
      textAlign: "center",
      maxWidth: "520px",
      margin: "0 auto",
      "@media (max-width: 900px)": {
         fontSize: "18px",
         textAlign: "left",
         lineHeight: "25px",
         marginLeft: "0px",
      },
   },
   getStartedForm: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      padding: "50px 0 77px",
      gap: "10px",
      "@media (max-width: 900px)": {
         justifyContent: "flex-start",
      },
   },
}));

/**
 * Time To Get To Work
 * @param {object} props props
 * @param {object} props.style Container style overrides.
 * @returns {React.Element} Time To Get To Work component
 */
const TimeToGetToWork = ({ style }) => {
   const classes = useStyles();
   const mobileView = useMediaQuery("(max-width:900px)");

   return (
      <div className={classes.engageContainer} style={style}>
         <h1 className={classes.timeToWork}>Ready to make more music?</h1>
         <div className={classes.timeToWorkContent}>
            Sign up now and try Aux Sounds, Mastering and Collaboration tools
            for free.
         </div>
         <div className={classes.getStartedForm}>
            <DarkButton
               style={{
                  padding: "12px 16px",
                  fontSize: "16px",
                  flex: 1,
                  maxWidth: "177px",
               }}
               component={Link}
               to="/signup"
            >
               Sign up now
            </DarkButton>
            <DownloadAppButton
               sx={{
                  color: "black",
                  borderColor: "black",
                  padding: "12px 16px",
                  fontSize: "16px",
                  flex: 1,
                  maxWidth: "177px",
               }}
               variant="outlined"
               color="dark"
               text={mobileView ? "Download" : null}
            />
         </div>
      </div>
   );
};

TimeToGetToWork.propTypes = {
   style: PropTypes.shape({}),
};
TimeToGetToWork.defaultProps = {
   style: {
      background:
         "linear-gradient(273.38deg, #FFD645 0%, rgba(255, 199, 229, 0.4) 100%), linear-gradient(93.38deg, #AE47FF 0%, rgba(200, 191, 255, 0.18) 100%)",
   },
};

export default TimeToGetToWork;
