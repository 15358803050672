import React from "react";

/**
 * AbletonIcon
 * @returns {JSX.Element} Ableton Icon
 */
function AbletonIcon() {
   return (
      <svg
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 1C20 0.447715 19.5523 0 19 0H1C0.447715 0 0 0.447715 0 1V19C0 19.5523 0.447715 20 1 20H19C19.5523 20 20 19.5523 20 19V1ZM17.9167 13.125C17.9167 12.7798 17.6368 12.5 17.2917 12.5H11.0417C10.6965 12.5 10.4167 12.7798 10.4167 13.125C10.4167 13.4702 10.6965 13.75 11.0417 13.75H17.2917C17.6368 13.75 17.9167 13.4702 17.9167 13.125ZM17.9167 6.875C17.9167 6.52982 17.6368 6.25 17.2917 6.25H11.0417C10.6965 6.25 10.4167 6.52982 10.4167 6.875C10.4167 7.22018 10.6965 7.5 11.0417 7.5H17.2917C17.6368 7.5 17.9167 7.22018 17.9167 6.875ZM17.9167 8.95833C17.9167 8.61316 17.6368 8.33333 17.2917 8.33333H11.0417C10.6965 8.33333 10.4167 8.61316 10.4167 8.95833C10.4167 9.30351 10.6965 9.58333 11.0417 9.58333H17.2917C17.6368 9.58333 17.9167 9.30351 17.9167 8.95833ZM17.9167 11.0417C17.9167 10.6965 17.6368 10.4167 17.2917 10.4167H11.0417C10.6965 10.4167 10.4167 10.6965 10.4167 11.0417C10.4167 11.3868 10.6965 11.6667 11.0417 11.6667H17.2917C17.6368 11.6667 17.9167 11.3868 17.9167 11.0417ZM3.33333 6.875C3.33333 6.52982 3.05351 6.25 2.70833 6.25C2.36316 6.25 2.08333 6.52982 2.08333 6.875V13.125C2.08333 13.4702 2.36316 13.75 2.70833 13.75C3.05351 13.75 3.33333 13.4702 3.33333 13.125V6.875ZM9.58333 6.875C9.58333 6.52982 9.30351 6.25 8.95833 6.25C8.61316 6.25 8.33333 6.52982 8.33333 6.875V13.125C8.33333 13.4702 8.61316 13.75 8.95833 13.75C9.30351 13.75 9.58333 13.4702 9.58333 13.125V6.875ZM7.5 6.875C7.5 6.52982 7.22018 6.25 6.875 6.25C6.52982 6.25 6.25 6.52982 6.25 6.875V13.125C6.25 13.4702 6.52982 13.75 6.875 13.75C7.22018 13.75 7.5 13.4702 7.5 13.125V6.875ZM5.41667 6.875C5.41667 6.52982 5.13684 6.25 4.79167 6.25C4.44649 6.25 4.16667 6.52982 4.16667 6.875V13.125C4.16667 13.4702 4.44649 13.75 4.79167 13.75C5.13684 13.75 5.41667 13.4702 5.41667 13.125V6.875Z"
            fill="currentColor"
         />
      </svg>
   );
}

export default AbletonIcon;
