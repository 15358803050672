import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import * as Sentry from "@sentry/react";
import HiringBadge from "_components/HiringBadge";
import api from "../../_utils/ghostContentApi";
import Footer from "../../_components/Footer";
import MetaTags from "../../_components/MetaTags";
import Header from "../../_components/Header/Header";
import BlogPostCard from "../../_components/BlogPostCard";
import EngageSignUpForm from "../../_components/EngageSignUpForm";

const useStyles = makeStyles((theme) => ({
   root: {
      height: "100%",
      width: "100%",
   },
   backgroundMain: {
      background: `linear-gradient(90deg, #FFDCC9 0%, rgba(255, 220, 201, 0) 100%),
      linear-gradient(180deg, #3EF1D1 0%, rgba(62, 241, 209, 0) 100%), 
       #6147FF`,
      padding: "0px 0 95px",
   },
   title: {
      textAlign: "center",
      fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
      maxWidth: "800px",
      marginLeft: "auto",
      marginRight: "auto",
      fontSize: theme.typography.big1.fontSize,
      lineHeight: "48px",
   },
   avatarContainer: {
      display: "flex",
      justifyContent: "center",
      padding: "86px 0 0",
      "& div": {
         width: "126px",
         height: "126px",
      },
   },
   subtitle: {
      textAlign: "center",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "600px",
      fontWeight: 400,
      fontSize: theme.typography.h6.fontSize,
      lineHeight: "24px",
   },
   containerSpace: {
      paddingTop: "80px",
      paddingBottom: "64px",
   },
   containerPost: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit,  minmax(280px, 1fr))",
      gridGap: 40,
      marginTop: "40px",
   },
   centerBtn: {
      display: "flex",
      justifyContent: "center",
   },
}));

/**
 * Authors
 *
 * Blog author landing page.
 * @returns {JSX.Element} Authors landing page component
 */
const Authors = () => {
   const history = useHistory();
   const classes = useStyles();

   const { slug } = useParams();
   const [postWithTag, setPostWithTag] = useState([]);

   const [paginationKey, setPaginationKey] = useState(1);

   const [ghostAuthor, setGhostAuthor] = useState(undefined);

   /**
    * Fetch Tag
    * @param {object} page GraphQL page object.
    * @returns {void} void
    */
   const fetchTag = (page) => {
      if (!page) return;
      api.posts
         .browse({
            filter: `authors:[${slug}]`,
            include: "tags,authors",
            page,
            limit: 9,
         })
         .then((posts) => {
            setPaginationKey(posts.meta.pagination.next);
            setPostWithTag([...postWithTag, ...posts]);
         })
         .catch((error) => {
            Sentry.captureException(error);
         });

      api.authors
         .read({ slug })
         .then((author) => {
            setGhostAuthor(author);
            if (author === undefined) {
               history.push("/404");
            }
         })
         .catch((error) => {
            Sentry.captureException(error);
            history.push("/404");
         });
   };

   useEffect(() => {
      fetchTag(paginationKey);
   }, []);

   return (
      <>
         {ghostAuthor && (
            <div className={classes.root}>
               <HiringBadge />

               <MetaTags
                  title={`${ghostAuthor.name}'s Posts on Aux Connect`}
                  image={ghostAuthor.profile_image}
                  description={`Read ${ghostAuthor.name}'s posts on the Aux Connect blog.`}
               />
               <div className={classes.backgroundMain}>
                  <Header />
                  <Container>
                     <div className={classes.avatarContainer}>
                        <Avatar src={ghostAuthor?.profile_image} />
                     </div>
                     <h1 className={classes.title}>
                        {ghostAuthor?.name}&apos;s
                        <br />
                        posts on Aux Connect
                     </h1>
                     <div className={classes.subtitle}>{ghostAuthor?.bio}</div>
                  </Container>
               </div>
               <Container className={classes.containerSpace}>
                  <div className={classes.containerPost}>
                     {postWithTag.map((post) => (
                        <BlogPostCard key={post?.slug} post={post} />
                     ))}
                  </div>
               </Container>
               <div className={classes.centerBtn}>
                  {paginationKey && (
                     <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => fetchTag(paginationKey)}
                     >
                        Load More
                     </Button>
                  )}
               </div>
               <EngageSignUpForm />
               <Footer />
            </div>
         )}
      </>
   );
};

export default Authors;
