/**
 * stringIsMultiple
 * Returns a string with an s if the array/number is greater than 1
 * @param {Array | number} arrayOrNumber arrayOrNumber to check length of
 * @param {string} string String to add an s to
 * @returns {string} String with an s if arrayOrNumber is greater than 1
 */
const stringIsMultiple = (arrayOrNumber, string) => {
   if (Array.isArray(arrayOrNumber)) {
      return arrayOrNumber?.length === 1 ? string : `${string}s`;
   }
   return +arrayOrNumber === 1 ? string : `${string}s`;
};

export default stringIsMultiple;
