/**
 *
 * @param {string} imageSrc img url
 * @param {Function} callback which will return a bool
 */
const isItDark = (imageSrc, callback) => {
   const fuzzy = 0.1;
   const img = new Image();
   img.src = imageSrc;
   img.style.display = "none";
   img.crossOrigin = "Anonymous";
   document.body.appendChild(img);

   img.onload = () => {
      // create canvas
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      let imageData;
      // Without an error handler firefox will throw an error.
      try {
         imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      } catch (error) {
         return;
      }
      const { data } = imageData;

      let r;
      let g;
      let b;
      let maxRgb;
      let light = 0;
      let dark = 0;

      for (let x = 0, len = data.length; x < len; x += 4) {
         r = data[x];
         g = data[x + 1];
         b = data[x + 2];

         maxRgb = Math.max(Math.max(r, g), b);
         if (maxRgb < 188) dark++;
         else light++;
      }

      const dlDiff = (light - dark) / (img.width * img.height);

      if (dlDiff + fuzzy < 0) callback(true);
      /* Dark. */ else callback(false); /* Not dark. */
   };
};

export default isItDark;
