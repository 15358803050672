import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { useQueryParam, StringParam } from "use-query-params";
import { useUser } from "_utils/UserContext";
import appStorage from "_utils/appStorage";
import { GET_CURRENT_USER } from "../_apollo/queries";

/**
 * Redirect To Feed If Authorised Hook
 *
 * Should redirect the user if to their feed page if the user has a valid
 * token which fetches a valid user object.
 *
 * This hook should be called on: sign up, login or password reset pages.
 */
function useRedirectToFeedIfAuthorised() {
   const history = useHistory();
   const [, setUser] = useUser();

   const [hash] = useQueryParam("hash", StringParam);
   const [redirectTo] = useQueryParam("redirectTo", StringParam);

   useQuery(GET_CURRENT_USER, {
      fetchPolicy: "cache-and-network", // Avoid cache results in case they were previously null
      /**
       * getCurrentUser onCompleted
       *
       * Redirect the user to the feed or a custom redirect location passed in the params.
       * @param {object} data Apollo response data for currentUser query.
       */
      onCompleted(data) {
         if (data?.currentUser) {
            setUser(data.currentUser);
            appStorage.setValue({
               key: "auxSelectedProfileURI",
               value: data.currentUser.profile.uri,
            });
            appStorage.setValue({
               key: "profileId",
               value: data.currentUser.profile.id,
            });
         }
         if (data?.currentUser?.email?.emailVerifiedAt) {
            if (hash) {
               history.push(`/feed?hash=${hash}`);
            } else if (redirectTo) {
               history.push(`/${redirectTo}`);
            } else {
               // Redirect to feed
               history.push("/feed");
            }
         } else if (data?.currentUser?.email) {
            if (redirectTo) {
               history.push(`/confirm-email?redirectTo=${redirectTo}`);
            } else if (hash) {
               history.push(`/confirm-email?hash=${hash}`);
            } else {
               history.push("/confirm-email");
            }
         }
      },
   });
}

export default useRedirectToFeedIfAuthorised;
