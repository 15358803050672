import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import useSignOut from "_utils/useSignOut";
import { useQueryParam, StringParam } from "use-query-params";
import { useDispatch } from "react-redux";
import { openLoadingAppModal } from "_redux/actions";
import { GET_CURRENT_USER, RESEND_CONFIRM_EMAIL } from "../_apollo/queries";

/**
 * Resend Confirm Email Page
 * @returns {JSX.Element} The Resend Confirm Email Page
 */
const ResendConfirmEmail = () => {
   const dispatch = useDispatch();
   const signOut = useSignOut();

   const [resendConfirmEmailRequest, { data, loading: loadingConfirm }] =
      useMutation(RESEND_CONFIRM_EMAIL);
   const [hash] = useQueryParam("hash", StringParam);
   const [redirectTo] = useQueryParam("redirectTo", StringParam);
   const history = useHistory();

   const [user, setUser] = useState(undefined);
   const { stopPolling, startPolling } = useQuery(GET_CURRENT_USER, {
      notifyOnNetworkStatusChange: true,
      /**
       * getCurrentUser onCompleted
       *
       * Store the user data in the state and redirect to the files page if the user email is verified
       * @param {object} dataUser Apollo data object.
       */
      onCompleted(dataUser) {
         setUser(dataUser?.currentUser);
         if (dataUser?.currentUser?.emailVerifiedAt) {
            if (redirectTo) {
               history.push(`/feed?redirectTo=${redirectTo}`);
            } else if (hash) {
               history.push(`/feed?hash=${hash}`);
            } else {
               history.push("/feed");
            }
         }
      },
   });
   useEffect(() => {
      dispatch(openLoadingAppModal(false));
      startPolling(10000);
      return () => stopPolling();
   }, []);

   return (
      <div
         style={{
            width: "100vw",
            height: "100vh",
         }}
      >
         <div
            style={{
               height: "52px",
               width: "100%",
               backgroundColor: "white",
               "-webkit-user-select": "none",
               "-webkit-app-region": "drag",
            }}
         />
         <div
            style={{
               width: "100%",
               height: "calc(100% - 52px)",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               position: "relative",
               background:
                  "radial-gradient(82.2% 100% at 50% 100%, #FFD645 0%, #FFBFBB 61.5%, #C8BFFF 100%)",
            }}
         >
            <div style={{ position: "absolute", top: "10%", left: "10%" }}>
               <ArrowOutwardIcon
                  sx={{
                     color: "white",
                     fontSize: "70px",
                     transform: "rotate(90deg)",
                  }}
               />
               <ArrowOutwardIcon
                  sx={{
                     color: "white",
                     fontSize: "70px",
                     transform: "rotate(90deg)",
                  }}
               />
               <ArrowOutwardIcon
                  sx={{
                     color: "white",
                     fontSize: "70px",
                     transform: "rotate(90deg)",
                  }}
               />
            </div>
            <div style={{ position: "absolute", bottom: "10%", right: "10%" }}>
               <ArrowOutwardIcon
                  sx={{
                     color: "white",
                     fontSize: "70px",
                     transform: "rotate(270deg)",
                  }}
               />
               <ArrowOutwardIcon
                  sx={{
                     color: "white",
                     fontSize: "70px",
                     transform: "rotate(270deg)",
                  }}
               />
               <ArrowOutwardIcon
                  sx={{
                     color: "white",
                     fontSize: "70px",
                     transform: "rotate(270deg)",
                  }}
               />
            </div>
            <div style={{ maxWidth: "558px" }}>
               <h3
                  style={{
                     fontWeight: 800,
                     fontSize: "76px",
                     lineHeight: "72px",
                     fontFamily: "dr-extrabold",
                     margin: "0 0 30px 0",
                  }}
               >
                  Verify your email
               </h3>
               <p
                  style={{
                     fontSize: "18px",
                     lineHeight: "24px",
                     margin: "0 0 40px 0",
                  }}
               >
                  We have sent you an email to{" "}
                  <span style={{ fontWeight: 700 }}>{user?.email}</span> with a
                  link to verify your account. If you don’t find it after a few
                  minutes, please check the spam folder.
               </p>
               <div style={{ display: "flex", gap: "16px" }}>
                  <Button
                     color="dark"
                     sx={{ color: "white" }}
                     variant="contained"
                     disabled={loadingConfirm || data}
                     onClick={async () => {
                        await resendConfirmEmailRequest();
                     }}
                  >
                     {data ? "Confirmation Sent" : "Resend Confirmation"}
                  </Button>
                  <Box ml={1}>
                     <Button
                        color="dark"
                        href="mailto:hello@aux.app"
                        variant="outlined"
                        target="_blank"
                     >
                        Contact Support
                     </Button>
                  </Box>
                  <Box ml={1}>
                     <Button
                        color="dark"
                        onClick={() => signOut()}
                        variant="outlined"
                     >
                        Sign Out
                     </Button>
                  </Box>
               </div>
            </div>
         </div>
      </div>
   );
};

export default ResendConfirmEmail;
