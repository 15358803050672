import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import HiringBadge from "_components/HiringBadge";
import api from "../../_utils/ghostContentApi";
import Footer from "../../_components/Footer";
import MetaTags from "../../_components/MetaTags";
import Header from "../../_components/Header/Header";
import BlogPostCard from "../../_components/BlogPostCard";
import EngageSignUpForm from "../../_components/EngageSignUpForm";

const useStyles = makeStyles((theme) => ({
   root: {
      height: "100%",
      width: "100%",
   },
   backgroundMain: {
      background: `linear-gradient(90deg, #FFDCC9 0%, rgba(255, 220, 201, 0) 100%),
      linear-gradient(180deg, #3EF1D1 0%, rgba(62, 241, 209, 0) 100%), 
       #6147FF`,
      padding: "0px 0 95px",
   },
   title: {
      textAlign: "center",
      fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
      maxWidth: "800px",
      marginLeft: "auto",
      marginRight: "auto",
      fontSize: theme.typography.big1.fontSize,
      lineHeight: "48px",
      padding: "80px 0 80px",
   },
   containerSpace: {
      paddingTop: "80px",
      paddingBottom: "64px",
   },
   containerPost: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit,  minmax(280px, 1fr))",
      gridGap: 40,
      marginTop: "40px",
   },
   centerBtn: {
      display: "flex",
      justifyContent: "center",
   },
}));

/**
 * Tag
 *
 * Blog tag landing page.
 * @returns {JSX.Element} Tag landing page component.
 */
const Tag = () => {
   const history = useHistory();
   const classes = useStyles();

   const { slug } = useParams();
   const [ghostTag, setGhostTag] = useState(undefined);
   const [postWithTag, setPostWithTag] = useState([]);
   const [paginationKey, setPaginationKey] = useState(1);

   /**
    * Fetch Tag
    * @param {object} page Ghost page object.
    */
   const fetchTag = (page) => {
      api.tags
         .read({ slug }, { include: "count.posts" })
         .then((tag) => {
            setGhostTag(tag);

            if (tag) {
               api.posts
                  .browse({
                     filter: `tags:[${slug}]`,
                     include: "tags,authors",
                     page,
                     limit: 9,
                  })
                  .then((posts) => {
                     setPaginationKey(posts.meta.pagination.next);
                     setPostWithTag(posts);
                  })
                  .catch((err) => {
                     throw new Error(err);
                  });
            } else {
               history.push("/404");
            }
         })
         .catch(() => {
            history.push("/404");
         });
   };

   useEffect(() => {
      fetchTag(paginationKey);
   }, []);

   return (
      <>
         {ghostTag && (
            <div className={classes.root}>
               <HiringBadge />
               <MetaTags
                  title={`${ghostTag.name} on Aux Connect`}
                  description={`Read posts tagged ${ghostTag.name} on the Aux Connect blog.`}
               />
               <div className={classes.backgroundMain}>
                  <Header />
                  <Container>
                     <h1 className={classes.title}>
                        {ghostTag?.name}
                        <br />
                        posts on Aux Connect
                     </h1>
                  </Container>
               </div>
               <Container className={classes.containerSpace}>
                  <div className={classes.containerPost}>
                     {postWithTag.map((post) => (
                        <BlogPostCard
                           key={`${postWithTag?.slug}-${post?.slug}-tag`}
                           post={post}
                        />
                     ))}
                  </div>
               </Container>
               <div className={classes.centerBtn}>
                  {paginationKey && (
                     <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => fetchTag(paginationKey)}
                     >
                        Load More
                     </Button>
                  )}
               </div>
               <EngageSignUpForm />
               <Footer />
            </div>
         )}
      </>
   );
};

export default Tag;
