import React, { useState } from "react";
import { ReactComponent as JoinIcon } from "_assets/icons/join-icon-black-24.svg";
import IconZip from "_assets/IconZip";

import { useHistory } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useUploadData } from "_utils/UploadDataContext";
import useStatsMutation from "_utils/useStatsMutation";
import { useMutation } from "@apollo/react-hooks";
import {
  ACCEPT_DECLINE_CONNECTION,
  ACCEPT_PROJECT_INVITE,
  DISMISS_NOTIFICATION,
  GET_CURRENT_USER,
  GET_NOTIFICATIONS,
  GET_PROJECTS,
} from "_apollo/queries";
import isElectronHook from "_utils/isElectron";
import { ChatBubble } from "@mui/icons-material";
import FolderWithSameName from "_components/Modal/FolderWithSameName";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { useUser } from "_utils/UserContext";
import formatBytes from "../../_utils/formatBytes";
import ProgressNotificationItem from "./ProgressNotificationItem";
import MultiButtonNotificationItem from "./MultiButtonNotificationItem";
import SingleButtonNotificationItem from "./SingleButtonNotificationItem";

const useStyles = makeStyles((theme: any) => ({
  notificationItem: {
    borderBottom: "1px solid #D9D9D9",
    padding: "10px 5px",
    width: "100%",
    "& *": {
      fontSize: theme.typography.medium.fontSize,
    },
  },
}));

/**
 * Notification
 * @param {object} props components props.
 * @param {Function} props.setNotifications function to set notifications in react useState.
 * @param {object} props.notification notification object.
 * @returns {React.ReactElement | null} Notification component.
 */
function Notification({ setNotifications, notification }) {
  const [user] = useUser();
  const { removeUpload, setFilesDownloadingState, setDownloadingZips } =
    useUploadData();
  const [showErrorAcceptInvite, setShowErrorAcceptInvite] = useState(null);

  const sendStatsMutation = useStatsMutation();
  const classes = useStyles();
  const history = useHistory();
  const isElectron = isElectronHook();

  /**
   * handleDismissNotifications
   *
   * Dismisses notifications and removes them from redux.
   * @param {Array} ids Array of ids of notifications to dismiss.
   */
  const handleDismissNotifications = (ids) => {
    dismissNotification({
      variables: {
        ids,
      },
    });
    setNotifications((prev) => prev.filter((n) => !ids.includes(n?.id)));
  };

  // TODO: move to its own file and expand?
  type Project = {
    id: string;
  };
  interface ProjectResponse {
    getProjects: Array<Project>;
  }
  type NotificationType = {
    id: string;
  };
  interface NotificationResponse {
    getNotifications: Array<NotificationType>;
  }

  const [acceptProjectInvite] = useMutation(ACCEPT_PROJECT_INVITE, {
    update(cache, { data }) {
      const newProject = data?.acceptProjectInvite;
      const projects: ProjectResponse = cache.readQuery({
        query: GET_PROJECTS,
      });

      if (newProject && projects?.getProjects) {
        cache.writeQuery({
          query: GET_PROJECTS,
          data: {
            getProjects: [...projects?.getProjects, newProject],
          },
        });
      }
    },
  });

  const [acceptDeclineConnection] = useMutation(ACCEPT_DECLINE_CONNECTION, {
    refetchQueries: [{ query: GET_CURRENT_USER }],
  });

  const [dismissNotification] = useMutation(DISMISS_NOTIFICATION, {
    /**
     * DISMISS_NOTIFICATION update
     * @param {object} cache apollo cache instance.
     * @param {object} param1 options update method.
     * @param {object} param1.data response query.
     */
    update(cache, { data }) {
      if (data?.dismissNotification) {
        const { getNotifications }: NotificationResponse = cache.readQuery({
          query: GET_NOTIFICATIONS,
        });
        cache.writeQuery({
          query: GET_NOTIFICATIONS,
          data: {
            getNotifications: getNotifications.filter(
              (n) =>
                !data?.dismissNotification?.find((nObj) => nObj?.id === n?.id)
            ),
          },
        });
      }
    },
  });

  if (notification?.typeName === "ConnectContact") {
    const senderProfile = notification?.body.split(" ")[0];
    return (
      <MultiButtonNotificationItem
        icon={<JoinIcon />}
        actionButtons={[
          {
            key: "action-button-notification-1",
            buttonText: "Accept",
            buttonClickHandler: () => {
              acceptDeclineConnection({
                variables: {
                  acceptValue: true,
                  connectionId: notification?.uniqueId,
                },
              });
              handleDismissNotifications([notification?.id]);
            },
          },
          {
            key: "action-button-notification-2",
            buttonText: "Decline",
            buttonClickHandler: () => {
              acceptDeclineConnection({
                variables: {
                  acceptValue: false,
                  connectionId: notification?.uniqueId,
                },
              });
              handleDismissNotifications([notification?.id]);
            },
          },
        ]}
        body={
          <Box display="flex">
            <Typography color="primary">{senderProfile}</Typography>
            &nbsp;invited you to connect
          </Box>
        }
        img={notification?.img}
        classes={classes}
        paused={notification?.paused}
        failed={notification?.failed}
      />
    );
  }

  if (notification?.typeName === "ContactJoined") {
    return (
      <MultiButtonNotificationItem
        actionButtons={[
          {
            buttonText: "Message",
            buttonClickHandler: () => {
              handleDismissNotifications([notification?.id]);
            },
          },
          {
            buttonText: "Decline",
            buttonClickHandler: () =>
              handleDismissNotifications([notification?.id]),
          },
        ]}
        body={notification?.body}
        img={notification?.img}
        classes={classes}
      />
    );
  }

  if (notification?.typeName === "NewMessage") {
    return (
      <SingleButtonNotificationItem
        icon={<ChatBubble sx={{ fontSize: "20px !important" }} />}
        body={notification?.body}
        img={notification?.img}
        buttonObj={{
          buttonText: "View",
          buttonClickHandler: () => {
            history.push({
              pathname: `${notification?.actionUrl.split("?messageUri=")[0]}`,
              state: {
                messageUri: notification?.actionUrl.split("messageUri=")[1],
              },
            });
            handleDismissNotifications([notification?.id]);
          },
        }}
        classes={classes}
      />
    );
  }
  if (notification?.typeName === "ZipDownloaded") {
    return (
      <Grid container alignItems="center" className={classes.notificationItem}>
        <div style={{ display: "flex", width: "500px" }}>
          <Box mr="8px" mt="3px">
            <IconZip />
          </Box>
          <Typography noWrap mr="12px" marginTop="4px" display="flex">
            <a
              style={{
                textDecoration: "none",
                color: "#6147FF",
                maxWidth: "300px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                display: "inline-block",
              }}
              href={notification.actionUrl}
            >
              {notification.title}
            </a>
            &nbsp;
            {notification?.body}
          </Typography>
        </div>

        <Button
          color="primary"
          variant="text"
          onClick={() => window.open(notification.actionUrl)}
        >
          Download
        </Button>
      </Grid>
    );
  }
  if (
    notification?.typeName === "MasterFileDownloaded" ||
    notification?.typeName === "SampleFileDownloaded"
  ) {
    const downloadCTA = () => {
      if (isElectron) {
        return window?.electronAPI?.isWindows ? "Explorer" : "Finder";
      }
      return "My Aux Files";
    };

    return (
      <Grid container alignItems="center" className={classes.notificationItem}>
        <div style={{ display: "flex", width: "500px", alignItems: "center" }}>
          <Box mr="8px" mt="3px">
            <AutoFixHighIcon
              sx={{
                fill: "#6147FF",
              }}
            />
          </Box>
          <Typography noWrap mr="12px" marginTop="4px" display="flex">
            {notification?.body}
          </Typography>
        </div>

        <Button
          color="primary"
          variant="text"
          onClick={() => {
            if (isElectron) {
              window?.electronAPI?.showFolderInFinderOrExplorer(
                `${window?.electronAPI?.getMyAuxFilesFolder(
                  user?.profile?.id
                )}/${notification?.file?.path}`
              );
            } else {
              history.push(`/files/${notification?.file?.uri}`);
            }
          }}
        >
          Show in {downloadCTA()}
        </Button>
      </Grid>
    );
  }

  if (notification?.typeName === "ProjectInvite") {
    const senderName = notification?.body.split(" ")[0];
    return (
      <MultiButtonNotificationItem
        icon={<JoinIcon />}
        actionButtons={[
          {
            buttonText: "Accept",
            buttonClickHandler: () => {
              acceptProjectInvite({
                variables: {
                  projectId: notification?.uniqueId,
                },
                onError(error) {
                  if (
                    error?.graphQLErrors[0]?.message ===
                    "Error: You already have a folder with that name."
                  ) {
                    setShowErrorAcceptInvite(notification?.project?.title);
                  }
                },
                onCompleted(data) {
                  const acceptProjectInviteData = data.acceptProjectInvite;
                  sendStatsMutation({
                    statsId: "ProjectUserAccepted",
                    projectId: acceptProjectInviteData.id,
                  });
                  setNotifications((prev) =>
                    prev.filter((n) => notification?.id !== n?.id)
                  );
                  // Redirect to the project you've just accepted.
                  history.push(`/projects/${acceptProjectInviteData?.uri}`);
                },
              });
            },
          },
          {
            buttonText: "Decline",
            buttonClickHandler: () =>
              handleDismissNotifications([notification?.id]),
          },
        ]}
        body={
          <Box display="flex">
            <FolderWithSameName
              open={!!showErrorAcceptInvite}
              handleClose={() => setShowErrorAcceptInvite(null)}
              title={showErrorAcceptInvite}
            />
            {senderName} invited you to&nbsp;
            <Typography color="primary">
              {notification?.project?.title}
            </Typography>
          </Box>
        }
        img={notification?.img}
        classes={classes}
      />
    );
  }

  if (notification?.typeName === "FailedUpload") {
    return (
      <ProgressNotificationItem
        title={notification?.title}
        percentage={100}
        classes={classes}
        failed
        label="Upload has failed"
        acceptHandler={() => handleDismissNotifications([notification?.id])}
      />
    );
  }

  if (notification?.typeName === "UploadingFile") {
    // Hide the notification at 100% as it will be replaced with the version of the notification stored in the database.
    if (notification?.file?.plainPercentage === 100) {
      return (
        notification?.file && (
          <ProgressNotificationItem
            title={notification?.title}
            percentage={100}
            classes={classes}
            label={`100% of ${formatBytes(notification?.file?.size)}`}
            acceptHandler={() => {
              removeUpload({
                conditionCallback: (file) => {
                  return (
                    `${notification?.file?.lastModified}-${notification?.hash}` ===
                    `${file?.lastModified}-${file?.hash}`
                  );
                },
              });
            }}
          />
        )
      );
    }
    return (
      <ProgressNotificationItem
        title={notification?.title}
        label={notification?.file.percentage}
        cancelHandler={() => {
          removeUpload({
            conditionCallback: (file) => {
              return (
                `${notification?.file?.lastModified}-${notification?.hash}` ===
                `${file?.lastModified}-${file?.hash}`
              );
            },
          });
        }}
        percentage={notification?.file?.plainPercentage}
        classes={classes}
        failed={notification?.file?.loading === "failed"}
      />
    );
  }
  if (notification?.typeName === "DownloadingZipFile") {
    return (
      <ProgressNotificationItem
        title={
          <>
            <Box mt="3px" mb="-2px">
              <IconZip />
            </Box>
            <Typography sx={{ marginTop: "-2px" }} noWrap>
              {notification?.title}
            </Typography>
          </>
        }
        cancelHandler={() => {
          setDownloadingZips((prev) =>
            prev.filter((n) => {
              if (n.name !== notification.file.uri) {
                return true;
              }
              n.cancelRequest.abort();
              return false;
            })
          );
        }}
        classes={classes}
      />
    );
  }
  if (notification?.typeName === "DownloadingFile") {
    return (
      <ProgressNotificationItem
        title={notification?.title}
        label={notification?.percentage}
        cancelHandler={() => {
          notification?.file?.cancelRequest?.abort();
          setFilesDownloadingState((prev) =>
            prev.filter((n) => n?.path !== notification?.path)
          );
          setNotifications((prev) =>
            prev.filter(
              (n) =>
                n?.path !== notification?.path &&
                n?.typeName !== notification?.typeName
            )
          );
        }}
        percentage={notification?.progress}
        classes={classes}
        failed={notification?.file?.loading === "failed"}
      />
    );
  }
  if (notification?.typeName === "FileDownloaded") {
    return (
      <ProgressNotificationItem
        title={notification?.title}
        label={notification?.percentage}
        acceptHandler={() => {
          setFilesDownloadingState((prev) =>
            prev.filter((n) => n?.path !== notification?.path)
          );
          setNotifications((prev) =>
            prev.filter(
              (n) =>
                `${n?.path}-${n?.typeName}` !==
                `${notification?.path}-${notification?.typeName}`
            )
          );
        }}
        percentage={notification?.progress}
        classes={classes}
        failed={notification?.file?.loading === "failed"}
      />
    );
  }

  return null;
}

export default Notification;
