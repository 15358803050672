import { useEffect, useMemo, useRef } from "react";
import axios from "axios";
import { useMutation } from "@apollo/react-hooks";
import { getMimeType, getExtension } from "_utils/fileTypeUtil/fileTypeUtil";
import useStatsMutation from "_utils/useStatsMutation";
import { useUploadData } from "_utils/UploadDataContext";
import axiosRetry from "axios-retry";
import cargoQueue from "async/cargoQueue";
import * as Sentry from "@sentry/react";
import { useUser } from "_utils/UserContext";

import formatBytes from "../../_utils/formatBytes";
import {
   REPLACE_FILE,
   UPLOAD_FILE,
   GET_FILES,
   GET_CURRENT_USER,
} from "../../_apollo/queries";
import {
   getMultiPartPolicy,
   abortMultipartUpload,
   completeMultipartUpload,
} from "../../_utils/policyRequest";
import {
   MAX_CHUNK_SIZE,
   MAX_SIMULTANEOUS_CHUNKS,
   MAX_SIMULTANEOUS_UPLOADS,
} from "../../_constants/GlobalVariables";
import {
   hashFileFromBrowserFileObject,
   hashFileFromDisk,
} from "../../_utils/hashFile";
import checkHashFile from "../../_utils/checkHashFile";

/**
 * File Uploader
 *
 * Background file uploader component that take upload requests
 * via Redux and completes the async to the rest of the app.
 *
 * TODO: this is a good candidate to refactor to use React Context.
 * NOTE: notifications have become convoluted because we used to have grouped notifications. Now with single notifications we can strip down the code to work much more like the download notifications have been implemented.
 * @returns {null} void
 */
const useFileUploader = () => {
   const sendStatsMutation = useStatsMutation();
   const [user, , , , , folderUri] = useUser();
   const { filesUploading, updateProgress, uploadFiles } = useUploadData();

   const countUpload = useMemo(
      () => filesUploading.filter((f) => f.plainPercentage !== 100).length,
      [filesUploading]
   );
   useEffect(() => {
      /**
       * handler
       *
       * Handles beforeunload event warning the user their files will be lost if this component is de-rendered.
       * @param {object} e beforeunload event object.
       */
      const handler = (e) => {
         if (countUpload > 0) {
            e.returnValue = "Leaving Aux will cancel your active uploads.";
         }
      };

      window.addEventListener("beforeunload", handler);
      return () => window.removeEventListener("beforeunload", handler);
   }, [countUpload]);

   const uploadsCargoQueueRef = useRef(null);
   const chunksCargoQueueRef = useRef(null);

   useEffect(() => {
      uploadsCargoQueueRef.current = cargoQueue(
         (tasks, callback) => {
            Promise.all(tasks.map((task) => task())).then(() => callback());
         },
         MAX_SIMULTANEOUS_UPLOADS, // One worker per allowed MAX_SIMULTANEOUS_UPLOADS, tasks will be processed as soon as a worker becomes available.
         1 // Payload of 1 task per worker run.
      );
      chunksCargoQueueRef.current = cargoQueue(
         (tasks, callback) => {
            Promise.all(tasks.map((task) => task())).then(() => callback());
         },
         MAX_SIMULTANEOUS_CHUNKS, // One worker per allowed MAX_SIMULTANEOUS_CHUNKS, tasks will be processed as soon as a worker becomes available.
         1 // Payload of 1 task per worker run.
      );
   }, []);

   const [replaceFileMutation] = useMutation(REPLACE_FILE, {
      /**
       * replaceFileMutation onCompleted
       * starts polling for file processed.
       * @param {object} data contains uploaded file data
       */
      // onCompleted(data) {
      //    // const file = data?.uploadFile;
      //    // // // TODO: Sync code is here rather than in Sync Context so is hard to see what happens and to maintain. Refactor this.
      //    // // if (
      //    // //    file?.conflictedMdate &&
      //    // //    file?.conflictedPath &&
      //    // //    file?.conflictedUrl &&
      //    // //    isElectron
      //    // // ) {
      //    // //    window?.electronAPI?.downloadFileToLocalPath(
      //    // //       file.conflictedUrl,
      //    // //       file.conflictedPath,
      //    // //       file.conflictedMdate
      //    // //    );
      //    // //    window?.electronAPI?.downloadFileToLocalPath(
      //    // //       file.signedUrl,
      //    // //       file.watchedPath,
      //    // //       file.modifiedAt
      //    // //    );
      //    // // }
      //    // // Only redirect to the new version if you are on the current project (to avoid background sync redirecting you to versions.)
      //    // if (file?.project?.uri) {
      //    //    if (
      //    //       window.location.href.includes(
      //    //          `/projects/${file.project.uri}/file/`
      //    //       )
      //    //    ) {
      //    //       history.push(
      //    //          `/projects/${file.project.uri}/file/${file.uri}/daw`
      //    //       );
      //    //    }
      //    // }
      // },
      /**
       * replaceFileMutation onCompleted.
       * @param {object} cache cache instance.
       * @param {object} data response from replaceFileMutation.
       */
      // update(cache, data) {
      //    // const newFile = data?.data?.uploadFile;
      //    // const currentFolderUri = newFile?.folder?.uri;
      //    // const currentFolderId = newFile?.folder?.id;
      //    // const currentProjectFolderId = newFile?.project?.folderId;
      //    // const variables = {};
      //    // // currentFolderUri could be undefined in root files, the uri value cached is set to null for this ones.
      //    // variables.uri = currentFolderUri || null;
      //    // const existingFiles = cache.readQuery({
      //    //    variables,
      //    //    query: GET_FILES,
      //    // });
      //    // // Check file added exists, if it does do nothing
      //    // const fileExists = existingFiles?.getFiles?.files?.some(
      //    //    (a) => a?.id === newFile?.id
      //    // );
      //    // if (
      //    //    !fileExists &&
      //    //    (folderUri === currentFolderUri ||
      //    //       currentFolderId === currentProjectFolderId) // If file is uploaded in root of a project(we do not have a folderUri from the params)
      //    // ) {
      //    //    cache.writeQuery({
      //    //       variables,
      //    //       query: GET_FILES,
      //    //       data: {
      //    //          getFiles: {
      //    //             files: [...existingFiles?.getFiles.files, newFile],
      //    //             pageInfo: {
      //    //                page: existingFiles?.getFiles?.pageInfo?.page,
      //    //                totalPages:
      //    //                   existingFiles?.getFiles?.pageInfo?.totalPages,
      //    //             },
      //    //          },
      //    //       },
      //    //    });
      //    // }
      // },
      refetchQueries: [{ query: GET_CURRENT_USER }],
   });

   const [uploadFileMutation] = useMutation(UPLOAD_FILE, {
      // /**
      //  * uploadFileMutation onCompleted
      //  * starts polling for file processed.
      //  * @param {object} data contains uploaded file data
      //  */
      // onCompleted(data) {
      //    const file = data?.uploadFile?.file;
      //    const folder = data?.uploadFile?.folder;
      // },
      /**
       * uploadFileMutation onCompleted.
       * @param {object} cache cache instance.
       * @param {object} data response from uploadFileMutation.
       */
      update(cache, data) {
         const newFile = data?.data?.uploadFile?.file;

         const currentFolderUri = newFile?.folder?.uri;
         const currentFolderId = newFile?.folder?.id;
         const currentProjectFolderId = newFile?.project?.folderId;

         const variables = {
            trashed: false,
         };
         // currentFolderUri could be undefined in root files, the uri value cached is set to null for this ones.
         variables.uri = currentFolderUri;

         const existingFiles = cache.readQuery({
            variables,
            query: GET_FILES,
         });

         // Check file added exists, if it does do nothing
         const fileExists = existingFiles?.getFiles?.files?.some(
            (a) => a?.id === newFile?.id
         );

         if (
            !fileExists &&
            (folderUri === currentFolderUri ||
               currentFolderId === currentProjectFolderId) // If file is uploaded in root of a project(we do not have a folderUri from the params)
         ) {
            cache.writeQuery({
               variables,
               query: GET_FILES,
               data: {
                  getFiles: {
                     files: [...(existingFiles?.getFiles.files || []), newFile],
                     pageInfo: {
                        page: existingFiles?.getFiles?.pageInfo?.page || 1,
                        totalPages:
                           existingFiles?.getFiles?.pageInfo?.totalPages || 1,
                     },
                  },
               },
            });
         }
      },
      refetchQueries: [{ query: GET_CURRENT_USER }],
   });

   /**
    * uploadSuccess
    *
    * Success callback.
    * @param {object} file file that was uploaded.
    */
   const uploadSuccess = async (file) => {
      const mutableEl = file;
      mutableEl.percentage = file?.size
         ? `100% of ${formatBytes(file.size)}`
         : "100%";
      mutableEl.plainPercentage = 100;
      mutableEl.loading = "success";
      updateProgress(mutableEl);

      // Call success callback if present.
      if (file.callback) {
         file.callback(file);
      }
      // TODO: errorCallback

      // TODO: TODO: TODO: Update this to use the path instead of these custom properties.
      // uploadIntoMasterFolder: !!file?.uploadIntoMasterFolder,
      // uploadIntoSoundsFolder: !!file?.uploadIntoSoundsFolder,

      // When uploading files to use in the Sounds or Mastering sections, we can set the path automatically to the appropriate folder.
      let updatedPath = file?.path;
      if (file?.uploadIntoMasterFolder) {
         updatedPath = `My Masters/${file?.path}`;
      }
      if (file?.uploadIntoSoundsFolder) {
         updatedPath = `My Sounds/Recordings/${file?.path}`;
      }

      const fileObj = {
         name: file.fileKey,
         originalName: file.name,
         uri: file?.uri,
         mimeType: file?.mimeType || file?.type || getMimeType(file)[0],
         size: file.fileSize || file.size,
         formattedSize: file.formattedSize,
         path: updatedPath,
         modifiedAt: file?.modifiedAt || file?.lastModifiedDate,
         isBouncedown: file?.isBouncedown,
         isStem: file?.isStem,
         dawFileId: file?.dawFileId,
         dawFolderId: file?.dawFolderId,
         newVersionFromFileId: file?.newVersionFromFileId,
         color: file?.color,
         hash: file?.hash,
         uploadIntoMasterFolder: !!file?.uploadIntoMasterFolder,
         uploadIntoSoundsFolder: !!file?.uploadIntoSoundsFolder,
      };

      if (file?.overwrite) {
         // Replace file if overwrite is set on the file by the sync code.
         await replaceFileMutation({
            variables: {
               file: fileObj,
               folderId: file?.folderId,
            },
         });
      } else {
         // Upload a new file.
         const response = await uploadFileMutation({
            variables: {
               file: fileObj,
               folderId: file?.folderId,
               isSyncFile: file?.isSyncFile,
            },
         });
         const fileResponse = response?.data?.uploadFile?.file;
         if (fileResponse && file?.uploadCompleteCallback) {
            file.uploadCompleteCallback(fileResponse);
         }
      }
   };

   /**
    * Upload Failed
    *
    * Upload failed callback.
    * @param {object} file file that was not uploaded.
    * @param {string} uploadId Upload id for the multipart upload.
    * @param {Error} err Error object.
    * @param {Error} err.message Error message.
    * @returns {object} file object for failed upload.
    */
   const uploadFailed = async (file, uploadId, err) => {
      if (uploadId) {
         await abortMultipartUpload(file.fileKey, file, uploadId);
      }
      if (err?.message !== "Upload cancelled") {
         sendStatsMutation({
            statsId: "FailedUpload",
            metadata: JSON.stringify({ fileName: file.name }),
         });
         const mutatedFile = file;
         mutatedFile.failed = true;
         updateProgress(mutatedFile);
      }
   };

   /**
    * Upload Parts
    *
    * Chunks files and start uploading each chunk.
    * @param {object} file File that is being chunked and uploaded.
    * @param {string} urls list of presigned uri for each part.
    * @param {number} uploadId The S3 upload id used to complete or fail the upload.
    * @returns {Array} Array of parts that are sent to complete the multipart upload process
    */
   async function uploadParts(file, urls, uploadId) {
      const request = axios.create();
      delete axios.defaults.headers.put["Content-Type"];
      // Retry each segment 3 times with an delay of 5, 10, then 15 seconds.
      axiosRetry(request, {
         retries: 3,
         retryDelay: (retryCount) => {
            return retryCount * 5000;
         },
      });

      const progressLoaded = {};
      // Array in which we will store the response of uploading a chunk
      const resParts = [];
      const keys = Object.keys(urls);
      // File descriptor needed to read specific chunks of a file.
      let fileDescriptor;
      if (!(file instanceof File)) {
         const userMyAuxFilesPath = window?.electronAPI?.getMyAuxFilesFolder(
            user?.profile?.id
         );
         const fullPathOfFile = window?.electronAPI?.updatePathCrossPlatform(
            `${userMyAuxFilesPath}/${file.path}`
         );
         fileDescriptor = window?.electronAPI?.fs?.openSync(
            fullPathOfFile,
            "r"
         );
      }
      await keys.forEach((key, i) => {
         uploadsCargoQueueRef.current.push(async () => {
            try {
               const config = {
                  cancelToken: file.cancelToken.token,
                  onUploadProgress: (progressEvent) => {
                     // When the upload progress event fires, update redux so we can show the progress bar for files.
                     progressLoaded[i] = progressEvent.loaded;
                     const sum = Object.values(progressLoaded).reduce(
                        (prev, curr) => {
                           return prev + curr;
                        },
                        0
                     );

                     // 5.53 MB of 12.2 MB .56%
                     const percentCompleted = Math.round(
                        (sum / file.size) * 100
                     );
                     const newData = file;
                     newData.percentage = `${percentCompleted}% of ${formatBytes(
                        file.size
                     )}`;
                     newData.plainPercentage = percentCompleted;
                     newData.loading = "loading";
                     newData.createdAt = Date.now();
                     updateProgress(newData);
                  },
               };

               const indexStr = keys[i];
               const index = Number(indexStr);
               const start = index * MAX_CHUNK_SIZE;
               const end = (index + 1) * MAX_CHUNK_SIZE;
               let data;

               if (file instanceof File) {
                  // On Web, create the chunks from the File object
                  const blob =
                     index < keys.length
                        ? file.slice(start, end)
                        : file.slice(start);

                  // Upload the chunk and store the response in an array.
                  // eslint-disable-next-line no-await-in-loop
                  data = await request.put(urls[index], blob, config);
               } else {
                  // On Desktop: Read each chunk from disk one by one using fs.readFileSync via preload.js.
                  const buffer = window?.electronAPI?.readChunkSync(
                     fileDescriptor,
                     index
                  );
                  const blob = new Blob([buffer]);
                  // Upload the chunk and store the response in an array.
                  // eslint-disable-next-line no-await-in-loop
                  data = await request.put(urls[index], blob, config);
               }

               // Push the part into the array to send to the complete endpoint which combines the parts.
               resParts.push({ PartNumber: index + 1, data });

               // If this was the final part, complete the upload.
               if (resParts.length === urls.length) {
                  // Close file once you are finished with the file.
                  if (!(file instanceof File)) {
                     window?.electronAPI?.fs?.closeSync(fileDescriptor);
                  }
                  const parts = resParts
                     .sort((a, b) => a.PartNumber - b.PartNumber) // Parts MUST be sent in order to the completeMultipartUpload request.
                     .map((part) => {
                        return {
                           ETag: part.data.headers.etag,
                           PartNumber: part.PartNumber,
                        };
                     });
                  await completeMultipartUpload(file.fileKey, uploadId, parts);

                  await uploadSuccess(file);
               }
            } catch (error) {
               // Close file once you are finished with the file.
               try {
                  if (!(file instanceof File) && fileDescriptor) {
                     window?.electronAPI?.fs?.closeSync(fileDescriptor);
                  }
               } catch (closeSyncError) {
                  Sentry.captureException(closeSyncError);
               }

               await uploadFailed(file, uploadId, error);
            }
         });
      });
   }

   /**
    * multipartParallelUpload
    *
    * Calls several functions to upload a file in multiple parts.
    * @param {string} key s3 key for the file that is being uploaded.
    * @param {object} file file that is being uploaded
    */
   const multipartParallelUpload = async (key, file) => {
      let uploadId;
      try {
         const maxParts = Math.ceil(file.size / MAX_CHUNK_SIZE);
         const { uploadId: uId, signedUrls } = await getMultiPartPolicy(
            key,
            file,
            maxParts
         );
         uploadId = uId;

         // Upload parts inside the chunks queue and complete the upload when all parts are uploaded.
         await uploadParts(file, signedUrls, uploadId);
      } catch (error) {
         await uploadFailed(file, uploadId, error);
      }
   };

   /**
    * dataFetchRequest
    *
    * The default function of the hook called to start a file upload.
    *
    * Dispatches redux action to update filesUploading.
    * Adds cancel axios cancel token to objects and starts multipart upload.
    * @param {Array} trackFileDrop Array of files that will be uploaded to s3.
    * @param {Function} callback Callback function to run after files have been uploaded.
    */
   const dataFetchRequest = async (trackFileDrop, callback) => {
      const filterZeroByteFiles = trackFileDrop.filter(
         (file) =>
            (file?.size > 0 || file?.fileSize > 0) && file?.name !== ".DS_Store"
      );
      // Send file upload data to react context for notifications.
      uploadFiles(filterZeroByteFiles);

      // Check the hash.
      await Promise.all(
         filterZeroByteFiles.map(async (file) => {
            try {
               const mutatedFile = file;
               let hash;
               if (file instanceof File) {
                  hash = await hashFileFromBrowserFileObject(file);
               } else {
                  hash = await hashFileFromDisk(file, user?.profile?.id);
               }
               mutatedFile.fileKey = `files/${
                  user.profile.id
               }/${hash}${getExtension(file)}`;

               // Check with the backend, if the hash matches then immediately mark as successful.
               const fileObject = await checkHashFile(hash);
               mutatedFile.hash = hash;
               if (fileObject) {
                  mutatedFile.skipUploadChunks = true;
                  await uploadSuccess(mutatedFile);
               }
            } catch (error) {
               // eslint-disable-next-line no-console
               console.error("🚀 ~ Error hashing the file.", error, file);
               Sentry.captureException(error);
            }
         })
      );

      // Because we batch uploads, if the user cancels all uploads we must also cancel any uploads that have not started yet.
      const cancelBeforeStart = [];
      // Add the custom cancel before start function to files.
      filterZeroByteFiles.map((file) => {
         const mutatedFile = file;
         mutatedFile.cancelBeforeStart = () => cancelBeforeStart.push(file.key);
         return mutatedFile;
      });

      // Map upload functions into an array to be queued and processed as a batch.
      await filterZeroByteFiles.forEach((singleFile) => {
         if (!singleFile.skipUploadChunks) {
            uploadsCargoQueueRef.current.push(async () => {
               // If the user cancelled this file, skip.
               if (cancelBeforeStart.includes(singleFile.key)) {
                  return;
               }

               const mutatedSingleFile = singleFile;
               mutatedSingleFile.cancelToken = axios.CancelToken.source();
               if (!mutatedSingleFile?.fileSize) {
                  mutatedSingleFile.fileSize = mutatedSingleFile?.size;
                  // For files missing when size key, they may be created by the fs module
               } else if (!mutatedSingleFile?.size) {
                  mutatedSingleFile.size = mutatedSingleFile?.fileSize;
               }

               // All files, regardless of size, run through the same chunked upload code.
               // eslint-disable-next-line no-await-in-loop
               await multipartParallelUpload(
                  mutatedSingleFile.fileKey,
                  mutatedSingleFile
               );
               if (callback) {
                  callback();
               }
            });
         }
      });
   };
   return dataFetchRequest;
};

export default useFileUploader;
