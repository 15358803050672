import React from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import PublicPrivateLayoutSwitcher from "_layouts/PublicPrivateLayoutSwitcher";
import MetaTags from "_components/MetaTags";

/**
 * Not Found Page (404)
 * @returns {Element} Not found page.
 */
const NotFoundPage = () => {
   return (
      <PublicPrivateLayoutSwitcher>
         <MetaTags
            title="Page Not Found"
            description="Backup, sync and version your music projects. Collaborate, connect and keep growing. No walls, no gatekeepers, just open music."
         />
         <div style={{ width: "100%", paddingLeft: 16 }}>
            <h2>Whoops</h2>
            <div>We can&apos;t find the page you&apos;re looking for.</div>
            <Button
               style={{ marginTop: 16 }}
               size="small"
               color="primary"
               variant="contained"
               component={Link}
               to="/feed"
            >
               Go Home
            </Button>
         </div>
      </PublicPrivateLayoutSwitcher>
   );
};

export default NotFoundPage;
