import appStorage from "_utils/appStorage";
import { clearCache } from "_apollo/queries";
import store from "_redux/store";
import { LOG_OUT_USER } from "_redux/actionTypes";
import { useHistory } from "react-router-dom";

/**
 * Sign Out
 *
 * Remove all user local storage data in order to sign out.
 * @returns {Function} signOut
 */
const useSignOut = () => {
   const history = useHistory();

   const signOut = async () => {
      // Delete Cookies
      appStorage.removeValue({ key: "auxJWT" });
      appStorage.removeValue({ key: "auxSelectedProfileURI" });
      appStorage.removeValue({ key: "profileId" });
      appStorage.removeValue({ key: "auxDeviceID" });
      appStorage.removeValue({ key: "auxFolderTree" });

      // Clear redux store
      store.dispatch({ type: LOG_OUT_USER });
      // Clear the Apollo store of all user data
      await clearCache();
      // Redirect to the signin page
      history.push("/signin");
   };
   return signOut;
};

export default useSignOut;
