import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";

// Apollo
import Button from "@mui/material/Button";
import DownloadAppButton from "_components/DownloadAppButton";
import FarOut from "_assets/Marketing/FeaturedIn/far-out.svg";
import Dt from "_assets/Marketing/FeaturedIn/dt.svg";
import MusicAlly from "_assets/Marketing/FeaturedIn/music-ally.svg";
import Record from "_assets/Marketing/FeaturedIn/record.svg";
import Imi from "_assets/Marketing/FeaturedIn/imi.svg";
import Square from "_assets/Marketing/FeaturedIn/square.svg";
import { Link } from "react-router-dom";
import Header from "_components/Header/Header";
import AiHomepageSection from "_components/AiHomepageSection";

const PurpleButton = withStyles(() => ({
  root: {
    color: "black",
    backgroundColor: "#9D75FF",
    "&:hover": {
      backgroundColor: "#9D75FF",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
}))(Button as any);

const useStyles = makeStyles((theme: any) => ({
  sticky: {
    position: "sticky",
    zIndex: 4,
    bottom: "0%",
    left: "0%",
    right: "0%",
  },
  title: {
    margin: "0px",
    lineHeight: "95%",
    paddingLeft: "2rem",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  secondTitle: {
    margin: "0px",
    lineHeight: "95%",
    paddingLeft: "2rem",
    textShadow: `0px 4px 4px rgba(0, 0, 0, ${
      navigator.userAgent.match(/safari/i) &&
      !navigator.userAgent.match(/chrome/i)
        ? 0.04
        : 0.25
    })`, // Fix for a Safari (specifically iOS) where text would have a 3D style effect.
    "-webkit-font-smoothing": "subpixel-antialiased",
  },
  absolute: {
    position: "absolute",
    top: "0%",
    bottom: "0%",
    left: "0%",
    right: "0%",
  },
  mainContainer: {
    maxHeight: "671px",
    height: "671px",
    transform: `translate(0px, 671px)`,
    boxSizing: "border-box",
    width: "100%",
  },
  mainContainerFixed: {
    position: "fixed",
    maxHeight: "671px",
    transform: `translate(0px, 0px)`,
    boxSizing: "border-box",
    width: "100%",
    top: "0%",
    bottom: "0%",
    left: "0%",
    right: "0%",
  },
  wrapper: {
    overflow: "hidden",
    position: "absolute",
    top: "0%",
    bottom: "0%",
    left: "0%",
    right: "0%",
    zIndex: 2,
  },
  videoCover: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    "& video": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      display: "block",
    },
  },
  flexCenterColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  backup: {
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },
  backupText: {
    marginTop: "36px",
    marginBottom: "24px",
    color: "#B3B3B3",
    fontSize: "18px",
    lineHeight: "145%",
  },
  btnContainer: {
    display: "flex",
    gap: "16px",
    marginBottom: "52px",
    "& button,a": {
      flex: 1,
    },
  },
  linearGradient1: {
    background: "linear-gradient(180deg, #111 0%, #000 100%)",
  },
  gridLogos: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    alignItems: "end",
    rowGap: "24px",
    justifyItems: "center",
    padding: "72px 40px 42px",
  },
}));

/**
 * Home page main section.
 * @returns {JSX.Element} The home page main section.
 */
function HomePageMainSectionMobile() {
  const classes = useStyles();

  /**
   * Categorizes a given number into groups based on a rule.
   *
   * If the number is less than 4, it will be placed in the first group (group 1).
   * Otherwise, the number will be placed in the group calculated using the formula:
   * group = Math.ceil((number - 1) / 4)
   *
   * @param {number} number - The number to be categorized.
   * @returns {number} The group number to which the input number belongs.
   */
  function categorizeNumbers(number) {
    if (number < 4) {
      return 1;
    }
    return Math.ceil((number - 1) / 4);
  }

  /**
   * Multiplies a given number by two-thirds (2/3).
   *
   * @param {number} number - The number to be multiplied.
   * @returns {number} The result of multiplying the input number by two-thirds (2/3).
   */
  function multiplyByTwoThirds(number) {
    return number * (2 / 3);
  }

  const [verticalMargin, setVerticalMargin] = useState(0);
  const [horizontalMargin, setHorizontalMargin] = useState(0);
  const [scaleVideo, setScaleVideo] = useState(0);
  const [pxFromTop, setPxFromTop] = useState(0);

  const setDimensions = () => {
    const pixelsToTop = document.documentElement.scrollTop;
    setPxFromTop(pixelsToTop);
    const numberByFour = categorizeNumbers(pixelsToTop);
    if (numberByFour <= 72) {
      setVerticalMargin(numberByFour);
      const numberByTwoThirds = multiplyByTwoThirds(numberByFour);
      if (numberByTwoThirds > 20) {
        setHorizontalMargin(20);
      } else {
        setHorizontalMargin(numberByTwoThirds);
      }
      setScaleVideo((pixelsToTop * 3) / 10000);
    } else {
      setVerticalMargin(72);
      setHorizontalMargin(20);
      setScaleVideo(0.0864);
    }
  };

  useEffect(() => {
    setDimensions();
    window.addEventListener("scroll", () => {
      setDimensions();
    });
  }, []);

  return (
    <div style={{ backgroundColor: "#111111" }}>
      <div
        style={{
          position: "relative",
          height: `calc(100vh + ${1000 - verticalMargin * 2}px)`,
        }}
      >
        <div style={{ zIndex: 10, position: "fixed" }}>
          <Header whiteText showDownloadButton />
        </div>
        <div
          className={classes.sticky}
          style={{
            top: `${80 + verticalMargin}px`,
          }}
        >
          <h1
            className={classes.title}
            style={{
              fontSize: `${52 - (52 * scaleVideo) / 1.5}px`,
              maxWidth: "335px",
              fontWeight: 300,
              color: "#F2F2F2",
              fontFamily: "dr-light, Arial, Helvetica, sans-serif",
            }}
          >
            Next <br /> generation
          </h1>
          <h1
            className={classes.secondTitle}
            style={{
              fontSize: `${52 - (52 * scaleVideo) / 1.5}px`,
              color: "#F2F2F2",
              fontWeight: 800,
              fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
            }}
          >
            Music <br /> making
          </h1>
        </div>
        <div className={classes.absolute}>
          <div
            className={
              pxFromTop < 671
                ? classes.mainContainerFixed
                : classes.mainContainer
            }
          >
            <div
              className={classes.wrapper}
              style={{
                margin: `${verticalMargin}px ${horizontalMargin}px`,
                borderRadius: `${verticalMargin <= 16 ? verticalMargin : 16}px`,
                height: `calc(100vh - ${verticalMargin * 2}px)`,
              }}
            >
              <div
                className={classes.absolute}
                style={{
                  transform: `translate3d(0px, 0px, 0px) scale(${
                    1.1 - scaleVideo
                  }, ${1.1 - scaleVideo})`,
                  zIndex: -1,
                }}
              >
                <div className={classes.videoCover}>
                  <video
                    src="https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/homepage-video.mp4"
                    loop
                    muted
                    autoPlay
                    playsInline
                    preload="none"
                    poster="https://aux-marketing-assets.s3.eu-west-1.amazonaws.com/marketing-pages/hero-poster.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classes.flexCenterColumn}
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div className={classes.backup}>
          <div className={classes.backupText}>
            Generate unique AI samples and loops, master your tracks instantly,
            and share them with your collaborators.
          </div>
          <div className={classes.btnContainer}>
            <PurpleButton
              style={{
                padding: "12px 16px",
                fontSize: "16px",
              }}
              component={Link}
              to="/signup"
            >
              Sign up now
            </PurpleButton>
            <DownloadAppButton
              sx={{
                padding: "12px 16px",
                fontSize: "16px",
              }}
            />
          </div>
        </div>
      </div>
      <div className={classes.linearGradient1}>
        <div className={classes.gridLogos}>
          <img
            src={FarOut}
            style={{ width: "80px", height: "auto" }}
            alt="far out"
          />
          <img src={Dt} alt="Dt" style={{ width: "50px", height: "auto" }} />
          <img
            src={MusicAlly}
            alt="Music ally"
            style={{ width: "86px", height: "auto" }}
          />
          <img
            src={Record}
            alt="record of the day"
            style={{ width: "73px", height: "auto" }}
          />
          <img src={Imi} alt="Imi" style={{ width: "40px", height: "auto" }} />
          <img
            src={Square}
            alt="square"
            style={{ width: "30px", height: "auto" }}
          />
        </div>
      </div>
      <AiHomepageSection />
    </div>
  );
}

export default HomePageMainSectionMobile;
