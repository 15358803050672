import React from "react";

/**
 * AcidProIcon
 * @returns {JSX.Element} Acid Pro Icon
 */
function AcidProIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 2H3C2.45 2 2 2.45 2 3V21C2 21.55 2.45 22 3 22H21C21.55 22 22 21.55 22 21V3C22 2.45 21.55 2 21 2ZM5.38 17.21V6.8C5.38 5.52 6.53 4.66 7.57 5.16L18.42 10.37C18.73 10.52 18.96 10.75 19.12 11.02C19.06 11.02 19 11 18.94 11H11.15C9.94 11 8.82 11.66 8.23 12.71L5.46 17.7C5.42 17.55 5.39 17.36 5.39 17.2L5.38 17.21ZM18.43 13.63L7.54 18.86C7.54 18.86 7.56 18.86 7.57 18.86C7.08 19.1 6.57 19.02 6.17 18.75C6.15 18.63 6.16 18.51 6.22 18.4L9.1 13.22C9.52 12.48 10.3 12.02 11.15 12.02H18.94C19.1 12.02 19.25 12.09 19.36 12.19C19.31 12.8 19 13.37 18.43 13.65V13.63Z"
        fill="black"
      />
    </svg>
  );
}

export default AcidProIcon;
