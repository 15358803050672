import { setUpgradeModal } from "_redux/actions";
import useStatsMutation from "_utils/useStatsMutation";
import { useDispatch } from "react-redux";
import getSubscriptionLimit from "./getSubscriptionLimit";

/**
 * Use Check Storage Limit
 *
 * Triggers upgrade modal if the user has not enough storage space for the files that are going to be uploaded.
 * @returns {Function} Callback that check the storage limit of a user.
 */
const useCheckStorageLimit = () => {
   const dispatch = useDispatch();
   const sendStatsMutation = useStatsMutation();

   /**
    *
    * @param {Array} files array files/folders to check if its possible to upload new files.
    * @param {object} user current user.
    * @returns {boolean} returns true if it is possible to add more files, otherwise it returs false.
    */
   const checkStorageLimit = (files, user) => {
      if (!files || files.length <= 0 || !user) return false;
      const limitObject = getSubscriptionLimit(user);

      let newFilesSize = 0;
      files.forEach((file) => {
         const fileSize = file.size ? file.size : file.fileSize;
         newFilesSize += fileSize;
      });

      if (
         user?.limits?.totalStorageUsed + newFilesSize >
         limitObject?.storage
      ) {
         sendStatsMutation({
            statsId: "PlanLimitReached",
            profileId: user?.profile?.id,
         });
         dispatch(setUpgradeModal(true));
         return false;
      }
      return true;
   };
   return checkStorageLimit;
};

export default useCheckStorageLimit;
