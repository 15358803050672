/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-return-assign */
import React, { useState, useRef, useEffect, useMemo } from "react";
import Container from "@mui/material/Container";
import Chip from "@mui/material/Chip";
import makeStyles from "@mui/styles/makeStyles";
import { useQuery } from "@apollo/react-hooks";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import MetaTags from "_components/MetaTags";
import PublicPrivateLayoutSwitcher from "_layouts/PublicPrivateLayoutSwitcher";
import useStatsMutation from "_utils/useStatsMutation";
import { TextField, CircularProgress, Box } from "@mui/material";
import {
   GET_FEATURED_TAGS,
   GET_FEATURED_LOCATION_TAGS,
   GET_FEATURED_GENRE_TAGS,
   GET_PROFILES,
   GET_TAGS_BY_URI,
} from "_apollo/queries";
import DiscoverInfiniteScroll from "_components/DiscoverInfiniteScroll";
import Header from "_components/Header/Header";
import SignUpInputFiled from "_components/SignUpInputFiled";
import DiscoverSort from "_components/DiscoverSort";
import { useUser } from "_utils/UserContext";
import ForYouBanner from "_components/ForYou/ForYouBanner";

const useStyles = makeStyles((theme) => ({
   root: {
      width: "100%",
   },
   form: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: "100%",
      maxWidth: "440px",
      height: 36,
      backgroundColor: theme.palette.secondary.shade5,
   },
   subHeaderFilters: {
      display: "flex",
      flexWrap: "wrap",
      gap: "12px",
   },
   titleTags: {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: "24px",
      fontWeight: 700,
      marginBottom: "12px",
   },

   headerLogged: {
      padding: "24px 24px 30px",
      borderBottom: `solid 1px ${theme.palette.secondary.shade15}`,
      marginBottom: "18px",
      display: "flex",
      alignItems: "flex-end",
   },
   titleDiscover: {
      marginRight: "auto",
      paddingRight: "16px",
   },
   headerNotLogged: {
      paddingTop: "24px",
      paddingBottom: "30px",
      marginBottom: "18px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
   },
   headerTitle: {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: "24px",
      fontWeight: "bold",
      marginBottom: "12px",
   },
   headerText: {
      fontSize: theme.typography.small2.fontSize,
      lineHeight: "16px",
      color: theme.palette.secondary.shade60,
      marginBottom: "20px",
   },
   input: {
      width: "100%",
      maxWidth: "440px",
      [`& fieldset`]: {
         borderRadius: 0,
      },
   },
   inputLogged: {
      width: "100%",
      [`& .MuiOutlinedInput-root`]: {
         borderRadius: 0,
      },
   },
   backgroundMain: {
      background: `linear-gradient(90deg, #FFC7E5 0%, rgba(255, 199, 229, 0) 100%),
       linear-gradient(180deg, #FF8845 0%, rgba(255, 136, 69, 0) 100%), 
       #6147FF`,
   },
   title: {
      textAlign: "center",
      fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
      maxWidth: "800px",
      marginLeft: "auto",
      marginRight: "auto",
      fontSize: theme.typography.big1.fontSize,
      lineHeight: "48px",
   },
   subtitle: {
      textAlign: "center",
      maxWidth: "800px",
      marginLeft: "auto",
      marginRight: "auto",
      fontWeight: 400,
      fontSize: theme.typography.h5.fontSize,
      lineHeight: "24px",
   },
   getStartedForm: {
      display: "flex",
      justifyContent: "center",
      margin: "0 auto",
      padding: "45px 0 190px",
      maxWidth: "60%",
   },
   noResults: {
      borderTop: `solid 1px ${theme.palette.secondary.shade15}`,
      borderBottom: `solid 1px ${theme.palette.secondary.shade15}`,
      padding: "42px 0px",
      margin: "50px 0px 64px",
      display: "flex",
      justifyContent: "center",
      fontSize: theme.typography.small1.fontSize,
      fontWeight: 700,
      lineHeight: "20px",
      "& a": {
         color: theme.palette.primary.main,
         textDecoration: "none",
         fontWeight: 300,
      },
   },
   lazyLoad: {
      overflow: "auto",
      height: "100%",
   },
   loader: {
      display: "flex",
      justifyContent: "center",
      height: "50px",
   },
}));

/**
 * Discover Page
 * @returns {JSX.Element} Discover Page
 */
const DiscoverPage = () => {
   const classes = useStyles();
   const [user] = useUser();
   const params = useParams();
   const scrollParentRef = useRef();
   const [search, setSearch] = useState("");
   const [flag, setFlag] = useState(true);

   const [sortValue, setSortValue] = useState("Popularity");
   const location = useLocation();
   const history = useHistory();
   const tags = useMemo(
      () => Object.values(params).slice(0, 5),
      [Object.values(params).length]
   );
   const [tagSearch, setTagSearch] = useState("");
   const startSearch = useRef(null);

   const { data: mainTag } = useQuery(GET_TAGS_BY_URI, {
      variables: {
         uri: Object.values(params).slice(0, 5),
      },
   });

   const { data: dataTags } = useQuery(GET_FEATURED_TAGS);
   const { data: dataLocationTags } = useQuery(GET_FEATURED_LOCATION_TAGS);
   const { data: dataGenreTags } = useQuery(GET_FEATURED_GENRE_TAGS);

   const {
      data,
      fetchMore,
      refetch: initialFetch,
      loading,
   } = useQuery(GET_PROFILES, {
      variables: {
         offset: 0,
         limit: 6,
         tags,
         sorting: sortValue,
         search,
      },
      /**
       * getProfiles onCompleted
       * @param {object} dataProfiles Apollo data object.
       */
      onCompleted(dataProfiles) {
         if (dataProfiles.getProfiles?.length === 0) {
            setFlag(false);
         }
      },
      notifyOnNetworkStatusChange: true,
   });

   const profiles = data?.getProfiles;

   const sendStatsMutation = useStatsMutation();

   useEffect(() => {
      sendStatsMutation({
         statsId: "UserPageView",
      });
   }, []);

   useEffect(() => {
      setFlag(true);
      initialFetch({
         offset: 0,
         limit: 6,
         tags,
         sorting: sortValue,
      });
   }, [sortValue, tags]);

   useEffect(() => {
      setTagSearch(location.pathname.slice("/discover/".length));
   }, [location.pathname]);

   /**
    * Fetch more data
    *
    * Fetches more profiles using the offset and limit variables
    * @returns {void}
    */
   const fetchMoreData = () => {
      const currentLength = data?.getProfiles.length;
      fetchMore({
         variables: {
            offset: currentLength,
            limit: 6,
            tags,
            sorting: sortValue,
         },
      }).then((fetchMoreResult) => {
         // This conditional just indicates if there is more users in the pagination to avoid breaking
         if (fetchMoreResult.data.getProfiles.length === 0) {
            setFlag(false);
         }
      });
   };

   const title = mainTag?.getTagsByUri
      ? `Discover ${mainTag?.getTagsByUri
           ?.map((a) => a?.name)
           .join(", ")} on Aux`
      : "Discover the Aux Community";

   const subtitle = mainTag?.getTagsByUri
      ? `Find ${mainTag?.getTagsByUri
           ?.map((a) => a?.name)
           .join(
              ", "
           )} and a whole lot more in the Aux Guide, a collection of all Aux user profiles plus our own database of key industry connections.`
      : `Find your next collaborator, label or recordings
      studio and more in the Aux Guide, a collection of all
      Aux user profiles plus our own database of key
      industry connections.`;

   /**
    * Add Tags to Search
    * @param {string} uri URI of tag to add to search.
    */
   const addTagsToSearch = (uri) => {
      const tagSearchSplit = tagSearch.split("/");
      if (
         tagSearch === "" ||
         (tagSearch &&
            !tagSearchSplit.includes(uri) &&
            tagSearchSplit.length < 5)
      ) {
         history.push(`${location.pathname}/${uri}`);
      }
   };

   /**
    * Remove Tags from Search
    * @param {string} uri URI of tag to remove from search.
    */
   const removeTagsFromSearch = (uri) => {
      if (tagSearch !== "") {
         history.push(`${location.pathname.replace(`/${uri}`, "")}`);
      }
   };

   return (
      <PublicPrivateLayoutSwitcher typeLayout="discover">
         <div
            style={
               user
                  ? {
                       position: "absolute",
                       top: 0,
                       right: 0,
                       left: 0,
                       bottom: 0,
                    }
                  : {}
            }
            className={`${classes.root} ${classes.lazyLoad}`}
            ref={scrollParentRef}
         >
            <MetaTags title={title} description={subtitle} />
            {!user && (
               <div className={classes.backgroundMain}>
                  <Header />
                  <Container>
                     <h1 className={classes.title}>{title}</h1>
                     <div className={classes.subtitle}>{subtitle}</div>
                     <div className={classes.getStartedForm}>
                        <SignUpInputFiled />
                     </div>
                  </Container>
               </div>
            )}

            {user ? (
               <div className={classes.headerLogged}>
                  <div className={classes.titleDiscover}>
                     <div className={classes.headerTitle}>
                        {params.length > 0
                           ? "Discover the Community"
                           : "Discover"}
                     </div>
                     <div className={classes.headerText}>
                        {params.length > 0
                           ? "Find your next collaborator, label or recording studio and more in the Aux Guide, a collection of all Aux user profiles plus our own database of key industry connections."
                           : "Find your next collaborator, label or recording studio and more in the Aux Guide."}
                     </div>
                     <TextField
                        className={classes.inputLogged}
                        size="small"
                        type="text"
                        variant="outlined"
                        autoComplete="off"
                        placeholder="Filter Guide Profiles"
                        value={search}
                        onChange={(e) => {
                           // Timeout for autosave input
                           clearTimeout(startSearch.current);
                           setSearch(e.target.value);
                           startSearch.current = setTimeout(() => {
                              setFlag(true);
                              initialFetch({
                                 offset: 0,
                                 limit: 6,
                                 tags,
                                 search: e.target.value,
                                 sorting: sortValue,
                              });
                           }, 500);
                        }}
                     />
                  </div>

                  <DiscoverSort
                     sortValue={sortValue}
                     setSortValue={setSortValue}
                  />
               </div>
            ) : (
               <Container maxWidth="lg" className={classes.headerNotLogged}>
                  <div className={classes.form}>
                     <IconButton
                        type="submit"
                        className={classes.iconButton}
                        aria-label="search"
                        size="large"
                     >
                        <SearchIcon />
                     </IconButton>
                     <InputBase
                        className={classes.input}
                        size="small"
                        type="text"
                        variant="outlined"
                        autoComplete="off"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                           // Timeout for autosave input
                           clearTimeout(startSearch.current);
                           setSearch(e.target.value);
                           startSearch.current = setTimeout(() => {
                              setFlag(true);
                              initialFetch({
                                 offset: 0,
                                 limit: 6,
                                 tags,
                                 search: e.target.value,
                                 sorting: sortValue,
                              });
                           }, 500);
                        }}
                     />
                  </div>
                  <DiscoverSort
                     sortValue={sortValue}
                     setSortValue={setSortValue}
                  />
               </Container>
            )}

            <div>
               <Container maxWidth="lg">
                  <Grid container spacing={2}>
                     <Grid item xs={4}>
                        <div className={classes.titleTags}>
                           Featured Locations
                        </div>
                        <div className={classes.subHeaderFilters}>
                           {dataLocationTags?.getFeaturedLocationTags.map(
                              (el) => {
                                 return (
                                    <Chip
                                       color={
                                          tags.some(
                                             (a) =>
                                                a.toLowerCase() ===
                                                el?.uri.toLowerCase()
                                          )
                                             ? "primary"
                                             : "secondary"
                                       }
                                       onClick={() => addTagsToSearch(el?.uri)}
                                       onDelete={
                                          tagSearch.split("/").includes(el?.uri)
                                             ? () =>
                                                  removeTagsFromSearch(el?.uri)
                                             : null
                                       }
                                       key={el.id}
                                       label={el.name}
                                    />
                                 );
                              }
                           )}
                        </div>
                     </Grid>

                     <Grid item xs={4}>
                        <div className={classes.titleTags}>Featured Genres</div>
                        <div className={classes.subHeaderFilters}>
                           {dataGenreTags?.getFeaturedGenreTags.map((el) => (
                              <Chip
                                 color={
                                    tags.some(
                                       (a) =>
                                          a.toLowerCase() ===
                                          el?.uri.toLowerCase()
                                    )
                                       ? "primary"
                                       : "secondary"
                                 }
                                 onClick={() => addTagsToSearch(el?.uri)}
                                 onDelete={
                                    tagSearch.split("/").includes(el?.uri)
                                       ? () => removeTagsFromSearch(el?.uri)
                                       : null
                                 }
                                 key={el.id}
                                 label={el.name}
                              />
                           ))}
                        </div>
                     </Grid>

                     <Grid item xs={4}>
                        <div className={classes.titleTags}>Featured Tags</div>
                        <div className={classes.subHeaderFilters}>
                           {dataTags?.getFeaturedTags.map((el) => (
                              <Chip
                                 onClick={() => addTagsToSearch(el?.uri)}
                                 onDelete={
                                    tagSearch.split("/").includes(el?.uri)
                                       ? () => removeTagsFromSearch(el?.uri)
                                       : null
                                 }
                                 color={
                                    tags.some(
                                       (a) =>
                                          a.toLowerCase() ===
                                          el?.uri.toLowerCase()
                                    )
                                       ? "primary"
                                       : "secondary"
                                 }
                                 key={el.id}
                                 label={el.name}
                              />
                           ))}
                        </div>
                     </Grid>
                  </Grid>
                  <Box mt="20px">
                     <ForYouBanner hideEmptyState />
                  </Box>
                  {profiles?.length > 0 ? (
                     <DiscoverInfiniteScroll
                        key={`${profiles?.length}-container-discover-infinite-loading`}
                        flag={flag}
                        search={search}
                        searchingArtists={loading}
                        profiles={data?.getProfiles}
                        fetchMoreData={fetchMoreData}
                        scrollParentRef={scrollParentRef}
                     />
                  ) : (
                     <div className={classes.noResults}>
                        No Results. &nbsp;{" "}
                        <Link to="/discover">Reset filters</Link>
                     </div>
                  )}
               </Container>
            </div>
            <span className={classes.loader}>
               {flag && <CircularProgress size={40} />}
            </span>
         </div>
      </PublicPrivateLayoutSwitcher>
   );
};

export default DiscoverPage;
/* eslint-enable react/no-this-in-sfc */
/* eslint-enable no-return-assign */
