import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import PropTypes from "prop-types";

import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import {
   EmailShareButton,
   FacebookShareButton,
   RedditShareButton,
   TwitterShareButton,
   LinkedinShareButton,
   FacebookMessengerShareButton,
   EmailIcon,
   FacebookIcon,
   RedditIcon,
   TwitterIcon,
   LinkedinIcon,
   FacebookMessengerIcon,
} from "react-share";
import { Box } from "@mui/material";
import * as Sentry from "@sentry/react";
import HiringBadge from "_components/HiringBadge";
import api from "../../_utils/ghostContentApi";
import { months } from "../../_constants/GlobalVariables";
import Footer from "../../_components/Footer";
import MetaTags from "../../_components/MetaTags";
import Header from "../../_components/Header/Header";
import EngageSignUpForm from "../../_components/EngageSignUpForm";
import MiniBlog from "../../_components/MiniBlog";
import isItDark from "../../_utils/isItDark";

const useStyles = makeStyles((theme) => ({
   backgroundShade5: {
      backgroundColor: theme.palette.secondary.shade5,
   },
   spacingCont: {
      paddingTop: "70px",
      paddingBottom: "70px",
      "@media (max-width: 768px)": {
         paddingTop: "50px",
         paddingBottom: "50px",
      },
   },
   title: {
      textAlign: "center",
      fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
      maxWidth: "800px",
      marginLeft: "auto",
      marginRight: "auto",
      fontSize: theme.typography.big1.fontSize,
      lineHeight: "48px",
   },

   flex: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      gap: "8px",
   },
   flexHeader: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      gap: "8px",
      justifyContent: "center",
   },
   mainView: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "10px",
   },
   date: {
      color: theme.palette.secondary.shade60,
      fontSize: theme.typography.h6.fontSize,
      lineHeight: "24px",
      margin: "12px 0 24px",
      fontWeight: 400,
   },
   chip: {
      backgroundColor: "white",
      border: `solid 1px ${theme.palette.secondary.shade15}`,
   },
   avatarLarge: {
      height: "126px",
      width: "126px",
   },
   containerAuthor: {
      width: "40%",
      marginLeft: "12px",
   },
   titleAuthor: {
      color: theme.palette.secondary.shade60,
      fontSize: theme.typography.h6.fontSize,
      lineHeight: "24px",
   },
   bioAuthor: {
      fontSize: theme.typography.small2.fontSize,
      lineHeight: "16px",
   },
   linkAuthor: {
      fontSize: theme.typography.small2.fontSize,
      lineHeight: "16px",
      color: theme.palette.primary.main,
      textDecoration: "none",
   },
   titlePage: {
      fontSize: theme.typography.big1.fontSize,
      lineHeight: "48px",
      fontFamily: "dr-extrabold",
      marginBottom: "44px",
   },
   link: { textDecoration: "none" },
   authorName: {
      color: "black",
   },
   spacingAuthorBox: {
      marginTop: "12px",
   },
   ghostHtml: theme.ghostHtml,
   white: {
      color: "white",
   },
   containerMain: {
      position: "relative",
      paddingBottom: "90px",
      boxSizing: "border-box",
   },
   imageMain: {
      position: "absolute",
      zIndex: -1,
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundSize: "cover",
      backgroundPosition: "center center",
      whiteSpace: "nowrap",
      overflow: "hidden",
      background: `linear-gradient(90deg, #FFDCC9 0%, rgba(255, 220, 201, 0) 100%),
      linear-gradient(180deg, #3EF1D1 0%, rgba(62, 241, 209, 0) 100%), 
       #6147FF`,
   },
}));
const url = window.location.href;

/**
 * Posts and Pages
 * @param {object} param props
 * @param {object} param.contentType post or page
 * @returns {Element} JSX element
 */
const PostsPages = ({ contentType = "post" }) => {
   const history = useHistory();
   const classes = useStyles();

   const { slug } = useParams();
   const [ghostPost, setGhostPost] = useState(undefined);
   const [ghostPage, setGhostPage] = useState(undefined);

   /**
    * Fetch Post
    */
   const fetchPost = async () => {
      let post;
      let page;
      if (contentType === "post") {
         try {
            post = await api.posts.read({ slug }, { include: "tags,authors" });

            if (post) {
               setGhostPost(post);
               window.scrollTo({ top: 0 });
            }
         } catch (error) {
            Sentry.captureException(error);
         }
      } else {
         try {
            page = await api.pages.read({ slug });
            if (page) {
               setGhostPage(page);
               window.scrollTo({ top: 0 });
            }
         } catch (error) {
            Sentry.captureException(error);
         }
      }
      if (!post && !page) {
         history.push("/404");
      }
   };

   useEffect(() => {
      fetchPost();
   }, [slug]);

   // Update styles for ghost gallery images.
   useEffect(() => {
      if (ghostPage?.html) {
         const galleryImagesDivs =
            document.querySelectorAll(".kg-gallery-image");
         galleryImagesDivs.forEach((div) => {
            const mutatedDiv = div;
            const image = div.querySelector("img");
            const ratio = image.width / image.height;
            mutatedDiv.style.flex = `${ratio} 1 0%`;
         });
      }
   }, [ghostPage]);

   // Set the post or page to the generic content variable used for shared sections of the page.
   const content = ghostPost || ghostPage;

   // Variable used to switch between light and dark text based on the feature image dominant brightness.
   const [whiteText, setWhiteText] = useState(false);

   useEffect(() => {
      if (ghostPost?.feature_image) {
         isItDark(ghostPost?.feature_image, (isDark) => {
            setWhiteText(isDark);
         });
      }
   }, [ghostPost?.feature_image]);

   /**
    * Format Date
    *
    * Format a datetime string into the user facing format.
    * @param {string} date DateTime string to format.
    * @returns {string} Formatted date string.
    */
   const formatDate = (date) => {
      return `${new Date(date).getDate()} ${
         months[new Date(date).getMonth()]
      } ${new Date(date).getFullYear()}`;
   };
   return (
      <>
         {content && (
            <>
               <HiringBadge />
               <MetaTags
                  title={content?.title || content?.meta_title}
                  image={ghostPost?.feature_image}
                  description={content?.excerpt}
               />
               <div className={classes.containerMain}>
                  <div
                     className={classes.imageMain}
                     style={
                        ghostPost?.feature_image
                           ? {
                                backgroundImage: `url(${ghostPost?.feature_image})`,
                                filter: whiteText
                                   ? "brightness(70%) contrast(50%)"
                                   : "brightness(130%) contrast(50%)",
                             }
                           : {}
                     }
                  />
                  <Header whiteText={whiteText} />
                  {ghostPost && (
                     <Container className={classes.mainView}>
                        <h1
                           className={`${classes.title} ${
                              whiteText ? classes.white : ""
                           }`}
                        >
                           {ghostPost.title}
                        </h1>
                        <div className={classes.flexHeader}>
                           {ghostPost.tags.map((tag) => (
                              <Link
                                 key={`${ghostPost?.slug}-${tag?.slug}`}
                                 to={`/connect/tag/${tag?.slug}`}
                                 className={classes.link}
                              >
                                 <Chip
                                    label={tag?.name}
                                    className={`${
                                       whiteText ? classes.white : ""
                                    }`}
                                 />
                              </Link>
                           ))}
                        </div>
                        <Link
                           to={`/connect/author/${ghostPost?.authors[0]?.slug}`}
                           className={classes.link}
                        >
                           <div className={classes.flex}>
                              <Avatar
                                 src={ghostPost?.authors[0]?.profile_image}
                              />
                              <span
                                 className={`${classes.authorName} ${
                                    whiteText ? classes.white : ""
                                 }`}
                              >
                                 {ghostPost?.authors[0]?.name}
                              </span>
                           </div>
                        </Link>
                     </Container>
                  )}
               </div>
               <Container className={classes.spacingCont}>
                  {ghostPage && (
                     <div className={classes.titlePage}>{ghostPage?.title}</div>
                  )}
                  <article
                     className={classes.ghostHtml}
                     id="original"
                     // eslint-disable-next-line react/no-danger
                     dangerouslySetInnerHTML={{
                        __html: content?.html,
                     }}
                  />

                  {ghostPost && (
                     <Box
                        marginY={1}
                        display="flex"
                        gap={1}
                        width="100%"
                        justifyContent="flex-start"
                     >
                        <FacebookShareButton
                           url={url}
                           quote={ghostPost.meta_title}
                        >
                           <FacebookIcon round size={30} />
                        </FacebookShareButton>
                        <FacebookMessengerShareButton url={url}>
                           <FacebookMessengerIcon round size={30} />
                        </FacebookMessengerShareButton>
                        <TwitterShareButton
                           url={url}
                           title={ghostPost.meta_title}
                           hashtags={["Aux"]}
                        >
                           <TwitterIcon round size={30} />
                        </TwitterShareButton>
                        <LinkedinShareButton
                           url={url}
                           title={ghostPost.meta_title}
                           summary={ghostPost?.meta_description}
                        >
                           <LinkedinIcon round size={30} />
                        </LinkedinShareButton>
                        <RedditShareButton
                           url={url}
                           title={ghostPost.meta_title}
                        >
                           <RedditIcon round size={30} />
                        </RedditShareButton>
                        <EmailShareButton url={url} body={ghostPost.meta_title}>
                           <EmailIcon round size={30} />
                        </EmailShareButton>
                     </Box>
                  )}
                  <div className={classes.date}>
                     {`Published ${formatDate(content.created_at)}`}
                  </div>
                  {ghostPost && (
                     <div className={classes.flex}>
                        {ghostPost.tags.map((tag) => (
                           <Chip
                              className={classes.chip}
                              key={`${ghostPost?.slug}-${tag?.slug}-mini`}
                              label={tag?.name}
                           />
                        ))}
                     </div>
                  )}
                  {ghostPost && (
                     <div
                        className={`${classes.flex} ${classes.spacingAuthorBox}`}
                     >
                        <Link
                           to={`/connect/author/${ghostPost?.authors[0]?.slug}`}
                           className={classes.link}
                        >
                           <Avatar
                              className={classes.avatarLarge}
                              src={ghostPost?.authors[0]?.profile_image}
                           />
                        </Link>
                        <div className={classes.containerAuthor}>
                           <Link
                              to={`/connect/author/${ghostPost?.authors[0]?.slug}`}
                              className={classes.link}
                           >
                              <div className={classes.titleAuthor}>
                                 {ghostPost?.authors[0]?.name}
                              </div>
                           </Link>
                           <p className={classes.bioAuthor}>
                              {ghostPost?.authors[0]?.bio}
                           </p>
                           <Link
                              className={classes.linkAuthor}
                              to={`/connect/author/${ghostPost?.authors[0]?.slug}`}
                           >
                              Read More By {ghostPost?.authors[0]?.name}
                           </Link>
                        </div>
                     </div>
                  )}
               </Container>
               <EngageSignUpForm />
               <div className={classes.backgroundShade5}>
                  <MiniBlog />
               </div>
               <Footer />
            </>
         )}
      </>
   );
};

PostsPages.propTypes = {
   contentType: PropTypes.oneOf(["post", "page"]).isRequired,
};

export default PostsPages;
