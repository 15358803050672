import { CONFIG_ENV } from "./getConfig";

/**
 * gtag
 * Wraps gtag function in a condition to not send Google Analytic events on develop.
 * @param  {...any} params - List containing all the parameters used for gtag function.
 */
const gtag = (...params) => {
   if (CONFIG_ENV !== "expo" && window.gtag) {
      // Also check if window.gtag exists because it doesn't exist in Electron.
      window.gtag(...params);
   }
};

export default gtag;
