import PropTypes from "prop-types";
import React, {
   createContext,
   useContext,
   useEffect,
   useRef,
   useState,
} from "react";

const ThemeContext = createContext();
/**
 * useUploadData
 *
 * NOTE: this data context now handles both uploads **and downloads** state.
 *
 * Custom hook to return the data from the context inside our components.
 * @returns {React.Context} Theme Context.
 */
const useUploadData = () => useContext(ThemeContext);

// TODO: this file is used for showing upload **status** not actually doing the upload so I feel like names such as setFilesUploading and uploadFiles are confusing. Could we rename these functions to be clearly distinct.
// setFilesUploadingNotifications and addFilesUploadingNotification. Also, I think we should rename the context to UploadNotificationContext.

/**
 * UploadDataProvider
 * UploadDataProvider Context Provider.
 * stores state of files being uploaded
 * @param {object} param Component props.
 * @param {Array} param.children Array of react components that this Provider will render as children.
 * @returns {React.Component} Returns Context Provider.
 */
const UploadDataProvider = ({ children }) => {
   const filesUploadingRef = useRef([]);
   // filesUploadingState is used to trigger rerender of components that use this context
   const [filesUploadingState, setFilesUploadingState] = useState([]);
   // filesDownloadingState is used to store the data of the files that are being downloaded
   const [filesDownloadingState, setFilesDownloadingState] = useState([]);
   const [downloadingZips, setDownloadingZips] = useState([]);
   const [syncNotifications, setSyncNotifications] = useState([]);
   const [showTraySyncNotifications, setShowTraySyncNotifications] =
      useState(false);

   /**
    * setFilesUploading
    * Wrapper function that sets filesUploading array.
    * @param {(Array | Function)} newFiles Array of new files that will be added to filesUploading array or function that will be used to update filesUploading array.
    */
   const setFilesUploading = (newFiles) => {
      if (newFiles instanceof Function) {
         filesUploadingRef.current = newFiles(filesUploadingRef.current);
      } else {
         filesUploadingRef.current = newFiles;
      }

      setFilesUploadingState(filesUploadingRef.current);
   };

   /**
    * dispatchUploadingFiles
    * Wrapper function that filters syncing files from uploadArray. They are still uploaded just not shown in notification.
    * @param {Array} files Array of new files that will be added to filesUploading array.
    */
   const uploadFiles = (files) => {
      setFilesUploading((prev) => [...prev, ...files]);
   };

   /**
    * updateProgress
    * Updates progress of file in filesUploading array.
    * @param {object} newData  New data that will be merged with existing file data.
    */
   const updateProgress = (newData) => {
      const updatedFiles = filesUploadingRef.current.map((file) => {
         if (file.path === newData.path && file.hash === newData.hash) {
            return newData;
         }
         return file;
      });
      setFilesUploading(updatedFiles);
   };

   /**
    * removeUpload
    * Removes file from filesUploading array.
    *
    * Should be used every time you want to remove and cancel an upload rather than calling cancelToken.cancel directly.
    * @param {object} props Component props.
    * @param {Function} props.conditionCallback Custom function to pass condition to remove upload.
    */
   const removeUpload = ({ conditionCallback }) => {
      let updatedFiles = filesUploadingRef.current.map((file) => {
         if (conditionCallback(file)) {
            // Cancel the upload with axios.
            file?.cancelToken?.cancel("Upload cancelled");
         }
         return file;
      });
      updatedFiles = updatedFiles.filter((file) => !conditionCallback(file));
      setFilesUploading(updatedFiles);
   };

   /**
    * removeDownload
    * Removes file from filesDownloading array.
    *
    * Should be used every time you want to remove and cancel a download rather than calling cancel.abort() directly.
    * @param {object} props Component props.
    * @param {string} props.path File path that is used to identify file in filesDownloadState array.
    * @param {Function} props.cancelRequest AbortController function that is used to cancel the download.
    */
   const removeDownload = ({ path, cancelRequest }) => {
      const updatedDownloads = filesDownloadingState.filter((file) => {
         return file.path !== path;
      });
      setFilesDownloadingState(updatedDownloads);

      // Cancel the download with fetch via AbortController.
      if (cancelRequest) {
         cancelRequest.abort();
      }
   };

   useEffect(() => {
      if (filesUploadingState?.length > 0) {
         const everyGroupCompleted = filesUploadingState.every(
            (fileGroup) => fileGroup?.avgProgress === 100
         );
         if (everyGroupCompleted) {
            setFilesUploading((prevFiles) =>
               prevFiles.filter((fileGroup) => fileGroup?.avgProgress < 100)
            );
         }
      }
   }, [filesUploadingState]);

   return (
      <ThemeContext.Provider
         value={{
            filesUploading: filesUploadingState,
            allFilesUploading: filesUploadingState,
            uploadFiles,
            updateProgress,
            removeUpload,
            removeDownload,
            filesDownloadingState,
            setFilesDownloadingState,
            downloadingZips,
            setDownloadingZips,
            setFilesUploading,
            syncNotifications,
            setSyncNotifications,
            showTraySyncNotifications,
            setShowTraySyncNotifications,
         }}
      >
         {children}
      </ThemeContext.Provider>
   );
};

UploadDataProvider.propTypes = {
   children: PropTypes.element.isRequired,
};

export { useUploadData, UploadDataProvider };
