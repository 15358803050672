import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Notification from "./Notification";

const useStyles = makeStyles((theme) => ({
   container: {
      flexWrap: "nowrap",
      padding: "0 10px",
      backgroundColor: theme.palette.coreApp.background,
      "& *": {
         fontSize: theme.typography.small1.fontSize,
      },
      overflow: "auto",
      maxHeight: 170,
      minWidth: 610, // Min width required to keep a consistent size for all notification types and No Notifications component
   },
   clearButton: {
      backgroundColor: theme.palette.coreApp.background,
   },
}));

/**
 * Notifications
 *
 * Renders a list of notifications inside the tray.
 * @param {object} props props
 * @param {object} props.notifications Redux notifications object.
 * @param {Function} props.setNotifications Dispatch action to set new notification in redux.
 * @param {Function} props.clearAll callback called when the Clear All button is clicked.
 * @param {string} props.emptyText Text to display when there are no notifications.
 * @returns {JSX.Element} Notifications list component.
 */
const Notifications = ({
   notifications,
   setNotifications,
   clearAll,
   emptyText,
}) => {
   const classes = useStyles();
   // TODO: sort notifications to make sure that the active uploads are at the top then sort the finished uploads by completed date.

   return (
      <Box
         display="flex"
         flexDirection="column"
         backgroundColor="white"
         borderRadius="4px"
         boxShadow="rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px"
      >
         <Grid
            container
            direction="column"
            id="notifications-container"
            className={classes.container}
         >
            {notifications?.length > 0 ? (
               notifications?.map((el) => {
                  // Create a unique key for each notification to prevent missing notifications or notifications only re-rendering when the upload is fully complete.
                  const key = `${el?.typeName}-${
                     el?.file?.hash ||
                     el?.file?.uri ||
                     el?.id ||
                     el?.file?.path ||
                     el?.file?.name
                  }${el?.file?.path ? `-${el?.file?.path}` : ""}`;

                  return (
                     <Notification
                        notification={el}
                        setNotifications={setNotifications}
                        key={key}
                     />
                  );
               })
            ) : (
               <Box py={2}>
                  <Grid justifyContent="center" container>
                     <h4>{emptyText}</h4>
                  </Grid>
               </Box>
            )}
         </Grid>
         {notifications?.length > 0 && (
            <Box
               display="flex"
               alignItems="center"
               justifyContent="center"
               className={classes.clearButton}
            >
               <Button onClick={() => clearAll()}>Clear All</Button>
            </Box>
         )}
      </Box>
   );
};

Notifications.propTypes = {
   notifications: PropTypes.instanceOf(Object).isRequired,
   setNotifications: PropTypes.func.isRequired,
};

export default Notifications;
