import React, { useEffect, useMemo, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";

// Apollo
import Button from "@mui/material/Button";
import DownloadAppButton from "_components/DownloadAppButton";
import FeaturedIn from "_assets/Marketing/FeaturedIn/featured-in.svg";
import FarOut from "_assets/Marketing/FeaturedIn/far-out.svg";
import Dt from "_assets/Marketing/FeaturedIn/dt.svg";
import MusicAlly from "_assets/Marketing/FeaturedIn/music-ally.svg";
import Record from "_assets/Marketing/FeaturedIn/record.svg";
import Imi from "_assets/Marketing/FeaturedIn/imi.svg";
import Square from "_assets/Marketing/FeaturedIn/square.svg";
import { Link } from "react-router-dom";
import Header from "_components/Header/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import AiHomepageSection from "_components/AiHomepageSection";

const PurpleButton = withStyles(() => ({
  root: {
    color: "black",
    backgroundColor: "#9D75FF",
    "&:hover": {
      backgroundColor: "#9D75FF",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
}))(Button as any);

const useStyles = makeStyles((theme: any) => ({
  sticky: {
    position: "sticky",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 4,
    bottom: "0%",
    left: "0%",
    right: "0%",
  },
  nextGen: {
    color: "#F2F2F2",
    margin: "0px",
    lineHeight: "95%",
    fontWeight: 300,
    fontFamily: "dr-light, Arial, Helvetica, sans-serif",
    textShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px",
  },
  musicCollaboration: {
    color: "#F2F2F2",
    margin: "0px",
    lineHeight: "95%",
    fontWeight: 800,
    fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
    paddingLeft: "33vw",
    textShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px",
  },
  absolute: {
    position: "absolute",
    top: "0%",
    bottom: "0%",
    left: "0%",
    right: "0%",
  },
  videoContainerFixed: {
    position: "fixed",
    maxHeight: "671px",
    transform: `translate(0px, 0px)`,
    boxSizing: "border-box",
    width: "100%",
    top: "0%",
    bottom: "0%",
    left: "0%",
    right: "0%",
  },
  videoContainer: {
    maxHeight: "671px",
    height: "671px",
    transform: `translate(0px, 671px)`,
    boxSizing: "border-box",
    width: "100%",
  },
  cover: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    "& video": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      display: "block",
    },
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  backup: {
    fontFamily: "Inter",
    marginTop: "36px",
    color: "#B3B3B3",
    fontSize: "1.1875rem",
    lineHeight: "145%",
  },
  btnContainer: {
    display: "flex",
    gap: "16px",
    marginBottom: "52px",
    marginTop: "24px",
  },
  gradient: {
    background: "linear-gradient(180deg, #111 0%, #000 100%)",
  },
  logos: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-end",
    padding: "7.75rem 40px 42px",
    flexWrap: "wrap",
  },
}));

/**
 * Home page main section.
 * @returns {JSX.Element} The home page main section.
 */
function HomePageMainSection() {
  const classes = useStyles();
  const smallFontSize = useMediaQuery("(max-width:1100px)");

  const fontSizeTitle = useMemo(
    () => (smallFontSize ? 4.1 : 5.1),
    [smallFontSize]
  );

  /**
   * Categorizes a given number into groups based on a rule.
   *
   * If the number is less than 4, it will be placed in the first group (group 1).
   * Otherwise, the number will be placed in the group calculated using the formula:
   * group = Math.ceil((number - 1) / 4)
   *
   * @param {number} number - The number to be categorized.
   * @returns {number} The group number to which the input number belongs.
   */
  function categorizeNumbers(number) {
    if (number < 4) {
      return 1;
    }
    return Math.ceil((number - 1) / 4);
  }

  /**
   * Multiplies a given number by two-thirds (2/3).
   *
   * @param {number} number - The number to be multiplied.
   * @returns {number} The result of multiplying the input number by two-thirds (2/3).
   */
  function multiplyByTwoThirds(number) {
    return number * (2 / 3);
  }

  const [verticalMargin, setVerticalMargin] = useState(0);
  const [horizontalMargin, setHorizontalMargin] = useState(0);
  const [scaleVideo, setScaleVideo] = useState(0);
  const [pxFromTop, setPxFromTop] = useState(0);
  const setDimensions = () => {
    const pixelsToTop = document.documentElement.scrollTop;
    setPxFromTop(pixelsToTop);
    const numberByFour = categorizeNumbers(pixelsToTop);
    if (numberByFour <= 72) {
      setVerticalMargin(numberByFour);
      const numberByTwoThirds = multiplyByTwoThirds(numberByFour);
      if (numberByTwoThirds > 40) {
        setHorizontalMargin(40);
      } else {
        setHorizontalMargin(numberByTwoThirds);
      }
      setScaleVideo((pixelsToTop * 3) / 10000);
    } else {
      setVerticalMargin(72);
      setHorizontalMargin(40);
      setScaleVideo(0.0864);
    }
  };

  useEffect(() => {
    setDimensions();
    window.addEventListener("scroll", () => {
      setDimensions();
    });
  }, []);

  return (
    <div style={{ backgroundColor: "#111" }}>
      <div
        style={{
          position: "relative",
          height: `calc(100vh + ${920 - verticalMargin * 2}px)`,
        }}
      >
        <div
          className={classes.sticky}
          style={{
            top: `${80 + verticalMargin}px`,
          }}
        >
          <h1
            className={classes.nextGen}
            style={{
              fontSize: `${
                fontSizeTitle - (fontSizeTitle * scaleVideo) / 1.5
              }rem`,
              paddingRight: smallFontSize ? "24rem" : "30rem",
            }}
          >
            Next generation
          </h1>
          <h1
            className={classes.musicCollaboration}
            style={{
              fontSize: `${
                fontSizeTitle - (fontSizeTitle * scaleVideo) / 1.5
              }rem`,
            }}
          >
            Music making
          </h1>
        </div>
        <div className={classes.absolute}>
          <div
            className={
              pxFromTop < 671
                ? classes.videoContainerFixed
                : classes.videoContainer
            }
          >
            <div>
              <Header whiteText showDownloadButton />
            </div>
            <div
              style={{
                margin: `${verticalMargin}px ${horizontalMargin}px`,
                borderRadius: `${verticalMargin <= 16 ? verticalMargin : 16}px`,
                overflow: "hidden",
                position: "absolute",
                top: `${verticalMargin / 1.8}px`,
                bottom: "0%",
                left: "0%",
                right: "0%",
                zIndex: 2,
                height: `calc(100vh - ${verticalMargin * 2.6}px)`,
              }}
            >
              <div
                className={classes.absolute}
                style={{
                  transform: `translate3d(0px, 0px, 0px) scale(${
                    1.1 - scaleVideo
                  }, ${1.1 - scaleVideo})`,
                  zIndex: -1,
                }}
              >
                <div className={classes.cover}>
                  <video
                    src="https://aux-marketing-assets.s3-eu-west-1.amazonaws.com/homepage-video.mp4"
                    loop
                    muted
                    autoPlay
                    playsInline
                    preload="none"
                    poster="https://aux-marketing-assets.s3.eu-west-1.amazonaws.com/marketing-pages/hero-poster.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classes.flexColumn}
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div
          className={classes.backup}
          style={
            smallFontSize ? { paddingLeft: "37.5vw" } : { paddingLeft: "29vw" }
          }
        >
          Generate unique AI samples and loops,
          <br />
          master your tracks instantly, and
          <br />
          share them with your collaborators.
          <div className={classes.btnContainer}>
            <PurpleButton
              style={{
                padding: "12px 16px",
                fontSize: "16px",
                width: "176px",
              }}
              component={Link}
              to="/signup"
            >
              Sign up now
            </PurpleButton>
            <DownloadAppButton
              sx={{
                padding: "12px 16px",
                fontSize: "16px",
                color: "white",
              }}
            />
          </div>
        </div>
      </div>
      <div className={classes.gradient}>
        <div className={classes.logos}>
          <img
            src={FeaturedIn}
            style={{ width: "6rem", height: "auto" }}
            alt="featured in"
          />
          <img
            src={FarOut}
            style={{ width: "6.85rem", height: "auto" }}
            alt="far out"
          />
          <img src={Dt} style={{ width: "4.3rem", height: "auto" }} alt="Dt" />
          <img
            src={MusicAlly}
            style={{ width: "7.37rem", height: "auto" }}
            alt="Music ally"
          />
          <img
            src={Record}
            style={{ width: "6.25rem", height: "auto" }}
            alt="record of the day"
          />
          <img
            src={Imi}
            style={{ width: "2.84rem", height: "auto" }}
            alt="Imi"
          />
          <img
            src={Square}
            style={{ width: "2.125rem", height: "auto" }}
            alt="square"
          />
        </div>
      </div>
      <AiHomepageSection />
    </div>
  );
}

export default HomePageMainSection;
