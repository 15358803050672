import React, { useEffect, useRef, useState } from "react";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleFilled from "@mui/icons-material/PauseCircleFilled";
import { Box, IconButton, Typography, CircularProgress } from "@mui/material";
import WaveSurfer from "wavesurfer.js";

/**
 * AudioPlayer
 * @param {object} params params.
 * @param {object} params.file The file object.
 * @param {string | null}params.typeSelected type of mastered version or null(original file).
 * @returns {JSX.Element} jsx element
 */
const AudioPlayer = ({ file, typeSelected }) => {
  const waveSurferRef = useRef<any>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!waveSurferRef.current) return; // Ensure WaveSurfer instance is initialized
    setIsPlaying(false); // Pause playback when changing audio
    setIsLoading(true);
    // Save the current time before loading the new track
    const currentTimeWave = waveSurferRef.current.getCurrentTime();
    let { signedUrl } = file;
    if (typeSelected) {
      const masteredData = file.masteredData.find(
        (a) => a.reference === typeSelected
      );
      signedUrl = masteredData.signed_url;
    }
    waveSurferRef.current.load(signedUrl);
    waveSurferRef.current.on("ready", () => {
      if (
        !Number.isNaN(currentTimeWave) &&
        Number.isFinite(currentTimeWave) &&
        duration > 0
      ) {
        waveSurferRef.current.seekTo(currentTimeWave / duration);
      }
      setIsLoading(false);
    });
    // You may add additional logic here to handle changes in WaveSurfer settings if needed
  }, [typeSelected]);

  useEffect(() => {
    setIsLoading(true);
    waveSurferRef.current = WaveSurfer?.create({
      container: `#waveform-container-${file.id}`,
      waveColor: "#C8BFFF",
      cursorColor: "black",
      cursorWidth: 0.75,
      height: 86,
      progressColor: "#6147FF",
      interact: true,
      hideScrollbar: true,
    });
    waveSurferRef.current.on("ready", () => {
      if (!duration) {
        setIsLoading(false);
        setDuration(waveSurferRef.current.getDuration());
      }
    });

    const bar = document.querySelector(
      `#waveform-container-${file.id} > wave > wave`
    ) as HTMLElement;
    if (bar) {
      bar.style.borderRight = "none";
    }
    if (!typeSelected) {
      waveSurferRef.current.load(file.signedUrl);
    } else {
      const masteredData = file.masteredData.find(
        (a) => a.reference === typeSelected
      );
      waveSurferRef.current.load(masteredData.signed_url);
    }
  }, []);

  const togglePlay = () => {
    if (isPlaying) {
      waveSurferRef.current.pause();
    } else {
      waveSurferRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  /**
   * secondToTime
   * Converts secound to formatted string HH:MM:SS
   * @param {number} second whole secound value.
   * @returns {string} HH:MM:SS string
   */
  function secondToTime(second) {
    const add0 = (num) => (num < 10 ? `0${num}` : String(num));
    const hour = Math.floor(second / 3600);
    const min = Math.floor((second - hour * 3600) / 60);
    const sec = Math.floor(second - hour * 3600 - min * 60);
    return [hour, min, sec].map(add0).join(":");
  }

  const renderRuler = () => {
    const segments = [];
    const interval = 30; // Adjust interval as needed

    for (let second = 0; second <= duration; second += interval) {
      segments.push(
        <Box
          key={second}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#666",
          }}
        >
          <Typography
            style={{
              fontSize: "12px",
              lineHeight: "16px",
            }}
          >
            {secondToTime(second)}
          </Typography>
          <div
            style={{
              bottom: 0,
              width: 1,
              backgroundColor: "#898989",
              height: 5,
            }}
          />
        </Box>
      );
    }
    return segments;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          position: "relative",
          marginLeft: "10.5px",
          justifyContent: "space-between",
        }}
      >
        {renderRuler()}
      </div>
      <div
        style={{
          display: "flex",
          position: "relative",
          marginBottom: "18px",
        }}
      >
        <div
          style={{
            width: "100%",
            alignItems: "center",
            display: "flex",
            boxSizing: "border-box",
            borderTop: "1px solid #D9D9D9",
          }}
        >
          {isLoading ? (
            <div
              style={{
                display: "flex",
                height: "30px",
                width: "30px",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "6px",
              }}
            >
              <CircularProgress size={24} />
            </div>
          ) : (
            <IconButton
              size="large"
              sx={{
                p: "0px",
                height: "30px",
                width: "30px",
                marginRight: "6px",
              }}
              onClick={togglePlay}
            >
              {isPlaying ? (
                <PauseCircleFilled
                  style={{ fill: "black", height: "30px", width: "30px" }}
                />
              ) : (
                <PlayCircleFilledIcon
                  style={{ fill: "black", height: "30px", width: "30px" }}
                />
              )}
            </IconButton>
          )}
          <div id={`waveform-container-${file.id}`} style={{ flexGrow: 1 }} />
        </div>
      </div>
    </>
  );
};

export default AudioPlayer;
