import React, { useEffect, useRef, useState, useMemo } from "react";
import { IconButton, Box, CircularProgress, useTheme } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import makeStyles from "@mui/styles/makeStyles";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import pathSoundNotification from "_assets/Polite.wav";
import { useUploadData } from "_utils/UploadDataContext";
import useRedirectConfirmEmail from "_utils/useRedirectConfirmEmail";
import { useUser } from "_utils/UserContext";
import isElectronHook from "_utils/isElectron";
import CloudCircleIcon from "@mui/icons-material/CloudCircle";
import fileDownload from "js-file-download";
import MenuIcon from "@mui/icons-material/Menu";
import { CONFIG_ENV } from "_utils/getConfig";
import { useDispatch } from "react-redux";
import { openLoadingAppModal } from "_redux/actions";
import AuxLogo from "_assets/AuxLogo";
import Notifications from "../Notifications/Notifications";
import {
   GET_NOTIFICATIONS,
   GET_USER_NOTIFICATION_SUBSCRIPTION,
   DISMISS_NOTIFICATION,
   GET_PROJECT_INVITATIONS,
} from "../../_apollo/queries";
import BackAndForwardButtons from "./BackAndForwardButtons";
import "./AppHeader.css";

const useStyles = makeStyles((theme) => ({
   logo: {
      color: theme.palette.coreApp.text,
   },
   notificationCount: {
      zIndex: 4,
      borderRadius: "50%",
      height: "16px",
      width: "16px",
      fontSize: "12px",
      lineHeight: "16px",
      color: "white",
      position: "absolute",
      top: "10px",
      right: "10px",
   },
   circularProgress: {
      position: "absolute",
      "& circle": {
         strokeWidth: 2,
      },
   },
   headerNavigation: {
      position: "relative",
      flexShrink: 1,
      minWidth: 200,
      display: "flex",
      alignItems: "center",
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 999,
      ...theme.noDrag,
   },
   headerIcons: {
      display: "flex",
      alignItems: "center",
      padding: "3px 0",
      ...theme.noDrag,
   },
   notifications: {
      position: "fixed",
      top: "54px",
      right: "1%",
      zIndex: 100,
      ...theme.noDrag,
   },
   menu: {
      ...theme.noDrag,
      height: 52,
      width: 52,
      borderRadius: "0px",
   },
}));

/**
 * AppHeader
 * This component is the header section of the app.
 *
 * App header bar including app logo, feedback button, search,
 * sync and notification icons.
 * @returns {JSX.Element} App header component.
 */
const AppHeader = () => {
   const theme = useTheme();
   useRedirectConfirmEmail();
   const dispatch = useDispatch();

   const {
      filesUploading,
      filesDownloadingState,
      downloadingZips,
      setDownloadingZips,
      setFilesDownloadingState,
      setFilesUploading,
      syncNotifications,
      setSyncNotifications,
      showTraySyncNotifications,
      setShowTraySyncNotifications,
   } = useUploadData();

   const isElectron = isElectronHook();
   useEffect(() => {
      dispatch(openLoadingAppModal(false));
   }, []);

   const classes = useStyles();
   const [user] = useUser();

   const [showTray, setShowTray] = useState(false);

   const [notifications, setNotifications] = useState([]);

   const averagePercentage = useMemo(() => {
      const excludeZipDownloadNotifications = syncNotifications.filter(
         (a) => a.typeName !== "ZipDownloaded"
      );
      const numberOfFiles = excludeZipDownloadNotifications.length;
      if (numberOfFiles === 0) return 0;
      const sum = excludeZipDownloadNotifications.reduce(
         (acc, notification) => acc + notification.file.plainPercentage,
         0
      );

      const average = sum / numberOfFiles;
      return average;
   }, [syncNotifications]);

   const { subscribeToMore, data: dataNotification } = useQuery(
      GET_NOTIFICATIONS,
      {
         fetchPolicy: "cache-and-network",
         skip: !user,
      }
   );

   useEffect(() => {
      const remoteNotifications = dataNotification?.getNotifications || [];
      const localNotifications = notifications.filter(
         // eslint-disable-next-line no-underscore-dangle
         (a) => a?.__typename !== "Notification"
      );
      setNotifications([
         ...(localNotifications || []),
         ...(remoteNotifications?.filter(
            (a) => a.typeName !== "ZipDownloaded"
         ) || []),
      ]);
      setSyncNotifications([
         ...(remoteNotifications?.filter(
            (a) => a.typeName === "ZipDownloaded"
         ) || []),
      ]);
   }, [dataNotification?.getNotifications]);

   /**
    * activateNotificationCount
    * Activates the notification count and displays the animation.
    */
   const activateNotificationCount = () => {
      const collapseModel = document.getElementById("notification-count");
      if (collapseModel) {
         collapseModel.classList.toggle("active");
         // After the animation is finnish reset the active state to the original
         setTimeout(() => {
            collapseModel.classList.toggle("active");
         }, 1000);
      }
   };

   useEffect(() => {
      setSyncNotifications((prevState) => [
         ...[...(downloadingZips || [])].map((zip) => ({
            typeName: "DownloadingZipFile",
            title: `Compressing ${zip.name}`,
            file: {
               uri: zip.name, // This is a unique value, used to set the key of the notification
            },
            cancelRequest: zip.cancelRequest,
         })),
         ...prevState.filter((n) => n?.typeName !== "DownloadingZipFile"),
      ]);
   }, [downloadingZips]);

   useEffect(() => {
      setSyncNotifications((a) => [
         ...a.filter(
            (n) =>
               n?.typeName === "DownloadingZipFile" ||
               n?.typeName === "ZipDownloaded"
         ),
         ...filesUploading.map((file) => {
            return {
               id: null,
               typeName: file.failed ? "FailedUpload" : "UploadingFile",
               title:
                  file.plainPercentage === 100
                     ? `Uploaded ${file.name}`
                     : `Uploading ${file.name}`,
               file,
               uri: file.uri,
               hash: file.hash,
               path: file.path,
               body: null,
               img: null,
               uniqueId: null,
               actionUrl: null,
            };
         }),
         ...filesDownloadingState,
      ]);
   }, [filesUploading, filesDownloadingState]);

   const [updateProjectInvites] = useLazyQuery(GET_PROJECT_INVITATIONS);
   const [dismissNotification] = useMutation(DISMISS_NOTIFICATION, {
      /**
       * DISMISS_NOTIFICATION update
       * @param {object} cache apollo cache instance.
       * @param {object} param1 options update method.
       * @param {object} param1.data response query.
       */
      update(cache, { data }) {
         if (data?.dismissNotification) {
            const { getNotifications } = cache.readQuery({
               query: GET_NOTIFICATIONS,
            });
            cache.writeQuery({
               query: GET_NOTIFICATIONS,
               data: {
                  getNotifications: getNotifications.filter(
                     (n) =>
                        !data?.dismissNotification?.find(
                           (nObj) => nObj?.id === n?.id
                        )
                  ),
               },
            });
         }
      },
   });

   useEffect(() => {
      if (subscribeToMore) {
         const unsubscribe = subscribeToMore({
            document: GET_USER_NOTIFICATION_SUBSCRIPTION,
            updateQuery: (prev, { subscriptionData }) => {
               if (!subscriptionData.data) {
                  return prev;
               }

               const newFeedItem =
                  subscriptionData.data.notificationSubscription;

               if (newFeedItem?.typeName === "ZipDownloaded") {
                  // fileDownload(newFeedItem.actionUrl, newFeedItem.title);
                  fetch(newFeedItem.actionUrl)
                     .then((response) => response.blob())
                     .then((blob) => {
                        fileDownload(blob, newFeedItem.title);
                     });

                  setDownloadingZips((p) =>
                     p.filter((f) => f.name !== newFeedItem.title)
                  );
                  setShowTray(false);
                  // Activate notification animation
                  activateNotificationCount();
                  if (isElectron && CONFIG_ENV !== "expo") {
                     const pathPoliteElectron =
                        window?.electronAPI?.getElectronAssetsPath();
                     const audio = new Audio(
                        `${pathPoliteElectron}/Polite.wav`
                     );
                     audio.play();
                  } else {
                     const audio = new Audio(pathSoundNotification);
                     audio.play();
                  }
                  return prev;
               }

               // Suppress notification when on the main feed of the project or on the same file feed
               if (newFeedItem?.typeName === "NewMessage") {
                  // All the messages contained a variable to scroll too.
                  const urlWithoutVariables = `/${
                     newFeedItem.actionUrl.split("?")[0]
                  }`;

                  // Both users are on the project page.
                  if (urlWithoutVariables === window.location.pathname) {
                     dismissNotification({
                        variables: {
                           ids: [newFeedItem.id],
                        },
                     });
                     return prev;
                  }
               }

               if (newFeedItem?.typeName === "ProjectInvite") {
                  setNotifications((a) => [newFeedItem, ...(a || [])]);
                  updateProjectInvites();
               }

               // Activate notification animation
               activateNotificationCount();
               // NOTE: This only triggers the sounds for electron once it is packaged. On dev mode it should have the same path as it does in web.
               // Also all the assets have to be added inside the forge.config.js as extraResources.
               if (isElectron && CONFIG_ENV !== "expo") {
                  const pathPoliteElectron =
                     window?.electronAPI?.getElectronAssetsPath();
                  const audio = new Audio(`${pathPoliteElectron}/Polite.wav`);
                  audio.play();
               } else {
                  const audio = new Audio(pathSoundNotification);
                  audio.play();
               }
               setShowTraySyncNotifications(false);
               return {
                  ...prev,
                  getNotifications: [newFeedItem, ...prev.getNotifications],
               };
            },
         });
         return () => (unsubscribe ? unsubscribe() : null);
      }
      return null;
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [subscribeToMore, user]);

   /**
    * handleClose
    * Handle closing notification tray. resets all notification states.
    */
   const handleClose = () => {
      setShowTray(false);
      setShowTraySyncNotifications(false);
   };

   const containerRef = useRef(false);
   const containerRefSync = useRef(false);
   const headerRef = useRef(false);

   useEffect(() => {
      /**
       * handleClickOutside
       * Handle click outside of notification tray. closes tray.
       * @param {object} event click event.
       */
      const handleClickOutside = (event) => {
         if (
            (containerRef.current &&
               !containerRef.current.contains(event.target) &&
               showTray &&
               !headerRef.current.contains(event.target)) ||
            (containerRefSync.current &&
               !containerRefSync.current.contains(event.target) &&
               showTraySyncNotifications &&
               !headerRef.current.contains(event.target))
         ) {
            handleClose();
         }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         // Unbind the event listener on clean up
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, [showTray, showTraySyncNotifications]);

   /**
    * handleDismissNotifications
    *
    * Dismisses notifications and removes them from redux.
    * @param {Array} ids Array of ids of notifications to dismiss.
    */
   const handleDismissNotifications = (ids) => {
      dismissNotification({
         variables: {
            ids,
         },
      });
      setNotifications((prev) => prev.filter((n) => !ids.includes(n?.id)));
   };

   return (
      <Box display="flex" flex={1} ref={headerRef}>
         {/* On windows allow a File menu */}
         {window?.electronAPI?.isWindows && (
            <IconButton
               className={classes.menu}
               onClick={(e) => {
                  e.preventDefault();
                  window?.electronAPI?.sendToMain("show-context-menu");
               }}
            >
               <MenuIcon />
            </IconButton>
         )}
         {/* On Web show the logo here */}
         {!isElectron && (
            <Box
               display="flex"
               alignItems="center"
               justifyContent="space-between"
               mb="14px"
               mt="16px"
               width="auto"
               position="relative"
            >
               <a href="/" alt="Aux home">
                  <AuxLogo className={classes.logo} />
               </a>
            </Box>
         )}
         <div
            className={classes.headerNavigation}
            style={{
               left: window?.electronAPI?.isWindows
                  ? "calc(50% - 42px)"
                  : "50%",
            }}
         >
            {/* TODO: audit use cases of search before re-adding it. */}
            {/* <SearchBar /> */}
         </div>

         <Box ml="auto" mr={window?.electronAPI?.isWindows ? "120px" : "0px"}>
            <div className={classes.headerIcons}>
               {/* TODO: in Project 5000 - Update to show uploads inline rather than trying to manage this messy header. */}
               <IconButton
                  id="notification-button"
                  onClick={() => {
                     if (showTraySyncNotifications) {
                        setShowTraySyncNotifications(false);
                     } else {
                        setShowTray(false);
                        setShowTraySyncNotifications(true);
                     }
                  }}
                  size="large"
                  variant="outlined"
               >
                  <CloudCircleIcon sx={{ color: theme.palette.coreApp.text }} />
                  <CircularProgress
                     color="primary"
                     className={classes.circularProgress}
                     variant="determinate"
                     value={averagePercentage}
                  />
                  <section
                     id="notification-count"
                     className={classes.notificationCount}
                     style={
                        syncNotifications.length > 0
                           ? {}
                           : {
                                display: "none",
                             }
                     }
                  >
                     <div className="badge pulse">
                        {syncNotifications.length > 99
                           ? "..."
                           : syncNotifications.length}
                     </div>
                  </section>
               </IconButton>

               <IconButton
                  id="notification-button"
                  onClick={() => {
                     if (showTray) {
                        setShowTray(false);
                     } else {
                        setShowTray(true);
                        setShowTraySyncNotifications(false);
                     }
                  }}
                  size="large"
                  variant="outlined"
                  sx={{
                     mr: "10px",
                  }}
               >
                  <NotificationsIcon
                     sx={{ color: theme.palette.coreApp.text }}
                  />
                  <section
                     id="notification-count"
                     className={classes.notificationCount}
                     style={
                        notifications.length > 0
                           ? {}
                           : {
                                display: "none",
                             }
                     }
                  >
                     <div className="badge pulse">
                        {notifications.length > 99
                           ? "..."
                           : notifications.length}
                     </div>
                  </section>
               </IconButton>

               <BackAndForwardButtons />
            </div>
            <div
               ref={containerRef}
               className={classes.notifications}
               style={{
                  visibility: showTray ? "visible" : "hidden",
               }}
            >
               <Notifications
                  notifications={notifications}
                  setNotifications={setNotifications}
                  clearAll={() => {
                     const notificationIds = notifications.map((n) => n.id);
                     setNotifications([]);
                     handleDismissNotifications(notificationIds);
                     setDownloadingZips([]);
                  }}
                  emptyText="No Notifications"
               />
            </div>
            {/* Sync notification container */}
            <div
               ref={containerRefSync}
               className={classes.notifications}
               style={{
                  visibility: showTraySyncNotifications ? "visible" : "hidden",
               }}
            >
               <Notifications
                  notifications={syncNotifications}
                  setNotifications={setSyncNotifications}
                  clearAll={() => {
                     setSyncNotifications([]);
                     setFilesDownloadingState([]);
                     setFilesUploading([]);
                     const notificationIds = syncNotifications
                        .filter((a) => a.typeName === "ZipDownloaded")
                        .map((n) => n.id);
                     handleDismissNotifications(notificationIds);
                  }}
                  emptyText="No Files Uploading"
               />
            </div>
         </Box>
      </Box>
   );
};

export default AppHeader;
