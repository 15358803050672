import {
   ApolloClient,
   InMemoryCache,
   split,
   HttpLink,
   ApolloLink,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { SubscriptionClient } from "subscriptions-transport-ws";
import appStorage from "_utils/appStorage";
import isElectronHook from "_utils/isElectron";
import store from "../_redux/store";
import { setError } from "../_redux/actions";
import getConfig from "../_utils/getConfig";

const { apiUrl, wsUrl } = getConfig();

// Define the Config based on the Environment
// React public environment variables must start with REACT_APP_.
// Web Sockets Subscription Setup

const wsClient = new SubscriptionClient(wsUrl, {
   reconnect: true,
   connectionParams: () => {
      const authToken = appStorage.getValue({ key: "auxJWT" });
      const deviceId = appStorage.getValue({ key: "auxDeviceID" }) || null;
      const selectedProfileUri = appStorage.getValue({
         key: "auxSelectedProfileURI",
      });
      if (deviceId) {
         return {
            authorization: authToken ? `Bearer: ${authToken}` : null,
            auxSelectedProfileURI: selectedProfileUri,
            deviceId,
         };
      }
      return {
         authorization: authToken ? `Bearer: ${authToken}` : null,
         auxSelectedProfileURI: selectedProfileUri,
      };
   },
});
const wsLink = new WebSocketLink(wsClient);
// Apollo GraphQL Client Setup
const authLink = new ApolloLink((operation, forward) => {
   const authToken = appStorage.getValue({ key: "auxJWT" });
   const deviceId = appStorage.getValue({ key: "auxDeviceID" }) || null;
   const selectedProfileUri = appStorage.getValue({
      key: "auxSelectedProfileURI",
   });
   const isElectron = isElectronHook();
   const headers = {};

   headers.electron = isElectron;

   if (authToken) {
      headers.authorization = `Bearer: ${authToken}`;
   }
   if (deviceId) {
      headers.deviceId = deviceId;
   }
   if (selectedProfileUri) {
      headers.auxSelectedProfileURI = selectedProfileUri;
   }
   // add the authorization to the headers
   operation.setContext({
      headers,
   });

   return forward(operation);
});
const errorLink = onError(({ networkError, graphQLErrors }) => {
   // Only show error for network error, not for unauthenticated error.
   if (
      networkError &&
      networkError.toString() === "TypeError: Failed to fetch"
   ) {
      store.dispatch(setError(true));
   } else if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
         const allowErrorsOn = [
            "updateProfileInfo",
            "updateContactInfo",
            "deleteProfile",
            "updatePassword",
            "redeemCoupon",
            "acceptProjectInvite",
            "signin",
            "signup",
            "validateCoupon",
            "checkHashProjectInvite",
         ];

         // If path is not on this lest throw error
         if (path && !allowErrorsOn.some((a) => a === path[0])) {
            throw new Error(
               `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
                  locations
               )}, Path: ${path}`
            );
         }
      });
   }
});

const httpLink = new HttpLink({ uri: apiUrl });
const link = errorLink.concat(authLink.concat(httpLink));

// Every request will be socket or http therefore we split it depends of the type of request
const splitLink = split(
   ({ query }) => {
      const definition = getMainDefinition(query);
      return (
         definition.kind === "OperationDefinition" &&
         definition.operation === "subscription"
      );
   },
   wsLink,
   link
);

const client = new ApolloClient({
   link: splitLink,
   cache: new InMemoryCache({
      addTypename: true, // Important this is what is was causing not to update single objects with the normalitzation pattern
      typePolicies: {
         Query: {
            fields: {
               getFolders: {
                  // Don't cache separate results based on the uri requested.
                  keyArgs: ["uri", "trashed"],
                  // Concatenate the incoming list items with
                  // the existing list items.
                  merge(existing, incoming, { args: { offset = 0 } }) {
                     // Slicing is necessary because the existing data is
                     // immutable, and frozen in development.
                     const merged = existing?.folders
                        ? existing?.folders.slice(0)
                        : [];
                     for (let i = 0; i < incoming.folders.length; i += 1) {
                        merged[offset + i] = incoming?.folders[i];
                     }
                     return { folders: merged, pageInfo: incoming?.pageInfo };
                  },
               },
               getFiles: {
                  // Don't cache separate results based on the uri requested.
                  keyArgs: ["uri", "trashed"],
                  // Concatenate the incoming list items with
                  // the existing list items.
                  merge(existing, incoming, { args: { offset = 0 } }) {
                     // Slicing is necessary because the existing data is
                     // immutable, and frozen in development.
                     const merged = existing?.files
                        ? existing?.files.slice(0)
                        : [];
                     for (let i = 0; i < incoming.files.length; i += 1) {
                        merged[offset + i] = incoming?.files[i];
                     }
                     return { files: merged, pageInfo: incoming?.pageInfo };
                  },
               },
               getModalFolders: {
                  // Don't cache separate results based on
                  // any of this field's arguments.
                  keyArgs: false,
                  // Concatenate the incoming list items with
                  // the existing list items.
                  merge(existing, incoming, { args: { offset = 0 } }) {
                     // Slicing is necessary because the existing data is
                     // immutable, and frozen in development.
                     const merged = existing?.folders
                        ? existing?.folders.slice(0)
                        : [];
                     for (let i = 0; i < incoming.folders.length; i += 1) {
                        merged[offset + i] = incoming?.folders[i];
                     }
                     return { folders: merged, pageInfo: incoming?.pageInfo };
                  },
               },
               getModalFiles: {
                  // Don't cache separate results based on
                  // any of this field's arguments.
                  keyArgs: false,
                  // Concatenate the incoming list items with
                  // the existing list items.
                  merge(existing, incoming, { args: { offset = 0 } }) {
                     // Slicing is necessary because the existing data is
                     // immutable, and frozen in development.
                     const merged = existing?.files
                        ? existing?.files.slice(0)
                        : [];
                     for (let i = 0; i < incoming.files.length; i += 1) {
                        merged[offset + i] = incoming?.files[i];
                     }
                     return { files: merged, pageInfo: incoming?.pageInfo };
                  },
               },
               guideUsers: {
                  // Don't cache separate results based on
                  // any of this field's arguments.
                  keyArgs: false,
                  // Concatenate the incoming list items with
                  // the existing list items.
                  merge(existing, incoming, { args: { offset = 0 } }) {
                     // Slicing is necessary because the existing data is
                     // immutable, and frozen in development.
                     const merged = existing ? existing.slice(0) : [];
                     for (let i = 0; i < incoming.length; i += 1) {
                        merged[offset + i] = incoming[i];
                     }
                     return merged;
                  },
               },
               getProfiles: {
                  // Don't cache separate results based on
                  // any of this field's arguments.
                  keyArgs: ["sort", "tags"],
                  // Concatenate the incoming list items with
                  // the existing list items.
                  merge(existing, incoming, { args: { offset = 0 } }) {
                     // Slicing is necessary because the existing data is
                     // immutable, and frozen in development.
                     const merged = existing ? existing.slice(0) : [];
                     for (let i = 0; i < incoming.length; i += 1) {
                        merged[offset + i] = incoming[i];
                     }
                     return merged;
                  },
               },
               getComments: {
                  // Don't cache separate results based on
                  // any of this field's arguments.
                  keyArgs: ["id", "packagedType"],
                  // Concatenate the incoming list items with
                  // the existing list items.
                  merge(existing, incoming, { args: { offset = 0 } }) {
                     // Slicing is necessary because the existing data is
                     // immutable, and frozen in development.
                     const merged = existing ? existing.slice(0) : [];
                     for (let i = 0; i < incoming.length; i += 1) {
                        merged[offset + i] = incoming[i];
                     }
                     return merged;
                  },
               },
               userContacts: {
                  // Don't cache separate results based on
                  // any of this field's arguments.
                  keyArgs: ["search"],
                  // Concatenate the incoming list items with
                  // the existing list items.
                  merge(existing, incoming, { args: { offset = 0 } }) {
                     // Slicing is necessary because the existing data is
                     // immutable, and frozen in development.
                     const merged = existing ? existing.slice(0) : [];
                     for (let i = 0; i < incoming.length; i += 1) {
                        merged[offset + i] = incoming[i];
                     }
                     return merged;
                  },
               },
               searchFiles: {
                  // Don't cache separate results based on
                  // any of this field's arguments.
                  keyArgs: ["search"],
                  // Concatenate the incoming list items with
                  // the existing list items.
                  merge(existing, incoming, { args: { offset = 0 } }) {
                     // Slicing is necessary because the existing data is
                     // immutable, and frozen in development.
                     const merged = existing ? existing.slice(0) : [];
                     for (let i = 0; i < incoming.length; i += 1) {
                        merged[offset + i] = incoming[i];
                     }
                     return merged;
                  },
               },
               feedMessagesPagination: {
                  // Don't cache separate results based on
                  // any of this field's arguments.
                  keyArgs: ["search"],
                  // Concatenate the incoming list items with
                  // the existing list items.
                  merge(existing, incoming, { args: { offset = 0 } }) {
                     // Slicing is necessary because the existing data is
                     // immutable, and frozen in development.
                     const merged = existing ? existing.slice(0) : [];
                     for (let i = 0; i < incoming.length; i += 1) {
                        merged[offset + i] = incoming[i];
                     }
                     return merged;
                  },
               },
            },
         },
      },
   }),
   onError: (e) => {
      throw Error(e);
   },
});

export { client, wsClient };
