import React from "react";
import CustomModal from "_components/Modal/CustomModal";
import { Button } from "@mui/material";

/**
 * Folder With Same Name
 *
 * If the user tries to accept an invite to a folder with the same name as one of their folders, this modal will appear.
 * @param {object} root0 props.
 * @param {boolean} root0.open if the modal is open or closed.
 * @param {Function} root0.handleClose function that will be called to close the modal.
 * @param {string} root0.title project title.
 * @returns {JSX.Element} modal.
 */
function FolderWithSameName({ open, handleClose, title }) {
  return (
    <CustomModal
      header={
        <h4 style={{ marginTop: "5px", textAlign: "center" }}>
          Folder {title} already exists
        </h4>
      }
      fullWidth="sm"
      open={open}
      handleClose={handleClose}
    >
      <div
        style={{
          marginTop: "10px",
          textAlign: "center",
        }}
      >
        <p
          style={{
            marginBottom: "20px",
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          You already have a folder with the same name as this project. Please
          rename or move this folder before you accept the invite.
        </p>
        <Button variant="contained" color="primary" onClick={handleClose}>
          OK
        </Button>
      </div>
    </CustomModal>
  );
}

export default FolderWithSameName;
