import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Chip, darken } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { EmojiEvents } from "@mui/icons-material";
import { ReactComponent as GuideIcon } from "../_assets/icons/guide-icon-white-24.svg";

const useStyles = makeStyles(() => ({
   chipAward: {
      backgroundColor: "#FFD645",
      color: "white",
      "&:hover": {
         backgroundColor: darken("#FFD645", 0.1),
      },
   },
   chipGuide: {
      backgroundColor: "#47D3FF",
      color: "white",
      "&:hover": {
         backgroundColor: darken("#47D3FF", 0.1),
      },
   },
   chipTag: {
      backgroundColor: "#F2F2F2",
      color: "black",
      "&:hover": {
         backgroundColor: darken("#F2F2F2", 0.1),
      },
   },
}));

/**
 * Tag
 *
 * Single tag chip component.
 * @param {object} props props
 * @param {number} props.id Tag id.
 * @param {Function} props.removeTags Remove tag function.
 * @param {object} props.tag tag object.
 * @param {string} props.tag.name Tag name.
 * @param {number} props.tag.awardsYear Year of the awards if awards tag.
 * @param {boolean} props.tag.highlightedGuide Guide featured tag.
 * @param {string} props.color Color to show the tag.
 * @returns {JSX.Element} Tag component.
 */
const Tag = ({
   id,
   removeTags,
   tag: { name, awardsYear, highlightedGuide },
   color,
}) => {
   const classes = useStyles();
   const isAward = useMemo(() => !!awardsYear, [awardsYear]);

   /**
    * Get Class Name
    * @returns {string} class name
    */
   const getClassName = () => {
      if (awardsYear) {
         return classes.chipAward;
      }
      if (highlightedGuide) {
         return classes.chipGuide;
      }
      return classes.chipTag;
   };

   /**
    * Get Icon
    * @returns {JSX.Element} Icon
    */
   const getIcon = () => {
      if (awardsYear) {
         return <EmojiEvents style={{ color: "white" }} />;
      }
      if (highlightedGuide) {
         return <GuideIcon />;
      }
      return null;
   };

   return (
      <Chip
         className={getClassName()}
         color={color || "primary"}
         key={id}
         label={name}
         icon={getIcon()}
         onDelete={removeTags && !isAward ? () => removeTags(name) : null}
         style={{
            marginBottom: 5,
            marginRight: 5,
            cursor: "inherit",
            padding: "3px 12px",
         }}
      />
   );
};

Tag.propTypes = {
   tag: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      awardsYear: PropTypes.bool,
      highlightedGuide: PropTypes.bool,
   }).isRequired,
   id: PropTypes.string.isRequired,
   removeTags: PropTypes.func,
   color: PropTypes.string,
};

Tag.defaultProps = {
   removeTags: null,
   color: null,
};

export default Tag;
