import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";

import useMediaQuery from "@mui/material/useMediaQuery";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { useQueryParam, StringParam } from "use-query-params";
import AuxAwardsLogo from "_assets/AuxAwardsLogo";
import { useUser } from "_utils/UserContext";
import DarkButton from "_components/Buttons/DarkButton";
import AuxOpenMusicLogo from "../../_assets/AuxOpenMusicLogo";
import HeaderDropdownIcon from "../../_assets/HeaderDropdownIcon";
import AuxPlainLogo from "../../_assets/AuxPlainLogo";
import AuxConnectLogo from "../../_assets/AuxConnectLogo";
import DownloadAppButton from "../DownloadAppButton";

const LightButton = withStyles(() => ({
   root: {
      color: "black",
      backgroundColor: "white",
      "&:hover": {
         backgroundColor: "white",
      },
   },
}))(Button);

const useStyles = makeStyles((theme) => ({
   header: {
      display: "flex",
      alignItems: "center",
      padding: "1.88rem 2.5rem",
      "@media (max-width: 768px)": {
         padding: "16px",
      },
   },
   logo: {
      marginRight: "60px",
   },
   spacingBtn: {
      margin: "16px 26px 16px 0",
   },
   itemsHeaderSpacing: {
      margin: "16px 28px 16px 0",
      fontSize: theme.typography.h6.fontSize,
      lineHeight: "24px",
      "& a": {
         textDecoration: "none",
         "&:hover": {
            color: theme.palette.primary.main,
         },
      },
   },
   itemsHeaderProfileSpacing: {
      margin: "16px 16px 16px 5px",
      fontSize: theme.typography.h6.fontSize,
      lineHeight: "24px",
      "& a": {
         textDecoration: "none",
         color: "black",
         "&:hover": {
            color: theme.palette.primary.main,
         },
      },
   },
   signUpBtn: {
      padding: "8px 22px",
      marginRight: "20px",
   },
   btnOutlinedLgSize: {
      padding: "8px 22px",
   },
   dropdownBtn: {
      position: "fixed",
      top: "0px",
      right: "0px",
      zIndex: 3,
   },
   dropdownMenu: {
      position: "fixed",
      zIndex: 4,
      backgroundColor: "white",
      top: 0,
      left: 0,
      right: 0,
      padding: "20px",
   },
   boxMenuHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: `solid 2px ${theme.palette.secondary.shade5}`,
      paddingBottom: "20px",
   },
   boxesMenu: {
      borderBottom: `solid 2px ${theme.palette.secondary.shade5}`,
      padding: "16px 0",
      lineHeight: "24px",
      fontSize: theme.typography.h6.fontSize,
      "& a": {
         textDecoration: "none",
         color: "black",
         "&:hover": {
            color: theme.palette.primary.main,
         },
      },
   },
   boxFooterMenu: {
      padding: "20px 0 0",
      display: "flex",
      alignItems: "center",
      "& button": {
         flex: 1,
      },
   },
   closeBtn: {
      padding: 5,
   },
   white: {
      color: "#F3F3F3",
   },
   black: {
      color: "black",
   },
}));

/**
 * Header
 *
 * Header used across marketing pages.
 * @param {props} props props
 * @returns {JSX.Element} Header component.
 */
const Header = ({ whiteText, showDownloadButton }) => {
   const [user] = useUser();
   const classes = useStyles();
   const mobileSize = useMediaQuery("(max-width:900px)");
   const tabletSize = useMediaQuery("(max-width:1000px)");
   const tabletSizeLg = useMediaQuery("(max-width:1080px)");
   const [hash] = useQueryParam("hash", StringParam);

   const [showDropdown, setShowDropdown] = useState(false);

   const hideButtons = useMemo(() => {
      const routes = ["connect", "awards"];
      return routes.some((r) => window.location.pathname.includes(r));
   }, [window.location.pathname]);
   const homePageOrPricingPage = useMemo(() => {
      return (
         window.location.pathname === "/" ||
         window.location.pathname === "/pricing"
      );
   }, [window.location.pathname]);

   /**
    * App Logo
    *
    * Conditionally renders the app logo depending on the page.
    * @param {props} props props
    * @param {props} props.isDropDown Is the logo displayed as part of a dropdown.
    * @returns {JSX.Element} Link component containing the Aux logo.
    */
   const AppLogo = ({ isDropDown }) => {
      AppLogo.propTypes = {
         isDropDown: PropTypes.bool,
      };

      AppLogo.defaultProps = {
         isDropDown: false,
      };
      if (isDropDown) {
         if (window.location.pathname.includes("connect")) {
            return (
               <Link to="/connect">
                  <AuxConnectLogo />
               </Link>
            );
         }
         return (
            <Link to="/">
               <AuxPlainLogo />
            </Link>
         );
      }

      if (window.location.pathname.includes("connect")) {
         return (
            <Link to="/connect" className={classes.logo}>
               <AuxConnectLogo style={whiteText ? { fill: "white" } : {}} />
            </Link>
         );
      }
      if (window.location.pathname.includes("awards")) {
         return (
            <Link to="/awards" className={classes.logo}>
               <AuxAwardsLogo style={whiteText ? { fill: "white" } : {}} />
            </Link>
         );
      }
      return (
         <Link to="/" className={classes.logo}>
            <AuxOpenMusicLogo
               style={{
                  fill: whiteText ? "white" : "black",
               }}
               width="120px"
               height="auto"
            />
         </Link>
      );
   };

   return (
      <div className={classes.header}>
         {showDropdown && (
            <div className={classes.dropdownMenu}>
               <div className={classes.boxMenuHeader}>
                  <AppLogo isDropDown />

                  <IconButton
                     className={classes.closeBtn}
                     onClick={() => setShowDropdown(false)}
                     size="large"
                  >
                     <CloseIcon />
                  </IconButton>
               </div>
               {hideButtons ? (
                  <div className={classes.boxesMenu}>
                     <Link to="/">What is Aux?</Link>
                  </div>
               ) : (
                  <>
                     <div className={classes.boxesMenu}>
                        <Link to="/info/looplm">About LoopLM</Link>
                     </div>
                     <div className={classes.boxesMenu}>
                        <Link to="/connect">Blog</Link>
                     </div>
                     <div className={classes.boxesMenu}>
                        <Link to="/pricing">Pricing</Link>
                     </div>
                  </>
               )}
               <div className={classes.boxFooterMenu}>
                  {user?.profile ? (
                     <>
                        <Avatar src={user?.profile?.photoUrlOrGravatar} />
                        <span className={classes.itemsHeaderProfileSpacing}>
                           {user?.profile?.name}
                        </span>
                        <span className={classes.spacingBtn}>
                           <DarkButton
                              size="large"
                              variant="contained"
                              component={Link}
                              to="/feed"
                           >
                              Launch
                           </DarkButton>
                        </span>
                     </>
                  ) : (
                     <>
                        <DarkButton
                           component={Link}
                           to={`/signup${hash ? `?hash=${hash}` : ""}`}
                           size="large"
                           className={classes.signUpBtn}
                        >
                           Sign up
                        </DarkButton>
                        <Button
                           component={Link}
                           to={`/signin${hash ? `?hash=${hash}` : ""}`}
                           size="large"
                           className={classes.btnOutlinedLgSize}
                           variant="outlined"
                        >
                           Sign in
                        </Button>
                     </>
                  )}
               </div>
            </div>
         )}
         <AppLogo />

         {mobileSize ? (
            <IconButton
               className={classes.dropdownBtn}
               style={
                  whiteText
                     ? {
                          backgroundColor: "transparent",
                          padding: "0px",
                          margin: "12px",
                       }
                     : {}
               }
               onClick={() => setShowDropdown(true)}
               size="large"
            >
               {/* #4C4C50 */}
               <HeaderDropdownIcon
                  fill={homePageOrPricingPage ? "rgb(76,76, 80)" : "black"}
               />
            </IconButton>
         ) : (
            <>
               {hideButtons ? (
                  <div className={classes.itemsHeaderSpacing}>
                     <Link
                        className={whiteText ? classes.white : classes.black}
                        to="/"
                     >
                        What is Aux?
                     </Link>
                  </div>
               ) : (
                  <>
                     <div className={classes.itemsHeaderSpacing}>
                        <Link
                           className={whiteText ? classes.white : classes.black}
                           to="/info/looplm"
                        >
                           About LoopLM
                        </Link>
                     </div>
                     <div className={classes.itemsHeaderSpacing}>
                        <Link
                           className={whiteText ? classes.white : classes.black}
                           to="/connect"
                        >
                           Connect Blog
                        </Link>
                     </div>
                     <div className={classes.itemsHeaderSpacing}>
                        <Link
                           className={whiteText ? classes.white : classes.black}
                           to="/pricing"
                        >
                           Pricing
                        </Link>
                     </div>
                  </>
               )}
               <div
                  style={{
                     marginLeft: "auto",
                     display: "flex",
                     alignItems: "center",
                  }}
               >
                  {user?.profile ? (
                     <>
                        <>
                           <Avatar src={user?.profile?.photoUrlOrGravatar} />
                           <span
                              className={`${
                                 classes.itemsHeaderProfileSpacing
                              } ${whiteText ? classes.white : classes.black}`}
                           >
                              {user?.profile?.name}
                           </span>
                        </>
                        {whiteText ? (
                           <LightButton
                              size="large"
                              variant="contained"
                              component={Link}
                              to="/feed"
                           >
                              Launch
                           </LightButton>
                        ) : (
                           <DarkButton
                              size="large"
                              variant="contained"
                              component={Link}
                              to="/feed"
                           >
                              Launch
                           </DarkButton>
                        )}
                     </>
                  ) : (
                     <>
                        <Button
                           color={whiteText ? "light" : "dark"}
                           size="large"
                           variant="text"
                           component={Link}
                           style={{
                              borderRight: whiteText
                                 ? "1px solid #F3F3F3"
                                 : "1px solid #000000",
                              borderRadius: 0,
                           }}
                           to={`/signin${hash ? `?hash=${hash}` : ""}`}
                        >
                           Sign In
                        </Button>
                        <Button
                           color={whiteText ? "light" : "dark"}
                           size="large"
                           variant="text"
                           component={Link}
                           to={`/signup${hash ? `?hash=${hash}` : ""}`}
                        >
                           Sign Up
                        </Button>
                        {showDownloadButton && !tabletSizeLg && (
                           <DownloadAppButton
                              sx={{
                                 color: whiteText ? "white" : "black",
                                 borderColor: whiteText ? "white" : "black",
                                 padding: "8px 9px",
                                 fontSize: " 0.9375rem",
                                 flex: 1,
                                 maxWidth: "177px",
                              }}
                              variant="outlined"
                              color={whiteText ? "light" : "dark"}
                              text="Download"
                           />
                        )}
                     </>
                  )}
               </div>
            </>
         )}
      </div>
   );
};

Header.propTypes = {
   whiteText: PropTypes.bool,
   showDownloadButton: PropTypes.bool,
};
Header.defaultProps = {
   whiteText: false,
   showDownloadButton: false,
};

export default Header;
