import {
   SHOW_MEDIA_PLAYER,
   CHANGE_TRACK,
   PLAY_STOP_TRACK,
   MANAGE_PROFILE,
   UPDATE_UPLOAD_COUNT,
   SET_UPGRADE_MODAL,
   SET_BACK_HISTORY,
   SET_PROJECT_MESSAGE,
   TOGGLE_MAIN_MENU,
   SET_ERROR,
   SET_DAW_COMMENT,
   SHOW_DOWNLOAD_LINK_MODAL,
   OPEN_LOADING_APP_MODAL,
} from "./actionTypes";

/**
 * setBackHistory
 * @param {object} backHistory backHistory object
 * @deprecated Create and use React Context instead of redux for all future actions and refactor as we go.
 * @returns {object} Redux action.
 */
export const setBackHistory = (backHistory) => ({
   type: SET_BACK_HISTORY,
   payload: { backHistory },
});

/**
 * setMessageProject
 * @deprecated Create and use React Context instead of redux for all future actions and refactor as we go.
 * @param {object} message message object
 * @returns {object} Redux action.
 */
export const setMessageProject = (message) => ({
   type: SET_PROJECT_MESSAGE,
   payload: { message },
});

/**
 * showMediaPlayerAction
 * @deprecated Create and use React Context instead of redux for all future actions and refactor as we go.
 * @param {object} file file object
 * @param {object} messages messages object
 * @param {object} pause pause object
 * @returns {object} Redux action.
 */
export const showMediaPlayerAction = (file, messages, pause) => ({
   type: SHOW_MEDIA_PLAYER,
   payload: { file, messages, pause },
});

/**
 * changeTrack
 * @deprecated Create and use React Context instead of redux for all future actions and refactor as we go.
 * @param {object} nextOrBack nextOrBack object
 * @returns {object} Redux action.
 */
export const changeTrack = (nextOrBack) => ({
   type: CHANGE_TRACK,
   payload: { nextOrBack },
});
/**
 * setIsPaused
 * @deprecated Create and use React Context instead of redux for all future actions and refactor as we go.
 * @param {object} boolPlayPause boolPlayPause object
 * @returns {object} Redux action.
 */
export const setIsPaused = (boolPlayPause) => ({
   type: PLAY_STOP_TRACK,
   payload: { boolPlayPause },
});
/**
 * setManagingProfile
 * @param {object} teamId teamId object
 * @param {object} profile profile object
 * @deprecated Create and use React Context instead of redux for all future actions and refactor as we go.
 * @returns {object} Redux action.
 */
export const setManagingProfile = (teamId, profile) => ({
   type: MANAGE_PROFILE,
   payload: { teamId, profile },
});

/**
 * updateUploadCount
 * @param {object} amount amount object
 * @deprecated Create and use React Context instead of redux for all future actions and refactor as we go.
 * @returns {object} Redux action.
 */
export const updateUploadCount = (amount) => ({
   type: UPDATE_UPLOAD_COUNT,
   payload: { amount },
});
/**
 * setUpgradeModal
 * @param {object} show show object
 * @deprecated Create and use React Context instead of redux for all future actions and refactor as we go.
 * @returns {object} Redux action.
 */
export const setUpgradeModal = (show) => ({
   type: SET_UPGRADE_MODAL,
   payload: show,
});
/**
 * toggleAppMenu
 * @param {object} collapse collapse object
 * @deprecated Create and use React Context instead of redux for all future actions and refactor as we go.
 * @returns {object} Redux action.
 */
export const toggleAppMenu = (collapse) => ({
   type: TOGGLE_MAIN_MENU,
   payload: { collapse },
});
/**
 * Loading App Modal
 * @param {boolean} open if true opens the modal
 * @returns {object} Redux action.
 */
export const openLoadingAppModal = (open) => ({
   type: OPEN_LOADING_APP_MODAL,
   payload: open,
});

/**
 * setError
 * @param {object} networkError networkError object
 * @returns {object} Redux action.
 */
export const setError = (networkError) => ({
   type: SET_ERROR,
   payload: networkError,
});
/**
 * setDawComment
 * @param {object} dawMessage dawMessage object
 * @deprecated Create and use React Context instead of redux for all future actions and refactor as we go.
 * @returns {object} Redux action.
 */
export const setDawComment = (dawMessage) => ({
   payload: { dawMessage },
   type: SET_DAW_COMMENT,
});

/**
 * setShowDownloadLinkModal
 * @param {object} show show object
 * @deprecated Create and use React Context instead of redux for all future actions and refactor as we go.
 * @returns {object} Redux action.
 */
export const setShowDownloadLinkModal = (show) => ({
   payload: show,
   type: SHOW_DOWNLOAD_LINK_MODAL,
});
