import { Close } from "@mui/icons-material";
import { Avatar, Box, IconButton, Skeleton, Typography } from "@mui/material";
import React, {
   useCallback,
   useEffect,
   useMemo,
   useRef,
   useState,
} from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import ConnectionStateButton from "_components/ConnectionStateButton";
import { useUser } from "_utils/UserContext";
import useGetConnections from "_utils/useGetConnections";
import isElectronHook from "_utils/isElectron";

const useStyles = makeStyles(() => ({
   forYouCard: {
      width: "342px",
      background: "rgba(255, 255, 255, 0.3)",
      borderStyle: "solid",
      borderWidth: "0.5px",
      boxShadow: "2px 2px 3px rgba(145, 120, 165, 0.2)",
      backdropFilter: "blur(15px)",
      borderRadius: "4px",
      padding: "15px 10px 10px 10px",
      boxSizing: "border-box",
   },
   avatar: {
      borderWidth: "0.5px",
      borderStyle: "solid",
   },
   description: {
      display: "-webkit-box",
      maxWidth: "226px",
      marginTop: "10px",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
   },
}));

/**
 * ForYouCard
 * A card that displays recommended connection using ai files tagging
 * @param {object} props Props
 * @param {object} props.connection Connection object containing profile, percentage and tags
 * @param {Function} props.removeConnection Function to remove a connection from the connections list
 * @returns {JSX.Element} ForYouCard component
 */
const ForYouCard = ({ connection, removeConnection }) => {
   const classes = useStyles();
   const isElectron = isElectronHook();
   const { profile } = useMemo(
      () => connection || { profile: null, percentage: null, tags: null },
      [connection]
   );
   const [user] = useUser();
   const [connections] = useGetConnections(false, user);

   const currentProfile = useMemo(() => user?.profile, [user]);
   const [smallView, setSmallView] = useState(false);
   const containerRef = useRef(null);

   useEffect(() => {
      if (containerRef.current) {
         // get parents width
         const parentWidth = containerRef.current.parentElement.clientWidth;
         setSmallView(parentWidth < 360);
      }
   }, [containerRef]);

   const ConnectionButton = useCallback(
      () => (
         <ConnectionStateButton
            connection={
               currentProfile?.connections?.sent.find(
                  (c) => c.receiverProfile?.id === profile.id
               ) ||
               currentProfile?.connections?.received.find(
                  (c) => c.senderProfile?.id === profile.id
               )
            }
            isSender={
               !!currentProfile?.connections?.sent.find(
                  (c) => c.receiverProfile?.id === profile.id
               )
            }
            isReceiver={
               !!currentProfile?.connections?.received.find(
                  (c) => c.senderProfile?.id === profile.id
               )
            }
            buttonStyles={{
               size: "small",
               variant: "contained",
               sx: {
                  backgroundColor: "black",
               },
            }}
            publicProfile={profile}
         />
      ),
      [profile, currentProfile]
   );

   const connectionAccepted = useMemo(() =>
      connections.find((c) => c?.uri === profile?.uri)
   );

   if (!connection) {
      return (
         <Box
            className={classes.forYouCard}
            sx={{
               borderColor: isElectron ? "rgba(255,255,255,0.5)" : "white",
            }}
            ref={containerRef}
         >
            <Box display="flex" gap="14px">
               <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap="5px"
               >
                  <Skeleton width={45} height={45} variant="circular" />
                  {!smallView && <Skeleton width={75} variant="rounded" />}
               </Box>
               <Box>
                  <Box
                     display="flex"
                     justifyContent="space-between"
                     flex={1}
                     alignItems="center"
                  >
                     <Skeleton width={34} variant="text" mb="5px" />
                     {smallView && <Skeleton width={75} variant="rounded" />}
                  </Box>
                  <Skeleton width={206} height={48} variant="text" />
               </Box>
            </Box>
            <Box display="flex" gap="10px" mt="10px">
               <Skeleton width={34} variant="text" />
               <Skeleton width={74} variant="text" />
               <Skeleton width={54} variant="text" />
            </Box>
         </Box>
      );
   }

   if (connectionAccepted) {
      return null;
   }

   return (
      <Box
         position="relative"
         className={classes.forYouCard}
         sx={{
            borderColor: isElectron ? "rgba(255,255,255,0.5)" : "white",
         }}
         ref={containerRef}
      >
         <IconButton
            sx={{
               position: "absolute",
               right: 0,
               top: 0,
            }}
            onClick={removeConnection}
         >
            <Close sx={{ width: 14, height: 14, color: "black" }} />
         </IconButton>
         <Box>
            <Box display="flex" gap="14px">
               <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{ width: smallView ? "auto" : 65 }}
               >
                  <Avatar
                     className={classes.avatar}
                     src={profile.photoUrlOrGravatar}
                     sx={{
                        width: smallView ? 45 : 65,
                        height: smallView ? 45 : 65,
                        borderColor: isElectron
                           ? "rgba(255,255,255,0.5)"
                           : "white",
                     }}
                  />
                  {!smallView && (
                     <Box display="flex" mt="6px">
                        <ConnectionButton />
                     </Box>
                  )}
               </Box>
               <Box flex={1}>
                  <Box
                     display={smallView && "flex"}
                     height={smallView ? "100%" : "initial"}
                     alignItems={smallView && "center"}
                     justifyContent="space-between"
                  >
                     <Box>
                        <Typography
                           component="p"
                           variant="medium"
                           fontWeight="bold"
                           noWrap
                           mb="5px"
                        >
                           {profile.name}
                        </Typography>
                        {/* <Typography
                           component="p"
                           color="hover.darkBlue"
                           fontWeight="bold"
                           variant="small"
                        >
                           {percentage}% match
                        </Typography> */}
                     </Box>

                     {smallView && (
                        <Box display="flex" alignSelf="flex-end">
                           <ConnectionButton />
                        </Box>
                     )}
                  </Box>

                  {profile.description && !smallView && (
                     <Typography
                        component="p"
                        variant="small"
                        className={classes.description}
                     >
                        {profile.description}
                     </Typography>
                  )}
               </Box>
            </Box>
            {/* NOTE: matches will all have the same tags in the current implementation... */}
            {/* <Box display="flex" gap="10px" mt="9px">
               {tags.map((tag) => (
                  <Typography
                     variant="small"
                     color={smallView ? "black" : "hover.darkBlue"}
                  >
                     #{tag.name}
                  </Typography>
               ))}
            </Box> */}
         </Box>
      </Box>
   );
};

ForYouCard.propTypes = {
   connection: PropTypes.shape({
      profile: PropTypes.shape({
         id: PropTypes.string.isRequired,
         name: PropTypes.string.isRequired,
         description: PropTypes.string,
         photoUrlOrGravatar: PropTypes.string.isRequired,
         uri: PropTypes.string.isRequired,
      }).isRequired,
      percentage: PropTypes.number.isRequired,
      tags: PropTypes.arrayOf(
         PropTypes.shape({
            name: PropTypes.string.isRequired,
         })
      ).isRequired,
   }).isRequired,
   removeConnection: PropTypes.func.isRequired,
};

export default ForYouCard;
