import React from "react";
import {
   Container,
   Button,
   TextField,
   InputLabel,
   Card,
   Box,
   Grid,
} from "@mui/material";

import { useHistory, useParams } from "react-router-dom";

// Apollo
import { useMutation } from "@apollo/react-hooks";
// Forms
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PublicPrivateLayoutSwitcher from "_layouts/PublicPrivateLayoutSwitcher";
import { UPDATE_PASSWORD_RESET } from "../_apollo/queries";

/**
 * Password Reset Update Page
 * @returns {JSX.Element} The Password Reset Update Page
 */
const PasswordResetUpdatePage = () => {
   const history = useHistory();
   const schemaPassword = Yup.object().shape({
      password: Yup.string().min(8).required().label("Password"),
      confirmPassword: Yup.string()
         .label("Confirm password")
         .oneOf([Yup.ref("password"), null], "Passwords must match"),
   });
   const { token } = useParams();

   const [updatePasswordReset, { loading }] = useMutation(
      UPDATE_PASSWORD_RESET,
      {
         /**
          * updatePasswordReset onCompleted
          *
          * On completed, redirect to sign in page
          */
         onCompleted() {
            history.push("/signin");
         },
      }
   );

   return (
      <PublicPrivateLayoutSwitcher>
         <div className="py-4">
            <Container>
               <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: "100%" }}
                  paddingTop={20}
               >
                  <Card sx={{ flex: "0 1 500px" }}>
                     <h2 className="text-light">Set New Password</h2>

                     <Formik
                        enableReinitialize
                        initialValues={{
                           password: "",
                           confirmPassword: "",
                        }}
                        validateOnChange={false}
                        validationSchema={schemaPassword}
                        onSubmit={async (values) => {
                           await updatePasswordReset({
                              variables: {
                                 password: values.password,
                                 token,
                              },
                           });
                        }}
                     >
                        {({ handleSubmit, handleChange, errors, values }) => (
                           <Form onSubmit={handleSubmit}>
                              <Box mt={3}>
                                 <InputLabel
                                    column
                                    md="4"
                                    className="text-md-right"
                                 >
                                    Password
                                 </InputLabel>
                                 <TextField
                                    fullWidth
                                    size="small"
                                    type="password"
                                    name="password"
                                    value={values.password}
                                    isInvalid={!!errors.password}
                                    error={errors.password}
                                    onChange={handleChange}
                                    helperText={errors.password}
                                 />
                              </Box>
                              <Box mt={3}>
                                 <InputLabel
                                    column
                                    md="4"
                                    className="text-md-right"
                                 >
                                    Confirm Password
                                 </InputLabel>
                                 <TextField
                                    fullWidth
                                    size="small"
                                    type="password"
                                    name="confirmPassword"
                                    value={values.confirmPassword}
                                    isInvalid={!!errors.confirmPassword}
                                    error={errors.confirmPassword}
                                    onChange={handleChange}
                                    helperText={errors.confirmPassword}
                                 />
                              </Box>
                              <Box mt={3} mb="30px">
                                 <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    className="mx-3"
                                    disabled={loading}
                                 >
                                    {loading ? "Loading..." : "Update"}
                                 </Button>
                              </Box>
                           </Form>
                        )}
                     </Formik>
                  </Card>
               </Grid>
            </Container>
         </div>
      </PublicPrivateLayoutSwitcher>
   );
};

export default PasswordResetUpdatePage;
