import React from "react";
import { Route } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GET_USER_PROFILE } from "_apollo/queries";
import useSignOutIfUnAuthorised from "_utils/useSignOutIfUnAuthorised";
import appStorage from "_utils/appStorage";

/**
 * Private Route.
 * @param {object} props props.
 * @param {JSX.Element} props.component Page passed to the route.
 * @returns {JSX.Element} Component.
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
   // It's important to check the user and logout if unauthorised in a query
   // that is separate from the query that is passed to the Component below,
   // because otherwise this will cause the whole app to re-render on
   // every request.
   useSignOutIfUnAuthorised();
   const authToken = appStorage.getValue({ key: "auxJWT" });

   const { loading, data } = useQuery(GET_USER_PROFILE, {
      skip: !authToken,
   });

   if (loading || !data?.profileDetails) return null;

   /* eslint-disable react/jsx-props-no-spreading */
   return (
      <Route
         {...rest}
         render={(props) => (
            <Component {...props} profile={data?.profileDetails} />
         )}
      />
   );
   /* eslint-enable react/jsx-props-no-spreading */
};

export default PrivateRoute;
