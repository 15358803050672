import React from "react";

/**
 * ProToolsIcon
 * @returns {JSX.Element} Pro Tools Icon
 */
function ProToolsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 2H3C2.45 2 2 2.45 2 3V21C2 21.55 2.45 22 3 22H21C21.55 22 22 21.55 22 21V3C22 2.45 21.55 2 21 2ZM20.03 14.34L19.04 16.6C19.04 16.6 18.9 16.57 18.67 16.49C17.22 18.63 14.77 20.04 12 20.04C9.23 20.04 6.77 18.63 5.33 16.48C5.09 16.56 4.94 16.6 4.94 16.6L3.99 14.33C3.99 14.33 4.11 14.34 4.31 14.29C4.09 13.56 3.97 12.79 3.97 11.99C3.96 7.57 7.57 3.96 12 3.96C16.43 3.96 20.04 7.57 20.04 12C20.04 12.8 19.92 13.57 19.7 14.3C19.9 14.34 20.03 14.34 20.03 14.34ZM15.89 14.6C15.0929 13.6812 14.6734 12.9327 14.3027 12.2712L14.3027 12.2711C14.0642 11.8455 13.8458 11.4559 13.56 11.08C12.97 10.31 12.52 9.96 11.99 9.96C11.46 9.96 11.01 10.31 10.42 11.08C10.1334 11.4569 9.91459 11.8462 9.67526 12.272C9.30504 12.9307 8.88566 13.6768 8.09 14.6C7.44 15.35 6.72001 15.83 6.13 16.13C7.43001 17.98 9.58 19.19 12 19.19C14.42 19.19 16.56 17.98 17.87 16.14C17.28 15.84 16.55 15.36 15.89 14.6ZM4.81 12C4.81 8.04 8.04 4.81 12 4.81V4.82C15.96 4.82 19.19 8.05 19.19 12.01C19.19 12.69 19.09 13.34 18.91 13.96C18.5 13.7 18.02 13.27 17.47 12.56C17.1016 12.0798 16.7803 11.5105 16.4517 10.9283C15.487 9.21882 14.459 7.39746 11.99 7.39H11.97C9.49022 7.39 8.45538 9.21988 7.4862 10.9336C7.15781 11.5143 6.83696 12.0817 6.47 12.56C5.93 13.25 5.47 13.67 5.08 13.93C4.91 13.31 4.81 12.67 4.81 12Z"
        fill="black"
      />
    </svg>
  );
}

export default ProToolsIcon;
