import PropTypes from "prop-types";
import React from "react";

/**
 * Windows Icon
 * @param {object} props props.
 * @param {string} props.color string representing the color of the Icon.
 * @returns {Element} Jsx element
 */
function WindowsIcon({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00625 11.3344L2 4.83125L10 3.74375V11.3313H2.00625V11.3344ZM11.3344 3.55312L21.9969 2V11.3344H11.3344V3.55312ZM22 12.6687V22.0031L11.3344 20.5031V12.6687H22ZM10 20.3312L2.00625 19.2344V12.6656H10V20.3312Z"
        fill={color}
      />
    </svg>
  );
}

WindowsIcon.propTypes = {
  color: PropTypes.string,
};
WindowsIcon.defaultProps = {
  color: "black",
};

export default WindowsIcon;
