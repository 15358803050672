import { Button, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import PropTypes from "prop-types";
import CustomModal from "./CustomModal";

const useStyles = makeStyles((theme) => ({
   footerLabel: {
      fontSize: theme.typography.small1.fontSize,
      position: "absolute",
      right: 75,
   },
}));

const CopyModal = ({
   title,
   show,
   body,
   handleMove,
   handleClose,
   fileAmount,
   loading,
   disableBtn,
}) => {
   const classes = useStyles();
   return (
      <CustomModal
         open={show}
         header={
            <Typography color="primary" variant="modalHeader">
               {title}
            </Typography>
         }
         footer={
            <Grid
               container
               alignItems="center"
               gap="16px"
               justifyContent="center"
               position="relative"
            >
               <Button
                  variant="contained"
                  color="primary"
                  onClick={handleMove}
                  id="copy-files-button-modal"
                  disabled={disableBtn || loading}
               >
                  {loading ? "Loading" : "Copy Files"}
               </Button>
               <Button onClick={handleClose} variant="outlined">
                  Cancel
               </Button>

               {fileAmount > 0 && (
                  <span className={classes.footerLabel}>
                     {fileAmount} file{fileAmount === 1 ? "" : "s"} will be
                     copied.
                  </span>
               )}
            </Grid>
         }
         handleClose={handleClose}
      >
         {body}
      </CustomModal>
   );
};

CopyModal.propTypes = {
   title: PropTypes.string.isRequired,
   body: PropTypes.elementType.isRequired,
   handleMove: PropTypes.func.isRequired,
   show: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   fileAmount: PropTypes.number.isRequired,
   loading: PropTypes.bool.isRequired,
   disableBtn: PropTypes.bool.isRequired,
};

export default CopyModal;
