import { useQuery } from "@apollo/react-hooks";
import { useState, useEffect } from "react";
import { FEED_SUBSCRIPTION } from "../_apollo/queries";

/**
 * Use Pagination
 *
 * Hook to use pagination over a query.
 * @param {number} initialLimit limit of the query.
 * @param {string} query query in which the pagination will occur.
 * @param {object} otherVariables variables query.
 * @param {string} profileId profile id.
 * @returns {object} return value from query.
 */
const usePagination = (initialLimit = 6, query, otherVariables, profileId) => {
   const [limit, setLimit] = useState(initialLimit);
   const [flag, setFlag] = useState(true);
   const { data, loading, fetchMore, subscribeToMore } = useQuery(query, {
      variables: {
         offset: 0,
         limit,
         ...otherVariables,
      },
      notifyOnNetworkStatusChange: true,
      /**
       * passed query onCompleted
       *
       * Update the response object with new data and setFlay if there are no responses.
       * @param {object} response Apollo data response for the passed query.
       */
      onCompleted(response) {
         const res = response[Object.keys(response)[0]];
         if (res.length === 0) {
            setFlag(false);
         }
      },
   });

   useEffect(() => {
      if (profileId) {
         const unsubscribe = subscribeToMore({
            document: FEED_SUBSCRIPTION,
            variables: {
               profileId,
            },
            updateQuery: (prev, { subscriptionData }) => {
               if (!subscriptionData.data) return prev;
               const newFeedItem = subscriptionData.data.feedSubscription;

               return {
                  ...prev,
                  feedMessagesPagination: [
                     ...prev?.feedMessagesPagination,
                     newFeedItem,
                  ],
               };
            },
         });
         return () => (unsubscribe ? unsubscribe() : null);
      }
      return null;
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [profileId]);
   /**
    * Trigger Fetch More
    * Fetches more results.
    */
   const triggerFetchMore = () => {
      if (loading) {
         return;
      }
      let list = [];
      if (data) {
         list = data[Object.keys(data)[0]];
      }
      if (flag && list?.length > 0) {
         const currentLength = list.length;
         fetchMore({
            variables: {
               offset: currentLength,
               limit: initialLimit,
               ...otherVariables,
            },
         }).then((fetchMoreResult) => {
            // This conditional just indicates if there is more users in the pagination to avoid breaking
            const newList =
               fetchMoreResult.data[Object.keys(fetchMoreResult.data)[0]];
            if (newList.length === 0) {
               setFlag(false);
            }
            // Update variables.limit for the original query to include
            // the newly added feed items.
            setLimit(currentLength + newList.length);
         });
      }
   };
   return {
      data,
      loading,
      fetchMore,
      triggerFetchMore,
      hasMore: flag,
      setHasMore: setFlag,
      subscribeToMore,
   };
};

export default usePagination;
