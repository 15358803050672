import React from "react";
import PropTypes from "prop-types";

import makeStyles from "@mui/styles/makeStyles";
import Footer from "_components/Footer";
import EngageSignUpForm from "_components/EngageSignUpForm";
import { GET_CURRENT_USER } from "_apollo/queries";
import { useQuery } from "@apollo/react-hooks";
import { useUser } from "_utils/UserContext";
import Header from "../_components/Header/Header";
import AppLayout from "./AppLayout";

const useStyles = makeStyles((theme) => ({
   headerWrapper: {
      background: `linear-gradient(90deg, #FFC7E5 0%, rgba(255, 199, 229, 0) 100%),
                  linear-gradient(180deg, #FF8845 0%, rgba(255, 136, 69, 0) 100%),
            ${theme.palette.primary.main}`,
   },
}));

/**
 * Custom Layout.
 *
 * Used for routes that have different layout when logged in or out.
 * @param {object} props props.
 * @param {JSX.Element} props.children props.
 * @param {string} props.typeLayout Selects layout for logged out users.
 * @returns {JSX.Element} Layout.
 */
const PublicPrivateLayoutSwitcher = ({ children, typeLayout }) => {
   const classes = useStyles();
   const { data: dataUser } = useQuery(GET_CURRENT_USER);
   const [user] = useUser();
   const userData = dataUser?.currentUser || user;

   /**
    * Render Logged Out Layout.
    *
    * Renders the different marketing page layouts depending on the route.
    * @returns {JSX.Element} Layout.
    */
   const renderLoggedOutLayout = () => {
      if (typeLayout === "profile") {
         return (
            <>
               <div className={classes.headerWrapper}>
                  <Header />
               </div>
               {children}
               <EngageSignUpForm />
               <Footer />
            </>
         );
      }
      if (typeLayout === "discover") {
         return (
            <>
               {children}
               <EngageSignUpForm />
               <Footer />
            </>
         );
      }
      if (typeLayout === "hidden") {
         return <>{children}</>;
      }
      return (
         <>
            <Header />
            {children}
         </>
      );
   };

   return userData ? (
      <AppLayout>{children}</AppLayout>
   ) : (
      renderLoggedOutLayout()
   );
};

PublicPrivateLayoutSwitcher.propTypes = {
   children: PropTypes.shape().isRequired,
   typeLayout: PropTypes.string,
};

PublicPrivateLayoutSwitcher.defaultProps = {
   typeLayout: null,
};

export default PublicPrivateLayoutSwitcher;
