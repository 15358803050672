import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
   Button,
   TextField,
   InputLabel,
   Box,
   Card,
   Grid,
   Container,
} from "@mui/material";
import PublicPrivateLayoutSwitcher from "_layouts/PublicPrivateLayoutSwitcher";
import useRedirectToFeedIfAuthorised from "_utils/useRedirectToFeedIfAuthorised";

// Apollo
import { useMutation } from "@apollo/react-hooks";
import { EmailOutlined } from "@mui/icons-material";
import isElectronHook from "_utils/isElectron";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { RESET_PASSWORD } from "../_apollo/queries";

const useStyles = makeStyles((theme) => ({
   // Click events are eaten by the -webkit-app-region: drag; so we need to disable it for anything that is interactive.
   noDrag: {
      ...theme.noDrag,
   },
}));

/**
 * Password Reset Page
 * @returns {JSX.Element} The Password Reset Page
 */
const PasswordResetPage = () => {
   const classes = useStyles();
   useRedirectToFeedIfAuthorised();
   const isElectron = isElectronHook();
   const history = useHistory();
   const [flag, setFlag] = useState(false);
   const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
      /**
       * updatePasswordReset onCompleted
       *
       * Set flag to show the user the "If an account exists with this email, we have emailed your password reset link!" message.
       */
      onCompleted() {
         setFlag(true);
      },
   });

   const schema = Yup.object().shape({
      email: Yup.string().email().max(320).required().label("Email"),
   });
   return (
      <PublicPrivateLayoutSwitcher
         typeLayout={isElectron ? "hidden" : undefined}
      >
         <Container>
            <Grid
               container
               justifyContent="center"
               alignItems="center"
               style={{ height: "100%" }}
               className={classes.noDrag}
               paddingTop={20}
            >
               {flag ? (
                  <Grid direction="column" container>
                     <Grid item alignSelf="center">
                        <h3>
                           If an account exists with this email, we have emailed
                           your password reset link!
                        </h3>
                     </Grid>
                     <Grid item alignSelf="center">
                        <Button
                           component={Link}
                           to="/signin"
                           size="large"
                           aria-label="Sign In"
                           color="primary"
                           variant="contained"
                           style={{
                              margin: "0 auto",
                              display: "block",
                           }}
                        >
                           Sign In
                        </Button>
                     </Grid>
                  </Grid>
               ) : (
                  <Card sx={{ flex: "0 1 500px" }}>
                     <h2 className="text-light">Reset Password</h2>

                     <Formik
                        initialValues={{
                           email: "",
                        }}
                        validationSchema={schema}
                        onSubmit={async ({ email }) => {
                           await resetPassword({
                              variables: {
                                 email,
                              },
                           });
                        }}
                     >
                        {({ handleSubmit, handleChange, errors, values }) => (
                           <Form noValidate onSubmit={handleSubmit}>
                              <InputLabel>Email Address</InputLabel>
                              <TextField
                                 fullWidth
                                 size="small"
                                 type="text"
                                 name="email"
                                 value={values.email}
                                 isInvalid={!!errors.email}
                                 error={errors.email}
                                 onChange={handleChange}
                                 helperText={errors.email}
                              />

                              <Box mt={3} mb="30px">
                                 <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={loading}
                                 >
                                    <EmailOutlined />
                                    &nbsp;
                                    {loading ? "Loading..." : "Reset Password"}
                                 </Button>
                                 &nbsp;
                                 <Button
                                    variant="outlined"
                                    size="large"
                                    disabled={loading}
                                    onClick={() => history.goBack()}
                                 >
                                    Go Back
                                 </Button>
                              </Box>
                           </Form>
                        )}
                     </Formik>
                  </Card>
               )}
            </Grid>
         </Container>
      </PublicPrivateLayoutSwitcher>
   );
};

export default PasswordResetPage;
