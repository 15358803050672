import React, { useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

// Apollo
import MetaTags from "_components/MetaTags";
import { Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AOS from "aos";
import MiniBlog from "../../_components/MiniBlog";
import Footer from "../../_components/Footer";
import FeedbackCards from "./FeedbackCards";
import "aos/dist/aos.css";
import HomePageMainSection from "./HomePageMainSection";
import HomePageMainSectionMobile from "./HomePageMainSectionMobile";
import AdDirect from "../../_components/AdDirect";

const useStyles = makeStyles(() => ({
   homepage: {
      color: "black",
      backgroundColor: "white",
      paddingBottom: "170px",
   },
}));

/**
 * Home Page
 * @returns {JSX.Element} Home page component.
 */
const HomePage = () => {
   const classes = useStyles();
   const mobileView = useMediaQuery("(max-width:900px)");

   useEffect(() => {
      AOS.init();
      setTimeout(() => {
         // Scroll down a little by default after a few seconds, only if the user hasn't already scrolled.
         if (window.scrollY < 300) {
            window.scrollTo({ top: 300, behavior: "smooth" });
         }
      }, 800);
   }, []);

   return (
      <div className={classes.homepage}>
         {/* Hiring Banner for future job listings: <HiringBadge />  */}
         <MetaTags
            title="Next Generation Music Making"
            description="The only app you need as a musician or producer. Generate unique AI samples and loops, back up and share your DAW project files with your collaborators and master your projects."
         />
         {mobileView ? <HomePageMainSectionMobile /> : <HomePageMainSection />}
         <FeedbackCards />
         {/* <RemoteCollaboration /> */}
         {/* <GuideMainPanel /> */}
         {/* <GuideOverlayIcons /> */}
         {/* <Features /> */}
         {/* TODO: Re-enable the features section when I have the collaboration features back in. */}
         {/* <AuxFor /> */}
         <MiniBlog />
         <AdDirect />
         <Divider />
         <Footer />
      </div>
   );
};

export default HomePage;
