import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
   Button,
   TextField,
   Divider,
   Box,
   ListSubheader,
   List,
   ListItem,
   InputLabel,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useMutation } from "@apollo/react-hooks";
import Stripe from "_assets/Stripe.png";
import {
   UPDATE_CARD,
   REDEEM_COUPON,
   SETUP_INTENT,
   DELETE_SUBSCRIPTION,
} from "_apollo/queries";
import SettingsContainer from "_components/SettingsContainer";
import { useUser } from "_utils/UserContext";
import getUsersPlan from "_utils/getUsersPlan";
import UpgradeSubscriptionModal from "_components/Modal/UpgradeSubscriptionModal";
import ConfirmCancelModal from "../../../_components/ConfirmCancelModal";
import { SubscriptionPlans } from "../../../_constants/GlobalVariables";
import DangerButton from "../../../_components/DangerButton";

const useStyles = makeStyles((theme) => ({
   inputLinksContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 8,
   },
   stripeElement: {
      padding: "12px",
      borderRadius: 4,
      backgroundColor: theme.palette.secondary.shade5,
   },
   userCardLastFour: {
      padding: "1em 0 2em",
      height: "18px",
      "& img": {
         marginRight: 12,
      },
   },
}));

/**
 * Billing Settings Panel
 *
 * @returns {JSX.Element} Billing settings component.
 */
const BillingSettings = () => {
   const [user] = useUser();
   const theme = useTheme();
   const [loading, setLoading] = useState(false);
   const [errorApi, setError] = useState(false);
   const classes = useStyles();
   const stripe = useStripe();
   const elements = useElements();
   const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);

   Yup.object().shape({
      password: Yup.string().min(8).required().label("Password"),
      email: Yup.string().email().max(320).required().label("Email"),
   });

   const [showCancelSubscription, setShowCancelSubscription] = useState(false);
   // ArrayPlans
   const { Features } = SubscriptionPlans;

   const [deleteSubscription, { loading: loadingDeleteSubscription }] =
      useMutation(DELETE_SUBSCRIPTION, {
         /**
          * deleteSubscription onCompleted
          */
         onCompleted() {
            setShowCancelSubscription(false);
         },
      });

   const [updateCard] = useMutation(UPDATE_CARD);
   const [setupIntent] = useMutation(SETUP_INTENT);

   const [
      redeemCoupon,
      { error: errorRedeemCoupon, loading: loadingRedeemCoupon },
   ] = useMutation(REDEEM_COUPON);

   /**
    * handleSubmitForm - update card
    * @param {object} values values
    * @param {string} setupIntentSecret setup intent client secret.
    * @returns {Promise} Promise to update card via Stripe API.
    */
   const handleSubmitForm = async (values, setupIntentSecret) => {
      if (!stripe || !elements) {
         return;
      }
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
         type: "card",
         card: cardElement,
      });
      // If this is successful, Stripe will attach for us the payment method to the costumer object.
      const { setupIntent: setupIntentData, error: errorSetupIntent } =
         await stripe.confirmCardSetup(setupIntentSecret, {
            payment_method: paymentMethod.id,
         });
      if (errorSetupIntent || error) {
         // Show message to customer that the card could not be updated.
         setLoading(false);
         setError(true);
         return;
      }

      if (setupIntentData.status === "succeeded") {
         // If we can use recurring charges, we can update the card.
         await updateCard({
            variables: {
               paymentMethod: paymentMethod.id,
               postalCode: values.zip,
            },
            onError: () => {
               setError(true);
            },
         });
         setLoading(false);
      }
   };

   return (
      <SettingsContainer title="Subscription">
         {openSubscriptionModal && (
            <UpgradeSubscriptionModal
               title="Upgrade to take your music production to the next level"
               description="and get access to samples and loops, mastering and project storage"
               open={openSubscriptionModal}
               handleClose={() => setOpenSubscriptionModal(false)}
            />
         )}
         <ConfirmCancelModal
            show={showCancelSubscription}
            handleClose={() => setShowCancelSubscription(false)}
            handleConfirm={async () => {
               await deleteSubscription();
            }}
            title="Cancel Subscription"
            body={
               <List
                  dense
                  subheader={
                     <ListSubheader>
                        You will lose all of these features immediately:
                     </ListSubheader>
                  }
               >
                  {(() => {
                     const planType = getUsersPlan(
                        user?.currentBillingPlan
                     )?.modalEnum;
                     switch (planType) {
                        case SubscriptionPlans.PlanModalEnum.STARTER:
                           return Features.StarterPlanFeatures;
                        case SubscriptionPlans.PlanModalEnum.BASIC:
                           return Features.BasicPlanFeatures;
                        case SubscriptionPlans.PlanModalEnum.PRO:
                           return Features.ProPlanFeatures;
                        case SubscriptionPlans.PlanModalEnum.VIP:
                           return Features.VIPPlanFeatures;
                        case SubscriptionPlans.PlanModalEnum.TEAM:
                           return Features.TeamPlanFeatures;
                        default:
                           return [];
                     }
                  })()
                     ?.filter(
                        (el) =>
                           el !== "Unlimited Projects" &&
                           el !== "Unlimited Collaborators" &&
                           el !== "Unlimited Messages"
                     )
                     ?.map((el) => (
                        <ListItem key={el}>{el}</ListItem>
                     ))}
               </List>
            }
            textButton="Confirm"
            textCancelButton="Go Back"
            loading={loadingDeleteSubscription}
         />

         {user?.subscriptions?.stripeStatus !== "active" && (
            <Box margin="20px 0px">
               <Box margin="20px 0px">
                  <Button
                     onClick={() => setOpenSubscriptionModal(true)}
                     variant="contained"
                  >
                     Manage your Plan
                  </Button>
               </Box>
            </Box>
         )}
         {user?.subscriptions?.stripeStatus === "active" && (
            <>
               <Box margin="20px 0px">
                  <Button
                     onClick={() => setOpenSubscriptionModal(true)}
                     variant="contained"
                  >
                     Update Your Subscription
                  </Button>
               </Box>

               <Divider />

               <Box margin="20px 0px">
                  <h3>Update Card</h3>

                  <div>
                     <div className={classes.userCardLastFour}>
                        <img
                           src={Stripe}
                           style={{
                              width: "40px",
                              height: "auto",
                              backgroundColor: "black",
                              borderRadius: "4px",
                           }}
                           alt="Stripe"
                        />
                        <span>**** **** **** {user?.cardLastFour}</span>
                     </div>
                  </div>

                  <Formik
                     enableReinitialize
                     initialValues={{
                        name: "",
                        zip: "",
                     }}
                     // validationSchema={validationSchema}
                     onSubmit={async (values) => {
                        setLoading(true);
                        // Need to request a client secret to create a mandate to be able to use recurring charges on a customer.
                        await setupIntent({
                           async onCompleted(data) {
                              const setupIntentSecret = data.setupIntent;
                              if (setupIntentSecret) {
                                 await handleSubmitForm(
                                    values,
                                    setupIntentSecret
                                 );
                              }
                           },
                        });
                     }}
                  >
                     {({ handleSubmit, handleChange, errors, values }) => (
                        <form onSubmit={handleSubmit}>
                           <div className={classes.inputLinksContainer}>
                              <InputLabel>Cardholder&apos;s Name</InputLabel>
                              <TextField
                                 data-testid="card-holder-name"
                                 variant="outlined"
                                 size="small"
                                 name="name"
                                 value={values.name}
                                 isInvalid={!!errors.name}
                                 error={errors.name}
                                 onChange={handleChange}
                              />
                              {errors?.name && <p>{errors.name}</p>}
                           </div>
                           <div className={classes.inputLinksContainer}>
                              <InputLabel>Card Details</InputLabel>
                              <CardElement
                                 className={classes.stripeElement}
                                 onChange={() => setError(false)}
                                 options={{
                                    hidePostalCode: true,
                                    style: {
                                       base: {
                                          fontSize:
                                             theme.typography.h6.fontSize,
                                          color: "#424770",
                                          "::placeholder": {
                                             color: "#aab7c4",
                                          },
                                       },

                                       invalid: {
                                          color: "#9e2146",
                                       },
                                    },
                                 }}
                              />
                              {errorApi && (
                                 <span
                                    style={{
                                       color: "red",
                                       lineHeight: "12px",
                                       fontSize: "12px",
                                       marginTop: "5px",
                                    }}
                                 >
                                    Unable to update the Card
                                 </span>
                              )}
                           </div>
                           <div className={classes.inputLinksContainer}>
                              <InputLabel>ZIP / Postal Code</InputLabel>
                              <TextField
                                 data-testid="postal-code"
                                 variant="outlined"
                                 size="small"
                                 name="zip"
                                 value={values.zip}
                                 isInvalid={!!errors.zip}
                                 error={errors.zip}
                                 onChange={handleChange}
                              />
                              {errors?.zip && (
                                 <p type="invalid">{errors.zip}</p>
                              )}
                           </div>
                           <Button
                              data-testid="update-card"
                              type="submit"
                              variant="outlined"
                              color="primary"
                              disabled={!stripe || loading}
                           >
                              {loading ? "Loading..." : "Update"}
                           </Button>
                        </form>
                     )}
                  </Formik>
               </Box>

               <Divider />

               <Box margin="20px 0px">
                  <h3>Redeem Coupon</h3>

                  <Formik
                     enableReinitialize
                     initialValues={{
                        coupon: "",
                     }}
                     // validationSchema={validationSchema}
                     onSubmit={async (values) => {
                        await redeemCoupon({
                           variables: {
                              coupon: values.coupon,
                           },
                        });
                     }}
                  >
                     {({
                        handleSubmit,
                        handleChange,
                        errors,
                        values,
                        setFieldError,
                     }) => {
                        if (errorRedeemCoupon?.graphQLErrors[0]?.message) {
                           setFieldError(
                              "coupon",
                              errorRedeemCoupon?.graphQLErrors[0]?.message
                           );
                           delete errorRedeemCoupon?.graphQLErrors[0]?.message;
                        }
                        return (
                           <form onSubmit={handleSubmit}>
                              <div className={classes.inputLinksContainer}>
                                 <InputLabel>Coupon Code</InputLabel>
                                 <TextField
                                    data-testid="redeem-coupon"
                                    variant="outlined"
                                    size="small"
                                    name="coupon"
                                    value={values.coupon}
                                    isInvalid={!!errors.coupon}
                                    error={errors.coupon}
                                    onChange={handleChange}
                                 />
                                 {errors?.coupon && (
                                    <p type="invalid">{errors.coupon}</p>
                                 )}
                              </div>
                              <Button
                                 data-testid="apply-coupon"
                                 type="submit"
                                 variant="outlined"
                                 color="primary"
                                 disabled={loadingRedeemCoupon}
                              >
                                 {loadingRedeemCoupon ? "Loading..." : "Redeem"}
                              </Button>
                           </form>
                        );
                     }}
                  </Formik>
               </Box>

               <Divider />

               <Box margin="20px 0px">
                  <Box margin="20px 0px">
                     <h3>Cancel Subscription</h3>

                     <DangerButton
                        onClick={() => setShowCancelSubscription(true)}
                     >
                        Cancel
                     </DangerButton>
                  </Box>
               </Box>
            </>
         )}
      </SettingsContainer>
   );
};

export default BillingSettings;
