import React from "react";
import PropTypes from "prop-types";
import { InsertDriveFile, PlayCircleFilled } from "@mui/icons-material";
import { CircularProgress, Skeleton } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import FolderIcon from "@mui/icons-material/Folder";
import { useHistory, useParams } from "react-router-dom";
import isFileReady from "../_utils/isFileReady";
import { getFileTypeObject } from "../_utils/fileTypeUtil/fileTypeUtil";

/**
 * File Type Icon
 *
 * Loads the correct custom or default icon depending on the type of file.
 * @param {props} props props
 * @returns {JSX.Element} File Type Icon
 */
const FileTypeIcon = ({ file, className }) => {
   const history = useHistory();
   const { folderUri } = useParams();

   if (!file) {
      return <Skeleton variant="rounded" width={20} height={20} />;
   }
   const { mimeType } = file;

   /**
    * Render Play Button
    * @returns {JSX.Element} Play/pause button depending on file playback state.
    */
   const renderPlayButton = () => {
      if (file?.processingPreview === 1 || file?.processingWaveform === 1) {
         return (
            <CircularProgress
               size={20}
               style={{ marginTop: 12 }}
               className={className}
            />
         );
      }
      return (
         <PlayCircleFilled
            style={{
               cursor: "pointer",
            }}
            onClick={(e) => {
               e.preventDefault();
               e.stopPropagation();
               // OPEN FILE THIRD COLUMN
               if (folderUri) {
                  history.push(
                     `/files/folder/${folderUri}/file/${file.uri}?play=1`
                  );
               } else {
                  history.push(`/files/file/${file.uri}?play=1`);
               }
            }}
            className={className}
         />
      );
   };

   if (!isFileReady(file)) {
      return <CircularProgress size={20} className={className} />;
   }
   // Get DAW icon dynamically from fileTypeData object.
   const fileTypeObject = getFileTypeObject(file.name || file.originalName);
   if (fileTypeObject && fileTypeObject.icon) {
      return fileTypeObject.icon;
   }
   // eslint-disable-next-line no-underscore-dangle
   if (file?.__typename === "Folder") {
      return <FolderIcon className={className} />;
   }
   if (mimeType.includes("audio")) {
      return renderPlayButton();
   }
   if (mimeType.includes("image")) {
      return <ImageIcon className={className} />;
   }

   return <InsertDriveFile className={className} />;
};

FileTypeIcon.propTypes = {
   file: PropTypes.shape({
      mimeType: PropTypes.string,
      id: PropTypes.number.isRequired,
   }).isRequired,
   className: PropTypes.instanceOf(Object),
};

FileTypeIcon.defaultProps = {
   className: null,
};

export default FileTypeIcon;
