import PropTypes from "prop-types";
import React from "react";
import { Skeleton, TableCell, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import formatBytes from "../../../_utils/formatBytes";

const useStyles = makeStyles((theme) => ({
   tableCell: {
      fontSize: theme.typography.small1.fontSize,
   },
}));

const TableCellSize = ({ file, loading }) => {
   const classes = useStyles();

   return (
      <TableCell sx={{ padding: "0 30px" }}>
         <Typography noWrap className={classes.tableCell}>
            {loading && <Skeleton />}
            {!loading && formatBytes(file.size)}
         </Typography>
      </TableCell>
   );
};

TableCellSize.propTypes = {
   file: PropTypes.shape({
      size: PropTypes.string,
   }).isRequired,
   loading: PropTypes.bool,
};

TableCellSize.defaultProps = {
   loading: false,
};

export default TableCellSize;
