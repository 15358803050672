export const LOG_OUT_USER = "LOG_OUT_USER";
export const SHOW_MEDIA_PLAYER = "SHOW_MEDIA_PLAYER";
export const CHANGE_TRACK = "CHANGE_TRACK";
export const PLAY_STOP_TRACK = "PLAY_STOP_TRACK";
export const MANAGE_PROFILE = "MANAGE_PROFILE";
export const UPDATE_UPLOAD_COUNT = "UPDATE_UPLOAD_COUNT";
export const SET_UPGRADE_MODAL = "SET_UPGRADE_MODAL";
export const CLEAR_START_TIME = "CLEAR_START_TIME";
export const SET_BACK_HISTORY = "SET_BACK_HISTORY";
export const SET_PROJECT_MESSAGE = "SET_PROJECT_MESSAGE";
export const TOGGLE_MAIN_MENU = "TOGGLE_MAIN_MENU";
export const SET_ERROR = "SET_ERROR";
export const SET_DAW_COMMENT = "SET_DAW_COMMENT";
export const SHOW_DOWNLOAD_LINK_MODAL = "SHOW_DOWNLOAD_LINK_MODAL";
export const OPEN_LOADING_APP_MODAL = "OPEN_LOADING_APP_MODAL";
