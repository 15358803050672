import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    display: "flex",
    alignItems: "center",
    background: `linear-gradient(
      90deg, #FFC7E5 0%, rgba(255, 199, 229, 0) 100%), linear-gradient(
         180deg, #FF8845 0%, rgba(255, 136, 69, 0) 100%), #6147FF`,
    "-webkit-user-select":
      "none" /* Allows the window to be dragged around the screen. */,
    "-webkit-app-region": "drag",
  },
  spinnerFrame: {
    display: "inline-block",
    width: "100%",
    height: "50px",
    textAlign: "center",
  },
  progressCircular: {
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    appearance: "none",
    boxSizing: "border-box",
    border: "none",
    borderRadius: "50%",
    padding: "0.25em",
    width: "50px",
    height: "50px",
    color: "#6147FF",
    backgroundColor: "transparent",
    fontSize: "16px",
    overflow: "hidden",
    "&::-webkit-progress-bar": {
      backgroundColor: "transparent",
    },
    "&:indeterminate": {
      "-webkit-mask-image":
        "linear-gradient(transparent 50%, black 50%), linear-gradient(to right, transparent 50%, black 50%)",
      maskImage:
        "linear-gradient(transparent 50%, black 50%), linear-gradient(to right, transparent 50%, black 50%)",
      animation: "$progressCircular 6s infinite cubic-bezier(0.3, 0.6, 1, 1)",
    },
    ":-ms-lang(x), &:indeterminate": {
      animation: "none",
    },
    "&:indeterminate::before, &:indeterminate::-webkit-progress-value": {
      content: '""',
      display: "block",
      boxSizing: "border-box",
      marginBottom: "0.25em",
      border: "solid 0.25em transparent",
      borderTopColor: "currentColor",
      borderRadius: "50%",
      width: "100% !important",
      height: "100%",
      backgroundColor: "transparent",
      animation: "$progressCircularPseudo 0.75s infinite linear alternate",
    },
    "&:indeterminate::-moz-progress-bar": {
      boxSizing: "border-box",
      border: "solid 0.25em transparent",
      borderTopColor: "currentColor",
      borderRadius: "50%",
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",
      animation: "$progressCircularPseudo 0.75s infinite linear alternate",
    },
    "&:indeterminate::-ms-fill": {
      animationName: "-ms-ring",
    },
  },
  "@keyframes progressCircular": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "12.5%": {
      transform: "rotate(180deg)",
      animationTimingFunction: "linear",
    },
    "25%": {
      transform: "rotate(630deg)",
    },
    "37.5%": {
      transform: "rotate(810deg)",
      animationTimingFunction: "linear",
    },
    "50%": {
      transform: "rotate(1260deg)",
    },
    "62.5%": {
      transform: "rotate(1440deg)",
      animationTimingFunction: "linear",
    },
    "75%": {
      transform: "rotate(1890deg)",
    },
    "87.5%": {
      transform: "rotate(2070deg)",
      animationTimingFunction: "linear",
    },
    "100%": {
      transform: "rotate(2520deg)",
    },
  },
  // eslint-disable-next-line mui-unused-classes/unused-classes
  "@keyframes progressCircularPseudo": {
    "0%": {
      transform: "rotate(-30deg)",
    },
    "29.4%": {
      borderLeftColor: "transparent",
    },
    "29.41%": {
      borderLeftColor: "currentColor",
    },
    "64.7%": {
      borderBottomColor: "transparent",
    },
    "64.71%": {
      borderBottomColor: "currentColor",
    },
    "100%": {
      borderLeftColor: "currentColor",
      borderBottomColor: "currentColor",
      transform: "rotate(225deg)",
    },
  },
}));

/**
 * Loading Modal
 *
 * A full screen modal that was created to deal with the delay between signin up/in and
 * all of the queries needed to render the feed. Now this is shown right away while the main
 * app loads leading to less confusing user experience.
 *
 * @returns {JSX.Element} Loading Modal
 */
export default function AppLoadingModal() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.spinnerFrame}>
        <div>
          <svg
            width="132"
            height="40"
            viewBox="0 0 132 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.1997 32.48C15.5997 32.48 17.7117 31.76 19.4397 30.464V32H25.6797V7.99999H19.4397V9.53599C17.7117 8.23999 15.5997 7.51999 13.1997 7.51999C5.90373 7.51999 0.719727 12.704 0.719727 20C0.719727 27.344 5.90373 32.48 13.1997 32.48ZM13.1997 26.72C9.40773 26.72 6.95973 24.08 6.95973 20C6.95973 15.92 9.40773 13.28 13.1997 13.28C16.9917 13.28 19.4397 15.92 19.4397 20C19.4397 24.08 16.9917 26.72 13.1997 26.72Z"
              fill="black"
            />
            <path
              d="M28.0591 22.4C28.0591 28.304 32.1391 32.48 37.7551 32.48C39.8671 32.48 41.7391 31.76 43.1791 30.464V32H49.4191V7.99999H43.1791V22.4C43.1791 24.944 41.3551 26.72 38.7151 26.72C36.1711 26.72 34.2991 24.944 34.2991 22.4V7.99999H28.0591V22.4Z"
              fill="black"
            />
            <path
              d="M66.6087 32H73.9047L65.9847 19.52L73.3287 7.99999H66.0807L62.2887 14.24L58.4967 7.99999H51.2007L58.5447 19.568L50.6247 32H57.9207L62.2887 24.848L66.6087 32Z"
              fill="black"
            />
            <path
              d="M84.6034 18.9612C87.8435 18.9612 90.1648 16.6722 90.1648 13.3998C90.1648 10.1436 87.8435 7.83841 84.6034 7.83841C81.3633 7.83841 79.042 10.1436 79.042 13.3998C79.042 16.6722 81.3633 18.9612 84.6034 18.9612ZM84.6034 16.9462C82.54 16.9462 81.2182 15.5599 81.2182 13.3998C81.2182 11.2397 82.54 9.85341 84.6034 9.85341C86.6668 9.85341 87.9886 11.2397 87.9886 13.3998C87.9886 15.5599 86.6668 16.9462 84.6034 16.9462Z"
              fill="black"
            />
            <path
              d="M92.4172 18.8H94.5934V15.3826H96.3505C98.6556 15.3826 100.316 13.8995 100.316 11.6911C100.316 9.48265 98.6556 7.99961 96.3505 7.99961H92.4172V18.8ZM96.3505 10.0146C97.4466 10.0146 98.1398 10.6755 98.1398 11.6911C98.1398 12.7066 97.4627 13.3676 96.3505 13.3676H94.5934V10.0146H96.3505Z"
              fill="black"
            />
            <path
              d="M102.577 7.99961V18.8H109.638V16.785H104.753V14.238H109.638V12.223H104.753V10.0146H109.638V7.99961H102.577Z"
              fill="black"
            />
            <path
              d="M119.54 18.8H121.716V7.99961H119.54V15.0924L114.236 7.99961H112.06V18.8H114.236V11.7072L119.54 18.8Z"
              fill="black"
            />
            <path
              d="M81.3794 24.5364L84.3455 28.1635L87.3277 24.5364V32H89.5039V21.1996H87.3277L84.3455 25.0039L81.3794 21.1996H79.2032V32H81.3794V24.5364Z"
              fill="black"
            />
            <path
              d="M91.9292 21.1996V27.7121C91.9292 30.3235 93.9281 32.1612 96.7652 32.1612C99.6023 32.1612 101.585 30.3235 101.585 27.7121V21.1996H99.4089V27.5509C99.4089 29.1307 98.4417 30.1462 96.7652 30.1462C95.0887 30.1462 94.1054 29.1307 94.1054 27.5509V21.1996H91.9292Z"
              fill="black"
            />
            <path
              d="M111.183 28.9533C111.183 24.7783 106.105 26.2129 106.105 24.2463C106.105 23.4725 106.67 23.0534 107.556 23.0534C108.443 23.0534 109.007 23.537 109.007 24.4075H111.183C111.183 22.3925 109.668 21.0384 107.556 21.0384C105.444 21.0384 103.929 22.328 103.929 24.2463C103.929 28.373 109.007 26.6159 109.007 28.9533C109.007 29.7271 108.443 30.1462 107.556 30.1462C106.67 30.1462 106.105 29.6626 106.105 28.7921H103.929C103.929 30.8071 105.444 32.1612 107.556 32.1612C109.668 32.1612 111.183 30.8716 111.183 28.9533Z"
              fill="black"
            />
            <path d="M115.701 32V21.1996H113.524V32H115.701Z" fill="black" />
            <path
              d="M126.881 26.1968H129.057C128.896 23.1663 126.639 21.0384 123.512 21.0384C120.272 21.0384 117.95 23.3436 117.95 26.5998C117.95 29.8722 120.272 32.1612 123.512 32.1612C126.639 32.1612 128.88 30.0656 129.057 27.0028H126.881C126.736 28.9372 125.446 30.1462 123.512 30.1462C121.448 30.1462 120.127 28.7599 120.127 26.5998C120.127 24.4397 121.448 23.0534 123.512 23.0534C125.446 23.0534 126.72 24.2624 126.881 26.1968Z"
              fill="black"
            />
          </svg>
        </div>
        <progress className={classes.progressCircular} />
      </div>
    </div>
  );
}
