import { Close } from "@mui/icons-material";
import {
   Box,
   Grid,
   IconButton,
   Modal,
   Typography,
   useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import { setShowDownloadLinkModal } from "_redux/actions";
import { useUser } from "_utils/UserContext";
import { useLazyQuery } from "@apollo/react-hooks";
import { HAS_DESKTOP_STAT } from "_apollo/queries";
import DownloadLinkEmailForm from "./DownloadLinkEmailForm";
import DownloadLinkSignUpForm from "./DownloadLinkSignUpForm";

const useStyles = makeStyles((theme) => ({
   closeBtn: {
      color: "black",
   },
   modalContainer: {
      background: `linear-gradient(179.98deg, #6147FF 0.01%, rgba(255, 61, 166, 0.2) 78.66%),
                  linear-gradient(8.41deg, #FF9052 0%, rgba(255, 136, 69, 0) 50%),#FFAFDA`,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      overflow: "auto",
      overflowX: "hidden",
      "& form": {
         display: "flex",
         flexDirection: "column",
         "& .Mui-focused": {
            outline: `1px solid ${theme.palette.strong.yellow}`,
         },
         "& .MuiInputBase-root": {
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            color: "white",
            padding: "8px 10px",
            height: "40px",
            borderRadius: "4px",
         },
         "& span > a": {
            color: "white",
         },
      },
   },
   heading: {
      fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
      color: "white",
      fontSize: 44,
      fontWeight: 800,
      lineHeight: "95%",
   },
   footerText: {
      marginTop: "40px",
      "& br": {
         display: "block",
         content: `""`,
         margin: "20px 0",
      },
   },
}));

/**
 * SendDownloadLinkModal component
 * Renders SendDownloadLinkModal component allows user to sign up
 * and receive a download link for the desktop app.
 * @returns {JSX.Element} returns SendDownloadLinkModal component
 */
const SendDownloadLinkModal = () => {
   const classes = useStyles();

   const { showDownloadLinkModal } = useSelector((state) => state.AppReducer);

   const [user] = useUser();

   const signedIn = useMemo(() => user?.email, [user?.email]);

   const dispatch = useDispatch();

   const [submit, setSubmit] = useState(false);

   // Prevent a mobile user from using any internal app page, only allow them to view the marketing pages.
   const nonRequiredRoutes = [
      "/music-creators",
      "/podcasters",
      "/music-industry",
      "/signin",
      "/signup",
      "/awards",
      "/connect",
      "/join",
      "/playlist",
      "/pricing",
      "/info",
   ];

   const mobileSize = useMediaQuery("(max-width:659px)");

   const location = useLocation();

   const appView = useMemo(
      () =>
         location.pathname !== "/" &&
         !nonRequiredRoutes.find((route) =>
            location.pathname.startsWith(route)
         ),
      [location.pathname, user]
   );

   const [checkIfStatExist] = useLazyQuery(HAS_DESKTOP_STAT);

   // Check if the user has used the desktop app first time a user object is available.
   useEffect(() => {
      if (
         user &&
         (!sessionStorage.getItem("showDesktopDownloadModal") ||
            Date.now() > sessionStorage.getItem("showDesktopDownloadModal"))
      ) {
         checkIfStatExist().then((res) => {
            if (!res.data.hasDesktopStat && appView) {
               dispatch(setShowDownloadLinkModal(true));

               sessionStorage.setItem(
                  "showDesktopDownloadModal",
                  Date.now() + 1000 * 60 * 60 * 24 * 7
               ); // Show the desktop download modal again after 7 days until they download it.
            }
         });
      }
   }, [user, location]);

   /**
    * handleClose
    * handleClose function
    * @returns {void} dispatches setShowDownloadLinkModal action
    */
   const handleClose = () => {
      dispatch(setShowDownloadLinkModal(false));
      setSubmit(false);
   };

   return (
      <Modal open={showDownloadLinkModal} onClose={handleClose}>
         <Box
            px="23px"
            pb="40px"
            width={400}
            borderRadius="6px"
            className={classes.modalContainer}
            maxHeight="90%"
            overflow="hidden auto"
         >
            <Box>
               <Grid container justifyContent="flex-end" pt="10px">
                  <IconButton
                     sx={{
                        marginRight: "-23px",
                     }}
                     onClick={handleClose}
                  >
                     <Close className={classes.closeBtn} />
                  </IconButton>
               </Grid>

               <Typography mt="40px" className={classes.heading}>
                  Download Aux
                  <br /> for free
               </Typography>

               {(() => {
                  if (submit) {
                     return (
                        <Typography
                           color="strong.green"
                           fontWeight="700"
                           variant="large"
                           mt="20px"
                           mb="20px"
                           component="p"
                        >
                           Switch to your desktop
                        </Typography>
                     );
                  }
                  if (signedIn && mobileSize) {
                     return (
                        <Typography
                           color="strong.yellow"
                           fontWeight="700"
                           variant="large"
                           mt="20px"
                           mb="20px"
                           component="p"
                        >
                           On a mobile device?
                        </Typography>
                     );
                  }
                  if (signedIn && !mobileSize) {
                     return (
                        <Typography
                           color="strong.yellow"
                           fontWeight="700"
                           variant="large"
                           mt="20px"
                           mb="20px"
                           component="p"
                        >
                           Get extra features on desktop
                        </Typography>
                     );
                  }
                  return (
                     <Typography
                        color="strong.yellow"
                        fontWeight="700"
                        variant="large"
                        mt="20px"
                        mb="20px"
                        component="p"
                     >
                        Sign up to get the download link
                     </Typography>
                  );
               })()}

               {(() => {
                  if (submit) {
                     return (
                        <Typography
                           mb="28px"
                           variant="large"
                           component="p"
                           pr="20px"
                        >
                           A download link for the app will be in your email in
                           a few seconds. Download and install the app to
                           generate sounds and master your tracks alongside your
                           DAW.
                        </Typography>
                     );
                  }
                  if (signedIn) {
                     return (
                        <Typography
                           mb="28px"
                           variant="large"
                           component="p"
                           pr="20px"
                        >
                           Aux is a powerful music making companion designed to
                           sit alongside your DAW on your Mac or PC. Fill in
                           your email below to get the download link sent to
                           you.
                        </Typography>
                     );
                  }
                  return null;
               })()}

               {signedIn
                  ? !submit && <DownloadLinkEmailForm setSubmit={setSubmit} />
                  : !submit && <DownloadLinkSignUpForm setSubmit={setSubmit} />}

               <Typography className={classes.footerText} component="div">
                  <Typography
                     fontWeight="700"
                     variant="large"
                     component="p"
                     mb="20px"
                  >
                     Why should I download Aux desktop?
                  </Typography>
                  <Typography variant="medium" component="p">
                     The Aux desktop app allows you to generate sounds using
                     LoopLM, and drag and drop them straight into your DAW
                     without having to download them first.
                     <br /> You can also master tracks and store and share them
                     with collaborators quickly from your desktop.
                  </Typography>
               </Typography>
            </Box>
         </Box>
      </Modal>
   );
};

export default withRouter(SendDownloadLinkModal);
