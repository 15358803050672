import React, { useMemo } from "react";
import ReactTimeAgo from "react-time-ago";
import isValidDate from "_utils/isValidDate";
import PropTypes from "prop-types";
import { round } from "javascript-time-ago/steps";
/**
 * ValidReactTimeAgo
 * Component to validate the date and return a valid date formatted with react-time-ago.
 * @param {object} props ValidReactTimeAgo props
 * @param {Date} props.date Date to validate
 * @param {boolean} props.overrideStyles if you want to use a custom time style or default.
 * @param {string} props.className class to add to the component.
 * @returns {JSX.Element} ValidReactTimeAgo component.
 */
const ValidReactTimeAgo = ({ date, overrideStyles, className }) => {
   // TimeAgo styles
   const customStyle = {
      steps: round,
      labels: "custom",
   };

   const validDate = useMemo(() => {
      if (isValidDate(date)) {
         return new Date(date);
      }
      return new Date();
   }, [date]);

   if (!isValidDate(date) || !date || !validDate) {
      return null;
   }

   return (
      <ReactTimeAgo
         date={validDate}
         timeStyle={!overrideStyles && customStyle}
         locale="en-US"
         className={className}
      />
   );
};

export default ValidReactTimeAgo;
ValidReactTimeAgo.propTypes = {
   date: PropTypes.instanceOf(Date),
   overrideStyles: PropTypes.bool,
   className: PropTypes.string,
};
ValidReactTimeAgo.defaultProps = {
   overrideStyles: false,
   className: "",
   date: new Date(),
};
