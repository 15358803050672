/**
 * isValidDate
 *
 * Validates date parameter returns a boolean result.
 * @param {Date} date timestamp
 * @returns {boolean} if It is a valid date
 */
const isValidDate = (date) => {
   if (!date) return false;
   const dateObj = new Date(Number(date));
   return dateObj instanceof Date && !Number.isNaN(dateObj.getTime());
};

export default isValidDate;
