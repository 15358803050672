import React from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import makeStyles from "@mui/styles/makeStyles";
import SignUpInputFiled from "./SignUpInputFiled";

const useStyles = makeStyles((theme) => ({
   engageGradientBlue: {
      background: `linear-gradient(90deg,  #B1EDFF 0%, rgba(177, 237, 255, 0) 100%),
         linear-gradient(180deg, #0CECC4 0%, rgba(12, 236, 196, 0) 100%), 
         ${theme.palette.primary.main}
         `,
   },
   engageGradientPurple: {
      background: `linear-gradient(90deg,  #FFEFB7 0%, rgba(255, 239, 183, 0) 100%),
         linear-gradient(180deg, #AE47FF 0%, rgba(174, 71, 255, 0) 100%), 
         ${theme.palette.primary.main}
         `,
   },
   engageGradientOrange: {
      background: `linear-gradient(90deg, #FFC7E5 0%, rgba(255, 199, 229, 0) 100%),
         linear-gradient(180deg, #FF8845 0%, rgba(255, 136, 69, 0) 100%), 
         ${theme.palette.primary.main}
         `,
   },
   getStartedForm: {
      display: "flex",
      justifyContent: "center",
      padding: "45px 0 130px",
      maxWidth: "520px",
      margin: "0 auto",
   },
   timeToWork: {
      fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
      fontSize: theme.typography.big1.fontSize,
      lineHeight: "48px",
      textAlign: "center",
      paddingTop: "130px",
   },
   timeToWorkContent: {
      fontSize: "19px",
      lineHeight: "28px",
      textAlign: "center",
      maxWidth: "520px",
      margin: "0 auto",
   },
}));

const EngageSignUpForm = ({ variant = "orange" }) => {
   const classes = useStyles();

   // Select the styled components styles based on the provided variant prop to set different background gradients.
   const gradientStyle = (selectedVariant) => {
      switch (selectedVariant) {
         case "purple":
            return classes.engageGradientPurple;
         case "blue":
            return classes.engageGradientBlue;
         case "orange":
         default:
            return classes.engageGradientOrange;
      }
   };

   return (
      <div className={gradientStyle(variant)}>
         <Container>
            <h1 className={classes.timeToWork}>Time to make music?</h1>
            <div className={classes.timeToWorkContent}>
               Take the first step on your journey today, and let Aux help with
               all the rest. Get started for free with 2GB of personal storage
               and unlimited projects that lasts forever.
            </div>
            <div className={classes.getStartedForm}>
               <SignUpInputFiled />
            </div>
         </Container>
      </div>
   );
};

EngageSignUpForm.propTypes = {
   variant: PropTypes.oneOf(["orange", "purple", "blue"]),
};

EngageSignUpForm.defaultProps = {
   variant: "orange",
};

export default EngageSignUpForm;
