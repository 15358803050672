/**
 * Generate URI
 *
 * Generate a random streaming to be used for the uri.
 * @param {number} length Length of the uri you would like
 * @returns {string} uri.
 */
const generateUri = (length) => {
   let result = "";
   const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
   const charactersLength = characters.length;
   for (let i = 0; i < length; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
};

export default generateUri;
