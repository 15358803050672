import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { Link, useHistory, useParams } from "react-router-dom";
import MetaTags from "_components/MetaTags";

import { useQuery } from "@apollo/react-hooks";
import Avatar from "@mui/material/Avatar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Card, CardContent, Chip, CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import { setUpgradeModal } from "_redux/actions";
import PublicPrivateLayoutSwitcher from "_layouts/PublicPrivateLayoutSwitcher";
import useStatsMutation from "_utils/useStatsMutation";
// import CustomModal from "_components/Modal/CustomModal";
// import FeatureProjectModal from "_components/FeatureProjectModal";
import { useUser } from "_utils/UserContext";
import useDownloadZip from "_utils/useDownloadZip";
import {
   GET_CURRENT_USER,
   GUIDE_VALUES_PRIVATE,
   GET_USER_PROFILE_PUBLIC,
} from "../../_apollo/queries";
import LockAlertIcon from "../../_assets/LockAlertIcon";
import ConnectionStateButton from "../../_components/ConnectionStateButton";
import TagExtractor from "../../_components/TagExtractor";
import ProfileAwardsSection from "./ProfileAwardsSection";
import ClaimProfileCard from "./ClaimProfileCard";

const useStyles = makeStyles((theme) => ({
   root: {
      height: "100%",
      width: "100%",
      overflowY: "auto",
   },
   headerBackground: {
      width: "100%",
      height: "284px",
      backgroundSize: "cover",
   },
   header: {
      padding: "24px 2.4vw",
      display: "flex",
      alignItems: "center",
   },
   container: {
      padding: "54px 2.4vw",
      marginBottom: "6px",
   },
   headerProfileImg: {
      width: "100px",
      height: "100px",
   },
   headerInfoContainer: {
      marginRight: "auto",
      marginLeft: "15px",
      maxWidth: "500px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
   },
   headerInfo: {
      fontSize: theme.typography.small2.fontSize,
      lineHeight: "16px",
      color: theme.palette.secondary.shade60,
   },
   headerTitle: {
      fontWeight: "700",
      fontSize: theme.typography.h5.fontSize,
      lineHeight: "24px",
      marginBottom: "8px",
   },
   titlesSections: {
      marginBottom: "18px",
      fontSize: theme.typography.h5.fontSize,
      lineHeight: "24px",
   },
   divider: {
      width: "100%",
      height: "1px",
      backgroundColor: theme.palette.secondary.shade15,
   },
   contactTitle: {
      fontSize: theme.typography.small2.fontSize,
      lineHeight: "16px",
      fontWeight: "bold",
   },
   boxContactField: {
      width: "100%",
      borderRadius: "4px",
      backgroundColor: theme.palette.secondary.shade5,
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "8px 12px",
      margin: "2px 0 12px",
      minHeight: "28px",
   },
   boxContactFieldLarge: {
      width: "100%",
      height: "104px",
      borderRadius: "4px",
      backgroundColor: theme.palette.secondary.shade5,
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      padding: "8px 12px",
      margin: "2px 0 12px",
      alignItems: "flex-start",
   },
   contactField: {
      fontSize: theme.typography.small2.fontSize,
      lineHeight: "16px",
      color: theme.palette.secondary.shade60,
      fontWeight: "normal",
      whiteSpace: "pre-wrap",
   },
   containerProjects: {
      display: "flex",
      flexWrap: "wrap",
      gap: "24px",
   },
   card: {
      width: "334px",
      height: "168px",
      display: "flex",
      padding: "16px",
      flexDirection: "column",
      "&:hover": {
         boxShadow: `0 0 0 2pt ${theme.palette.primary.main}`,
      },
   },
   cardHeader: {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: "24px",
   },
   cardContent: {
      fontSize: theme.typography.small2.fontSize,
      lineHeight: "16px",
      color: theme.palette.secondary.shade60,
   },
   cardFooter: {
      marginTop: "auto",
      fontSize: theme.typography.small2.fontSize,
      lineHeight: "16px",
      color: theme.palette.secondary.shade60,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
   },
   buttonsContainerProfile: {
      "@media (min-width:900px)": {
         display: "inline-flex",
      },
   },
}));
/**
 * Profile Page
 *
 * A users public profile page.
 * @param {props} props props
 * @returns {JSX.Element} Profile Page
 */
const ProfilePage = ({ dispatchSetUpgradeModal }) => {
   const [loggedUser] = useUser();
   const matches = useMediaQuery("(min-width:900px)");
   const downloadZip = useDownloadZip();
   const history = useHistory();

   const classes = useStyles();
   const { profileSlug } = useParams();

   const { refetch: getUser } = useQuery(GET_CURRENT_USER);

   const { data: dataProfile, refetch } = useQuery(GET_USER_PROFILE_PUBLIC, {
      variables: { uri: profileSlug },
      /**
       * getUserProfilePublic onCompleted
       *
       * Redirects to 404 if the user does not exist.
       * @param {props} props props
       */
      onCompleted({ profileDetailsPublic }) {
         // If there is not a user send then to 404
         if (!profileDetailsPublic) {
            history.push("/404");
         }
      },
   });

   useEffect(() => {
      refetch({ uri: profileSlug });
   }, [profileSlug]);

   const profile = { ...dataProfile?.profileDetailsPublic };

   const {
      data,
      loading: loadingContactDetails,
      refetch: getGuideValues,
   } = useQuery(GUIDE_VALUES_PRIVATE, {
      skip: !profile?.id,
      variables: {
         id: profile?.id,
      },
   });

   const sendStatsMutation = useStatsMutation({
      onCompleted: async () => {
         await getGuideValues();
         await getUser();
      },
   });

   // TODO: remove skipping because it appears we don't use it currently?
   const [, setSkip] = useState(true);
   useEffect(() => {
      if (profile?.id) {
         setSkip(false);
      }
   }, [profile]);

   // const { data: dataProjects } = useQuery(GET_PROJECTS, {
   //    variables: {
   //       onlyFeaturedProjectsOfId: profile?.id,
   //    },
   //    skip,
   // });
   // NOTE: FEATURE PROJECTS DISABLED(AFTER ACTIVATING AGAIN CHECK FOR EMAILS IN ACCEPTED CONTACTS - SECURITY)
   const dataProjects = {};

   const unlockedGuideAccess = !!loggedUser?.limits?.guideAccess?.find(
      (uri) => uri === profile?.uri
   );

   /**
    * Unlock Button
    *
    * Button to unlock the guide access - users must have paid plan to access directory.
    * @returns {JSX.Element} Unlock Button
    */
   const UnlockButton = () => {
      const hasSubscription = !!loggedUser?.subscriptions;
      let accessGuideLimitReached = false;

      if (loggedUser?.limits?.guideAccessLeft === 0) {
         accessGuideLimitReached = true;
      }

      if (hasSubscription && !accessGuideLimitReached) {
         return (
            <Button
               onClick={async () => {
                  await sendStatsMutation({
                     userId: loggedUser.id,
                     belongsToProfileId: profile.id,
                     statsId: "UserAccessGuide",
                  });
               }}
            >
               Unlock &nbsp; <LockAlertIcon />
            </Button>
         );
      }

      if (!loggedUser) {
         return (
            <Button component={Link} to="/signup">
               Unlock &nbsp; <LockAlertIcon />
            </Button>
         );
      }
      return (
         <Button
            onClick={() => {
               sendStatsMutation({
                  statsId: "PlanLimitReached",
               });
               dispatchSetUpgradeModal(true);
            }}
         >
            Unlock &nbsp; <LockAlertIcon />
         </Button>
      );
   };

   // Make sure we only send a *single* profile view event, not a new event on every re-render.
   const sentProfileViewMutationRef = useRef(false);
   // const [openFeatureProject, setOpenFeatureProject] = useState(false);

   useEffect(() => {
      if (
         loggedUser &&
         profile?.id &&
         loggedUser?.profile?.id !== profile?.id &&
         !sentProfileViewMutationRef.current
      ) {
         sendStatsMutation({
            statsId: "UserProfileView",
            userId: loggedUser?.id,
            belongsToProfileId: profile?.id,
         });
         sentProfileViewMutationRef.current = true;
      }
   }, [loggedUser, profile]);

   const [loadingDownload, setLoadingDownload] = useState(undefined);

   return (
      <PublicPrivateLayoutSwitcher typeLayout="profile">
         <MetaTags
            title={`${profile?.name ? `${profile.name} on ` : ""}Aux`}
            description={`Connect and collaborate ${
               profile?.name ? `with ${profile.name}` : ""
            } for free with Aux.`}
            image={profile?.backgroundImageSignedUrl}
         />

         {/* {openFeatureProject && (
            <CustomModal
               fullWidth="md"
               open={openFeatureProject}
               handleClose={() => setOpenFeatureProject(false)}
            >
               <FeatureProjectModal
                  profile={profile}
                  setOpen={setOpenFeatureProject}
               />
            </CustomModal>
         )} */}

         <div className={classes.root}>
            {profile?.backgroundImageSignedUrl && (
               <div
                  className={classes.headerBackground}
                  style={{
                     backgroundImage: `url(${profile?.backgroundImageSignedUrl})`,
                  }}
               />
            )}
            <Container style={loggedUser ? { padding: 0 } : {}}>
               <div className={classes.header}>
                  <Avatar
                     alt={profile?.name}
                     src={profile?.photoUrlOrGravatar}
                     className={classes.headerProfileImg}
                  />
                  <div className={classes.headerInfoContainer}>
                     <div className={classes.headerTitle}>{profile?.name}</div>
                     <div className={classes.headerInfo}>
                        {profile?.description}
                        <TagExtractor profile={profile?.description} />
                     </div>
                     <Grid container>
                        {profile?.tags?.map((el) => {
                           return (
                              <Grid
                                 key={`${profile?.uri}-${el?.uri}-tags-profile`}
                                 item
                                 style={{ marginBottom: 5, marginRight: 5 }}
                              >
                                 <Chip
                                    size="small"
                                    component={Link}
                                    to={`/discover/${el.uri}`}
                                    key={el.id}
                                    label={el.name}
                                    style={{ cursor: "pointer" }}
                                 />
                              </Grid>
                           );
                        })}
                     </Grid>
                  </div>
                  <div
                     className={matches ? classes.buttonsContainerProfile : ""}
                  >
                     {profile?.uri === loggedUser?.profile?.uri && (
                        <Button
                           component={Link}
                           to="/settings"
                           size="large"
                           variant="text"
                           color="primary"
                        >
                           Edit Your Profile
                        </Button>
                     )}
                     {profile?.uri !== loggedUser?.profile?.uri && (
                        <>
                           {/* TODO: link this to open a DM conversation with this profile once we have DM functionality. */}
                           {/* <Button
                           className={classes.messageBtn}
                           size="large"
                           variant="outlined"
                           color="primary"
                        >
                           Message
                        </Button> */}
                           {!profile?.awardNominee && (
                              <ConnectionStateButton
                                 connection={
                                    loggedUser?.profile?.connections?.sent.find(
                                       (c) =>
                                          c.receiverProfile?.id === profile?.id
                                    ) ||
                                    loggedUser?.profile?.connections?.received.find(
                                       (c) =>
                                          c.senderProfile?.id === profile?.id
                                    )
                                 }
                                 publicProfile={profile}
                                 isSender={
                                    !!loggedUser?.profile?.connections?.sent.find(
                                       (c) =>
                                          c.receiverProfile?.id === profile?.id
                                    )
                                 }
                                 isReceiver={
                                    !!loggedUser?.profile?.connections?.received.find(
                                       (c) =>
                                          c.senderProfile?.id === profile?.id
                                    )
                                 }
                                 buttonStyles={{
                                    size: "large",
                                    variant: "contained",
                                 }}
                              />
                           )}
                        </>
                     )}
                  </div>
               </div>
               <div className={classes.divider} />
               {profile &&
                  profile?.tags?.filter((a) => !!a?.awardsCategoryTitle)
                     .length > 0 && (
                     <ProfileAwardsSection
                        awardNominee={!!profile?.awardNominee}
                        tags={profile?.tags}
                     />
                  )}
               {profile?.uri !== loggedUser?.profile?.uri &&
                  !profile?.awardNominee &&
                  profile?.guide && (
                     <>
                        <div className={classes.container}>
                           <div className={classes.titlesSections}>
                              Contact Details
                           </div>
                           <Grid container spacing={6}>
                              <Grid
                                 item
                                 xs={7}
                                 className={classes.contactTitle}
                              >
                                 {loadingContactDetails ? (
                                    <Grid
                                       container
                                       justifyContent="center"
                                       alignItems="center"
                                       style={{ height: "100%" }}
                                    >
                                       <CircularProgress />
                                    </Grid>
                                 ) : (
                                    <Grid container spacing={6}>
                                       <Grid item xs={6}>
                                          <div className={classes.contactTitle}>
                                             Phone Number
                                          </div>
                                          <div
                                             className={classes.boxContactField}
                                          >
                                             {data?.getGuideValuesPrivate
                                                ?.phoneNumber ||
                                             unlockedGuideAccess ? (
                                                <div
                                                   className={
                                                      classes.contactField
                                                   }
                                                >
                                                   {
                                                      data
                                                         ?.getGuideValuesPrivate
                                                         ?.phoneNumber
                                                   }
                                                </div>
                                             ) : (
                                                <>
                                                   <div
                                                      className={
                                                         classes.contactField
                                                      }
                                                   >
                                                      *********
                                                   </div>

                                                   <UnlockButton />
                                                </>
                                             )}
                                          </div>
                                          <div className={classes.contactTitle}>
                                             Email
                                          </div>
                                          <div
                                             className={classes.boxContactField}
                                          >
                                             {data?.getGuideValuesPrivate
                                                ?.email ||
                                             unlockedGuideAccess ? (
                                                <div
                                                   className={
                                                      classes.contactField
                                                   }
                                                >
                                                   {
                                                      data
                                                         ?.getGuideValuesPrivate
                                                         ?.email
                                                   }
                                                </div>
                                             ) : (
                                                <>
                                                   <div
                                                      className={
                                                         classes.contactField
                                                      }
                                                   >
                                                      *********
                                                   </div>
                                                   <UnlockButton />
                                                </>
                                             )}
                                          </div>
                                       </Grid>

                                       <Grid item xs={6}>
                                          <div className={classes.contactTitle}>
                                             Address
                                          </div>
                                          <div
                                             className={
                                                classes.boxContactFieldLarge
                                             }
                                          >
                                             {data?.getGuideValuesPrivate
                                                ?.address ||
                                             unlockedGuideAccess ? (
                                                <div
                                                   className={
                                                      classes.contactField
                                                   }
                                                >
                                                   {
                                                      data
                                                         ?.getGuideValuesPrivate
                                                         ?.address
                                                   }
                                                </div>
                                             ) : (
                                                <>
                                                   <div>
                                                      <div
                                                         className={
                                                            classes.contactField
                                                         }
                                                      >
                                                         *********
                                                      </div>
                                                      <div
                                                         className={
                                                            classes.contactField
                                                         }
                                                      >
                                                         *********
                                                      </div>
                                                      <div
                                                         className={
                                                            classes.contactField
                                                         }
                                                      >
                                                         *********
                                                      </div>
                                                   </div>
                                                   <UnlockButton />
                                                </>
                                             )}
                                          </div>
                                       </Grid>
                                    </Grid>
                                 )}
                              </Grid>
                              <Grid item xs={5}>
                                 <ClaimProfileCard
                                    title=" What is the Aux Guide?"
                                    subtitle="The Aux Guide collects public contact
                              information from the top music industry
                              companies and collects them in a simple
                              searchable database for Aux members."
                                 />
                              </Grid>
                           </Grid>
                        </div>
                        <div className={classes.divider} />
                     </>
                  )}

               {!profile?.awardNominee && (
                  <div className={classes.container}>
                     {/* <div className={classes.titlesSections}>
                        Featured Projects &nbsp;&nbsp;
                        {profile?.uri === loggedUser?.profile?.uri && (
                           <Button
                              size="large"
                              variant="outlined"
                              color="primary"
                              onClick={() => setOpenFeatureProject(true)}
                           >
                              Add Project
                           </Button>
                        )}
                     </div> */}
                     <div className={classes.containerProjects}>
                        {dataProjects?.getProjects?.length > 0 ? (
                           <>
                              {dataProjects?.getProjects.map((project) => {
                                 return (
                                    <Card
                                       key={`${profile?.uri}-${project?.uri}-projects-profile`}
                                       variant="outlined"
                                       className={classes.card}
                                       onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          if (
                                             project?.acceptedContacts.some(
                                                (a) =>
                                                   a.uri ===
                                                   loggedUser?.profile?.uri
                                             )
                                          ) {
                                             history.push(
                                                `/projects/${project?.uri}/folder/${project?.folder?.uri}`
                                             );
                                          }
                                       }}
                                    >
                                       <div className={classes.cardHeader}>
                                          {project?.title}
                                       </div>
                                       <p className={classes.cardContent}>
                                          {project?.description}
                                       </p>
                                       <div className={classes.cardFooter}>
                                          <Button
                                             color="primary"
                                             variant="contained"
                                             disabled={loadingDownload}
                                             onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setLoadingDownload(
                                                   project?.folderId
                                                );
                                                downloadZip(
                                                   [],
                                                   [project?.folder],
                                                   () =>
                                                      setLoadingDownload(
                                                         undefined
                                                      )
                                                );
                                             }}
                                          >
                                             {loadingDownload ===
                                             project?.folderId
                                                ? "Loading..."
                                                : "Download Files"}
                                          </Button>
                                       </div>
                                    </Card>
                                 );
                              })}
                           </>
                        ) : (
                           <Card variant="outlined" className={classes.card}>
                              <CardContent>
                                 <div
                                    className={classes.cardHeader}
                                    style={{ marginBottom: 16 }}
                                 >
                                    {/* Nothing to See Here */}
                                    Connect with {profile?.name}
                                 </div>
                                 <div className={classes.cardContent}>
                                    Click the Connect button to start working
                                    with {profile?.name} on your next music
                                    project.
                                 </div>
                              </CardContent>
                           </Card>
                        )}
                     </div>
                  </div>
               )}
               {!loggedUser && <div className={classes.divider} />}
            </Container>
         </div>
      </PublicPrivateLayoutSwitcher>
   );
};

ProfilePage.propTypes = {
   dispatchSetUpgradeModal: PropTypes.func,
};

ProfilePage.defaultProps = {
   dispatchSetUpgradeModal: null,
};

export default connect(undefined, {
   dispatchSetUpgradeModal: setUpgradeModal,
})(ProfilePage);
