import globalConfig from "../config.json";

/* eslint-disable no-nested-ternary */
export const CONFIG_ENV = process.env.REACT_APP_CONFIG_ENV
   ? process.env.REACT_APP_CONFIG_ENV
      ? process.env.REACT_APP_CONFIG_ENV
      : "expo"
   : process.env.REACT_APP_VERCEL_ENV === "preview" ||
     process.env.REACT_APP_VERCEL_ENV === "development"
   ? "green"
   : process.env.REACT_APP_VERCEL_ENV === "production"
   ? "standalone"
   : "expo";
/* eslint-enable no-nested-ternary */

/**
 * Get Config
 *
 * Get the apps config data object, scoped to the current CONFIG_ENV.
 * @returns {object} App config object.
 */
const getConfig = () => {
   return globalConfig[CONFIG_ENV];
};

export default getConfig;
