import { getFileTypeObject, getGenericName } from "./fileTypeUtil/fileTypeUtil";
import fileTypeData from "./fileTypeUtil/fileTypeData";
import { File, Folder } from "../types";

type FileFolder = File | Folder;

type InputObject = {
  fileFolder?: FileFolder;
  fileName?: string;
};

/**
 * Is DAW Package Type
 *
 * Checks if a file type is a DAW package type file using the file or the fileName.
 * @param {object} inputObject props
 * @param {object} inputObject.fileFolder File to check.
 * @param {string} inputObject.fileName Name to check.
 * @returns {boolean} Is the file a DAW type.
 */
const isDawPackageType = (inputObject: InputObject): boolean => {
  const { fileFolder, fileName } = inputObject;

  // Function may be called without input while queries are loading.
  if (!fileFolder && !fileName) {
    return false;
  }

  // This get's a list of supported DAW file types from the list of file
  // types in fileTypeData.js. This way we can quickly add new supported package
  // type files by setting packageFile: true in fileTypeData.js
  const dawType = fileTypeData.reduce((accumulator, currentValue) => {
    if (currentValue.packageFile) {
      accumulator.push(currentValue.name);
    }
    return accumulator;
  }, []);
  if (fileName) {
    return dawType.includes(getFileTypeObject(fileName)?.name);
  }

  return dawType.includes(getGenericName(fileFolder));
};

export default isDawPackageType;
