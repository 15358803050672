import React, { useState, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/react-hooks";

import { Form } from "formik";
import { TextField } from "@mui/material";
import { GET_CATEGORIES } from "../../_apollo/queries";
import AutoCompleteItem from "../SuggestionsContacts/AutoCompleteItem";

/**
 * Suggestions Categories
 *
 * TODO: this is a near exact copy of SuggestionsTags and SuggestionsContacts so they can be combined into one general component or split into cleaner lower level components.
 * @param {object} props props.
 * @param {Array} props.categoriesSearch categoriesSearch
 * @param {Function} props.setCategoriesSearch setCategoriesSearch
 * @param {Array} props.categoriesDefault categoriesDefault
 * @param {object} props.values values
 * @param {Array} props.categories categories
 * @param {Function} props.handleChange handleChange
 * @param {Function} props.addCategories addCategories
 * @param {Function} props.setFieldValue setFieldValue
 * @param {Array} props.errors errors
 * @param {boolean} props.touched touched
 * @param {Function} props.setFieldTouched setFieldTouched
 * @returns {JSX.Element} SuggestionsCategories component.
 */
const SuggestionsCategories = ({
   categoriesSearch,
   setCategoriesSearch,
   categoriesDefault,
   values,
   categories,
   handleChange,
   addCategories,
   setFieldValue,
   errors,
   touched,
   setFieldTouched,
}) => {
   const [getCategories] = useLazyQuery(GET_CATEGORIES, {
      /**
       * getCategories onCompleted
       * @param {object} data Apollo data object.
       */
      onCompleted(data) {
         setCategoriesSearch(data.getCategories);
      },
   });

   const [isVisible, setVisibility] = useState(touched);
   const [cursor, setCursor] = useState(-1);

   const timer = useRef(null);

   const searchContainer = useRef(null);
   const searchResultRef = useRef(null);

   /**
    * Show Suggestion
    */
   const showSuggestion = () => {
      setFieldTouched("category", true);
      setVisibility(true);
   };

   /**
    * Hide Suggestion
    */
   const hideSuggestion = () => {
      setFieldValue("category", "");
      setVisibility(false);
   };

   /**
    * Handle click outside
    * @param {object} event event
    */
   const handleClickOutside = (event) => {
      if (
         searchContainer.current &&
         !searchContainer.current.contains(event.target)
      ) {
         hideSuggestion();
      }
   };

   useEffect(() => {
      window.addEventListener("mousedown", handleClickOutside);

      return () => {
         window.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   /**
    * Scroll Into View
    * @param {number} position Position to scroll to.
    */
   const scrollIntoView = (position) => {
      if (searchResultRef.current !== null) {
         searchResultRef.current.parentNode.scrollTo({
            top: position - 80,
            behavior: "smooth",
         });
      }
   };

   const suggestions = useMemo(() => {
      const categoryFilter =
         values.category === "" ? categoriesDefault : categoriesSearch;
      // if (!values.category || values.category === '') return [];
      if (!categoryFilter || categoryFilter.length === 0) return [];
      if (!isVisible) return [];
      setCursor(-1);
      scrollIntoView(0);
      showSuggestion();
      if (values.category) {
         return categoryFilter
            .filter(
               ({ name }) =>
                  [
                     ...(categories.map((el) => el.name) || []),
                     ...(values?.category || []),
                  ].indexOf(name) === -1
            )
            .filter((element) => {
               if (values.category !== "") {
                  if (values.category !== element.name) {
                     return (
                        element.name &&
                        element.name
                           .toLowerCase()
                           .includes(values.category.toLowerCase().trim())
                     );
                  }
               }
               return false;
            });
      }
      return categoryFilter;
   }, [categoriesSearch, values.category, touched]);

   useEffect(() => {
      if (cursor < 0 || cursor > suggestions.length || !searchResultRef) {
         return () => {};
      }

      const listItems = Array.from(searchResultRef.current.children);
      if (listItems[cursor]) {
         scrollIntoView(listItems[cursor].offsetTop);
      }
      return null;
   }, [cursor]);

   /**
    * Keyboard Navigation
    * @param {object} e event
    */
   const keyboardNavigation = (e) => {
      if (e.key === "ArrowDown") {
         if (isVisible) {
            setCursor((c) => (c < suggestions.length - 1 ? c + 1 : c));
         } else {
            showSuggestion();
         }
      }

      if (e.key === "ArrowUp") {
         setCursor((c) => (c > 0 ? c - 1 : 0));
      }

      if (e.key === "Escape") {
         hideSuggestion();
      }
      if (e.key === "Tab") {
         addCategories({ name: values.category });
         hideSuggestion();
      }

      if (e.key === "Enter" && cursor >= 0) {
         e.preventDefault();
         addCategories({
            id: suggestions[cursor]?.id,
            name: suggestions[cursor]?.name,
         });
         hideSuggestion();
      } else if (e.key === "Enter" && values.category !== "") {
         e.preventDefault();
         addCategories({ name: values.category });
         hideSuggestion();
      }
   };

   /**
    * Handle Category Change
    * @param {object} e event
    */
   const handleCategoryChange = (e) => {
      handleChange(e);
      showSuggestion();
      clearTimeout(timer.current);
      // Search for categories
      if (e.target.value !== "") {
         timer.current = setTimeout(() => {
            getCategories({
               variables: {
                  name: e.target.value,
               },
            });
         }, 1000);
      }
   };

   return (
      <div ref={searchContainer} className="wrapper-autosuggest">
         <TextField
            className="mt-1"
            name="category"
            placeholder="Add a category"
            error={errors.category}
            value={values.category}
            type="text"
            autoComplete="off"
            onClick={showSuggestion}
            onChange={(e) => handleCategoryChange(e)}
            onKeyDown={(e) => keyboardNavigation(e)}
            onFocus={(event) => {
               event.target.setAttribute("autocomplete", "off");
            }}
            isInvalid={!!errors.category}
         />
         <div
            className={`search-result ${isVisible ? "visible" : "invisible"}`}
         >
            <div className="list-group" ref={searchResultRef}>
               {suggestions.map((item, idx) => {
                  return (
                     <AutoCompleteItem
                        key={item.name}
                        onSelectItem={() => {
                           hideSuggestion();
                           addCategories({ id: item.id, name: item.name });
                           setFieldValue("category", "");
                        }}
                        isHighlighted={cursor === idx}
                        name={item.name}
                        email={item.email}
                     />
                  );
               })}
            </div>
         </div>
         <Form.Control.Feedback type="invalid">
            {errors.category}
         </Form.Control.Feedback>
      </div>
   );
};

SuggestionsCategories.propTypes = {
   categoriesSearch: PropTypes.instanceOf(Array).isRequired,
   setCategoriesSearch: PropTypes.func.isRequired,
   categoriesDefault: PropTypes.instanceOf(Array).isRequired,
   values: PropTypes.instanceOf(Object).isRequired,
   categories: PropTypes.instanceOf(Array).isRequired,
   handleChange: PropTypes.func.isRequired,
   addCategories: PropTypes.func.isRequired,
   setFieldValue: PropTypes.func.isRequired,
   errors: PropTypes.instanceOf(Array).isRequired,
   touched: PropTypes.bool.isRequired,
   setFieldTouched: PropTypes.func.isRequired,
};

export default SuggestionsCategories;
