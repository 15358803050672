import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

// Redux
import { connect } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import { Box, InputLabel } from "@mui/material";

// Apollo
import useStatsMutation from "_utils/useStatsMutation";
import { UPDATE_CONTACT_INFO } from "_apollo/queries";
import { useUser } from "_utils/UserContext";

const validationSchema = Yup.object().shape({
   email: Yup.string()
      .email()
      .lowercase()
      .trim()
      .max(255)
      .required()
      .label("Email"),
   name: Yup.string().required().trim().min(3).max(255).label("Name"),
});

/**
 * Name Settings Form.
 * @param {object} props props.
 * @param {boolean} props.isManagingProfile defines if an admin is managing this profile.
 * @param {object} props.managingProfile user managed.
 * @param {string} props.selectedTeam team id.PropTypes.
 * @returns {JSX.Element} Form edit name email from profile.
 */
function NameSettingsForm({
   isManagingProfile,
   managingProfile,
   selectedTeam,
}) {
   const [user] = useUser();
   const [isUpdated, setIsUpdated] = useState(false);
   const sendStatsMutation = useStatsMutation();

   const [
      updateContactInfo,
      { loading: loadingContactInfo, error: errorUpdateContact },
   ] = useMutation(UPDATE_CONTACT_INFO, {
      /**
       * updateContactInfo onCompeleted
       *
       * After updating contact info send a stat and redirect to the users feed.
       */
      onCompleted() {
         setIsUpdated(true);
         setTimeout(() => {
            setIsUpdated(false);
         }, 5000);
         sendStatsMutation({
            statsId: "UserProfileUpdated",
            userId: user.id,
         });
      },
   });

   const textButton = useMemo(() => {
      if (loadingContactInfo) {
         return "Loading...";
      }
      if (isUpdated) {
         return "Updated";
      }
      return "Update";
   }, [isUpdated, loadingContactInfo]);

   return (
      <Box style={{ marginTop: 20 }}>
         <Formik
            enableReinitialize
            initialValues={{
               name:
                  (isManagingProfile
                     ? managingProfile?.name
                     : user?.profile?.name) || "",
               email:
                  (isManagingProfile
                     ? managingProfile?.email
                     : user?.profile?.email) || "",
            }}
            validateOnChange={false}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
               await updateContactInfo({
                  variables: {
                     email: values.email,
                     name: values.name,
                     id: managingProfile?.id || null,
                     teamId: selectedTeam,
                  },
               });
            }}
         >
            {({
               handleSubmit,
               handleChange,
               errors,
               values,
               setFieldError,
               touched,
            }) => {
               if (errorUpdateContact && errorUpdateContact.graphQLErrors) {
                  errorUpdateContact?.graphQLErrors?.forEach((event) => {
                     setFieldError("email", event?.message);

                     // Delete the errors to avoid re-render loop
                     delete errorUpdateContact.graphQLErrors;
                  });
               }
               return (
                  <form onSubmit={handleSubmit} id="update-profile-form">
                     <Box mb={1}>
                        <InputLabel>Name</InputLabel>
                        <TextField
                           id="update-profile-username-input"
                           size="small"
                           fullWidth
                           variant="outlined"
                           name="name"
                           placeholder="Name"
                           value={values.name}
                           onChange={handleChange}
                           error={touched.name && Boolean(errors.name)}
                           helperText={touched.name && errors.name}
                        />
                     </Box>
                     <Box mb={1}>
                        <InputLabel>Email</InputLabel>

                        <TextField
                           size="small"
                           fullWidth
                           variant="outlined"
                           id="email"
                           name="email"
                           placeholder="Email"
                           value={values.email}
                           onChange={handleChange}
                           error={touched.email && Boolean(errors.email)}
                           helperText={touched.email && errors.email}
                        />
                     </Box>

                     <Button
                        disabled={loadingContactInfo}
                        variant="outlined"
                        color="primary"
                        type="submit"
                        sx={
                           isUpdated
                              ? {
                                   backgroundColor: "#AE47FF",
                                   "&:hover": {
                                      backgroundColor: "#AE47FF",
                                      boxShadow: "none",
                                   },
                                }
                              : {}
                        }
                        id="update-profile-submit-button"
                     >
                        {textButton}
                     </Button>
                  </form>
               );
            }}
         </Formik>
      </Box>
   );
}

NameSettingsForm.propTypes = {
   selectedTeam: PropTypes.string.isRequired,
   managingProfile: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      uri: PropTypes.string,
      links: PropTypes.arrayOf({}),
   }).isRequired,
   isManagingProfile: PropTypes.bool.isRequired,
};

/**
 * mapStateToProps
 * @deprecated TODO: update to hook based redux method.
 * @param {object} state redux state props
 * @returns {object} component props with redux props added.
 */
const mapStateToProps = (state) => {
   const { selectedTeam, managingProfile, isManagingProfile } =
      state.AppReducer;
   return { selectedTeam, managingProfile, isManagingProfile };
};

export default connect(mapStateToProps)(NameSettingsForm);
