import PropTypes from "prop-types";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Chip from "@mui/material/Chip";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const useStyles = makeStyles((theme) => ({
   card: {
      width: "100%",
      padding: "12px 12px 0 12px",
      marginTop: "18px",
      minHeight: "102px",
      borderRadius: "4px",
      border: `solid 1px ${theme.palette.secondary.shade15}`,
   },
   title: {
      fontSize: "12px",
      lineHeight: "16px",
      marginBottom: "4px",
   },
   subtitle: {
      fontSize: "12px",
      lineHeight: "16px",
      color: theme.palette.secondary.shade60,
   },
   chip: {
      marginBottom: "8px",
      backgroundColor: "#FFD645",
      color: "white",
      fontSize: "12px",
      lineHeight: "16px",
      paddingLeft: "6px",
      paddingRight: "6px",
      height: "23px",
   },
   white: {
      fill: "white",
      height: "20px",
      width: "20px",
   },
}));

/**
 * Award Card.
 * @param {object} props props object
 * @param {string} props.title Title of the card.
 * @param {string} props.subtitle Body of the card.
 * @param {string} props.award Chip content.
 * @param {string} props.uri Body of the card.
 * @returns {JSX.Element} Award Card component
 */
const AwardCard = ({ title, subtitle, award, uri }) => {
   const classes = useStyles();

   return (
      <div className={classes.card}>
         <Chip
            className={classes.chip}
            label={award}
            component="a"
            href={`/discover/${uri}`}
            clickable
            icon={<EmojiEventsIcon className={classes.white} />}
         />
         <div className={classes.title}>{title}</div>
         <div className={classes.subtitle}>{subtitle}</div>
      </div>
   );
};

AwardCard.propTypes = {
   subtitle: PropTypes.string.isRequired,
   title: PropTypes.string.isRequired,
   award: PropTypes.string.isRequired,
   uri: PropTypes.string.isRequired,
};

export default AwardCard;
