import { Button, Grid } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Link, useHistory } from "react-router-dom";

import useStatsMutation from "_utils/useStatsMutation";
import { useUser } from "_utils/UserContext";
import {
   ACCEPT_DECLINE_CONNECTION,
   GET_CURRENT_USER,
   REMOVE_CONNECTION,
   SEND_CONNECT_INVITE,
} from "../_apollo/queries";
import DangerButton from "./DangerButton";
import CustomModal from "./Modal/CustomModal";

/**
 * ConnectionStateButton
 * Button that displays the current state of the connection between two users.
 * @param {object} props - The props.
 * @param {object} props.connection - The connection object.
 * @param {object} props.buttonStyles - The styles to be applied to the button.
 * @param {boolean} props.isSender - Whether the user is the sender of the connection.
 * @param {boolean} props.isReceiver - Whether the user is the receiver of the connection.
 * @param {object} props.publicProfile - Profile which button action will have effect.
 * @returns {JSX.Element} - The ConnectionStateButton component.
 */
const ConnectionStateButton = ({
   connection,
   buttonStyles,
   isSender,
   isReceiver,
   publicProfile,
}) => {
   const [textContent, setTextContent] = useState("Connect");
   const [hoverTextContent, setHoverTextContent] = useState();
   const clickHandlerRef = useRef();
   let buttonColor = "primary.main";
   let buttonVariant = "contained";

   const [user] = useUser();
   const profile = user?.profile;
   const [sendConnectInvite] = useMutation(SEND_CONNECT_INVITE, {
      refetchQueries: [{ query: GET_CURRENT_USER }],
   });
   const [acceptDeclineConnection] = useMutation(ACCEPT_DECLINE_CONNECTION, {
      refetchQueries: [{ query: GET_CURRENT_USER }],
   });
   const [removeConnection] = useMutation(REMOVE_CONNECTION, {
      refetchQueries: [{ query: GET_CURRENT_USER }],
   });
   const sendStatsMutation = useStatsMutation();

   const [showModal, setShowModal] = useState(false);

   const history = useHistory();
   useEffect(() => {
      if (profile) {
         if (isSender) {
            if (connection?.accepted === true) {
               setTextContent("Disconnect");
               clickHandlerRef.current = () => {
                  setShowModal(true);
               };
               buttonColor = "danger.main";
               buttonVariant = "text";
            } else if (connection?.declined === true) {
               setTextContent("Pending");
               clickHandlerRef.current = () => {
                  removeConnection({
                     variables: {
                        senderProfileUri: connection?.senderProfile?.uri,
                        receiverProfileUri: connection?.receiverProfile?.uri,
                     },
                  });
               };
               buttonColor = "danger.main";
               buttonVariant = "text";
            } else {
               setTextContent("Pending");
               clickHandlerRef.current = () => {
                  removeConnection({
                     variables: {
                        senderProfileUri: connection?.senderProfile?.uri,
                        receiverProfileUri: connection?.receiverProfile?.uri,
                     },
                  });
               };
               buttonColor = "danger.main";
               buttonVariant = "text";
            }
         } else if (isReceiver) {
            if (connection?.accepted === true) {
               setTextContent("Disconnect");
               clickHandlerRef.current = () => {
                  setShowModal(true);
               };
               buttonColor = "danger.main";
               buttonVariant = "text";
            } else if (
               connection?.accepted === false &&
               connection?.declined === false
            ) {
               setTextContent("Accept");
               clickHandlerRef.current = () => {
                  setTextContent("Pending");
                  acceptDeclineConnection({
                     variables: {
                        acceptValue: true,
                        connectionId: connection?.id,
                     },
                  });
               };
            } else {
               setTextContent("Connect");
               clickHandlerRef.current = () => {
                  setTextContent("Pending");
                  acceptDeclineConnection({
                     variables: {
                        acceptValue: true,
                        connectionId: connection?.id,
                     },
                  });
               };
            }
         } else {
            setTextContent("Connect");
            clickHandlerRef.current = () => {
               setTextContent("Pending");
               sendConnectInvite({
                  variables: {
                     receiverProfileUri: publicProfile?.uri,
                  },
               });
               sendStatsMutation({
                  statsId: "UserConnectionRequested",
                  belongsToProfileId: publicProfile?.id,
               });
            };
         }
      } else {
         setTextContent("Connect");
         clickHandlerRef.current = () => {
            history.push("/signup");
         };
      }
   }, [connection, isSender, isReceiver, profile]);

   if (profile?.id === publicProfile?.id) {
      return null;
   }

   // If the user is logged out, the connect button takes the user to sign up before redirecting back to the profile.
   if (!profile) {
      return (
         <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonStyles}
            component={Link}
            to={`/signup?redirectTo=${publicProfile?.uri}`}
         >
            Connect
         </Button>
      );
   }

   return (
      <>
         <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonStyles}
            sx={{
               ...buttonStyles?.sx,
               width: "73px",
               height: "26px",
               padding: "5px 8px",
               fontSize: "12px",
               backgroundColor:
                  textContent === "Pending" ? "danger.main" : "black",
               color: "white",
               "&:hover": {
                  backgroundColor:
                     textContent === "Pending" ? "danger.main" : buttonColor,
               },
            }}
            variant={buttonVariant}
            onClick={clickHandlerRef.current}
            onMouseEnter={() => {
               if (textContent === "Pending") {
                  setHoverTextContent("Cancel");
               }
            }}
            onMouseLeave={() => {
               setHoverTextContent(null);
            }}
         >
            {hoverTextContent || textContent}
         </Button>
         <CustomModal handleClose={() => setShowModal(false)} open={showModal}>
            <Grid
               justifyContent="center"
               alignItems="center"
               container
               direction="column"
            >
               <h3>Are you sure you want to disconnect</h3>
               <DangerButton
                  size="large"
                  onClick={() => {
                     if (isSender) {
                        removeConnection({
                           variables: {
                              senderProfileUri: connection?.senderProfile?.uri,
                              receiverProfileUri:
                                 connection?.receiverProfile?.uri,
                           },
                        });
                     } else {
                        removeConnection({
                           variables: {
                              senderProfileUri: connection?.senderProfile?.uri,
                              receiverProfileUri:
                                 connection?.receiverProfile?.uri,
                           },
                        });
                     }
                     setShowModal(false);
                  }}
               >
                  Disconnect
               </DangerButton>
            </Grid>
         </CustomModal>
      </>
   );
};

export default ConnectionStateButton;

ConnectionStateButton.propTypes = {
   connection: PropTypes.instanceOf(Object).isRequired,
   buttonStyles: PropTypes.instanceOf(Object),
   isSender: PropTypes.bool.isRequired,
   isReceiver: PropTypes.bool.isRequired,
   profile: PropTypes.instanceOf(Object).isRequired,
   publicProfile: PropTypes.shape({
      uri: PropTypes.string,
      id: PropTypes.string,
   }),
};

ConnectionStateButton.defaultProps = {
   buttonStyles: {},
   publicProfile: {},
};
