import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material";
import generateUri from "_utils/generateUri";
import { getMimeType } from "_utils/fileTypeUtil/fileTypeUtil";

/* Wrapper for tags that will make them a Droppable container,
  @handleDropEvent will recieve the files */

const styles = {
   width: "calc(100% - 4px)",
   height: "calc(100% - 4px)",
   position: "absolute",
   top: 0,
   bottom: 0,
   right: 0,
   left: 0,
   zIndex: 99999,
};

const FileUploaderDropHandler = ({
   handleDropEvent,
   dropId,
   setSelectedDropFolderId,
   isStem,
   isBouncedown,
}) => {
   const [dragging, setDragging] = useState(false);
   const [visible, setVisible] = useState(false);

   const dropzoneRef = useRef(null);
   const theme = useTheme();
   const getFilesDataTransferItems = async (dataTransferItems) => {
      const traverseFileTreePromise = (item, path = "", folder, filesList) => {
         return new Promise((resolve) => {
            if (item?.isFile) {
               item?.file((file) => {
                  // Exclude mac .DS_Store hidden files from folder uploads.
                  if (file.name !== ".DS_Store") {
                     const modifiedFile = file;
                     modifiedFile.uri = generateUri(10);
                     modifiedFile.fileName = file.name;
                     const [mimeType] = getMimeType({
                        originalName: file.name,
                     });
                     modifiedFile.mimeType = mimeType || "other";
                     modifiedFile.modifiedAt = file?.lastModifiedDate;
                     modifiedFile.fileSize = file.size;
                     if (!modifiedFile.path) {
                        modifiedFile.path = item?.fullPath;
                     }

                     // set folder name
                     const removeBottom = item?.fullPath?.slice(
                        0,
                        item?.fullPath?.lastIndexOf("/")
                     ); // remove file name
                     modifiedFile.folderName = removeBottom.slice(
                        removeBottom?.lastIndexOf("/") + 1
                     ); // remove parent folders

                     folder.push(modifiedFile);
                     filesList.push(modifiedFile);
                     resolve(modifiedFile);
                  }
                  resolve(file);
               });
            } else if (item?.isDirectory) {
               const dirReader = item.createReader();
               dirReader.readEntries((entries) => {
                  const entriesPromises = [];
                  const subfolder = [];
                  folder.push({ name: item.name, subfolder });
                  entries.forEach((entr) => {
                     entriesPromises.push(
                        traverseFileTreePromise(
                           entr,
                           path || `${item.name}/`,
                           subfolder,
                           filesList
                        )
                     );
                  });
                  resolve(Promise.all(entriesPromises));
               });
            }
         });
      };

      const folder = [];
      const filesList = [];
      return new Promise((resolve) => {
         const entriesPromises = [];
         for (let i = 0; i < dataTransferItems.length; i++) {
            const it = dataTransferItems[i];
            entriesPromises.push(
               traverseFileTreePromise(
                  it.webkitGetAsEntry(),
                  null,
                  folder,
                  filesList
               )
            );
         }

         Promise.all(entriesPromises).then(() => {
            resolve({ folder, filesList });
         });
      });
   };

   const handleDragIn = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const dt = e?.dataTransfer?.types;

      const isFile = dt?.length >= 1 && dt.includes("Files");

      if (!isFile) return;
      setVisible(true);
   };

   const preventDefaultEvent = (e) => {
      e.preventDefault();
      e.stopPropagation();
   };

   const handleDragOut = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);
   };

   const handleDrop = async (e) => {
      setDragging(false);
      const { items } = e.dataTransfer;

      e.preventDefault();
      e.stopPropagation();
      let folder;
      let filesList;
      if (items && items.length > 0) {
         // If the files are in the box, they will be saved
         ({ folder, filesList } = await getFilesDataTransferItems(items));
      }

      if (handleDropEvent) {
         handleDropEvent(
            e,
            { folders: folder, files: filesList },
            dropId,
            isStem,
            isBouncedown
         );
      }

      if (setSelectedDropFolderId) {
         setSelectedDropFolderId(dropId);
      }
   };

   const dragEnter = (e) => {
      e.preventDefault();
      // hover styles
      setDragging(true);
   };

   const timeoutRef = useRef();
   // If user drags outside of the app to the menu the event dragleave is not triggered
   // Therefore we add a timeout of a second, if user does not drags over the app the state will be reset
   const dragOverTimeout = (e) => {
      e.preventDefault();
      if (timeoutRef.current) {
         clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
         setDragging(false);
         setVisible(false);
      }, 1000);
   };

   useEffect(() => {
      const div = dropzoneRef.current;
      window.addEventListener("dragenter", handleDragIn);
      window.addEventListener("dragover", preventDefaultEvent);
      window.addEventListener("dragleave", preventDefaultEvent);
      window.addEventListener("drop", preventDefaultEvent);
      div.addEventListener("dragenter", dragEnter);
      div.addEventListener("dragleave", handleDragOut);
      div.addEventListener("dragover", dragOverTimeout);
      div.addEventListener("drop", handleDrop);
      return () => {
         window.removeEventListener("dragenter", handleDragIn);
         window.removeEventListener("dragover", preventDefaultEvent);
         window.removeEventListener("dragleave", preventDefaultEvent);
         window.removeEventListener("drop", preventDefaultEvent);
         div.removeEventListener("dragenter", dragEnter);
         div.removeEventListener("dragleave", handleDragOut);
         div.removeEventListener("dragover", dragOverTimeout);
         div.removeEventListener("drop", handleDrop);
      };
   }, [handleDropEvent]);

   return (
      <div
         ref={dropzoneRef}
         style={{
            ...styles,
            pointerEvents: visible ? "auto" : "none",
            border: dragging ? `2px dashed ${theme.palette.primary.main}` : "",
         }}
      />
   );
};

FileUploaderDropHandler.propTypes = {
   handleDropEvent: PropTypes.func.isRequired,
   dropId: PropTypes.string,
   isStem: PropTypes.bool,
   isBouncedown: PropTypes.bool,
   setSelectedDropFolderId: PropTypes.func,
};

FileUploaderDropHandler.defaultProps = {
   dropId: null,
   setSelectedDropFolderId: null,
   isStem: false,
   isBouncedown: false,
};

export default FileUploaderDropHandler;
