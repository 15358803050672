import React, { createContext, useContext, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { GET_CURRENT_USER, REGISTER_DEVICE_USER } from "_apollo/queries";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import type { User } from "types";
import { client } from "_apollo/client";
import generateMd5 from "_utils/generateMd5";
import isElectronHook from "_utils/isElectron";
import appStorage from "./appStorage";

type UserContent = [
  user: User,
  setUser: (user: User) => void,
  fetchUserCache: () => void,
  loading: boolean,
  setFolderUri: (uri: string | null) => void,
  folderUri: string | null
];

const ThemeContext = createContext<UserContent>(null);

/**
 * UserContext to useContext
 * @returns {UserContent} React UserContext
 */
const useUser = () => useContext(ThemeContext);

/**
 * UserProvider
 * @param {object} props props.
 * @param {object} props.children JSX
 * @returns {JSX.Element} JSX
 */
const UserProvider = ({ children }) => {
  const [user, setUser] = useState<User>();
  const [folderUri, setFolderUri] = useState<string | null>();
  const [reRender, setReRender] = useState(false);
  const [registerDevice] = useMutation(REGISTER_DEVICE_USER);
  /**
   * force refetch of user from cache.
   * @returns {Function} function that sets re-render.
   */
  const fetchUserCache = () => setReRender((prev) => !prev);
  const currentUser = client.readQuery({
    query: GET_CURRENT_USER,
  });
  const [fetchUser, { loading }] = useLazyQuery(GET_CURRENT_USER, {
    /**
     * getCurrentUser onCompleted
     * @param {object} data Apollo query data response for the getCurrentUser query.
     */
    onCompleted(data) {
      setUser(data?.currentUser);
    },
  });

  const isElectron = isElectronHook();
  const token = appStorage.getValue({ key: "auxJWT" });
  const [deviceIdSetThisSession, setDeviceIdSetThisSession] = useState(false);
  useEffect(() => {
    if (!token) {
      setUser(null);
    } else if (currentUser?.currentUser) {
      // Register each of the users devices so we can detect if a user has a desktop app installed and allow the user to log out of other apps for security.
      let deviceId = appStorage.getValue({ key: "auxDeviceID" });

      // Create a new device ID if there isn't already one in local storage.
      if (!deviceId) {
        const machineIdSync = isElectron
          ? window?.electronAPI?.machineIdSync
          : () => null;
        const machineId = machineIdSync();

        if (machineId !== null) {
          // The user is on electron so has a machineId so use that as the deviceId.
          appStorage.setValue({ key: "auxDeviceID", value: machineId });
          deviceId = machineId;
        } else {
          // The user is on the web so generate a random deviceId.
          deviceId = generateMd5(15);
          appStorage.setValue({ key: "auxDeviceID", value: deviceId });
        }
      }

      // Register the deviceId only if it's not already registered.
      // Only register device once per UserContext session to prevent spamming of the endpoint during a larger sync.
      if (
        !deviceIdSetThisSession &&
        deviceId !== currentUser?.currentUser?.currentDevice?.deviceId
      ) {
        registerDevice({
          variables: {
            deviceId,
            web: 1,
          },
        });
        setDeviceIdSetThisSession(true);
      }
      setUser(currentUser?.currentUser);
    } else {
      fetchUser();
    }
  }, [currentUser, token, reRender]);

  return (
    <ThemeContext.Provider
      value={[user, setUser, fetchUserCache, loading, setFolderUri, folderUri]}
    >
      {children}
    </ThemeContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.element,
};
UserProvider.defaultProps = {
  children: null,
};

export { UserProvider, useUser };
