import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import UnlockImage from "_assets/marketing-unlock.png";
import SearchImage from "_assets/marketing-search.png";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles(() => ({
   root: {
      backgroundColor: "#FAFAFF",
   },
   container: {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      paddingTop: "70px",
      paddingBottom: "100px",
      paddingLeft: "40px",
      rowGap: "70px",
   },
   child: {
      flex: 1,
      position: "relative",
      minWidth: "250px",
      marginBottom: "100px",
      "& div": {
         maxWidth: "345px",
         fontSize: "19px",
         lineHeight: "29px",
      },
   },
}));

/**
 * Guide Overlay Icons
 *
 * Homepage section shows additional guide features with overlay icons.
 * @returns {JSX.Element} Guide extra features panel homepage section
 */
const GuideOverlayIcons = () => {
   const classes = useStyles();
   const mobileView = useMediaQuery("(max-width:900px)");

   return (
      <>
         <div className={classes.root}>
            <Container className={classes.container}>
               <div
                  className={classes.child}
                  style={{
                     paddingLeft: mobileView ? "0.50rem" : "80px",
                     paddingRight: mobileView ? "0.75rem" : "0",
                  }}
               >
                  <img
                     src={SearchImage}
                     style={{
                        flex: 1,
                        position: "absolute",
                        width: mobileView ? "120px" : "130px",
                        height: mobileView ? "120px" : "130px",
                        top: mobileView ? "-126px" : "-130px",
                        left: mobileView ? "-40px" : "10px",
                     }}
                     alt="Search"
                  />
                  <div
                     style={{
                        fontSize: mobileView ? "1.125rem" : "1.1875rem",
                        lineHeight: "145%",
                     }}
                  >
                     Search our directory by location, genre or descriptive tags
                     to identify exactly what you’re looking for.{" "}
                  </div>
               </div>
               <div
                  className={classes.child}
                  style={{
                     paddingLeft: mobileView ? "0.50rem" : "80px",
                     paddingRight: mobileView ? "0.75rem" : "0",
                  }}
               >
                  <img
                     src={UnlockImage}
                     style={{
                        flex: 1,
                        position: "absolute",
                        width: mobileView ? "120px" : "130px",
                        height: mobileView ? "120px" : "130px",
                        top: mobileView ? "-126px" : "-130px",
                        left: mobileView ? "-40px" : "10px",
                     }}
                     alt="Unlock"
                  />
                  <div
                     style={{
                        fontSize: mobileView ? "1.125rem" : "1.1875rem",
                        lineHeight: "145%",
                     }}
                  >
                     Unlock contact details with one of our subscription plans
                     and get in touch to take the next step on your journey.{" "}
                  </div>
               </div>
            </Container>
         </div>
      </>
   );
};

export default GuideOverlayIcons;
