import React from "react";

const LockAlertIcon = () => {
   return (
      <svg
         width="16"
         height="17"
         viewBox="0 0 16 17"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M6.66659 12.0413C5.93325 12.0413 5.33325 11.4038 5.33325 10.6247C5.33325 9.84551 5.93325 9.20801 6.66659 9.20801C7.39992 9.20801 7.99992 9.84551 7.99992 10.6247C7.99992 11.4038 7.39992 12.0413 6.66659 12.0413ZM10.6666 14.1663V7.08301H2.66659V14.1663H10.6666ZM10.6666 5.66634C11.3999 5.66634 11.9999 6.30384 11.9999 7.08301V14.1663C11.9999 14.9455 11.3999 15.583 10.6666 15.583H2.66659C1.93325 15.583 1.33325 14.9455 1.33325 14.1663V7.08301C1.33325 6.30384 1.93325 5.66634 2.66659 5.66634H3.33325V4.24967C3.33325 2.26634 4.79992 0.708008 6.66659 0.708008C8.53325 0.708008 9.99992 2.26634 9.99992 4.24967V5.66634H10.6666ZM6.66659 2.12467C5.53325 2.12467 4.66659 3.04551 4.66659 4.24967V5.66634H8.66659V4.24967C8.66659 3.04551 7.79992 2.12467 6.66659 2.12467ZM14.6666 4.95801H13.3333V9.20801H14.6666V4.95801ZM14.6666 10.6247H13.3333V12.0413H14.6666V10.6247Z"
            fill="#6147FF"
         />
      </svg>
   );
};

export default LockAlertIcon;
