import PropTypes from "prop-types";
import React from "react";
import { BrowserRouter as Router, HashRouter, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import ReactPixel from "react-facebook-pixel";
import { connect } from "react-redux";
import UpgradeSubscriptionModal from "_components/Modal/UpgradeSubscriptionModal";
import { setUpgradeModal } from "_redux/actions";
// import SendDownloadLinkModal from "_components/Modal/SendDownloadLinkModal";

import getConfig from "../_utils/getConfig";
import PageViewWrapper from "../_components/PageViewWrapper";
import isElectronHook from "../_utils/isElectron";
import SwitchRouter from "./SwitchRouter";
import NativeMenusListeners from "../_utils/NativeMenusListeners";
import SendDownloadLinkModal from "../_components/Modal/SendDownloadLinkModal";

const { facebookPixelKey } = getConfig();
const isElectron = isElectronHook();

// Initialise the Facebook Pixel Event tracking
// TODO: disable on test build...
ReactPixel.init(facebookPixelKey);
ReactPixel.pageView(); // For tracking page view

/**
 * Router
 *
 * The switch and standard routes HOC.
 * @param {object} props props
 * @param {Function} props.showUpgradeModal Show upgrade modal set state function.
 * @param {Function} props.dispatchSetUpgradeModal Redux dispatch set upgrade modal action.
 * @returns {JSX.Element} React Router DOM router.
 */
const Routes = ({ showUpgradeModal, dispatchSetUpgradeModal }) => {
   return (
      <div>
         {isElectron ? (
            <HashRouter>
               <NativeMenusListeners />
               <SendDownloadLinkModal />
               <PageViewWrapper>
                  <QueryParamProvider ReactRouterRoute={Route}>
                     <SwitchRouter />
                     <UpgradeSubscriptionModal
                        title="Oops, you have reached your storage limit."
                        description="Upgrade to get more features."
                        open={showUpgradeModal}
                        handleClose={() => dispatchSetUpgradeModal(false)}
                     />
                  </QueryParamProvider>
               </PageViewWrapper>
            </HashRouter>
         ) : (
            <Router basename="/">
               <NativeMenusListeners />
               <SendDownloadLinkModal />
               <PageViewWrapper>
                  <QueryParamProvider ReactRouterRoute={Route}>
                     <SwitchRouter />
                     <UpgradeSubscriptionModal
                        title="Oops, you have reached your storage limit."
                        description="Upgrade to get more features."
                        open={showUpgradeModal}
                        handleClose={() => dispatchSetUpgradeModal(false)}
                     />
                  </QueryParamProvider>
               </PageViewWrapper>
            </Router>
         )}
      </div>
   );
};

Routes.propTypes = {
   showUpgradeModal: PropTypes.bool,
   dispatchSetUpgradeModal: PropTypes.func,
};
Routes.defaultProps = {
   showUpgradeModal: false,
   dispatchSetUpgradeModal: undefined,
};

/**
 * mapStateToProps
 * @deprecated TODO: update to hook based redux method.
 * @param {object} state redux state props
 * @returns {object} component props with redux props added.
 */
const mapStateToProps = (state) => {
   const { showUpgradeModal } = state.AppReducer;
   return { showUpgradeModal };
};

export default connect(mapStateToProps, {
   dispatchSetUpgradeModal: setUpgradeModal,
})(Routes);
