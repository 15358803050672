import appStorage from "_utils/appStorage";
import type { File } from "types";
import getConfig from "./getConfig";

const { restUrl } = getConfig();

/**
 * Check Hash File
 *
 * Checks if there is another file that has already been uploaded.
 * @param {string} hash hash of file.
 * @returns {Promise<File>} returns matching hash file.
 */
const checkHashFile = async (hash: string): Promise<File> => {
  // Check the plist file using and express endpoint via fetch request.
  const checkHashResponse = await fetch(`${restUrl}/checkHash/${hash}`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer: ${appStorage.getValue({ key: "auxJWT" })}`,
      auxSelectedProfileURI: appStorage.getValue({
        key: "auxSelectedProfileURI",
      }),
    },
  });

  const fileObj = await checkHashResponse.json();

  return fileObj;
};

export default checkHashFile;
