import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import { Typography, useTheme } from "@mui/material";
import { useQueryParam, StringParam } from "use-query-params";
import { Link } from "react-router-dom";

import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import appStorage from "_utils/appStorage";
import JoinAuxConnectProfile from "_components/JoinAuxConnectProfile";
import useRedirectToFeedIfAuthorised from "_utils/useRedirectToFeedIfAuthorised";
import useStatsMutation from "_utils/useStatsMutation";
import SignInSignUpFeaturedPost from "_components/SignInSignUpFeaturedPost";

import { client } from "_apollo/client";
import getConfig from "_utils/getConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useUser } from "_utils/UserContext";
import { useDispatch } from "react-redux";
import { GET_CURRENT_USER, LOG_IN_USER } from "../_apollo/queries";
import AuxLogo from "../_assets/AuxLogo";
import { openLoadingAppModal } from "../_redux/actions";

const useStyles = makeStyles((theme) => ({
   root: {
      display: "flex",
      height: "100vh",
      "@media (max-width: 768px)": {
         flexDirection: "column-reverse",
      },
   },
   container: {
      flex: 3,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      padding: "4vw",
   },
   input: {
      marginBottom: "10px",
      width: "100%",
      [`& fieldset`]: {
         borderRadius: 0,
      },
      "-webkit-user-select": "text",
      "-webkit-app-region": "none",
   },
   // Click events are eaten by the -webkit-app-region: drag; so we need to disable it for anything that is interactive.
   noDrag: {
      ...theme.noDrag,
   },
   button: {
      marginRight: 8,
      minWidth: "69px",
   },
   wrapperPost: {
      flex: 5,
   },
   formWrapper: {
      marginTop: "20px",
   },
   labelInput: {
      marginTop: "10px",
      lineHeight: "16px",
      fontSize: theme.typography.small2.fontSize,
   },
   containerButton: {
      margin: "20px 0px",
      display: "flex",
      alignItems: "center",
   },
   link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      lineHeight: "20px",
      fontSize: theme.typography.small1.fontSize,
   },
   linkInfo: {
      color: theme.palette.primary.main,
      lineHeight: "20px",
      fontSize: theme.typography.small1.fontSize,
   },
   wrapperLinks: {
      color: theme.palette.primary.main,
   },
}));

/**
 * Sign In Page
 *
 * Sign In page with featured post.
 * @returns {JSX.Element} sign In Page.
 */
const SignInPage = () => {
   const theme = useTheme();
   const classes = useStyles();

   const dispatch = useDispatch();
   const [hash] = useQueryParam("hash", StringParam);
   const [redirectTo] = useQueryParam("redirectTo", StringParam);
   const { domain } = getConfig();
   const history = useHistory();
   const [, setUser] = useUser();

   const sendStatsMutation = useStatsMutation();
   useRedirectToFeedIfAuthorised();

   const schema = Yup.object().shape({
      password: Yup.string().min(8).required().label("Password"),
      email: Yup.string().email().max(320).required().label("Email"),
   });

   const [getUser, { loading: loadingUser }] = useLazyQuery(GET_CURRENT_USER, {
      /**
       * getCurrentUser onCompleted
       *
       * Redirect the user to the feed or a custom redirect location passed in the params.
       * @param {object} data Apollo response data for currentUser query.
       */
      onCompleted(data) {
         // This is duplicated in signin, signup and useRedirectToFeedIfAuthorised so should be generalised to hook.
         if (data?.currentUser) {
            setUser(data.currentUser);
            appStorage.setValue({
               key: "auxSelectedProfileURI",
               value: data.currentUser.profile.uri,
            });
            appStorage.setValue({
               key: "profileId",
               value: data.currentUser.profile.id,
            });
         }
         if (data?.currentUser?.email?.emailVerifiedAt) {
            if (hash) {
               history.push(`/feed?hash=${hash}`);
            } else if (redirectTo) {
               history.push(`/${redirectTo}`);
            } else {
               // Redirect to feed
               history.push("/feed");
            }
         } else if (data?.currentUser?.email) {
            if (redirectTo) {
               history.push(`/confirm-email?redirectTo=${redirectTo}`);
            } else if (hash) {
               history.push(`/confirm-email?hash=${hash}`);
            } else {
               history.push("/confirm-email");
            }
         }
      },
   });

   const [apiError, setAPIError] = useState(false);

   const [signin, { loading: loadingSignIn, error }] = useMutation(
      LOG_IN_USER,
      {
         /**
          * signin onCompleted
          *
          * Set the jwt, register the device and get the user.
          * @param {object} data Response data from signin mutation.
          * @returns {void} void
          */
         async onCompleted(data) {
            // This line opens the first column if it was collapsed.
            window.localStorage.setItem("column-resize-0", "260");
            // Load user and set Token/cookie
            appStorage.setValue({ key: "auxJWT", value: data.signin.jwt });
            // Loading App Modal is shown while the login is in progress
            dispatch(openLoadingAppModal(true));
            if (data.signin?.uri) {
               appStorage.setValue({
                  key: "auxSelectedProfileURI",
                  value: data.signin.profile.uri,
               });
               appStorage.setValue({
                  key: "profileId",
                  value: data.signin.profile.id,
               });
            }
            await client.resetStore();
            sendStatsMutation({
               statsId: "UserSignIn",
               userId: data.signin.id,
            });
            await getUser();
         },
         /**
          * signin onError
          *
          * Set the api error state to true so we can show error if user is trying to signin while API is down being updated.
          * @param {Error} errorResponse Error response from signin mutation.
          */
         onError(errorResponse) {
            if (errorResponse?.toString()?.includes("Failed to fetch")) {
               setAPIError(true);
            }
         },
      }
   );

   /**
    * Link Redirect
    * @returns {string} Link to redirect to after successful signin.
    */
   const linkRedirect = () => {
      if (hash) {
         return `/signup?hash=${hash}`;
      }
      if (redirectTo) {
         return `/signup?redirectTo=${redirectTo}`;
      }
      return "/signup";
   };
   const loading = loadingSignIn || loadingUser;

   return (
      <div className={classes.root}>
         <Container className={classes.container} maxWidth="md">
            <Link className={classes.noDrag} to="/">
               <AuxLogo
                  style={{
                     height: "36px",
                     width: "116px",
                     color: theme.palette.coreApp.text,
                  }}
               />
            </Link>
            {redirectTo && redirectTo !== "awards" && <JoinAuxConnectProfile />}
            <div className={classes.formWrapper}>
               <Formik
                  initialValues={{
                     email: "",
                     password: "",
                     terms: false,
                  }}
                  validateOnChange={false}
                  validationSchema={schema}
                  onSubmit={async (values) => {
                     setAPIError(false);
                     await signin({
                        variables: {
                           email: values.email,
                           password: values.password,
                        },
                     });
                  }}
               >
                  {({
                     handleSubmit,
                     handleChange,
                     errors,
                     values,
                     setFieldError,
                     touched,
                  }) => {
                     if (error && error.graphQLErrors) {
                        error?.graphQLErrors?.forEach((event) => {
                           setFieldError("email", event?.message);
                           setFieldError("password", event?.message);
                           delete error.graphQLErrors;
                        });
                     }

                     return (
                        <form
                           onSubmit={handleSubmit}
                           className={classes.noDrag}
                        >
                           {apiError && (
                              <div style={{ display: "block" }}>
                                 <Typography
                                    style={{
                                       color: "#d32f2f",
                                       fontSize: "12px",
                                       lineHeight: "16px",
                                    }}
                                 >
                                    The app is being updated. Try again in a few
                                    minutes.
                                 </Typography>
                              </div>
                           )}

                           <Typography className={classes.labelInput}>
                              Email
                           </Typography>
                           <TextField
                              size="small"
                              className={classes.input}
                              variant="outlined"
                              id="email"
                              name="email"
                              placeholder="Email"
                              value={values.email.trim()}
                              onChange={handleChange}
                              error={touched.email && Boolean(errors.email)}
                              helperText={touched.email && errors.email}
                           />
                           <Typography className={classes.labelInput}>
                              Password
                           </Typography>

                           <TextField
                              size="small"
                              className={classes.input}
                              variant="outlined"
                              id="password"
                              name="password"
                              placeholder="Password"
                              type="password"
                              value={values.password}
                              onChange={handleChange}
                              error={
                                 touched.password && Boolean(errors.password)
                              }
                              helperText={touched.password && errors.password}
                           />
                           <div className={classes.containerButton}>
                              <Button
                                 variant="contained"
                                 className={classes.button}
                                 type="submit"
                                 color="primary"
                                 disabled={loading}
                              >
                                 {loading ? "Loading..." : "Sign In"}
                              </Button>
                              <Link
                                 className={classes.link}
                                 to={linkRedirect()}
                              >
                                 {" "}
                                 New to Aux? Sign Up
                              </Link>
                              <Link
                                 className={classes.link}
                                 to="/password/reset"
                                 style={{ marginLeft: "auto" }}
                              >
                                 Forgot password?
                              </Link>
                           </div>

                           <div className={classes.wrapperLinks}>
                              <a
                                 className={classes.linkInfo}
                                 href={`${domain}/info/terms-of-service`}
                                 target="_blank"
                                 rel="noreferrer"
                              >
                                 Terms of Service
                              </a>{" "}
                              |{" "}
                              <a
                                 className={classes.linkInfo}
                                 href={`${domain}/info/privacy-policy`}
                                 target="_blank"
                                 rel="noreferrer"
                              >
                                 Privacy Policy
                              </a>
                           </div>
                        </form>
                     );
                  }}
               </Formik>
            </div>
         </Container>
         <div className={classes.wrapperPost}>
            <SignInSignUpFeaturedPost type="signin" />
         </div>
      </div>
   );
};

export default SignInPage;
