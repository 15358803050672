import PropTypes from "prop-types";
import React from "react";
import { Skeleton, TableCell, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import formatDate from "_utils/formatDate";

const useStyles = makeStyles((theme) => ({
   tableCell: {
      fontSize: theme.typography.small1.fontSize,
   },
}));

const TableCellLastModified = ({ file, loading }) => {
   const classes = useStyles();

   return (
      <TableCell sx={{ p: "0 30px" }}>
         <Typography noWrap className={classes.tableCell}>
            {loading && <Skeleton />}
            {!loading &&
               formatDate(new Date(Number(file?.updatedAt || file?.createdAt)))}
         </Typography>
      </TableCell>
   );
};

TableCellLastModified.propTypes = {
   file: PropTypes.shape({
      updatedAt: PropTypes.string,
      createdAt: PropTypes.string,
   }).isRequired,
   loading: PropTypes.bool,
};

TableCellLastModified.defaultProps = {
   loading: false,
};

export default TableCellLastModified;
