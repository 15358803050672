import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TAG_USER_VERIFY } from "../_apollo/queries";

const useStyles = makeStyles((theme) => ({
   description: {
      "& a": {
         color: theme.palette.primary.main,
         textDecoration: "none",
      },
   },
}));

/**
 * Tag Extractor.
 *
 * Shows the users profile with #tags linked to tagged endpoint and @users linked to profile.
 * @param {object} props props
 * @param {object} props.profile User profile object.
 * @returns {JSX.Element} Tag Extractor component.
 */
const TagExtractor = ({ profile }) => {
   const classes = useStyles();
   const [tagUserVerify, { data, loading }] = useLazyQuery(TAG_USER_VERIFY, {
      variables: (() => {
         const tagList = [];
         const userList = [];
         const bioStringArr = profile?.description?.split(" ");
         bioStringArr?.forEach((el) => {
            if (el.startsWith("#")) {
               tagList.push(el.slice(1));
            } else if (el.startsWith("@")) {
               userList.push(el.slice(1));
            }
         });
         return { tagList, userList };
      })(),
   });

   useEffect(() => {
      const tagList = [];
      const userList = [];
      const bioStringArr = profile?.description?.split(" ");
      bioStringArr?.forEach((el) => {
         if (el.startsWith("#")) {
            tagList.push(el.slice(1));
         } else if (el.startsWith("@")) {
            userList.push(el.slice(1));
         }
      });
      tagUserVerify({
         variables: {
            tagList,
            userList,
         },
      });
   }, [profile]);

   if (loading) {
      return <CircularProgress />;
   }

   if (!data || data?.tagUserVerify?.length === 0) {
      return <p>{profile?.description}</p>;
   }

   return (
      <p className={classes.description}>
         {(() => {
            const bioStringArr = profile?.description?.split(" ");
            return bioStringArr?.map((el) => {
               if (
                  el.startsWith("#") &&
                  data?.tagUserVerify?.includes(el.slice(1))
               ) {
                  return (
                     <span key={el}>
                        <Link
                           to={`/discover?search=${encodeURIComponent(
                              el.slice(1)
                           )}`}
                        >
                           {el}
                        </Link>
                        &nbsp;
                     </span>
                  );
               }
               if (
                  el.startsWith("@") &&
                  data?.tagUserVerify?.includes(el.slice(1))
               ) {
                  return (
                     <span key={el}>
                        <Link to={`/${encodeURIComponent(el.slice(1))}`}>
                           {el}
                        </Link>
                        &nbsp;
                     </span>
                  );
               }
               if (el.startsWith("http")) {
                  return (
                     <span key={el}>
                        <a target="_blank" rel="nofollow noreferrer" href={el}>
                           {el}
                        </a>
                        &nbsp;
                     </span>
                  );
               }
               return `${el} `;
            });
         })()}
      </p>
   );
};

TagExtractor.propTypes = {
   profile: PropTypes.instanceOf(Object).isRequired,
};

export default TagExtractor;
