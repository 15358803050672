import { useEffect } from "react";
import { getFileDataFromPath } from "_utils/fileSyncHelpers";
import {
   getMyAuxFilesFolder,
   showFolderInFinderOrExplorer,
} from "_utils/electronAPI";
import useFileUploader from "_components/FileUploader/useFileUploader";
import { useLazyQuery } from "@apollo/client";
import { GET_CURRENT_USER } from "_apollo/queries";
import isInsideDAWPackageFile from "./isInsideDAWPackageFile";
/**
 * Native Menus Listeners
 *
 * Provides listeners triggered by the native system menus on Mac and Windows.
 * @returns {null} null.
 */
const NativeMenusListeners = () => {
   const uploadFiles = useFileUploader();
   const [getUser] = useLazyQuery(GET_CURRENT_USER);

   // Listeners from native menu - Inside of file watcher because you need to be logged in to use them
   useEffect(() => {
      window?.electronAPI?.createProject(() => {
         // Select + project button from the first column and click it
         const btn = document.getElementById("first-col-icon-projects-btn");
         if (btn) {
            btn.click();
         }
      });
      window?.electronAPI?.goToSettings(async () => {
         window.location.hash = "#/settings";
      });
      window?.electronAPI?.menuOpenFinderOrExplorer(async () => {
         const { data } = await getUser();
         showFolderInFinderOrExplorer(
            getMyAuxFilesFolder(data?.currentUser?.profile?.id)
         );
      });

      window?.electronAPI?.addFilesFromMenu(() => {
         // Open dialog from electron API
         window?.electronAPI
            ?.openDialog("showOpenDialog", {
               properties: ["openFile", "multiSelections"],
            })
            .then(async (el) => {
               const { data } = await getUser();
               el?.filePaths.forEach((path) => {
                  if (!isInsideDAWPackageFile(path)) {
                     const { fullData } = getFileDataFromPath(
                        path,
                        false,
                        data?.currentUser?.profile?.id
                     );
                     uploadFiles([fullData]);
                  }
               });
            });
      });
   }, []);

   return null;
};

export default NativeMenusListeners;
