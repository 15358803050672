/**
 *Generate MD5.
 * @param {number} length characters of the generated md5
 * @returns {string} md5
 */
export default function generateMd5(length) {
   let result = "";
   const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
   const charactersLength = characters.length;
   for (let i = 0; i < length; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}
