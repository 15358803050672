import React, { useState, useEffect, useCallback, useRef } from "react";
import makeStyles from "@mui/styles/makeStyles";
import LayoutWrapper from "./LayoutWrapper";
import AppMenu from "../_components/AppMenu/AppMenu";

const COL_WIDTH_MAX_0 = 400;
const COL_WIDTH_MIN_1 = 300;

const COL_ID_0 = "column-resize-0";
const COL_ID_1 = "column-resize-1";

const useStyles = makeStyles((theme) => ({
   container: {
      height: "calc(100% - 52px)",
      maxHeight: "calc(100% - 52px)",
      maxWidth: "100%",
      width: "100%",
      display: "flex",
      overflow: "hidden",
   },
   column0: {
      height: "100%",
      display: "flex",
      minWidth: "180px",
      overflow: "hidden",
   },
   column1: {
      height: "100%",
      display: "flex",
      flexGrow: 1,
      overflow: "hidden",
      minWidth: COL_WIDTH_MIN_1,
   },
   bar: {
      backgroundColor: theme.palette.coreApp.borders,
      height: "100%",
      width: 1,
      zIndex: 2,
      cursor: "col-resize",
      margin: 0,
   },
   barGrabbed: {
      backgroundColor: theme.palette.purple.main,
      width: 3,
      marginRight: -1,
      marginLeft: -1,
   },
}));

/**
 * Three Col Layout Main End
 *
 * Three column layout wrapper with the main app column in the end, used for the majority of the app pages except pages like the settings where there is a sub menu in the middle.
 *
 * The third column is hide-able - for example for file previews in the files listings.
 * @param {object} props props
 * @param {JSX.Element} props.children Main column child component.
 * @returns {JSX.Element} Three Col Layout Main End Component
 */
const AppLayout = ({ children }) => {
   const classes = useStyles();

   const ref0 = useRef();
   const ref1 = useRef();

   const tableElement = useRef();

   const [resizingGrab, setResizingGrab] = useState(false);

   // TODO: recover width using the value in local storage on refresh.

   const mouseMove = useCallback(
      (e) => {
         // The resize bars are inside column 0 and 1 fixed to the end
         const width0 = e.clientX;

         if (width0 < COL_WIDTH_MAX_0) {
            ref0.current.style.width = `${width0}px`;
            window.localStorage.setItem(COL_ID_0, ref0.current.offsetWidth);
         }
      },
      [resizingGrab]
   );

   const removeListeners = useCallback(() => {
      window.removeEventListener("mousemove", mouseMove);
      window.removeEventListener("mouseup", removeListeners);
   }, [mouseMove]);

   const mouseUp = useCallback(() => {
      setResizingGrab(false);
      removeListeners();
   }, [setResizingGrab, removeListeners]);

   useEffect(() => {
      if (resizingGrab !== false) {
         window.addEventListener("mousemove", mouseMove);
         window.addEventListener("mouseup", mouseUp);
      }
      return () => {
         removeListeners();
      };
   }, [resizingGrab, mouseMove, mouseUp, removeListeners]);

   return (
      <LayoutWrapper>
         <div className={classes.container} ref={tableElement}>
            <React.Fragment key={COL_ID_0}>
               <div className={classes.column0} ref={ref0} id={COL_ID_0}>
                  <AppMenu />
               </div>
               <div
                  aria-hidden="true"
                  className={`${classes.bar} ${
                     resizingGrab && classes.barGrabbed
                  }`}
                  onMouseDown={() => setResizingGrab(true)}
               />
            </React.Fragment>
            <React.Fragment key={COL_ID_1}>
               <div className={classes.column1} ref={ref1} id={COL_ID_1}>
                  {children}
               </div>
            </React.Fragment>
         </div>
      </LayoutWrapper>
   );
};

export default AppLayout;
