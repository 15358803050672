import {
   Delete,
   OpenWith,
   FileUpload,
   FileCopy,
   StarBorder,
} from "@mui/icons-material";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import useDownloadZip from "_utils/useDownloadZip";
import downloadFile from "_utils/downloadFiles";

const FileActionButtons = ({
   selectedFiles,
   selectedFolders,
   setShowMoveModal,
   setShowCopyModal,
   trashFilesFolders,
   starFilesFolders,
   fileToDownload,
}) => {
   const downloadZip = useDownloadZip();

   const [downloadZipLoadingState, setDownloadZipLoadingState] =
      useState(false);
   const DownloadButton = () => {
      if (selectedFiles.length === 1 && selectedFolders.length === 0) {
         return (
            <Tooltip title="Download" placement="bottom">
               <IconButton
                  variant="outlined"
                  color="primary"
                  onClick={() => downloadFile(fileToDownload)}
               >
                  <FileUpload
                     style={{ fill: "black", transform: "rotate(180deg)" }}
                  />
               </IconButton>
            </Tooltip>
         );
      }
      return (
         <Tooltip title="Download" placement="bottom">
            <IconButton
               variant="outlined"
               color="primary"
               disabled={downloadZipLoadingState}
               onClick={async () => {
                  setDownloadZipLoadingState(true);
                  const callback = () => setDownloadZipLoadingState(false);
                  downloadZip(selectedFiles, selectedFolders, callback);
               }}
            >
               {downloadZipLoadingState ? (
                  <CircularProgress size={20} />
               ) : (
                  <FileUpload
                     style={{ fill: "black", transform: "rotate(180deg)" }}
                  />
               )}
            </IconButton>
         </Tooltip>
      );
   };

   return (
      <>
         <Tooltip title="Star/Unstar" placement="bottom">
            <IconButton
               onClick={() => {
                  starFilesFolders();
               }}
               size="large"
            >
               <StarBorder style={{ fill: "#000" }} />
            </IconButton>
         </Tooltip>
         {setShowMoveModal &&
            // Do not allow root project folders from being moved
            !selectedFolders.some(
               (f) => !!f?.projectId && !f?.parentFolderId
            ) && (
               <Tooltip title="Move" placement="bottom">
                  <IconButton
                     onClick={() => setShowMoveModal(true)}
                     color="primary"
                     variant="text"
                  >
                     <OpenWith style={{ fill: "#000" }} />
                  </IconButton>
               </Tooltip>
            )}
         {setShowCopyModal && (
            <Tooltip title="Copy" placement="bottom">
               <IconButton
                  onClick={() => setShowCopyModal(true)}
                  color="primary"
                  variant="text"
               >
                  <FileCopy style={{ fill: "#000" }} />
               </IconButton>
            </Tooltip>
         )}
         <DownloadButton />
         <Tooltip title="Trash" placement="bottom">
            <div>
               <IconButton onClick={() => trashFilesFolders()} size="large">
                  <Delete style={{ fill: "#E63E33" }} />
               </IconButton>
            </div>
         </Tooltip>
      </>
   );
};
FileActionButtons.propTypes = {
   selectedFiles: PropTypes.arrayOf(
      PropTypes.shape({
         id: PropTypes.string,
      })
   ).isRequired,
   selectedFolders: PropTypes.arrayOf(
      PropTypes.shape({
         id: PropTypes.string,
      })
   ),
   setShowMoveModal: PropTypes.func,
   setShowCopyModal: PropTypes.func,
   trashFilesFolders: PropTypes.func.isRequired,
   starFilesFolders: PropTypes.func.isRequired,
   fileToDownload: PropTypes.instanceOf(Object),
};
FileActionButtons.defaultProps = {
   setShowMoveModal: null,
   setShowCopyModal: null,
   fileToDownload: null,
   selectedFolders: [],
};
export default FileActionButtons;
