import { SubscriptionPlans } from "../_constants/GlobalVariables";

/**
 * Check User Subscription Plan
 *
 * Checks if user planId is found in selected plans in plansEnum array.
 * @param {Array} planEnums Plans to compare with.
 * @param {string} planId plan identifier.
 * @returns {boolean} true if users plan is found.
 */
const checkUsersSubscriptionPlan = (planEnums, planId) => {
   return SubscriptionPlans.ArrayPlans.filter((ap) =>
      planEnums.includes(ap.modalEnum)
   ).some((p) => p.plan === planId || p?.legacyPlans?.includes(planId));
};

export default checkUsersSubscriptionPlan;
