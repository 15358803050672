// import Values from "js-cookie";
// import getConfig from "./getConfig";
// import isElectronHook from "./isElectron";

// const { cookiesDomain } = getConfig();

/**
 * App Storage functions that work across Web and Electron using localStorage
 */

/**
 * Set Value
 *
 * Cross platform function that sets a cookie on web or Electron depending on the environment.
 * @param {object} params The function params
 * @param {string} params.key The key of the cookie to set.
 * @param {string} params.value The vale of the cookie to set.
 */
const setValue = ({ key, value }) => {
   localStorage.setItem(key, value);
};

/**
 * Get Value
 *
 * Cross platform function that gets a cookie on web or Electron depending on the environment.
 * @param {object} params The function params
 * @param {string} params.key The key of the cookie to get.
 * @returns {string} cookie
 */
const getValue = ({ key }) => {
   const cookie = localStorage.getItem(key);
   return cookie;
};
/**
 * Remove Value
 *
 * Cross platform function that removes a cookie on web or Electron depending on the environment.
 * @param {object} params The function params
 * @param {string} params.key The key of the cookie to get.
 */
const removeValue = ({ key }) => {
   localStorage.removeItem(key);
};

export default { setValue, getValue, removeValue };
