import React from "react";
import useStatsMutation from "_utils/useStatsMutation";
import * as Yup from "yup";
import { Formik } from "formik";
import {
   Button,
   Checkbox,
   FormControlLabel,
   FormHelperText,
   TextField,
} from "@mui/material";
import { useUser } from "_utils/UserContext";
import generateMd5 from "_utils/generateMd5";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_CURRENT_USER, SIGN_UP_USER } from "_apollo/queries";
import appStorage from "_utils/appStorage";
import { useHistory } from "react-router-dom";
import { setContext } from "@apollo/client/link/context";
import gtag from "_utils/gtag";
import ReservedUsernames from "ReservedUsernames";
import TermsAndConditionsLabel from "_components/AppendedLabels/TermsAndConditionsLabel";
import { setShowDownloadLinkModal } from "_redux/actions";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

/**
 * DownloadLinkSignUpForm
 * A form for signing up to get sent a desktop download link.
 * @param {object} props - props
 * @param {Function} props.setSubmit - setSubmit set state to true when form is submitted. Used to show success message.
 * @returns {JSX.Element} - DownloadLinkSignUpForm component
 */
const DownloadLinkSignUpForm = ({ setSubmit }) => {
   const sendStatsMutation = useStatsMutation();

   const [, setUser] = useUser();

   const history = useHistory();

   const dispatch = useDispatch();

   const [getUser] = useLazyQuery(GET_CURRENT_USER, {
      fetchPolicy: "network-only",
      /**
       * getUser onCompleted
       * adds data to local storage and sends stats events to backend.
       * @param {object} data - data from GET_CURRENT_USER query
       * @param {object} data.currentUser - currentUser object from GET_CURRENT_USER query
       */
      onCompleted({ currentUser }) {
         setUser(currentUser);
         if (currentUser.profile?.uri) {
            appStorage.setValue({
               key: "auxSelectedProfileURI",
               value: currentUser.profile.uri,
            });
            appStorage.setValue({
               key: "profileId",
               value: currentUser.profile.id,
            });
         }

         sendStatsMutation({
            statsId: "UserRequestDesktopDownloadLink",
            metadata: JSON.stringify({ email: currentUser.email }),
         });
         dispatch(setShowDownloadLinkModal(false));
         history.push(`/feed`);
      },
   });

   const [signup, { error }] = useMutation(SIGN_UP_USER, {
      /**
       * signup onCompleted
       * adds data to local storage and calls getUser to refetch user data.
       * @param {object} data - data from SIGN_UP_USER mutation
       */
      onCompleted(data) {
         // window.localStorage.setItem("column-resize-0", "200");
         // Load user and set Token/cookie
         appStorage.setValue({ key: "auxJWT", value: data.signup.jwt });
         setContext(() => ({
            headers: { authorization: `Bearer: ${data.signup.jwt}` },
         }));

         if (!appStorage.getValue({ key: "auxDeviceID" })) {
            appStorage.setValue({
               key: "auxDeviceID",
               value: generateMd5(15),
            });
         }
         // eslint-disable-next-line no-param-reassign
         delete data.signup.jwt;

         sendStatsMutation({
            statsId: "UserSignUp",
            userId: data.signup.id,
         });
         // Track the Sign Up conversion event
         // Google Analytics
         gtag("event", "sign_up", {
            event_label: "Sign Up",
            event_category: "engagement",
         });
         // Google Adwords
         gtag("event", "conversion", {
            send_to: "AW-10804925588/FOhuCNDQqYYDEJSpmKAo",
         });
         // Twitter
         window.twq("track", "SignUp");
         getUser();
      },
   });

   const schema = Yup.object().shape({
      name: Yup.string()
         .required()
         .trim()
         .min(3)
         .max(128)
         .label("Name")
         .notOneOf(ReservedUsernames, "Name is reserved"),
      password: Yup.string().min(8).required().label("Password"),
      email: Yup.string().email().max(320).required().label("Email"),
      terms: Yup.bool().oneOf([true], "You must accept the terms of service "),
   });

   return (
      <Formik
         initialValues={{
            name: "",
            email: "",
            password: "",
            terms: false,
            optInMarketing: true,
         }}
         validateOnChange={false}
         validationSchema={schema}
         onSubmit={async (values) => {
            const { name, email, password, optInMarketing } = values;
            const profileLink = name
               .toLowerCase()
               .trim()
               .replace(/\s\s+/g, " ")
               .split(" ")
               .join("-");
            await signup({
               variables: {
                  name: name.trim(),
                  uri: profileLink,
                  email,
                  password,
                  optInMarketing,
                  isPhoneMobile: false,
               },
            });
            setSubmit(true);
         }}
      >
         {({
            handleSubmit,
            handleChange,
            errors,
            values,
            setFieldError,
            touched,
         }) => {
            // Map GraphQL errors to Formik
            if (error && error.graphQLErrors) {
               error?.graphQLErrors?.forEach((event) => {
                  if (event?.message.includes("Name")) {
                     setFieldError("name", event?.message);
                  } else if (event?.message.includes("Email")) {
                     setFieldError("email", event?.message);
                  }
                  // Delete the errors to avoid re-render loop
                  delete error.graphQLErrors;
               });
            }
            return (
               <form noValidate onSubmit={handleSubmit}>
                  <TextField
                     size="small"
                     variant="outlined"
                     id="name"
                     name="name"
                     placeholder="Name"
                     value={values.name}
                     onChange={handleChange}
                     error={touched.name && Boolean(errors.name)}
                     helperText={touched.name && errors.name}
                     sx={{ mb: "22px", mt: "22px" }}
                  />
                  <TextField
                     size="small"
                     variant="outlined"
                     id="email"
                     name="email"
                     placeholder="Email"
                     value={values.email}
                     onChange={handleChange}
                     error={touched.email && Boolean(errors.email)}
                     helperText={touched.email && errors.email}
                     sx={{ mb: "22px" }}
                  />

                  <TextField
                     size="small"
                     variant="outlined"
                     id="password"
                     name="password"
                     placeholder="Password"
                     type="password"
                     value={values.password}
                     onChange={handleChange}
                     error={touched.password && Boolean(errors.password)}
                     helperText={touched.password && errors.password}
                     sx={{ mb: "22px" }}
                  />
                  <FormControlLabel
                     control={
                        <Checkbox
                           name="terms"
                           color="primary"
                           onChange={handleChange}
                           required
                           sx={{
                              color: "black",
                              "&.Mui-checked": {
                                 color: "black",
                              },
                           }}
                        />
                     }
                     id="terms"
                     name="terms"
                     label={
                        <TermsAndConditionsLabel textColorOverride="white" />
                     }
                  />
                  {errors.terms && (
                     <FormHelperText error>{errors.terms}</FormHelperText>
                  )}
                  <div>
                     <Button
                        sx={{ width: "100%", marginTop: "12px" }}
                        variant="contained"
                        type="submit"
                        color="primary"
                     >
                        Sign Up
                     </Button>
                  </div>
               </form>
            );
         }}
      </Formik>
   );
};

DownloadLinkSignUpForm.propTypes = {
   setSubmit: PropTypes.func.isRequired,
};

export default DownloadLinkSignUpForm;
