import fileDownload from "js-file-download";
import appStorage from "_utils/appStorage";
import getConfig from "./getConfig";

const { restUrl } = getConfig();

/**
 * Download Single File
 * @param {object} file File Instance.
 */
const downloadFile = async (file) => {
   try {
      const zipFile = await fetch(`${restUrl}/download/single/file`, {
         credentials: "include",
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            authorization: `Bearer: ${appStorage.getValue({ key: "auxJWT" })}`,
            auxSelectedProfileURI: appStorage.getValue({
               key: "auxSelectedProfileURI",
            }),
         },
         body: JSON.stringify({
            key: file.name,
            name: file.originalName,
            encodeDownload: file.encodeDownload,
            mimeType: file.mimeType,
         }),
      });
      const fileAPIResponse = await zipFile.json();
      let fileName = file.originalName;
      if (file.encodeDownload === 1 && file.mimeType.includes("audio")) {
         fileName = `${fileName.split(".")[0]}-download.mp3`;
      }
      // fileDownload(fileAPIResponse.url, fileName);
      fetch(fileAPIResponse.url)
         .then((response) => response.blob())
         .then((blob) => {
            fileDownload(blob, fileName);
         });
   } catch (error) {
      throw new Error(error);
   }
};

export default downloadFile;
