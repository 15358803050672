import { setShowDownloadLinkModal } from "_redux/actions";
import store from "_redux/store";
import appStorage from "./appStorage";
import getConfig from "./getConfig";

const { restUrl } = getConfig();
/**
 * downloadApp
 * If user is on desktop, download the app. If user is on mobile, show the download link modal.
 */
const downloadApp = async () => {
   const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
   ];
   // window width less than 660px
   const mobileModalOpened = window.innerWidth < 659;
   const isMobile = toMatch.some((toMatchItem) =>
      navigator.userAgent.match(toMatchItem)
   );
   if (isMobile || mobileModalOpened) {
      store.dispatch(setShowDownloadLinkModal(true));
      return;
   }
   const res = await fetch(`${restUrl}/download/app`, {
      method: "POST",
      headers: {
         "Content-Type": "application/json",
         authorization: `Bearer: ${appStorage.getValue({ key: "auxJWT" })}`,
         auxSelectedProfileURI: appStorage.getValue({
            key: "auxSelectedProfileURI",
         }),
      },
   });
   const json = await res.json();
   window.open(json?.url);
};

export default downloadApp;
