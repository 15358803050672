import React, { useRef, useState, useEffect, useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { PauseCircleFilled, PlayCircleFilled } from "@mui/icons-material";
import { Box, Skeleton, Typography, Grid, IconButton } from "@mui/material";
import WaveSurfer from "wavesurfer.js";
import useStatsMutation from "_utils/useStatsMutation";
import { useTheme } from "@mui/styles";
import { useUser } from "_utils/UserContext";
import isFileReady from "_utils/isFileReady";
import * as Sentry from "@sentry/react";
import { useQueryParam, BooleanParam } from "use-query-params";

const useStyles = makeStyles((theme: any) => ({
   container: {
      position: "relative",
   },
   iconPlayAudio: {
      height: "40px",
      width: "40px",
      fill: theme.palette.coreApp.text,
   },
   hideOverflow: {
      overflowX: "scroll",
      width: "calc(100% - 56px)",
      "&::-webkit-scrollbar": {
         display: "none",
      },
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
   },
   waveformContainer: {
      width: "calc(100% - 56px)",
      "& wave": {
         borderRight: "none",
         "&::-webkit-scrollbar": {
            display: "none",
         },
         "-ms-overflow-style": "none",
         "scrollbar-width": "none",
      },
   },
   grid: {
      backgroundImage:
         "linear-gradient(to right, #E2E2E2 1px, transparent 1px)",
      backgroundSize: "8px 7px",
      minHeight: 57,
   },
}));

/**
 * Media Controller Project.
 *
 * Media preview on project files page.
 * @param {object} props Props.
 * @param {object} props.filePreview file instance which is on the preview.
 * @returns {JSX.Element} Media Controller STEMs player.
 */
const MediaPlayer = ({ filePreview }) => {
   const [user] = useUser();

   const [play] = useQueryParam("play", BooleanParam);

   const theme = useTheme();
   const wavesurferRef = useRef();
   const classes = useStyles();
   const containerRef = useRef();
   const previewRef = useRef();
   const previewBarRef = useRef();
   const rulerContainer = useRef();
   const [rulerScrollValue, setRulerScrollValue] = useState(0);
   const messagesContainer = useRef();
   const fullWidth = useRef(true);
   const seekTimer = useRef();
   const triggerNewComment = useRef(false);
   const [jump, setJump] = useState(null);
   const sendStatsMutation = useStatsMutation();
   const draggingPreview = useRef(false);
   const [isPaused, setIsPaused] = useState(true);
   const [currentAudioTime, setCurrentAudioTime] = useState(0);
   const [previewSrc, setPreviewSrc] = useState(null);
   const [waveformLoaded, setWaveformLoaded] = useState(false);

   // const selectedBouncedown = useMemo(
   //    () => filePreview?.id === selectedElement?.id && filePreview,
   //    [filePreview, selectedElement]
   // );

   const secondsShowing = useMemo(() => {
      const containerWidth = containerRef?.current?.offsetWidth;
      // If pixel per second is equal and the file duration on seconds is bigger than the pixels of the container it will overflow, therefore we return the only pixels that are shown.
      if (containerWidth < filePreview?.audioLength) return containerWidth;

      return filePreview?.audioLength;
   }, [filePreview?.audioLength]);

   const setScrubberLeftOffsetPercentage = (value) => {
      if (previewBarRef.current) {
         previewBarRef.current.style.left = `${
            Number.isNaN(value) ? 0 : value
         }%`;
      }
   };

   useEffect(() => {
      fullWidth.current = secondsShowing === filePreview?.audioLength;
   }, [secondsShowing, filePreview?.audioLength]);

   const handleScrubEnd = (value) => {
      const floatToSec = value * filePreview?.audioLength;
      const skipped = Math.floor(floatToSec);
      if (skipped && !Number.isNaN(skipped)) {
         sendStatsMutation({
            statsId: "FilePreviewSkipTo",
            fileId: filePreview?.id,
            userId: user?.id,
            secondsSkipped: skipped,
         });
      }
   };

   useEffect(() => {
      if (jump) {
         handleScrubEnd(jump);
      }
   }, [jump]);

   const initWaveform = () => {
      try {
         wavesurferRef.current = WaveSurfer?.create({
            container: "#waveform-container",
            backend: "MediaElement",
            progressColor: "#6147FF",
            waveColor: theme.palette.secondary.shade15,
            scrollParent: true,
            minPxPerSec: 1,
            height: 44,
            responsive: true,
            fillParent: true,
            removeMediaElementOnDestroy: false,
            duration: filePreview.audioLength,
            hideScrollbar: true,
         });

         wavesurferRef.current.load(filePreview?.previewSignedUrl);

         // Access the Shadow DOM through ref
         const wavesurferContainer = document.querySelector(
            "#waveform-container > div"
         );

         const { shadowRoot } = wavesurferContainer;
         if (shadowRoot) {
            const waveformContainer = shadowRoot.querySelector(".scroll");
            // Attach scroll event listener to the waveform container within Shadow DOM
            waveformContainer.addEventListener(
               "scroll",
               ({ target: { scrollLeft: value } }) => {
                  if (messagesContainer?.current) {
                     messagesContainer.current.scrollLeft = value;
                  }
                  if (rulerContainer?.current) {
                     setRulerScrollValue(value);
                     rulerContainer.current.scrollLeft = value;
                  }
               }
            );
         }

         wavesurferRef.current.on("ready", async () => {
            setWaveformLoaded(true);
            if (!previewSrc) {
               const previewImage = await wavesurferRef.current.exportImage();
               // Set waveform if image is available.
               if (previewImage) {
                  setPreviewSrc(previewImage);
               }
               if (play) {
                  wavesurferRef.current.play();
               }
            }
         });
         wavesurferRef.current.on("interaction", (value) => {
            if (!draggingPreview.current) {
               triggerNewComment.current = true;
            }
            // if (setEditingMessage) {
            //    setEditingMessage(undefined);
            // }
            clearTimeout(seekTimer.current);
            seekTimer.current = setTimeout(() => {
               setJump(value);
               seekTimer.current = null;
            }, 1000);
         });

         wavesurferRef.current.on("play", () => setIsPaused(false));
         wavesurferRef.current.on("pause", () => setIsPaused(true));

         wavesurferRef.current.on("timeupdate", (value) => {
            setCurrentAudioTime(value);
            setScrubberLeftOffsetPercentage(
               100 * (currentAudioTime / filePreview?.audioLength)
            );
         });
      } catch (error) {
         Sentry.captureException(error);
      }
   };

   useEffect(() => {
      if (isFileReady(filePreview)) {
         initWaveform();
      }
   }, [isFileReady(filePreview)]);

   useEffect(() => {
      wavesurferRef.current?.on("seeking", () => {
         if (triggerNewComment.current && filePreview?.projectId) {
            // if (setNewComment) {
            //    setNewComment((prev) => ({
            //       createdAt: new Date().getTime(),
            //       id: filePreview?.isBouncedown
            //          ? "bouncedown-comment"
            //          : "normal-comment",
            //       email: user?.profile?.email,
            //       fileId: filePreview.id,
            //       message: prev?.message || "",
            //       photoUrlOrGravatar: user?.profile?.photoUrlOrGravatar,
            //       profile: user?.profile,
            //       timestamp: value * 1000,
            //       newComment: true,
            //       file: filePreview,
            //    }));
            // }

            triggerNewComment.current = false;
         }
      });
      return () => {
         wavesurferRef.current?.un("seeking");
      };
   }, [filePreview]);

   useEffect(() => {
      const mouseDown = (e) => {
         e.preventDefault();
         if (fullWidth?.current) return;
         previewBarRef.current.style.cursor = "grabbing";
         draggingPreview.current = true;
         if (isPaused) {
            setIsPaused(true);
         }
      };
      const mouseUp = () => {
         previewBarRef.current.style.cursor = "grab";
         draggingPreview.current = false;
      };
      const mouseMoving = (e) => {
         e.preventDefault();
         if (!draggingPreview.current) return;
         const rect = previewRef.current.getBoundingClientRect();
         const x =
            e.clientX - (rect.left + previewBarRef?.current?.offsetWidth / 2);

         const positionPercentage =
            ((x + previewBarRef?.current?.offsetWidth / 2) / rect.width) * 100;
         const positionOffset = (x / rect.width) * 100;
         wavesurferRef.current.seekTo(
            Math.max(Math.min(positionPercentage / 100, 1), 0)
         );
         setScrubberLeftOffsetPercentage(positionOffset);
      };

      // drag events previewRef.
      previewRef.current?.addEventListener("mousedown", mouseDown);
      previewRef.current?.addEventListener("mouseup", mouseUp);
      previewRef.current?.addEventListener("mousemove", mouseMoving);

      return () => {
         previewRef.current?.removeEventListener("mousedown", mouseDown);
         previewRef.current?.removeEventListener("mouseup", mouseUp);
         previewRef.current?.removeEventListener("mousemove", mouseMoving);
         // destroyWaveform();
      };
   }, [previewSrc]);

   const playbackReady = useMemo(
      () => !!filePreview?.previewSignedUrl && !filePreview?.processingPreview,
      [filePreview, waveformLoaded]
   );

   /**
    * secondToTime
    * Converts secound to formatted string HH:MM:SS
    * @param {number} second whole secound value.
    * @returns {string} HH:MM:SS string
    */
   function secondToTime(second) {
      const add0 = (num) => (num < 10 ? `0${num}` : String(num));
      const hour = Math.floor(second / 3600);
      const min = Math.floor((second - hour * 3600) / 60);
      const sec = Math.floor(second - hour * 3600 - min * 60);
      return [hour, min, sec].map(add0).join(":");
   }
   const renderRuler = () => {
      const segments = [];
      // Makes sure that each bar has atleasst 125px between them.
      const interval = Math.max(
         Math.round(secondsShowing / (containerRef.current?.offsetWidth / 125)),
         1
      );
      if (interval <= 0) return null;
      for (
         let second = -interval;
         second <= filePreview?.audioLength;
         second += interval
      ) {
         const time = second;
         segments.push(
            <Box
               color="#898989"
               // -1 to remove px from marker width.
               left={`${
                  Number.isNaN(
                     containerRef.current?.offsetWidth *
                        (second / secondsShowing)
                  )
                     ? 0
                     : containerRef.current?.offsetWidth *
                       (second / secondsShowing)
               }px`}
               position="absolute"
               display="flex"
               flexDirection="column"
               top={5}
            >
               <Typography
                  variant="small"
                  top={10}
                  style={{ transform: "translateX(-50%)" }}
               >
                  {time >= 0 && secondToTime(time)}
               </Typography>

               <div
                  style={{
                     bottom: 0,
                     width: 1,
                     backgroundColor: "#898989",
                     height: 5,
                  }}
               />
            </Box>
         );
      }
      return segments;
   };

   return (
      <div className={classes.container}>
         <Box
            display="flex"
            flexDirection="column"
            position="relative"
            onClick={() => {
               // setSelectedElement(filePreview);
            }}
         >
            <Box
               ref={rulerContainer}
               onScroll={(e) => {
                  e.target.scrollLeft = rulerScrollValue;
               }}
               className={classes.hideOverflow}
               paddingLeft="56px"
               height={23}
               position="relative"
            >
               <Box position="relative">
                  <Box display="flex" position="absolute">
                     {renderRuler()}
                  </Box>
               </Box>
            </Box>

            <Grid
               container
               justifyContent="space-between"
               alignItems="center"
               className={classes.grid}
               borderBottom="none"
               borderTop="1px solid"
               borderRight="1px solid"
               // Prevent a double width border when not selected because the first ruler line is at 0.
               borderLeft="none"
               borderColor="secondary.shade15"
               wrap="nowrap"
               position="relative"
               tabIndex="0"
               role="button"
            >
               {playbackReady ? (
                  <IconButton
                     onClick={() => {
                        if (!isPaused) {
                           wavesurferRef.current?.pause();
                        } else {
                           wavesurferRef.current?.play();
                        }
                     }}
                  >
                     {!isPaused ? (
                        <PauseCircleFilled className={classes.iconPlayAudio} />
                     ) : (
                        <PlayCircleFilled
                           data-testid="play-bouncedown"
                           className={classes.iconPlayAudio}
                        />
                     )}
                  </IconButton>
               ) : (
                  <Box padding="8px" paddingRight={0}>
                     <Skeleton
                        width={40}
                        height={40}
                        style={{ margin: "6px 10px 0 10px" }}
                        variant="circular"
                     />
                  </Box>
               )}
               {!waveformLoaded && (
                  <Skeleton
                     height={55}
                     width="100%"
                     variant="rectangle"
                     style={{
                        position: "absolute",
                        zIndex: 10,
                     }}
                  />
               )}
               <Box
                  id="waveform-container"
                  className={classes.waveformContainer}
                  ref={containerRef}
               />
            </Grid>

            {/* <Box width="100%">
               <Box
                  order={3}
                  position="relative"
                  height="26px"
                  border="1px solid"
                  borderColor={
                     selectedBouncedown ? "primary.main" : "secondary.shade15"
                  }
               >
                  <Box
                     marginLeft="48px"
                     ref={messagesContainer}
                     className={classes.hideOverflow}
                     height="400px"
                     style={{ background: "transparent" }}
                     paddingTop="13px"
                     onScroll={(e) => {
                        e.target.scrollLeft = messagesScrollValue;
                     }}
                     marginTop="-10px"
                     position="relative"
                  >
                     <Box
                        display="flex"
                        position="absolute"
                        data-testid="comment-bubbles-container"
                     >
                        {filePreview?.uri && (
                           <CommentsBubbles
                              currentFile={filePreview}
                              scrubberContainer={containerRef.current}
                              scrollOffset={messagesScrollValue}
                              setReplying={setReplying}
                              profile={profile}
                              audioLength={secondsShowing * 1000}
                              newComment={newComment}
                              bouncedownComments={bouncedownComments}
                              wavesurferRef={wavesurferRef?.current}
                           />
                        )}
                     </Box>
                  </Box>
               </Box>
            </Box> */}
         </Box>
      </div>
   );
};

export default MediaPlayer;
