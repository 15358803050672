import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import ServicesLogos from "_assets/services-logos.png";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import withStyles from "@mui/styles/withStyles";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

const DarkButton = withStyles(() => ({
   root: {
      color: "white",
      backgroundColor: "black",
      "&:hover": {
         backgroundColor: "black",
         boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      },
   },
}))(Button);

const useStyles = makeStyles(() => ({
   child: {
      flex: 1,
      minWidth: "320px",
   },
   image: {
      width: "100%",
      maxWidth: "98vw",
      height: "auto",
   },
}));

/**
 * Guide Main Panel
 *
 * Homepage section highlighting the features of the guide.
 * @returns {JSX.Element} Guide Main panel homepage section
 */
const GuideMainPanel = () => {
   const classes = useStyles();
   const mobileView = useMediaQuery("(max-width:900px)");

   return (
      <div
         style={{
            background: mobileView
               ? "radial-gradient(50.13% 22.39% at 50.13% 25.59%, #FFDCC9 0%, rgba(220, 176, 255, 0.00) 100%), linear-gradient(225deg, #FFC7E5 0%, #C8BFFF 100%)"
               : "radial-gradient(33.19% 56.5% at 27.43% 43.5%, #FFDCC9 0%, rgba(220, 176, 255, 0) 100%), linear-gradient(250.97deg, #FFC7E5 0.15%, #C8BFFF 99.99%)",
         }}
      >
         <Container
            style={{
               display: "flex",
               flexWrap: "wrap",
               paddingTop: mobileView ? "90px" : "140px",
               paddingBottom: mobileView ? "120px" : "200px",
               gap: "80px",
            }}
         >
            <div className={classes.child}>
               <img
                  className={classes.image}
                  src={ServicesLogos}
                  alt="Services"
               />
            </div>
            <div className={classes.child}>
               <div
                  style={{
                     fontSize: mobileView ? "36px" : "3rem",
                     lineHeight: mobileView ? "36px" : "3rem",
                     fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
                     marginTop: mobileView ? "0px" : "40px",
                     marginBottom: mobileView ? "22px" : "34px",
                     paddingLeft: "10px",
                     paddingRight: mobileView ? "10px" : "74px",
                  }}
               >
                  Thousands of music services across the UK are waiting to be
                  discovered.
               </div>
               <div
                  style={{
                     fontSize: mobileView ? "18px" : "1.1875rem",
                     lineHeight: mobileView ? "145%" : "28px",
                     marginBottom: "34px",
                     paddingLeft: "10px",
                     paddingRight: mobileView ? "10px" : "74px",
                  }}
               >
                  The Guide features recording studios, record labels,
                  management, rehearsal spaces and plenty more connections to
                  help you with your next steps in music.
               </div>
               <DarkButton
                  variant="contained"
                  component={Link}
                  to="/discover"
                  sx={{
                     padding: "12px 16px",
                     fontSize: "16px",
                     marginLeft: "10px",
                  }}
               >
                  Go to discover
               </DarkButton>
            </div>
         </Container>
      </div>
   );
};

export default GuideMainPanel;
