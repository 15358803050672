import { Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { red } from "@mui/material/colors";

const DangerButton = withStyles((theme) => ({
   root: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      "&:hover": {
         backgroundColor: red[700],
      },
      "&:disabled": {
         backgroundColor: theme.palette.gray.main,
      },
   },
}))(Button);

export default DangerButton;
