import { getMimeType } from "./fileTypeUtil/fileTypeUtil";
import generateUri from "./generateUri";

/**
 * Create Nested Folder Tree
 *
 * Creates nested structure of a files and folders that the backend createNestedFolders
 * resolver needs to recursively create a nested folder structure and place files in
 * the correct place.
 * @param {string} pathAsArray path splitted by slash to be updated in place.
 * @param {string} pathFile path of a file.
 * @param {object} file file instance.
 * @param {object} level folders files nested structure.
 */
const createNestedFolderTree = (pathAsArray, pathFile, file, level) => {
   pathAsArray.reduce((r, name, i) => {
      // Specifically exclude .DS_Store files and 0 byte files.
      if (
         !r[name] &&
         name !== ".DS_Store" &&
         (file?.size > 0 || file?.fileSize > 0)
      ) {
         // fileName, mimeType, fileSize, key
         // eslint-disable-next-line no-param-reassign
         r[name] = { treeFolders: [] };
         if (i === pathAsArray.length - 1) {
            // Is File
            const [mimeType] = getMimeType({
               originalName: file.name,
            });
            // Set folder name using the webkitRelativePath
            const removeBottom = pathFile?.slice(0, pathFile?.lastIndexOf("/")); // remove file name
            const folderName = removeBottom.slice(
               removeBottom?.lastIndexOf("/") + 1
            );
            const modifiedFile = file;
            modifiedFile.uri = generateUri(10);
            const node = {
               name,
               uri: modifiedFile.uri,
               fileSize: file?.size || file.fileSize,
               mimeType: mimeType || "other",
               fileName: file.name,
               folderName,
               modifiedAt: file.lastModifiedDate || file.modifiedAt,
            };
            r.treeFolders.push(node);
         } else {
            // Is Directory
            const node = {
               name,
               subfolder: r[name].treeFolders,
            };
            r.treeFolders.push(node);
         }
      }

      return r[name] || r;
   }, level);
};

export default createNestedFolderTree;
