import { SubscriptionPlans } from "_constants/GlobalVariables";

/**
 * getUsersPlan
 * Get the users plan from the subscription plans array.
 * @param {string} plan The plan id.
 * @returns {object} The plan object.
 */
const getUsersPlan = (plan) => {
   // Get the plan from the users subscription.
   const usersSubscription = SubscriptionPlans.ArrayPlans.find((ap) => {
      return ap?.plan === plan || ap?.legacyPlans?.includes(plan);
   });
   // If the plan is not found, return the first plan in the array which should be free monthly plan.
   return usersSubscription || SubscriptionPlans.ArrayPlans[0];
};

export default getUsersPlan;
