import React from "react";

import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { useQueryParam, StringParam } from "use-query-params";

// Apollo
import { useQuery } from "@apollo/react-hooks";

import { Avatar, Box, Grow, Typography } from "@mui/material";
import { GET_USER_PROFILE_PUBLIC } from "../_apollo/queries";

const useStyles = makeStyles(() => ({
   card: {
      borderRadius: 0,
   },
}));
/**
 * Join Aux Connect Profile
 *
 * Panel shown on the SignUp and SignIn page showing the profile page you will be returned to after authentication.
 * @returns {JSX.Element} Join Aux Connect Profile component
 */
const JoinAuxConnectProfile = () => {
   const classes = useStyles();

   const [redirectTo] = useQueryParam("redirectTo", StringParam);

   const { loading: redirectProfileLoading, data: redirectProfileData } =
      useQuery(GET_USER_PROFILE_PUBLIC, {
         variables: {
            uri: redirectTo,
         },
      });

   return (
      <Grow in={!redirectProfileLoading && redirectTo}>
         <Box my={1}>
            <Grid container justifyContent="center">
               <Box
                  display="flex"
                  alignItems="center"
                  component={Card}
                  className={classes.card}
                  variant="outlined"
               >
                  <Avatar
                     src={
                        redirectProfileData?.profileDetailsPublic
                           ?.photoUrlOrGravatar
                     }
                  />
                  <Box ml={1}>
                     <Typography>
                        Join Aux to connect with{" "}
                        {redirectProfileData?.profileDetailsPublic?.name}
                     </Typography>
                  </Box>
               </Box>
            </Grid>
         </Box>
      </Grow>
   );
};

export default JoinAuxConnectProfile;
