import React, { useEffect, useMemo, useState } from "react";
import AppLayout from "_layouts/AppLayout";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { masteringTypes, SubscriptionPlans } from "_constants/GlobalVariables";
import FileUploaderDropHandler from "_components/FileUploaderDropHandler";
import { Upload } from "@mui/icons-material";
import { useUser } from "_utils/UserContext";
import useCheckStorageLimit from "_utils/useCheckStorageLimit";
import useFileUploader from "_components/FileUploader/useFileUploader";
import FileFolderSelectorModal from "_components/Modal/FileFolderSelectorModal";
import { useMutation } from "@apollo/client";
import { CREATE_FILE_FROM_KEY, GET_CURRENT_USER } from "_apollo/queries";
import AudioPlayer from "_components/AudioPlayer";
import { useUploadData } from "_utils/UploadDataContext";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import formatBytes from "_utils/formatBytes";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import UploadIcon from "@mui/icons-material/Upload";
import useStatsMutation from "_utils/useStatsMutation";
import AddIcon from "@mui/icons-material/Add";
import getUsersPlan from "_utils/getUsersPlan";
import UpgradeSubscriptionModal from "_components/Modal/UpgradeSubscriptionModal";
import DangerButton from "_components/DangerButton";
import { useMasteringData } from "_utils/MasteringDataContext";
import DoneIcon from "@mui/icons-material/Done";
import AllowanceComponent from "_components/AllowanceComponent";
import fileDownload from "js-file-download";

interface CustomFile extends File {
  mimeType: string;
  uploadCompleteCallback: Function;
  uploadIntoMasterFolder?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    flex: 1,
    height: "100%",
    overflowY: "auto",
  },
  pageHeaderBar: {
    padding: "23px",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.coreApp.borders}`,
    height: "76.5px",
  },
  containerLimits: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    gap: "46px",
  },
  button: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  dropFile: {
    border: `1px dashed ${theme.palette.secondary.shade15}`,
    margin: "20px",
    padding: "20px",
    position: "relative",
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    borderRadius: "8px",
    flexDirection: "column",
    background: "rgba(242, 242, 242, 0.36)",
    minHeight: "214px",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  uploading: {
    fontSize: "0.925rem",
    lineHeight: "1.25rem",
    fontWeight: 700,
    marginBottom: "20px",
  },
  uploadingTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    fontSize: "0.925rem",
    lineHeight: "1.25rem",
    fontWeight: 400,
  },
  titleWithIcon: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  masteringTopBox: {
    border: `1px dashed ${theme.palette.secondary.shade15}`,
    borderBottomStyle: "solid",
    margin: "20px 20px 0 20px",
    padding: "20px",
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
    flexDirection: "column",
    background: "rgba(242, 242, 242, 0.36)",
    minHeight: "72px",
  },
  masteringBottomBox: {
    border: `1px dashed ${theme.palette.secondary.shade15}`,
    borderTop: "none",
    margin: "0 20px 20px 20px",
    padding: "20px",
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
    flexDirection: "column",
    background: "rgba(242, 242, 242, 0.36)",
    minHeight: "102px",
  },
  titleWithIconDisabled: {
    fontSize: "0.925rem",
    lineHeight: "1.25rem",
    fontWeight: 400,
    color: theme.palette.secondary.shade30,
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  masteringTypesContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: "34px",
    paddingLeft: "24px",
    paddingBottom: "16px",
  },
  masteringTypesTitle: {
    fontSize: "0.75rem",
    lineHeight: "1rem",
    fontWeight: 700,
    marginRight: "12px",
  },
  masteringTypesFlex: {
    display: "flex",
    gap: "8px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: "92px",
  },
  fileSelected: {
    fontSize: "0.925rem",
    lineHeight: "1.25rem",
    fontWeight: 700,
    color: "rgba(0, 0, 0, 0.30)",
    textAlign: "center",
    padding: "14px 6px",
    cursor: "pointer",
  },
  fileSelectedActive: {
    fontSize: "0.925rem",
    lineHeight: "1.25rem",
    fontWeight: 700,
    color: "black",
    textAlign: "center",
    padding: "10px 6px",
    borderTop: "4px solid #6147FF",
  },
  filesSelectedContainer: {
    display: "flex",
    paddingRight: "23px",
    height: "34px",
    gap: "20px",
    marginLeft: "auto",
  },
  chip: {
    padding: "8px 4px",
    lineHeight: "1rem",
    fontSize: "0.75rem",
  },
  titleTable: {
    padding: "6px 24px 18px 24px",
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    fontWeight: 700,
  },
  tableContainer: {
    padding: "0 20px 20px",
    boxSizing: "border-box",
  },
  tableCell: {
    padding: "8px 10px",
  },
  fileName: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  cancelMasterBtn: {
    border: "1px solid #f44336",
  },
  newMasterBtn: {
    border: "1px solid #B3B3B3",
  },
}));
const { Limits } = SubscriptionPlans;

/**
 * Mastering Page
 *
 * TODO: all off these different states should be separate sub compontents!!
 *
 * @returns {React.FC} JSX Element representing the Mastering Page.
 */
function MasteringPage() {
  const classes = useStyles();
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [loadingKey, setLoadingKey] = useState([]);
  const [downloadedKey, setDownloadedKey] = useState([]);

  const [user] = useUser();
  const userSubscriptionObject = useMemo(
    () => getUsersPlan(user?.currentBillingPlan),
    [user]
  );

  const userLimitObj = useMemo(
    () =>
      Limits.find(
        (l) => l.planEnum === (userSubscriptionObject?.modalEnum || 0)
      ),
    [userSubscriptionObject]
  );

  const checkStorageLimit = useCheckStorageLimit();
  const uploadFiles = useFileUploader();
  const [open, setOpen] = useState(false);
  const { filesUploading } = useUploadData();
  const [selectedMasteringType, setSelectedMasteringType] = useState(
    masteringTypes[0]
  );
  const [originalTab, setOriginalTab] = useState(false);
  const sendStatsMutation = useStatsMutation();

  // File uploaded is the one that percentage is less than 100 and has a uploadCompleteCallback...
  const fileUploading = useMemo(() => {
    return filesUploading?.find((f) => f.uploadCompleteCallback);
  }, [filesUploading]);
  const { fileToMaster, setFileToMaster, masterAudioFile, abortControllerRef } =
    useMasteringData();
  const [createFileFromKey] = useMutation(CREATE_FILE_FROM_KEY);

  // Simulate progress bar taking about 80 seconds to reach 100 using setInterval.
  const [generationProgress, setGenerationProgress] = useState(0);
  useEffect(() => {
    let interval;
    if (fileToMaster.status === "MASTERING") {
      interval = setInterval(() => {
        setGenerationProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(interval);
            return 100;
          }
          return oldProgress + 0.25;
        });
      }, 500);
    }
    return () => {
      setGenerationProgress(0);
      clearInterval(interval);
    };
  }, [fileToMaster.status === "MASTERING"]);

  const handleDropEvent = async (event, data) => {
    const eventResetValue = event;
    const filesInput = Array.from<CustomFile>(
      event?.target?.files || data?.files || []
    );

    // Validation conditions for drag and drop.
    if (filesInput.length !== 1) return;
    if (
      filesInput[0]?.mimeType &&
      !filesInput[0]?.mimeType?.includes("audio")
    ) {
      return;
    }
    const enoughStorage = checkStorageLimit(filesInput, user);
    if (!enoughStorage) {
      return;
    }

    setFileToMaster((a) => ({ ...a, status: "UPLOADING" }));
    uploadFiles(
      filesInput.map((f, i) => {
        const mutableFile = f;
        mutableFile.uploadCompleteCallback = (file) => {
          // Set file to mastering
          setOpen(false);
          setFileToMaster(() => ({ status: "MASTERING", file }));
          masterAudioFile(file.name);
        };
        mutableFile.uploadIntoMasterFolder = true;
        return mutableFile;
      })
    );

    if (eventResetValue?.target?.value) eventResetValue.target.value = "";
  };

  /**
   * Capitalizes the first letter of each word in a given string.
   *
   * @param {string} str - The string to capitalize.
   * @returns {string} The input string with the first letter of each word capitalized.
   */
  function capitalizeWords(str) {
    return str.replace(/\b\w/g, (char) => {
      return char.toUpperCase();
    });
  }

  /**
   * Inserts a string before the last dot and updates the file to wav as all masters are wav.
   *
   * @param {string} str - The string to insert into.
   * @param {string} insertStr - The string to insert.
   * @returns {string} The modified string with the insertStr inserted before the last dot and the extension updated to wav.
   */
  function replaceExtensionAndInsertReference(str, insertStr) {
    const lastDotIndex = str.lastIndexOf(".");
    if (lastDotIndex !== -1) {
      return `${str.substring(0, lastDotIndex) + insertStr}.wav`;
    }
    // If no dot found, just return the original string
    return str;
  }

  return (
    <AppLayout>
      {openSubscriptionModal && (
        <UpgradeSubscriptionModal
          title={
            user?.currentBillingPlan
              ? "To download more masters upgrade your plan"
              : "To download masters start your free trial"
          }
          description="and get access all app features, including project storage and samples and loops"
          open={openSubscriptionModal}
          handleClose={() => setOpenSubscriptionModal(false)}
        />
      )}
      <div className={classes.root}>
        {open && (
          <FileFolderSelectorModal
            open={open}
            loading={false}
            setOpen={setOpen}
            title="Select track to master"
            selectMode="singleFile"
            filterFiles={(file) => file.mimeType.includes("audio")}
            onConfirm={(selectedFiles) => {
              setOpen(false);
              // Select single file to Master
              setFileToMaster(() => ({
                status: "MASTERING",
                file: selectedFiles[0],
              }));
              masterAudioFile(selectedFiles[0].name);
            }}
          />
        )}
        <div className={classes.pageHeaderBar}>
          <h3
            style={{
              margin: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            Mastering
          </h3>
          <div className={classes.containerLimits}>
            {fileToMaster.status !== "MASTERING" &&
              (userLimitObj.mastersAllowance === 0 ||
                0.75 * userLimitObj.mastersAllowance <
                  user?.limits?.mastersCreated) && (
                <Button
                  className={classes.newMasterBtn}
                  onClick={() => {
                    setOpenSubscriptionModal(true);
                  }}
                >
                  Upgrade to Download
                </Button>
              )}
            {fileToMaster.status !== "MASTERING" &&
              userLimitObj.mastersAllowance !== 9999 &&
              userLimitObj.mastersAllowance !== 0 && (
                <AllowanceComponent
                  currentValue={user?.limits?.mastersCreated}
                  limit={userLimitObj.mastersAllowance}
                  title="masters downloaded"
                />
              )}

            {fileToMaster.status !== "EMPTY" && (
              <>
                {fileToMaster.status === "MASTERING" ? (
                  <DangerButton
                    className={classes.cancelMasterBtn}
                    onClick={() => {
                      abortControllerRef.current.abort();
                      abortControllerRef.current = new AbortController();
                      setFileToMaster({ status: "EMPTY", file: null });
                    }}
                  >
                    Cancel Mastering
                  </DangerButton>
                ) : (
                  <Button
                    className={classes.newMasterBtn}
                    onClick={() => {
                      setFileToMaster({ status: "EMPTY", file: null });
                    }}
                  >
                    <AddIcon
                      style={{
                        fill: "black",
                        height: "20px",
                        width: "20px",
                        marginRight: "6px",
                      }}
                    />
                    New master
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        {fileToMaster.status === "EMPTY" && (
          <div>
            <Box className={`${classes.dropFile} ${classes.flex}`}>
              <FileUploaderDropHandler handleDropEvent={handleDropEvent} />
              Drag and drop or add from your files
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  marginTop: "14px",
                }}
              >
                <Button
                  variant="contained"
                  disableElevation
                  className={classes.button}
                  onClick={(event) => {
                    event?.currentTarget?.querySelector("input")?.click();
                  }}
                >
                  <input
                    hidden
                    type="file"
                    accept="audio/*"
                    onChange={(e) => {
                      handleDropEvent(e, null);
                      e.target.value = null;
                    }}
                  />
                  <Upload /> Upload file
                </Button>
                <Button
                  variant="outlined"
                  sx={{ border: "1px solid #B3B3B3" }}
                  onClick={() => setOpen(true)}
                >
                  Add from files
                </Button>
              </div>
            </Box>
          </div>
        )}
        {fileToMaster.status === "UPLOADING" && fileUploading && (
          <div>
            <Box className={classes.dropFile}>
              <div>
                <div className={classes.uploading}>Uploading</div>
                <div className={classes.uploadingTitle}>
                  <div className={classes.titleWithIcon}>
                    <PlayCircleFilledIcon
                      style={{ height: "24px", width: "24px" }}
                    />
                    {fileUploading.name}
                  </div>
                  <div className={classes.colorPrimary}>
                    {fileUploading.percentage}
                  </div>
                </div>
              </div>
              <Box color="#6147FF" borderRadius="4px" overflow="hidden">
                <LinearProgress
                  sx={{
                    height: "7px",
                  }}
                  value={fileUploading.plainPercentage}
                  variant={
                    fileUploading?.percentage ? "determinate" : "indeterminate"
                  }
                  color="inherit"
                />
              </Box>
            </Box>
          </div>
        )}
        {fileToMaster.status === "MASTERING" && (
          <div>
            <Box padding="23px" className={classes.masteringTopBox}>
              <div>
                <div className={classes.uploading}>Uploaded</div>
                <div className={classes.titleWithIconDisabled}>
                  <PlayCircleFilledIcon
                    style={{ height: "24px", width: "24px" }}
                  />
                  {fileToMaster.file.originalName}
                  <div style={{ marginLeft: "36px" }}>
                    {formatBytes(fileToMaster.file.size)}
                  </div>
                </div>
              </div>
            </Box>
            <Box padding="23px" className={classes.masteringBottomBox}>
              <div className={classes.uploading}>Mastering</div>
              <div className={classes.uploadingTitle}>
                <div className={classes.titleWithIcon}>
                  <AutoFixHighIcon style={{ height: "24px", width: "24px" }} />
                  {fileToMaster.file.originalName}
                </div>
              </div>
              <Box color="#6147FF" borderRadius="4px" overflow="hidden">
                <LinearProgress
                  sx={{
                    height: "7px",
                  }}
                  value={generationProgress}
                  variant="determinate"
                  color="inherit"
                />
              </Box>
            </Box>
          </div>
        )}
        {fileToMaster.status === "MASTERED" && fileToMaster.file && (
          <div>
            <div className={classes.header}>
              {!originalTab && (
                <div className={classes.masteringTypesContainer}>
                  <div className={classes.masteringTypesTitle}>Style:</div>
                  <div className={classes.masteringTypesFlex}>
                    {masteringTypes.map((type) => (
                      <Chip
                        key={type}
                        label={capitalizeWords(type.replace("-", " ")).replace(
                          "Diy",
                          "DIY"
                        )}
                        className={classes.chip}
                        color={
                          selectedMasteringType === type ? "primary" : "default"
                        }
                        onClick={() => {
                          setSelectedMasteringType(type);
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}
              <div className={classes.filesSelectedContainer}>
                <div
                  onClick={() => {
                    setOriginalTab(false);
                    setSelectedMasteringType(masteringTypes[0]);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setOriginalTab(false);
                      setSelectedMasteringType(masteringTypes[0]);
                    }
                  }}
                  tabIndex={0}
                  role="button"
                  className={
                    !selectedMasteringType
                      ? classes.fileSelected
                      : classes.fileSelectedActive
                  }
                >
                  Mastered
                </div>
                <div
                  tabIndex={0}
                  role="button"
                  onClick={() => {
                    setSelectedMasteringType(null);
                    setOriginalTab(true);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setSelectedMasteringType(null);
                      setOriginalTab(true);
                    }
                  }}
                  className={
                    selectedMasteringType
                      ? classes.fileSelected
                      : classes.fileSelectedActive
                  }
                >
                  Original
                </div>
              </div>
            </div>

            <Box padding="23px">
              <AudioPlayer
                file={fileToMaster.file}
                typeSelected={selectedMasteringType}
              />
            </Box>
            <div>
              <div className={classes.titleTable}>Mastered files</div>
              <TableContainer
                className={classes.tableContainer}
                sx={{
                  marginTop: "-2px", // Needed to hide the table rows under the header
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tableCell}
                        style={{ padding: "8px 10px" }}
                      >
                        Name
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        File Type
                      </TableCell>
                      <TableCell className={classes.tableCell}>Style</TableCell>
                      <TableCell className={classes.tableCell} />
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {fileToMaster.file.masteredData?.map((f) => {
                      return (
                        <TableRow key={`${f?.uri}-files-table-project`}>
                          <TableCell className={classes.tableCell}>
                            <div className={classes.fileName}>
                              <PlayCircleFilledIcon
                                style={{
                                  fill: "black",
                                  height: "24px",
                                  width: "24px",
                                }}
                              />
                              <span>
                                {replaceExtensionAndInsertReference(
                                  fileToMaster.file.originalName,
                                  `-${f.reference}`
                                )}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            WAV
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {f.reference}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <IconButton
                              sx={{ p: "0" }}
                              onClick={() => {
                                // If free plan or limit reached show upgrade modal
                                if (
                                  userSubscriptionObject?.modalEnum === 0 ||
                                  user?.limits?.mastersCreated >=
                                    userLimitObj.mastersAllowance
                                ) {
                                  setOpenSubscriptionModal(true);
                                  return;
                                }
                                setLoadingKey([...loadingKey, f.file_key]);
                                // Refetch current user to update the limits
                                sendStatsMutation(
                                  {
                                    statsId: "MasterTrackDownload",
                                    userId: user?.id,
                                    metadata: JSON.stringify({
                                      fileName: f.file_key,
                                      reference: f.reference,
                                    }),
                                  },
                                  {
                                    refetchQueries: [
                                      { query: GET_CURRENT_USER },
                                    ],
                                  }
                                );

                                // Trigger normal download.
                                const downloadFileName =
                                  replaceExtensionAndInsertReference(
                                    fileToMaster.file.originalName,
                                    `-${f.reference}`
                                  );
                                // fileDownload(f.signed_url, downloadFileName);
                                fetch(f.signed_url)
                                  .then((response) => response.blob())
                                  .then((blob) => {
                                    fileDownload(blob, downloadFileName);
                                    setLoadingKey([
                                      ...loadingKey.filter(
                                        (key) => key !== f.file_key
                                      ),
                                    ]);
                                    setDownloadedKey([
                                      ...downloadedKey,
                                      f.file_key,
                                    ]);
                                  });

                                // Also add to My Aux Files
                                createFileFromKey({
                                  variables: {
                                    key: f.file_key,
                                    originalFileId: fileToMaster.file.id,
                                    name: replaceExtensionAndInsertReference(
                                      fileToMaster.file.originalName,
                                      `-${f.reference}`
                                    ),
                                    size: f.file_size,
                                    type: "masters",
                                  },
                                  onCompleted: () => {
                                    //
                                  },
                                });
                              }}
                            >
                              {downloadedKey.includes(f.file_key) && (
                                <DoneIcon
                                  sx={{
                                    fill: "#0CECC4",
                                    height: "24px",
                                    width: "24px",
                                  }}
                                />
                              )}
                              {loadingKey.includes(f.file_key) && (
                                <CircularProgress size={24} />
                              )}
                              {!loadingKey.includes(f.file_key) &&
                                !downloadedKey.includes(f.file_key) && (
                                  <UploadIcon
                                    sx={{
                                      rotate: "180deg",
                                      fill: "black",
                                      height: "24px",
                                      width: "24px",
                                    }}
                                  />
                                )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
}

export default MasteringPage;
