import React, { useState, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { useLazyQuery } from "@apollo/react-hooks";
import { Paper, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GET_TAGS } from "../../_apollo/queries";
import AutoCompleteItem from "../SuggestionsContacts/AutoCompleteItem";

const useStyles = makeStyles(() => ({
   searchResults: {
      position: "absolute",
      width: "100%",
      "z-index": 5,
      height: 150,
      "overflow-y": "auto",
   },
}));

/**
 * AutoComplete
 *
 * Tags suggestions autocomplete.
 *
 * TODO: this component has mega prop drilling. It should be refactored to use context/queries direction within the childmost component.
 * @param {object} props props
 * @param {object} props.tagSearch tagSearch
 * @param {Function} props.setTagSearch setTagSearch
 * @param {Array} props.tagDefault tagDefault
 * @param {object} props.values values
 * @param {object} props.tags tags
 * @param {Function} props.handleChange handleChange
 * @param {Function} props.addTags addTags
 * @param {Function} props.setFieldValue setFieldValue
 * @param {Function} props.setFieldTouched setFieldTouched
 * @param {object} props.errors errors
 * @param {boolean} props.touched touched
 * @returns {JSX.Element} AutoComplete component.
 */
const AutoComplete = ({
   tagSearch,
   setTagSearch,
   tagDefault,
   values,
   tags,
   handleChange,
   addTags,
   setFieldValue,
   errors,
   touched,
   setFieldTouched,
}) => {
   const classes = useStyles();
   const [getTags] = useLazyQuery(GET_TAGS, {
      /**
       * getTags onCompleted
       * @param {object} data Apollo data object.
       */
      onCompleted(data) {
         setTagSearch(data.getTags);
      },
   });

   const [isVisible, setVisibility] = useState(touched);
   const [cursor, setCursor] = useState(-1);

   const timer = useRef(null);

   const searchContainer = useRef(null);
   const searchResultRef = useRef(null);

   /**
    * Scroll Into View
    * @param {number} position Position to scroll to.
    */
   const scrollIntoView = (position) => {
      if (searchResultRef.current !== null) {
         searchResultRef.current.parentNode.scrollTo({
            top: position - 80,
            behavior: "smooth",
         });
      }
   };

   /**
    * Show Suggestion
    */
   const showSuggestion = () => {
      setFieldTouched("tag", true);
      setVisibility(true);
   };

   /**
    * Hide Suggestion
    */
   const hideSuggestion = () => {
      setFieldValue("tag", "");
      setVisibility(false);
   };

   const suggestions = useMemo(() => {
      const tagFilter = values.tag === "" ? tagDefault : tagSearch;
      // if (!values.tag || values.tag === '') return [];
      if (!tagFilter || tagFilter.length === 0) return [];
      if (!isVisible) return [];

      setCursor(-1);
      scrollIntoView(0);
      showSuggestion();
      if (values.tag) {
         return tagFilter
            .filter(
               ({ name }) =>
                  [
                     ...(tags.map((el) => el.name) || []),
                     ...(values?.tag || []),
                  ].indexOf(name) === -1
            )
            .filter((element) => {
               if (values.tag !== "") {
                  if (values.tag !== element.name) {
                     return (
                        element.name &&
                        element.name
                           .toLowerCase()
                           .includes(values.tag.toLowerCase().trim())
                     );
                  }
               }
               return false;
            });
      }
      return tagFilter;
   }, [tagSearch, values.tag, touched]);

   useEffect(() => {
      if (cursor < 0 || cursor > suggestions.length || !searchResultRef) {
         return () => {};
      }

      const listItems = Array.from(searchResultRef.current.children);
      if (listItems[cursor]) {
         scrollIntoView(listItems[cursor].offsetTop);
      }
      return null;
   }, [cursor]);

   /**
    * Keyboard Navigation
    * @param {object} e event
    */
   const keyboardNavigation = (e) => {
      if (e.key === "ArrowDown") {
         if (isVisible) {
            setCursor((c) => (c < suggestions.length - 1 ? c + 1 : c));
         } else {
            showSuggestion();
         }
      }

      if (e.key === "ArrowUp") {
         setCursor((c) => (c > 0 ? c - 1 : 0));
      }

      if (e.key === "Escape") {
         hideSuggestion();
      }
      if (e.key === "Tab") {
         addTags({ name: values.tag });
         hideSuggestion();
      }

      if (e.key === "Enter" && cursor >= 0) {
         e.preventDefault();
         addTags({
            id: suggestions[cursor]?.id,
            name: suggestions[cursor]?.name,
         });
         hideSuggestion();
      } else if (e.key === "Enter" && values.tag !== "") {
         e.preventDefault();
         addTags({ name: values.tag });
         hideSuggestion();
      }
   };

   /**
    * Handle Tag Change
    * @param {object} e Event object.
    */
   const handleTagChange = (e) => {
      handleChange(e);
      showSuggestion();
      clearTimeout(timer.current);
      // Search for tags
      if (e.target.value !== "") {
         timer.current = setTimeout(() => {
            getTags({
               variables: {
                  name: e.target.value,
               },
            });
         }, 1000);
      }
   };
   return (
      <div ref={searchContainer} className="wrapper-autosuggest">
         <TextField
            fullWidth
            size="small"
            variant="outlined"
            className={classes.input}
            name="tag"
            placeholder="New Tag"
            error={errors.tag}
            value={values.tag}
            type="text"
            autoComplete="off"
            onClick={showSuggestion}
            onChange={(e) => handleTagChange(e)}
            onKeyDown={(e) => keyboardNavigation(e)}
            onFocus={(event) => {
               event.target.setAttribute("autocomplete", "off");
            }}
            onBlur={() => {
               addTags({ name: values.tag });
               hideSuggestion();
            }}
         />

         {isVisible && (
            <div className={classes.searchResults}>
               <Paper ref={searchResultRef}>
                  {suggestions.map((item, idx) => {
                     return (
                        <AutoCompleteItem
                           key={item.name}
                           onSelectItem={() => {
                              hideSuggestion();
                              addTags({ id: item.id, name: item.name });
                              setFieldValue("tag", "");
                           }}
                           isHighlighted={cursor === idx}
                           name={item.name}
                           email={item.email}
                        />
                     );
                  })}
               </Paper>
            </div>
         )}
      </div>
   );
};

AutoComplete.propTypes = {
   tagSearch: PropTypes.instanceOf(Object).isRequired,
   setTagSearch: PropTypes.func.isRequired,
   tagDefault: PropTypes.instanceOf(Array).isRequired,
   values: PropTypes.instanceOf(Object).isRequired,
   tags: PropTypes.instanceOf(Object).isRequired,
   handleChange: PropTypes.func.isRequired,
   addTags: PropTypes.func.isRequired,
   setFieldValue: PropTypes.func.isRequired,
   setFieldTouched: PropTypes.func.isRequired,
   errors: PropTypes.instanceOf(Object).isRequired,
   touched: PropTypes.bool,
};

AutoComplete.defaultProps = {
   touched: false,
};

export default AutoComplete;
