// TODO: Review file, useEffect/useCallback dependencies changed.
import React, { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import { Link, useParams, useHistory } from "react-router-dom";

// Apollo
import { useMutation, useQuery } from "@apollo/react-hooks";
import { CircularProgress, Button, Box } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useQueryParam, StringParam } from "use-query-params";
import {
   CONFIRM_EMAIL,
   GET_CURRENT_USER,
   RESEND_CONFIRM_EMAIL,
} from "../_apollo/queries";

/**
 * Confirm Email Page
 * Show the thank you or Unable to Verify page after the user clicks their verification link.
 * @returns {JSX.Element} Email confirmation result page.
 */
const ConfirmEmailPage = () => {
   const { token } = useParams();
   const history = useHistory();

   const { data: userData } = useQuery(GET_CURRENT_USER);
   const user = userData?.currentUser;

   const [hash] = useQueryParam("hash", StringParam);
   const [redirectTo] = useQueryParam("redirectTo", StringParam);

   const [somethingWentWrong, setSomethingWentWrong] = useState(false);
   const [
      resendConfirmEmailRequest,
      { data: dataResendEmail, loading: loadingConfirm },
   ] = useMutation(RESEND_CONFIRM_EMAIL);

   const [confirmEmailRequest] = useMutation(CONFIRM_EMAIL, {
      /**
       * confirmEmailRequest onCompleted
       *
       * If the user has the dekstop app installed, trigger the app link and sent confirm email events.
       * @param {object} data Apollo confirm email response data.
       * @returns {void} void
       */
      onCompleted(data) {
         const confirmEmail = data?.confirmEmailRequest;
         if (!confirmEmail.verified) {
            return;
         }
         if (confirmEmail.hasDesktop) {
            window.location.replace("aux://aux/completeConfirmEmail");
         }
         ReactPixel.track("CompleteRegistration");
         if (hash) {
            history.push(`/feed?hash=${hash}`);
         } else if (redirectTo) {
            history.push(`/${redirectTo}`);
         } else {
            // Redirect to feed
            history.push("/feed");
         }
      },
      /**
       * confirmEmailRequest onError
       *
       * Show error message if confirmation fails and resend the confirmation email.
       */
      onError() {
         setSomethingWentWrong(true);
      },
   });

   useEffect(() => {
      confirmEmailRequest({
         variables: {
            token,
         },
      });
   }, []);

   return (
      <div
         style={{
            width: "100vw",
            height: "100vh",
         }}
      >
         <div
            style={{
               height: "52px",
               width: "100%",
               backgroundColor: "white",
               "-webkit-user-select": "none",
               "-webkit-app-region": "drag",
            }}
         />
         <div
            style={{
               width: "100%",
               height: "calc(100% - 52px)",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               position: "relative",
               background:
                  "radial-gradient(82.2% 100% at 50% 100%, #FFD645 0%, #FFBFBB 61.5%, #C8BFFF 100%)",
            }}
         >
            <div style={{ position: "absolute", top: "10%", left: "10%" }}>
               <ArrowOutwardIcon
                  sx={{
                     color: "white",
                     fontSize: "70px",
                     transform: "rotate(90deg)",
                  }}
               />
               <ArrowOutwardIcon
                  sx={{
                     color: "white",
                     fontSize: "70px",
                     transform: "rotate(90deg)",
                  }}
               />
               <ArrowOutwardIcon
                  sx={{
                     color: "white",
                     fontSize: "70px",
                     transform: "rotate(90deg)",
                  }}
               />
            </div>
            <div style={{ position: "absolute", bottom: "10%", right: "10%" }}>
               <ArrowOutwardIcon
                  sx={{
                     color: "white",
                     fontSize: "70px",
                     transform: "rotate(270deg)",
                  }}
               />
               <ArrowOutwardIcon
                  sx={{
                     color: "white",
                     fontSize: "70px",
                     transform: "rotate(270deg)",
                  }}
               />
               <ArrowOutwardIcon
                  sx={{
                     color: "white",
                     fontSize: "70px",
                     transform: "rotate(270deg)",
                  }}
               />
            </div>
            {somethingWentWrong ? (
               <div style={{ maxWidth: "558px" }}>
                  <h3
                     style={{
                        fontWeight: 800,
                        fontSize: "76px",
                        lineHeight: "72px",
                        fontFamily: "dr-extrabold",
                        margin: "0 0 30px 0",
                     }}
                  >
                     Try that again
                  </h3>
                  <p
                     style={{
                        fontSize: "18px",
                        lineHeight: "24px",
                        margin: "0 0 40px 0",
                     }}
                  >
                     The link you clicked{" "}
                     <span style={{ fontWeight: 700 }}>has expired</span>, you
                     must click on the link within 24 hours. To resend your
                     verification link, sign in and click the &quot;Resend
                     Confirmation&quot; button.
                  </p>
                  <div style={{ display: "flex", gap: "16px" }}>
                     {user ? (
                        <Button
                           component={Link}
                           color="dark"
                           sx={{ color: "white" }}
                           variant="contained"
                           disabled={loadingConfirm || dataResendEmail}
                           onClick={async () => {
                              await resendConfirmEmailRequest();
                           }}
                        >
                           {dataResendEmail
                              ? "Confirmation Sent"
                              : "Resend Confirmation"}
                        </Button>
                     ) : (
                        <Button
                           to="/signin"
                           component={Link}
                           color="dark"
                           sx={{ color: "white" }}
                           variant="contained"
                           disabled={loadingConfirm || dataResendEmail}
                        >
                           Sign In
                        </Button>
                     )}
                     <Box ml={1}>
                        <Button
                           color="dark"
                           href="mailto:hello@aux.app"
                           variant="outlined"
                           target="_blank"
                        >
                           Contact Support
                        </Button>
                     </Box>
                  </div>
               </div>
            ) : (
               <div style={{ width: "100%", paddingLeft: 16 }}>
                  <CircularProgress />
               </div>
            )}
         </div>
      </div>
   );
};

export default ConfirmEmailPage;
