import { Avatar, Box, Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";

import SettingsContainer from "_components/SettingsContainer";
import { makeStyles } from "@mui/styles";
import isValidDate from "_utils/isValidDate";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_PROFILE_STATS, GET_CURRENT_USER } from "_apollo/queries";
import ValidReactTimeAgo from "_components/ValidReactTimeAgo";

const useStyles = makeStyles((theme: any) => ({
  avatar: {
    width: 83,
    height: 83,
  },
  links: {
    fontWeight: "bold",
    "&:visited": {
      color: theme.palette.primary.main,
    },
  },
  timeAgo: {
    fontWeight: "bold",
  },
}));

/**
 * AccountSettings
 *
 * Account Page, contains information about user's plan and their app limits.
 * @returns {JSX.Element} Account Page Component.
 */
const AccountSettings = () => {
  const classes = useStyles();

  const { data: profileStatsData, loading: userStatsLoading } =
    useQuery(GET_PROFILE_STATS);
  const { data } = useQuery(GET_CURRENT_USER);
  const user = data?.currentUser;
  const profile = useMemo(() => user?.profile, [user]);

  const profileCreatedDate = useMemo(
    () => new Date(+profile?.createdAt),
    [profile]
  );

  const description = useMemo(() => {
    if (!profileCreatedDate) return null;

    const { soundsGenerationCount, masterTrackCount, fileCount } =
      profileStatsData?.getProfileStats
        ? profileStatsData?.getProfileStats
        : { soundsGenerationCount: 0, masterTrackCount: 0, fileCount: 0 };

    return (
      <Box>
        <Typography component="span">
          Since you joined{" "}
          <ValidReactTimeAgo
            className={classes.timeAgo}
            date={profileCreatedDate}
            overrideStyles={{
              labels: "route",
            }}
          />
          ,&nbsp;
        </Typography>
        <Typography component="span">
          you have generated&nbsp;
          <Link className={classes.links} to="/sounds" color="primary">
            {soundsGenerationCount} sound
            {soundsGenerationCount === 1 ? "" : "s"}
          </Link>
        </Typography>
        <Typography component="span">
          , mastered&nbsp;
          <Link className={classes.links} to="/mastering" color="primary">
            {masterTrackCount} track
            {masterTrackCount === 1 ? "" : "s"}
          </Link>
        </Typography>
        <Typography component="span">
          , and created&nbsp;
          <Link className={classes.links} to="/files" color="primary">
            {fileCount} file
            {fileCount === 1 ? "" : "s"}.
          </Link>
        </Typography>
      </Box>
    );
  }, [profileStatsData, profileCreatedDate]);

  return (
    <SettingsContainer title="Settings">
      <Grid margin="45px 0px" container gap="35px" alignItems="flex-start">
        <Avatar className={classes.avatar} src={profile?.photoUrlOrGravatar} />
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Typography variant="h5" fontWeight="bold" marginBottom="15px">
            Hi, {profile?.name}!
          </Typography>
          {isValidDate(profileCreatedDate) && !userStatsLoading && description}
        </Box>
      </Grid>
    </SettingsContainer>
  );
};

export default AccountSettings;
