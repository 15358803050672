import PropTypes from "prop-types";
import React from "react";

/**
 * Aux Awards Logo
 *
 * Aux Awards logo without a year number.
 * @param {object} props props
 * @param {object} props.style Styles to pass through to the svg.
 * @returns {SVGAElement} Aux Awards SVG logo.
 */
const AuxAwardsLogo = ({ style }) => {
   return (
      <svg
         style={style}
         width="251"
         height="35"
         viewBox="0 0 956 200"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M66.0001 162.4C78.0001 162.4 88.5601 158.8 97.2001 152.32V160H128.4V40H97.2001V47.68C88.5601 41.2 78.0001 37.6 66.0001 37.6C29.5201 37.6 3.6001 63.52 3.6001 100C3.6001 136.72 29.5201 162.4 66.0001 162.4ZM66.0001 133.6C47.0401 133.6 34.8001 120.4 34.8001 100C34.8001 79.6 47.0401 66.4 66.0001 66.4C84.9601 66.4 97.2001 79.6 97.2001 100C97.2001 120.4 84.9601 133.6 66.0001 133.6Z"
            fill="black"
         />
         <path
            d="M140.297 112C140.297 141.52 160.697 162.4 188.777 162.4C199.337 162.4 208.697 158.8 215.897 152.32V160H247.097V40H215.897V112C215.897 124.72 206.777 133.6 193.577 133.6C180.857 133.6 171.497 124.72 171.497 112V40H140.297V112Z"
            fill="black"
         />
         <path
            d="M333.045 160H369.525L329.925 97.6L366.645 40H330.405L311.445 71.2L292.485 40H256.005L292.725 97.84L253.125 160H289.605L311.445 124.24L333.045 160Z"
            fill="black"
         />
         <path
            d="M490.714 160H510.415L461.521 40.003H442.894L394 160H413.88L423.91 135.284H480.685L490.714 160ZM430.716 118.27L452.387 64.7188L473.879 118.27H430.716Z"
            fill="black"
         />
         <path
            d="M579.024 160H597.83L626.128 70.45H606.785L588.517 134.926L571.323 70.45H552.697L535.324 134.926L517.235 70.45H497.892L526.19 160H544.995L562.01 101.613L579.024 160Z"
            fill="black"
         />
         <path
            d="M673.112 161.791C684.754 161.791 694.604 157.672 701.768 150.329V160H719.678V70.45H701.768V80.1214C694.604 72.7783 684.754 68.659 673.112 68.659C645.889 68.659 626.546 88.0018 626.546 115.225C626.546 142.627 645.889 161.791 673.112 161.791ZM673.112 145.314C656.098 145.314 644.456 133.314 644.456 115.225C644.456 97.1359 656.098 84.9571 673.112 84.9571C690.306 84.9571 701.768 97.1359 701.768 115.225C701.768 133.314 690.306 145.314 673.112 145.314Z"
            fill="black"
         />
         <path
            d="M734.003 160H751.913V100.36L779.853 88.8973V70.45L751.913 82.6288V70.45H734.003V160Z"
            fill="black"
         />
         <path
            d="M829.825 161.791C841.466 161.791 851.317 157.672 858.481 150.329V160H876.391V40.003H858.481V80.1214C851.317 72.7783 841.466 68.659 829.825 68.659C802.601 68.659 783.259 88.0018 783.259 115.225C783.259 142.627 802.601 161.791 829.825 161.791ZM829.825 145.314C812.81 145.314 801.169 133.314 801.169 115.225C801.169 97.1359 812.81 84.9571 829.825 84.9571C847.018 84.9571 858.481 97.1359 858.481 115.225C858.481 133.314 847.018 145.314 829.825 145.314Z"
            fill="black"
         />
         <path
            d="M907.193 94.2703C907.193 87.6436 911.85 84.0616 919.014 84.0616C926.536 84.0616 931.371 88.7182 931.371 97.1359H949.281C949.281 79.9423 936.924 68.659 919.014 68.659C902.715 68.659 890.716 79.0468 890.716 94.2703C890.716 128.299 931.73 115.404 931.73 135.284C931.73 142.448 926.894 146.388 919.909 146.388C912.208 146.388 907.193 141.732 907.193 133.135H889.283C889.283 150.329 902.178 161.791 919.909 161.791C937.282 161.791 949.64 151.224 949.64 135.284C949.64 101.076 907.193 114.15 907.193 94.2703Z"
            fill="black"
         />
      </svg>
   );
};

AuxAwardsLogo.propTypes = {
   style: PropTypes.shape({}),
};
AuxAwardsLogo.defaultProps = {
   style: {},
};

export default AuxAwardsLogo;
