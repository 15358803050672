import React from "react";

/**
 * SettingsIcon
 * Settings Cog Icon
 * @returns {JSX.Element} component.
 */
const SettingsIcon = ({ styles }) => {
   return (
      <svg
         style={styles}
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <g clipPath="url(#clip0_6792_246142)">
            <path
               d="M19.14 12.94C19.18 12.64 19.2 12.33 19.2 12C19.2 11.68 19.18 11.36 19.13 11.06L21.16 9.47999C21.34 9.33999 21.39 9.06999 21.28 8.86999L19.36 5.54999C19.24 5.32999 18.99 5.25999 18.77 5.32999L16.38 6.28999C15.88 5.90999 15.35 5.58999 14.76 5.34999L14.4 2.80999C14.36 2.56999 14.16 2.39999 13.92 2.39999H10.08C9.84 2.39999 9.65 2.56999 9.61 2.80999L9.25 5.34999C8.66 5.58999 8.12 5.91999 7.63 6.28999L5.24 5.32999C5.02 5.24999 4.77 5.32999 4.65 5.54999L2.74 8.86999C2.62 9.07999 2.66 9.33999 2.86 9.47999L4.89 11.06C4.84 11.36 4.8 11.69 4.8 12C4.8 12.31 4.82 12.64 4.87 12.94L2.84 14.52C2.66 14.66 2.61 14.93 2.72 15.13L4.64 18.45C4.76 18.67 5.01 18.74 5.23 18.67L7.62 17.71C8.12 18.09 8.65 18.41 9.24 18.65L9.6 21.19C9.65 21.43 9.84 21.6 10.08 21.6H13.92C14.16 21.6 14.36 21.43 14.39 21.19L14.75 18.65C15.34 18.41 15.88 18.09 16.37 17.71L18.76 18.67C18.98 18.75 19.23 18.67 19.35 18.45L21.27 15.13C21.39 14.91 21.34 14.66 21.15 14.52L19.14 12.94ZM12 15.6C10.02 15.6 8.4 13.98 8.4 12C8.4 10.02 10.02 8.39999 12 8.39999C13.98 8.39999 15.6 10.02 15.6 12C15.6 13.98 13.98 15.6 12 15.6Z"
               fill="currentcolor"
            />
         </g>
         <defs>
            <clipPath id="clip0_6792_246142">
               <rect width="24" height="24" fill="white" />
            </clipPath>
         </defs>
      </svg>
   );
};

export default SettingsIcon;
