import appStorage from "_utils/appStorage";
import getConfig from "./getConfig";

const { restUrl } = getConfig();

/**
 * Get Policy Request
 *
 * It gets a policy request to be able to upload files to S3 from the web.
 * @param {string} fileKey string location of file
 * @param {object} file File object
 * @param {string} typeData Type stream/multi data Changes in web vs Electron
 * @returns {object} With all the keys of the policy which will need to be added to the file when It is uploaded
 */
const getPolicyRequest = async (
   fileKey,
   file,
   typeData = "application/octet-stream"
) => {
   const policyRequest = await fetch(`${restUrl}/policy`, {
      credentials: "include",
      method: "POST",
      headers: {
         "Content-Type": "application/json",
         authorization: `Bearer: ${appStorage.getValue({ key: "auxJWT" })}`,
         auxSelectedProfileURI: appStorage.getValue({
            key: "auxSelectedProfileURI",
         }),
      },
      body: JSON.stringify({
         key: fileKey,
         size: file?.size || file?.fileSize,
         typeData,
      }),
   });
   const policy = await policyRequest.json();
   return policy;
};

/**
 * Get Multi-Part Policy
 *
 * It gets a policy to able to upload files to S3 from the web in multiple parts.
 * @param {string} fileKey string location of file.
 * @param {object} file File object.
 * @param {string} parts Array of file parts in the S3 SDK format.
 * @returns {object} With all the keys of the policy which will need to be added to the file when It is uploaded
 */
const getMultiPartPolicy = async (fileKey, file, parts) => {
   const res = await fetch(`${restUrl}/multipart`, {
      credentials: "include",
      method: "POST",
      headers: {
         "Content-Type": "application/json",
      },
      body: JSON.stringify({
         key: fileKey,
         parts,
      }),
   });

   return res.json();
};

/**
 * Abort Multipart Upload
 *
 * Call to the S3 API to cancel a running multi-part update.
 * @param {string} fileKey string location of file.
 * @param {object} file File object.
 * @param {string} uploadId Existing AWS upload ID.
 * @returns {object} S3 API json response.
 */
const abortMultipartUpload = async (fileKey, file, uploadId) => {
   const res = await fetch(`${restUrl}/multipart/abort`, {
      credentials: "include",
      method: "POST",
      headers: {
         "Content-Type": "application/json",
      },
      body: JSON.stringify({
         key: fileKey,
         uploadId,
      }),
   });

   return res.json();
};

/**
 * Complete Multipart Upload
 *
 * Finalise a multi-part update.
 * @param {string} fileKey string location of file.
 * @param {string} uploadId Existing AWS upload ID.
 * @param {string} parts Array of file parts in the S3 SDK format.
 * @returns {object} S3 API json response.
 */
const completeMultipartUpload = async (fileKey, uploadId, parts) => {
   const res = await fetch(`${restUrl}/multipart/complete`, {
      credentials: "include",
      method: "POST",
      headers: {
         "Content-Type": "application/json",
      },
      body: JSON.stringify({
         key: fileKey,
         uploadId,
         parts,
      }),
   });

   return res.json();
};

export {
   getPolicyRequest,
   getMultiPartPolicy,
   abortMultipartUpload,
   completeMultipartUpload,
};
