import React from "react";

/**
 * MasteringIcon
 *
 * @param {object} props - props object.
 * @param {object} props.className - styled components class name.
 * @returns {JSX.Element} MasteringIcon
 */
function MasteringIcon({ className, styles }) {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={styles}
    >
      <g clipPath="url(#clip0_8712_21489)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 3C5.44772 3 5 3.44772 5 4V5H4C3.44772 5 3 5.44772 3 6V8C3 8.55228 3.44772 9 4 9H5V20C5 20.5523 5.44772 21 6 21C6.55228 21 7 20.5523 7 20V9H8C8.55228 9 9 8.55228 9 8V6C9 5.44772 8.55228 5 8 5H7V4C7 3.44772 6.55228 3 6 3ZM12 3C11.4477 3 11 3.44772 11 4V14H10C9.44772 14 9 14.4477 9 15V17C9 17.5523 9.44772 18 10 18H11V20C11 20.5523 11.4477 21 12 21C12.5523 21 13 20.5523 13 20V18H14C14.5523 18 15 17.5523 15 17V15C15 14.4477 14.5523 14 14 14H13V4C13 3.44772 12.5523 3 12 3ZM17 4C17 3.44772 17.4477 3 18 3C18.5523 3 19 3.44772 19 4V8H20C20.5523 8 21 8.44772 21 9V11C21 11.5523 20.5523 12 20 12H19V20C19 20.5523 18.5523 21 18 21C17.4477 21 17 20.5523 17 20V12H16C15.4477 12 15 11.5523 15 11V9C15 8.44772 15.4477 8 16 8H17V4Z"
          fill="currentcolor"
        />
      </g>
      <defs>
        <clipPath id="clip0_8712_21489">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MasteringIcon;
