const { Button } = require("@mui/material");
const { withStyles } = require("@mui/styles");

const DarkButton = withStyles(() => ({
   root: {
      color: "white",
      backgroundColor: "black",
      "&:hover": {
         backgroundColor: "black",
      },
   },
}))(Button);

export default DarkButton;
