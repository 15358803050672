import React from "react";

/**
 * GenericDAWIcon
 * @returns {JSX.Element} Generic DAW Icon
 */
function GenericDAWIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1422_25)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 2H3C2.45 2 2 2.45 2 3V21C2 21.55 2.45 22 3 22H21C21.55 22 22 21.55 22 21V3C22 2.45 21.55 2 21 2ZM12 17.21C9.33 17.21 7.17 15.05 7.17 12.38C7.17 9.71 9.33 7.55 12 7.55C13.13 7.55 14.16 7.96 14.99 8.61L13.15 10.45C12.93 10.67 12.93 11.02 13.15 11.24C13.37 11.46 13.72 11.46 13.94 11.24L15.78 9.4C16.43 10.22 16.83 11.24 16.83 12.37C16.83 15.04 14.67 17.2 12 17.2V17.21ZM17.07 17.46C16.92 17.61 16.69 17.61 16.54 17.46C16.39 17.31 16.39 17.08 16.54 16.93C17.75 15.72 18.42 14.1 18.42 12.39C18.42 8.85 15.54 5.96 11.99 5.96C8.44 5.96 5.56 8.84 5.56 12.39C5.56 14.12 6.24 15.74 7.46 16.95C7.61 17.1 7.61 17.33 7.46 17.48C7.39 17.55 7.29 17.59 7.19 17.59C7.09 17.59 7 17.55 6.93 17.48C5.56 16.12 4.8 14.31 4.8 12.38C4.8 8.42 8.02 5.2 11.98 5.2C15.94 5.2 19.16 8.42 19.16 12.38C19.16 14.3 18.41 16.1 17.06 17.45L17.07 17.46Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1422_25">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default GenericDAWIcon;
