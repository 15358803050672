import React from "react";
import { makeStyles } from "@mui/styles";
import IconHiringBanner from "_assets/Marketing/IconHiringBanner";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    bottom: "20px",
    right: "40px",
    zIndex: 1000,
    textDecoration: "none",
    "@media (max-width: 600px)": {
      right: "10px",
    },
    "&:hover > :nth-child(1)": {
      width: "280px",
      opacity: 0,
    },
    "&:hover > :nth-child(2)": {
      opacity: 1,
    },
  },
  firstContent: {
    padding: "10px 30px",
    "@media (max-width: 600px)": {
      padding: "10px",
    },
    backgroundColor: "#9D75FF",
    borderRadius: "30px",
    border: "1.5px solid #000",
    display: "flex",
    gap: "30px",
    width: "324px",
    transition: "opacity 0.2s ease-in-out, width 0.2s ease-in-out",
    marginBottom: "-46px",
  },
  secondContent: {
    padding: "10px 30px",
    backgroundColor: "#9D75FF",
    borderRadius: "30px",
    border: "1.5px solid #000",
    display: "flex",
    width: "250px",
    transition: "opacity 0.2s ease-in-out",
    opacity: 0,
    boxSizing: "border-box",
    marginLeft: "72px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
    color: "#fff",
  },
  firstText: {
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
    color: "black",
    whiteSpace: "nowrap",
  },
  secondText: {
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
    color: "#fff",
    whiteSpace: "nowrap",
    display: "flex",
  },
}));
/**
 * HiringBadge
 *  A React component representing a hiring badge with a link to open positions.
 *
 * @returns {JSX.Element} The rendered React component.
 */
function HiringBadge() {
  const classes = useStyles();
  return (
    <Link to="/info/careers" className={classes.root}>
      <div className={classes.firstContent}>
        <div className={classes.firstText}>We are hiring</div>
        <IconHiringBanner />
        <div className={classes.secondText}>
          Open positions
          <ArrowOutwardIcon />
        </div>
      </div>
      <div className={classes.secondContent}>
        Go to open positions
        <ArrowOutwardIcon />
      </div>
    </Link>
  );
}

export default HiringBadge;
