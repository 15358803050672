import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import {
   Button,
   Input,
   TableCell,
   Box,
   useTheme,
   Typography,
   useMediaQuery,
   Skeleton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AssignmentTurnedIn, Star } from "@mui/icons-material";
import { Link } from "react-router-dom";
import isStarred from "_utils/isStarred";
import { useUser } from "_utils/UserContext";
import { getNameWithoutExtension } from "_utils/fileTypeUtil/fileTypeUtil";
import isDawPackageType from "_utils/isDawPackageType";
import FileTypeIcon from "../../FileTypeIcon";

const useStyles = makeStyles((theme) => ({
   tableCell: {
      fontSize: theme.typography.small1.fontSize,
      padding: "6px 0px",
      width: "55%",
      minWidth: "200px", // Min width added due when you have a single project folder and you open its preview, the Go to Project button goes on top of everything else.
      whiteSpace: "nowrap",
   },
   customButton: {
      border: "none",
      borderRadius: "4px",
      display: "flex",
      "&:dragging": {
         visibility: "visible",
      },
   },
}));

/**
 * Table Cell Name.
 * @param {object} props props.
 * @param {object}  props.file current file/folder.
 * @param {Function} props.handleFinishRenaming callback to rename file/folder.
 * @param {string} props.renamingFolderValue text on the rename input.
 * @param {object} props.selectedRenaming file/folder being renamed.
 * @param {Function} props.setRenamingFolderValue sets string that shows in the input.
 * @param {Function} props.clickHandler click handler of the component, handles redirects and actions.
 * @param {boolean} props.isModal if the component is rendered inside a modal.
 * @param {boolean} props.loading if the state is loading.
 * @returns {JSX.Element} Table Cell Name.
 */
const TableCellName = ({
   file,
   handleFinishRenaming,
   renamingFolderValue,
   selectedRenaming,
   setRenamingFolderValue,
   clickHandler,
   isModal,
   loading,
}) => {
   const [user] = useUser();
   const theme = useTheme();
   const classes = useStyles();
   const isSmallScreen = useMediaQuery("(max-width:1190px)");
   // eslint-disable-next-line no-underscore-dangle
   const isFile = file?.__typename === "File";
   const folderInput = useRef(null);

   useEffect(() => {
      if (folderInput?.current) folderInput?.current?.select();
   }, [selectedRenaming]);
   const [hoverCell, setHoverCell] = useState(false);

   /**
    * renderNameCell
    *
    * Renders the cell name based on the many different types of file and folder that can be displayed including play buttons, project icons etc.
    * @returns {JSX.Element} Table cell name.
    */
   const renderNameCell = () => {
      /**
       * star
       * @returns {JSX.Element} Star icon in the correct color.
       */
      const star = () => {
         // eslint-disable-next-line no-underscore-dangle
         const fileType = file?.__typename?.toLowerCase();

         return (
            isStarred(file.id, fileType, user) && (
               <Star
                  style={{
                     fill: theme.palette.primary.main,
                  }}
               />
            )
         );
      };

      if (isFile || isDawPackageType({ file })) {
         return (
            <Box
               display="flex"
               flexDirection="row"
               alignItems="center"
               key={file.id}
            >
               {loading && (
                  <Skeleton
                     sx={{
                        minWidth: `${(Math.random() * 10 + 20).toFixed(0)}vw`,
                     }}
                  />
               )}
               {!loading && (
                  <>
                     <FileTypeIcon file={file} />
                     <Typography
                        style={{
                           textAlign: "initial",
                           marginLeft: 14,
                           marginRight: "auto",
                           fontSize: theme.typography.small1.fontSize,
                           width: "20vw",
                           cursor: "pointer",
                        }}
                        noWrap
                     >
                        {getNameWithoutExtension(file)}
                     </Typography>
                     <div style={{ marginRight: "12px" }}>{star()}</div>
                  </>
               )}
            </Box>
         );
      }

      // if item has a project and that project's folder id matches this items id - only root folder for project has AssignmentTurnedIn.
      if (file?.project && file?.project.folderId === file?.id) {
         return (
            <Box
               display="flex"
               flexDirection="row"
               alignItems="center"
               position="relative"
            >
               {loading && (
                  <Skeleton
                     sx={{
                        minWidth: `${(Math.random() * 10 + 20).toFixed(0)}vw`,
                     }}
                  />
               )}
               {!loading && (
                  <>
                     <AssignmentTurnedIn />
                     <div
                        style={{
                           display: "flex",
                           alignItems: "center",
                           flexGrow: 1,
                        }}
                     >
                        <Typography
                           style={{
                              textAlign: "initial",
                              marginLeft: 14,
                              marginRight: "auto",
                              fontSize: theme.typography.small1.fontSize,
                              cursor: "pointer",
                              flexGrow: 1,
                              maxWidth:
                                 !isModal && hoverCell
                                    ? `calc(100% - ${
                                         isSmallScreen ? "80" : "130"
                                      }px)`
                                    : "100%",
                              userSelect: "none",
                           }}
                           noWrap
                        >
                           {file.name}
                        </Typography>
                        {!isModal && hoverCell && (
                           <Button
                              sx={{
                                 height: 20,
                                 backgroundColor: "transparent",
                                 position: "absolute",
                                 right: "34px",
                                 top: "2px",
                              }}
                              style={
                                 isSmallScreen
                                    ? { padding: "0px", minWidth: 0 }
                                    : {}
                              }
                              color="primary"
                              component={Link}
                              size="small"
                              onClick={(e) => {
                                 e.stopPropagation();
                              }}
                              to={`/projects/${file?.project?.uri}`}
                           >
                              {isSmallScreen ? (
                                 <AssignmentTurnedIn />
                              ) : (
                                 "Go to Project"
                              )}
                           </Button>
                        )}
                     </div>

                     <div style={{ marginRight: "12px" }}>{star()}</div>
                  </>
               )}
            </Box>
         );
      }
      return (
         <Box display="flex" flexDirection="row" alignItems="center">
            {loading && (
               <Skeleton
                  sx={{ minWidth: `${(Math.random() * 10 + 20).toFixed(0)}vw` }}
               />
            )}
            {!loading && (
               <>
                  <FileTypeIcon file={file} />
                  <Typography
                     style={{
                        textAlign: "initial",
                        marginLeft: 14,
                        marginRight: "auto",
                        fontSize: theme.typography.small1.fontSize,
                        cursor: "pointer",
                     }}
                     noWrap
                  >
                     {file.name}
                  </Typography>
                  <div style={{ marginRight: "12px" }}>{star()}</div>
               </>
            )}
         </Box>
      );
   };

   return (
      <TableCell
         style={isModal ? { padding: "8px 10px" } : {}}
         className={classes.tableCell}
         key={`${file?.uri}-table-cell-name`}
         onMouseEnter={() => setHoverCell(true)}
         onMouseLeave={() => setHoverCell(false)}
      >
         {selectedRenaming?.uri === file?.uri ? (
            <Input
               inputRef={folderInput}
               id="rename-folder"
               value={renamingFolderValue}
               inputProps={{
                  maxLength: "191",
               }}
               onKeyDown={(e) => {
                  if (!e?.target?.checkValidity()) {
                     return;
                  }

                  if (e.key === "Enter") {
                     e.preventDefault();
                     const newName = e?.target?.value;
                     handleFinishRenaming(newName, file);
                  }
               }}
               onBlur={(e) => {
                  if (!e?.target?.checkValidity()) {
                     return;
                  }

                  e.preventDefault();
                  const newName = e?.target?.value;
                  handleFinishRenaming(newName, file);
               }}
               onChange={(e) => {
                  setRenamingFolderValue(e?.target?.value);
               }}
            />
         ) : (
            <div
               // TODO: this should be a link or a button for accessibility.
               role="button"
               tabIndex={0}
               onClick={clickHandler}
               onKeyDown={clickHandler}
               className={classes.customButton}
               disabled={loading}
            >
               <Typography width="100%" noWrap>
                  {renderNameCell()}
               </Typography>
            </div>
         )}
      </TableCell>
   );
};

TableCellName.propTypes = {
   file: PropTypes.shape({
      __typename: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      project: PropTypes.shape({
         folderId: PropTypes.string,
         uri: PropTypes.string,
      }),
      renaming: PropTypes.bool,
      starred: PropTypes.number,
      uri: PropTypes.string,
      watchedPath: PropTypes.string,
      new: PropTypes.bool,
      parentFolderId: PropTypes.string,
      parentFolder: PropTypes.shape({
         projectId: PropTypes.string,
      }),
      projectId: PropTypes.string,
   }).isRequired,
   handleFinishRenaming: PropTypes.func.isRequired,
   renamingFolderValue: PropTypes.string.isRequired,
   selectedRenaming: PropTypes.shape({
      uri: PropTypes.string,
   }),
   setRenamingFolderValue: PropTypes.func.isRequired,
   clickHandler: PropTypes.func.isRequired,
   isModal: PropTypes.bool,
   loading: PropTypes.bool,
};

TableCellName.defaultProps = {
   selectedRenaming: null,
   isModal: false,
   loading: false,
};

export default TableCellName;
