import React from "react";
import useStatsMutation from "_utils/useStatsMutation";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, TextField } from "@mui/material";
import { useUser } from "_utils/UserContext";
import PropTypes from "prop-types";

/**
 * DownloadLinkEmailForm
 * Form with email input for the download link modal.
 * @param {object} props - props
 * @param {Function} props.setSubmit - setSubmit set state to true when form is submitted. Used to show success message.
 * @returns {JSX.Element} React component.
 */
const DownloadLinkEmailForm = ({ setSubmit }) => {
   const [user] = useUser();

   const statsMutation = useStatsMutation();

   const schema = Yup.object().shape({
      email: Yup.string().email().required(),
   });
   return (
      <Formik
         initialValues={{
            email: user?.email || "",
         }}
         validationSchema={schema}
         onSubmit={({ email }, { resetForm }) => {
            statsMutation({
               statsId: "UserRequestDesktopDownloadLink",
               metadata: JSON.stringify({ email }),
            });
            resetForm();
            setSubmit(true);
         }}
         validateOnBlur
      >
         {({ handleChange, handleSubmit, values, errors, touched }) => (
            <form onSubmit={handleSubmit}>
               <TextField
                  placeholder="Enter your email"
                  value={values.email}
                  onChange={handleChange}
                  name="email"
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
               />
               <Button
                  type="submit"
                  sx={{
                     background: "black",
                     color: "white",
                     height: 40,
                     mt: "10px",
                     "&:hover": {
                        background: "black",
                     },
                  }}
                  variant="contained"
               >
                  Send me download link
               </Button>
            </form>
         )}
      </Formik>
   );
};

DownloadLinkEmailForm.propTypes = {
   setSubmit: PropTypes.func.isRequired,
};

export default DownloadLinkEmailForm;
