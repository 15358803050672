import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MetaTags from "_components/MetaTags";
import isElectronHook from "_utils/isElectron";
import getConfig from "_utils/getConfig";
import ghostApi from "../_utils/ghostContentApi";
import isItDark from "../_utils/isItDark";

const GreyButton = withStyles((theme) => ({
   root: {
      color: "white",
      margin: "30px",
      backgroundColor: theme.palette.secondary.shade30,
      "&:hover": {
         backgroundColor: theme.palette.secondary.shade30,
      },
   },
}))(Button);

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundSize: "cover",
      height: "100%",
      position: "relative",
      display: "flex",
      textAlign: "center",
      alignItems: "center",
   },
   // Click events are eaten by the -webkit-app-region: drag; so we need to disable it for anything that is interactive.
   noDrag: {
      ...theme.noDrag,
   },
   white: {
      color: "white",
   },
   imageMain: {
      position: "absolute",
      zIndex: -1,
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundSize: "cover",
      backgroundPosition: "center center",
      whiteSpace: "nowrap",
      overflow: "hidden",
      background: `linear-gradient(90deg, #FFDCC9 0%, rgba(255, 220, 201, 0) 100%),
      linear-gradient(180deg, #3EF1D1 0%, rgba(62, 241, 209, 0) 100%),
       #6147FF`,
   },
   excerpt: {
      lineHeight: "24px",
      fontSize: theme.typography.h6.fontSize,
      margin: "30px",
   },
   title: {
      fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
      fontWeight: 800,
      lineHeight: "48px",
      fontSize: theme.typography.big1.fontSize,
      margin: "30px",
   },
}));

/**
 * Sign In / Sign Up Featured Post
 *
 * Shows the featured post on the Sign In and Sign Up pages.
 * @param {object} props props
 * @param {string} props.type SignIn or SignUp tag to search for for post or page to show.
 * @returns {JSX.Element} Shows the right hand column showing a featured post or page.
 */
const SignInSignUpFeaturedPost = ({ type }) => {
   const classes = useStyles();
   const isElectron = isElectronHook();
   const { domain } = getConfig();

   const [post, setPost] = useState(null);
   const [isPage, setIsPage] = useState(false);
   const [whiteText, setWhiteText] = useState(false);

   useEffect(() => {
      ghostApi.pages
         .browse({ filter: `tag:${type}` })
         .then((pages) => {
            // Search for pages.
            let taggedPage = null;
            if (pages[0]) {
               // eslint-disable-next-line prefer-destructuring
               taggedPage = pages[0];
            }

            ghostApi.posts
               .browse({ filter: `tag:${type}` })
               .then((posts) => {
                  let taggedPost = null;
                  if (posts[0]) {
                     // eslint-disable-next-line prefer-destructuring
                     taggedPost = posts[0];
                  }

                  let displayPost = null;
                  if (taggedPage && !taggedPost) {
                     displayPost = taggedPage;
                     setIsPage(true);
                  } else if (taggedPost && !taggedPage) {
                     displayPost = taggedPost;
                  } else if (
                     taggedPage.published_at > taggedPost.published_at
                  ) {
                     displayPost = taggedPage;
                     setIsPage(true);
                  } else if (
                     taggedPage.published_at < taggedPost.published_at
                  ) {
                     displayPost = taggedPost;
                  }

                  if (displayPost) {
                     if (displayPost?.feature_image) {
                        isItDark(displayPost?.feature_image, (isDark) => {
                           setWhiteText(isDark);
                        });
                     }
                     setPost(displayPost);
                  }
               })
               .catch((err) => {
                  throw new Error(err);
               });
         })
         .catch((err) => {
            throw new Error(err);
         });
   }, []);

   return (
      <div className={classes.root}>
         <MetaTags
            title={type === "signup" ? "Sign Up for Aux" : "Sign In to Aux"}
            description={
               type === "signup"
                  ? "Enter your details to create an account and start your journey with Aux."
                  : "Welcome back to Aux - your best friend in the music industry."
            }
            image={post?.feature_image}
         />
         <div
            className={classes.imageMain}
            style={{
               backgroundImage: `url(${post?.feature_image})`,
               filter: whiteText
                  ? "brightness(70%) contrast(50%)"
                  : "brightness(130%) contrast(50%)",
            }}
         />
         <Container>
            <Typography
               className={`${classes.title} ${whiteText ? classes.white : ""}`}
               variant="h5"
            >
               {post?.title}
            </Typography>
            <Typography
               className={`${classes.excerpt} ${
                  whiteText ? classes.white : ""
               }`}
            >
               {post?.excerpt}
            </Typography>

            <GreyButton
               component="a"
               href={`${domain}/${isPage ? "info" : "connect"}/${post?.slug}`}
               variant="contained"
               target={isElectron ? "_blank" : "_self"}
               className={classes.noDrag}
            >
               Read More
            </GreyButton>
         </Container>
      </div>
   );
};

SignInSignUpFeaturedPost.propTypes = {
   type: PropTypes.string.isRequired,
};

export default SignInSignUpFeaturedPost;
