import gql from "graphql-tag";
import { client, wsClient } from "./client";

/**
 * Clear cache.
 *
 * Clear cache and close sockets.
 */
export const clearCache = async () => {
   await client.stop();
   await client.resetStore();
   await wsClient.close();
};

// This could be file basic fields fragment, that would reduce the file size, and we would only need to update the fields in a fragment instead of on all the queries.
// The use of fragments could avoid many errors of missing fields while updating the cache
const FILE_BASIC_FIELDS = gql`
   fragment FILE_BASIC_FIELDS on File {
      id
      profileId
      uri
      originalName
      mimeType
      name
      path
      folderId
      failed
      projectId
      signedUrl
      previewSignedUrl
      waveformWhiteName
      waveformBlueName
      waveformWhiteUrlLarge
      waveformBlueUrlLarge
      waveformSlimUrl
      processingWaveform
      # waveformProcessingTime
      waveformWhiteUrl
      waveformBlueUrl
      size
      trashed
      formattedSize
      viewCount
      previewCount
      previewCountRemaining
      previewTime
      previewSkipCount
      downloadCount
      downloadCountRemaining
      watermark
      encodeDownload
      encodeDownloadBitrate
      processingPreview
      # previewProcessingTime
      processingDownload
      downloadProcessingTime
      modifiedAt
      createdAt
      updatedAt
      deletedAt
      isStem
      isBouncedown
      dawFileId
      dawFolderId
      audioRegions
      color
      audioLength
      conflictedPath
      conflictedUrl
      conflictedMdate
      locked
      version
      versionComment
      restoredFromVersionFileId
      rootVersionFileId
      nextVersionFileId
   }
`;

const FOLDER_FIELDS = gql`
   fragment FOLDER_FIELDS on Folder {
      id
      userId
      parentFolderId
      projectId
      profileId
      numberFiles
      name
      originalName
      trashed
      uri
      path
      size
      createdAt
      updatedAt
      modifiedAt
      deletedAt
      version
      versionComment
      restoredFromVersionFolderId
      rootVersionFolderId
      nextVersionFolderId
      locked
   }
`;
const MESSAGE_FIELDS = gql`
   fragment MESSAGE_FIELDS on Message {
      id
      replyToMessageId
      threadMessageId
      read
      edited
      resolved
      profileId
      fileId
      folderId
      projectId
      profileName
      email
      photoUrlOrGravatar
      uri
      message
      expiry
      allow
      type
      viewCount
      createdAt
      updatedAt
      starred
      trashed
      timestamp
      failed
   }
`;

export const SIGN_UP_USER = gql`
   mutation signup(
      $name: String!
      $email: String!
      $password: String
      $appleAuthUniqueId: String
      $optInMarketing: Boolean
      $isPhoneMobile: Boolean
   ) {
      signup(
         name: $name
         email: $email
         password: $password
         optInMarketing: $optInMarketing
         appleAuthUniqueId: $appleAuthUniqueId
         isPhoneMobile: $isPhoneMobile
      ) {
         admin
         jwt
         email
         id
         trialEndsAt
         stripeId
         cardBrand
         cardLastFour
         currentBillingPlan
         emailVerifiedAt
         emailVerifiedAt
         hasCompletedWelcome
         profile {
            uri
         }
      }
   }
`;

export const LOG_IN_USER = gql`
   mutation signin($email: String!, $password: String!) {
      signin(email: $email, password: $password) {
         admin
         jwt
         email
         id
         trialEndsAt
         stripeId
         cardBrand
         cardLastFour
         currentBillingPlan
         emailVerifiedAt
         emailVerifiedAt
         hasCompletedWelcome
         optInMarketing
         profile {
            uri
         }
      }
   }
`;

export const UPDATE_CONTACT_INFO = gql`
   mutation updateContactInfo(
      $email: String!
      $name: String!
      $id: ID
      $teamId: ID
   ) {
      updateContactInfo(email: $email, name: $name, id: $id, teamId: $teamId) {
         admin
         jwt
         email
         profile {
            id
            phoneNumber
            phoneGuide
            emailGuide
            addressGuide
            backgroundImageSignedUrl
            guide
            name
            email
            uri
            profileUrl
            description
            photoUrl
            photoUrlOrGravatar
            backgroundStyle
            backgroundImageUrl
            primaryColor
            secondaryColor
            uploadForm
         }
         id
         trialEndsAt
         stripeId
         cardBrand
         cardLastFour
         currentBillingPlan
         emailVerifiedAt
         subscriptions {
            id
            name
            stripeStatus
            stripePlan
            stripeId
            currentPlan
            googlePayId
            applePayId
            createdAt
            updatedAt
         }

         emailVerifiedAt
         devices {
            id
            deviceId
            ip
            active
            userAgent
            notifications
         }
         profiles {
            id
            phoneNumber
            phoneGuide
            emailGuide
            addressGuide
            backgroundImageSignedUrl
            guide
            name
            email
            uri
            profileUrl
            description
            photoUrl
            photoUrlOrGravatar
            backgroundStyle
            backgroundImageUrl
            primaryColor
            secondaryColor
            uploadForm
         }
      }
   }
`;
export const UPDATE_EMAIL_SETTINGS = gql`
   mutation updateEmailSettings($emails: [String], $primaryEmail: String) {
      updateEmailSettings(emails: $emails, primaryEmail: $primaryEmail) {
         admin
         jwt
         email
         profile {
            id
            phoneNumber
            phoneGuide
            emailGuide
            addressGuide
            backgroundImageSignedUrl
            name
            email
            uri
            profileUrl
            description
            photoUrl
            photoUrlOrGravatar
            backgroundStyle
            backgroundImageUrl
            primaryColor
            secondaryColor
            uploadForm

            tags {
               id
               name
               uri
            }
            emails {
               email
               verified
            }
         }
         id
         trialEndsAt
         stripeId
         cardBrand
         cardLastFour
         currentBillingPlan
         emailVerifiedAt
         subscriptions {
            id
            name
            stripeStatus
            stripePlan
            stripeId
            currentPlan
            googlePayId
            applePayId
            createdAt
            updatedAt
         }
         emailVerifiedAt
         devices {
            deviceId
            notifications
         }
         hasCompletedWelcome
      }
   }
`;
export const REMOVE_EMAIL = gql`
   mutation removeEmail($email: String) {
      removeEmail(email: $email) {
         admin
         jwt
         email
         profile {
            id
            phoneNumber
            phoneGuide
            emailGuide
            addressGuide
            backgroundImageSignedUrl
            name
            email
            uri
            profileUrl
            description
            photoUrl
            photoUrlOrGravatar
            backgroundStyle
            backgroundImageUrl
            primaryColor
            secondaryColor
            uploadForm

            tags {
               id
               name
               uri
            }
            emails {
               email
               verified
            }
         }
         id
         trialEndsAt
         stripeId
         cardBrand
         cardLastFour
         currentBillingPlan
         emailVerifiedAt
         subscriptions {
            id
            name
            stripeStatus
            stripePlan
            stripeId
            currentPlan
            googlePayId
            applePayId
            createdAt
            updatedAt
         }
         emailVerifiedAt
         devices {
            id
            deviceId
            ip
            active
            userAgent
            notifications
         }
         hasCompletedWelcome

         limits {
            guideAccessLeft
            totalStorageUsed
            guideAccess
            mastersCreated
            soundsDownloaded
         }
      }
   }
`;
export const UPDATE_PROFILE_INFO = gql`
   mutation updateProfileInfo(
      $tags: [String]
      $deletedTags: [String]
      $bio: String
      $profileLink: String
      $id: ID
      $teamId: ID
   ) {
      updateProfileInfo(
         tags: $tags
         deletedTags: $deletedTags
         bio: $bio
         profileLink: $profileLink
         id: $id
         teamId: $teamId
      ) {
         admin
         jwt
         email
         profile {
            id
            phoneNumber
            phoneGuide
            emailGuide
            addressGuide
            backgroundImageSignedUrl
            guide
            name
            email
            uri
            profileUrl
            description
            photoUrl
            photoUrlOrGravatar
            backgroundStyle
            backgroundImageUrl
            primaryColor
            secondaryColor
            uploadForm
         }
         id
         trialEndsAt
         stripeId
         cardBrand
         cardLastFour
         currentBillingPlan
         emailVerifiedAt
         subscriptions {
            id
            name
            stripeStatus
            stripePlan
            stripeId
            currentPlan
            googlePayId
            applePayId
            createdAt
            updatedAt
         }
         emailVerifiedAt
         devices {
            id
            deviceId
            ip
            active
            userAgent
            notifications
         }
         hasCompletedWelcome

         limits {
            guideAccessLeft
            totalStorageUsed
            guideAccess
            mastersCreated
            soundsDownloaded
         }
      }
   }
`;

export const UPDATE_PROFILE_IMG = gql`
   mutation updateProfileImg($image: String!, $teamId: ID) {
      updateProfileImg(image: $image, teamId: $teamId) {
         admin
         jwt
         email
         profile {
            id
            phoneNumber
            phoneGuide
            emailGuide
            addressGuide
            backgroundImageSignedUrl
            guide
            name
            email
            uri
            profileUrl
            description
            photoUrl
            photoUrlOrGravatar
            backgroundStyle
            backgroundImageUrl
            primaryColor
            secondaryColor
            uploadForm
         }
         id
         trialEndsAt
         stripeId
         cardBrand
         cardLastFour
         currentBillingPlan
         emailVerifiedAt
         subscriptions {
            id
            name
            stripeStatus
            stripePlan
            stripeId
            currentPlan
            googlePayId
            applePayId
            createdAt
            updatedAt
         }
         emailVerifiedAt
         devices {
            id
            deviceId
            ip
            active
            userAgent
            notifications
         }
         hasCompletedWelcome

         limits {
            guideAccessLeft
            totalStorageUsed
            guideAccess
            mastersCreated
            soundsDownloaded
         }
      }
   }
`;

export const REMOVE_PROFILE_IMG = gql`
   mutation removeProfileImg($id: ID, $isBackground: Boolean, $teamId: ID) {
      removeProfileImg(id: $id, isBackground: $isBackground, teamId: $teamId) {
         admin
         jwt
         email
         profile {
            id
            phoneNumber
            phoneGuide
            emailGuide
            addressGuide
            backgroundImageSignedUrl
            guide
            name
            email
            uri
            profileUrl
            description
            photoUrl
            photoUrlOrGravatar
            backgroundStyle
            backgroundImageUrl
            primaryColor
            secondaryColor
            uploadForm
         }
         id
         trialEndsAt
         stripeId
         cardBrand
         cardLastFour
         currentBillingPlan
         emailVerifiedAt
         subscriptions {
            id
            name
            stripeStatus
            stripePlan
            stripeId
            currentPlan
            googlePayId
            applePayId
            createdAt
            updatedAt
         }
         emailVerifiedAt
         devices {
            id
            deviceId
            ip
            active
            userAgent
            notifications
         }
         hasCompletedWelcome

         limits {
            guideAccessLeft
            totalStorageUsed
            guideAccess
            mastersCreated
            soundsDownloaded
         }
      }
   }
`;
export const UPDATE_PASSWORD = gql`
   mutation updatePassword(
      $id: ID!
      $currentPassword: String!
      $password: String!
      $confirmPassword: String!
   ) {
      updatePassword(
         id: $id
         currentPassword: $currentPassword
         password: $password
         confirmPassword: $confirmPassword
      ) {
         admin
         jwt
         email
         profile {
            id
            phoneNumber
            phoneGuide
            emailGuide
            addressGuide
            backgroundImageSignedUrl
            guide
            name
            email
            uri
            profileUrl
            description
            photoUrl
            photoUrlOrGravatar
            backgroundStyle
            backgroundImageUrl
            primaryColor
            secondaryColor
            uploadForm
         }
         id
         trialEndsAt
         stripeId
         cardBrand
         cardLastFour
         currentBillingPlan
         emailVerifiedAt
         subscriptions {
            id
            name
            stripeStatus
            stripePlan
            stripeId
            currentPlan
            googlePayId
            applePayId
            createdAt
            updatedAt
         }
         emailVerifiedAt
         devices {
            id
            deviceId
            ip
            active
            userAgent
            notifications
         }
         hasCompletedWelcome

         limits {
            guideAccessLeft
            totalStorageUsed
            guideAccess
            mastersCreated
            soundsDownloaded
         }
      }
   }
`;

// TODO: make sure fields added here are also added to Sign In and Sign Up using a fragment.
export const GET_CURRENT_USER = gql`
   query currentUser {
      currentUser {
         admin
         jwt
         email
         beta
         profile {
            id
            phoneNumber
            phoneGuide
            emailGuide
            addressGuide
            notifications {
               id
            }
            notificationOptOut {
               channel
               type
               enabled
            }
            backgroundImageSignedUrl
            guide
            categories {
               id
               name
               rank
            }
            name
            email
            uri
            profileUrl
            description
            photoUrl
            photoUrlOrGravatar
            backgroundStyle
            backgroundImageUrl
            primaryColor
            secondaryColor
            uploadForm

            connections {
               sent {
                  senderProfile {
                     id
                     name
                     uri
                     photoUrl
                     photoUrlOrGravatar
                  }
                  receiverProfile {
                     id
                     name
                     uri
                     photoUrl
                     photoUrlOrGravatar
                  }
                  id
                  accepted
                  declined
                  createdAt
                  updatedAt
               }
               received {
                  senderProfile {
                     id
                     name
                     uri
                     photoUrl
                     photoUrlOrGravatar
                  }
                  receiverProfile {
                     name
                     uri
                     photoUrl
                     photoUrlOrGravatar
                  }
                  id
                  accepted
                  declined
                  createdAt
                  updatedAt
               }
            }
            tags {
               id
               name
               uri
               awardsCategoryTitle
               awardsCategorySubtitle
               awardsCategoryDescription
               awardsYear
               highlightedGuide
            }
            emails {
               email
               verified
            }
            projects {
               id
               profileId
               title
               type
               uri
               description
               publicFiles
               createdAt
               updatedAt
            }
            contacts {
               id
               name
               email
               phoneNumber
               hasProfile
            }
            createdAt
            updatedAt
         }
         id
         trialEndsAt
         stripeId
         cardBrand
         cardLastFour
         currentBillingPlan
         emailVerifiedAt
         subscriptions {
            id
            name
            stripeStatus
            stripePlan
            stripeId
            currentPlan
            googlePayId
            applePayId
            createdAt
            updatedAt
         }
         emailVerifiedAt
         devices {
            id
            deviceId
            ip
            active
            userAgent
            notifications
         }
         currentDevice {
            id
            deviceId
            syncedAt
         }
         hasCompletedWelcome
         limits {
            guideAccessLeft
            totalStorageUsed
            guideAccess
            mastersCreated
            soundsDownloaded
         }
         optInMarketing
         profiles {
            id
            phoneNumber
            phoneGuide
            emailGuide
            addressGuide
            backgroundImageSignedUrl
            guide
            name
            email
            uri
            profileUrl
            description
            photoUrl
            photoUrlOrGravatar
            backgroundStyle
            backgroundImageUrl
            primaryColor
            secondaryColor
            uploadForm
         }
         starredItems {
            projects
            folders
            files
         }
      }
   }
`;

export const GET_USER_PROFILE = gql`
   query profileDetails($uri: String) {
      profileDetails(uri: $uri) {
         id
         name
         email
         uri
         guide
         awardNominee
         profileUrl
         description
         photoUrl
         photoUrlOrGravatar
         backgroundImageSignedUrl
         backgroundStyle
         backgroundImageUrl
         primaryColor
         secondaryColor
         uploadForm

         phoneGuide
         emailGuide
         addressGuide
         tags {
            id
            name
            uri
            awardsCategoryTitle
            awardsCategorySubtitle
            awardsCategoryDescription
            awardsYear
            highlightedGuide
         }
         categories {
            id
            name
         }
         contacts {
            phoneNumber
            email
         }
         emails {
            email
            verified
         }
         user {
            subscriptions {
               id
               name
               stripeStatus
               stripePlan
               stripeId
               currentPlan
               googlePayId
               applePayId
            }
            currentBillingPlan
         }
      }
   }
`;
export const GET_USER_PROFILE_PUBLIC = gql`
   query profileDetailsPublic($uri: String!) {
      profileDetailsPublic(uri: $uri) {
         id
         name
         uri
         guide
         awardNominee
         profileUrl
         description
         photoUrl
         photoUrlOrGravatar
         primaryColor
         phoneGuide
         emailGuide
         addressGuide
         tags {
            id
            name
            uri
            awardsCategoryTitle
            awardsCategorySubtitle
            awardsCategoryDescription
            awardsYear
         }
         categories {
            id
            name
         }
      }
   }
`;

export const GET_PROFILES = gql`
   query getProfiles(
      $offset: Int
      $limit: Int
      $tags: [String]
      $sorting: String!
      $search: String
   ) {
      getProfiles(
         offset: $offset
         limit: $limit
         tags: $tags
         sorting: $sorting
         search: $search
      ) {
         id
         guide
         awardNominee
         name
         uri
         profileUrl
         description
         photoUrl
         photoUrlOrGravatar
         tags {
            id
            name
            uri
            awardsCategoryTitle
            awardsCategorySubtitle
            awardsCategoryDescription
            awardsYear
            highlightedGuide
         }
      }
   }
`;

export const GET_PROFILE_NOMINATIONS = gql`
   query getProfileNominations($profileId: String!) {
      getProfileNominations(profileId: $profileId) {
         tag {
            id
            name
            awardsYear
         }
         awardsNominationTitle
         awardsNominationDescription
         awardsNominationUrl
      }
   }
`;

export const GET_FEED_MESSAGE_PAGINATION = gql`
   ${FILE_BASIC_FIELDS}
   query feedMessagesPagination($offset: Int, $limit: Int, $search: String) {
      feedMessagesPagination(offset: $offset, limit: $limit, search: $search) {
         id
         typeName
         uniqueId
         createdAt
         icon
         title
         projectId
         body
         actionText
         actionUrl
         read
         file {
            ...FILE_BASIC_FIELDS
         }
         project {
            id
            title
            uri
            pendingContacts {
               email
            }
            acceptedContacts {
               uri
            }
         }
         profile {
            photoUrlOrGravatar
            name
            uri
         }
         profileConnectionIntent {
            photoUrlOrGravatar
            name
            uri
         }
         messageSentBy {
            photoUrlOrGravatar
            name
            uri
         }
      }
   }
`;
export const GET_INVOICES = gql`
   query {
      getInvoices {
         id
         userId
         total
         createdAt
         url
      }
   }
`;
export const GET_CONTACTS = gql`
   query userContacts($offset: Int, $limit: Int) {
      getContacts(offset: $offset, limit: $limit) {
         id
         name
         email
         phoneNumber
         hasProfile
      }
   }
`;

// export const GET_FILE = gql`
//    ${FILE_BASIC_FIELDS}
//    ${FOLDER_FIELDS}
//    query getFile($fileUri: String) {
//       getFile(fileUri: $fileUri) {
//          ...FILE_BASIC_FIELDS
//          project {
//             id
//             uri
//          }
//          dawFile {
//             ...FILE_BASIC_FIELDS
//          }
//          bouncedown {
//             ...FILE_BASIC_FIELDS
//          }
//          stems {
//             ...FILE_BASIC_FIELDS
//             messages {
//                id
//                fileId
//                profileId
//                threadMessageId
//                message
//                timestamp
//                profileName
//                photoUrlOrGravatar
//                resolved
//                edited
//                createdAt
//                updatedAt
//                replies {
//                   id
//                   replyToMessageId
//                   threadMessageId
//                   profileId
//                   email
//                   uri
//                   profileName
//                   photoUrlOrGravatar
//                   message
//                   fileId
//                   expiry
//                   allow
//                   edited
//                   timestamp
//                   createdAt
//                   updatedAt
//                   replyToMessage {
//                      id
//                      profileName
//                      threadMessageId
//                   }
//                   files {
//                      ...FILE_BASIC_FIELDS
//                   }
//                }
//             }
//          }
//          versions {
//             ...FILE_BASIC_FIELDS
//             profile {
//                name
//                photoUrlOrGravatar
//             }
//          }
//          folder {
//             ...FOLDER_FIELDS
//             path
//             versions {
//                ...FOLDER_FIELDS
//                path
//                dawFile {
//                   ...FILE_BASIC_FIELDS
//                }
//                profile {
//                   name
//                   photoUrlOrGravatar
//                }
//             }
//          }
//       }
//    }
// `;

export const GET_FILE_FOLDER = gql`
   ${FILE_BASIC_FIELDS}
   ${FOLDER_FIELDS}
   ${MESSAGE_FIELDS}
   query getFileFolder($fileFolderURI: String) {
      getFileFolder(fileFolderURI: $fileFolderURI) {
         file {
            ...FILE_BASIC_FIELDS
            project {
               id
               uri
            }
            dawFile {
               ...FILE_BASIC_FIELDS
            }
            bouncedown {
               ...FILE_BASIC_FIELDS
            }
            stems {
               ...FILE_BASIC_FIELDS
               messages {
                  ...MESSAGE_FIELDS
                  replies {
                     ...MESSAGE_FIELDS
                     replyToMessage {
                        ...MESSAGE_FIELDS
                     }
                     files {
                        ...FILE_BASIC_FIELDS
                     }
                  }
               }
            }
            versions {
               ...FILE_BASIC_FIELDS

               profile {
                  name
                  photoUrlOrGravatar
               }
            }
            folder {
               ...FOLDER_FIELDS
               path
               versions {
                  ...FOLDER_FIELDS
                  path
                  profile {
                     name
                     photoUrlOrGravatar
                  }
               }
            }
         }
         folder {
            ...FOLDER_FIELDS
            project {
               id
               uri
            }
            folders {
               ...FOLDER_FIELDS
            }
            files {
               ...FILE_BASIC_FIELDS
            }
            bouncedown {
               ...FILE_BASIC_FIELDS
            }
            stems {
               ...FILE_BASIC_FIELDS
               messages {
                  ...MESSAGE_FIELDS
                  replies {
                     ...MESSAGE_FIELDS
                     replyToMessage {
                        ...MESSAGE_FIELDS
                     }
                     files {
                        ...FILE_BASIC_FIELDS
                     }
                  }
               }
            }
            parentFolder {
               ...FOLDER_FIELDS
            }
         }
      }
   }
`;

export const GET_FILES_INFO = gql`
   ${FILE_BASIC_FIELDS}
   ${FOLDER_FIELDS}
   ${MESSAGE_FIELDS}
   query getFilesInfo($fileIds: [ID], $fileUris: [String], $daw: Boolean) {
      getFilesInfo(fileIds: $fileIds, fileUris: $fileUris, daw: $daw) {
         ...FILE_BASIC_FIELDS

         project {
            id
            uri
         }
         dawFile {
            ...FILE_BASIC_FIELDS
         }
         bouncedown {
            ...FILE_BASIC_FIELDS
         }
         stems {
            ...FILE_BASIC_FIELDS
            messages {
               ...MESSAGE_FIELDS
               replies {
                  ...MESSAGE_FIELDS
                  replyToMessage {
                     ...MESSAGE_FIELDS
                  }
                  files {
                     ...FILE_BASIC_FIELDS
                  }
               }
            }
         }
         versions {
            ...FILE_BASIC_FIELDS

            profile {
               name
               photoUrlOrGravatar
            }
         }
         folder {
            ...FOLDER_FIELDS
            path
            versions {
               ...FOLDER_FIELDS
               path
               profile {
                  name
                  photoUrlOrGravatar
               }
            }
         }
      }
   }
`;

export const GET_TAGS_BY_URI = gql`
   query getTagsByUri($uri: [String!]!) {
      getTagsByUri(uri: $uri) {
         id
         name
         uri
      }
   }
`;
export const GET_TAGS_FILE_FOLDER = gql`
   query getTagsFileFolder($id: ID!, $isFile: Boolean) {
      getTagsFileFolder(id: $id, isFile: $isFile) {
         id
         name
         uri
      }
   }
`;
export const DELETE_TAG_FILE_FOLDER = gql`
   mutation deleteTagFileFolder(
      $idFileFolder: ID!
      $isFile: Boolean
      $tagId: ID!
   ) {
      deleteTagFileFolder(
         idFileFolder: $idFileFolder
         isFile: $isFile
         tagId: $tagId
      ) {
         id
         name
         uri
      }
   }
`;
export const GET_TAGS = gql`
   query getTags($name: String, $offset: Int, $limit: Int) {
      getTags(name: $name, offset: $offset, limit: $limit) {
         id
         name
         uri
         awardsYear
         awardsCategoryTitle
         awardsCategorySubtitle
         awardsCategoryDescription
         highlightedGuide
      }
   }
`;
export const GET_FEATURED_TAGS = gql`
   query getFeaturedTags {
      getFeaturedTags {
         id
         name
         uri
      }
   }
`;
export const GET_FEATURED_LOCATION_TAGS = gql`
   query getFeaturedLocationTags {
      getFeaturedLocationTags {
         id
         name
         uri
      }
   }
`;
export const GET_FEATURED_GENRE_TAGS = gql`
   query getFeaturedGenreTags {
      getFeaturedGenreTags {
         id
         name
         uri
      }
   }
`;
export const UPDATE_PROFILE_CATEGORIES = gql`
   mutation updateProfileCategories(
      $categories: [String]
      $deletedCategories: [String]
      $id: ID
      $teamId: ID
   ) {
      updateProfileCategories(
         categories: $categories
         deletedCategories: $deletedCategories
         id: $id
         teamId: $teamId
      )
   }
`;
export const GET_CATEGORIES = gql`
   query getCategories($name: String) {
      getCategories(name: $name) {
         id
         name
      }
   }
`;
export const GET_PROFILE_STATS = gql`
   query getProfileStats {
      getProfileStats {
         soundsGenerationCount
         masterTrackCount
         fileCount
      }
   }
`;
export const TAG_USER_VERIFY = gql`
   query tagUserVerify($tagList: [String], $userList: [String]) {
      tagUserVerify(tagList: $tagList, userList: $userList)
   }
`;

export const GET_FILES_FOLDERS = gql`
   ${FOLDER_FIELDS}
   ${FILE_BASIC_FIELDS}
   ${MESSAGE_FIELDS}
   query getFilesFolders(
      $currentFolderId: ID
      $currentFolderUri: String
      $currentFileUri: String
   ) {
      getFilesFolders(
         currentFolderId: $currentFolderId
         currentFolderUri: $currentFolderUri
         currentFileUri: $currentFileUri
      ) {
         files {
            ...FILE_BASIC_FIELDS

            messages {
               ...MESSAGE_FIELDS
               project {
                  uri
               }
               profile {
                  photoUrlOrGravatar
               }
            }
         }
         currentFolder {
            ...FOLDER_FIELDS
            path
         }
         folders {
            ...FOLDER_FIELDS
            path
            parentFolder {
               id
               projectId
            }
            project {
               folderId
               uri
            }
            files {
               ...FILE_BASIC_FIELDS
            }
         }
         parentFolders {
            ...FOLDER_FIELDS
            path
         }
      }
   }
`;
export const GET_FILES = gql`
   ${FILE_BASIC_FIELDS}
   query getFiles($uri: ID, $offset: Int, $trashed: Boolean!) {
      getFiles(uri: $uri, offset: $offset, trashed: $trashed)
         @connection(key: "getFiles") {
         files {
            ...FILE_BASIC_FIELDS
            folder {
               uri
            }
         }
         pageInfo {
            page
            totalPages
         }
      }
   }
`;

export const GET_FOLDERS = gql`
   ${FOLDER_FIELDS}
   ${FILE_BASIC_FIELDS}
   query getFolders($uri: ID, $offset: Int, $trashed: Boolean!) {
      getFolders(uri: $uri, offset: $offset, trashed: $trashed)
         @connection(key: "getFolders") {
         folders {
            ...FOLDER_FIELDS
            project {
               id
               uri
               folderId
            }
            parentFolder {
               ...FOLDER_FIELDS
            }
         }
         pageInfo {
            page
            totalPages
         }
      }
   }
`;

// The getModalX queries match the above queries but in a new namespace inside the Apollo cache.
export const GET_MODAL_FILES = gql`
   ${FILE_BASIC_FIELDS}
   query getModalFiles($uri: ID, $offset: Int) {
      getModalFiles(uri: $uri, offset: $offset) @connection(key: "getFiles") {
         files {
            ...FILE_BASIC_FIELDS

            stems {
               ...FILE_BASIC_FIELDS
            }
            bouncedown {
               ...FILE_BASIC_FIELDS
            }
         }
         pageInfo {
            page
            totalPages
         }
      }
   }
`;

export const GET_MODAL_FOLDERS = gql`
   ${FOLDER_FIELDS}
   query getModalFolders($uri: ID, $offset: Int) {
      getModalFolders(uri: $uri, offset: $offset)
         @connection(key: "getFolders") {
         folders {
            ...FOLDER_FIELDS
            project {
               id
               uri
               folderId
            }
         }
         pageInfo {
            page
            totalPages
         }
      }
   }
`;

export const GET_FOLDER = gql`
   ${FOLDER_FIELDS}
   ${FILE_BASIC_FIELDS}
   query getFolder($folderUri: String) {
      getFolder(folderUri: $folderUri) {
         ...FOLDER_FIELDS
         path
         project {
            title
            uri
         }
         parentFolders {
            ...FOLDER_FIELDS
            path
         }
         folders {
            ...FOLDER_FIELDS
            path
         }
         files {
            ...FILE_BASIC_FIELDS
         }
      }
   }
`;

export const GET_CURRENT_FOLDER_TO_ADD_PROJECT = gql`
   query getFolder($folderUri: String) {
      getFolder(folderUri: $folderUri) {
         id
         uri
      }
   }
`;

export const SEARCH_FILES = gql`
   query searchFiles($search: String, $limit: Int!, $offset: Int!) {
      searchFiles(search: $search, limit: $limit, offset: $offset) {
         id
         originalName
         mimeType
         name
         uri
         failed
         signedUrl
         audioLength
         previewSignedUrl
         waveformSlimUrl
         waveformSlimUrl
         waveformWhiteUrl
         waveformBlueUrl
         size
         formattedSize
         processingPreview
         processingWaveform
         processingDownload
         createdAt
         updatedAt
      }
   }
`;
export const SEARCH_FOLDERS = gql`
   query searchFolders($search: String, $limit: Int!, $offset: Int!) {
      searchFolders(search: $search, limit: $limit, offset: $offset) {
         id
         originalName
         name
         uri
         size
         createdAt
         updatedAt
      }
   }
`;
export const GET_NOTIFICATIONS = gql`
   ${FILE_BASIC_FIELDS}
   query getNotifications($limit: Int, $offset: Int) {
      getNotifications(limit: $limit, offset: $offset) {
         id
         typeName
         uniqueId
         createdBy
         icon
         title
         body
         actionText
         actionUrl
         read
         file {
            ...FILE_BASIC_FIELDS
         }
         project {
            title
            uri
         }
         #createdAt
         #updatedAt
      }
   }
`;
export const GET_USER_NOTIFICATION_SUBSCRIPTION = gql`
   ${FILE_BASIC_FIELDS}
   subscription notificationSubscription {
      notificationSubscription {
         id
         typeName
         uniqueId
         createdBy
         icon
         title
         body
         actionText
         actionUrl
         read
         file {
            ...FILE_BASIC_FIELDS
         }
         project {
            title
            uri
         }
         #createdAt
         #updatedAt
      }
   }
`;

export const REGISTER_DEVICE_USER = gql`
   mutation registerDevice(
      $deviceId: String!
      $userAgent: String
      $ip: String
      $notifications: Int
      $exponentPushToken: String
      $mobile: Int
      $web: Int
   ) {
      registerDevice(
         deviceId: $deviceId
         userAgent: $userAgent
         ip: $ip
         notifications: $notifications
         exponentPushToken: $exponentPushToken
         mobile: $mobile
         web: $web
      ) {
         deviceId
         userAgent
         ip
         notifications
         exponentPushToken
         mobile
      }
   }
`;
export const DELETE_DEVICE = gql`
   mutation deleteDevice($id: ID!) {
      deleteDevice(id: $id)
   }
`;
export const TOGGLE_DEVICE = gql`
   mutation toggleDevice($id: ID!) {
      toggleDevice(id: $id)
   }
`;

export const REGISTER_NOTIFICATION_SUBSCRIPTION = gql`
   mutation registerNotificationSubscription(
      $subscription: NotificationSubscriptionInput!
      $deviceId: ID
   ) {
      registerNotificationSubscription(
         subscription: $subscription
         deviceId: $deviceId
      )
   }
`;
export const RESET_PASSWORD = gql`
   mutation resetPassword($email: String!) {
      resetPassword(email: $email)
   }
`;

export const CREATE_FOLDER = gql`
   ${FOLDER_FIELDS}
   mutation createFolder($name: String!, $parentFolderUri: String) {
      createFolder(name: $name, parentFolderUri: $parentFolderUri) {
         ...FOLDER_FIELDS
         path
         project {
            uri
         }
      }
   }
`;

export const CREATE_NESTED_FOLDER = gql`
   mutation createNestedFolder(
      $nestedFolders: [NestedFolderInput]
      $currentFolderId: ID
      $currentProjectId: ID
   ) {
      createNestedFolder(
         nestedFolders: $nestedFolders
         currentFolderId: $currentFolderId
         currentProjectId: $currentProjectId
      ) {
         id
         uri
         folderId
         trashed
         projectId
         createdAt
         updatedAt
         failed
         originalName
         mimeType
         name
         signedUrl
         audioLength
         previewSignedUrl
         waveformSlimUrl
         waveformWhiteUrl
         waveformBlueUrl
         size
         formattedSize
         processingPreview
         processingWaveform
         processingDownload
         encodeDownload
         path
         folder {
            id
            uri
            name
            originalName
         }
         project {
            uri
         }
         createdAt
         updatedAt
      }
   }
`;

export const UPLOAD_NEW_FOLDER_VERSION = gql`
   mutation uploadNewFolderVersion(
      $nestedFolders: NestedFolderInput
      $newVersionFromId: ID
   ) {
      uploadNewFolderVersion(
         nestedFolders: $nestedFolders
         newVersionFromId: $newVersionFromId
      ) {
         id
         uri
         folderId
         trashed
         projectId
         createdAt
         updatedAt
         failed
         originalName
         mimeType
         name
         signedUrl
         audioLength
         previewSignedUrl
         waveformSlimUrl
         waveformWhiteUrl
         waveformBlueUrl
         size
         formattedSize
         processingPreview
         processingWaveform
         processingDownload
         encodeDownload
         path
         folder {
            id
            uri
            name
            originalName
         }
         project {
            uri
         }
         createdAt
         updatedAt
      }
   }
`;
export const RENAME_FILE_OR_FOLDER = gql`
   ${FOLDER_FIELDS}
   ${FILE_BASIC_FIELDS}
   mutation renameFileOrFolder($name: String!, $folderId: ID, $fileId: ID) {
      renameFileOrFolder(name: $name, folderId: $folderId, fileId: $fileId) {
         file {
            ...FILE_BASIC_FIELDS

            folder {
               uri
               parentFolders {
                  uri
                  path
               }
            }
         }
         folder {
            ...FOLDER_FIELDS
            path
            parentFolders {
               uri
               path
            }
         }
      }
   }
`;

export const MOVE_FILES_FOLDER_BY_PATH = gql`
   ${FILE_BASIC_FIELDS}
   ${FOLDER_FIELDS}
   mutation moveFilesFolderByPath($path: String!, $newPath: String!) {
      moveFilesFolderByPath(path: $path, newPath: $newPath) {
         ...FOLDER_FIELDS
         folders {
            ...FOLDER_FIELDS
         }
         files {
            ...FILE_BASIC_FIELDS
         }
      }
   }
`;

export const MOVE_FILES_FOLDERS = gql`
   ${FILE_BASIC_FIELDS}
   ${FOLDER_FIELDS}
   mutation moveFilesFolder(
      $fileIds: [ID]
      $folderIds: [ID]
      $targetFolderId: ID
      $projectId: ID
   ) {
      moveFilesFolder(
         fileIds: $fileIds
         folderIds: $folderIds
         targetFolderId: $targetFolderId
         projectId: $projectId
      ) {
         folders {
            ...FOLDER_FIELDS
            folders {
               ...FOLDER_FIELDS
            }
            files {
               ...FILE_BASIC_FIELDS
            }
         }
         files {
            ...FILE_BASIC_FIELDS
         }
      }
   }
`;
export const COPY_FILES_FOLDERS = gql`
   ${FILE_BASIC_FIELDS}
   ${FOLDER_FIELDS}
   mutation copyFilesFolder(
      $fileIds: [ID!]!
      $folderIds: [ID!]!
      $targetFolderId: ID
      $projectId: ID
      $isUploadFromProject: Boolean
   ) {
      copyFilesFolder(
         fileIds: $fileIds
         folderIds: $folderIds
         targetFolderId: $targetFolderId
         projectId: $projectId
         isUploadFromProject: $isUploadFromProject
      ) {
         folders {
            ...FOLDER_FIELDS
            folders {
               ...FOLDER_FIELDS
            }
            files {
               ...FILE_BASIC_FIELDS
            }
         }
         files {
            ...FILE_BASIC_FIELDS
         }
      }
   }
`;
export const DELETE_FILES_FOLDERS = gql`
   mutation deleteFilesFolders($fileIds: [ID], $folderIds: [ID]) {
      deleteFilesFolders(fileIds: $fileIds, folderIds: $folderIds) {
         id
         uri
         folder {
            id
            uri
         }
         dawFile {
            id
            uri
            project {
               id
               uri
            }
            stems {
               id
               uri
            }
         }
      }
   }
`;
export const STARRED_FILES_FOLDERS = gql`
   mutation starFilesFolders($fileIds: [ID], $folderIds: [ID]) {
      starFilesFolders(fileIds: $fileIds, folderIds: $folderIds) {
         projects
         folders
         files
      }
   }
`;
export const TRASH_FILES_FOLDERS = gql`
   ${FILE_BASIC_FIELDS}
   ${FOLDER_FIELDS}
   mutation trashFilesFolders($fileIds: [ID], $folderIds: [ID]) {
      trashFilesFolders(fileIds: $fileIds, folderIds: $folderIds) {
         files {
            ...FILE_BASIC_FIELDS

            project {
               uri
               folder {
                  uri
               }
            }
            folder {
               ...FOLDER_FIELDS
               path
               parentFolders {
                  ...FOLDER_FIELDS
                  path
               }
            }
            createdAt
            updatedAt
         }
         folders {
            ...FOLDER_FIELDS
            path
            project {
               id
               uri
               folder {
                  uri
               }
            }
            parentFolders {
               ...FOLDER_FIELDS
               path
            }
            parentFolder {
               ...FOLDER_FIELDS
               path
            }
         }
      }
   }
`;
export const SAVE_USER_MOBILE_RAW_CONTACT = gql`
   mutation saveUserMobileContactsRaw($contacts: [UserMobileContactsRawInput]) {
      saveUserMobileContactsRaw(contacts: $contacts)
   }
`;
export const CONFIRM_EMAIL = gql`
   mutation confirmEmailRequest($token: String) {
      confirmEmailRequest(token: $token) {
         verified
         hasDesktop
         hasMobile
      }
   }
`;
export const RESEND_CONFIRM_EMAIL = gql`
   mutation {
      resendConfirmEmailRequest
   }
`;

export const REPLACE_FILE = gql`
   ${FILE_BASIC_FIELDS}
   ${FOLDER_FIELDS}
   ${MESSAGE_FIELDS}
   mutation replaceFile($file: FileInput!, $folderId: ID) {
      replaceFile(file: $file, folderId: $folderId) {
         ...FILE_BASIC_FIELDS

         stems {
            ...FILE_BASIC_FIELDS
         }
         bouncedown {
            ...FILE_BASIC_FIELDS
         }
         messages {
            ...MESSAGE_FIELDS
         }
         folder {
            ...FOLDER_FIELDS
            path
            parentFolder {
               id
               uri
               size
            }
         }
         project {
            id
            uri
            folderId
         }
      }
   }
`;

export const UPLOAD_FILE = gql`
   ${FILE_BASIC_FIELDS}
   ${FOLDER_FIELDS}
   ${MESSAGE_FIELDS}
   mutation uploadFile($file: FileInput!, $folderId: ID, $isSyncFile: Boolean) {
      uploadFile(file: $file, folderId: $folderId, isSyncFile: $isSyncFile) {
         file {
            ...FILE_BASIC_FIELDS

            stems {
               ...FILE_BASIC_FIELDS
            }
            bouncedown {
               ...FILE_BASIC_FIELDS
            }
            messages {
               ...MESSAGE_FIELDS
            }
            folder {
               ...FOLDER_FIELDS
               path
               parentFolder {
                  id
                  uri
                  size
               }
            }
            project {
               id
               uri
               folderId
            }
         }
         folder {
            ...FOLDER_FIELDS
            folders {
               ...FOLDER_FIELDS
            }
            files {
               ...FILE_BASIC_FIELDS
            }
            bouncedown {
               ...FILE_BASIC_FIELDS
            }
            stems {
               ...FILE_BASIC_FIELDS
            }
         }
      }
   }
`;

export const UPDATE_FILE_COLOR = gql`
   ${FILE_BASIC_FIELDS}
   mutation updateFileColor($fileId: ID, $color: String) {
      updateFileColor(fileId: $fileId, color: $color) {
         ...FILE_BASIC_FIELDS
      }
   }
`;

export const REGISTER_STRIPE = gql`
   mutation registerStripe($terms: Boolean!) {
      registerStripe(terms: $terms) {
         setupIntentSecret
         user {
            jwt
            email
            id
            trialEndsAt
            stripeId
            cardBrand
            cardLastFour
            currentBillingPlan
            emailVerifiedAt
            emailVerifiedAt
            hasCompletedWelcome
         }
      }
   }
`;

export const CONFIRM_PAYMENT = gql`
   mutation confirmPayment(
      $paymentMethod: String!
      $postalCode: String!
      $priceId: String!
      $coupon: String
      $currentPlan: String!
      $trial: Boolean
      $quantity: Int!
   ) {
      confirmPayment(
         paymentMethod: $paymentMethod
         postalCode: $postalCode
         priceId: $priceId
         coupon: $coupon
         currentPlan: $currentPlan
         trial: $trial
         quantity: $quantity
      ) {
         jwt
         email
         profile {
            id
            phoneNumber
            phoneGuide
            emailGuide
            addressGuide
            backgroundImageSignedUrl
            guide
            name
            email
            uri
            profileUrl
            description
            photoUrl
            photoUrlOrGravatar
            backgroundStyle
            backgroundImageUrl
            primaryColor
            secondaryColor
            uploadForm
         }
         id
         trialEndsAt
         stripeId
         cardBrand
         cardLastFour
         currentBillingPlan
         emailVerifiedAt
         subscriptions {
            id
            name
            stripeStatus
            stripePlan
            stripeId
            currentPlan
            googlePayId
            applePayId
            createdAt
            updatedAt
         }
         emailVerifiedAt
         devices {
            id
            deviceId
            ip
            active
            userAgent
            notifications
         }
         hasCompletedWelcome

         limits {
            guideAccessLeft
            totalStorageUsed
            guideAccess
            mastersCreated
            soundsDownloaded
         }
      }
   }
`;
export const DELETE_SUBSCRIPTION = gql`
   mutation {
      deleteSubscription {
         admin
         jwt
         email
         profile {
            id
            phoneNumber
            phoneGuide
            emailGuide
            addressGuide
            backgroundImageSignedUrl
            guide
            name
            email
            uri
            profileUrl
            description
            photoUrl
            photoUrlOrGravatar
            backgroundStyle
            backgroundImageUrl
            primaryColor
            secondaryColor
            uploadForm
         }
         id
         trialEndsAt
         stripeId
         cardBrand
         cardLastFour
         currentBillingPlan
         emailVerifiedAt
         subscriptions {
            id
            name
            stripeStatus
            stripePlan
            stripeId
            currentPlan
            googlePayId
            applePayId
            createdAt
            updatedAt
         }
         emailVerifiedAt
         devices {
            id
            deviceId
            ip
            active
            userAgent
            notifications
         }
         hasCompletedWelcome

         limits {
            guideAccessLeft
            totalStorageUsed
            guideAccess
            mastersCreated
            soundsDownloaded
         }
      }
   }
`;
export const UPDATE_SUBSCRIPTION = gql`
   mutation updateSubscriptionStripe(
      $priceId: String!
      $newPlan: String
      $coupon: String
      $quantity: Int!
   ) {
      updateSubscriptionStripe(
         priceId: $priceId
         newPlan: $newPlan
         coupon: $coupon
         quantity: $quantity
      ) {
         admin
         jwt
         email
         profile {
            id
            phoneNumber
            phoneGuide
            emailGuide
            addressGuide
            backgroundImageSignedUrl
            guide
            name
            email
            uri
            profileUrl
            description
            photoUrl
            photoUrlOrGravatar
            backgroundStyle
            backgroundImageUrl
            primaryColor
            secondaryColor
            uploadForm
         }
         id
         trialEndsAt
         stripeId
         cardBrand
         cardLastFour
         currentBillingPlan
         emailVerifiedAt
         subscriptions {
            id
            name
            stripeStatus
            stripePlan
            stripeId
            currentPlan
            googlePayId
            applePayId
            createdAt
            updatedAt
         }
         emailVerifiedAt
         devices {
            id
            deviceId
            ip
            active
            userAgent
            notifications
         }
         hasCompletedWelcome

         limits {
            guideAccessLeft
            totalStorageUsed
            guideAccess
            mastersCreated
            soundsDownloaded
         }
      }
   }
`;
export const UPDATE_CARD = gql`
   mutation updateCard($paymentMethod: String!, $postalCode: String!) {
      updateCard(paymentMethod: $paymentMethod, postalCode: $postalCode) {
         admin
         jwt
         email
         profile {
            id
            phoneNumber
            phoneGuide
            emailGuide
            addressGuide
            backgroundImageSignedUrl
            guide
            name
            email
            uri
            profileUrl
            description
            photoUrl
            photoUrlOrGravatar
            backgroundStyle
            backgroundImageUrl
            primaryColor
            secondaryColor
            uploadForm
         }
         id
         trialEndsAt
         stripeId
         cardBrand
         cardLastFour
         currentBillingPlan
         emailVerifiedAt
         subscriptions {
            id
            name
            stripeStatus
            stripePlan
            stripeId
            currentPlan
            googlePayId
            applePayId
            createdAt
            updatedAt
         }
         emailVerifiedAt
         devices {
            id
            deviceId
            ip
            active
            userAgent
            notifications
         }
         hasCompletedWelcome

         limits {
            guideAccessLeft
            totalStorageUsed
            guideAccess
            mastersCreated
            soundsDownloaded
         }
      }
   }
`;
export const SETUP_INTENT = gql`
   mutation setupIntent {
      setupIntent
   }
`;
export const REDEEM_COUPON = gql`
   mutation redeemCoupon($coupon: String!) {
      redeemCoupon(coupon: $coupon) {
         admin
         jwt
         email
         profile {
            id
            phoneNumber
            phoneGuide
            emailGuide
            addressGuide
            backgroundImageSignedUrl
            guide
            name
            email
            uri
            profileUrl
            description
            photoUrl
            photoUrlOrGravatar
            backgroundStyle
            backgroundImageUrl
            primaryColor
            secondaryColor
            uploadForm
         }
         id
         trialEndsAt
         stripeId
         cardBrand
         cardLastFour
         currentBillingPlan
         emailVerifiedAt
         subscriptions {
            id
            name
            stripeStatus
            stripePlan
            stripeId
            currentPlan
            googlePayId
            applePayId
            createdAt
            updatedAt
         }
         emailVerifiedAt
         devices {
            id
            deviceId
            ip
            active
            userAgent
            notifications
         }
         hasCompletedWelcome

         limits {
            guideAccessLeft
            totalStorageUsed
            guideAccess
            mastersCreated
            soundsDownloaded
         }
      }
   }
`;
export const UPDATE_PASSWORD_RESET = gql`
   mutation updatePasswordReset($token: String!, $password: String!) {
      updatePasswordReset(token: $token, password: $password)
   }
`;
export const USER_COMPLETED_WELCOME_FLOW = gql`
   mutation userCompletedWelcomeFlow {
      userCompletedWelcomeFlow {
         id
         hasCompletedWelcome
      }
   }
`;
export const UPDATE_NOTIFICATION_SETTING = gql`
   mutation updateNotificationSetting($type: String!, $channel: String!) {
      updateNotificationSetting(type: $type, channel: $channel)
   }
`;

export const SEND_INVITE = gql`
   mutation sendInvite($email: String!) {
      sendInvite(email: $email)
   }
`;

// export const CREATE_PROFILE = gql`
//    mutation createProfile(
//       $teamId: ID
//       $name: String
//       $email: String
//       $uri: String
//       $description: String
//       $location: String
//       $role: String
//    ) {
//       createProfile(
//          teamId: $teamId
//          name: $name
//          email: $email
//          uri: $uri
//          description: $description
//          location: $location
//          role: $role
//       )
//    }
// `;
export const CREATE_PROFILE = gql`
   mutation createProfile(
      $name: String!
      $bio: String
      $tags: [String]
      $image: String
   ) {
      createProfile(name: $name, bio: $bio, tags: $tags, image: $image) {
         name
         uri
      }
   }
`;

export const DELETE_PROFILE = gql`
   mutation deleteProfile(
      $password: String!
      $deleteFiles: Boolean
      $deleteConnections: Boolean
      $deleteProjects: Boolean
   ) {
      deleteProfile(
         password: $password
         deleteFiles: $deleteFiles
         deleteConnections: $deleteConnections
         deleteProjects: $deleteProjects
      ) {
         name
         uri
      }
   }
`;
export const STATS_MUTATION = gql`
   mutation statsMutation($stats: Stats!) {
      statsMutation(stats: $stats)
   }
`;
export const DISMISS_NOTIFICATION = gql`
   mutation dismissNotification($ids: [ID]!) {
      dismissNotification(ids: $ids) {
         id
      }
   }
`;

export const CREATE_PROJECT = gql`
   mutation createProject(
      $title: String!
      $description: String
      $invites: [AutoComplete]
   ) {
      createProject(
         title: $title
         description: $description
         invites: $invites
      ) {
         id
         profileId
         title
         type
         uri
         description
         publicFiles
         featured
         trashed
         folderId
         folder {
            id
            uri
            path
         }
         pendingContacts {
            email
            name
            invitationId
         }
         acceptedContacts {
            id
            name
            description
            photoUrlOrGravatar
            uri
         }
         messages {
            id
         }
         files {
            id
            uri
            originalName

            updatedAt
         }
         createdAt
         updatedAt
      }
   }
`;
export const GET_PROJECTS = gql`
   query getProjects($excludeFeatured: Boolean, $onlyFeaturedProjectsOfId: ID) {
      getProjects(
         excludeFeatured: $excludeFeatured
         onlyFeaturedProjectsOfId: $onlyFeaturedProjectsOfId
      ) {
         id
         profileId
         title
         type
         uri
         description
         publicFiles
         featured
         trashed
         folderId
         signedCoverImageUrl
         pendingContacts {
            email
            name
            invitationId
         }
         acceptedContacts {
            id
            name
            description
            photoUrlOrGravatar
            uri
         }
         messages {
            id
         }
         files {
            id
            uri
            originalName
            updatedAt
         }
         folder {
            uri
         }
         createdAt
         updatedAt
      }
   }
`;
export const GET_PROJECT = gql`
   ${FILE_BASIC_FIELDS}
   ${MESSAGE_FIELDS}
   query getProject($uri: String!) {
      getProject(uri: $uri) {
         id
         profileId
         title
         type
         uri
         description
         publicFiles
         featured
         trashed
         folderId
         creator {
            name
            photoUrlOrGravatar
         }
         folder {
            id
            uri
            projectId
            originalName
            path
         }
         folders {
            id
            uri
         }
         files {
            ...FILE_BASIC_FIELDS

            messages {
               ...MESSAGE_FIELDS
            }
            versions {
               ...FILE_BASIC_FIELDS
            }
         }
         signedCoverImageUrl
         pendingContacts {
            email
            name
            invitationId
         }
         acceptedContacts {
            id
            name
            description
            photoUrlOrGravatar
            uri
         }
         messages {
            ...MESSAGE_FIELDS
            profile {
               id
               name
               photoUrlOrGravatar
            }
            files {
               ...FILE_BASIC_FIELDS
            }
            replies {
               ...MESSAGE_FIELDS
               files {
                  ...FILE_BASIC_FIELDS
               }
            }
         }
         createdAt
         updatedAt
      }
   }
`;
export const GET_PUBLIC_PROJECT = gql`
   query getPublicProject($uri: String!) {
      getPublicProject(uri: $uri) {
         id
         title
         type
         uri
         description
         files {
            id
            uri
            originalName
            mimeType
            signedUrl
            processingPreview
            processingWaveform
            waveformBlueUrl
            waveformWhiteUrl
            waveformSlimUrl
            audioLength
            previewSignedUrl
         }
         signedCoverImageUrl
      }
   }
`;
export const GET_PROJECT_INVITATIONS = gql`
   query getProjectInvitations {
      getProjectInvitations {
         id
         title
         profileId
         type
         uri
         creator {
            name
         }
      }
   }
`;

export const ADD_FILES_PROJECT = gql`
   ${MESSAGE_FIELDS}
   ${FILE_BASIC_FIELDS}
   mutation addFilesProject($id: ID!, $fileIds: [ID!], $folderIds: [ID!]) {
      addFilesProject(id: $id, fileIds: $fileIds, folderIds: $folderIds) {
         id
         profileId
         title
         type
         uri
         description
         publicFiles

         featured
         trashed
         folderId
         creator {
            name
         }
         files {
            ...FILE_BASIC_FIELDS
            messages {
               ...MESSAGE_FIELDS
            }
         }
         pendingContacts {
            email
            name
            invitationId
         }
         acceptedContacts {
            id
            name
            description
            photoUrlOrGravatar
            uri
         }
         messages {
            ...MESSAGE_FIELDS
            profile {
               name
               photoUrlOrGravatar
            }
         }
         createdAt
         updatedAt
      }
   }
`;

export const UPDATE_PROJECT = gql`
   mutation updateProject(
      $title: String!
      $id: ID!
      $description: String
      $addInvitations: [AutoComplete]
      $removeInvitations: [AutoComplete]
   ) {
      updateProject(
         title: $title
         id: $id
         description: $description
         addInvitations: $addInvitations
         removeInvitations: $removeInvitations
      ) {
         id
         profileId
         title
         type
         uri
         description
         publicFiles
         folderId
         folder {
            id
            name
            originalName
         }
         signedCoverImageUrl
         pendingContacts {
            email
            name
            invitationId
         }
         acceptedContacts {
            id
            name
            description
            photoUrlOrGravatar
            uri
         }
         createdAt
         updatedAt
      }
   }
`;

export const UPDATE_PROJECT_IMAGE = gql`
   mutation updateProjectImage($projectId: ID!, $image: String!) {
      updateProjectImage(projectId: $projectId, image: $image)
   }
`;

export const SEND_PROJECT_MESSAGE = gql`
   ${MESSAGE_FIELDS}
   ${FILE_BASIC_FIELDS}
   mutation sendProjectMessage(
      $message: String!
      $projectId: String
      $fileId: String
      $folderId: String
      $timestamp: Int
      $files: [FileInput!]
      $replyId: ID
      $threadMessageId: ID
      $createdAt: String
      $actionUrl: String
   ) {
      sendProjectMessage(
         message: $message
         projectId: $projectId
         fileId: $fileId
         folderId: $folderId
         files: $files
         timestamp: $timestamp
         replyId: $replyId
         threadMessageId: $threadMessageId
         createdAt: $createdAt
         actionUrl: $actionUrl
      ) {
         ...MESSAGE_FIELDS
         files {
            ...FILE_BASIC_FIELDS
         }
      }
   }
`;

export const STAR_PROJECT = gql`
   mutation starProject($id: ID!) {
      starProject(id: $id) {
         projects
         folders
         files
      }
   }
`;

export const FEATURE_PROJECT = gql`
   mutation featureProject($ids: [ID!]) {
      featureProject(ids: $ids) {
         id

         featured
         trashed
         createdAt
         updatedAt
      }
   }
`;
export const SEND_CONNECT_INVITE = gql`
   mutation sendConnectInvite($receiverProfileUri: String!) {
      sendConnectInvite(receiverProfileUri: $receiverProfileUri) {
         accepted
         declined
         createdAt
         updatedAt
      }
   }
`;
export const ACCEPT_DECLINE_CONNECTION = gql`
   mutation acceptDeclineConnection(
      $acceptValue: Boolean!
      $connectionId: ID!
   ) {
      acceptDeclineConnection(
         acceptValue: $acceptValue
         connectionId: $connectionId
      ) {
         accepted
         declined
      }
   }
`;
export const UPDATE_GUIDE = gql`
   mutation updateGuide(
      $phoneNumber: String
      $address: String
      $email: String
      $phoneGuide: Boolean
      $emailGuide: Boolean
      $addressGuide: Boolean
   ) {
      updateGuide(
         phoneNumber: $phoneNumber
         email: $email
         address: $address
         phoneGuide: $phoneGuide
         emailGuide: $emailGuide
         addressGuide: $addressGuide
      ) {
         phoneNumber
         email
         address
      }
   }
`;

export const GUIDE_VALUES = gql`
   query getGuideValues {
      getGuideValues {
         phoneNumber
         email
         address
      }
   }
`;
export const GUIDE_VALUES_PRIVATE = gql`
   query getGuideValuesPrivate($id: ID!) {
      getGuideValuesPrivate(id: $id) {
         phoneNumber
         email
         address
      }
   }
`;

export const REMOVE_CONNECTION = gql`
   mutation removeConnection(
      $receiverProfileUri: String!
      $senderProfileUri: String!
   ) {
      removeConnection(
         receiverProfileUri: $receiverProfileUri
         senderProfileUri: $senderProfileUri
      ) {
         accepted
         declined
         createdAt
         updatedAt
      }
   }
`;

export const GET_FEATURED_PROJECTS = gql`
   ${MESSAGE_FIELDS}
   query getFeaturedProjects($profileId: String!) {
      getFeaturedProjects(profileId: $profileId) {
         id
         profileId
         title
         type
         uri
         description
         publicFiles
         featured
         trashed
         folderId
         creator {
            name
         }
         files {
            id
            trashed
            uri
            failed
            originalName
            mimeType
            name
            signedUrl
            audioLength
            previewSignedUrl
            waveformWhiteUrl
            waveformBlueUrl
            size
            formattedSize
            processingPreview
            processingWaveform
            processingDownload
            encodeDownload

            messages {
               ...MESSAGE_FIELDS
            }
         }
         pendingContacts {
            email
            name
            invitationId
         }
         acceptedContacts {
            id
            name
            description
            photoUrlOrGravatar
            uri
         }
         messages {
            ...MESSAGE_FIELDS
            profile {
               name
               photoUrlOrGravatar
            }
         }
      }
   }
`;
export const ACCEPT_PROJECT_INVITE = gql`
   mutation acceptProjectInvite($projectId: ID!, $profileId: String) {
      acceptProjectInvite(projectId: $projectId, profileId: $profileId) {
         id
         uri
         trashed
         createdAt
         updatedAt
         folder {
            uri
         }
         pendingContacts {
            email
            name
            invitationId
         }
         acceptedContacts {
            id
            name
            description
            photoUrlOrGravatar
            uri
         }
      }
   }
`;
export const REVOKE_PROJECT_ACCESS = gql`
   mutation revokeProjectAccess(
      $projectId: ID!
      $profileId: ID
      $email: String
      $permanent: Boolean
   ) {
      revokeProjectAccess(
         projectId: $projectId
         profileId: $profileId
         email: $email
         permanent: $permanent
      ) {
         id
         uri
         trashed
         createdAt
         updatedAt
         profileId
         folder {
            uri
         }
         pendingContacts {
            email
            name
            invitationId
         }
         acceptedContacts {
            id
            name
            description
            photoUrlOrGravatar
            uri
         }
      }
   }
`;

export const FEED_SUBSCRIPTION = gql`
   ${MESSAGE_FIELDS}
   subscription feedSubscription($profileId: ID!) {
      feedSubscription(profileId: $profileId) {
         id
         name
         value
         messages {
            ...MESSAGE_FIELDS
            profile {
               name
            }
         }
         files {
            id
            uri
            originalName

            updatedAt
            messages {
               ...MESSAGE_FIELDS
            }
            profile {
               name
            }
         }
         project {
            id
            title
            uri
            signedCoverImageUrl
            type
         }
         createdAt
         updatedAt
      }
   }
`;
export const PROJECTS_SUBSCRIPTION = gql`
   subscription projectsSubscription($profileId: ID!, $projectUri: ID!) {
      projectsSubscription(profileId: $profileId, projectUri: $projectUri) {
         id
         profileId
         profileName
         photoUrlOrGravatar
         message
         fileId
         expiry
         allow
         createdAt
         files {
            id
            originalName
            mimeType
            name
            failed
            signedUrl
            audioLength
            previewSignedUrl
            waveformWhiteUrl
            waveformBlueUrl
            size
            formattedSize
         }
      }
   }
`;
export const EDIT_COMMENT_SUBSCRIPTION = gql`
   ${MESSAGE_FIELDS}
   subscription editCommentSubscription(
      $profileId: ID!
      $projectUri: ID
      $fileId: ID
      $folderId: ID
   ) {
      editCommentSubscription(
         profileId: $profileId
         projectUri: $projectUri
         fileId: $fileId
         folderId: $folderId
      ) {
         ...MESSAGE_FIELDS
         files {
            id
            originalName
            mimeType
            name
            failed
            signedUrl
            audioLength
            previewSignedUrl
            waveformWhiteUrl
            waveformBlueUrl
            size
            formattedSize
         }
      }
   }
`;
export const DELETE_COMMENT_SUBSCRIPTION = gql`
   ${MESSAGE_FIELDS}
   subscription deleteCommentSubscription(
      $profileId: ID!
      $projectUri: ID
      $fileId: ID
      $folderId: ID
   ) {
      deleteCommentSubscription(
         profileId: $profileId
         projectUri: $projectUri
         fileId: $fileId
         folderId: $folderId
      ) {
         ...MESSAGE_FIELDS
         files {
            id
            originalName
            mimeType
            name
            failed
            signedUrl
            audioLength
            previewSignedUrl
            waveformWhiteUrl
            waveformBlueUrl
            size
            formattedSize
         }
      }
   }
`;
export const RESOLVE_COMMENT_SUBSCRIPTION = gql`
   ${MESSAGE_FIELDS}
   subscription resolveCommentSubscription(
      $profileId: ID!
      $projectUri: ID
      $fileId: ID
      $folderId: ID
   ) {
      resolveCommentSubscription(
         profileId: $profileId
         projectUri: $projectUri
         fileId: $fileId
         folderId: $folderId
      ) {
         ...MESSAGE_FIELDS
         files {
            id
            trashed
            originalName
            mimeType
            name
            failed
            signedUrl
            audioLength
            previewSignedUrl
            waveformWhiteUrl
            waveformBlueUrl
            size
            formattedSize
         }
         profile {
            name
            photoUrlOrGravatar
         }
      }
   }
`;

export const GET_CONNECTIONS = gql`
   query {
      getConnections {
         id
         name
         photoUrlOrGravatar
         uri
      }
   }
`;

export const GET_TAGS_COUNT = gql`
   query getTagsCount {
      getTagsCount
   }
`;

export const CHECK_HASH_PROJECT_INVITE = gql`
   query checkHashProjectInvite($hash: String) {
      checkHashProjectInvite(hash: $hash) {
         profiles {
            id
            name
            photoUrlOrGravatar
            uri
         }
         acceptedProfile {
            id
            uri
         }
         project {
            id
            title
            description
            uri
            creator {
               name
            }
            messagesCount
            filesCount
         }
      }
   }
`;

export const GET_REMOTE_SYNC_FILES = gql`
   query getRemoteSyncFilesFolders {
      getRemoteSyncFilesFolders {
         files {
            id
            originalName
            name
            size
            hash
            path
            signedUrl
            modifiedAt
         }
         folders {
            id
            name
            uri
            size
            path
         }
      }
   }
`;

export const GET_AWARD_CATEGORIES = gql`
   query getAwardCategories($year: Int, $allYears: Boolean) {
      getAwardCategories(year: $year, allYears: $allYears) {
         id
         name
         rank
         uri
         awardsCategoryTitle
         awardsCategorySubtitle
         awardsCategoryDescription
         nominees {
            profile {
               id
               name
               uri
               description
               photoUrl
               photoUrlOrGravatar
            }
            awardsNominationTitle
            awardsNominationDescription
            awardsNominationUrl
            awardsNominationWinner
         }
         partners {
            id
            name
            description
            photoUrlOrGravatar
         }
         awardsYear
         createdAt
         updatedAt
      }
   }
`;

export const GET_AWARD_PARTNERS = gql`
   query getAwardPartners($year: Int!) {
      getAwardPartners(year: $year) {
         name
         photoUrlOrGravatar
         description
      }
   }
`;

export const DELETE_FILE_WATCHER = gql`
   ${FOLDER_FIELDS}
   ${FILE_BASIC_FIELDS}
   mutation deleteFileOrFolderByPath($filePath: String!) {
      deleteFileOrFolderByPath(filePath: $filePath) {
         parentFolder {
            ...FOLDER_FIELDS
            files {
               ...FILE_BASIC_FIELDS
            }
            folders {
               ...FOLDER_FIELDS
            }
         }
         folder {
            ...FOLDER_FIELDS
            project {
               id
               uri
               trashed
            }
         }
         file {
            ...FILE_BASIC_FIELDS
         }
      }
   }
`;

export const ADD_DIR = gql`
   ${FOLDER_FIELDS}
   ${FILE_BASIC_FIELDS}
   mutation addDir($path: String!) {
      addDir(path: $path) {
         ...FOLDER_FIELDS
         path
         folders {
            ...FOLDER_FIELDS
            path
            project {
               uri
            }
            files {
               ...FILE_BASIC_FIELDS
            }
         }
      }
   }
`;

export const VALIDATE_COUPON = gql`
   mutation validateCoupon(
      $coupon: String!
      $currency: String!
      $priceId: String!
   ) {
      validateCoupon(coupon: $coupon, currency: $currency, priceId: $priceId) {
         id
         percentOff
         amountOff
         duration
         name
      }
   }
`;

export const VOTE_AWARD_CATEGORY = gql`
   mutation voteAwardCategory($votedForProfileId: ID!, $categoryTagId: ID!) {
      voteAwardCategory(
         votedForProfileId: $votedForProfileId
         categoryTagId: $categoryTagId
      ) {
         userId
         categoryTagId
      }
   }
`;

export const RESEND_PROJECT_INVITE = gql`
   ${MESSAGE_FIELDS}
   mutation resendProjectInvite($invites: [AutoComplete], $projectId: ID) {
      resendProjectInvite(invites: $invites, projectId: $projectId) {
         id
         profileId
         title
         type
         uri
         description
         publicFiles

         featured
         trashed
         folderId
         creator {
            name
            photoUrlOrGravatar
         }
         folder {
            uri
         }
         files {
            id
            trashed
            uri
            failed
            originalName
            mimeType
            name
            signedUrl
            audioLength
            previewSignedUrl
            waveformSlimUrl
            waveformWhiteUrl
            waveformBlueUrl
            size
            formattedSize
            processingPreview
            processingWaveform
            processingDownload
            encodeDownload
            messages {
               ...MESSAGE_FIELDS
            }
         }
         pendingContacts {
            email
            name
            invitationId
         }
         acceptedContacts {
            id
            name
            description
            photoUrlOrGravatar
            uri
         }
         messages {
            ...MESSAGE_FIELDS
            profile {
               name
               photoUrlOrGravatar
            }
            files {
               id
               originalName
               mimeType
               name
               failed
               signedUrl
               audioLength
               previewSignedUrl
               waveformWhiteUrl
               waveformBlueUrl
               size
               formattedSize
            }
         }
         stats {
            id
            name
            value
            messages {
               ...MESSAGE_FIELDS
               files {
                  id
                  originalName
                  mimeType
                  name
                  failed
                  signedUrl
                  audioLength
                  previewSignedUrl
                  waveformWhiteUrl
                  waveformBlueUrl
                  size
                  formattedSize
               }
            }
            files {
               id
               uri
               originalName
               updatedAt
               messages {
                  ...MESSAGE_FIELDS
               }
               profile {
                  name
               }
            }
            createdAt
            updatedAt
         }
         createdAt
         updatedAt
      }
   }
`;
export const DELETE_PROJECT = gql`
   mutation deleteProject($id: ID) {
      deleteProject(id: $id) {
         id
      }
   }
`;

export const UPDATE_CONTACT = gql`
   mutation updateContact(
      $id: ID
      $name: String
      $email: String
      $phone: String
   ) {
      updateContact(id: $id, name: $name, email: $email, phone: $phone) {
         id
         name
         email
         phoneNumber
      }
   }
`;

export const DELETE_PROJECT_MESSAGE = gql`
   mutation deleteProjectMessage($messageId: ID!) {
      deleteProjectMessage(messageId: $messageId) {
         id
      }
   }
`;
export const SET_MESSAGE_RESOLVED = gql`
   mutation setMessageResolved($messageId: ID!, $projectId: ID) {
      setMessageResolved(messageId: $messageId, projectId: $projectId) {
         id
         resolved
      }
   }
`;
export const EDIT_PROJECT_MESSAGE = gql`
   ${MESSAGE_FIELDS}
   mutation editProjectMessage($messageId: ID!, $message: String!) {
      editProjectMessage(messageId: $messageId, message: $message) {
         id
         messages {
            ...MESSAGE_FIELDS
         }
      }
   }
`;
export const CONVERT_FOLDER_TO_PROJECT = gql`
   mutation convertFolderToProject($folderId: ID!) {
      convertFolderToProject(folderId: $folderId) {
         id
         title
         uri
         type
      }
   }
`;
export const SYNC_FOLDER = gql`
   ${FILE_BASIC_FIELDS}
   ${FOLDER_FIELDS}
   mutation syncFolder($folderId: ID!, $path: String!) {
      syncFolder(folderId: $folderId, path: $path) {
         ...FOLDER_FIELDS
         path
         remoteFilesFolders {
            files {
               ...FILE_BASIC_FIELDS

               folder {
                  path
               }
            }
            folders {
               ...FOLDER_FIELDS
               path
            }
         }
      }
   }
`;

export const TRIGGER_SYNC_SUBSCRIPTION = gql`
   subscription triggerSyncSubscription {
      triggerSyncSubscription {
         projectId
         profileId
      }
   }
`;

export const COMMENTS_SUBSCRIPTION = gql`
   ${MESSAGE_FIELDS}
   ${FILE_BASIC_FIELDS}
   ${FOLDER_FIELDS}
   subscription commentsSubscription(
      $profileId: ID!
      $fileId: ID
      $folderId: ID
   ) {
      commentsSubscription(
         profileId: $profileId
         fileId: $fileId
         folderId: $folderId
      ) {
         ...MESSAGE_FIELDS
         files {
            ...FILE_BASIC_FIELDS
         }
         file {
            ...FILE_BASIC_FIELDS
            folder {
               ...FOLDER_FIELDS
               path
            }
            project {
               uri
            }
            dawFile {
               version
               uri
            }
         }
         replies {
            ...MESSAGE_FIELDS
            replyToMessage {
               ...MESSAGE_FIELDS
            }
            files {
               ...FILE_BASIC_FIELDS
            }
         }
         project {
            folderId
            uri
         }
         profile {
            photoUrlOrGravatar
         }
      }
   }
`;
export const UPLOAD_NEW_VERSION = gql`
   ${FILE_BASIC_FIELDS}
   mutation uploadNewVersion($file: FileInput!, $folderId: ID) {
      uploadNewVersion(file: $file, folderId: $folderId) {
         ...FILE_BASIC_FIELDS

         project {
            uri
            folder {
               path
            }
         }
      }
   }
`;

export const GET_COMMENTS = gql`
   ${FILE_BASIC_FIELDS}
   ${FOLDER_FIELDS}
   ${MESSAGE_FIELDS}
   query getComments($id: ID!, $packagedType: Boolean, $offset: Int) {
      getComments(id: $id, packagedType: $packagedType, offset: $offset) {
         ...MESSAGE_FIELDS
         replies {
            ...MESSAGE_FIELDS
            replyToMessage {
               ...MESSAGE_FIELDS
            }
            files {
               ...FILE_BASIC_FIELDS
            }
         }
         file {
            ...FILE_BASIC_FIELDS
            folder {
               ...FOLDER_FIELDS
               path
            }
            project {
               uri
            }
            dawFile {
               version
               uri
            }
         }
         files {
            ...FILE_BASIC_FIELDS
         }
      }
   }
`;

export const GET_COMMENTS_IDS = gql`
   query getComments($id: ID!, $packagedType: Boolean) {
      getComments(id: $id, packagedType: $packagedType) {
         id
      }
   }
`;
export const TOGGLE_LOCK_VERSION = gql`
   ${FILE_BASIC_FIELDS}
   ${FOLDER_FIELDS}
   mutation toggleLockVersion($id: ID!, $isFile: Boolean!) {
      toggleLockVersion(id: $id, isFile: $isFile) {
         file {
            ...FILE_BASIC_FIELDS
         }
         folder {
            ...FOLDER_FIELDS
            path
         }
      }
   }
`;

export const DECLINE_INVITATION = gql`
   mutation declineInvitation($projectId: ID!) {
      declineInvitation(projectId: $projectId) {
         id
         typeName
         uniqueId
         createdBy
         icon
         title
         body
         actionText
         actionUrl
         read
         project {
            id
            title
            uri
            signedCoverImageUrl
            pendingContacts {
               name
               email
               invitationId
            }
            acceptedContacts {
               id
            }
         }
      }
   }
`;

export const DELETE_VERSIONS_FILE = gql`
   ${FILE_BASIC_FIELDS}
   mutation deleteVersionsFile(
      $rootVersionFileId: String!
      $deleteSingleFileId: String
   ) {
      deleteVersionsFile(
         rootVersionFileId: $rootVersionFileId
         deleteSingleFileId: $deleteSingleFileId
      ) {
         ...FILE_BASIC_FIELDS

         versions {
            ...FILE_BASIC_FIELDS
         }
      }
   }
`;

export const DELETE_VERSIONS_FOLDER = gql`
   ${FOLDER_FIELDS}
   mutation deleteVersionsFolder(
      $rootVersionFolderId: String!
      $deleteSingleFolderId: String
   ) {
      deleteVersionsFolder(
         rootVersionFolderId: $rootVersionFolderId
         deleteSingleFolderId: $deleteSingleFolderId
      ) {
         ...FOLDER_FIELDS
         path
         versions {
            ...FOLDER_FIELDS
            path
         }
      }
   }
`;

export const RESTORE_VERSION = gql`
   ${FILE_BASIC_FIELDS}
   mutation restoreVersion($id: ID!) {
      restoreVersion(id: $id) {
         ...FILE_BASIC_FIELDS

         project {
            uri
         }
      }
   }
`;

export const RESTORE_FOLDER_VERSION = gql`
   ${FILE_BASIC_FIELDS}
   mutation restoreFolderVersion($id: ID!) {
      restoreFolderVersion(id: $id) {
         ...FILE_BASIC_FIELDS

         project {
            uri
         }
      }
   }
`;

export const COPY_VERSION = gql`
   ${FILE_BASIC_FIELDS}
   mutation copyVersion(
      $id: ID!
      $name: String
      $keepComments: Boolean
      $keepVersionName: Boolean
   ) {
      copyVersion(
         id: $id
         name: $name
         keepComments: $keepComments
         keepVersionName: $keepVersionName
      ) {
         ...FILE_BASIC_FIELDS

         project {
            uri
         }
      }
   }
`;
export const COPY_FOLDER_VERSION = gql`
   ${FILE_BASIC_FIELDS}
   mutation copyFolderVersion(
      $id: ID!
      $name: String
      $keepComments: Boolean
      $keepVersionName: Boolean
   ) {
      copyFolderVersion(
         id: $id
         name: $name
         keepComments: $keepComments
         keepVersionName: $keepVersionName
      ) {
         ...FILE_BASIC_FIELDS

         project {
            uri
         }
      }
   }
`;

export const UPDATE_VERSION_DETAILS = gql`
   ${FILE_BASIC_FIELDS}
   ${FOLDER_FIELDS}
   mutation updateVersionDetails(
      $id: ID!
      $name: String!
      $description: String
      $isFile: Boolean!
   ) {
      updateVersionDetails(
         id: $id
         name: $name
         description: $description
         isFile: $isFile
      ) {
         file {
            ...FILE_BASIC_FIELDS
         }
         folder {
            ...FOLDER_FIELDS
            path
         }
      }
   }
`;

export const GET_EVENTS = gql`
   ${FILE_BASIC_FIELDS}
   ${FOLDER_FIELDS}
   query getEvents($ids: [ID]!) {
      getEvents(ids: $ids) {
         id
         name
         createdAt
         files {
            ...FILE_BASIC_FIELDS
            project {
               id
               uri
               title
            }
            folder {
               version
            }
         }
         folders {
            ...FOLDER_FIELDS
            path
            project {
               uri
            }
         }
         profile {
            name
            uri
         }
      }
   }
`;

export const SET_ENGAGE_STAT = gql`
   mutation setEngageStat {
      setEngageStat
   }
`;

export const GET_RECOMMENDED_CONNECTIONS = gql`
   query getRecommendedConnections($limit: Int) {
      getRecommendedConnections(limit: $limit) {
         profile {
            id
            uri
            name
            description
            photoUrlOrGravatar
         }
         percentage
         tags {
            id
            name
            uri
         }
      }
   }
`;

export const HAS_DESKTOP_STAT = gql`
   query hasDesktopStat {
      hasDesktopStat
   }
`;

export const REPLACE_BOUNCEDOWN_FILE = gql`
   ${FILE_BASIC_FIELDS}
   mutation replaceBouncedownFile($oldFileId: ID!, $newFileId: ID!) {
      replaceBouncedownFile(oldFileId: $oldFileId, newFileId: $newFileId) {
         ...FILE_BASIC_FIELDS
      }
   }
`;
export const ADD_TAG_FILE_FOLDER = gql`
   mutation addTagFileFolder($id: ID!, $isFile: Boolean, $name: String!) {
      addTagFileFolder(id: $id, isFile: $isFile, name: $name) {
         id
         name
         uri
      }
   }
`;

export const GET_FILE_FOLDER_PERMISSIONS = gql`
   query getFileFolderPermissions($id: ID!, $isFile: Boolean) {
      getFileFolderPermissions(id: $id, isFile: $isFile) {
         type
         owner {
            photoUrlOrGravatar
            name
         }
         collaborators {
            photoUrlOrGravatar
            name
         }
      }
   }
`;

export const ADD_BOUNCEDOWN = gql`
   ${FILE_BASIC_FIELDS}
   ${FOLDER_FIELDS}
   mutation addBouncedown(
      $currentDawId: ID!
      $isFile: Boolean!
      $newFileId: ID!
   ) {
      addBouncedown(
         currentDawId: $currentDawId
         isFile: $isFile
         newFileId: $newFileId
      ) {
         file {
            ...FILE_BASIC_FIELDS
            bouncedown {
               ...FILE_BASIC_FIELDS
            }
         }
         folder {
            ...FOLDER_FIELDS
            bouncedown {
               ...FILE_BASIC_FIELDS
            }
         }
      }
   }
`;
export const ADD_STEMS = gql`
   ${FILE_BASIC_FIELDS}
   ${FOLDER_FIELDS}
   mutation addStems($currentDawId: ID!, $isFile: Boolean!, $newFiles: [ID!]) {
      addStems(
         currentDawId: $currentDawId
         isFile: $isFile
         newFiles: $newFiles
      ) {
         file {
            ...FILE_BASIC_FIELDS
            stems {
               ...FILE_BASIC_FIELDS
            }
         }
         folder {
            ...FOLDER_FIELDS
            stems {
               ...FILE_BASIC_FIELDS
            }
         }
      }
   }
`;

export const ADD_MAILCHIMP_TAG = gql`
   mutation addMailchimpTag($tag: String!) {
      addMailchimpTag(tag: $tag)
   }
`;

export const ADD_TAG_TO_MAICHIMP_USER = gql`
   mutation addTagToMailchimpUser($tag: String!) {
      addTagToMailchimpUser(tag: $tag)
   }
`;
export const REGISTER_MAILCHIMP_WITH_TAG = gql`
   mutation registerMailChimpWithTag($tag: String!, $email: String!) {
      registerMailChimpWithTag(tag: $tag, email: $email)
   }
`;

export const CREATE_FILE_FROM_KEY = gql`
   ${FILE_BASIC_FIELDS}
   mutation createFileFromKey(
      $key: String!
      $originalFileId: ID
      $name: String!
      $size: Int!
      $type: String!
   ) {
      createFileFromKey(
         key: $key
         originalFileId: $originalFileId
         name: $name
         size: $size
         type: $type
      ) {
         ...FILE_BASIC_FIELDS
      }
   }
`;
