import React, {
   useCallback,
   useEffect,
   useMemo,
   useRef,
   useState,
} from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";

import { useMutation } from "@apollo/react-hooks";

import { Link } from "react-router-dom";

import InfiniteScroll from "react-infinite-scroller";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import AppLayout from "_layouts/AppLayout";
import useStatsMutation from "_utils/useStatsMutation";
import CustomModal from "_components/Modal/CustomModal";
import { Done, EditOutlined } from "@mui/icons-material";

import AddContactForm from "_components/AddContactForm";
import Skeleton from "@mui/material/Skeleton";
import { useUser } from "_utils/UserContext";
import { GET_CONTACTS, SEND_INVITE } from "../_apollo/queries";
import useGetConnections from "../_utils/useGetConnections";
import usePagination from "../_utils/usePagination";

const useStyles = makeStyles((theme) => ({
   root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      position: "relative",
      height: "100vh",
      overflowY: "auto",
   },
   rootInner: {
      // padding: "22px 12px",
      height: "100%",
      display: "flex",
      flexDirection: "column",
   },
   card: {
      display: "flex",
      alignItems: "center",
      padding: "6px 10px",
      gap: "10px",
      border: "none",
      borderRadius: 0,
      borderBottom: `solid 1px ${theme.palette.secondary.shade15}`,
      boxShadow: "none",
      minHeight: 70,
   },
   title: {
      fontWeight: "bold",
      fontSize: theme.typography.h5.fontSize,
      lineHeight: "24px",
   },
   containerTitle: {
      borderBottom: `solid 1px ${theme.palette.secondary.shade15}`,
      padding: "24px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
   },
   profileName: {
      marginRight: "auto",
      fontSize: theme.typography.small1.fontSize,
      lineHeight: "20px",
   },
   profileImage: {
      width: 60,
      height: 60,
   },
   noDataBox: {
      height: "25px",
      backgroundColor: theme.palette.secondary.shade5,
      width: "100%",
      textAlign: "center",
      lineHeight: "25px",
      fontSize: "14px",
      borderBottom: `solid 1px ${theme.palette.secondary.shade15}`,
   },
}));

/**
 * Contacts Page
 * @returns {JSX.Component} Contacts Page
 */
const ContactsPage = () => {
   const classes = useStyles();
   const [user, , , loading] = useUser();

   const profile = useMemo(() => user?.profile, [user]);
   const [userContactList, setUserContactList] = useState([]);
   const containerRef = useRef();

   const {
      data: contactList,
      loading: loadingContacts,
      triggerFetchMore: fetchMore,
      hasMore,
      setHasMore,
   } = usePagination(6, GET_CONTACTS);

   useEffect(() => {
      const list =
         contactList?.getContacts?.length > 0
            ? contactList?.getContacts
            : profile?.contacts;
      if (list && list.length > 0) {
         setUserContactList(list);
      }
   }, [contactList, profile]);

   const [connections] = useGetConnections(true, user);

   const [sendInvite] = useMutation(SEND_INVITE);

   const sendStatsMutation = useStatsMutation();

   const [addContactModal, setAddContactModal] = useState(null);

   /**
    * Loading Component
    * @returns {JSX.Component} Loading state of the connections/contacts skeleton
    */
   const LoadingComponent = () => (
      <Card raised={false} variant="outlined" className={classes.card}>
         <Skeleton variant="circular" className={classes.profileImage} />
         <Skeleton variant="text" height={60} width="20%" />
      </Card>
   );

   /**
    * Render Connections
    *
    * Renders a list of the users connections.
    *
    * TODO: candidate for refactoring as sub component to make each component smaller and easier to maintain.
    * @returns {JSX.Component} Connections list
    */
   const renderConnections = () => {
      if (loading) {
         return <LoadingComponent />;
      }
      if (connections?.length < 1) {
         return <div className={classes.noDataBox}>No connections</div>;
      }
      return connections?.map((el) => (
         <Card
            raised={false}
            key={el?.id}
            // variant="outlined"
            className={classes.card}
         >
            <Avatar
               src={
                  el?.photoUrlOrGravatar ||
                  "https://s.gravatar.com/avatar/7d1214b175a9ecd3beb7968da10525a2?default=mp"
               }
               className={classes.profileImage}
            />
            <p className={classes.profileName}>{el?.name || el?.email}</p>

            <Button
               size="small"
               component={Link}
               to={`/${el?.uri}`}
               color="primary"
               variant={el?.accepted ? "contained" : "outlined"}
            >
               {el?.accepted ? "Profile" : "Pending"}
            </Button>
         </Card>
      ));
   };

   /**
    * Render Contacts
    *
    * Renders a list of the users contacts.
    *
    * TODO: candidate for refactoring as sub component to make each component smaller and easier to maintain.
    * @returns {JSX.Component} Contacts list
    */
   const renderContacts = useCallback(() => {
      if (loadingContacts) {
         return <LoadingComponent />;
      }
      if (userContactList?.length === 0) {
         return <div className={classes.noDataBox}>No contacts</div>;
      }

      return userContactList.map((el, i) => (
         <Card
            raised={false}
            key={el?.id}
            variant="outlined"
            className={classes.card}
         >
            <Avatar
               src={
                  el?.photoUrlOrGravatar ||
                  "https://s.gravatar.com/avatar/7d1214b175a9ecd3beb7968da10525a2?default=mp"
               }
               className={classes.profileImage}
            />
            <p className={classes.profileName}>{el?.name || el?.email}</p>

            <div className="mr-4">
               <IconButton
                  color="primary"
                  onClick={() =>
                     setAddContactModal({
                        id: el?.id,
                        name: el?.name,
                        email: el?.email,
                        phone: el?.phoneNumber,
                     })
                  }
               >
                  <EditOutlined />
               </IconButton>
               {!el?.hasProfile && el?.email && (
                  <Button
                     size="small"
                     color="primary"
                     variant={el?.isInvited ? "contained" : "outlined"}
                     onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (el?.isInvited) return;

                        sendInvite({
                           variables: {
                              email: el?.email,
                           },
                        });
                        setUserContactList((prevArr) => {
                           const mutatedArr = [...prevArr];
                           mutatedArr[i] = {
                              ...prevArr[i],
                              isInvited: true,
                           };
                           return mutatedArr;
                        });
                        sendStatsMutation({
                           statsId: "UserInvited",
                           contactId: el?.id,
                           profileId: profile?.id,
                        });
                     }}
                  >
                     {el?.isInvited || el?.email === profile?.email ? (
                        <Done color="primary " />
                     ) : (
                        "Invite"
                     )}
                  </Button>
               )}
            </div>
         </Card>
      ));
   }, [loadingContacts, userContactList]);
   return (
      <AppLayout>
         <div className={classes.root} ref={containerRef}>
            <div className={classes.containerTitle}>
               <div className={classes.title}>Contacts</div>
               <Box>
                  <Button
                     color="primary"
                     variant="contained"
                     onClick={() =>
                        setAddContactModal({ name: "", email: "", phone: "" })
                     }
                  >
                     Add Contact
                  </Button>
               </Box>
            </div>
            <div className={classes.rootInner}>
               <>
                  <Typography
                     color="secondary.dark"
                     variant="small1"
                     padding="10px"
                  >
                     Connections
                  </Typography>
                  <Divider />
                  {renderConnections()}
               </>
               <>
                  <Typography
                     color="secondary.dark"
                     variant="small1"
                     padding="10px"
                  >
                     Contacts
                  </Typography>
                  <Divider />
                  <InfiniteScroll
                     pageStart={0}
                     loadMore={fetchMore}
                     hasMore={hasMore}
                     useWindow={false}
                     getScrollParent={() => containerRef.current}
                  >
                     {renderContacts()}
                  </InfiniteScroll>
               </>
            </div>
         </div>
         <CustomModal
            open={addContactModal}
            handleClose={() => setAddContactModal(null)}
            header={
               <Typography color="primary" variant="modalHeader">
                  Add Contact
               </Typography>
            }
         >
            <Box>
               <AddContactForm
                  callback={() => {
                     setAddContactModal(null);
                     setHasMore(true);
                     fetchMore();
                  }}
                  initialValues={addContactModal}
               />
            </Box>
         </CustomModal>
      </AppLayout>
   );
};

export default ContactsPage;
