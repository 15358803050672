import React, { useState } from "react";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
   examplesCTALink: {
      textDecoration: "none",
      color: "inherit",
      fontSize: "19px",
      lineHeight: "28px",
      maxWidth: "fit-content",
      "&:hover": {
         borderBottom: `2px solid currentColor`,
         marginBottom: "-2px",
      },
   },
}));
/**
 * LinkMarketingPages component renders a link to marketing pages.
 * @param {object} props - The props object.
 * @param {string} props.linkText - The text of the link.
 * @param {string} props.to - The href of the link.
 * @param {string} [props.color] - The color of the link.
 * @param {object} [props.textSize] - Additional styles for text size.
 * @param {string} [props.target] - The target attribute of the link.
 * @param {boolean} [props.external] - Indicates whether the link is external.
 * @returns {JSX.Element} Link to marketing pages.
 */
function LinkMarketingPages({
   linkText,
   to,
   color = "#6147FF",
   textSize = {},
   target = "_self",
   external = false,
}) {
   const classes = useStyles();
   const [hover, setHover] = useState(false);

   return (
      <div
         style={{
            display: "flex",
            alignItems: "center",
            color,
         }}
         onMouseEnter={() => setHover(true)}
         onMouseLeave={() => setHover(false)}
      >
         {external ? (
            <a
               target={target}
               href={to}
               className={classes.examplesCTALink}
               style={textSize}
            >
               {linkText}
            </a>
         ) : (
            <Link
               target={target}
               to={to}
               className={classes.examplesCTALink}
               style={textSize}
            >
               {linkText}
            </Link>
         )}
         {hover && <ArrowOutwardIcon style={{ fill: "currentColor" }} />}
      </div>
   );
}

export default LinkMarketingPages;
