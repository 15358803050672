import React from "react";
import { LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: any) => ({
  mastersLimits: {
    fontSize: "0.75rem",
    lineHeight: "1rem",
    marginBottom: "8px",
    fontWeight: 400,
    color: theme.palette.secondary.shade60,
  },
}));

/**
 * AllowanceComponent
 * @param {object} root0 props.
 * @param {number} root0.currentValue current value of the progress bar.
 * @param {number} root0.limit limit of the progress bar and allowance.
 * @param {string} root0.title text that shows next to the current progress.
 * @returns {React.Component} AllowanceComponent
 */
function AllowanceComponent({ currentValue, limit, title }) {
  const classes = useStyles();

  // Function to normalise the values (MIN / MAX could be integrated) of the limit
  const MIN = 0;
  const normalise = (value) => ((value - MIN) * 100) / (limit - MIN);

  return (
    <div>
      <div className={classes.mastersLimits}>
        {currentValue}/{limit} {title}
      </div>
      <LinearProgress
        sx={{
          height: "7px",
          borderRadius: "6px",
          width: "210px",
        }}
        value={normalise(currentValue)}
        variant="determinate"
      />
    </div>
  );
}

export default AllowanceComponent;
