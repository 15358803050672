import { getFileTypeObject, getGenericName } from "./fileTypeUtil/fileTypeUtil";
import fileTypeData from "./fileTypeUtil/fileTypeData";

/**
 * Is Supported DAW
 *
 * Checks if a file type is a supported DAW file type set in the fileTypeData array.
 * @param {object} props props
 * @param {object} props.file File to check.
 * @param {string} props.fileName Name to check.
 * @returns {boolean} Is the file a DAW type.
 */
const isSupportedDAW = ({ file, fileName }) => {
   if (!file && !fileName) {
      return false;
   }

   const supportedDAWTypes = fileTypeData.reduce((prev, curr) => {
      if (curr.supportedDAW) {
         prev.push(curr.name);
      }
      return prev;
   }, []);

   if (fileName) {
      return supportedDAWTypes.includes(getFileTypeObject(fileName)?.name);
   }

   return supportedDAWTypes.includes(getGenericName(file));
};

export default isSupportedDAW;
