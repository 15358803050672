import { combineReducers } from "redux";

const COL_ID_0 = "column-resize-0";

const APP_REDUCER_INITIAL_STATE = {
  // User
  currentFile: undefined,
  currentAudioTime: undefined,
  currentAudioDuration: undefined,
  mediaPlayerMessages: [],
  isPaused: true,
  fileIndex: undefined,
  managingProfile: undefined,
  isManagingProfile: false,
  selectedTeam: undefined,
  shuffleEnabled: false,
  playedFiles: [],
  showUpgradeModal: false,
  backHistory: 0,
  messageProject: "",
  collapsedAppMenu: window.localStorage.getItem(COL_ID_0) || "260",
  dawHoverComment: null,
  notifications: [],
  showDownloadLinkModal: false,
  networkError: false,
  showLoadingAppModal: false,
};

/**
 * App Reducer
 *
 * The core app redux store.
 * @param {object} state App state.
 * @param {object} action Action to complete.
 * @returns {object} The updated state object.
 */
const AppReducer = (state = APP_REDUCER_INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case "TOGGLE_MAIN_MENU": {
      if (+payload.collapse === 0) {
        return {
          ...state,
        };
      }
      if (payload.collapse) {
        window.localStorage.setItem(COL_ID_0, payload.collapse);
      } else {
        window.localStorage.setItem(COL_ID_0, payload.collapse);
      }
      return {
        ...state,
        collapsedAppMenu: payload.collapse,
      };
    }
    case "SET_BACK_HISTORY": {
      // Load the user into the state
      const { backHistory } = payload;
      return {
        ...state,
        backHistory: state.backHistory + backHistory,
      };
    }
    case "SET_PROJECT_MESSAGE": {
      const { message } = payload;
      return {
        ...state,
        messageProject: message,
      };
    }
    case "SET_UPGRADE_MODAL": {
      return {
        ...state,
        showUpgradeModal: payload,
      };
    }
    case "LOG_OUT_USER": {
      // set state to initial state;
      return {
        ...APP_REDUCER_INITIAL_STATE,
      };
    }
    case "OPEN_LOADING_APP_MODAL": {
      return {
        ...state,
        showLoadingAppModal: payload,
      };
    }
    case "SHOW_MEDIA_PLAYER": {
      const { file, messages, pause } = payload;

      const fileIndex = messages?.findIndex((m) => m.id === file.id);

      return {
        ...state,
        currentFile: file,
        mediaPlayerMessages: messages,
        fileIndex,
        isPaused: pause,
        playedFiles: [...state.playedFiles, file],
      };
    }
    case "PLAY_STOP_TRACK": {
      const { boolPlayPause } = payload;
      return {
        ...state,
        isPaused: boolPlayPause,
      };
    }
    case "CHANGE_TRACK": {
      const { nextOrBack } = payload;
      let newCurrentFile = state.currentFile;
      let newFileIndex = state.fileIndex;
      let playableFiles = [...(state?.mediaPlayerMessages || [])];
      if (state.shuffleEnabled) {
        playableFiles = playableFiles.filter(
          (pf) => state.playedFiles.findIndex((f) => f.id === pf.id) === -1
        );

        playableFiles.sort(() => Math.random() - 0.5);
        const newRandomFile = playableFiles[0];
        const randomFileIndex = state.mediaPlayerMessages?.findIndex(
          (f) => f.id === newRandomFile?.id
        );
        if (newRandomFile && randomFileIndex) {
          return {
            ...state,
            fileIndex: randomFileIndex,
            currentFile: newRandomFile,
            playedFiles: [...state.playedFiles, newRandomFile],
            loopEnabled: false,
            currentAudioTime: APP_REDUCER_INITIAL_STATE.currentAudioTime,
            currentAudioDuration:
              APP_REDUCER_INITIAL_STATE.currentAudioDuration,
          };
        }
        return {
          ...state,
          fileIndex: APP_REDUCER_INITIAL_STATE.fileIndex,
          playedFiles: APP_REDUCER_INITIAL_STATE.playedFiles,
          currentFile: APP_REDUCER_INITIAL_STATE.currentFile,
          currentAudioTime: APP_REDUCER_INITIAL_STATE.currentAudioTime,
          currentAudioDuration: APP_REDUCER_INITIAL_STATE.currentAudioDuration,
        };
      }
      if (state?.mediaPlayerMessages?.length > 0) {
        let direction;
        if (nextOrBack && state.fileIndex + 1 < playableFiles.length) {
          direction = state.fileIndex + 1;
          newFileIndex = state.fileIndex + 1;
          newCurrentFile = playableFiles[direction];
        } else if (!nextOrBack && state.fileIndex - 1 > -1) {
          direction = state.fileIndex - 1;
          newFileIndex = state.fileIndex - 1;
          newCurrentFile = playableFiles[direction];
        }
        return {
          ...state,
          fileIndex: newFileIndex,
          currentFile: newCurrentFile,
          playedFiles: [...state.playedFiles, newCurrentFile],
          loopEnabled: false,
          currentAudioTime: APP_REDUCER_INITIAL_STATE.currentAudioTime,
          currentAudioDuration: APP_REDUCER_INITIAL_STATE.currentAudioDuration,
        };
      }
      return state;
    }
    case "MANAGE_PROFILE": {
      const { teamId, profile } = payload;
      return {
        ...state,
        managingProfile: profile,
        isManagingProfile: !!profile,
        selectedTeam: teamId,
      };
    }
    case "SET_DAW_COMMENT": {
      const { dawMessage } = payload;
      return {
        ...state,
        dawHoverComment: dawMessage,
      };
    }
    case "SHOW_DOWNLOAD_LINK_MODAL": {
      const show = payload;
      return {
        ...state,
        showDownloadLinkModal: show,
      };
    }
    case "SET_ERROR": {
      const networkError = payload;
      return {
        ...state,
        networkError,
      };
    }
    // Default
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  AppReducer,
});

type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
export type { RootState };
