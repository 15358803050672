import React from "react";
import { Button } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import downloadApp from "_utils/downloadApp";
import WindowsIcon from "_assets/icons/WindowsIcon";
import useMediaQuery from "@mui/material/useMediaQuery";

/**
 * Download App Button
 *
 * Button that shows either the Mac or Windows download button based on the user agent.
 * @param {object} props props
 * @param {string} props.variant MUI Button variant, passed through to underlying component.
 * @param {string} props.color MUI Button color, passed through to underlying component.
 * @param {object} props.sx MUI sx, passed through to underlying component.
 * @param {string} props.text text to display in the button.
 * @returns {JSX.Element} Download App Button
 */
const DownloadAppButton = ({
   variant = "outlined",
   color = "light",
   sx = {},
   text,
}) => {
   const isWindowsLike = /(Windows)/i.test(window.navigator.userAgent);
   const mobileView = useMediaQuery("(max-width:900px)");

   return (
      <Button
         variant={variant}
         color={color}
         sx={sx}
         onClick={() => downloadApp()}
      >
         {!mobileView && (
            <>
               {isWindowsLike ? (
                  <WindowsIcon color={color === "light" ? "white" : "black"} />
               ) : (
                  <AppleIcon
                     sx={{ color: color === "light" ? "white" : "black" }}
                  />
               )}{" "}
               &nbsp;
            </>
         )}
         {text && text}
         {!text && "Free download"}
      </Button>
   );
};

export default DownloadAppButton;
