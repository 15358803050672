import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
   row: {
      zIndex: 6,
      cursor: "pointer",
      padding: "5px",
      display: "flex",
      alignItems: "center",
      "& img": {
         height: "24px",
         width: "24px",
         borderRadius: "50%",
         marginRight: "12px",
      },
   },
   highlighted: { backgroundColor: theme.palette.primary.main, color: "white" },
   text: {
      lineHeight: "20px",
      fontSize: theme.typography.small1.fontSize,
   },
}));

/**
 * AutoCompleteItem
 * @param {object} props props
 * @param {string} props.name name
 * @param {string} props.email email
 * @param {Function} props.onSelectItem onSelectItem
 * @param {boolean} props.isHighlighted isHighlighted
 * @param {string} props.photoUrlOrGravatar photoUrlOrGravatar
 * @returns {JSX.Element} AutoCompleteItem component.
 */
const AutoCompleteItem = ({
   name,
   email,
   onSelectItem,
   isHighlighted,
   photoUrlOrGravatar,
}) => {
   const classes = useStyles();

   return (
      <Grid
         container
         variant="contained"
         size="small"
         className={`${classes.row} ${
            isHighlighted ? classes.highlighted : ""
         }`}
         onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onSelectItem(e);
         }}
         onKeyDown={(e) => {
            const { key } = e;
            if (key === "Enter") {
               e.stopPropagation();
               e.preventDefault();
               onSelectItem(e);
            }
         }}
      >
         {photoUrlOrGravatar && <img src={photoUrlOrGravatar} alt="" />}
         <div className={classes.text}>{name || email}</div>
      </Grid>
   );
};

AutoCompleteItem.propTypes = {
   name: PropTypes.string,
   email: PropTypes.string.isRequired,
   onSelectItem: PropTypes.func.isRequired,
   isHighlighted: PropTypes.bool.isRequired,
   photoUrlOrGravatar: PropTypes.string,
};

AutoCompleteItem.defaultProps = {
   name: null,
   photoUrlOrGravatar: null,
};

export default AutoCompleteItem;
