import { useMemo } from "react";
import { SubscriptionPlans } from "_constants/GlobalVariables";
import getUsersPlan from "_utils/getUsersPlan";
import formatBytes from "_utils/formatBytes";

const { Limits } = SubscriptionPlans;

type LimitValues = {
  storagePercentage: number;
  guidePercentage: number;
  storageUsedText: string;
  storageLeftText: string;
};

/**
 * useGetLimitValues
 * react hook to get user limits values
 * @param {User} user User object
 * @returns {LimitValues} object containing storagePercentage, guidePercentage, storageUsedText
 */
const useGetLimitValues = (user): LimitValues => {
  const userSubscriptionObject = useMemo(
    () => getUsersPlan(user?.currentBillingPlan),
    [user]
  );

  const userLimitObj = useMemo(
    () =>
      Limits.find(
        (l) => l.planEnum === (userSubscriptionObject?.modalEnum || 0)
      ),
    [user]
  );

  const data = useMemo(() => {
    const { totalStorageUsed } = user?.limits || 0;
    let storageUsedStr = "";
    let storageLeftStr = "";

    /**
     * usedString
     * returns string for storage used text
     * @param {number} pow  power of storage value to return units specific to plan
     * @returns {string} string for storage used text
     */
    const usedString = (pow: number): string => {
      let unit;
      if (pow === 3) {
        unit = "GB";
      } else {
        unit = "TB";
      }
      return `${convertBytes(totalStorageUsed, pow)}/${convertBytes(
        userLimitObj.storage,
        pow
      )}${unit} Storage Used`;
    };

    /**
     * leftString
     * returns string for storage left text
     *
     * NOTE: the remaining storage will never go negative, ie. you can't have -1GB of storage left. It will show 0GB of storage left in this case. Where as percentage used can exceed 100%.
     * @returns {string} string for storage left text
     */
    const leftString = (): string => {
      if (totalStorageUsed >= userLimitObj.storage) {
        return `0GB storage left`;
      }
      return `${formatBytes(
        userLimitObj.storage - totalStorageUsed
      )} storage left`;
    };

    /**
     * convertBytes
     * converts bytes to higher storage value using given power.
     * @param {number} bytes bytes to convert
     * @param {number} pow power of higher storage value
     * @returns {number} higher storage value
     */
    const convertBytes = (bytes, pow) => {
      const convertedValue = bytes / 1024 ** pow;
      return Math.floor(convertedValue);
    };

    // Updated 20 April 2023 to always show GB remaining, otherwise at the TB scale this will just show 0TB remaining always. We could update to show decimals for TB like 0.5TB but GB has better resolution.
    storageUsedStr = usedString(3);
    storageLeftStr = leftString();

    return {
      storagePercentage: Math.floor(
        (totalStorageUsed / userLimitObj.storage) * 100
      ),
      guidePercentage:
        userLimitObj.guideAccessAmount === 0
          ? 100
          : Math.floor(
              ((userLimitObj.guideAccessAmount -
                user?.limits?.guideAccessLeft) /
                userLimitObj.guideAccessAmount) *
                100
            ),
      storageUsedText: storageUsedStr,
      storageLeftText: storageLeftStr,
    };
  }, [userSubscriptionObject, userLimitObj, user]);
  return data;
};

export default useGetLimitValues;
