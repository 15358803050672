import fileTypeData from "./fileTypeData";

// TODO: these helpers are prime for unit testing and switching to typescript as they are so fundamental to the app and are easy to test.
// TODO: split these out like the isSupportedDAW and isDawPackageType helpers. It may seem like more files but having each file do one thing is a good thing makes it easier to find what you need and easier to test.

/**
 * Get file extension.
 * @param {object} fileFolder File instance.
 * @returns {string} extension of the file
 */
const getExtension = (fileFolder) => {
   if (!fileFolder) {
      return "";
   }
   if (!fileFolder.name && !fileFolder.originalName) {
      return "";
   }

   const name =
      fileFolder instanceof File
         ? fileFolder.name
         : fileFolder.originalName || fileFolder.name;

   // TODO: update this function to use the getFileTypeObj function.
   // if band use lastIndexOf .band
   if (name.includes(".band")) {
      const sliceIndex = name.lastIndexOf(".band");
      return name.slice(sliceIndex).slice(0, ".band".length);
   }
   // if logic use lastIndexOf .logic
   if (name.includes(".logic")) {
      const sliceIndex = name.lastIndexOf(".logic");
      return name.slice(sliceIndex).slice(0, ".logic".length);
   }
   // if logicx use lastIndexOf .logicx
   if (name.includes(".logicx")) {
      const sliceIndex = name.lastIndexOf(".logicx");
      return name.slice(sliceIndex).slice(0, ".logicx".length);
   }
   // if als use lastIndexOf .als
   if (name.slice(-4) === ".als") {
      const sliceIndex = name.lastIndexOf(".als");
      return name.slice(sliceIndex).slice(0, ".als".length);
   }
   const sliceIndex = name.lastIndexOf(".");
   return name.slice(sliceIndex);
};

/**
 * Get file type object.
 * @param {object} fileName File name to check.
 * @returns {object} file type object
 */
const getFileTypeObject = (fileName) => {
   const extension = getExtension({ originalName: fileName });
   const fileTypeObj = fileTypeData.find((fileType) =>
      fileType.ext.some(
         (singleExtension) => singleExtension === extension.toLowerCase() // File extensions are case insensi so we most lowercase to ensure a match.
      )
   );

   return fileTypeObj;
};

/**
 * Return matching file type data object.
 * @param {object} file File instance.
 * @returns {object} file data object.
 */
const getFileTypeObj = (file) => {
   const ext = getExtension(file);
   const fileTypeObj = fileTypeData.find((fileType) =>
      fileType.ext.some(
         (singleExtension) => singleExtension === ext.toLowerCase() // File extensions are case insensitive so we most lowercase to ensure a match.
      )
   );
   if (fileTypeObj) {
      return fileTypeObj;
   }
   return {
      ext: [""],
      name: "Other",
      mimeType: ["application/other"],
   };
};

/**
 * Get file mimeType from name.
 * @param {object} file File instance.
 * @returns {string} mime type of file.
 */
const getMimeType = (file) => {
   return getFileTypeObj(file)?.mimeType || "other";
};

/**
 * Get file generic name.
 * @param {object} file File instance.
 * @returns {string} file name.
 */
const getGenericName = (file) => {
   return getFileTypeObj(file)?.name;
};

/**
 * Get File Name from Path
 * @param {string} filePath File path.
 * @returns {string} file name.
 */
const getFileName = (filePath) => {
   // eslint-disable-next-line no-useless-escape
   return filePath.replace(/^.*(\\|\/|\:)/, "");
};

/**
 * Get File Base Path
 * @param {string} filePath File path.
 * @returns {string} file name.
 */
const getFileBasePath = (filePath) => {
   return filePath.substring(0, filePath.length - getFileName(filePath).length);
};

/**
 * Get file name without extension.
 * @param {object} file File instance.
 * @returns {string} file name without extension.
 */
const getNameWithoutExtension = (file) => {
   if (!file) {
      return "";
   }

   // Garageband and Logic files are bundle file types so they require special handling.
   // if band use lastIndexOf .band
   // TODO: this should be updated to use the getFileTypeObj function.
   if (file?.originalName.includes(".band")) {
      const sliceIndex = file.originalName.lastIndexOf(".band");
      return file.originalName.slice(0, sliceIndex);
   }
   // if logic use lastIndexOf .logic
   if (file?.originalName.includes(".logic")) {
      const sliceIndex = file.originalName.lastIndexOf(".logic");
      return file.originalName.slice(0, sliceIndex);
   }
   // if logicx use lastIndexOf .logicx
   if (file?.originalName.includes(".logicx")) {
      const sliceIndex = file.originalName.lastIndexOf(".logicx");
      return file.originalName.slice(0, sliceIndex);
   }
   const sliceIndex = file.originalName.lastIndexOf(".");
   if (sliceIndex === -1) {
      return file.originalName;
   }
   return file.originalName.slice(0, sliceIndex);
};

/**
 * Get DAW Root Folder Path
 *
 * Takes a file path and if it's a DAW Package file then return the root folder path.
 * @param {string} filePath Root path of the DAW package file.
 * @returns {string|false} root folder path or false if no package type file is found.
 */
const getDAWRootFolderPath = (filePath) => {
   const DAWPackageFileExtensions = fileTypeData
      .reduce((acc, fileType) => {
         if (fileType.packageFile) {
            acc.push(...fileType.ext);
         }
         return acc;
      }, [])
      .sort((a, b) => {
         // Sorting is super important otherwise logicx files will be matched before logic files.
         return b.length - a.length;
      });

   // Loop through each package file extension and split and return if a matching extension is found.
   // eslint-disable-next-line no-restricted-syntax
   for (const extension of DAWPackageFileExtensions) {
      const splitIndex = filePath.lastIndexOf(extension);
      if (splitIndex !== -1) {
         return filePath.slice(0, splitIndex + extension.length); // Add back the extension!
      }
   }

   return false;
};

/**
 * Strip zip from extension.
 * @param {object} file file instance.
 * @returns {string} file original name wihtout the .zip extension.
 */
const stripZipFromExtension = (file) => {
   const fileObj = getFileTypeObj(file);
   if (fileObj?.packageFile && file.originalName.slice(-4) === ".zip") {
      return file.originalName.slice(0, file.originalName.length - 4);
   }
   return file.originalName;
};

/**
 * Skip Hidden Files.
 *
 * Filters an array of files and excludes the non allowed files.
 *
 * TODO: generalise this to any file that starts with .
 * TODO: update this to use typescript as it failed with typeerror.
 * @param {Array} files files to be filtered.
 * @returns {Array} filtered array of files.
 */
const filterHiddenFiles = (files = []) => {
   return files.filter((file) => {
      if (!file || (!file?.originalName && !file?.name)) {
         return false;
      }
      const { originalName, name } = file;

      return (
         originalName !== ".DS_Store" &&
         originalName !== "Icon?" &&
         originalName !== ".musicapps-project-folder" &&
         name !== ".DS_Store" &&
         name !== "Icon?" &&
         name !== ".musicapps-project-folder"
      );
   });
};

export {
   getFileTypeObject,
   getExtension,
   getMimeType,
   getGenericName,
   getFileBasePath,
   getNameWithoutExtension,
   getDAWRootFolderPath,
   stripZipFromExtension,
   filterHiddenFiles,
   getFileTypeObj,
};
