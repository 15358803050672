import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { GET_FILE_FOLDER } from "../_apollo/queries";

/**
 * Get DAW File Folder Custom Hook
 *
 * Get's the specified file or folder from apollo and managed cache updating and websocket subscriptions for comments.
 * @param {Function} callback callback to be called after file is found.
 * @returns {object} Apollo GET_FILE_FOLDER query object.
 */
const useGetDAWFileFolder = (callback) => {
   const { fileFolderURI } = useParams();

   const { data, refetch, loading, startPolling, stopPolling } = useQuery(
      GET_FILE_FOLDER,
      {
         variables: {
            fileFolderURI,
         },
         /**
          * getFilesInfo onCompleted
          * @param {object} fileData Apollo query response data.
          */
         onCompleted(fileData) {
            callback(fileData);
         },
      }
   );

   return { data, refetch, loading, startPolling, stopPolling };
};

export default useGetDAWFileFolder;
