import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { useQueryParam, StringParam } from "use-query-params";
import { useEffect } from "react";
import appStorage from "_utils/appStorage";
import useSignOut from "_utils/useSignOut";
import { GET_USER_PROFILE } from "../_apollo/queries";

/**
 * Sign Out If Un Authorised Hook
 *
 * Should redirect the user if to their feed page if the user has a valid
 * token which fetches a valid user object.
 *
 * This hook should be called on: sign up, login or password reset pages.
 */
function useSignOutIfUnAuthorised() {
   const [hash] = useQueryParam("hash", StringParam);
   const history = useHistory();
   const authToken = appStorage.getValue({ key: "auxJWT" });
   const signOut = useSignOut();

   const { loading, data, refetch } = useQuery(GET_USER_PROFILE, {
      fetchPolicy: "cache-and-network", // Avoid cache results in case the token has expired since last caching.
      skip: !authToken,
      /**
       * getUserProfile onCompleted
       * @param {object} profile Apollo data response for getUserProfile query.
       */
      onCompleted(profile) {
         if (!profile?.profileDetails) {
            // Sign Out and delete all of the users session data.
            signOut();
            // Redirect them to the signin page
            history.push(hash ? `/signin?hash=${hash}` : "/signin");
         }
      },
   });

   // Check if the user is logged in on each page view.
   useEffect(() => {
      const unlisten = history?.listen(() => {
         refetch();
      });

      return () => {
         unlisten();
      };
   }, [history]);
   if (!authToken) {
      history.push(hash ? `/signin?hash=${hash}` : "/signin");
   }

   if (!loading) {
      if (!data?.profileDetails) {
         // Sign Out and delete all of the users session data.
         signOut();
         // Redirect them to the signin page
         history.push(hash ? `/signin?hash=${hash}` : "/signin");
      }
   }
}

export default useSignOutIfUnAuthorised;
