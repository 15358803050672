import {
  Box,
  Button,
  LinearProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import useGetLimitValues from "_utils/useGetLimitValues";
import { useUser } from "_utils/UserContext";
import makeStyles from "@mui/styles/makeStyles";
import getUsersPlan from "_utils/getUsersPlan";
import { Link } from "react-router-dom";
import { SubscriptionPlans } from "_constants/GlobalVariables";
import UpgradeSubscriptionModal from "_components/Modal/UpgradeSubscriptionModal";

const useStyles = makeStyles((theme) => ({
  storageCard: {
    backgroundColor: "#F1EFFE", // theme.palette.pastel.purple,
    "& *": {
      color: "black",
    },
  },
  planType: {
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "11px",
  },
  storageLeft: {
    textAlign: "center",
    marginTop: "7px",
    marginBottom: "10px",
  },
  upgradeButton: {
    margin: "0px auto",
    color: "white !important",
    backgroundColor: "black",
    fontSize: "14px",
    "&:hover": {
      color: "white !important",
      backgroundColor: "black",
    },
  },
}));

const { ArrayPlans } = SubscriptionPlans;

/**
 * StorageCard
 * component to display storage card
 * @returns {JSX.Element} storage card component
 */
const StorageCard = () => {
  const classes = useStyles();
  const [user] = useUser();
  const { storagePercentage, storageLeftText } = useGetLimitValues(user);
  const userSubscriptionObject = useMemo(
    () => getUsersPlan(user?.currentBillingPlan),
    [user]
  );

  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);

  /**
   * getNextPlanUsingPrice
   * getNextPlanUsingPrice gets the next plan using the price of the current plan.
   */
  const getNextPlanUsingPrice = useMemo(() => {
    if (!userSubscriptionObject) {
      return null;
    }
    const nextPlan = ArrayPlans.find(
      (plan) =>
        plan.price > userSubscriptionObject.price &&
        plan.type === userSubscriptionObject.type &&
        plan.currency === userSubscriptionObject.currency
    );
    return nextPlan;
  }, [userSubscriptionObject]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.storageCard}
      px="15px"
      py="12px"
      m="10px"
      borderRadius="4px"
    >
      {openSubscriptionModal && (
        <UpgradeSubscriptionModal
          title="Upgrade to take your music production to the next level"
          description="and get access to samples and loops, mastering and project storage"
          open={openSubscriptionModal}
          handleClose={() => setOpenSubscriptionModal(false)}
        />
      )}
      <Typography className={classes.planType} variant={"medium" as any}>
        {userSubscriptionObject?.label} Plan
      </Typography>
      <LinearProgress
        sx={{ height: "7px", borderRadius: "6px" }}
        variant="determinate"
        value={Math.min(100, storagePercentage)}
      />
      <Typography className={classes.storageLeft} variant={"small" as any}>
        {storageLeftText}
      </Typography>
      <Button
        onClick={() => setOpenSubscriptionModal(true)}
        className={classes.upgradeButton}
      >
        {getNextPlanUsingPrice?.label ? (
          <>Upgrade to {getNextPlanUsingPrice?.label ?? "Pro"}</>
        ) : (
          <>Manage Plan</>
        )}
      </Button>
    </Box>
  );
};

export default StorageCard;
