import {
   Box,
   CircularProgress,
   Container,
   Grid,
   Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import AuxOpenMusicLogo from "_assets/AuxOpenMusicLogo";
import Squiggle from "_assets/Squiggle";
import Footer from "_components/Footer";
import MetaTags from "_components/MetaTags";
import SignUpForm from "_components/SignUpForm";
import isItDark from "_utils/isItDark";
import * as Sentry from "@sentry/react";
import TimeToGetToWork from "_components/TimeToGetToWork";
import HiringBadge from "_components/HiringBadge";
import api from "../_utils/ghostContentApi";
import Features from "./HomePage/Features";
import RemoteCollaboration from "./HomePage/RemoteCollaboration";
import FeedbackCards from "./HomePage/FeedbackCards";
import GuideMainPanel from "./HomePage/GuideMainPanel";
import GuideOverlayIcons from "./HomePage/GuideOverlayIcons";

const useStyles = makeStyles((theme) => ({
   root: {
      "& h2, & h3": {
         fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
      },
   },
   containerMain: {
      position: "relative",
      paddingBottom: "90px",
      boxSizing: "border-box",
      padding: 80,
      "@media (max-width: 575px)": {
         display: "flex",
         flexDirection: "column",
         alignItems: "center",

         "& h1": {
            fontSize: 40,
         },

         "& p": {
            fontSize: 16,
         },
         padding: 30,
      },
   },
   imageMain: {
      position: "absolute",
      zIndex: -1,
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundSize: "cover",
      backgroundPosition: "center center",
      whiteSpace: "nowrap",
      overflow: "hidden",
      background: `linear-gradient(90deg, #B1EDFF 0%, rgba(177, 237, 255, 0) 100%),
      linear-gradient(180deg, #0CECC4 0%, rgba(12, 236, 196, 0) 100%), 
      #6147FF`,
   },
   mainView: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "10px",
   },
   squiggle: {
      position: "absolute",
      top: 125,
      zIndex: "-1",
      "@media (max-width: 575px)": {
         display: "none",
      },
   },
   title: {
      textAlign: "center",
      fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
      maxWidth: "800px",
      marginLeft: "auto",
      marginRight: "auto",
   },
   white: {
      color: "white",
   },
   ghostHtml: theme.ghostHtml,
   section: {
      fontSize: theme.typography.h4.fontSize,
      width: "60%",
      margin: "auto",
      "@media (max-width: 770px)": {
         width: "80%",
      },
   },
   footerGradiant: {
      position: "absolute",
      zIndex: -1,
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundSize: "cover",
      backgroundPosition: "center center",
      whiteSpace: "nowrap",
      overflow: "hidden",
      background: `linear-gradient(90deg, #FFDCC9 0%, rgba(255, 220, 201, 0) 100%),
      linear-gradient(180deg, #0CECC4 0%, rgba(12, 236, 196, 0) 100%), 
      #6147FF;`,
   },
}));

/**
 * Landing Page
 *
 * Page component used to show custom ghost landing pages.
 * @returns {JSX.Element} Landing page component.
 */
const LandingPage = () => {
   const history = useHistory();
   const classes = useStyles();
   const { slug } = useParams();
   const [ghostPage, setGhostPage] = useState(undefined);

   // Update styles for ghost gallery images.
   useEffect(() => {
      if (ghostPage?.html) {
         const galleryImagesDivs =
            document.querySelectorAll(".kg-gallery-image");
         galleryImagesDivs.forEach((div) => {
            const mutatedDiv = div;
            const image = div.querySelector("img");
            const ratio = image.width / image.height;
            mutatedDiv.style.flex = `${ratio} 1 0%`;
         });
      }
   }, [ghostPage]);

   /**
    * Fetch Post
    *
    * Fetches this pages post from ghost using the ghost API.
    */
   const fetchPost = async () => {
      let page;
      try {
         page = await api.pages.read({ slug });
         if (page) {
            setGhostPage({
               ...page,
            });
            window.scrollTo({ top: 0 });
         } else {
            history.push("/404");
         }
      } catch (error) {
         Sentry.captureException(error);
         history.push("/404");
      }
   };

   useEffect(() => {
      if (slug) {
         fetchPost();
      }
   }, [slug]);

   // Variable used to switch between light and dark text based on the feature image dominant brightness.
   const [whiteText, setWhiteText] = useState(false);

   useEffect(() => {
      if (ghostPage?.feature_image) {
         isItDark(ghostPage?.feature_image, (isDark) => {
            setWhiteText(isDark);
         });
      }
   }, [ghostPage?.feature_image]);

   const replacementTags = useMemo(
      () => ghostPage?.html.match(/\[[A-Z]+\]/g),
      [ghostPage]
   );

   /**
    * Replace Tag With Component
    * @param {string} tag Tag string to replace with.
    * @returns {JSX.Element|null} Element for matching tag or null.
    */
   const replaceTagWithComponent = (tag) => {
      switch (tag) {
         case "[FEATURES]":
            return <Features />;
         case "[REMOTECOLLABORATION]":
            return <RemoteCollaboration />;
         case "[QUOTES]":
            return <FeedbackCards />;
         case "[SIGNUP]":
            return (
               <TimeToGetToWork
                  style={{
                     background:
                        "linear-gradient(239.18deg, #6D55FF 0%, rgba(97, 71, 255, 0) 58.6%), linear-gradient(-44.46deg, #FF9052 15.62%, rgba(255, 136, 69, 0) 52.75%), linear-gradient(90deg, #FFAFDA 0%, rgba(255, 220, 201, 0) 100%)",
                  }}
               />
            );
         case "[DISCOVER]":
            return (
               <>
                  <GuideMainPanel />
                  <GuideOverlayIcons />
               </>
            );
         default:
            return null;
      }
   };

   if (!ghostPage) {
      return (
         <Grid
            height="100vh"
            container
            justifyContent="center"
            alignItems="center"
         >
            <CircularProgress />
         </Grid>
      );
   }

   return (
      <div className={classes.root}>
         <HiringBadge />
         <MetaTags
            title={ghostPage.title}
            image={ghostPage.feature_image}
            description={ghostPage.excerpt}
         />
         <div className={classes.containerMain}>
            <div
               className={classes.imageMain}
               style={
                  ghostPage?.feature_image
                     ? {
                          backgroundImage: `url(${ghostPage?.feature_image})`,
                       }
                     : {}
               }
            />

            <Box marginBottom="50px">
               <Link to="/">
                  <AuxOpenMusicLogo
                     style={whiteText ? { fill: "white" } : {}}
                  />
               </Link>
            </Box>

            <Container className={classes.mainView}>
               <div className={classes.squiggle}>
                  <Squiggle />
               </div>
               <Typography
                  variant="big2"
                  component="h1"
                  marginBottom="25px"
                  className={`${classes.title} ${
                     whiteText ? classes.white : ""
                  }`}
               >
                  {ghostPage.title}
               </Typography>
               <Typography
                  className={whiteText ? classes.white : ""}
                  variant="h5"
                  maxWidth={540}
                  component="p"
                  textAlign="center"
               >
                  {ghostPage.excerpt}
               </Typography>
               <Box maxWidth={410} marginTop="25px">
                  <SignUpForm darkMode={whiteText} />
               </Box>
            </Container>
         </div>
         {/* Split the content by the placeholder tags used to fill sections on the landing page. */}
         {ghostPage?.html.split(/\[[A-Z]+\]/g).map((content, index) => (
            <>
               {/* Content length of 8 is an empty paragraph so hide it. */}
               {content.length > 8 && (
                  <div className={classes.section}>
                     <article className={classes.ghostHtml}>
                        <span
                           // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={{
                              __html: content,
                           }}
                        />
                     </article>
                  </div>
               )}
               {replacementTags &&
                  replaceTagWithComponent(replacementTags[index])}
            </>
         ))}
         <div className={classes.containerMain}>
            <div className={classes.footerGradiant} />
            <Grid container justifyContent="center">
               <Box
                  maxWidth={410}
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
               >
                  <Box
                     marginBottom="50px"
                     display="flex"
                     justifyContent="center"
                  >
                     <AuxOpenMusicLogo />
                  </Box>
                  <Typography textAlign="center" variant="h2" fontWeight="800">
                     Your new friend in the industry.
                  </Typography>
                  <SignUpForm />
               </Box>
            </Grid>
         </div>
         <Footer />
      </div>
   );
};

export default LandingPage;
