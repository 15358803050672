import React, { useState } from "react";
import {
  Button,
  Typography,
  IconButton,
  InputBase,
  useTheme,
} from "@mui/material/";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import FileArchiveActionButtons from "_components/FileArchiveActionButtons";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import stringIsMultiple from "_utils/stringIsMultiple";

const useStyles = makeStyles((theme: any) => ({
  buttonContainer: {
    // padding: "0px 25px 20px 25px",
    paddingRight: "30px",
    flexWrap: "nowrap",
    display: "flex",
    height: "100%",
  },
  rightButtonContainer: {
    display: "flex",
    marginLeft: "auto",
    alignItems: "center",
    [`& button, & label`]: {
      whiteSpace: "nowrap",
    },
    [`& .MuiIconButton-root`]: {
      padding: 8,
    },
  },
  form: {
    display: "flex",
    alignItems: "center",
    width: 400,
    height: 36,
    backgroundColor: theme.palette.secondary.shade5,
    borderRadius: 4,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    flexShrink: 1,
    minWidth: 100,
    fontSize: "14px",
  },
}));

const HeaderActionsTrash = ({
  files,
  folders,
  selectedFiles,
  selectedFolders,
  totalSelectedFiles,
  setShowDeleteModal,
  trashFilesFolders,
}) => {
  const theme: any = useTheme();
  const classes = useStyles();

  const [collapsedSearch, setCollapsedSearch] = useState(true);
  const [search, setSearch] = useState("");

  // From an array of files/folders, return an array of ids.
  // Used for queries/mutations.
  const getIds = (fList) => fList.map((f) => f.id);

  return (
    <div className={classes.buttonContainer}>
      <div className={classes.rightButtonContainer}>
        {selectedFiles.length === 0 && selectedFolders.length === 0 && (
          <>
            {collapsedSearch ? (
              <IconButton
                type="submit"
                aria-label="search"
                onClick={() => setCollapsedSearch(!collapsedSearch)}
              >
                <SearchIcon sx={{ color: theme.palette.coreApp.text }} />
              </IconButton>
            ) : (
              <div className={classes.form}>
                <IconButton
                  type="submit"
                  style={{
                    padding: "5px 0px 5px 5px",
                    backgroundColor: "transparent",
                  }}
                  aria-label="search"
                  onClick={() => {
                    setCollapsedSearch(!collapsedSearch);
                    setSearch("");
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <InputBase
                  className={classes.input}
                  placeholder="Search"
                  autoComplete="off"
                  inputProps={{ "aria-label": "search" }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            )}
          </>
        )}
        {selectedFolders.length > 0 || selectedFiles.length > 0 ? (
          <>
            {totalSelectedFiles > 0 && (
              <Typography
                component="span"
                style={{ minWidth: "109px", fontSize: "14px" }}
              >
                {`${totalSelectedFiles} ${stringIsMultiple(
                  totalSelectedFiles,
                  "item"
                )} selected`}
              </Typography>
            )}

            <FileArchiveActionButtons
              setShowDeleteModal={setShowDeleteModal}
              trashFilesFolders={() =>
                trashFilesFolders({
                  variables: {
                    fileIds: getIds(selectedFiles),
                    folderIds: getIds(selectedFolders),
                  },
                })
              }
            />
          </>
        ) : (
          <Button
            disabled={
              (!files || files?.length === 0) &&
              (!folders || folders?.length === 0)
            }
            sx={{ marginLeft: "25px" }}
            variant="contained"
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            Empty trash
          </Button>
        )}

        <Button
          size="small"
          color="primary"
          component={Link}
          variant="contained"
          to="/files"
          //  sx={{ p: "6px 12px 6px 0px" }}
          sx={{ ml: "10px" }}
        >
          {/* <ChevronLeftIcon sx={{ fill: "black" }} /> */}
          Back to Files
        </Button>
      </div>
    </div>
  );
};

export default HeaderActionsTrash;
