import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory, useLocation } from "react-router-dom";
import { Button, LinearProgress } from "@mui/material";
import AppHeader from "_components/Header/AppHeader";
import CloseIcon from "@mui/icons-material/Close";
import { useUser } from "_utils/UserContext";
import { SubscriptionPlans } from "_constants/GlobalVariables";
import ErrorBanner from "_components/ErrorBanner";
import getUsersPlan from "_utils/getUsersPlan";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useSignOut from "_utils/useSignOut";
import useStatsMutation from "_utils/useStatsMutation";
import appStorage from "_utils/appStorage";
import UpgradeSubscriptionModal from "_components/Modal/UpgradeSubscriptionModal";

const useStyles = makeStyles((theme) => ({
   draggableElectronHeader: {
      "-webkit-user-select": "none",
      "-webkit-app-region": "drag",
   },
   wrapperContHeader: {
      position: "relative",
      height: "52px",
      padding: "0px 16px",
      borderBottom: `solid 1px ${theme.palette.coreApp.borders}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
   },
   wrapperContHeaderMain: {
      width: "100vw",
      height: "100%",
      overflowY: "hidden",
      display: "flex",
      flexDirection: "column",
   },
   containerHeader: {
      display: "flex",
      position: "absolute",
      width: "100vw",
      maxHeight: "100vh",
      maxWidth: "100vw",
   },
   limitsWarning: {
      backgroundColor: theme.palette.danger.main,
      height: "44px",
      display: "flex",
      alignItems: "center",
      padding: "0 24px",
      "& span": {
         color: "white",
         fontSize: "12px",
         lineHeight: "16px",
      },
   },
   linearProgressBar: {
      flexGrow: 100,
      margin: "0 20px",
      height: "7px",
      borderRadius: "6px",
      backgroundColor: "#FF4539",
   },
}));

const { Limits } = SubscriptionPlans;

/**
 * Layout Wrapper
 * @param {object} props props.
 * @param {JSX.Element} props.children props.
 * @returns {JSX.Element} Layout Wrapper HOC.
 */
const LayoutWrapper = ({ children }) => {
   const classes = useStyles();
   const history = useHistory();
   const location = useLocation();
   const currentFile = useSelector((state) => state.AppReducer.currentFile);
   const [user, , , , setFolderUri] = useUser();
   const { folderUri } = useParams();

   const sendStatsMutation = useStatsMutation();
   const signOut = useSignOut();
   const [openUpgradeModal, setOpenUpgradeModal] = useState(false);

   useEffect(() => {
      const deviceId = appStorage.getValue({ key: "auxDeviceID" });
      const currentDevice = user?.devices?.find(
         (device) => device.deviceId === deviceId
      );
      // Allow the user to force logout on other devices by setting them to active=false.
      if (currentDevice && currentDevice.active === 0) {
         sendStatsMutation({
            statsId: "UserLogOut",
            userId: user.id,
         });
         signOut();
      }
      if (user && !user?.currentBillingPlan && user?.createdAt) {
         // Don't show the popup until the user is at least 3 days old!
         if (new Date() - new Date(user.createdAt) > 1000 * 60 * 60 * 24 * 3) {
            const date = appStorage.getValue({
               key: "freePlanPopup",
            });
            // if there has been more than 7 days since the last popup, show the popup again.
            if (
               !date ||
               new Date() - new Date(date) > 1000 * 60 * 60 * 24 * 7
            ) {
               setOpenUpgradeModal(true);
               appStorage.setValue({
                  key: "freePlanPopup",
                  value: new Date(),
               });
            }
         }
      }
   }, [user]);

   useEffect(() => {
      setFolderUri(folderUri);
   }, [folderUri]);

   const [, setCanGoForward] = useState(true);

   const userSubscriptionObject = useMemo(
      () => getUsersPlan(user?.currentBillingPlan),
      [user]
   );

   const userLimitObj = useMemo(
      () =>
         Limits.find(
            (l) => l.planEnum === (userSubscriptionObject?.modalEnum || 0)
         ),
      [user]
   );

   const { storagePercentage } = useMemo(() => {
      return {
         storagePercentage: Math.floor(
            (user?.limits?.totalStorageUsed / userLimitObj.storage) * 100
         ),
      };
   }, [userLimitObj, user]);

   useEffect(() => {
      setCanGoForward(true);
   }, [location]);

   const [hide, setHide] = useState(
      !!sessionStorage.getItem("hideWarningLimit")
   );

   return (
      <div
         className={classes.containerHeader}
         style={{
            height: `calc(100% - ${currentFile ? "47" : "0"}px)`,
         }}
      >
         {openUpgradeModal && (
            <UpgradeSubscriptionModal
               title="Time to upgrade!"
               description="Unlock samples and loop, more storage, mastering, and more."
               open={openUpgradeModal}
               handleClose={() => setOpenUpgradeModal(false)}
            />
         )}
         <div className={classes.wrapperContHeaderMain}>
            <div>
               <div
                  className={`${classes.draggableElectronHeader} ${classes.wrapperContHeader}`}
               >
                  <AppHeader />
               </div>

               {storagePercentage > 79 && !hide && (
                  <div className={classes.limitsWarning}>
                     <span>
                        {storagePercentage > 99
                           ? "Your storage is full, upgrade to expand storage"
                           : `
                        Your storage space is ${storagePercentage}% full, upgrade
                        to expand storage`}
                     </span>
                     <LinearProgress
                        className={classes.linearProgressBar}
                        value={
                           storagePercentage > 100 ? 100 : storagePercentage
                        }
                        variant="determinate"
                        color="light"
                     />
                     <Button
                        onClick={() => history.push("/settings#subscription")}
                        color="light"
                        variant="outlined"
                        size="small"
                     >
                        Upgrade now
                     </Button>
                     <Button
                        color="light"
                        size="small"
                        disabled={storagePercentage > 99}
                        onClick={() => {
                           sessionStorage.setItem("hideWarningLimit", true);
                           setHide(true);
                        }}
                     >
                        <CloseIcon />
                     </Button>
                  </div>
               )}
            </div>
            <ErrorBanner />
            {children}
         </div>
      </div>
   );
};

LayoutWrapper.propTypes = {
   children: PropTypes.shape().isRequired,
};

export default LayoutWrapper;
