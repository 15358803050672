import appStorage from "_utils/appStorage";
import { useUploadData } from "_utils/UploadDataContext";
import CryptoJS from "crypto-js";
import getConfig from "./getConfig";

const { restUrl } = getConfig();
/**
 * Download zip custom hook.
 *
 * When downloading a zip it sets the processing notification of the zip download.
 *
 * @returns {Function} download zip.
 */
const useDownloadZip = () => {
  const { setDownloadingZips } = useUploadData();
  const getIds = (fList) => fList.map((f) => f.id);

  /**
   * Download ZIP
   *
   * @param {Array} selectedFiles Files to download.
   * @param {Array} selectedFolders Folders to download recursively.
   * @param {Function} callback function to run after downloadZip.
   */
  const downloadZip = async (
    selectedFiles = [],
    selectedFolders = [],
    callback
  ) => {
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1; // months from 1-12
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    const newDate = `${year}-${month}-${day}`;
    const randomString = CryptoJS.lib.WordArray.random(4);

    let name = `Aux-Files-${newDate}-${randomString}.zip`;
    try {
      const controller = new AbortController();
      const { signal } = controller;

      if (selectedFiles.length === 1 && selectedFolders.length === 0) {
        name = `${selectedFiles[0].originalName}-${newDate}-${randomString}.zip`;
      } else if (selectedFiles.length === 0 && selectedFolders.length === 1) {
        name = `${selectedFolders[0].name}-${newDate}-${randomString}.zip`;
      }
      setDownloadingZips((prev) => [
        ...prev,
        { name, cancelRequest: controller },
      ]);
      await fetch(`${restUrl}/download/zip`, {
        credentials: "include",
        signal,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer: ${appStorage.getValue({ key: "auxJWT" })}`,
          auxSelectedProfileURI: appStorage.getValue({
            key: "auxSelectedProfileURI",
          }),
        },
        body: JSON.stringify({
          fileIds: getIds(selectedFiles),
          folderIds: getIds(selectedFolders),
          name,
        }),
      });
    } catch (e) {
      throw new Error(e);
    }
    if (callback) {
      callback();
    }
  };

  return downloadZip;
};

export default useDownloadZip;
