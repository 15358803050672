import { Box, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme: any) => ({
  pageHeaderBar: {
    padding: "25px 23px 24px 23px",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.coreApp.borders}`,
  },
  title: {
    margin: 0,
  },
}));

const SettingsContainer = ({ children, title }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.pageHeaderBar}>
        <h3 className={classes.title}>{title}</h3>
      </div>
      <Box style={{ padding: "0 24px" }}>{children}</Box>
      <Divider />
    </>
  );
};

SettingsContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SettingsContainer;
