import { GET_CURRENT_USER } from "_apollo/queries";
/**
 * updateStarredItemsCache
 * Updates user's starredItems graphql cache.
 * @param {object} cache Graphql client's cache object.
 * @param {object} mutationResult Data returned from graphql mutation.
 * @param {object} mutationResult.data Data returned from graphql mutation.
 */
const updateStarredItemsCache = (cache, { data }) => {
   const starredItems = data[Object.keys(data)[0]]?.starredItems;
   const existing = cache.readQuery({
      query: GET_CURRENT_USER,
   });

   const user = { ...existing.currentUser, starredItems };
   cache.writeQuery({
      query: GET_CURRENT_USER,
      data: {
         currentUser: {
            user,
         },
      },
   });
};

export default updateStarredItemsCache;
