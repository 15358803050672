import { useQuery } from "@apollo/client";
import { ChevronRight } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GET_RECOMMENDED_CONNECTIONS } from "_apollo/queries";
import JoinIcon from "_assets/icons/JoinIcon";
import { useUser } from "_utils/UserContext";
import PropTypes from "prop-types";
import ForYouCard from "./ForYouCard";

const useStyles = makeStyles(() => ({
   root: {
      background: `
      radial-gradient(36.89% 32.4% at 50.15% 54.7%, #C8BFFF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(100% 85.64% at 0% 0%, #FFD645 0%, rgba(255, 199, 299, 0.5) 100%), radial-gradient(51.79% 46.82% at 100% 100%, #AE47FF 0%, rgba(255, 255, 255, 0) 100%)
      `,
      borderRadius: "6px",
      padding: "23px 15px 49px 15px",
      overflow: "auto",
   },
   transparentBox: {
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "15px",
      width: "100%",
      // flexGrow: 1,
      height: "44px",
      background: "rgba(255, 255, 255, 0.3)",
      /* core/white */
      border: "0.5px solid #FFFFFF",
      boxShadow: "2px 2px 3px rgba(145, 120, 165, 0.2)",
      backdropFilter: "blur(15px)",
      /* Note: backdrop-filter has minimal browser support */
      borderRadius: "4px",
      marginBottom: "8px",
      textDecoration: "none",
      color: "black",
   },
}));

/**
 * ForYouBanner
 * A banner that displays recommended connections using ai files tagging
 * @param {object}  props Props object
 * @param  {boolean} props.hideEmptyState Hide empty state
 * @returns {JSX.Element} ForYouBanner
 */
const ForYouBanner = ({ hideEmptyState }) => {
   const classes = useStyles();
   const [connections, setConnections] = useState([]);
   const [user] = useUser();
   const { loading } = useQuery(GET_RECOMMENDED_CONNECTIONS, {
      variables: {
         limit: 3,
      },
      skip: !user?.profile,
      onCompleted: (data) => {
         if (!data?.getRecommendedConnections?.length > 0) return;
         setConnections(data.getRecommendedConnections);
      },
   });

   /**
    * removeConnection
    * Removes a connection from the connections list
    * @param {string} uri Profile uri to remove from connections list.
    */
   const removeConnection = (uri) => {
      setConnections(
         connections.filter((connection) => connection.profile.uri !== uri)
      );
   };

   if (connections?.length === 0 && !loading && !hideEmptyState) {
      return (
         <Grid
            className={classes.root}
            container
            flexDirection="column"
            alignItems="center"
            // sx={{
            //    height: "362px",
            //    width: "335px",
            // }}
         >
            <Box>
               <JoinIcon />
            </Box>
            <Typography
               mt="5px"
               variant="medium"
               textAlign="center"
               fontWeight="bold"
            >
               Welcome to your recommendations
            </Typography>
            <Typography
               mt="15px"
               variant="small"
               textAlign="center"
               width="80%"
            >
               We will recommend artists to connect with as soon as we know more
               about you.
            </Typography>
            <Typography mt="16px" mb="20px" variant="small" textAlign="center">
               To help speed up this process:
            </Typography>
            <div
               style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
               }}
            >
               <Box
                  className={classes.transparentBox}
                  component={Link}
                  to="settings/profile"
               >
                  <Typography variant="small" fontWeight="bold">
                     Complete your profile
                  </Typography>
                  <ChevronRight />
               </Box>
               <Box
                  className={classes.transparentBox}
                  component={Link}
                  to="/discover"
               >
                  <Typography variant="small" fontWeight="bold">
                     Discover and connect with people
                  </Typography>
                  <ChevronRight />
               </Box>
               <Box
                  className={classes.transparentBox}
                  component={Link}
                  to="/projects"
               >
                  <Typography variant="small" fontWeight="bold">
                     Upload your projects
                  </Typography>
                  <ChevronRight />
               </Box>
            </div>
         </Grid>
      );
   }

   if (connections?.length === 0 && !loading && hideEmptyState) {
      return null;
   }

   return (
      <Grid className={classes.root} position="relative" container>
         <Box position="absolute" bottom="9px" right={0}>
            <Button
               sx={{
                  color: "#530094",
               }}
               component="a"
               href="mailto:hello@aux.app?subject=AI Recommendation Feedback"
            >
               How did we do?
            </Button>
            {/* <Button>Artists</Button>
            <Button>Labels</Button>
            <Button>Others</Button> */}
         </Box>
         <Box width="100%">
            <Typography
               variant="medium"
               fontWeight="bold"
               mb="10px"
               display="flex"
               alignItems="center"
               gap="5px"
            >
               <JoinIcon /> For you
            </Typography>
            <Box display="flex" width="100%" gap="11px" flexWrap="wrap">
               {loading && (
                  <>
                     <ForYouCard />
                     <ForYouCard />
                     <ForYouCard />
                  </>
               )}

               {!loading &&
                  connections?.map((connection) => (
                     <ForYouCard
                        key={connection.profile.uri}
                        connection={connection}
                        removeConnection={() =>
                           removeConnection(connection.profile.uri)
                        }
                     />
                  ))}
            </Box>
         </Box>
      </Grid>
   );
};

ForYouBanner.propTypes = {
   hideEmptyState: PropTypes.bool,
};

ForYouBanner.defaultProps = {
   hideEmptyState: false,
};

export default ForYouBanner;
