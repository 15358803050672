import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useUser } from "_utils/UserContext";
import { filterHiddenFiles } from "_utils/fileTypeUtil/fileTypeUtil";
import useCheckStorageLimit from "_utils/useCheckStorageLimit";
import useFileUploader from "_components/FileUploader/useFileUploader";
import createNestedFolderTree from "_utils/createNestedFolderTree";
import {
  GET_FOLDER,
  CREATE_NESTED_FOLDER,
  GET_FOLDERS,
  GET_FILES,
} from "../../_apollo/queries";
/**
 * File Uploader Button
 *
 * A complex component that has both the file and folder upload buttons as
 * well as their upload logic and the file dropzone logic.
 *
 * TODO: The components should be split from the uploader logic to make this simpler.
 * @param {props} props props
 * @param {string} props.folderUri Folder Uri to upload into.
 * @param {number} props.selectedDropFolderId Selected folder id.
 * @param {Function} props.callback callback triggered after handleDrop is finnish.
 * @returns {JSX.Element} Returns both the Upload Files and Upload Folders.
 */
const FolderUploadInput = ({ folderUri, selectedDropFolderId, callback }) => {
  const uploadFiles = useFileUploader();
  const [user] = useUser();
  const checkStorageLimit = useCheckStorageLimit();
  const folderUploadButton = useRef(null);

  useEffect(() => {
    const inputEl = folderUploadButton.current;
    if (inputEl) {
      inputEl.setAttribute("webkitdirectory", true);
      inputEl.setAttribute("directory", true);
      inputEl.setAttribute("mozdirectory", true);
    }
  }, []);

  const [createNestedFolder] = useMutation(CREATE_NESTED_FOLDER, {
    refetchQueries: [
      {
        query: GET_FOLDERS,
        variables: {
          uri: folderUri,
          trashed: false,
        },
      },
      {
        query: GET_FILES,
        variables: {
          uri: folderUri,
          trashed: false,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  // Get folder and it's parent folders.
  // folderUri
  const { data: folderData } = useQuery(GET_FOLDER, {
    variables: { folderUri },
  });
  const currentFolder = folderData?.getFolder;

  /**
   * Handle Drop Event
   * @param {object} event event from input.
   */
  const handleDropEvent = async (event) => {
    const filesInput = Array.from(event?.target?.files || []);
    // Combine files data and filter out .DS_Store files from macs.
    const normalFiles = filterHiddenFiles(filesInput);

    // Will store in treeFolders the structure of nestedFolders from the Upload Folder Button
    const treeFolders = [];
    const level = { treeFolders };
    if (filesInput?.length > 0) {
      const arrayFiles = Array.from<any>(normalFiles);
      if (arrayFiles?.filter((a) => a?.webkitRelativePath).length > 0) {
        arrayFiles.forEach((file) => {
          const pathFile = file.webkitRelativePath;
          const pathAsArray = pathFile.split("/");
          createNestedFolderTree(pathAsArray, pathFile, file, level);
        });
      }
    }

    // Combine files data and filter out .DS_Store files from macs.
    const filesUpload = filterHiddenFiles(normalFiles);

    if (treeFolders.length === 0 && filesUpload.length === 0) return;

    const enoughStorage = checkStorageLimit(filesUpload, user);
    if (!enoughStorage) {
      return;
    }

    if (treeFolders?.length > 0 && !(treeFolders[0] instanceof File)) {
      createNestedFolder({
        variables: {
          nestedFolders: treeFolders,
          currentFolderId: selectedDropFolderId || currentFolder?.id,
          currentProjectId: currentFolder?.projectId,
        },
        /**
         * createNestedFolder onCompleted
         * @param {object} response response from createNestedFolder mutation
         */
        onCompleted(response) {
          const { createNestedFolder: fileData } = response;
          if (fileData) {
            uploadFiles(
              filesUpload.map((f) => {
                const mutatedFile = f;
                mutatedFile.overwrite = true;
                return mutatedFile;
              })
            );
          }
        },
      });
    }
    callback();
  };

  return (
    <input
      hidden
      type="file"
      multiple
      onChange={(e) => {
        handleDropEvent(e);
        e.target.value = null;
      }}
      ref={folderUploadButton}
    />
  );
};

FolderUploadInput.defaultProps = {
  selectedDropFolderId: null,
  folderUri: null,
  callback: () => null,
};

FolderUploadInput.propTypes = {
  folderUri: PropTypes.string,
  selectedDropFolderId: PropTypes.string,
  callback: PropTypes.func,
};

export default FolderUploadInput;
