import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
   form: {
      border: "2px solid black",
      borderRadius: "7.6px",
      width: "100%",
      display: "flex",
      height: "40px",
      overflow: "hidden",
      "& div": {
         paddingLeft: "6px",
      },
      "& .MuiInputBase-root ": {
         borderRadius: 0,
         backgroundColor: "white",
      },
      "@media (max-width: 768px)": {
         display: "flex",
         flexDirection: "column",
         height: "76px",
      },
   },
   btn: {
      borderBottomLeftRadius: "0px",
      borderTopLeftRadius: "0px",
      "@media (max-width: 768px)": {
         borderTopRightRadius: "0px",
         borderTopLeftRadius: "0px",
      },
   },
   input: {
      height: "100%",
      flexGrow: 100,
   },
}));

/**
 * Sign Up Input Field
 *
 * Sign up input field used on marketing pages.
 * @returns {JSX.Element} Sign Up Input Field component.
 */
const SignUpInputFiled = () => {
   const classes = useStyles();
   const [email, setEmail] = useState("");

   return (
      <div style={{ width: "100%" }}>
         <div className={classes.form}>
            <InputBase
               value={email}
               placeholder="Email Address"
               className={classes.input}
               onChange={(e) => {
                  setEmail(e.target.value);
               }}
            />
            <Button
               component={Link}
               to={{
                  pathname: `/signup`,
                  state: { getStartedEmail: email },
               }}
               size="large"
               aria-label="Get Started"
               color="primary"
               variant="contained"
               className={classes.btn}
            >
               Get Started
            </Button>
         </div>
      </div>
   );
};

export default SignUpInputFiled;
