import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import TimeToGetToWork from "_components/TimeToGetToWork";
import useMediaQuery from "@mui/material/useMediaQuery";
import SwitchFeatures from "./SwitchFeatures";

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: "#FAFAFF",
      paddingTop: "60px",
      marginBottom: "60px",
   },
   title: {
      paddingLeft: "0.6rem",
      paddingRight: "0.6rem",
      fontFamily: "dr-extrabold, Arial, Helvetica, sans-serif",
      fontSize: "3.5rem",
      lineHeight: "3.75rem",
      textAlign: "center",
      paddingBottom: "0.8rem",
      "@media (max-width: 900px)": {
         fontSize: "44px",
         lineHeight: "41.8px",
      },
   },
   content: {
      paddingLeft: "1.3rem",
      paddingRight: "1.3rem",
      fontSize: "1.375rem",
      lineHeight: "1.75rem",
      textAlign: "center",
      color: theme.palette.secondary.shade60,
   },
   featuresSwitchContainer: {
      width: "86vw",
      marginLeft: "4vw",
      marginRight: "7vw",
   },
}));

/**
 * Features
 * @returns {JSX.Element} Features Component
 */
const Features = () => {
   const classes = useStyles();
   const mobileView = useMediaQuery("(max-width:900px)");

   return (
      <div className={classes.root}>
         <div>
            <div className={classes.title}>
               Don’t let your hard work go to waste
            </div>
            <div className={classes.content}>
               We keep your projects safe and automatically upload your changes
            </div>
            <div className={classes.featuresSwitchContainer}>
               <SwitchFeatures />
            </div>
         </div>
         <TimeToGetToWork
            style={
               mobileView
                  ? {
                       background:
                          "linear-gradient(320deg, rgba(255, 214, 69, 0.96) 0%, rgba(255, 199, 229, 0.35) 90%), linear-gradient(150deg, rgba(174, 71, 255, 0.7) 0%, rgba(200, 191, 255, 0) 90%)",
                    }
                  : {
                       background:
                          "linear-gradient(273.38deg, #FFD645 0%, rgba(255, 199, 229, 0.4) 100%), linear-gradient(93.38deg, #AE47FF 0%, rgba(200, 191, 255, 0.18) 100%)",
                    }
            }
         />
      </div>
   );
};

export default Features;
