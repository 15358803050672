import { createStore } from "redux";
import reducers from "./reducer";

// Create Redux Store
// We do not use a persistent store because everything is stored already in the cache
const store = createStore(
   reducers,
   /* eslint-disable-next-line no-underscore-dangle */
   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
