import React from "react";

/**
 * JoinIcon
 * SVG join icon.
 * @returns {JSX.Element} JoinIcon component
 */
const JoinIcon = () => {
   return (
      <svg
         width="18"
         height="18"
         viewBox="0 0 18 18"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M5.1934 12.8065C5.7824 14.9246 7.45181 16.5708 9.57802 17.1299C11.7044 17.6891 13.9677 17.0768 15.5223 15.5224C17.0768 13.9677 17.689 11.7044 17.1298 9.57814C16.5707 7.45193 14.9245 5.78252 12.8063 5.19352C12.2173 3.07538 10.5479 1.42921 8.42172 0.870064C6.29531 0.310923 4.03206 0.923132 2.47747 2.4776C0.922979 4.03228 0.310745 6.29554 0.869936 8.42184C1.42908 10.548 3.07525 12.2175 5.1934 12.8065V12.8065ZM12.9891 7.56738H12.9893C14.1391 8.15374 14.9389 9.25498 15.1404 10.5299C15.342 11.8048 14.9212 13.0992 14.0082 14.0116C13.0952 14.9241 11.8007 15.3445 10.5259 15.1421C9.25102 14.94 8.15016 14.1397 7.56457 12.9895C8.94749 12.828 10.2355 12.2047 11.2199 11.2201C12.2046 10.2357 12.8278 8.94767 12.9893 7.56479L12.9891 7.56738ZM7.13177 10.8686C7.20059 9.90024 7.61632 8.98914 8.30281 8.30284C8.98912 7.61634 9.90022 7.20064 10.8685 7.1318C10.7997 8.10012 10.384 9.01122 9.6975 9.69753C9.0112 10.384 8.10009 10.7997 7.13177 10.8686V10.8686ZM6.84957 2.81746C7.59117 2.81802 8.31837 3.02297 8.951 3.41001C9.58369 3.79704 10.0975 4.35097 10.4356 5.01102C9.05263 5.17247 7.7646 5.79577 6.7802 6.78037C5.79556 7.76482 5.17228 9.05284 5.01085 10.4357C3.93116 9.88051 3.15898 8.86832 2.90883 7.68005C2.65888 6.49197 2.95758 5.25439 3.72206 4.31099C4.48653 3.36758 5.63542 2.81895 6.84957 2.81746V2.81746Z"
            fill="black"
         />
      </svg>
   );
};

export default JoinIcon;
