import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { Box } from "@mui/material";

const useStyles = makeStyles(() => ({
   draggableWrapper: {
      "pointer-events": "none", // This is important because otherwise this overlay prevents you on clicking anything on the page.
      "-webkit-user-select": "none",
      "-webkit-app-region": "drag",
      position: "absolute",
      width: "100%",
      height: "100%",
   },
}));

/**
 * Draggable Route.
 * Route wrapper that adds a div at the top, so that the window can be dragged.
 * @param {object} props props
 * @param {Element} props.component Page that is being rendered.
 * @returns {JSX.Element} A custom route that adds the draggable CSS to a wrapper of the main route.
 */
const DraggableRoute = ({ component: Component, ...rest }) => {
   const classes = useStyles();

   /* eslint-disable react/jsx-props-no-spreading */
   return (
      <Route
         {...rest}
         render={(props) => (
            <>
               <Box height={40} className={classes.draggableWrapper} />
               <Component {...props} />
            </>
         )}
      />
   );
   /* eslint-enable react/jsx-props-no-spreading */
};

DraggableRoute.propTypes = {
   Component: PropTypes.shape(),
   component: PropTypes.instanceOf(Object),
};

DraggableRoute.defaultProps = {
   Component: undefined,
   component: undefined,
};
export default DraggableRoute;
