import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Cancel } from "@mui/icons-material";
import { CustomFile, useSoundsData } from "_utils/SoundsDataContext";

const validationSchema = Yup.object().shape({
  prompt: Yup.string().trim().max(255).required().label("Prompt"),
});

const useStyles = makeStyles((theme) => ({
  searchForm: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  fileButton: {
    marginRight: "12px",
    textWrap: "nowrap",
    minWidth: "inherit",
    "& > svg": {
      marginLeft: "5px",
    },
  },
  searchInput: {
    borderRadius: "4px 0px 0px 4px",
    border: "0.5px solid var(--shades-shade-15, #D9D9D9)",
    background: "#FFF",
    flexGrow: 1,
    height: "36px",
    "& input": {
      borderRadius: "4px 0px 0px 4px",
      background: "#FFF",
      padding: "7px 10px",
      fontSize: "14px",
      minWidth: "150px",
    },
    "& .MuiInputBase-adornedEnd": {
      paddingRight: 0,
      background: "transparent",
    },
  },
  searchButton: {
    borderRadius: "0px 4px 4px 0px",
    background: "var(--core-strong-dark-blue, #6147FF)",
    height: "37px",
  },
}));

/**
 * SoundsSearchForm
 *
 * @param {object} props props
 * @param {boolean} props.loading Overall loading state.
 * @param {Array<string>} props.samples List of generated samples.
 * @param {string} props.prompt Current prompt value.
 * @param {Function} props.setPrompt Set prompt value.
 * @param {Function} props.generateSounds Generate sounds function.
 * @param {Function} props.abortAllRequestsAndReset Abort all requests function.
 * @param {boolean} props.clearExisting Clear existing prompt before searching.
 * @param {boolean} props.renderedInline Used to hide the audio file preview when in the FilesListings component.
 * @returns {JSX.Element} Sounds Search form.
 */
const SoundsSearchForm = ({
  loading,
  samples,
  generateSounds,
  prompt,
  setPrompt,
  clearExisting = false,
  abortAllRequestsAndReset,
  renderedInline = false,
}) => {
  const classes = useStyles();

  const { initAudio, setInitAudio } = useSoundsData();
  const audioFile: CustomFile = initAudio.file;

  const textButton = useMemo(() => {
    if (loading) {
      return <CircularProgress size={16} />;
    }
    return "Create";
  }, [loading]);

  const triggerGenerate = () => {
    generateSounds(prompt, clearExisting);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ prompt: "" }}
      // TODO: P3 validation is not implemented.
      // validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={triggerGenerate}
    >
      <Form className={classes.searchForm}>
        {initAudio?.status !== "EMPTY" && renderedInline && (
          <Button
            className={classes.fileButton}
            variant="outlined"
            onClick={() => {
              setInitAudio({ status: "EMPTY", file: null });
              abortAllRequestsAndReset();
            }}
          >
            {audioFile?.originalName}
            <Cancel />
          </Button>
        )}
        <TextField
          className={classes.searchInput}
          size="small"
          fullWidth
          variant="outlined"
          name="prompt"
          placeholder="Describe the sound you need..."
          //   value={values.name}
          onChange={(event) => setPrompt(event.target.value)}
          //   error={touched.name && Boolean(errors.name)}
          //   helperText={touched.name && errors.name}
          disabled={loading}
          // defaultValue={prompt}
          value={prompt}
          // Add X icon adornment to clear the input when there are samples.
          InputProps={
            (samples && samples.length > 0) || loading
              ? {
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ background: "transparent" }}
                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          setPrompt("");
                          abortAllRequestsAndReset();
                        }}
                      >
                        <Cancel />
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : {}
          }
        />
        <Button
          className={classes.searchButton}
          disabled={loading || !prompt}
          variant="contained"
          type="submit"
          color="primary"
          onClick={triggerGenerate}
        >
          {textButton}
        </Button>
      </Form>
    </Formik>
  );
};

export default SoundsSearchForm;
