import React from "react";

/**
 * IconZip
 *
 * @returns {JSX.Element} Icon for zip download, used on the download notification.
 */
function IconZip() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5372 5.70913C14.5372 6.78391 15.4013 7.64801 16.4761 7.64801L20.1642 7.64805L20.1644 20.1243C20.1644 21.1571 19.3214 22 18.2887 22H5.87566C4.84292 22 4 21.157 4 20.1243V3.87566C4 3.36983 4.18967 2.90618 4.54791 2.54791C4.90614 2.18964 5.36983 2 5.87566 2H7.35086V3.68602H9.12096V2.02104H14.5372V5.70913ZM9.12111 3.70706V5.45627H7.35086V7.22652H9.12096V5.47731H10.8912V3.70706H9.12111ZM9.12111 7.24755V8.99676H7.35086V10.767H9.12111V12.4952H7.35086V14.2655H9.12111V12.5373H10.8914V10.767H9.12111L9.12096 9.0178H10.8912V7.24755H9.12111ZM10.8914 14.3075H9.12111V16.0357H10.8914V14.3075ZM19.8691 6.236H16.4761L16.476 6.23603C16.181 6.23603 15.9492 6.00419 15.9493 5.68812V2.31608C16.0758 2.40031 16.1812 2.48469 16.2654 2.56893L19.6162 5.91994C19.7215 6.02519 19.8059 6.1306 19.8691 6.236Z"
        fill="#6147FF"
      />
    </svg>
  );
}

export default IconZip;
