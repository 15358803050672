import React from "react";

import { makeStyles } from "@mui/styles";
import AppLayout from "../../_layouts/AppLayout";

import AccountSettings from "./SettingsComponents/AccountSettings";
import BillingSettings from "./SettingsComponents/BillingSettings";
import ProfileSettings from "./SettingsComponents/ProfileSettings";

const useStyles = makeStyles(() => ({
   root: {
      padding: 0,
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      position: "relative",
      height: "100%",
      overflowX: "auto",
   },
}));

/**
 * SettingsPage
 *
 * Account Page, contains information about user's plan and their app limits.
 * @returns {JSX.Element} Account Page Component.
 */
const SettingsPage = () => {
   const classes = useStyles();

   return (
      <AppLayout>
         <div className={classes.root}>
            {/* <SettingsContainer title="Settings" /> */}

            {/* <SettingNavigation /> */}
            {/* TODO: add in the actual settings I need? */}

            <AccountSettings />
            <BillingSettings />

            <ProfileSettings />
         </div>
      </AppLayout>
   );
};

export default SettingsPage;
