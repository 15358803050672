import PropTypes from "prop-types";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles((theme) => ({
   labelSelect: {
      fontSize: theme.typography.small2.fontSize,
      lineHeight: "16px",
      fontWeight: 700,
   },
   inputSelect: {
      borderRadius: 0,
      fontSize: theme.typography.small1.fontSize,
      lineHeight: "20px",
      width: "150px",
      "& .MuiOutlinedInput-input": {
         padding: "10px",
         paddingRight: "32px",
      },
   },
}));

const DiscoverSort = ({ sortValue, setSortValue }) => {
   const classes = useStyles();

   const handleChange = (e) => {
      setSortValue(e.target.value);
   };

   return (
      <div>
         <div className={classes.labelSelect}>Sort</div>
         <Select
            className={classes.inputSelect}
            value={sortValue}
            onChange={handleChange}
            name="primaryEmail"
            variant="outlined"
         >
            <MenuItem value="Popularity">Popularity</MenuItem>
            <MenuItem value="AToZ">Alphabetical (A&gt;Z)</MenuItem>
            <MenuItem value="ZToA"> Alphabetical (Z&gt;A)</MenuItem>
         </Select>
      </div>
   );
};

DiscoverSort.propTypes = {
   setSortValue: PropTypes.func.isRequired,
   sortValue: PropTypes.string.isRequired,
};

export default DiscoverSort;
