import PropTypes from "prop-types";
import React from "react";

const AuxPlainLogo = ({ style }) => {
   return (
      <svg
         width="74"
         height="26"
         viewBox="0 0 74 26"
         fill="none"
         style={style}
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M13.2002 25.48C15.6002 25.48 17.7122 24.76 19.4402 23.464V25H25.6802V0.999991H19.4402V2.53599C17.7122 1.23999 15.6002 0.519989 13.2002 0.519989C5.90421 0.519989 0.720215 5.70399 0.720215 13C0.720215 20.344 5.90421 25.48 13.2002 25.48ZM13.2002 19.72C9.40821 19.72 6.96021 17.08 6.96021 13C6.96021 8.91999 9.40821 6.27999 13.2002 6.27999C16.9922 6.27999 19.4402 8.91999 19.4402 13C19.4402 17.08 16.9922 19.72 13.2002 19.72Z"
            fill="black"
         />
         <path
            d="M28.0596 15.4C28.0596 21.304 32.1396 25.48 37.7556 25.48C39.8676 25.48 41.7396 24.76 43.1796 23.464V25H49.4196V0.999991H43.1796V15.4C43.1796 17.944 41.3556 19.72 38.7156 19.72C36.1716 19.72 34.2996 17.944 34.2996 15.4V0.999991H28.0596V15.4Z"
            fill="black"
         />
         <path
            d="M66.6092 25H73.9052L65.9852 12.52L73.3292 0.999991H66.0812L62.2892 7.23999L58.4972 0.999991H51.2012L58.5452 12.568L50.6252 25H57.9212L62.2892 17.848L66.6092 25Z"
            fill="black"
         />
      </svg>
   );
};

AuxPlainLogo.propTypes = {
   style: PropTypes.shape({}),
};

AuxPlainLogo.defaultProps = {
   style: {},
};

export default AuxPlainLogo;
