import React, { useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import DraggableRoute from "_routes/DraggableRoute";

// Pages
import SignUpPage from "_pages/SignUpPage";
import SignInPage from "_pages/SignInPage";
import HomePage from "_pages/HomePage/HomePage";
import DiscoverPage from "_pages/DiscoverPage/DiscoverPage";
import ProfilePage from "_pages/ProfilePage/ProfilePage";
import SoundsPage from "_pages/SoundsPage/SoundsPage"; // Sounds screen used for pitch.
import PricingPage from "_pages/PricingPage/PricingPage";
import ConfirmEmailPage from "_pages/ConfirmEmailPage";
import PasswordResetPage from "_pages/PasswordResetPage";
import PasswordResetUpdatePage from "_pages/PasswordResetUpdatePage";
import NotFoundPage from "_pages/NotFoundPage";
import ContactsPage from "_pages/ContactsPage";
import Blog from "_pages/Blog/Blog";
import PostsPages from "_pages/Blog/PostsPages";
import Tag from "_pages/Blog/Tag";
import Authors from "_pages/Blog/Authors";
import FilesListingsPage from "_pages/FilesPage/FilesListingsPage";
import FilePage from "_pages/FilePage/FilePage";
import ResendConfirmEmail from "_pages/ResendConfirmEmail";
import LandingPage from "_pages/LandingPage";
import ErrorPage from "_pages/ErrorPage";
// import PublicPlaylistPage from "_pages/PublicPlaylistPage";
import MasteringPage from "_pages/MasteringPage";

// Other
import PrivateRoute from "./PrivateRoute";
import isElectronHook from "../_utils/isElectron";
import SettingsPage from "../_pages/SettingsPage/SettingsPage";

// You cannot group statements in SwitchRouter. Therefore disable complexity rule.
/* eslint-disable complexity */
/**
 * Switch Router.
 * @returns {JSX.Element} The Main App React Router Switch Router
 */
function SwitchRouter() {
   const isElectron = isElectronHook();
   const history = useHistory();

   // Functions for the buttons in the electron app that trigger opening customer support links and triggers back and forward navigation.
   useEffect(() => {
      window?.electronAPI?.featureIdea(() => {
         window.open("https://roadmap.aux.app");
      });
      window?.electronAPI?.contactAuxTeam(() => {
         window.open("mailto:hello@aux.app?subject=Desktop Feedback");
      });
      window?.electronAPI?.navigate((_event, route) => {
         history.push(route);
         history.go();
      });
   }, []);

   return (
      <Switch>
         <DraggableRoute
            exact
            path="/"
            // Defaults to home on web and signup on electron
            component={isElectron ? SignUpPage : HomePage}
         />

         {/* Redirect legacy landing pages to root. */}
         <Redirect exact path="/music-creators" to="/" />
         <Redirect exact path="/music-industry" to="/" />
         <Redirect exact path="/podcasters" to="/" />
         <Route exact path="/pricing" component={PricingPage} />
         {/* // Redirect download to the homepage for Google Ads site links. */}
         <Redirect exact from="/download" to="/" />

         {/* Auth */}
         {/* Draggable routes allow the user to click and drag anywhere in the window to reposition it in electron. This is ideal for pages that don't have a header.  */}
         <DraggableRoute exact path="/signup" component={SignUpPage} />
         <DraggableRoute exact path="/signin" component={SignInPage} />
         <Route
            exact
            path="/confirm-email/:token"
            component={ConfirmEmailPage}
         />
         <Route exact path="/confirm-email" component={ResendConfirmEmail} />
         <DraggableRoute
            exact
            path="/password/reset"
            component={PasswordResetPage}
         />
         <Route
            exact
            path="/password/reset/:token"
            component={PasswordResetUpdatePage}
         />

         {/* Blog / Discover */}
         <Route
            exact
            path={[
               "/discover",
               "/discover/:slug?",
               "/discover/:slug?/:slug1?",
               "/discover/:slug?/:slug1?/:slug2?",
               "/discover/:slug?/:slug1?/:slug2?/:slug3?",
               "/discover/:slug?/:slug1?/:slug2?/:slug3?/:slug4?",
            ]}
            component={DiscoverPage}
         />
         <Redirect exact from="/guide" to="/discover" />

         {!isElectron && <Route exact path="/connect" component={Blog} />}
         {!isElectron && (
            <Route exact path="/connect/author/:slug" component={Authors} />
         )}
         {!isElectron && (
            <Route exact path="/connect/tag/:slug" component={Tag} />
         )}
         {!isElectron && (
            <Route exact path="/connect/:slug">
               <PostsPages contentType="post" />
            </Route>
         )}
         {!isElectron && (
            <Redirect exact from="/tapenotes" to="/join/tapenotes" />
         )}
         {!isElectron && (
            <Redirect exact from="/info/tapenotes" to="/join/tapenotes" />
         )}
         {!isElectron && (
            <Route exact path="/info/:slug">
               <PostsPages contentType="page" />
            </Route>
         )}
         {!isElectron && (
            <Route exact path="/join/:slug" component={LandingPage} />
         )}

         {/* This route is disabled for now, it also needs to be updated so the player can play due the bottom media player has been removed. */}
         {/* TODO: I could use this for my public shared playlist concept? */}
         {/* <Route
            exact
            path={["/playlist/:projectUri"]}
            component={PublicPlaylistPage}
         /> */}
         {/* <PrivateRoute
            exact
            path={["/waitinglist/:product"]}
            component={WaitingListPage}
         /> */}
         <PrivateRoute
            path={["/files", "/files/folder/:folderUri"]}
            component={FilesListingsPage}
            exact
         />
         <PrivateRoute
            path="/files/file/:fileFolderURI"
            component={FilePage}
            exact
         />
         <PrivateRoute
            path="/files/folder/:folderUri/file/:fileFolderURI"
            component={FilePage}
            exact
         />

         {/* TODO: update all /feed links to go to sounds so I can remove this redirect. */}
         <Redirect exact from="/feed" to="/sounds" />

         <PrivateRoute exact path="/mastering" component={MasteringPage} />
         <PrivateRoute exact path="/sounds" component={SoundsPage} />
         <PrivateRoute exact path="/contacts" component={ContactsPage} />

         <PrivateRoute exact path="/settings" component={SettingsPage} />

         {/* // There are two 404 pages defined. */}
         <Route path="/404" component={NotFoundPage} />
         <Route path="/error" component={ErrorPage} />

         {/* Try to match all other routes to a user profile. */}
         <Route path="/:profileSlug" exact strict component={ProfilePage} />
         {/* // This one will handle nested routes */}
         <Route component={NotFoundPage} />
      </Switch>
   );
}
/* eslint-enable complexity */

export default SwitchRouter;
