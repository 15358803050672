import PropTypes from "prop-types";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import isElectronHook from "_utils/isElectron";
import getConfig from "_utils/getConfig";

const useStyles = makeStyles((theme) => ({
   card: {
      width: "100%",
      padding: "12px 12px 0 12px",
      marginTop: "18px",
      minHeight: "102px",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "column",
      border: `solid 1px ${theme.palette.secondary.shade15}`,
   },
   title: {
      fontSize: "12px",
      lineHeight: "16px",
      marginBottom: "4px",
   },
   subtitle: {
      fontSize: "12px",
      lineHeight: "16px",
      color: theme.palette.secondary.shade60,
   },
   boxBtn: {
      marginLeft: "auto",
   },
}));

/**
 * Claim Profile Card.
 * Card used to inform users about the generated profiles created by the Aux team.
 * @param {object} param props.
 * @param {string} param.title Title of the card
 * @param {string} param.subtitle Body of the card
 * @returns {JSX.Element} JSX
 */
const ClaimProfileCard = ({ title, subtitle }) => {
   const classes = useStyles();
   const isElectron = isElectronHook();
   const { domain } = getConfig();

   return (
      <div className={classes.card}>
         <div className={classes.title}>{title}</div>
         <div className={classes.subtitle}>{subtitle}</div>

         <div className={classes.boxBtn}>
            <Button
               component="a"
               href={`${isElectron ? domain : ""}/info/what-is-the-aux-guide/`}
               color="primary"
               variant="text"
               target={isElectron ? "_blank" : ""}
            >
               More Information
            </Button>
            <Button
               component="a"
               href={`${isElectron ? domain : ""}/info/what-is-the-aux-guide/`}
               color="primary"
               variant="text"
               target={isElectron ? "_blank" : ""}
            >
               Claim Profile
            </Button>
         </div>
      </div>
   );
};

ClaimProfileCard.propTypes = {
   subtitle: PropTypes.string.isRequired,
   title: PropTypes.string.isRequired,
};

export default ClaimProfileCard;
