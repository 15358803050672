import { Box } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import PropTypes from "prop-types";

const pixelRatio = window.devicePixelRatio || 1;

/**
 * ImageUploadInput
 * @param {object} props props.
 * @param {Function} props.setBase64Image set blob or base64 as the result of cropping.
 * @param {string} props.upImg url string.
 * @param {boolean} props.circularCrop circular or square crop.
 * @returns {JSX.Element} ImageUploadInput component.
 */
const ImageUploadInput = ({ setBase64Image, upImg, circularCrop }) => {
   const imgRef = useRef(null);
   const previewCanvasRef = useRef(null);

   const [completedCrop, setCompletedCrop] = useState(null);
   const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 9 / 9 });

   const onLoad = useCallback((img) => {
      imgRef.current = img;
   }, []);

   useEffect(() => {
      if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
         return;
      }

      const image = imgRef.current;
      const canvas = previewCanvasRef.current;
      const completeCrop = completedCrop;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext("2d");

      canvas.width = completeCrop.width * pixelRatio;
      canvas.height = completeCrop.height * pixelRatio;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";
      ctx.drawImage(
         image,
         completeCrop.x * scaleX,
         completeCrop.y * scaleY,
         completeCrop.width * scaleX,
         completeCrop.height * scaleY,
         0,
         0,
         completeCrop.width,
         completeCrop.height
      );
      const dataUrl = canvas.toDataURL("image/jpeg");
      setBase64Image(dataUrl);
   }, [completedCrop]);

   return (
      <Box display="flex" alignItems="center" justifyContent="space-between">
         <ReactCrop
            circularCrop={circularCrop}
            src={upImg}
            imageStyle={{
               maxHeight: 300,
            }}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
         />
         <canvas
            ref={previewCanvasRef}
            className="round-canvas"
            /* Rounding is important so the canvas width and height
   matches/is a multiple for sharpness. */
            style={{
               width: "60px",
               height: "60px",
            }}
         />
      </Box>
   );
};

ImageUploadInput.propTypes = {
   circularCrop: PropTypes.bool,
   setBase64Image: PropTypes.func.isRequired,
   upImg: PropTypes.string,
};

ImageUploadInput.defaultProps = {
   upImg: null,
   circularCrop: false,
};

export default ImageUploadInput;
