import React, {
  useContext,
  createContext,
  useState,
  useRef,
  useEffect,
} from "react";
import useStatsMutation from "_utils/useStatsMutation";
import * as Sentry from "@sentry/react";

export interface CustomFile extends File {
  mimeType?: string;
  uploadCompleteCallback?: Function;
  uploadIntoSoundsFolder?: boolean;
  originalName?: string;
  signedUrl?: string;
}

type ModalAPIQuery = {
  prompt: string;
  // eslint-disable-next-line camelcase
  audio_file?: string;
};

interface SoundsInitFile {
  status: "EMPTY" | "UPLOADING" | "UPLOADED";
  file: File | null;
}

type ProviderValueType = {
  loading: boolean;
  samples: string[];
  prompt: string;
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
  initAudio: SoundsInitFile;
  setInitAudio: React.Dispatch<React.SetStateAction<SoundsInitFile>>;
  samplesGenerating: number;
  generateSounds: (
    thisPrompt: string,
    clearExisting?: boolean
  ) => Promise<void>;
  abortAllRequestsAndReset: () => void;
};

const ThemeContext = createContext(null);

const SOUNDS_API_URL =
  "https://indietechteam--aux-sounds-api-2-0-model-web.modal.run/";

/**
 * useSoundsData
 * React context hook for sounds.
 * @returns {ProviderValueType} DownloadingSyncContext
 */
const useSoundsData = () => useContext<ProviderValueType>(ThemeContext);

/**
 * SoundsDataContext
 * React context provider for sounds.
 * @param {object} props props
 * @param {React.Component} props.children children components.
 * @returns {React.Component} DownloadingSyncProvider
 */
const SoundsDataContext = ({ children }) => {
  const statsMutation = useStatsMutation();

  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [initAudio, setInitAudio] = useState<{
    status: "EMPTY" | "UPLOADING" | "UPLOADED";
    file: CustomFile | null;
  }>({
    status: "EMPTY",
    file: null,
  });

  const [samples, setSamples] = useState([]);
  // Track the total number of samples still being generated to show skeleton for these in SoundsListings
  const [samplesGenerating, setSamplesGenerating] = useState(0);

  // On first load make a warmup request to start the modal infra.
  useEffect(() => {
    fetch(
      `${SOUNDS_API_URL}?${new URLSearchParams({
        warmup: "warmup",
      })}`
    );
  }, []);

  // Setup and abort controller to allow requests to be cancelled.
  const abortControllerRef = useRef<AbortController>(null);
  useEffect(() => {
    abortControllerRef.current = new AbortController();

    return () => abortControllerRef.current.abort();
  }, []);

  const abortAllRequestsAndReset = () => {
    // setPrompt(""); // Don't reset the prompt here because it's used by search form. Always clear direct if needed.
    setSamples([]);
    setSamplesGenerating(0);

    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
  };

  const requestSample = async (thisPrompt, initAudioSignedURL) => {
    // Increment the number of samples still being generated.
    setSamplesGenerating((prev) => prev + 1);

    const searchObject: ModalAPIQuery = {
      prompt: thisPrompt,
    };
    if (initAudioSignedURL) {
      searchObject.audio_file = initAudioSignedURL;
    }

    // Call the demo API to get URLs for tracks:
    const response = await fetch(
      `${SOUNDS_API_URL}?${new URLSearchParams(searchObject)}`,
      { signal: abortControllerRef.current.signal }
    );

    // Decrement the number of samples still being generated.
    setSamplesGenerating((prev) => prev - 1);

    // Catch errors.
    if (!response.ok) {
      // TODO: bring toasts into this project to show failed to generate..
      Sentry.captureException(
        new Error(
          `Failed to generate sample. Response Code: ${response.status}`
        )
      );
      // TODO: P3: retry generation in the case of failures (up to 3 times with exponential backoff)
      return;
    }

    const sampleData = await response.json();

    // Log generation stat
    statsMutation({
      statsId: "SoundsGeneration",
      metadata: JSON.stringify({
        sampleUrl: sampleData.url,
        thisPrompt,
      }),
    });

    // Add the sample to the list of samples
    setSamples((prevSamples) => [...prevSamples, sampleData]);
  };

  const generateSounds = async (thisPrompt, clearExisting = true) => {
    // Query backend for prompt
    setLoading(true);

    // Log stat.
    statsMutation({
      statsId: "SoundsSearch",
      metadata: JSON.stringify({
        prompt: thisPrompt,
      }),
    });

    // Clear the samples list
    if (clearExisting) {
      abortAllRequestsAndReset();
    }

    setPrompt(thisPrompt);

    const initAudioSignedURL = initAudio?.file?.signedUrl;

    // Trigger six independent requests for samples, resolving loading state only when all are complete.
    await Promise.all([
      requestSample(thisPrompt, initAudioSignedURL),
      requestSample(thisPrompt, initAudioSignedURL),
      requestSample(thisPrompt, initAudioSignedURL),
      requestSample(thisPrompt, initAudioSignedURL),
      requestSample(thisPrompt, initAudioSignedURL),
      requestSample(thisPrompt, initAudioSignedURL),
    ]).catch((error) => {
      // Abort error is abort error skip.
      if (error.name === "AbortError") {
        return;
      }

      // TODO: show toast here when error is returned, decrement the samples count and if all fail, clear the prompt too so the main search section shows...

      // Catch all other errors.
      Sentry.captureException(error);
    });

    setLoading(false);
  };

  return (
    <ThemeContext.Provider
      value={{
        loading,
        samples,
        prompt,
        setPrompt,
        initAudio,
        setInitAudio,
        samplesGenerating,
        generateSounds,
        abortAllRequestsAndReset,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export { useSoundsData, SoundsDataContext };
