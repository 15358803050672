import React from "react";

const HeaderDropdownIcon = ({ fill = "black" }) => {
   return (
      <svg
         width="40"
         height="40"
         viewBox="0 0 48 48"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM12 20V18H36V20H12ZM12 30V28H36V30H12Z"
            fill={fill}
         />
      </svg>
   );
};

export default HeaderDropdownIcon;
