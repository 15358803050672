import React from "react";
import { Grid, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { GET_FOLDER } from "_apollo/queries";
import { useQuery } from "@apollo/client";
import { useQueryParam, BooleanParam } from "use-query-params";

const useStyles = makeStyles((theme) => ({
   pathContainer: {
      margin: "21px 0 17px 0",
      padding: "0 15px",
      [`& a`]: {
         fontWeight: "bold",
         fontSize: theme.typography.h5.fontSize,
      },
   },
   Link: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      userDrag: "none",
      userSelect: "none",
   },
   text: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "180px",
      display: "inline-block",
      marginLeft: 4,
   },
   textFirstChild: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "none",
      display: "inline-block",
      marginLeft: 4,
      fontSize: "18px",
   },
}));

/**
 * Folders Path
 *
 * Take a folder, loads all of it's parent folders and shows breadcrumbs
 * allowing the user to navigate back up the folder tree in the files
 * section
 * @param {object} props props
 * @param {boolean} props.isModal Boolean when loaded by ModalFilesListings which requires folders to be loaded in internal state.
 * @param {Function} props.handleChangeDirectory Function to call when within modal to navigate directories.
 * @param {string} props.folderUri current folder uri.
 * @returns {JSX.Element} Breadcrumbs path component with links to each parent folder.
 */
const FoldersPath = ({ isModal, handleChangeDirectory, folderUri }) => {
   const [trashed] = useQueryParam("trashed", BooleanParam);

   const classes = useStyles();
   const theme = useTheme();

   // Get folder and it's parent folders.
   // folderUri
   const { data: folderData } = useQuery(GET_FOLDER, {
      variables: { folderUri },
      skip: folderUri === undefined, // This prevents path from flashing if the folder takes a long time to load.
   });
   const folder = folderData?.getFolder;

   const foldersToMap = folder?.parentFolders ? [...folder?.parentFolders] : [];

   const folderBreadcrumbs = foldersToMap?.map((destinationFolder, i) => {
      return (
         <Link
            key={`${destinationFolder?.uri}-path-component`}
            style={{
               textDecoration: "none",
               color:
                  foldersToMap.length - 1 === i
                     ? theme.palette.coreApp.text
                     : theme.palette.primary.main,
               maxHeight: "24px",
               maxWidth: `${100 / (foldersToMap?.length + 1)}%`,
            }}
            to={`/files/folder/${destinationFolder.uri}${
               trashed ? "?trashed=1" : ""
            }`}
            onClick={(event) => {
               // For modals, prevent the link behavior to handle the folder traversal in cache.
               if (isModal) {
                  event.preventDefault();
                  handleChangeDirectory(destinationFolder);
               }
            }}
         >
            <span className={classes.text} style={{ maxWidth: "100%" }}>
               / {destinationFolder?.name}
            </span>
            &nbsp;
         </Link>
      );
   });

   return (
      <Grid
         className={classes.pathContainer}
         id="folder-path"
         container
         flexWrap="nowrap"
         margin={0}
         alignItems="flex-start"
      >
         <Link
            className={classes.Link}
            style={{
               textDecoration: "none",
               color:
                  foldersToMap.length > 0
                     ? theme.palette.primary.main
                     : theme.palette.coreApp.text,
               maxWidth:
                  folderBreadcrumbs?.length === 0
                     ? "100%"
                     : `${100 / folderBreadcrumbs?.length + 1}%`,
            }}
            to={trashed ? "/files?trashed=1" : "/files"}
            onClick={(event) => {
               // For modals, prevent the link behavior to handle the folder traversal in cache.
               if (isModal) {
                  event.preventDefault();
                  handleChangeDirectory(null); // null uri is root folder.
               }
            }}
            data-testid="root-folder-path"
         >
            <span className={classes.textFirstChild}>
               {trashed ? "Trash" : "Files"}
            </span>
         </Link>

         {folderBreadcrumbs}
      </Grid>
   );
};

FoldersPath.propTypes = {
   isModal: PropTypes.bool,
   handleChangeDirectory: PropTypes.func,
   folderUri: PropTypes.string,
};

FoldersPath.defaultProps = {
   folderUri: null,
   isModal: false,
   handleChangeDirectory: undefined,
};

export default FoldersPath;
