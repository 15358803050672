import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";

import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import TextField from "@mui/material/TextField";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";

import { useQueryParam, StringParam } from "use-query-params";

// Apollo
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import appStorage from "_utils/appStorage";

import { Link, useLocation } from "react-router-dom";
import { darken, Typography } from "@mui/material";
import useStatsMutation from "_utils/useStatsMutation";
import { client } from "_apollo/client";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useUser } from "_utils/UserContext";
import { useDispatch } from "react-redux";
import { openLoadingAppModal } from "_redux/actions";
import { GET_CURRENT_USER, SIGN_UP_USER } from "../_apollo/queries";
import ReservedUsernames from "../ReservedUsernames";
import TermsAndConditionsLabel from "./AppendedLabels/TermsAndConditionsLabel";
import ReactLabelComponent from "./AppendedLabels/ReactLabelComponent";
import generateMd5 from "../_utils/generateMd5";
import gtag from "../_utils/gtag";

const useStyles = makeStyles((theme) => ({
   input: {
      marginBottom: "10px",
      width: "100%",
      [`& fieldset`]: {
         borderRadius: 0,
      },
      "-webkit-user-select": "text",
      "-webkit-app-region": "none",
   },
   // Click events are eaten by the -webkit-app-region: drag; so we need to disable it for anything that is interactive.
   noDrag: {
      ...theme.noDrag,
   },
   checkboxMark: {
      marginBottom: "auto",
      marginTop: "6px",
   },
   labelInput: {
      marginTop: "10px",
      lineHeight: "16px",
      fontSize: theme.typography.small2.fontSize,
   },
   formWrapper: {
      marginTop: "20px",
   },
   link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      lineHeight: "20px",
      fontSize: theme.typography.small1.fontSize,
      marginLeft: "8px",
   },
   whiteText: {
      color: "white",
   },
   grayText: {
      "a&, & a": {
         color: "#D9D9D9",
      },
   },
   grayCheckbox: {
      "&.MuiCheckbox-root": {
         color: "#D9D9D9",
      },
   },
   grayBtn: {
      color: "black",
      backgroundColor: "#D9D9D9",
      "&:hover": {
         backgroundColor: darken("#D9D9D9", 0.2),
      },
   },
}));

/**
 * Sign Up Form
 *
 * Sign up form component, renders form to call sig up mutation.
 * @param {object} props Component's props.
 * @param {object} props.darkMode boolean value to change forms style to be more suitable for dark backgrounds.
 * @returns {JSX.Element} SignUpForm component.
 */
const SignUpForm = ({ darkMode }) => {
   const classes = useStyles();
   const location = useLocation();
   const getStartedEmail = location?.state?.getStartedEmail;
   const history = useHistory();
   const [, setUser] = useUser();
   const dispatch = useDispatch();

   const smallScreen = useMediaQuery("(max-width:770px)");

   const [redirectTo] = useQueryParam("redirectTo", StringParam);
   const [hash] = useQueryParam("hash", StringParam);

   // TODO: this is duplicated in the signup and settings page, we should have a single
   // validation schema in a shared helper that we can use anywhere we want to validate
   // these fields in a consistent way.
   const schema = Yup.object().shape({
      name: Yup.string()
         .required()
         .trim()
         .min(3)
         .max(128)
         .label("Name")
         .notOneOf(ReservedUsernames, "Name is reserved"),
      password: Yup.string().min(8).required().label("Password"),
      email: Yup.string().email().max(320).required().label("Email"),
      terms: Yup.bool().oneOf([true], "You must accept the terms of service "),
   });

   const sendStatsMutation = useStatsMutation();

   const [getUser, { loading: loadingUser }] = useLazyQuery(GET_CURRENT_USER, {
      fetchPolicy: "network-only",
      /**
       * getCurrentUser onCompleted
       *
       * Redirect the user to the feed or a custom redirect location passed in the params.
       * @param {object} data Apollo response data for currentUser query.
       */
      onCompleted(data) {
         // This is duplicated in signin, signup and useRedirectToFeedIfAuthorised so should be generalised to hook.
         if (data?.currentUser) {
            setUser(data.currentUser);
            appStorage.setValue({
               key: "auxSelectedProfileURI",
               value: data.currentUser.profile.uri,
            });
            appStorage.setValue({
               key: "profileId",
               value: data.currentUser.profile.id,
            });
         }
         if (data?.currentUser?.email?.emailVerifiedAt) {
            if (hash) {
               history.push(`/feed?hash=${hash}`);
            } else if (redirectTo) {
               history.push(`/${redirectTo}`);
            } else {
               // Redirect to feed
               history.push("/feed");
            }
         } else if (data?.currentUser?.email) {
            if (redirectTo) {
               history.push(`/confirm-email?redirectTo=${redirectTo}`);
            } else if (hash) {
               history.push(`/confirm-email?hash=${hash}`);
            } else {
               history.push("/confirm-email");
            }
         }
      },
   });

   const [apiError, setAPIError] = useState(false);

   const [signup, { loading: loadingSignUp, error }] = useMutation(
      SIGN_UP_USER,
      {
         /**
          * signup onCompleted
          *
          * After a successful signup request we set the user token and redirect to the welcome page.
          * @param {object} data data
          */
         async onCompleted(data) {
            // window.localStorage.setItem("column-resize-0", "200");
            // Load user and set Token/cookie
            appStorage.setValue({ key: "auxJWT", value: data.signup.jwt });
            // Loading App Modal is shown while the login is in progress
            dispatch(openLoadingAppModal(true));
            if (!appStorage.getValue({ key: "auxDeviceID" })) {
               appStorage.setValue({
                  key: "auxDeviceID",
                  value: generateMd5(15),
               });
            }
            if (data.signup?.uri) {
               appStorage.setValue({
                  key: "auxSelectedProfileURI",
                  value: data.signup.profile.uri,
               });
               appStorage.setValue({
                  key: "profileId",
                  value: data.signup.profile.id,
               });
            }
            // eslint-disable-next-line no-param-reassign
            delete data.signup.jwt;
            await client.resetStore();
            sendStatsMutation({
               statsId: "UserSignUp",
               userId: data.signup.id,
            });
            // Track the Sign Up conversion event
            // Google Analytics
            gtag("event", "sign_up", {
               event_label: "Sign Up",
               event_category: "engagement",
            });
            // Google Adwords
            gtag("event", "conversion", {
               send_to: "AW-10804925588/FOhuCNDQqYYDEJSpmKAo",
            });
            // Twitter
            window.twq("track", "SignUp");
            getUser();

            // Redirect to confirm email page
            history.push("/confirm-email");
         },
         /**
          * signup onError
          *
          * Set the api error state to true so we can show error if user is trying to signup while API is down being updated.
          * @param {Error} errorResponse Error response from signup mutation.
          */
         onError(errorResponse) {
            if (errorResponse?.toString()?.includes("Failed to fetch")) {
               setAPIError(true);
            }
         },
      }
   );

   const loading = loadingSignUp || loadingUser;

   /**
    * appendClasses
    * @param  {...any} params params
    * @returns {string} class
    */
   const appendClasses = (...params) => params.join(" ");

   /**
    * getContrastClasses
    *
    * Applies class based on dark/light mode.
    * @param {...any} params params
    * @returns {string} class
    */
   const getContrastClasses = (...params) =>
      darkMode ? appendClasses(...params) : params[0];

   /**
    * linkRedirect
    * @returns {string} path to redirect to.
    */
   const linkRedirect = () => {
      if (hash) {
         return `/signin?hash=${hash}`;
      }
      if (redirectTo) {
         return `/signin?redirectTo=${redirectTo}`;
      }
      return "/signin";
   };

   // // Return a "Go To Feed" button if the user is already logged in (primarily for the /join/ landing pages)
   // Commented this out because you see this button when you have JWT but it's not clear why it's not valid.
   // We should instead redirect to /feed if you are logged in on the signup or login pages.
   // if (appStorage.getValue({ key: "auxJWT" })) {
   //    return (
   //       <Button
   //          component={Link}
   //          variant="contained"
   //          to="/feed"
   //          className={classes.noDrag}
   //       >
   //          Go To Feed
   //       </Button>
   //    );
   // }
   return (
      <div
         className={getContrastClasses(classes.formWrapper, classes.whiteText)}
      >
         <Formik
            initialValues={{
               name: "",
               email: getStartedEmail || "",
               password: "",
               terms: false,
               optInMarketing: true,
            }}
            validateOnChange={false}
            validationSchema={schema}
            onSubmit={async (values) => {
               setAPIError(false);
               const { name, email, password, optInMarketing } = values;
               const profileLink = name
                  .toLowerCase()
                  .trim()
                  .replace(/\s\s+/g, " ")
                  .split(" ")
                  .join("-");
               await signup({
                  variables: {
                     name: name.trim(),
                     uri: profileLink,
                     email: email.trim(),
                     password,
                     optInMarketing,
                     isPhoneMobile: false,
                  },
               });
            }}
         >
            {({
               handleSubmit,
               handleChange,
               errors,
               values,
               setFieldError,
               touched,
            }) => {
               // Map GraphQL errors to Formik
               if (error && error.graphQLErrors) {
                  error?.graphQLErrors?.forEach((event) => {
                     if (event?.message.includes("Name")) {
                        setFieldError("name", event?.message);
                     } else if (event?.message.includes("Email")) {
                        setFieldError("email", event?.message);
                     }
                     // Delete the errors to avoid re-render loop
                     delete error.graphQLErrors;
                  });
               }
               return (
                  <form
                     noValidate
                     onSubmit={handleSubmit}
                     className={classes.noDrag}
                  >
                     {apiError && (
                        <div style={{ display: "block" }}>
                           <Typography
                              style={{
                                 color: "#d32f2f",
                                 fontSize: "12px",
                                 lineHeight: "16px",
                              }}
                           >
                              The app is being updated. Try again in a few
                              minutes.
                           </Typography>
                        </div>
                     )}

                     <Typography className={classes.labelInput}>
                        Name
                     </Typography>
                     <TextField
                        size="small"
                        className={classes.input}
                        variant="outlined"
                        id="name"
                        name="name"
                        placeholder="Name"
                        value={values.name}
                        onChange={handleChange}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                     />

                     <Typography className={classes.labelInput}>
                        Email Address
                     </Typography>
                     <TextField
                        size="small"
                        className={classes.input}
                        variant="outlined"
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={values.email.trim()}
                        onChange={handleChange}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                     />

                     <Typography className={classes.labelInput}>
                        Password
                     </Typography>
                     <TextField
                        size="small"
                        className={classes.input}
                        variant="outlined"
                        id="password"
                        name="password"
                        placeholder="Password"
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                     />
                     <FormControlLabel
                        control={
                           <Checkbox
                              name="terms"
                              id="terms"
                              color="primary"
                              onChange={handleChange}
                              className={getContrastClasses(
                                 classes.checkboxMark,
                                 classes.grayCheckbox
                              )}
                              required
                           />
                        }
                        name="terms"
                        label={<TermsAndConditionsLabel />}
                     />
                     {errors.terms && (
                        <FormHelperText error>{errors.terms}</FormHelperText>
                     )}
                     <FormControlLabel
                        control={
                           <Checkbox
                              name="optInMarketing"
                              color="primary"
                              onChange={handleChange}
                              checked={values.optInMarketing}
                              className={getContrastClasses(
                                 classes.checkboxMark,
                                 classes.grayCheckbox
                              )}
                              id="optInMarketing"
                           />
                        }
                        name="optInMarketing"
                        label={
                           <ReactLabelComponent
                              width={340}
                              body="Get the latest music industry tips, Aux guides and special offers delivered right to your inbox"
                           />
                        }
                     />
                     <div>
                        <Button
                           variant="contained"
                           type="submit"
                           color="primary"
                           disabled={loading}
                           className={getContrastClasses("", classes.grayBtn)}
                           style={smallScreen ? { width: "100%" } : {}}
                        >
                           {loading ? "Loading..." : "Sign Up"}
                        </Button>
                        <Link
                           className={getContrastClasses(
                              classes.link,
                              classes.grayText
                           )}
                           to={linkRedirect()}
                        >
                           {" "}
                           Already have an account? Sign In
                        </Link>
                     </div>
                  </form>
               );
            }}
         </Formik>
      </div>
   );
};

SignUpForm.propTypes = {
   location: PropTypes.shape({
      state: PropTypes.shape({
         getStartedEmail: PropTypes.string,
      }),
   }),
   darkMode: PropTypes.bool,
};
SignUpForm.defaultProps = {
   location: null,
   darkMode: false,
};

export default SignUpForm;
