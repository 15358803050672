import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import useMediaQuery from "@mui/material/useMediaQuery";
import Card from "@mui/material/Card";
import OrionImage from "_assets/orion.jpeg";
import WillBrown from "_assets/will-brown.jpeg";
import CurvesMarketingImage from "_assets/curves-marketing.svg";

const useStyles = makeStyles(() => ({
   feedbackContainer: {
      display: "flex",
      boxSizing: "content-box",
      height: "678px",
      width: "auto",
      maxWidth: "85%",
   },
   feedbackCard: {
      minHeight: "238px",
      border: "0.8px solid #FFF",
      borderRadius: "8px",
      boxShadow: "-4px 4px 7px 4px rgba(183, 183, 195, 0.10)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      paddingBottom: "2.6rem",
      height: "calc(100% - 2.6rem)",
      background: "rgba(255, 255, 255, 0.30)",
   },
   feedbackCardWrapper: {
      flex: 1,
      position: "relative",
      zIndex: 2,
      backdropFilter: "blur(15px)",
      "@media (max-width:900px)": {
         height: "312px",
      },
   },
   feedbackAvatar: {
      width: "85px",
      height: "85px",
      border: "2px solid #FFF",
      position: "relative",
      left: "-50%",
      fill: "rgba(255, 255, 255, 0.65)",
      filter: "drop-shadow(0px 4px 24px rgba(26, 26, 26, 0.08))",
   },
   feedbackAvatarContainer: {
      zIndex: 1,
      position: "absolute",
      left: "50%",
      bottom: "-62px",
      "@media (max-width:900px)": {
         bottom: "-72px",
      },
   },
   feedbackTitle: {
      marginTop: "32px",
      marginBottom: "6px",
      fontSize: "1.3125rem",
      lineHeight: "1.75rem",
      fontWeight: 600,
   },
   feedbackSubtitle: {
      marginBottom: "16px",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      fontWeight: 500,
   },
   feedbackDescription: {
      textAlign: "center",
      fontSize: "1rem",
      lineHeight: "1.5rem",
   },
   dots: {
      width: "12px",
      height: "12px",
      borderRadius: "50%",
      cursor: "pointer",
      "&:hover": {
         backgroundColor: "#6147FF",
         boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      },
   },
}));

/**
 * Feedback Cards
 * @returns {JSX.Element} Feedback Cards
 */
const FeedbackCards = () => {
   const classes = useStyles();
   const mobileView = useMediaQuery("(max-width:900px)");
   const [cardNumber, setCardNumber] = useState(1);

   const [touchStart, setTouchStart] = useState(null);
   const [touchEnd, setTouchEnd] = useState(null);

   // the required distance between touchStart and touchEnd to be detected as a swipe
   const minSwipeDistance = 50;

   const onTouchStart = (e) => {
      setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
      setTouchStart(e.targetTouches[0].clientX);
   };

   const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

   const onTouchEnd = () => {
      if (!touchStart || !touchEnd) return;
      const distance = touchStart - touchEnd;
      const isLeftSwipe = distance > minSwipeDistance;
      const isRightSwipe = distance < -minSwipeDistance;
      if (isLeftSwipe || isRightSwipe) {
         if (isRightSwipe && cardNumber > 1) {
            setCardNumber(cardNumber - 1);
         } else if (isLeftSwipe && cardNumber < 3) {
            setCardNumber(cardNumber + 1);
         }
      }
      // add your conditional logic here
   };

   return (
      <div
         style={{
            backgroundColor: "#F8F8FD",
            backgroundImage: `url(${CurvesMarketingImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: mobileView ? "center" : "",
            backgroundSize: mobileView ? "cover" : "", // Set to the left on desktop.
            // backgroundSize: mobileView ? "cover" : "100% 100%",
         }}
      >
         <Container className={classes.feedbackContainer}>
            {mobileView ? (
               <div
                  style={{
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "center",
                     justifyContent: "center",
                  }}
               >
                  <div
                     style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                     }}
                     onTouchStart={onTouchStart}
                     onTouchMove={onTouchMove}
                     onTouchEnd={onTouchEnd}
                  >
                     {cardNumber === 1 && (
                        <div className={classes.feedbackCardWrapper}>
                           <Card
                              variant="outlined"
                              className={classes.feedbackCard}
                           >
                              <div className={classes.feedbackTitle}>
                                 Patrick Laroche
                              </div>
                              <div className={classes.feedbackSubtitle}>
                                 Freelance Bassist
                              </div>
                              <div className={classes.feedbackDescription}>
                                 As a professional freelance bassist, Aux
                                 Discover makes it so easy to find venues and
                                 other professionals to work with. It&apos;s a
                                 one-stop shop for musicians, producers and
                                 studios to collaborate online.
                              </div>
                              <div className={classes.feedbackAvatarContainer}>
                                 <Avatar
                                    className={classes.feedbackAvatar}
                                    src="https://aux-marketing-assets.s3.eu-west-1.amazonaws.com/marketing-pages/patrick-laroche.png"
                                    alt="Patrick Laroche"
                                 />
                              </div>
                           </Card>
                        </div>
                     )}
                     {cardNumber === 2 && (
                        <div className={classes.feedbackCardWrapper}>
                           <Card
                              variant="outlined"
                              className={classes.feedbackCard}
                           >
                              <div className={classes.feedbackTitle}>Orion</div>
                              <div className={classes.feedbackSubtitle}>
                                 Artist and Blogger
                              </div>
                              <div className={classes.feedbackDescription}>
                                 Aux is a great tool that helps me store my
                                 music and find collaborators easily for getting
                                 feedback and creating new music.
                              </div>
                              <div className={classes.feedbackAvatarContainer}>
                                 <Avatar
                                    className={classes.feedbackAvatar}
                                    src={OrionImage}
                                    alt="Orion"
                                 />
                              </div>
                           </Card>
                        </div>
                     )}
                     {cardNumber === 3 && (
                        <div className={classes.feedbackCardWrapper}>
                           <Card
                              variant="outlined"
                              className={classes.feedbackCard}
                           >
                              <div className={classes.feedbackTitle}>
                                 Will Brown
                              </div>
                              <div className={classes.feedbackSubtitle}>
                                 Tape Notes
                              </div>
                              <div className={classes.feedbackDescription}>
                                 Editing interviews into a final finished show
                                 takes a lot of time and a whole load of
                                 different tools and platforms. Aux are changing
                                 that by creating a single tool to manage the
                                 editing, collaboration and feedback process for
                                 podcast creators.
                              </div>
                              <div className={classes.feedbackAvatarContainer}>
                                 <Avatar
                                    className={classes.feedbackAvatar}
                                    src={WillBrown}
                                    alt="Will Brown"
                                 />
                              </div>
                           </Card>
                        </div>
                     )}
                  </div>
                  <div
                     style={{
                        height: "120px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                     }}
                  >
                     <div
                        style={{
                           display: "flex",
                           alignItems: "center",
                           gap: "16px",
                        }}
                     >
                        <Box
                           className={classes.dots}
                           style={{
                              backgroundColor:
                                 cardNumber === 1 ? "#6147FF" : "#B6B4C7",
                           }}
                           onClick={() => setCardNumber(1)}
                        />
                        <Box
                           className={classes.dots}
                           style={{
                              backgroundColor:
                                 cardNumber === 2 ? "#6147FF" : "#B6B4C7",
                           }}
                           onClick={() => setCardNumber(2)}
                        />
                        <Box
                           className={classes.dots}
                           style={{
                              backgroundColor:
                                 cardNumber === 3 ? "#6147FF" : "#B6B4C7",
                           }}
                           onClick={() => setCardNumber(3)}
                        />
                     </div>
                  </div>
               </div>
            ) : (
               <div
                  style={{
                     display: "flex",
                     gap: "1.88rem",
                     margin: "auto 0 137px",
                  }}
               >
                  <div className={classes.feedbackCardWrapper}>
                     <Card variant="outlined" className={classes.feedbackCard}>
                        <div className={classes.feedbackTitle}>
                           Patrick Laroche
                        </div>
                        <div className={classes.feedbackSubtitle}>
                           Freelance Bassist
                        </div>
                        <div className={classes.feedbackDescription}>
                           As a professional freelance bassist, Aux Discover
                           makes it so easy to find venues and other
                           professionals to work with. It&apos;s a one-stop shop
                           for musicians, producers and studios to collaborate
                           online.
                        </div>
                        <div className={classes.feedbackAvatarContainer}>
                           <Avatar
                              className={classes.feedbackAvatar}
                              src="https://aux-marketing-assets.s3.eu-west-1.amazonaws.com/marketing-pages/patrick-laroche.png"
                              alt="Patrick Laroche"
                           />
                        </div>
                     </Card>
                  </div>
                  <div className={classes.feedbackCardWrapper}>
                     <Card variant="outlined" className={classes.feedbackCard}>
                        <div className={classes.feedbackTitle}>Orion</div>
                        <div className={classes.feedbackSubtitle}>
                           Artist and Blogger
                        </div>
                        <div className={classes.feedbackDescription}>
                           Aux is a great tool that helps me store my music and
                           find collaborators easily for getting feedback and
                           creating new music.
                        </div>
                        <div className={classes.feedbackAvatarContainer}>
                           <Avatar
                              className={classes.feedbackAvatar}
                              src={OrionImage}
                              alt="Orion"
                           />
                        </div>
                     </Card>
                  </div>
                  <div className={classes.feedbackCardWrapper}>
                     <Card variant="outlined" className={classes.feedbackCard}>
                        <div className={classes.feedbackTitle}>Will Brown</div>
                        <div className={classes.feedbackSubtitle}>
                           Tape Notes
                        </div>
                        <div className={classes.feedbackDescription}>
                           Editing interviews into a final finished show takes a
                           lot of time and a whole load of different tools and
                           platforms. Aux are changing that by creating a single
                           tool to manage the editing, collaboration and
                           feedback process for podcast creators.
                        </div>
                        <div className={classes.feedbackAvatarContainer}>
                           <Avatar
                              className={classes.feedbackAvatar}
                              src={WillBrown}
                              alt="Will Brown"
                           />
                        </div>
                     </Card>
                  </div>
               </div>
            )}
         </Container>
      </div>
   );
};

export default FeedbackCards;
