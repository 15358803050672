import React from "react";
import * as ReactDOM from "react-dom";
import "./App.css";
import "./_assets/FontDr/WebLicense/Dr/dr.css";
import "./_assets/FontInter/inter-ui.css";

// Redux for player state
import { Provider } from "react-redux";
// Apollo Packages
import { ApolloProvider } from "@apollo/react-hooks";
// Stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Calculate time ago
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import * as Sentry from "@sentry/react";
import { client } from "./_apollo/client";
import store from "./_redux/store";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import getConfig, { CONFIG_ENV } from "./_utils/getConfig";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const { stripeKey, sentryDSN } = getConfig();

TimeAgo.addDefaultLocale(en);

if (sentryDSN) {
  Sentry.init({
    /**
     * Before Send
     * @param {Sentry.Event} event Sentry event
     * @returns {null | Sentry.Event} null if we don't want to send the event, otherwise pass the event through.
     */
    beforeSend(event) {
      if (CONFIG_ENV === "expo") {
        return null;
      }
      return event;
    },
    dsn: sentryDSN,
    integrations: [new Sentry.BrowserTracing()],
    release: process.env.REACT_APP_VERSION,
    environment: CONFIG_ENV,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

// Show the current version of the app in the console.
// eslint-disable-next-line no-console
console.info(
  `%c Aux Web Version: ${
    process.env.REACT_APP_VERSION || "No process.env set"
  } (${CONFIG_ENV})`,
  "color:#6147FF; font-size:12px"
);

const customLabels = {
  second: {
    past: {
      one: "now",
      other: "now",
    },
    future: "just now", // Hack to avoid posts flashing "future" when they are first created.
  },
  minute: {
    past: {
      one: "{0}min",
      other: "{0}min",
    },
    future: "future",
  },
  hour: {
    past: {
      one: "{0}hr",
      other: "{0}hr",
    },
    future: "future",
  },
  day: {
    past: {
      one: "{0}d",
      other: "{0}d",
    },
    future: "future",
  },
  week: {
    past: {
      one: "{0}wk",
      other: "{0}wk",
    },
    future: "future",
  },
  month: {
    past: {
      one: "{0}mth",
      other: "{0}mth",
    },
    future: "future",
  },
  year: {
    past: {
      one: "{0}yr",
      other: "{0}yr",
    },
    future: "future",
  },
};
TimeAgo.addLabels("en", "custom", customLabels);

const stripePromise = loadStripe(stripeKey);

serviceWorkerRegistration.register();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
