import PropTypes from "prop-types";
import React from "react";

import InfiniteScroll from "react-infinite-scroller";

import { useQuery } from "@apollo/react-hooks";
import { GET_CURRENT_USER } from "_apollo/queries";
import { useUser } from "_utils/UserContext";
import ArtistList from "./DiscoverProfilesList";

const DiscoverInfiniteScroll = ({
   fetchMoreData,
   profiles,
   searchingArtists,
   flag,
   scrollParentRef,
}) => {
   const [loggedInUser] = useUser();
   const { data: dataUser } = useQuery(GET_CURRENT_USER);
   const userData = dataUser?.currentUser;

   return (
      <InfiniteScroll
         pageStart={0}
         loadMore={fetchMoreData}
         hasMore={flag}
         useWindow={!userData}
         getScrollParent={() => (userData ? scrollParentRef.current : null)}
      >
         <ArtistList
            profiles={profiles}
            loggedInUser={loggedInUser}
            loading={searchingArtists}
         />
      </InfiniteScroll>
   );
};

DiscoverInfiniteScroll.propTypes = {
   profiles: PropTypes.arrayOf({}).isRequired,
   fetchMoreData: PropTypes.func.isRequired,
   flag: PropTypes.bool.isRequired,
   searchingArtists: PropTypes.bool.isRequired,

   scrollParentRef: PropTypes.shape({
      current: PropTypes.elementType,
   }),
};

DiscoverInfiniteScroll.defaultProps = {
   scrollParentRef: { current: null },
};

export default DiscoverInfiniteScroll;
