import React from "react";
import { Grid, Button, LinearProgress, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/styles";

/**
 * ProgressNotificationItem
 *
 * A single notification that contains an upload progress bar, used for files and folders uploads.
 * @param {object} props props
 * @param {Function} props.cancelHandler Function used to cancel the upload in progress
 * @param {object} props.classes Classes to apply to notification.
 * @param {string} props.title Title of the notification.
 * @param {string} props.label Label of the notification.
 * @param {number} props.percentage Progress percentage from 0 to 100.
 * @param {boolean} props.failed Failed state should show.
 * @param {Function} props.acceptHandler Function used to accept notification such as project invite.
 * @param {boolean} props.paused Paused state should show.
 * @returns {JSX.Element} Progress notification.
 */
const ProgressNotificationItem = ({
   cancelHandler,
   classes,
   title,
   label,
   percentage,
   failed,
   acceptHandler,
   paused,
}) => {
   const theme = useTheme();

   /**
    * color
    *
    * Chooses the correct font color for the notification based on the component state from props.
    * @returns {string} CSS valid color value.
    */
   const color = () => {
      if (paused) {
         return theme.palette.primary.secondary;
      }
      if (failed) {
         return theme.palette.error.main;
      }
      return theme.palette.primary.main;
   };

   /**
    * actionButton
    *
    * Renders the correct button depending on the notification type.
    * @returns {Button} Button component for call to action.
    */
   const actionButton = () => {
      if (paused) {
         return <Button onClick={acceptHandler}>Resume</Button>;
      }
      if (failed) {
         return <Button onClick={acceptHandler}>Try Again</Button>;
      }
      if (percentage === 100) {
         return (
            <Button onClick={acceptHandler} style={{ minWidth: "70px" }}>
               Clear
            </Button>
         );
      }
      return (
         <Button onClick={cancelHandler} style={{ minWidth: "70px" }}>
            Cancel
         </Button>
      );
   };

   return (
      <Grid
         container
         direction="row"
         alignItems="center"
         className={classes.notificationItem}
         wrap="nowrap"
      >
         <Box width={500}>
            <Box display="flex" gap="20px" mb="5px" alignItems="center">
               <Typography
                  noWrap
                  component="span"
                  maxWidth={430}
                  flexShrink={1}
                  display="flex"
                  alignItems="center"
                  gap="10px"
               >
                  {title}
               </Typography>
               <Typography
                  flexShrink={0}
                  component="span"
                  color={color()}
                  noWrap
               >
                  {label}
               </Typography>
            </Box>
            <Box color={color()} borderRadius="4px" overflow="hidden">
               <LinearProgress
                  color="inherit"
                  variant={percentage && "determinate"}
                  value={percentage || 0}
               />
            </Box>
         </Box>
         <Box mx={1}>{actionButton()}</Box>
      </Grid>
   );
};

export default ProgressNotificationItem;
