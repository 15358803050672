/**
 * Is Inside DAW Package File
 *
 * Checks if a file is within a package DAW file type, so that we can skip sync actions.
 *
 * TODO: update data.js to have a boolean field supportedDAWTypes: true|false and
 * packageFileType true|false and check all of those files here instead of hard
 * coding them.
 * @param {string} pathFile to check.
 * @returns {boolean} Is the file inside a DAW Package file folder.
 */
const isInsideDAWPackageFile = (pathFile) => {
   return pathFile?.includes(".band/") || pathFile?.includes(".logicx/");
};

export default isInsideDAWPackageFile;
