import React, {
  useContext,
  createContext,
  useState,
  useRef,
  useEffect,
} from "react";
import { File } from "types";
import useStatsMutation from "_utils/useStatsMutation";
import * as Sentry from "@sentry/react";
import { CONFIG_ENV } from "_utils/getConfig";

interface CustomFile extends File {
  masteredData: any[]; // Add a key property to the File type
}
interface StateType {
  status: "EMPTY" | "UPLOADING" | "MASTERING" | "MASTERED";
  file: CustomFile | null;
}
type ProviderValueType = {
  fileToMaster: StateType;
  setFileToMaster: React.Dispatch<React.SetStateAction<StateType>>;
  masterAudioFile: (fileName: string) => Promise<any>;
  abortControllerRef: React.MutableRefObject<AbortController>;
};

const ThemeContext = createContext(null);

const MASTERING_API_URL =
  "https://indietechteam--aux-mastering-api-0-2-model-web.modal.run";

/**
 * useMasteringData
 * React context hook for mastering.
 * @returns {ProviderValueType} DownloadingSyncContext
 */
const useMasteringData = () => useContext<ProviderValueType>(ThemeContext);

/**
 * MasteringDataContext
 * React context provider for mastering.
 * @param {object} props props
 * @param {React.Component} props.children children components.
 * @returns {React.Component} DownloadingSyncProvider
 */
const MasteringDataContext = ({ children }) => {
  const [fileToMaster, setFileToMaster] = useState({
    status: "EMPTY", // EMPTY, UPLOADING, MASTERING, MASTERED
    file: null,
  });

  const abortControllerRef = useRef<AbortController>(null);
  useEffect(() => {
    abortControllerRef.current = new AbortController();
  }, []);

  const sendStatsMutation = useStatsMutation();

  const masterAudioFile = async (fileName) => {
    try {
      sendStatsMutation({
        statsId: "MasterTrack",
        metadata: JSON.stringify({ fileName }),
      });
      const response = await fetch(
        `${MASTERING_API_URL}?${new URLSearchParams({
          fileName,
          configEnv: CONFIG_ENV,
        })}`,
        {
          signal: abortControllerRef.current.signal,
        }
      );
      // Catch errors.
      if (!response.ok) {
        Sentry.captureException(
          new Error(
            `Failed to generate sample. Response Code: ${response.status}`
          )
        );
        return null;
      }

      const masteredData = await response.json();
      setFileToMaster((a) => ({
        status: "MASTERED",
        file: {
          ...a.file,
          masteredData: masteredData.mastered_data,
          signedUrl: masteredData.signed_url_original_file,
        },
      }));
      return masteredData;
    } catch {
      return null;
    }
  };

  return (
    <ThemeContext.Provider
      value={{
        fileToMaster,
        setFileToMaster,
        masterAudioFile,
        abortControllerRef,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export { useMasteringData, MasteringDataContext };
