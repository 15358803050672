import PropTypes from "prop-types";
import React, { useState } from "react";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { useQueryParam, BooleanParam } from "use-query-params";
import TableCellName from "./TableCells/TableCellName";
import TableCellType from "./TableCells/TableCellType";
import TableCellSize from "./TableCells/TableCellSize";
import TableCellLastModified from "./TableCells/TableCellLastModified";

const useStyles = makeStyles(() => ({
   tableRow: {
      cursor: "pointer",
      position: "relative",
      [`& .MuiTableCell-body, & button`]: {
         color: "inherit",
      },
   },
}));
/**
 * Table Row Folder.
 * @param {object} root0 props.
 * @param {object} root0.f folder instance.
 * @param {object} root0.filesLoading loading state folders.
 * @param {boolean} root0.isModal if it is rendered inside a modal
 * @param {Function} root0.renderFileFolderActionMenu render menu.
 * @param {Function} root0.handleFinishRenaming rename request.
 * @param {object} root0.renamingFolderValue track value of folder being renamed.
 * @param {object} root0.selectedRenaming selected file to be renamed.
 * @param {Function}root0.setRenamingFolderValue set rename value.
 * @param {Function} root0.setSelectedRenaming set folder to rename.
 * @param {Array} root0.selectedFiles selected files with chackboxes.
 * @param {Function} root0.setSelectedFiles function to select the files from the checkoboxes.
 * @returns {JSX.Element} table folder row.
 */
const TableRowFile = ({
   f,
   filesLoading,
   isModal,
   selectedFiles,
   setSelectedFiles,
   renderFileFolderActionMenu,
   handleFinishRenaming,
   renamingFolderValue,
   selectedRenaming,
   setRenamingFolderValue,
   setSelectedRenaming,
}) => {
   const attributes = { "data-fileid": f.id };
   const [trashed] = useQueryParam("trashed", BooleanParam);

   const classes = useStyles();
   const history = useHistory();
   const { folderURI } = useParams();
   const [hover, setHover] = useState(false);

   /**
    * Click Handler.
    *
    * Handles clicking into a directory
    * @param {object} e event.
    */
   const clickHandler = (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (folderURI) {
         history.push(
            `/files/folder/${folderURI}/file/${f?.uri}${
               trashed ? "?trashed=1" : ""
            }`
         );
      }

      // If this is a trashed file, don't allow user to click it.
      if (trashed) {
         return;
      }
      history.push(`/files/file/${f?.uri}${trashed ? "?trashed=1" : ""}`);
   };

   return (
      <TableRow
         key={`file-${f.uri}`}
         draggable={!renamingFolderValue}
         className={classes.tableRow}
         onMouseEnter={() => setHover(true)}
         onMouseLeave={() => setHover(false)}
         // eslint-disable-next-line react/jsx-props-no-spreading
         {...attributes}
      >
         {!isModal && (
            <TableCell sx={{ padding: "0px !important" }}>
               {(hover || !!selectedFiles.find((_f) => _f.id === f.id)) && (
                  <Checkbox
                     disabled={filesLoading}
                     checked={!!selectedFiles.find((_f) => _f.id === f.id)}
                     onClick={(e) => {
                        setSelectedFiles((prevFilesArr) => {
                           if (e.target.checked) {
                              return [...prevFilesArr, f];
                           }
                           return prevFilesArr.filter(
                              (file) => file.id !== f.id
                           );
                        });
                     }}
                  />
               )}
            </TableCell>
         )}
         <TableCellName
            file={f}
            handleFinishRenaming={handleFinishRenaming}
            renamingFolderValue={renamingFolderValue}
            selectedRenaming={selectedRenaming}
            setSelectedRenaming={setSelectedRenaming}
            setRenamingFolderValue={setRenamingFolderValue}
            clickHandler={clickHandler}
            isModal={isModal}
            loading={filesLoading}
         />
         <TableCellType file={f} loading={filesLoading} />
         <TableCellSize file={f} loading={filesLoading} />
         <TableCellLastModified file={f} loading={filesLoading} />
         {renderFileFolderActionMenu(f)}
      </TableRow>
   );
};

TableRowFile.propTypes = {
   f: PropTypes.shape({
      id: PropTypes.string,
      parentFolderId: PropTypes.string,
      projectId: PropTypes.string,
      uri: PropTypes.shape({
         startsWith: PropTypes.func,
      }),
   }).isRequired,
   filesLoading: PropTypes.bool.isRequired,
   handleFinishRenaming: PropTypes.func,
   isModal: PropTypes.bool.isRequired,
   renamingFolderValue: PropTypes.shape({}),
   renderFileFolderActionMenu: PropTypes.func.isRequired,
   selectedFiles: PropTypes.shape({
      find: PropTypes.func,
   }),
   selectedRenaming: PropTypes.shape({}),
   setRenamingFolderValue: PropTypes.func.isRequired,
   setSelectedFiles: PropTypes.func.isRequired,
   setSelectedRenaming: PropTypes.func.isRequired,
   uri: PropTypes.string,
};

TableRowFile.defaultProps = {
   uri: null,
   selectedRenaming: null,
   selectedFiles: null,
   renamingFolderValue: null,
   handleFinishRenaming: () => null,
};

export default TableRowFile;
