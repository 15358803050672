import React from "react";

/**
 * FLStudioIcon
 * @returns {JSX.Element} FL Studio Icon
 */
function FLStudioIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 2H3C2.45 2 2 2.45 2 3V21C2 21.55 2.45 22 3 22H21C21.55 22 22 21.55 22 21V3C22 2.45 21.55 2 21 2ZM13.74 16.85C12.32 18.87 10.64 19.83 9.71 19.57C8.98 19.37 7.88 18.51 7.83 14.83C7.79 11.51 8.24 10.01 8.78 9.03C8.84 8.92 10.34 8.67 10.33 8.8C10.29 9.59 10.52 11.44 11.9 12.05C13.68 12.85 15.02 11.51 15.33 11C15.39 10.89 16.27 11.89 16.26 11.98C16.08 13.12 15.06 14.97 13.74 16.85ZM17.29 11.81C17.07 11.74 15.25 9.52 15.25 9.52C15.25 9.52 14.12 12.06 12.37 11.31C10.68 10.59 11.25 7.79 11.25 7.79C9.98 7.84 8.1 8.52 7.98 8.1C7.78 7.39 8.63 5.71 10.59 5.42C12.55 5.13 13.65 6.93 13.65 6.93C13.7 6.6 14.02 5.85 14.61 5.03C15.13 4.31 16.17 3.46 16.39 3.67C16.6 3.87 16.05 4.48 15.22 5.51C14.44 6.49 14.53 7.27 14.53 7.27C15.66 7.13 17.06 7.14 17.78 9.19C18.3 10.66 17.48 11.89 17.26 11.82L17.29 11.81Z"
        fill="black"
      />
    </svg>
  );
}

export default FLStudioIcon;
