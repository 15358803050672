/**
 * Is Starred
 *
 * Function that takes id of starred item and checks if the item can be found in user's starredItems array.
 * @param {string} id Id of a starred item that will be checked.
 * @param {string} type type of starred item (project/folder/file).
 * @param {object} user user object from redux, this will make sure that every component that uses isStarred will rerender because it will need to pass the user object to this function
 * @returns {boolean} boolean value of if the starred item is found.
 */
const isStarred = (id, type, user) => {
   if (!id || !type || !user) {
      return false;
   }
   const starredItems = user?.starredItems;

   if (starredItems) {
      const { projects, files, folders } = starredItems;

      /**
       * predicate
       *
       * Function to match project, file or folder object to the id passed to isStarred.
       * @param {object} item project, file or folder object.
       * @returns {boolean} True if a match is found with the passed through array.find.
       */
      const predicate = (item) => String(item) === String(id);

      switch (type) {
         case "project":
            return !!projects.find(predicate);
         case "folder":
            return !!folders.find(predicate);
         case "file":
            return !!files.find(predicate);

         default:
            return false;
      }
   }

   return false;
};

export default isStarred;
