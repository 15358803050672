import { useQuery } from "@apollo/react-hooks";
import isFileReady from "_utils/isFileReady";
import { POLLING_INTERVAL } from "_constants/GlobalVariables";
import { useRef, useEffect } from "react";
import { GET_FILES } from "../_apollo/queries";

/**
 * useGetFiles
 *
 * Custom hook used to get files and poll for files that are processing.
 * @param {object} variables payload for the query
 * @param {boolean} skip skip the query.
 * @returns {object} options of useQuey GET_FILES_INFO.
 */
const useGetFiles = (variables, skip) => {
   const isPolling = useRef();

   const { data, fetchMore, refetch, loading, startPolling, stopPolling } =
      useQuery(GET_FILES, {
         variables,
         skip,
      });

   useEffect(() => {
      const files = data?.getFiles?.files;
      if (files?.length > 0) {
         const everyFileIsReady = files.every((file) => isFileReady(file));
         if (files?.length > 0 && !everyFileIsReady && !isPolling.current) {
            startPolling(POLLING_INTERVAL * 500);
            isPolling.current = true;
         } else if (isPolling.current && everyFileIsReady) {
            stopPolling();
            isPolling.current = false;
         }
      }
   }, [data?.getFiles?.files]);

   useEffect(() => {
      return () => {
         if (isPolling.current) {
            stopPolling();
            isPolling.current = false;
         }
      };
   }, []);

   return { data, refetch, loading, fetchMore };
};

export default useGetFiles;
