import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Button, Box } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

const SingleButtonNotificationItem = ({
   icon,
   buttonObj: { buttonClickHandler },
   body,
   classes,
}) => {
   return (
      <Grid container alignItems="center" className={classes.notificationItem}>
         <Box mr="12px">{icon}</Box>
         <Typography noWrap mr="12px">
            {body}
         </Typography>
         <Grid className={classes.actionBtnContainer}>
            <Button onClick={buttonClickHandler}>
               <ChevronRight
                  sx={{
                     fontSize: "20px !important",
                     color: "black",
                  }}
               />
            </Button>
         </Grid>
      </Grid>
   );
};

SingleButtonNotificationItem.propTypes = {
   icon: PropTypes.string.isRequired,
   buttonObj: PropTypes.shape({
      buttonText: PropTypes.string.isRequired,
      buttonClickHandler: PropTypes.func.isRequired,
   }).isRequired,
   body: PropTypes.string.isRequired,
   classes: PropTypes.shape({
      notificationItem: PropTypes.instanceOf(Object),
      actionBtnContainer: PropTypes.instanceOf(Object),
      notificationImage: PropTypes.instanceOf(Object),
   }).isRequired,
};
export default SingleButtonNotificationItem;
