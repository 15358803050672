import PropTypes from "prop-types";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import ClaimProfileCard from "./ClaimProfileCard";
import AwardCard from "./AwardCard";

const useStyles = makeStyles(() => ({
   container: {
      padding: "54px 2.4vw",
      marginBottom: "6px",
   },
   titlesSections: {
      marginBottom: "18px",
      fontSize: "18px",
      lineHeight: "24px",
   },
}));
/**
 * Profile Awards Container.
 * Show awards of the profile and and information box of how to claim such profile
 * @param {object} params params object
 * @param {Array} params.tags array of tags.
 * @param {boolean} params.awardNominee boolean value representing if profile is an awardNominee.
 * @returns {Element} JSX element.
 */
function ProfileAwardsSection({ tags, awardNominee }) {
   const classes = useStyles();

   return (
      <div className={classes.container}>
         <div className={classes.titlesSections}>Awards</div>
         <Grid container spacing={24}>
            <Grid item xs={6}>
               {tags
                  .filter((a) => !!a?.awardsCategoryTitle)
                  .map((tag) => (
                     <AwardCard
                        key={`${tag.uri}-award-nominee`}
                        title={tag.awardsCategoryTitle}
                        subtitle={tag.awardsCategorySubtitle}
                        award={tag.name}
                        uri={tag.uri}
                     />
                  ))}
            </Grid>
            {awardNominee && (
               <Grid item xs={6}>
                  <ClaimProfileCard
                     title="What are the Aux Awards?"
                     subtitle="The Aux Awards highlights independent artists and music
           businesses succeeding in new and exciting ways. This
           profile has been created on behalf of an awards nominee."
                  />
               </Grid>
            )}
         </Grid>
      </div>
   );
}

ProfileAwardsSection.propTypes = {
   tags: PropTypes.arrayOf(Object).isRequired,
   awardNominee: PropTypes.bool,
};

ProfileAwardsSection.defaultProps = {
   awardNominee: false,
};

export default ProfileAwardsSection;
