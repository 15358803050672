import React, { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import { Link, useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LoginIcon from "_assets/icons/LoginIcon";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { AutoFixHigh, Folder, Help, Person } from "@mui/icons-material";

import useStatsMutation from "_utils/useStatsMutation";
import { useUser } from "_utils/UserContext";
import StorageCard from "_components/StorageCard";

import SoundsIcon from "_assets/icons/SoundsIcon";
import MasteringIcon from "_assets/icons/MasteringIcon";
import SettingsIcon from "_assets/icons/SettingsIcon";

import GrayPopover from "_components/GrayPopover";
import useSignOut from "_utils/useSignOut";
import FeedbackModal from "../Modal/FeedbackModal";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",

    "& .MuiButton-root": {
      color: "black",
    },
  },
  userText: {
    fontWeight: "bold",
    fontSize: theme.typography.h5.fontSize,
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& > a": {
      width: "100%",
      justifyContent: "flex-start",
      borderRadius: 0,
    },
    "& > button": {
      borderRadius: 0,
    },
    "& .MuiTypography-root": {
      fontSize: theme.typography.medium.fontSize,
    },
  },
  item: {
    padding: 6,
    paddingLeft: 16,
    width: "100%",
    justifyContent: "flex-start",
    fontSize: theme.typography.medium.fontSize,
    ...theme.noDrag,
  },
  itemIcon: {
    margin: 0,
    marginRight: 14,
    "& .MuiSvgIcon-root": {
      margin: 0,
    },
  },
  itemText: {
    fontSize: theme.typography.medium.fontSize,
    fontWeight: "bold",
  },
  divider: {
    width: "calc(100% - 16px)",
    alignSelf: "center",
  },
}));

/**
 * Main Menu
 *
 * The main menu column of the Aux app.
 * @returns {JSX.Element} The menu column component.
 */
const AppMenu = () => {
  const [user, setUser] = useUser();
  const signOut = useSignOut();

  const history = useHistory();

  const theme: any = useTheme();
  const classes = useStyles();

  const [expandMenu, setExpandMenu] = useState(null);

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const sendStatsMutation = useStatsMutation();

  /**
   * Dropdown Settings Menu
   * @returns {JSX.Element} The users dropdown menu component.
   */
  const DropdownSettingsMenu = () => {
    return (
      <GrayPopover
        id="simple-menu"
        anchorEl={expandMenu}
        open={Boolean(expandMenu)}
        onClose={() => setExpandMenu(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography
          display="flex"
          flex={1}
          noWrap
          variant={"small" as any}
          fontWeight="bold"
          paddingLeft="20px"
          color="black"
        >
          {user?.email}
        </Typography>

        <Divider
          className={classes.divider}
          style={{
            marginTop: "8px",
            marginBottom: "12px",
          }}
        />
        <Box
          sx={{
            width: "185px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            "& .MuiButton-root": {
              backgroundColor: "transparent",
              color: "black",
              fontSize: "12px",
              paddingLeft: "15px",
              borderRadius: 0,
              width: "100%",
              justifyContent: "start",
              "& svg": {
                marginRight: "13px",
              },
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          }}
        >
          <Button component={Link} to={`/${user?.profile?.uri}`}>
            <SvgIcon>
              <Person />
            </SvgIcon>
            Go to profile
          </Button>

          <Button
            id="sign-out-button"
            onClick={() => {
              sendStatsMutation(
                {
                  statsId: "UserLogOut",
                  userId: user.id,
                },
                {
                  onCompleted: () => {
                    signOut();
                    setUser(null);
                  },
                }
              );
            }}
          >
            <SvgIcon>
              <LoginIcon sx={{ color: theme.palette.coreApp.text }} />
            </SvgIcon>
            Sign out
          </Button>
        </Box>
      </GrayPopover>
    );
  };

  const DropdownSettingsMenuCallback = useCallback(DropdownSettingsMenu, [
    user?.email,
    expandMenu,
  ]);

  return (
    <Box display="flex" flexDirection="column" width="100%" position="relative">
      <div
        className={classes.root}
        style={
          {
            // height: isStorageCardSmall ? "calc(100% - 110px)" : "100%",
          }
        }
        id="overflow-container"
      >
        <FeedbackModal
          open={showFeedbackModal}
          handleClose={() => setShowFeedbackModal(false)}
        />

        <Box
          pt="18px"
          pb="16px"
          id="user-container"
          className={classes.itemContainer}
        >
          <Button
            className={classes.item}
            id="open-menu-button-first-column"
            onClick={(e) => setExpandMenu(e.currentTarget)}
            style={{
              paddingLeft: "20px",
            }}
          >
            <Avatar
              src={user?.profile?.photoUrlOrGravatar}
              className={classes.itemIcon}
              alt={user?.profile?.name} // Alt is used for fill if the user doesn't have a gravatar so use the name so it will fill with the first letter of the users name.
              sx={{
                width: 30,
                height: 30,
                outline: "1.5px solid",
                outlineColor: "secondary.shade60",
                mr: "14px",
              }}
            />
            <Typography className={classes.userText} variant="h5" noWrap>
              {user?.profile?.name}
            </Typography>
            <SvgIcon sx={{ ml: "29px" }}>
              <ArrowDropDownIcon sx={{ color: theme.palette.coreApp.text }} />
            </SvgIcon>
          </Button>
          <DropdownSettingsMenuCallback />
        </Box>
        <Divider className={classes.divider} />

        <Box pt="15px" className={classes.itemContainer}>
          <Button component={Link} to="/sounds" className={classes.item}>
            <SoundsIcon
              className={classes.itemIcon}
              styles={{ color: theme.palette.coreApp.text }}
            />
            <Typography className={classes.itemText} component="span" noWrap>
              Sounds
              {history.location.pathname !== "/sounds" && (
                <>
                  {" "}
                  <Chip
                    label="v2.0"
                    size="small"
                    color="error"
                    icon={<AutoFixHigh />}
                    sx={{
                      fontSize: 11,
                      backgroundColor: "#5740E6",
                      height: "23px",
                    }}
                  />
                </>
              )}
            </Typography>
          </Button>
        </Box>
        <Box className={classes.itemContainer}>
          <Button component={Link} to="/mastering" className={classes.item}>
            <MasteringIcon
              className={classes.itemIcon}
              styles={{ color: theme.palette.coreApp.text }}
            />
            <Typography className={classes.itemText} noWrap>
              Mastering
            </Typography>
          </Button>
        </Box>
        <Box className={classes.itemContainer}>
          <Button
            id="main-menu-aux-files-page"
            component={Link}
            to="/files"
            className={classes.item}
          >
            <SvgIcon className={classes.itemIcon}>
              <Folder sx={{ color: theme.palette.coreApp.text }} />
            </SvgIcon>
            <Box display="flex" flex={1} alignItems="center" gap="15px">
              <Typography className={classes.itemText} noWrap>
                Files
              </Typography>
            </Box>
          </Button>
        </Box>

        <Divider
          className={classes.divider}
          style={{ marginTop: "12px", marginBottom: "12px" }}
        />
        <Box className={classes.itemContainer}>
          <Button className={classes.item} component={Link} to="/settings">
            <SvgIcon
              style={{
                marginRight: "8px",
              }}
              className={classes.itemIcon}
            >
              <SettingsIcon styles={{ color: theme.palette.coreApp.text }} />
            </SvgIcon>
            <Typography className={classes.itemText} noWrap>
              Settings
            </Typography>
          </Button>
          <Button
            onClick={() => setShowFeedbackModal(true)}
            className={classes.item}
          >
            <SvgIcon
              style={{
                marginRight: "8px",
              }}
              className={classes.itemIcon}
            >
              <Help sx={{ color: theme.palette.coreApp.text }} />
            </SvgIcon>
            <Typography className={classes.itemText} noWrap>
              Help / Feedback
            </Typography>
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          justifyContent="flex-end"
          position="absolute"
          bottom={0}
          width="100%"
        >
          <StorageCard />
        </Box>
      </div>
    </Box>
  );
};

export default AppMenu;
