import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Button, Box } from "@mui/material";

const MultiButtonNotificationItem = ({
   actionButtons,
   body,
   classes,
   icon,
}) => {
   return (
      <Grid container alignItems="center" className={classes.notificationItem}>
         <Box mr="12px">{icon}</Box>
         <Typography noWrap mr="12px">
            {body}
         </Typography>
         <Grid className={classes.actionBtnContainer}>
            {actionButtons?.map(
               ({ buttonText, buttonClickHandler, key }, index) => (
                  <Button
                     disableElevation
                     key={key}
                     color="primary"
                     variant={index === 0 ? "contained" : "text"}
                     onClick={buttonClickHandler}
                  >
                     {buttonText}
                  </Button>
               )
            )}
         </Grid>
      </Grid>
   );
};
MultiButtonNotificationItem.propTypes = {
   icon: PropTypes.string.isRequired,
   actionButtons: PropTypes.instanceOf(Array).isRequired,
   body: PropTypes.string.isRequired,
   classes: PropTypes.shape({
      notificationItem: PropTypes.instanceOf(Object),
      actionBtnContainer: PropTypes.instanceOf(Object),
   }).isRequired,
};
export default MultiButtonNotificationItem;
